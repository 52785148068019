import { useAdminPhones } from './useAdminPhones';
import {
  ShowWhatsappBuyPhoneDialogArgs,
  showWhatsappBuyPhoneDialog,
} from '../showWhatsappBuyPhoneDialog';

export const useShowWhatsappBuyPhoneDialog = () => {
  const { loading, canPurchaseNewPhone } = useAdminPhones();

  const onWhatsappLogin = (modalProps: ShowWhatsappBuyPhoneDialogArgs) => {
    if (canPurchaseNewPhone) {
      showWhatsappBuyPhoneDialog(modalProps);
    } else {
      modalProps.onWhatsappLoginRequest();
    }
  };

  return {
    onWhatsappLogin,
    loading,
  };
};

import React from 'react';
import { Modal } from '@services/index';
import { BillingInfoDialog } from '../components/PremiumBillingInfo/BillingInfoDialog';
import {
  useUpdateWorkspacesBillingInfo,
  useWorkspacesBillingInfo,
} from '@utils/Data/Pricing/hooks';
import { getBillingInfo } from './utils';

export const useBillingInfoDialog = () => {
  const [workspacesBillingInfo, workspacesBillingInfoLoading] =
    useWorkspacesBillingInfo();
  const [updateWorkspacesBillingInfo, { loading: updating }] =
    useUpdateWorkspacesBillingInfo();

  return () => {
    Modal.show(
      ({ close }) => (
        <BillingInfoDialog
          loading={workspacesBillingInfoLoading || updating}
          billingDetails={getBillingInfo(workspacesBillingInfo)}
          onClose={close}
          onSubmit={(values) => {
            updateWorkspacesBillingInfo({
              variables: {
                details: { id: workspacesBillingInfo?.id, ...values },
              },
            });
            close();
          }}
        />
      ),
      { mobileAdaptive: true },
    );
  };
};

import React, { useMemo } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  ComboboxWithTriangleButton,
  defaultItemToString,
} from '@ui/SimpleCombobox';
import { SortTypesIds } from '../helpers';

interface SortTypeDropdownProps {
  value: SortTypesIds;
  onChange: (sortType: SortTypesIds) => void;
}

export const SortTypeDropdown: React.FC<SortTypeDropdownProps> = ({
  onChange,
  value,
}) => {
  const { t } = useSafeTranslation();
  const sortTypes = useMemo(
    () => [
      {
        id: SortTypesIds.planMostUsed,
        title: t('BotList.botsSortTypes.planMostUsed'),
      },
      {
        id: SortTypesIds.planLeastUsed,
        title: t('BotList.botsSortTypes.planLeastUsed'),
      },
      {
        id: SortTypesIds.nameAZ,
        title: t('BotList.botsSortTypes.nameAZ'),
      },
      {
        id: SortTypesIds.nameZA,
        title: t('BotList.botsSortTypes.nameZA'),
      },
      {
        id: SortTypesIds.lastOpened,
        title: t('BotList.botsSortTypes.lastOpened'),
      },
      {
        id: SortTypesIds.dateBuilt,
        title: t('BotList.botsSortTypes.dateBuilt'),
      },
    ],
    [t],
  );

  const currentSortTypeItem = useMemo(
    () => sortTypes.find(({ id }) => value === id),
    [value, sortTypes],
  );

  return (
    <ComboboxWithTriangleButton
      intent="secondary"
      items={sortTypes}
      selectedItem={currentSortTypeItem}
      onChange={({ id }) => {
        onChange(id);
      }}
      itemToString={defaultItemToString}
      data-testid="workspaces_bot_list__sort_type_dropdown"
    />
  );
};

import { useQuery } from '@apollo/react-hooks';
import { INTRO_QUESTIONS_CONFIG_QUERY } from './queries';
import {
  IntroQuestionsConfigQuery,
  IntroQuestionsConfigQuery_introQuestionsConfig_variables,
} from './@types/IntroQuestionsConfigQuery';
import { shuffle } from '@utils/ArrayUtils';
import { useMemo } from 'react';
import { showSomethingWentWrongToaster } from '@services/MessageService';
import { log } from 'cf-common/src/logger';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useShopifyIntroQuestionsOverrides } from '@pages/ShopifyChooseBot/useShopifyIntroQuestionsOverrides';

export const useIntroQuestionsConfig = () => {
  const { i18n } = useSafeTranslation();
  const { getShopifyIntroQuestionsSchemaType } =
    useShopifyIntroQuestionsOverrides();
  const { data, ...queryState } = useQuery<IntroQuestionsConfigQuery>(
    INTRO_QUESTIONS_CONFIG_QUERY,
    {
      variables: {
        locale: i18n.language,
        type: getShopifyIntroQuestionsSchemaType(),
      },
      onError: (error) => {
        log.warn({ error, msg: 'Error load intro questions config' });
        showSomethingWentWrongToaster();
      },
    },
  );
  const rawQuestionsConfig = data?.introQuestionsConfig.variables;
  const questionsConfig = useMemo<
    IntroQuestionsConfigQuery_introQuestionsConfig_variables[] | undefined
  >(
    () =>
      rawQuestionsConfig?.map((question) => {
        return {
          ...question,
          options: question.random
            ? shuffle(question.options)
            : question.options,
        };
      }),
    [rawQuestionsConfig],
  );
  const schemeId = data?.introQuestionsConfig.id;
  return {
    schemeId,
    questionsConfig,
    ...queryState,
  };
};

import React, { useEffect, useState } from 'react';
import { Flex } from '@ui/Flex';
import cn from 'classnames';
import * as css from './PickOption.css';
import { ButtonUnstyled } from '@ui/Button';
import { PhoneOption } from '../../types';
import { ReactComponent as NeedSvg } from './images/need.svg';
import { ReactComponent as HaveSvg } from './images/have.svg';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Spacer } from '@ui/Spacer';
import { SingleButtonFooter } from '../common/SingleButtonFooter';
import { useAdminFeatures } from '@utils/Data/Admin';
import { useAdminPhones } from '../../hooks/useAdminPhones';
import { CenteredLoader } from '@ui/Loader';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { sendEvent } from 'cf-common/src/analytics';
import { sendEventToGA } from '@utils/Analytics';
import { connectWaPhoneEvent } from 'cf-common/src/conversionTracking';
import { useCurrentBotId } from '@utils/Routing';

interface PickOptionProps {
  onDone: (option: PhoneOption, needFBAuth: boolean) => void;
}

export const PickOption: React.FC<PickOptionProps> = ({ onDone }) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId();
  const [phoneOption, setPhoneOption] = useState<PhoneOption | undefined>();
  const { adminFeatures } = useAdminFeatures();
  const { phones, loading, canPurchaseNewPhone, noFBToken } = useAdminPhones();
  const { getValueDueToSize, isSmallScreenSize } = useDeviceMedia();

  useEffect(() => {
    if (phones?.length && phoneOption !== PhoneOption.have) {
      setPhoneOption(PhoneOption.have);
    }
  }, [phoneOption, phones]);

  if (loading) {
    return <CenteredLoader />;
  }

  return (
    <>
      <Flex
        className={cn(isSmallScreenSize && css.mobileBox)}
        gapFactor={3}
        flexDirection={getValueDueToSize('column', 'row')}
      >
        <ButtonUnstyled
          className={cn(
            css.button,
            isSmallScreenSize && css.mobile,
            phoneOption === PhoneOption.have && css.active,
          )}
          onClick={() => {
            sendEvent({
              category: 'purchase phone number',
              label: 'pick option',
              action: 'have button click',
            });
            setPhoneOption(PhoneOption.have);
          }}
          data-testid="purchase-phone-number_pick-option_have-button"
        >
          <HaveSvg className={css.img} />
          <Spacer factor={3} />
          <Flex gapFactor={2}>
            <div
              className={cn(
                css.round,
                phoneOption === PhoneOption.have && css.active,
              )}
            />
            <div>
              <Type weight="medium">
                {t(
                  'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.pickOption.have.title',
                )}
              </Type>
              <Spacer factor={1} />
              <Type size="12px">
                {t(
                  'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.pickOption.have.text',
                )}
                <ul className={css.ul}>
                  <li>
                    {t(
                      'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.pickOption.have.li1',
                    )}
                  </li>
                  <li>
                    {t(
                      'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.pickOption.have.li2',
                    )}
                  </li>
                </ul>
              </Type>
            </div>
          </Flex>
        </ButtonUnstyled>
        <ButtonUnstyled
          data-testid="purchase-phone-number_pick-option_need-button"
          className={cn(
            css.button,
            isSmallScreenSize && css.mobile,
            phoneOption === PhoneOption.need && css.active,
          )}
          onClick={() => {
            if (phones?.length) {
              return;
            }
            sendEvent({
              category: 'purchase phone number',
              label: 'pick option',
              action: 'need button click',
            });
            setPhoneOption(PhoneOption.need);
          }}
          disabled={!canPurchaseNewPhone}
        >
          <NeedSvg className={css.img} />
          <Spacer factor={3} />
          <Flex gapFactor={2}>
            <div
              className={cn(
                css.round,
                phoneOption === PhoneOption.need && css.active,
              )}
            />
            <div>
              <Type weight="medium">
                {t(
                  'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.pickOption.need.title',
                )}
              </Type>
              <Spacer factor={1} />
              <Type size="12px">
                {t(
                  adminFeatures?.get_a_new_number_text === 1
                    ? 'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.pickOption.need.text1'
                    : 'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.pickOption.need.text2',
                )}
              </Type>
            </div>
          </Flex>
        </ButtonUnstyled>
      </Flex>
      <SingleButtonFooter
        buttonI18nKey="pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.pickOption.button"
        onButtonClick={() => {
          if (!phoneOption) {
            return;
          }
          sendEvent({
            category: 'purchase phone number',
            label: 'pick option',
            action: 'next button click',
            propertyBag: {
              phoneOption,
            },
          });
          sendEventToGA({
            type: 'purchase phone number',
            name: 'next button click',
            label: 'pick option',
          });
          onDone(phoneOption, noFBToken);
          connectWaPhoneEvent(botId!);
        }}
        buttonDisabled={!phoneOption}
      />
    </>
  );
};

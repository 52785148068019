import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Button } from '@ui/Button';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import {
  SearchType,
  useFilterableSearch,
} from '@components/FilterableSearchField';
import { EntryPointStatus, FlowsFilter } from '../FilterableSearch/types';
import * as css from './EmptyListPlaceholder.css';
import { Translation } from '@translations';

interface DescriptionPlaceholderProps extends TestLocator {
  title: string;
  description: string;
  buttonTitle?: string;
  onClick?(): void;
}

const DescriptionPlaceholder: React.FC<DescriptionPlaceholderProps> = ({
  title,
  description,
  buttonTitle,
  onClick,
  ...props
}) => (
  <Flex
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    className={css.container}
    data-testid={props['data-testid']}
  >
    <Type weight="medium" size="18px" align="center">
      {title}
    </Type>
    <Spacer factor={2} />
    <Type align="center" as="div" size="15px_DEPRECATED">
      {description}
    </Type>
    <Spacer />
    {buttonTitle && (
      <Button className={css.button} onClick={onClick}>
        {buttonTitle}
      </Button>
    )}
  </Flex>
);

interface EmptyListPlaceholderProps {
  error: boolean;
  refetch?(): void;
}

const getEmptyListPlaceholderI18nKey = (key: string) =>
  `pages.BotPage.FlowTab.FlowList.EmptyListPlaceholder.${key}` as Translation;

export const EmptyListPlaceholder: React.FC<EmptyListPlaceholderProps> = ({
  error,
  refetch,
}) => {
  const { type, value } = useFilterableSearch<FlowsFilter>();
  const { t } = useSafeTranslation();

  if (error) {
    return (
      <DescriptionPlaceholder
        title={t(getEmptyListPlaceholderI18nKey('flows.title'))}
        description={t(getEmptyListPlaceholderI18nKey('flows.description'))}
        buttonTitle={t(getEmptyListPlaceholderI18nKey('flows.buttonText'))}
        onClick={refetch}
      />
    );
  }

  // eslint-disable-next-line default-case
  switch (type) {
    case SearchType.search:
      return (
        <DescriptionPlaceholder
          data-testid="flow-list__no-flows"
          title={t(getEmptyListPlaceholderI18nKey('search.title'))}
          description={t(getEmptyListPlaceholderI18nKey('search.description'))}
        />
      );
    case FlowsFilter.entryPointStatus:
      // eslint-disable-next-line default-case
      switch (value) {
        case EntryPointStatus.ACTIVE:
          return (
            <DescriptionPlaceholder
              title={t(
                getEmptyListPlaceholderI18nKey('epActiveStatusFilter.title'),
              )}
              description={t(
                getEmptyListPlaceholderI18nKey(
                  'epActiveStatusFilter.description',
                ),
              )}
            />
          );
        case EntryPointStatus.INACTIVE:
          return (
            <DescriptionPlaceholder
              title={t(
                getEmptyListPlaceholderI18nKey('epInactiveStatusFilter.title'),
              )}
              description={t(
                getEmptyListPlaceholderI18nKey(
                  'epInactiveStatusFilter.description',
                ),
              )}
            />
          );
      }
      break;
  }

  return null;
};

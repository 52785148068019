import React, { forwardRef } from 'react';
import cn from 'classnames';
import {
  BroadcastStatus,
  TriggeredMessageType,
  WhatsappBroadcastType,
} from '@globals';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { IconNames } from '@ui/Icon/icons';
import { Tooltip2 } from '@ui/Tooltip2';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import Maybe from 'graphql/tsutils/Maybe';
import { Schedule } from '../../../type';
import { DateFormat } from '@utils/DateTime';
import css from './MainInformation.css';
import { weekdayIdsFromValue } from '@pages/BotPage/WhatsappReEngageTab/utils';
import { getWeekdaysShort } from '../../../constants';
import { Weekdays } from '@utils/DateTime/types';
import { isSomeEnum } from '@utils/isSomeEnum';
import i18next from 'i18next';
import { Translation } from '@translations';
import { ButtonUnstyled } from '@ui/Button';
import {
  fromTimestampInSeconds,
  getUtcOffset,
} from '@utils/DateTime/DateUtils';
import { TextEllipsis } from '@ui/TextEllipsis';

interface MainInformationProps {
  title: string;
  type: WhatsappBroadcastType;
  status: BroadcastStatus;
  disabled: boolean;
  schedule: Maybe<Schedule>;
  triggerType: Maybe<TriggeredMessageType>;
  onOpenBroadcast: VoidFunction;
  statusUpdatedAt: Maybe<number>;
}

const weekdaysShort = () =>
  getWeekdaysShort().reduce((value, item) => {
    // eslint-disable-next-line no-param-reassign
    value[item.id] = item.name;
    return value;
  }, {} as { [key in Weekdays]: string });

const getRecurringWhatsappText = (schedule: Maybe<Schedule>) => {
  if (!schedule) return '';
  if (schedule.daysOfWeek)
    return `${weekdayIdsFromValue(schedule.daysOfWeek)
      .map((id) => (isSomeEnum(Weekdays, id) ? weekdaysShort()[id] : ''))
      .join(', ')} ${i18next.t(
      'pages.BotPage.ReengageWhatsappTab.shortDescriptions.recurringTime',
      {
        time: DateFormat.HHmm(
          fromTimestampInSeconds(schedule.startingTimestamp ?? 0).valueOf(),
        ),
      },
    )}`;
  if (schedule.dayOfMonth)
    return i18next.t<string, Translation>(
      'pages.BotPage.ReengageWhatsappTab.monthly',
    );
  return '';
};

export const BroadcastTypeIcon = forwardRef<
  HTMLDivElement,
  { icon: IconNames }
>(({ icon, ...toolTipBind }, ref) => (
  <Flex
    className={css.iconContainer}
    alignItems="center"
    justifyContent="center"
    ref={ref}
    data-testid={`whatsapp-reengage__reengage-type-icon_${icon}`}
    {...toolTipBind}
  >
    <Icon icon={icon} />
  </Flex>
));

const BaseMainInformation: React.FC<{
  title: string;
  description?: {
    icon: IconNames;
    iconDescription: string;
    text: string;
  };
  onOpenBroadcast: VoidFunction;
  disabled: boolean;
}> = ({ title, description, onOpenBroadcast, disabled, children }) => (
  <Flex flexDirection="column" className={css.container} gapFactor={1}>
    <ButtonUnstyled
      onClick={onOpenBroadcast}
      className={cn(css.titleButton, disabled && css.disabled)}
      disabled={disabled}
      title={title}
      data-testid="whatsapp-reengage__reengage-title"
    >
      <Type weight="medium" className={css.ellipsis}>
        {title}
      </Type>
    </ButtonUnstyled>
    {description && (
      <Flex gapFactor={1} alignItems="center">
        <Tooltip2
          content={description.iconDescription}
          placement="right"
          type="small"
        >
          {(ref, toolTipBind) => (
            <BroadcastTypeIcon
              ref={ref}
              {...toolTipBind}
              icon={description.icon}
            />
          )}
        </Tooltip2>
        <TextEllipsis
          title={description.text}
          data-testid="whatsapp-reengage__reengage-description"
        >
          <Type color="greyDark">{description.text}</Type>
        </TextEllipsis>
      </Flex>
    )}
    {children}
  </Flex>
);

export const MainInformation: React.FC<MainInformationProps> = ({
  title,
  type,
  status,
  schedule,
  triggerType,
  statusUpdatedAt,
  onOpenBroadcast,
  disabled,
  children,
}) => {
  const { t } = useSafeTranslation();

  if (status === BroadcastStatus.DRAFT)
    return (
      <BaseMainInformation
        title={title}
        onOpenBroadcast={onOpenBroadcast}
        disabled={disabled}
      >
        {children}
      </BaseMainInformation>
    );

  return {
    [WhatsappBroadcastType.ONE_TIME]: schedule ? (
      <BaseMainInformation
        onOpenBroadcast={onOpenBroadcast}
        disabled={disabled}
        title={title}
        description={{
          icon: 'calendar',
          iconDescription: t(
            'pages.BotPage.ReengageWhatsappTab.shortDescriptions.scheduled',
          ),
          text: schedule.startingTimestamp
            ? t(
                'pages.BotPage.ReengageWhatsappTab.shortDescriptions.scheduledOn',
                {
                  date: DateFormat.DDMMMHHmm(
                    fromTimestampInSeconds(
                      schedule.startingTimestamp ?? 0,
                    ).valueOf(),
                  ),
                  offset: getUtcOffset(),
                },
              )
            : null,
        }}
      >
        {children}
      </BaseMainInformation>
    ) : (
      <BaseMainInformation
        onOpenBroadcast={onOpenBroadcast}
        disabled={disabled}
        title={title}
        description={{
          icon: 'send',
          iconDescription: t(
            'pages.BotPage.ReengageWhatsappTab.shortDescriptions.oneTime',
          ),
          text: statusUpdatedAt
            ? t('pages.BotPage.ReengageWhatsappTab.shortDescriptions.sentOn', {
                date: DateFormat.DDMMMHHmm(statusUpdatedAt),
                offset: getUtcOffset(),
              })
            : null,
        }}
      >
        {children}
      </BaseMainInformation>
    ),
    [WhatsappBroadcastType.TRIGGER]: (
      <BaseMainInformation
        onOpenBroadcast={onOpenBroadcast}
        disabled={disabled}
        title={title}
        description={{
          icon: 'connectBlock',
          iconDescription: t(
            'pages.BotPage.ReengageWhatsappTab.shortDescriptions.triggered',
          ),
          text: triggerType
            ? t(`components.broadcast.controller.triggerType.${triggerType}`)
            : null,
        }}
      >
        {children}
      </BaseMainInformation>
    ),
    [WhatsappBroadcastType.WEEKLY]: (
      <BaseMainInformation
        onOpenBroadcast={onOpenBroadcast}
        disabled={disabled}
        title={title}
        description={{
          icon: 'bellPlus',
          iconDescription: t(
            'pages.BotPage.ReengageWhatsappTab.shortDescriptions.recurring',
          ),
          text: getRecurringWhatsappText(schedule),
        }}
      >
        {children}
      </BaseMainInformation>
    ),
    get [WhatsappBroadcastType.MONTHLY]() {
      return this[WhatsappBroadcastType.WEEKLY];
    },
  }[type];
};

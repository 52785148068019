import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import {
  getTierNameByType,
  TierTypeEmoji,
} from '@components/DialogsPricing/constants';
import { useBillingData } from '../../BotSectionView/Data/useBillingData';
import * as css from './PlanBadge.css';
import { useBotProStatus } from '@utils/Data/Bot';
import { TierType } from '@globals';
import { useShopifyAccount } from '@utils/Integrations/Shopify/useShopifyAccount';
import { useSafeTranslation } from '@utils/useSafeTranslation';

interface PlanBadgeProps {
  className?: string;
}

export const PlanBadge: React.FC<PlanBadgeProps> = ({ className }) => {
  const { t } = useSafeTranslation();
  const { pricing } = useBillingData();
  const { botIsManualPro, loading } = useBotProStatus();
  const { hasShopifyAccount, loading: accountLoading } = useShopifyAccount();

  if (loading || accountLoading) {
    return null;
  }

  const tier = botIsManualPro ? TierType.manual : pricing?.current_tier || null;
  const tierText = hasShopifyAccount
    ? t('pages.Pricing.tier.business')
    : getTierNameByType(tier);

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      className={cn(css.badge, className)}
      data-testid="bot-panel__plan-badge"
    >
      <TierTypeEmoji type={tier} />
      <Spacer horizontalFactor={1} />
      <Type size="15px" color="white">
        {tierText}
      </Type>
    </Flex>
  );
};

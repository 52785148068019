import React, { useRef, useState } from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import * as css from './AddAttributesDropdown.css';
import { Button } from '@ui/Button';
import useOnClickOutside from 'use-onclickoutside';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useContactAttributes } from '../useContactAttributes';
import { Contact } from '../useContactQuery';
import { EditAttributeName } from '@pages/BotPage/ContactPage/EditAttributeName/EditAttributeName';
import { sendEvent } from '@utils/Analytics';
import { EditAttributeValue } from '@pages/BotPage/ContactPage/EditAttributeValue/EditAttributeValue';

interface AddAttributesDropdownProps {
  contact: Contact;
}

export const AddAttributesDropdown: React.FC<AddAttributesDropdownProps> = ({
  contact,
}) => {
  const { t } = useSafeTranslation();
  const [show, setShow] = useState<boolean>(false);
  const { createOrUpdateAttribute } = useContactAttributes();
  const {
    values,
    handleSubmit,
    setFieldValue,
    touched,
    errors,
    resetForm,
    isSubmitting,
  } = useFormik({
    initialValues: {
      name: '',
      value: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required()
        .notOneOf(contact.customAttributes.map((ca) => ca.name)),
      value: Yup.string().required(),
    }),
    onSubmit: ({ name, value }, { resetForm: submitResetForm }) => {
      return createOrUpdateAttribute({ name, value }).then(() => {
        sendEvent({
          category: 'contact profile',
          action: 'add',
          label: 'custom attribute',
          propertyBag: {
            contactId: contact.id,
            tab: 'people tab',
          },
        });
        setShow(false);
        submitResetForm();
      });
    },
  });
  const ref = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(ref, () => {
    if (show) {
      resetForm();
    }
    setShow(false);
  });
  return (
    <div ref={ref} data-testid="user-card_add-attributes-button">
      <Manager>
        <Reference>
          {(bind) => (
            <Button
              {...bind}
              intent="secondary"
              onClick={() => {
                sendEvent({
                  category: 'contact profile',
                  action: 'click',
                  label: 'add attribute',
                  propertyBag: {
                    contactId: contact.id,
                    tab: 'people tab',
                  },
                });
                setShow(!show);
              }}
              icon={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  // eslint-disable-next-line chatfuel/no-use-hex-colors
                  fill="#208EF0"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.2222 5C6.78578 5 4 7.78578 4 11.2222C4 14.6587 6.78578 17.4444 10.2222 17.4444H13.7778C17.2142 17.4444 20 14.6587 20 11.2222C20 7.78578 17.2142 5 13.7778 5H10.2222ZM8.13845 11.1502V11.3064C8.92839 11.3932 9.37543 11.7925 9.37543 12.4219V13.3854C9.37543 13.954 9.56207 14.158 10.0742 14.158H10.2739V15.2431H9.90929C8.48568 15.2431 7.90408 14.7396 7.90408 13.5243V12.7951C7.90408 12.1875 7.60026 11.9444 6.84071 11.9314V10.5252C7.59592 10.5165 7.90408 10.2691 7.90408 9.66146V8.93229C7.90408 7.73438 8.48134 7.24826 9.90929 7.24826H10.2739V8.33333H10.0742C9.55773 8.33333 9.37543 8.53733 9.37543 9.1059V10.0347C9.37543 10.6641 8.92839 11.0634 8.13845 11.1502ZM16.2578 11.3368V11.1806C15.4679 11.0938 15.0208 10.6944 15.0208 10.0651V9.10156C15.0208 8.53299 14.8342 8.32899 14.322 8.32899H14.1224V7.24826H14.487C15.9106 7.24826 16.4922 7.7474 16.4922 8.96267V9.69184C16.4922 10.2995 16.7917 10.5469 17.5556 10.5556V11.9618C16.796 11.9705 16.4922 12.2179 16.4922 12.8299V13.5547C16.4922 14.7526 15.9149 15.2431 14.487 15.2431H14.1224V14.1536H14.322C14.8342 14.1536 15.0208 13.9497 15.0208 13.3811V12.4523C15.0208 11.8229 15.4679 11.428 16.2578 11.3368Z" />
                </svg>
              }
            >
              {t('PeoplePage.addAttribute')}
            </Button>
          )}
        </Reference>
        {show && (
          <Popper
            placement="bottom-end"
            modifiers={{
              offset: {
                offset: '0, 4',
              },
            }}
            positionFixed
          >
            {({ style, ref }) => (
              <form
                ata-testid="add-new-attributes"
                style={style}
                ref={ref}
                onSubmit={handleSubmit}
                className={css.form}
              >
                <EditAttributeName
                  platform={contact?.platform}
                  value={values.name}
                  onChange={(name) => setFieldValue('name', name)}
                  excludedAttributeNames={contact.systemAttributes.map(
                    (ca) => ca.name,
                  )}
                  inputProps={{
                    placeholder: t('PeoplePage.attribute'),
                    name: 'name',
                    error: touched.name && !!errors.name,
                  }}
                />
                <EditAttributeValue
                  variable={values.name}
                  value={values.value}
                  onChange={(name) => setFieldValue('value', name)}
                  inputProps={{
                    placeholder: t('PeoplePage.value'),
                    name: 'value',
                    error: touched.value && !!errors.value,
                  }}
                />
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  data-testid="add-attributes_add-button"
                >
                  {t('PeoplePage.add')}
                </Button>
              </form>
            )}
          </Popper>
        )}
      </Manager>
    </div>
  );
};

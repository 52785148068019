import React from 'react';
import { ComboboxWithTriangleButton } from '@ui/SimpleCombobox';
import { MenuItem } from '@ui/Menu';
import * as css from '../UsageTable.css';

interface DatePickerRowProps {
  selectedYear?: number;
  onYearChange(year: number): void;
  cols: number;
  years?: number[];
}

const mapYear = (year: number) => ({
  id: year.toString(),
  title: year.toString(),
  year,
});

export const DatePickerRow = React.memo<DatePickerRowProps>(
  ({ onYearChange, selectedYear, cols, years }) => (
    <tr>
      <th>
        {years && selectedYear ? (
          <ComboboxWithTriangleButton
            className={css.yearView}
            items={years.map(mapYear)}
            onChange={(item) => {
              onYearChange(item.year);
            }}
            buttonChildren={() => selectedYear}
            autoSelectFirstItem
            data-testid="premium_billing_usage__date_picker"
          >
            {({
              downshift: { getItemProps, highlightedIndex },
              props: { item, index },
            }) => (
              <MenuItem
                title={item.title}
                active={index === highlightedIndex}
                key={item.id}
                {...getItemProps({ item })}
              />
            )}
          </ComboboxWithTriangleButton>
        ) : (
          <div className={css.yearViewStub} />
        )}
      </th>
      <th className={css.stubCell} colSpan={cols} />
    </tr>
  ),
);

import React from 'react';
import cn from 'classnames';
import { LS, LSKey } from 'cf-common/src/localStorage';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  TimeIntervalsIds,
  TimeoutInputByTimestamp,
  TimeoutInputOnChange,
} from '@ui/TimeoutInput';
import { Flex } from '@ui/Flex';
import { Disableable } from '../types';
import * as commonCSS from './common.css';
import * as css from './SendInControl.css';

const ALLOWED_TIME_INTERVALS_IDS = [
  TimeIntervalsIds.minutes,
  TimeIntervalsIds.hours,
  TimeIntervalsIds.days,
];

const getAllowedTimeIntervals = () =>
  LS.get(LSKey.waBroadcastsTriggerIntervals)
    ? [TimeIntervalsIds.seconds, ...ALLOWED_TIME_INTERVALS_IDS]
    : ALLOWED_TIME_INTERVALS_IDS;

export interface SendInControlProps extends Disableable {
  sendInValue: number;
  onSendInValueChange: TimeoutInputOnChange;
}

export const SendInControl: React.FC<SendInControlProps> = ({
  disabled,
  sendInValue,
  onSendInValueChange,
  children,
}) => {
  const { t } = useSafeTranslation();

  return (
    <PluginControlLabel
      label={t('pages.BotPage.ReengageWhatsappTab.panel.sendIn.label')}
      light
    >
      {() => (
        <Flex alignItems="center">
          <TimeoutInputByTimestamp
            disabled={disabled}
            maxLength={3}
            value={sendInValue}
            allowedTimeIntervalsIds={getAllowedTimeIntervals()}
            onChange={onSendInValueChange}
            className={css.wrapper}
            inputClassName={commonCSS.dropdown}
            containerClassName={css.container}
            timeIntervalClassName={cn(commonCSS.dropdown, css.timeInterval)}
            timeIntervalWrapperClassName={css.wrapper}
            data-testid="reengage-editor__send-in-time"
          />
          {children}
        </Flex>
      )}
    </PluginControlLabel>
  );
};

import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { PlanNextChargeDetails } from '../PlanNextChargeDetails';
import { PlanBadge } from './PlanBadge';
import { PlanTillDate } from './PlanTillDate';
import * as css from './PlanSummary.css';

interface PlanSummaryProps {
  className?: string;
}

export const PlanSummary: React.FC<PlanSummaryProps> = ({ className }) => (
  <Flex
    justifyContent="space-between"
    alignItems="center"
    className={cn(css.mobileWrapper, className)}
  >
    <Flex alignItems="center" className={css.mobileWrapper}>
      <PlanBadge className={css.planBadge} />

      <PlanNextChargeDetails />
    </Flex>

    <PlanTillDate />
  </Flex>
);

import React from 'react';
import { Input } from '@ui/Input';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import { InputLikeElement, insertText } from '@utils/documentExecCommand';
import { useExtendedFormAction } from '../../../form/ExtendFormActionsContext';
import { ControlDecoratorProps } from '@ui/Input/ControlDecorator';
import { useSafeTranslation } from '@utils/useSafeTranslation';

export const BUTTON_TEXT_LENGTH = 25;

export interface ButtonTextInputViewProps extends TestLocator {
  error?: ControlDecoratorProps['error'];
  disabled?: boolean;
  buttonText: string;
  onButtonTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ButtonTextInputView: React.FC<ButtonTextInputViewProps> = ({
  disabled,
  error,
  buttonText,
  onButtonTextChange,
  'data-testid': dataTestId,
}) => {
  const { t } = useSafeTranslation();
  const { saveForm } = useExtendedFormAction();

  return (
    <TextOutsideControls
      onInsertRequest={(text, el) => {
        insertText(text, el as InputLikeElement);
      }}
      currentTextLimit={BUTTON_TEXT_LENGTH - buttonText.trim().length}
      shouldShowOutsideControls={{ symbolsLimit: true }}
      initialShow={false}
      tight
    >
      {({ ref, getInputProps }) => (
        <Input
          error={error}
          placeholder={t('pages.MessagesTemplates.message.nameYourButton')}
          data-testid={dataTestId}
          value={buttonText}
          disabled={disabled}
          onChange={onButtonTextChange}
          maxLength={BUTTON_TEXT_LENGTH}
          {...getInputProps({ onBlur: () => saveForm() } as any)}
          ref={ref}
        />
      )}
    </TextOutsideControls>
  );
};

import React from 'react';
import { BroadcastStatus } from '@globals';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { TypeTranslation } from '@ui/Type';
import cn from 'classnames';
import css from './Status.css';

interface StatusProps {
  status: BroadcastStatus;
}

const componenets = {
  [BroadcastStatus.DRAFT]: (
    <Flex className={cn(css.status, css.draft)}>
      <TypeTranslation
        noColor
        className={css.text}
        i18nKey="pages.BotPage.ReengageWhatsappTab.status.draft"
      />
    </Flex>
  ),
  [BroadcastStatus.LIVE]: (
    <Flex className={cn(css.status, css.live)}>
      <TypeTranslation
        noColor
        className={css.text}
        i18nKey="pages.BotPage.ReengageWhatsappTab.status.live"
      />
      <div className={css.circle} />
    </Flex>
  ),
  [BroadcastStatus.PAUSED]: (
    <Flex className={cn(css.status, css.paused)}>
      <TypeTranslation
        noColor
        className={css.text}
        i18nKey="pages.BotPage.ReengageWhatsappTab.status.paused"
      />
      <Icon icon="pause" size="20px" noColor />
    </Flex>
  ),
  [BroadcastStatus.SCHEDULED]: (
    <Flex className={cn(css.status, css.scheduled)}>
      <TypeTranslation
        noColor
        className={css.text}
        i18nKey="pages.BotPage.ReengageWhatsappTab.status.scheduled"
      />
    </Flex>
  ),
  [BroadcastStatus.SENT]: (
    <Flex className={cn(css.status, css.sent)}>
      <TypeTranslation
        noColor
        className={css.text}
        i18nKey="pages.BotPage.ReengageWhatsappTab.status.sent"
      />
    </Flex>
  ),
};

export const Status: React.FC<StatusProps> = ({ status }) =>
  componenets[status];

import React from 'react';
import cn from 'classnames';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import Maybe from 'graphql/tsutils/Maybe';
import { Flex } from '@ui/Flex';
import { VisuallyDisabled } from '@ui/VisuallyDisabled';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { StatItem } from './StatItem';
import * as css from './AIStatisticsView.css';
import { Platform } from '@globals';
import { sendHomeTabEvent } from '../../utils';
import { SectionBox } from '@components/SectionBox';

interface AIStatisticsViewProps {
  title: string;
  icon: string;
  replied: Maybe<number>;
  unrecognized: Maybe<number>;
  linkTo: string;
  platform: Platform;
  disabled?: boolean;
}

export const AIStatisticsView: React.FC<AIStatisticsViewProps> = ({
  title,
  icon,
  disabled,
  replied,
  unrecognized,
  linkTo,
  platform,
}) => {
  const { t } = useSafeTranslation();

  return (
    <SectionBox data-testid={`ai-statistics__${platform}`}>
      <VisuallyDisabled disable={Boolean(disabled)}>
        <Flex alignItems="center">
          <Icon icon={icon} size="32px" />
          <Spacer horizontalFactor={3} />
          <Type size="24px" weight="semibold">
            {title}
          </Type>
        </Flex>

        <Spacer horizontalFactor={6} />

        <Flex justifyContent="space-between">
          <StatItem
            linkTo={linkTo}
            caption={t('pages.GrowPage.AIStatistics.keywordGroups')}
            value={replied}
            className={css.statistics}
            data-testid={`ai-statistics__${platform}-keywords`}
            onClick={() =>
              sendHomeTabEvent({ action: 'keyword group statistics click' })
            }
          />

          <Spacer />

          <StatItem
            linkTo={linkTo}
            className={cn(css.right, css.statistics)}
            caption={t('pages.GrowPage.AIStatistics.unrecognized')}
            value={unrecognized}
            data-testid={`ai-statistics__${platform}-unrecognized`}
            onClick={() =>
              sendHomeTabEvent({ action: 'unrecognized statistics click' })
            }
          />
        </Flex>
      </VisuallyDisabled>
    </SectionBox>
  );
};

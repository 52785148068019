import React from 'react';
import {
  ManagedWhatsappAttachmentType,
  MessagesTemplateHeaderType,
} from '@globals';
import { Button } from '@ui/Button';
import { useExtendedFormAction } from '../../form/ExtendFormActionsContext';
import { HeaderParameterMediaType } from '@components/FlowBuilder/EditorPanel/components/plugins/WhatsappTemplatePlugin/components/HeaderMedia/types';
import { HeaderMediaFileSelector } from '@components/FlowBuilder/EditorPanel/components/plugins/WhatsappTemplatePlugin/components/HeaderMedia/components/HeaderMediaFileSelector';
import { FileAttachmentType } from '@utils/UploadService/types';
import { HeaderMediaFileSelectorProps } from '@components/FlowBuilder/EditorPanel/components/plugins/WhatsappTemplatePlugin/components/HeaderMedia/components/HeaderMediaFileSelector/HeaderMediaFileSelector';
import { MessagesTemplateHeaderText } from './MessagesTemplateHeaderText';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import { VisuallyDisabled } from '@ui/VisuallyDisabled';
import { logEvent } from '../../utils';

const FROM_ATTACHMENT_TO_MEDIA_TYPE = {
  [ManagedWhatsappAttachmentType.document]: HeaderParameterMediaType.document,
  [ManagedWhatsappAttachmentType.image]: HeaderParameterMediaType.image,
  [ManagedWhatsappAttachmentType.video]: HeaderParameterMediaType.video,
};

const FILE_TO_TYPE = {
  [FileAttachmentType.image]: ManagedWhatsappAttachmentType.image,
  [FileAttachmentType.mp4]: ManagedWhatsappAttachmentType.video,
  [FileAttachmentType.pdf]: ManagedWhatsappAttachmentType.document,
};

const UPLOAD_FILE_QUERY_PARAMS = {
  resumable: JSON.stringify(true),
};

export const MessageHeader = () => {
  const { t } = useSafeTranslation();
  const {
    values,
    setAttachment,
    setHeaderText,
    clearHeader,
    formDisabled,
    saveForm,
  } = useExtendedFormAction();
  const { attachment, type } = values.content.header;

  const onChange: HeaderMediaFileSelectorProps['onChange'] = (
    { filename, link },
    filetype,
  ) => {
    const newValues = !filetype
      ? clearHeader()
      : setAttachment({
          // @ts-expect-error
          type: FILE_TO_TYPE[filetype],
          url: link!,
          filename: filename!,
        });

    saveForm(newValues);
  };

  const value = attachment
    ? {
        link: attachment.url!,
        filename: attachment.filename,
      }
    : null;

  if (type === MessagesTemplateHeaderType.text) {
    return <MessagesTemplateHeaderText />;
  }

  return (
    <HeaderMediaFileSelector
      value={value}
      type={FROM_ATTACHMENT_TO_MEDIA_TYPE[attachment?.type!]}
      onChange={onChange}
      disabled={formDisabled}
      showFormatErrorInit={false}
      queryParams={UPLOAD_FILE_QUERY_PARAMS}
    >
      {({ chooseFile }) => (
        <Flex gapFactor={2}>
          <Button
            disabled={formDisabled}
            intent="secondary"
            onClick={() => {
              logEvent({
                action: 'add header item',
                propertyBag: { item_type: 'text' },
              });
              saveForm(setHeaderText(''));
            }}
            data-testid="messages-template-message__header-text-button"
          >
            <VisuallyDisabled disable={formDisabled}>
              <Flex alignItems="center">
                <Icon color="baseSub" icon="text" />
                <Type color="baseSub">
                  {t('pages.MessagesTemplates.message.headerType.text')}
                </Type>
              </Flex>
            </VisuallyDisabled>
          </Button>

          <Button
            disabled={formDisabled}
            intent="secondary"
            onClick={() => {
              logEvent({
                action: 'add header item',
                propertyBag: { item_type: 'image' },
              });
              chooseFile(FileAttachmentType.image);
            }}
            data-testid="messages-template-message__header-image-button"
          >
            <VisuallyDisabled disable={formDisabled}>
              <Flex alignItems="center">
                <Icon color="baseSub" icon="image" />
                <Type color="baseSub">
                  {t('pages.MessagesTemplates.message.headerType.image')}
                </Type>
              </Flex>
            </VisuallyDisabled>
          </Button>

          <Button
            disabled={formDisabled}
            intent="secondary"
            onClick={() => {
              logEvent({
                action: 'add header item',
                propertyBag: { item_type: 'video' },
              });
              chooseFile(FileAttachmentType.mp4);
            }}
            data-testid="messages-template-message__header-video-button"
          >
            <VisuallyDisabled disable={formDisabled}>
              <Flex alignItems="center">
                <Icon color="baseSub" icon="videoFill" />
                <Type color="baseSub">
                  {t('pages.MessagesTemplates.message.headerType.video')}
                </Type>
              </Flex>
            </VisuallyDisabled>
          </Button>

          <Button
            disabled={formDisabled}
            intent="secondary"
            onClick={() => {
              logEvent({
                action: 'add header item',
                propertyBag: { item_type: 'pdf' },
              });
              chooseFile(FileAttachmentType.pdf);
            }}
            data-testid="messages-template-message__header-pdf-button"
          >
            <VisuallyDisabled disable={formDisabled}>
              <Flex alignItems="center">
                <Icon color="baseSub" icon="clip" />
                <Type color="baseSub">
                  {t('pages.MessagesTemplates.message.headerType.pdf')}
                </Type>
              </Flex>
            </VisuallyDisabled>
          </Button>
        </Flex>
      )}
    </HeaderMediaFileSelector>
  );
};

import React, { useCallback, useState } from 'react';
import * as css from './MessagesTemplatesTableEmptyStateSlider.css';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { ButtonUnstyled } from '@ui/Button';
import cn from 'classnames';
import { SwiperSliderWrapper } from '@ui/SwiperSliderWrapper/SwiperSliderWrapper';
import SwiperInterface from 'swiper/types/swiper-class';
import range from 'lodash-es/range';
import { Translation } from '@translations';
import { MessageSlide1 } from './sliders/MessageSlide1';
import { MessageSlide2 } from './sliders/MessageSlide2';
import { MessageSlide3 } from './sliders/MessageSlide3';
import { SwiperSlide } from 'swiper/react';

const DOT_ITEMS = range(0, 3);

export const MessagesTemplatesTableEmptyStateSlider: React.FC = () => {
  const { t } = useSafeTranslation();
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface | null>(
    null,
  );
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  const setSlide = useCallback(
    (n: number) => () => {
      swiperInstance?.slideTo(n);
    },
    [swiperInstance],
  );

  return (
    <div>
      <div className={css.sliderHeader}>
        <Type size="18px" weight="semibold">
          {t(
            `pages.MessagesTemplates.Table.emptyState.slider.item${
              activeSlideIndex + 1
            }.title` as Translation,
          )}
        </Type>
        <Spacer factor={2} />
        <Flex gapFactor={5}>
          {DOT_ITEMS.map((n) => (
            <ButtonUnstyled
              key={n}
              onClick={setSlide(n)}
              className={cn(css.dot, n === activeSlideIndex && css.active)}
            />
          ))}
        </Flex>
      </div>
      <SwiperSliderWrapper
        init
        spaceBetween={8}
        slidesPerView={1}
        loop={false}
        onActiveIndexChange={({ activeIndex }) => {
          setActiveSlideIndex(activeIndex);
        }}
        onSwiper={setSwiperInstance}
        autoplay={{
          delay: 3000,
          stopOnLastSlide: true,
          pauseOnMouseEnter: false,
        }}
      >
        <SwiperSlide>
          <MessageSlide1 />
        </SwiperSlide>
        <SwiperSlide>
          <MessageSlide2 />
        </SwiperSlide>
        <SwiperSlide>
          <MessageSlide3 />
        </SwiperSlide>
      </SwiperSliderWrapper>
    </div>
  );
};

import React, { useMemo, useState } from 'react';
import { descend, prop, sortWith, add } from 'ramda';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './FrequentUnrecognizedMessages.css';
import { FrequentUnrecognizedMessagesQuery_bot_aiPhraseGroups } from './@types/FrequentUnrecognizedMessagesQuery';
import { FrequentUnrecognizedMessagesItem } from './components/FrequentUnrecognizedMessagesItem';
import { useFrequentUnrecognizedMessages } from './hooks/useFrequentUnrecognizedMessages';
import { Icon } from '@ui/Icon';
import { Tooltip2 } from '@ui/Tooltip2';
import { isMobileDevice } from 'cf-common/src/utils/isMobileDevice';
import { sendEvent } from '@utils/Analytics';
import { Platform } from '@globals';

interface FrequentUnrecognizedMessagesProps {
  platform: Platform;
}

const sortByCount =
  sortWith<FrequentUnrecognizedMessagesQuery_bot_aiPhraseGroups>([
    descend(prop('count')),
  ]);

const ITEMS_DEFAULT_LIMIT = 5;
const ITEMS_LIMIT_INC_STEP = 5;
const itemsLimitInc = add(ITEMS_LIMIT_INC_STEP);
const itemsLimitSub = add(-ITEMS_LIMIT_INC_STEP);

export const FrequentUnrecognizedMessages: React.FC<FrequentUnrecognizedMessagesProps> =
  ({ platform }) => {
    const [itemsLimit, setItemsLimit] = useState<number>(ITEMS_DEFAULT_LIMIT);
    const { t } = useSafeTranslation();
    const { aiPhraseGroups, loading } = useFrequentUnrecognizedMessages(
      platform,
      'network-only',
    );

    const phraseGroups = useMemo(
      () => sortByCount(aiPhraseGroups || []),
      [aiPhraseGroups],
    );

    const phraseGroupsCurrent = useMemo(
      () => phraseGroups.slice(0, itemsLimit),
      [itemsLimit, phraseGroups],
    );

    if (loading || !phraseGroupsCurrent?.length) {
      return null;
    }

    return (
      <>
        <div
          className={css.box}
          data-testid="keywords__frequent-unrecognized-messages_component"
        >
          <div className={css.innerBox}>
            <Flex justifyContent="space-between" alignItems="center">
              <div
                style={{
                  width: 360,
                }}
              >
                <Type size="24px" weight="semibold">
                  {t('pages.Keywords.FrequentUnrecognizedMessages.title')}
                </Type>
              </div>
              <Tooltip2
                content={t('pages.Keywords.FrequentUnrecognizedMessages.info')}
                placement={isMobileDevice() ? 'top' : 'right'}
              >
                {(ref, bind) => (
                  <Icon
                    size="20px"
                    className={css.infoIcon}
                    icon="question"
                    ref={ref}
                    {...bind}
                  />
                )}
              </Tooltip2>
            </Flex>
            <Spacer factor={8} />
            <div className={css.layout}>
              {!loading &&
                phraseGroupsCurrent.map((group, index) => (
                  <FrequentUnrecognizedMessagesItem
                    key={group.phrase}
                    platform={platform}
                    showDivider={phraseGroupsCurrent.length - 1 > index}
                    {...group}
                  />
                ))}
            </div>
            <Spacer factor={5} />
            {phraseGroups.length > ITEMS_DEFAULT_LIMIT && (
              <Flex justifyContent="flex-end">
                <Button
                  onClick={() => {
                    sendEvent({
                      category: 'keywords',
                      label: 'frequent unrecognized messages',
                      action: 'show more click',
                    });
                    setItemsLimit(itemsLimitInc);
                  }}
                  intent="secondary"
                  disabled={itemsLimit >= phraseGroups.length}
                  data-testid="keywords__frequent-unrecognized-messages__show-more-button"
                >
                  {t('pages.Keywords.FrequentUnrecognizedMessages.showMore')}
                </Button>
                {itemsLimit > ITEMS_DEFAULT_LIMIT && (
                  <>
                    <Spacer factor={1} horizontalFactor={4} />
                    <Button
                      onClick={() => {
                        setItemsLimit(itemsLimitSub);
                        sendEvent({
                          category: 'keywords',
                          label: 'frequent unrecognized messages',
                          action: 'show less click',
                        });
                      }}
                      intent="secondary"
                      data-testid="keywords__frequent-unrecognized-messages__show-less-button"
                    >
                      {t(
                        'pages.Keywords.FrequentUnrecognizedMessages.showLess',
                      )}
                    </Button>
                  </>
                )}
              </Flex>
            )}
          </div>
        </div>
        <Spacer factor={7} />
      </>
    );
  };

import { useCallback } from 'react';
import { useMutation } from 'react-apollo';
import {
  CreateWhatsappBroadcast,
  CreateWhatsappBroadcastVariables,
} from './@types/CreateWhatsappBroadcast';
import {
  CREATE_WHATSAPP_BROADCAST_MUTATION,
  WHATSAPP_BROADCASTS_QUERY,
} from './queries';
import { defaultErrorHandler } from '../utils';
import { mapQuery } from '@utils/GQL/mapQuery';
import {
  WhatsappBroadcasts,
  WhatsappBroadcastsVariables,
  WhatsappBroadcasts_whatsappBroadcastList_broadcasts as Broadcast,
} from './@types/WhatsappBroadcasts';
import { useCurrentBotId } from '@utils/Routing';

export const useCreateBroadcast = () => {
  const botId = useCurrentBotId()!;
  const [createBroadcastMutation, queryResult] = useMutation<
    CreateWhatsappBroadcast,
    CreateWhatsappBroadcastVariables
  >(CREATE_WHATSAPP_BROADCAST_MUTATION, {
    update(proxy, mutationResult) {
      if (!mutationResult.data) return;

      mapQuery<WhatsappBroadcasts, WhatsappBroadcastsVariables>(
        proxy,
        {
          query: WHATSAPP_BROADCASTS_QUERY,
          variables: { botId, status: null, type: null },
        },
        (data) => {
          if (!data.whatsappBroadcastList) return data;

          const broadcast: Broadcast = {
            statusUpdatedAt: null,
            stats: null,
            ...mutationResult.data!.createWhatsappBroadcast,
          };

          return {
            whatsappBroadcastList: {
              ...data.whatsappBroadcastList,
              broadcasts: [broadcast, ...data.whatsappBroadcastList.broadcasts],
            },
          };
        },
      );
    },
  });

  const createBroadcast = useCallback(
    (variables: CreateWhatsappBroadcastVariables) => {
      return createBroadcastMutation({ variables }).catch(
        defaultErrorHandler({
          msg: 'Could not create broadcast',
          data: variables,
        }),
      );
    },
    [createBroadcastMutation],
  );

  return [createBroadcast, queryResult] as const;
};

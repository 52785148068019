import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { useCallback } from 'react';
import {
  FLOW_GROUPS_QUERY,
  FLOW_ITEM_QUERY,
  FLOWS_QUERY,
} from '@utils/Data/Flow';
import {
  FlowGroupsQuery,
  FlowGroupsQueryVariables,
} from '@utils/Data/Flow/@types/FlowGroupsQuery';
import { useCurrentBotId } from '@utils/Routing';
import { FlowSharingInput } from '@globals';
import {
  ChangeFlowGroupMutation,
  ChangeFlowGroupMutationVariables,
} from './@types/ChangeFlowGroupMutation';
import {
  RemoveFlowGroupMutation,
  RemoveFlowGroupMutationVariables,
} from './@types/RemoveFlowGroupMutation';
import {
  CloneFlowGroupMutation,
  CloneFlowGroupMutationVariables,
} from './@types/CloneFlowGroupMutation';
import {
  UpdateFlowGroupSharingMutation,
  UpdateFlowGroupSharingMutationVariables,
} from './@types/UpdateFlowGroupSharingMutation';

const CHANGE_FLOW_GROUP_MUTATION = gql`
  mutation ChangeFlowGroupMutation($flowGroup: FlowGroupInput!) {
    updateFlowGroup(flowGroup: $flowGroup) {
      id
      title
      collapsed
    }
  }
`;

const REMOVE_FLOW_GROUP_MUTATION = gql`
  mutation RemoveFlowGroupMutation($groupId: ID!) {
    removeFlowGroup(groupId: $groupId) {
      success
    }
  }
`;

const CLONE_FLOW_GROUP_MUTATION = gql`
  mutation CloneFlowGroupMutation($flowGroupId: ID!, $botId: ID!) {
    cloneFlowGroup(groupId: $flowGroupId, botId: $botId) {
      id
      flow_ids
    }
  }
`;

const UPDATE_FLOW_GROUP_SHARING_MUTATION = gql`
  mutation UpdateFlowGroupSharingMutation(
    $flowGroupId: ID!
    $sharingParams: FlowSharingInput!
  ) {
    updateFlowGroupSharing(
      groupId: $flowGroupId
      sharingParams: $sharingParams
    ) {
      id
      sharing_params {
        allow_cloning
        sharing_enabled
      }
    }
  }
`;

interface flowGroupUpdateProps {
  groupId: string;
  onCompleted?: (data: ChangeFlowGroupMutation) => void;
  onError?: () => void;
}

export const useFlowGroupUpdate = ({
  groupId,
  onCompleted,
  onError,
}: flowGroupUpdateProps) => {
  const botId = useCurrentBotId();
  const [updateFlowGroup, { loading }] = useMutation<
    ChangeFlowGroupMutation,
    ChangeFlowGroupMutationVariables
  >(CHANGE_FLOW_GROUP_MUTATION, {
    refetchQueries: [{ query: FLOW_GROUPS_QUERY, variables: { botId } }],
    onCompleted,
    onError,
  });

  const onUpdateFlowGroup = useCallback(
    (title: string, collapsed: boolean) => {
      updateFlowGroup({
        variables: {
          flowGroup: { id: groupId, title, collapsed },
        },
      });
    },
    [updateFlowGroup, groupId],
  );

  return { onUpdateFlowGroup, loading };
};

interface UpdateCollapsedFlowGroupProps {
  groupId: string;
  onCompleted?: (data?: ChangeFlowGroupMutation) => void;
  onError?: () => void;
}

export const useUpdateCollapsedFlowGroup = ({
  groupId,
  onCompleted,
  onError,
}: UpdateCollapsedFlowGroupProps) => {
  const botId = useCurrentBotId();
  const [updateCollapsedFlowGroup, { loading }] = useMutation<
    ChangeFlowGroupMutation,
    ChangeFlowGroupMutationVariables
  >(CHANGE_FLOW_GROUP_MUTATION, {
    onCompleted,
    onError,
  });

  const onUpdateCollapsedFlowGroup = useCallback(
    (collapsed: boolean) => {
      updateCollapsedFlowGroup({
        variables: {
          flowGroup: { id: groupId, collapsed },
        },
        update: (cache) => {
          const flowGroupsData = cache.readQuery<
            FlowGroupsQuery,
            FlowGroupsQueryVariables
          >({
            query: FLOW_GROUPS_QUERY,
            variables: { botId: botId || '' },
          });
          if (flowGroupsData?.bot) {
            flowGroupsData.bot.flow_groups =
              flowGroupsData.bot.flow_groups?.map((group) => {
                if (group.id === groupId) {
                  return { ...group, collapsed };
                }
                return group;
              }) || [];
            cache.writeQuery({
              query: FLOW_GROUPS_QUERY,
              variables: { botId: botId || '' },
              data: flowGroupsData,
            });
          }
        },
      });
    },
    [updateCollapsedFlowGroup, groupId, botId],
  );

  return { onUpdateCollapsedFlowGroup, loading };
};

interface RemoveFlowGroupProps {
  groupId: string;
  onCompleted?: (data?: RemoveFlowGroupMutation) => void;
  onError?: () => void;
}

export const useRemoveFlowGroup = ({
  groupId,
  onCompleted,
  onError,
}: RemoveFlowGroupProps) => {
  const botId = useCurrentBotId();
  const [removeFlowGroup, { loading }] = useMutation<
    RemoveFlowGroupMutation,
    RemoveFlowGroupMutationVariables
  >(REMOVE_FLOW_GROUP_MUTATION, {
    refetchQueries: [{ query: FLOW_GROUPS_QUERY, variables: { botId } }],
    awaitRefetchQueries: true,
    onCompleted,
    onError,
  });

  const onRemoveFlowGroup = useCallback(() => {
    if (botId) {
      removeFlowGroup({ variables: { groupId } });
    }
  }, [botId, removeFlowGroup, groupId]);

  return { onRemoveFlowGroup, loading };
};

interface CloneFlowGroupProps {
  botId?: string | undefined;
  groupId?: string | undefined;
  onCompleted?: (data: CloneFlowGroupMutation) => void;
  onError?: (error: ApolloError) => void;
}

export const useCloneFlowGroup = ({
  botId,
  groupId,
  onCompleted,
  onError,
}: CloneFlowGroupProps) => {
  const [cloneFlowGroupMutation, { loading, called, error }] = useMutation<
    CloneFlowGroupMutation,
    CloneFlowGroupMutationVariables
  >(CLONE_FLOW_GROUP_MUTATION, {
    refetchQueries: [
      { query: FLOW_GROUPS_QUERY, variables: { botId } },
      { query: FLOWS_QUERY, variables: { botId } },
    ],
    awaitRefetchQueries: true,
    onCompleted,
    onError,
  });

  const cloneFlowGroup = useCallback(
    (targetBotId: string, targetGroupId?: string) => {
      const flowGroupId = groupId ?? targetGroupId;
      if (flowGroupId) {
        cloneFlowGroupMutation({
          variables: {
            flowGroupId,
            botId: targetBotId,
          },
        });
      }
    },
    [groupId, cloneFlowGroupMutation],
  );

  return { cloneFlowGroup, loading, called, error };
};

interface FlowGroupUpdateSharingProps {
  groupId?: string;
  botId?: string;
  flowIds?: string[];
  onCompleted?(data?: UpdateFlowGroupSharingMutation): void;
  onError?(): void;
}

interface UpdateFlowGroupSharingProps {
  sharingParams: FlowSharingInput;
}

export const useFlowGroupSharingUpdate = ({
  groupId,
  botId,
  flowIds = [],
  onCompleted,
  onError,
}: FlowGroupUpdateSharingProps) => {
  const [updateFlowGroupSharingMutation, { loading, error }] = useMutation<
    UpdateFlowGroupSharingMutation,
    UpdateFlowGroupSharingMutationVariables
  >(UPDATE_FLOW_GROUP_SHARING_MUTATION, {
    onCompleted,
    onError,
  });

  const updateFlowGroupSharing = useCallback(
    ({ sharingParams }: UpdateFlowGroupSharingProps) => {
      if (groupId) {
        updateFlowGroupSharingMutation({
          variables: {
            flowGroupId: groupId,
            sharingParams,
          },
          optimisticResponse: {
            updateFlowGroupSharing: {
              __typename: 'FlowGroup',
              id: groupId,
              sharing_params: {
                __typename: 'FlowSharing',
                sharing_enabled: !!sharingParams.sharing_enabled,
                allow_cloning: !!sharingParams.allow_cloning,
              },
            },
          },
          refetchQueries: botId
            ? flowIds.map((flowId) => ({
                query: FLOW_ITEM_QUERY,
                variables: { botId, flowId },
              }))
            : undefined,
        });
      }
    },
    [botId, flowIds, groupId, updateFlowGroupSharingMutation],
  );

  return { updateFlowGroupSharing, loading, error };
};

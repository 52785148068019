import React, { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '@utils/queryParams';
import {
  usePremiumPricingInfo,
  usePremiumPricingPaymentList,
  useWorkspacesBillingInfo,
} from '@utils/Data/Pricing/hooks';
import { getBillingAddress, getBillingCard } from './utils';
import { useUpdatePremiumPaymentDialog } from '@components/DialogsPricing/hooks';
import { useBillingInfoDialog } from './useBillingInfoDialog';
import { useResetMetaForMobile } from '@utils/css/useResetMetaForMobile';
import { BillingLayout } from '../components/BillingLayout/BillingLayout';
import { usePremiumBillingColumns } from './usePremiumBillingColumns';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import css from './PremiumBillingPage.css';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { PaymentHistoryItem } from '../Table/types';
import {
  calculateHeight,
  chargingEntityTypeToPaymentEntityType,
  getNextCharge,
  invoicesTableAdapter,
} from '../Table/utils';

export const PremiumBillingContainer: React.FC<{}> = () => {
  useResetMetaForMobile();
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();
  const { data: pricing, loading: pricingLoading } = usePremiumPricingInfo();
  const {
    current_tier,
    next_tier,
    current_tier_limit,
    reached_limit,
    next_prepayment_date,
    charging_entity_type,
    status,
    subscriptionId,
  } = pricing ?? {};

  const [paymentsList, paymentsListLoading] =
    usePremiumPricingPaymentList(subscriptionId);
  const [workspacesBillingInfo, workspacesBillingInfoLoading] =
    useWorkspacesBillingInfo();

  const showBillingInfoDialog = useBillingInfoDialog();
  const showPaymentUpdateDialog = useUpdatePremiumPaymentDialog();
  const history = useHistory();
  const { update } = useQueryParams<{ update?: string }>();

  useEffect(() => {
    if (update) {
      showPaymentUpdateDialog();
      history.replace('/premium-billing?view=invoices');
    }
  }, [update, history, showPaymentUpdateDialog]);

  const columns = usePremiumBillingColumns(subscriptionId);

  const items = invoicesTableAdapter(paymentsList || []);

  const nextCharge = useMemo(
    () => getNextCharge(pricing, paymentsList || []),
    [pricing, paymentsList],
  );

  const paymentEntityType = chargingEntityTypeToPaymentEntityType(
    charging_entity_type || null,
  );

  const loading = paymentsListLoading || pricingLoading;

  return (
    <BillingLayout<PaymentHistoryItem>
      isPaused={false}
      planBlockLoading={pricingLoading}
      currentTier={current_tier}
      nextTier={next_tier}
      conversations={{
        limit: current_tier_limit ?? 0,
        reached: reached_limit ?? 0,
      }}
      isPremium
      nextPrepaymentDate={next_prepayment_date}
      paymentEntityType={paymentEntityType}
      status={status}
      billingCard={getBillingCard(workspacesBillingInfo)}
      onUpdatePaymentMethod={() => showPaymentUpdateDialog()}
      billingAdress={getBillingAddress(workspacesBillingInfo)}
      onUpdateBillingAdress={showBillingInfoDialog}
      billingInfoLoading={workspacesBillingInfoLoading}
      invoices={{
        items,
        columns,
        columnClassName: css.invoiceColumn,
        headers: [
          t('pages.Billing.invoices.date'),
          t('pages.Billing.invoices.description'),
          t('pages.Billing.invoices.total'),
          '',
        ],
        itemHeight: (index) =>
          calculateHeight(
            (items[index]?.parts || []).length,
            isSmallScreenSize,
          ),
      }}
      nextCharge={nextCharge}
      nextChargeParts={nextCharge?.parts.length ?? 0}
      invoicesLoading={loading}
    />
  );
};

import { TType } from '@utils/useSafeTranslation';
import { WhatsappBroadcastConfig } from '../types';
import { IBroadcastType, BroadcastTypes } from './types';

export class OneTimeBroadcastType implements IBroadcastType {
  public readonly type = BroadcastTypes.oneTime;
  public readonly title: string;
  public readonly buttonTitle: string;

  constructor(public readonly t: TType) {
    this.title = t('pages.BotPage.ReengageWhatsappTab.broadcasts.sendNow');
    this.buttonTitle = t(
      'pages.BotPage.ReengageWhatsappTab.panel.buttons.send',
    );
  }

  public getDefaultConfig(
    config: WhatsappBroadcastConfig,
  ): WhatsappBroadcastConfig {
    return {
      ...config,
      userFilter: null,
      schedule: null,
      triggerSettings: null,
    };
  }

  public isCurrentBroadcast(config: WhatsappBroadcastConfig): boolean {
    return !config.triggerSettings && !config.schedule;
  }
}

import { useCurrentBotId } from '@utils/Routing';
import { useMutation } from 'react-apollo';
import {
  ReregisterWhatsappMutation,
  ReregisterWhatsappMutationVariables,
} from './@types/ReregisterWhatsappMutation';
import { REREGISTER_WHATSAPP_MUTATION } from './queries';
import { showSomethingWentWrongToaster } from '@services/MessageService';

export const useReregisterWhatsapp = () => {
  const botId = useCurrentBotId()!;
  const [reregisterWhatsapp, state] = useMutation<
    ReregisterWhatsappMutation,
    ReregisterWhatsappMutationVariables
  >(REREGISTER_WHATSAPP_MUTATION, {
    variables: { botId },
    onError: () => {
      showSomethingWentWrongToaster();
    },
  });

  return {
    reregisterWhatsapp,
    ...state,
  };
};

import React from 'react';
import {
  IntroQuestionsBlockQuestion,
  IntroQuestionsBlockQuestionProps,
} from '../IntroQuestionsBlockQuestion';
import { IntroQuestionsQuestionProps } from '../../types';
import { Spacer } from '@ui/Spacer';
import { Input } from '@ui/Input/Input';

interface IntroQuestionsBlockQuestionTextInputProps
  extends IntroQuestionsBlockQuestionProps,
    Omit<IntroQuestionsQuestionProps, 'showErrorState'> {
  onChange: (value: string) => void;
}

export const IntroQuestionsBlockQuestionTextInput: React.FC<IntroQuestionsBlockQuestionTextInputProps> =
  ({ id, title, required, onChange, disabled, 'data-testid': dataTestid }) => {
    return (
      <IntroQuestionsBlockQuestion
        title={title}
        required={required}
        data-testid={dataTestid}
      >
        <Input
          disabled={disabled}
          name={id}
          onChange={e => onChange(e.target.value)}
        />
        <Spacer factor={8} />
      </IntroQuestionsBlockQuestion>
    );
  };

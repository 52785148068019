import React from 'react';
import { useFirstSessionStepState } from '@pages/BotPage/KeywordsPage/FirstSessionPage/components/common/useFirstSessionStepState';
import { Step } from '@pages/BotPage/KeywordsPage/FirstSessionPage/types';
import { PlansPaywall } from '@components/PlansPaywall';
import { PaywallStatus } from '@globals';

interface PlansPaywallStepProps extends Step {
  status: PaywallStatus;
}

export const PlansPaywallStep: React.FC<PlansPaywallStepProps> = ({
  status,
  done,
  onDone,
  current,
  enabled,
}) => {
  const { stepDoneHandler } = useFirstSessionStepState({
    done,
    onDone,
    current,
    enabled,
  });

  return <PlansPaywall status={status} onDone={stepDoneHandler} />;
};

import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { MESSAGES_TEMPLATE_FRAGMENT } from './messageTemplateFragments';
import {
  GetMessageTemplates,
  GetMessageTemplatesVariables,
} from './@types/GetMessageTemplates';
import { useCurrentBotId } from '@utils/Routing';
import { WatchQueryFetchPolicy } from 'apollo-client';

interface GetMessageTemplatesArgs {
  fetchPolicy?: WatchQueryFetchPolicy;
  skip?: boolean;
}

export const GET_MESSAGE_TEMPLATES_QUERY = gql`
  query GetMessageTemplates($botId: MongoObjectId!) {
    messageTemplates(botId: $botId) {
      ...messagesTemplateFragment
    }
  }

  ${MESSAGES_TEMPLATE_FRAGMENT}
`;

export const useGetMessageTemplates = (args?: GetMessageTemplatesArgs) => {
  const botId = useCurrentBotId()!;
  const { data, ...queryState } = useQuery<
    GetMessageTemplates,
    GetMessageTemplatesVariables
  >(GET_MESSAGE_TEMPLATES_QUERY, {
    variables: { botId },
    fetchPolicy: args?.fetchPolicy,
    skip: args?.skip,
  });

  return {
    templates: data?.messageTemplates,
    ...queryState,
  };
};

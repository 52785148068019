import gql from 'graphql-tag';

export const WHATSAPP_BROADCAST_FRAGMENT = gql`
  fragment whatsappBroadcastFragment on WhatsappBroadcast {
    id
    flowId
    status
    title
    type
    triggerSettings {
      waitInterval
      waitIntervalTimeUnit
      triggerType
      userFilter {
        operation
        parameters {
          type
          name
          operation
          values
        }
        valid
      }
      timeOfDay
      orderStatusUpdateStatus
    }
    startingDateTime
    schedule {
      startingTimestamp
      useBotTimeZone
      dayOfMonth
      daysOfWeek
    }
    updatedAt
  }
`;

export const WHATSAPP_BROADCAST_QUERY = gql`
  query WhatsappBroadcast($botId: String!, $broadcastId: String!) {
    whatsappBroadcast(botId: $botId, broadcastId: $broadcastId) {
      userFilter {
        operation
        parameters {
          type
          name
          operation
          values
        }
        valid
      }
      ...whatsappBroadcastFragment
    }
  }

  ${WHATSAPP_BROADCAST_FRAGMENT}
`;

export const WHATSAPP_BROADCASTS_QUERY = gql`
  query WhatsappBroadcasts(
    $botId: String!
    $status: BroadcastStatus
    $type: WhatsappBroadcastType
    $limit: Int
    $before: String
  ) {
    whatsappBroadcastList(
      botId: $botId
      status: $status
      type: $type
      limit: $limit
      before: $before
    ) {
      broadcasts {
        stats {
          totalUsers
          successUsers
          errorUsers
          readUsers
          clickedUsers
        }
        ...whatsappBroadcastFragment
        statusUpdatedAt
      }
      nextBefore
    }
  }
  ${WHATSAPP_BROADCAST_FRAGMENT}
`;

export const CREATE_WHATSAPP_BROADCAST_MUTATION = gql`
  mutation CreateWhatsappBroadcast(
    $botId: String!
    $templateId: String
    $managedTemplateId: String
  ) {
    createWhatsappBroadcast(
      botId: $botId
      templateId: $templateId
      managedTemplateId: $managedTemplateId
    ) {
      ...whatsappBroadcastFragment
    }
  }
  ${WHATSAPP_BROADCAST_FRAGMENT}
`;

export const UPDATE_WHATSAPP_BROADCAST_MUTATION = gql`
  mutation UpdateWhatsappBroadcast(
    $botId: String!
    $broadcastId: String!
    $broadcast: UpdateWhatsappBroadcastInput!
  ) {
    updateWhatsappBroadcast(
      botId: $botId
      broadcastId: $broadcastId
      broadcast: $broadcast
    ) {
      id
    }
  }
`;

export const UPDATE_WHATSAPP_BROADCAST_STATUS_MUTATION = gql`
  mutation UpdateWhatsappStatusBroadcast(
    $botId: String!
    $broadcastId: String!
    $status: LiveCycleBroadcastStatus!
  ) {
    updateWhatsappBroadcastStatus(
      botId: $botId
      broadcastId: $broadcastId
      status: $status
    ) {
      ...whatsappBroadcastFragment
    }
  }

  ${WHATSAPP_BROADCAST_FRAGMENT}
`;

export const DELETE_WHATSAPP_BROADCAST_MUTATION = gql`
  mutation DeleteWhatsappBroadcast($botId: String!, $broadcastId: String!) {
    deleteWhatsappBroadcast(botId: $botId, broadcastId: $broadcastId)
  }
`;

export const FLOW_BLOCKS_VALIDATION_QUERY = gql`
  query FlowBlocksValidationQuery($botId: String!, $flowId: MongoObjectId!) {
    bot(id: $botId) {
      id
      flowBlocks(id: $flowId) {
        id
        subtype
        cards {
          id
          plugin_id
          validation_details {
            fields {
              field
              error
            }
          }
        }
      }
    }
  }
`;

export const USER_COUNT_BY_FILTER_QUERY = gql`
  query UserCountByFilterQuery(
    $botId: String!
    $withInstagram: Boolean!
    $segmentation: SegmentationInput
  ) {
    bot(id: $botId) {
      id
      userCountByFilter(
        withInstagram: $withInstagram
        segmentation: $segmentation
      )
    }
  }
`;

export const MOBILE_WIDTH = 700;

export enum OldFirstSessionStep {
  zero = 0,
  first = 1,
  second = 2,
  third = 3,
  done = 4,
  intentsAndTest = 'intentsAndTest',
}

export enum FirstSessionStep {
  introQuestions = 'introQuestions',
  waInitialSetup = 'waInitialSetup',
  waDiscounts = 'waDiscounts',
  activateBot = 'activateBot',
  productTour = 'productTour',
  paywall = 'paywall',
  done = 'done',
}

import React from 'react';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import { FormContainer } from '../FormContainer';
import { useExtendedFormAction } from '../../form/ExtendFormActionsContext';
import { CategoryDropdown } from './CategoryDropdown';
import { NameInput } from './NameInput';
import { LanguageDropdown } from './LanguageDropdown';
import * as css from './MessagesTemplateSettings.css';

export const MessagesTemplateSettings = () => {
  const { t } = useSafeTranslation();
  const { values } = useExtendedFormAction();

  return (
    <div className={css.wrapper}>
      <FormContainer>
        <Type as="h1" weight="medium">
          {t('pages.MessagesTemplates.settings.title')}
        </Type>

        <PluginControlLabel
          spacerFactor={2}
          light
          label={t('pages.MessagesTemplates.settings.category')}
        >
          {() => <CategoryDropdown />}
        </PluginControlLabel>

        {values.category && (
          <>
            <PluginControlLabel
              spacerFactor={2}
              light
              label={t('pages.MessagesTemplates.settings.name')}
            >
              {() => <NameInput />}
            </PluginControlLabel>

            <PluginControlLabel
              spacerFactor={2}
              light
              label={t('pages.MessagesTemplates.settings.language')}
            >
              {() => <LanguageDropdown />}
            </PluginControlLabel>
          </>
        )}
      </FormContainer>
    </div>
  );
};

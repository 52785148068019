import * as ng from 'angular';
import * as React from 'react';
import { angular2react } from 'angular2react';
import controller from '../../../components/users/controller';

import template from '../../../components/users/users.html';
import { lazyInjector } from '../../../utils/angularLazyInjector';
import { useReadyToRender } from '../../../utils/AngularRouting';
import { BotTabAngular } from '../../../modern-components/BotTab';
import UsersTable from '../../../components/users/users-table';
import SegmentsList from '../../../components/users/segments-list';
import SegmentsNameEditing from '../../../components/users/segment-name-editing';
import { MiniOnbordingTab } from '@components/MiniOnboarding/MiniOnbordingTab';
import { BotTabs } from '@utils/Routing';

const peopleTabWrapper = {
  template: () => template,
  controller,
  controllerAs: 'vm',
  restrict: 'E',
  replace: true,
  bindings: {},
};

export const ngPeopleTabWrapper = ng
  .module('app.pages.peopleTab', [
    UsersTable,
    SegmentsList,
    SegmentsNameEditing,
  ])
  .component('peopleTabWrapper', peopleTabWrapper).name;

const PeopleTabWrapperFunc = () => {
  return angular2react<{}>(
    'peopleTabWrapper',
    peopleTabWrapper,
    lazyInjector.$injector as any,
  );
};

const PeopleTabWrapper = PeopleTabWrapperFunc();

export const PeopleTab: React.FC = () => {
  const readyToRender = useReadyToRender('common');

  if (!readyToRender) {
    return <div />;
  }

  return (
    <MiniOnbordingTab page={BotTabs.people}>
      <BotTabAngular tabName="users-tab" rcol>
        <PeopleTabWrapper />
      </BotTabAngular>
    </MiniOnbordingTab>
  );
};

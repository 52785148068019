import React from 'react';
import cn from 'classnames';
import { ButtonProps } from '@ui/Button';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { GoToPlansButton } from './GoToPlansButton';
import { useBillingData } from '../Data/useBillingData';
import { PricingPlanType } from '../../../../../Plans/types';
import css from './TimeLimitTrialExpiredButton.css';
import { useTimeLimitedTrial } from '@utils/Data/Pricing/useTimeLimitedTrial';

export const TimeLimitTrialExpiredButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps
>((props, ref) => {
  const { trialExpired } = useTimeLimitedTrial();
  const { isLastTier } = useBillingData();
  const { t } = useSafeTranslation();

  return (
    <GoToPlansButton
      plan={isLastTier ? PricingPlanType.enterprise : PricingPlanType.business}
      ref={ref}
      intent={trialExpired ? 'red' : 'primary'}
      {...props}
      className={cn(css.button, props.className)}
    >
      {t('pages.Pricing.selectPlan')}
    </GoToPlansButton>
  );
});

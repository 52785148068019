import { useSafeTranslation } from '@utils/useSafeTranslation';
import React from 'react';
import { PaymentEntityType } from '../types';
import { DescriptionItemView, TitleProps } from './Primitives';

export interface ExtraPaymentProps extends TitleProps {
  payment: number;
  pricePerPayment: string;
  paymentEntityType: PaymentEntityType;
}

export const ExtraPayment: React.FC<ExtraPaymentProps> = ({
  payment,
  pricePerPayment,
  paymentEntityType,
  ...props
}) => {
  const { t } = useSafeTranslation();
  const subscription = `${payment} x ${pricePerPayment}`;

  const title = {
    [PaymentEntityType.dialogs]: t('pages.Billing.Table.extraConversations'),
    [PaymentEntityType.broadcasts]: t('pages.Billing.Table.extraBroadcast'),
    [PaymentEntityType.users]: '',
  }[paymentEntityType];

  return (
    <DescriptionItemView title={title} subscription={subscription} {...props} />
  );
};

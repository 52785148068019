import React from 'react';
import cn from 'classnames';
import { Box } from '@ui/Box';
import * as css from './HeaderBox.css';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { MOBILE_WIDTH } from '../../consts';
import { Flex } from '@ui/Flex';
import { useIsWindowWidthLess } from '@utils/DOM/useIsWindowWidthLess';
import { useAdminData } from '@utils/Data/Admin';
import { ReactComponent as UglyHandSvg } from './images/ugly_hand.svg';
import { ReactComponent as LinesSvg } from './images/lines.svg';
import { OriginalAccountType } from '@globals';

interface HeaderBoxProps {}

const HIDE_HAND_WIDTH = 780;

export const HeaderBox: React.FC<HeaderBoxProps> = () => {
  const { t } = useSafeTranslation();
  const isMobileWindowSize = useIsWindowWidthLess(MOBILE_WIDTH);
  const isHideLinesSize = useIsWindowWidthLess(HIDE_HAND_WIDTH);
  const { adminData, adminDataLoading } = useAdminData();

  if (adminDataLoading) {
    return null;
  }

  return (
    <Box borderRadiusFactor={3} className={css.box}>
      {!isHideLinesSize && <LinesSvg className={css.lines} />}
      <UglyHandSvg
        className={cn(css.uglyHand, isMobileWindowSize && css.uglyHandMobile)}
      />
      <Flex className={css.titleBox} justifyContent="space-between">
        <Type
          size={isMobileWindowSize ? '30px' : '44px'}
          color="white"
          weight="semibold"
          as="div"
          className={css.title}
        >
          {t(
            adminData?.me.original_account_type === OriginalAccountType.guest
              ? 'pages.FirstSession.HeaderBox.titleGuest'
              : 'pages.FirstSession.HeaderBox.title_v2',
            { name: adminData?.me.name?.split(' ')[0] },
          )}
        </Type>
      </Flex>
      <div className={css.subtitle}>
        <Type size={isMobileWindowSize ? '15px' : '18px'} color="white">
          {t('pages.FirstSession.HeaderBox.text1_v2')}
        </Type>
      </div>
    </Box>
  );
};

import { Flex } from '@ui/Flex';
import cn from 'classnames';
import React, { useState } from 'react';
import { Spacer } from '@ui/Spacer';
import * as css from './KeywordsPage.css';
import { PlatformSelector } from './components/PlatformSelector';
import { KeywordsSearchField } from './components/KeywordsSearchField';
import { FrequentUnrecognizedMessages } from './components/FrequentUnrecognizedMessages';
import { WelcomeMessage } from './components/WelcomeMessage';
import { KeywordsGroups } from './components/KeywordsGroups';
import { useRolePermission } from '@utils/Roles';
import { CenteredLoader } from '@ui/Loader';
import { useResetMetaForMobile } from '@utils/css/useResetMetaForMobile';
import { useHistory } from 'react-router-dom';
import {
  BotTabs,
  getTabLink,
  useCurrentBotId,
  useKeywordsTabParams,
} from '@utils/Routing';
import { Permission } from '@common/services/RoleService';
import { KeywordsPlacements } from './consts';
import { Platform } from '@globals';
import { testThisInChatfuelDismiss } from '@components/QuickAccessToolbar/TestThis/TestThisChatfuel/events';
import { useKeywordsPlatformRedirect } from './useKeywordsPlatformRedirect';

export const KeywordsPage: React.FC = () => {
  useResetMetaForMobile();
  const { platform } = useKeywordsTabParams();
  const history = useHistory();
  const [intentsFilterTerm, setIntentsFilterTerm] = useState<string>('');
  const botId = useCurrentBotId();
  const { allowed: viewAllowed, loading: permissionLoading } =
    useRolePermission({
      domain: 'ai',
      can: Permission.VIEW,
    });
  const { allowed: editAllowed } = useRolePermission({
    domain: 'ai',
    can: Permission.EDIT,
  });

  useKeywordsPlatformRedirect(platform, botId);

  if (!viewAllowed || !platform) {
    return null;
  }

  if (permissionLoading) {
    return <CenteredLoader className={css.loader} />;
  }

  return (
    <Flex justifyContent="center" className={css.page}>
      <div
        className={cn(css.content, { [css.viewOnly]: !editAllowed })}
        data-testid="keywords__page"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          className={css.head}
        >
          {platform !== Platform.whatsapp && (
            <PlatformSelector
              currentPlatform={platform}
              placement={KeywordsPlacements.keywords}
              onChange={(platform) => {
                testThisInChatfuelDismiss();
                history.push(getTabLink(BotTabs.keywords, botId, { platform }));
              }}
            />
          )}
          <KeywordsSearchField
            intentsFilterTerm={intentsFilterTerm}
            onIntentsFitlerTermChange={setIntentsFilterTerm}
            platform={platform}
          />
        </Flex>
        <Spacer factor={7} />
        <FrequentUnrecognizedMessages platform={platform} />
        <WelcomeMessage platform={platform} />
        <Spacer factor={8} />
        <KeywordsGroups
          intentsFilterTerm={intentsFilterTerm}
          platform={platform}
        />
        <Spacer factor={8} horizontalFactor={1} />
      </div>
    </Flex>
  );
};

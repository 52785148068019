import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import {
  DeleteMessageTemplate,
  DeleteMessageTemplateVariables,
} from './@types/DeleteMessageTemplate';
import { mapQuery } from '@utils/GQL/mapQuery';
import { GET_MESSAGE_TEMPLATES_QUERY } from './useGetMessageTemplates';
import {
  GetMessageTemplates,
  GetMessageTemplatesVariables,
} from './@types/GetMessageTemplates';
import { useCurrentBotId } from '@utils/Routing';
import { log } from 'cf-common/src/logger';
import { showSomethingWentWrongToaster } from '@services/MessageService';

export const DELETE_MESSAGE_TEMPLATE_MUTATION = gql`
  mutation DeleteMessageTemplate(
    $botId: MongoObjectId!
    $templateId: MongoObjectId!
  ) {
    deleteMessageTemplate(botId: $botId, templateId: $templateId)
  }
`;

export const useDeleteMessageTemplate = () => {
  const botId = useCurrentBotId()!;
  const [mutation, queryResult] = useMutation<
    DeleteMessageTemplate,
    DeleteMessageTemplateVariables
  >(DELETE_MESSAGE_TEMPLATE_MUTATION, {
    onError: (error) => {
      log.error({
        error,
        msg: 'delete whatsapp template error',
      });
      showSomethingWentWrongToaster();
    },
  });
  const deleteMessageTemplate = (variables: DeleteMessageTemplateVariables) =>
    mutation({
      variables,
      optimisticResponse: {
        deleteMessageTemplate: true,
      },
      update: (cache, mutationResult) => {
        if (!mutationResult.data?.deleteMessageTemplate) {
          return;
        }
        mapQuery<GetMessageTemplates, GetMessageTemplatesVariables>(
          cache,
          {
            query: GET_MESSAGE_TEMPLATES_QUERY,
            variables: {
              botId,
            },
          },
          (data) => {
            // eslint-disable-next-line no-param-reassign
            data.messageTemplates = data.messageTemplates.filter(
              ({ id }) => id !== variables.templateId,
            );
            return data;
          },
        );
      },
    });
  return [deleteMessageTemplate, queryResult] as const;
};

import { useBotStatusCalloutTexts } from './useBotStatusCalloutTexts';
import { BotStatusCalloutState } from '../consts';
import { useBotStatusCalloutState } from './useBotStatusCalloutState';
import { useCallback } from 'react';
import { useConnectPage } from '@components/ConnectPageDialog';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import { useEntryPointsModal } from '@components/EntryPointsModal';
import { getFlowControllerStrict } from '@components/FlowBuilder/PixiFieldRepository';
import { useActivateEntryPoint } from '@components/FlowBuilder/utils/useActivateEntryPoint';
import { useHistory } from 'react-router-dom';
import { DeepLinksMode } from '@pages/DeepLinks/types';
import { sendEvent } from '@utils/Analytics';

export const useBotStatusCallout = () => {
  const history = useHistory();
  const { state, refetch } = useBotStatusCalloutState();
  const { text, buttonText } = useBotStatusCalloutTexts(
    state || BotStatusCalloutState.connectPage,
  );
  const botId = useCurrentBotId()!;
  const { connectPage } = useConnectPage({ botId });
  const { showEntryPointModal } = useEntryPointsModal({
    onDataUpdated: refetch,
  });
  const { activateEntryPoint } = useActivateEntryPoint();

  const action = useCallback(() => {
    const { id: flowId, platform } = getFlowControllerStrict().flow;
    switch (state) {
      case BotStatusCalloutState.connectWhatsapp:
        sendEvent({
          category: 'whatsapp flow callout',
          action: 'connect whatsapp button click',
        });
        history.push(
          getTabLink(BotTabs.home, botId, {
            dlMode: DeepLinksMode.connectWhatsapp,
          }),
        );
        break;
      case BotStatusCalloutState.connectPage:
        connectPage({
          onPageConnected: ({ status, pageId, verifiedPermissions }) => {
            getFlowControllerStrict().updateFlowBuilderPage(
              status,
              pageId,
              verifiedPermissions,
            );
          },
        });
        break;
      case BotStatusCalloutState.addEntryPoint:
        showEntryPointModal(flowId, platform || undefined);
        break;
      case BotStatusCalloutState.activateEntryPoint:
        activateEntryPoint();
        break;
      default:
    }
  }, [
    activateEntryPoint,
    botId,
    connectPage,
    history,
    showEntryPointModal,
    state,
  ]);

  return {
    show: !!state,
    text,
    buttonText,
    action,
  };
};

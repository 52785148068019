import React, { useMemo } from 'react';
import i18next from 'i18next';
import cn from 'classnames';
import { Type } from '@ui/Type';
import { MenuItem } from '@ui/Menu';
import { OrderStatusUpdateStatus } from '@globals';
import { ComboboxWithTriangleButton } from '@ui/SimpleCombobox';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import { Disableable } from '../types';
import * as commonCSS from './common.css';
import * as css from './OrderStatusUpdateStatusControl.css';

const items = () => [
  {
    id: OrderStatusUpdateStatus.in_transit,
    title: i18next.t(
      'pages.BotPage.ReengageWhatsappTab.panel.statusChanged.type.inTransit',
    ),
    comment: i18next.t(
      'pages.BotPage.ReengageWhatsappTab.panel.statusChanged.type.inTransitComment',
    ),
  },
  {
    id: OrderStatusUpdateStatus.out_for_delivery,
    title: i18next.t(
      'pages.BotPage.ReengageWhatsappTab.panel.statusChanged.type.outForDelivery',
    ),
    comment: i18next.t(
      'pages.BotPage.ReengageWhatsappTab.panel.statusChanged.type.outForDeliveryComment',
    ),
  },
  {
    id: OrderStatusUpdateStatus.delivered,
    title: i18next.t(
      'pages.BotPage.ReengageWhatsappTab.panel.statusChanged.type.delivery',
    ),
    comment: i18next.t(
      'pages.BotPage.ReengageWhatsappTab.panel.statusChanged.type.deliveryComment',
    ),
  },
  {
    id: OrderStatusUpdateStatus.attempted_delivery,
    title: i18next.t(
      'pages.BotPage.ReengageWhatsappTab.panel.statusChanged.type.attemptedDelivery',
    ),
    comment: i18next.t(
      'pages.BotPage.ReengageWhatsappTab.panel.statusChanged.type.attemptedDeliveryComment',
    ),
  },
];

export interface OrderStatusUpdateStatusControlProps extends Disableable {
  orderStatusUpdateStatus: OrderStatusUpdateStatus;
  onOrderStatusUpdateStatusChange: (value: OrderStatusUpdateStatus) => void;
}

export const OrderStatusUpdateStatusControl: React.FC<OrderStatusUpdateStatusControlProps> =
  ({ disabled, orderStatusUpdateStatus, onOrderStatusUpdateStatusChange }) => {
    const { t } = useSafeTranslation();
    const currentItem = useMemo(
      () =>
        items().find(({ id }) => id === orderStatusUpdateStatus) || items()[0],
      [orderStatusUpdateStatus],
    );
    return (
      <PluginControlLabel
        label={t('pages.BotPage.ReengageWhatsappTab.panel.statusChanged.label')}
        light
      >
        {() => (
          <>
            <ComboboxWithTriangleButton
              menuBoxClassName={css.menu}
              disabled={disabled}
              itemToString={(item) => item?.title || ''}
              selectedItem={currentItem}
              className={cn(commonCSS.dropdown, css.dropdown)}
              buttonChildren={() => (
                <Type size="15px">{currentItem.title}</Type>
              )}
              items={items()}
              onChange={(item) => onOrderStatusUpdateStatusChange(item.id)}
              data-testid="reengage-editor__status-type-dropdown"
            >
              {({
                downshift: { getItemProps, highlightedIndex },
                props: { item, index },
              }) => (
                <MenuItem
                  title={item.title}
                  active={index === highlightedIndex}
                  key={item.id}
                  wrapContent={Boolean(item.comment)}
                  comment={item.comment}
                  {...getItemProps({ item })}
                />
              )}
            </ComboboxWithTriangleButton>
          </>
        )}
      </PluginControlLabel>
    );
  };

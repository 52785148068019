import { useMemo } from 'react';
import {
  SearchType,
  useFilterableSearch,
} from '@components/FilterableSearchField';
import { FlowGroupsQuery_bot_flow_groups as FlowGroup } from '@utils/Data/Flow/@types/FlowGroupsQuery';
import { FlowsQuery_bot_flows as Flow } from '@utils/Data/Flow/@types/FlowsQuery';
import { isShopifyPlugin } from '@components/FlowBuilder/views/components/ShopifyAccount/utils/isShopifyPlugin';
import { PluginType } from '@components/Plugins/common/PluginTypes';

import { EntryPointStatus, FlowsFilter } from '../types';
import {
  matchStringWithFilterValue,
  matchFlowOrFlowGroupTitleWithFilterValue,
} from '../helpers';

interface FilterFlowsConfig {
  flows?: Flow[] | null;
  flowGroups?: FlowGroup[] | null;
}

export const useFilterFlows = ({ flowGroups, flows }: FilterFlowsConfig) => {
  const { type: flowsFilterType, value: flowsFilterValue } =
    useFilterableSearch<FlowsFilter>();
  const filteredFlows = useMemo(
    () =>
      // eslint-disable-next-line array-callback-return, consistent-return
      (flows ?? []).filter((flow) => {
        if (!flows) return false;

        if (flowsFilterValue === null) return true;

        const entryPointsWithoutShopify = flow.entry_points?.filter(
          ({ entry_point_type }) =>
            !isShopifyPlugin(entry_point_type as PluginType),
        );

        // eslint-disable-next-line default-case
        switch (flowsFilterType) {
          case SearchType.search: {
            return (
              !!entryPointsWithoutShopify?.some(({ title }) =>
                matchStringWithFilterValue(title, flowsFilterValue),
              ) ||
              matchFlowOrFlowGroupTitleWithFilterValue(
                flowGroups ?? [],
                flow,
                flowsFilterValue,
              )
            );
          }
          case FlowsFilter.entryPointStatus:
            return !!entryPointsWithoutShopify?.some(({ enabled }) =>
              flowsFilterValue === EntryPointStatus.ACTIVE ? enabled : !enabled,
            );
        }
      }),
    [flowGroups, flows, flowsFilterValue, flowsFilterType],
  );
  return filteredFlows;
};

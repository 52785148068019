import gql from 'graphql-tag';

export const GENERATE_CHART_EXPORT_URL = gql`
  mutation GenerateChartExportUrl(
    $botId: String!
    $statsType: StatsType!
    $startDate: String!
    $endDate: String!
    $platform: Platform
  ) {
    generateChartExportUrl(
      botId: $botId
      statsType: $statsType
      startDate: $startDate
      endDate: $endDate
      platform: $platform
    )
  }
`;

import React from 'react';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import cn from 'classnames';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import css from './Cards.css';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';

interface CardsProps {
  loading: boolean;
  isEcommerce: boolean;
}

const loader = Array.from(Array(5), (_, i) => (
  <LoadingPlaceholder className={cn(css.card, css.loader)} key={i} />
));

export const Cards: React.FC<CardsProps> = ({ loading, isEcommerce }) => {
  const { t } = useSafeTranslation();

  const items = isEcommerce
    ? [
        {
          value: 'x10',
          text: t('pages.FirstSession.ActivateBotBlock.returnsSpent'),
        },
        {
          value: '+80%',
          text: t('pages.FirstSession.ActivateBotBlock.totalSales'),
        },
        {
          value: '+64%',
          text: t('pages.FirstSession.ActivateBotBlock.conversionRate'),
        },
        {
          value: '+76%',
          text: t('pages.FirstSession.ActivateBotBlock.fasterResponses'),
        },
      ]
    : [
        {
          value: '+80%',
          text: t('pages.FirstSession.ActivateBotBlock.totalSales'),
        },
        {
          value: '+64%',
          text: t('pages.FirstSession.ActivateBotBlock.conversionRate'),
        },
        {
          value: '76%',
          text: t('pages.FirstSession.ActivateBotBlock.fasterResponses'),
        },
        {
          value: '90%',
          text: t('pages.FirstSession.ActivateBotBlock.openRateOnMessenger'),
        },
      ];

  return (
    <Flex gapFactor={2} className={css.cardContainer}>
      {loading
        ? loader
        : items.map(({ value, text }) => (
            <Flex flexDirection="column" className={css.card} key={value}>
              <Type size="44px" weight="bold" color="accent1Normal">
                {value}
              </Type>
              <Type size="18px" color="baseNormal">
                {text}
              </Type>
            </Flex>
          ))}
    </Flex>
  );
};

import { useEffect, useCallback, useState } from 'react';
import { BotStatusName } from '@globals';
import { useHistory, useLocation } from 'react-router-dom';
import { useBotPageStatus } from '@utils/Data/Bot';
import { UPDATE_CARD_QUERY_PARAMS, usePaymentInfo } from '@utils/Data/Pricing';
import {
  ConnectPageField,
  ConnectPageOrigin,
  getConnectPageUrlParams,
  useConnectPage,
} from '@components/ConnectPageDialog';
import { sendEvent } from '@utils/Analytics';
import { isWarning } from '@components/Premium/Payments/PaymentStatus/subscriptionStatus';
import { useUpdatePaymentDialog } from '@components/DialogsPricing/hooks';

export const useUpdatePayments = (botId: string) => {
  const location = useLocation();
  const history = useHistory();
  const { paymentInfo } = usePaymentInfo();
  const { botPageStatus } = useBotPageStatus();
  const showPaymentUpdateDialog = useUpdatePaymentDialog();
  const [isBackFromFb, setBackFromFb] = useState(false);

  const { connectPage } = useConnectPage({
    botId,
    urlParams: {
      [ConnectPageField.origin]: ConnectPageOrigin.billing,
    },
    onPageConnected({ close }) {
      const { cpOrigin } = getConnectPageUrlParams();
      close?.();
      if (cpOrigin === ConnectPageOrigin.billing) {
        setBackFromFb(true);
      }
    },
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const isUpdateCard = Boolean(queryParams.get(UPDATE_CARD_QUERY_PARAMS));
    if (isUpdateCard) {
      showPaymentUpdateDialog();
      queryParams.delete(UPDATE_CARD_QUERY_PARAMS);
      history.push({ search: queryParams.toString() });
    }
  }, [showPaymentUpdateDialog, location.search, history]);

  useEffect(() => {
    if (isBackFromFb) {
      setBackFromFb(false);
      showPaymentUpdateDialog();
    }
  }, [isBackFromFb, showPaymentUpdateDialog]);

  const updatePayment = useCallback(() => {
    const isNoCard = !paymentInfo?.active_card;
    const isError = paymentInfo ? isWarning(paymentInfo) : false;
    const isPageConnected =
      ((botPageStatus ?? '') as BotStatusName) !== BotStatusName.draft;

    sendEvent({
      category: 'billing',
      action: `click ${
        isNoCard ? 'add' : isError ? 'update' : 'edit'
      } payment info `,
      label: 'new dialogs pricing',
      propertyBag: { botId, isError, from: 'billing info' },
    });
    if (isPageConnected) {
      showPaymentUpdateDialog();
    } else {
      connectPage({
        botId,
        onPageConnected: () => {
          showPaymentUpdateDialog();
        },
      });
    }
  }, [botId, connectPage, botPageStatus, paymentInfo, showPaymentUpdateDialog]);

  return updatePayment;
};

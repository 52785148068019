import * as React from 'react';
import * as ng from 'angular';
import { angular2react } from 'angular2react';
import { lazyInjector } from '../../../utils/angularLazyInjector';
import { useReadyToRender } from '../../../utils/AngularRouting';
import controller from '../../../components/statistics/controller';
import template from '../../../components/statistics/statistics.html';
import thirdPartyAnalytics from '../../../components/statistics/third-party-analytics';
import yandexMetrikaConfirmPage from '../../../components/statistics/yandex-metrika-confirm-page';
import { BotTabAngular } from '../../../modern-components/BotTab';

const analyzeTabWrapper = {
  controller,
  template: () => template,
  controllerAs: 'vm',
  transclude: true,
  bindToController: true,
};

export const ngAnalyzeTabWrapper = ng
  .module('app.pages.analyzeTab', [
    'chart.js',
    thirdPartyAnalytics,
    yandexMetrikaConfirmPage,
  ])
  .component('analyzeTabWrapper', analyzeTabWrapper)
  .config([
    'ChartJsProvider',
    (ChartJsProvider: any) => {
      ChartJsProvider.setOptions({
        responsive: true,
        colors: [
          '#803690',
          '#00ADF9',
          '#DCDCDC',
          '#46BFBD',
          '#FDB45C',
          '#949FB1',
          '#4D5360',
        ],
      });
    },
  ]).name;

const AnalyzeTabWrapperFunc = () => {
  return angular2react<{}>(
    'analyzeTabWrapper',
    analyzeTabWrapper,
    lazyInjector.$injector as any,
  );
};

const AnalyzeTabWrapper = AnalyzeTabWrapperFunc();

export const AnalyzeTab: React.FC = () => {
  const readyToRender = useReadyToRender('common');
  if (!readyToRender) {
    return <div />;
  }

  return (
    <BotTabAngular tabName="statistics-container" rcol>
      <AnalyzeTabWrapper />
    </BotTabAngular>
  );
};

import Maybe from 'graphql/tsutils/Maybe';
import { PaymentEntityType } from '@pages/Billing/Table/types';
import { Translation } from '@translations';

export const paymentEntityTypeTranslationKey = (
  paymentEntityType: Maybe<PaymentEntityType>,
) => {
  return (
    paymentEntityType &&
    ({
      [PaymentEntityType.broadcasts]: 'pages.Billing.premiumPage.broadcast',
      [PaymentEntityType.dialogs]: 'pages.Billing.premiumPage.conversation',
      [PaymentEntityType.users]: 'pages.Billing.premiumPage.user',
    }[paymentEntityType] as Translation)
  );
};

import { useCallback } from 'react';
import { useMutation } from 'react-apollo';
import { useCurrentBotId } from '@utils/Routing';
import {
  UpdateWhatsappStatusBroadcast,
  UpdateWhatsappStatusBroadcastVariables,
} from './@types/UpdateWhatsappStatusBroadcast';
import { UPDATE_WHATSAPP_BROADCAST_STATUS_MUTATION } from './queries';
import { LiveCycleBroadcastStatus } from '@globals';
import { defaultErrorHandler } from '../utils';

export const useUpdateBroadcastStatus = () => {
  const botId = useCurrentBotId()!;
  const [updateBroadcastStatusMutation, queryResult] = useMutation<
    UpdateWhatsappStatusBroadcast,
    UpdateWhatsappStatusBroadcastVariables
  >(UPDATE_WHATSAPP_BROADCAST_STATUS_MUTATION);

  const updateStatus = useCallback(
    (status: LiveCycleBroadcastStatus) => (broadcastId: string) => {
      return updateBroadcastStatusMutation({
        variables: {
          botId,
          broadcastId,
          status,
        },
      }).catch(
        defaultErrorHandler({
          msg: `Could not set status to broadcast`,
          data: { broadcastId, status },
        }),
      );
    },
    [updateBroadcastStatusMutation, botId],
  );

  const setBroadcastLive = useCallback(
    updateStatus(LiveCycleBroadcastStatus.enable),
    [updateStatus],
  );

  const setBroadcastPause = useCallback(
    updateStatus(LiveCycleBroadcastStatus.pause),
    [updateStatus],
  );

  const setBroadcastUnpause = useCallback(
    updateStatus(LiveCycleBroadcastStatus.unpause),
    [updateStatus],
  );

  return {
    setBroadcastLive,
    setBroadcastPause,
    setBroadcastUnpause,
    ...queryResult,
  };
};

import { useCallback, useEffect, useState } from 'react';
import { useApolloClient } from 'react-apollo';
import {
  WhatsappBroadcast,
  WhatsappBroadcastVariables,
  WhatsappBroadcast_whatsappBroadcast,
} from './@types/WhatsappBroadcast';
import { mapQuery } from '@utils/GQL/mapQuery';
import { WHATSAPP_BROADCAST_QUERY } from './queries';
import { BroadcastTypes, useBroadcastsTypeManager } from '../Broadcast';
import {
  OrderStatusUpdateStatus,
  TriggeredMessageIntervalTimeUnit,
  TriggeredMessageType,
} from '@globals';
import { whatsappConfig } from '../whatsappConfigUpdaters';
import { isEditableBroadcast, isShopifyTrigger } from '../utils';
import { useOnBroadcastTimePassed } from './useOnBroadcastTimePassed';

type Idenitity<T> = (x: T) => T;

export interface UseEditBroadcastCacheProps extends WhatsappBroadcastVariables {
  defaultConfig: WhatsappBroadcast_whatsappBroadcast;
}

export const useEditBroadcastCache = ({
  defaultConfig,
  ...variables
}: UseEditBroadcastCacheProps) => {
  const queryParams = {
    query: WHATSAPP_BROADCAST_QUERY,
    variables,
  };

  const client = useApolloClient();
  const { broadcastsTypeManager } = useBroadcastsTypeManager();
  const [config, setConfig] =
    useState<WhatsappBroadcast_whatsappBroadcast>(defaultConfig);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const newState = !isEditableBroadcast(config);
    if (disabled === newState) {
      return;
    }

    setDisabled(newState);
  }, [config, disabled]);

  useOnBroadcastTimePassed(config, () => setDisabled(true));

  useEffect(() => {
    const subscription = client
      .watchQuery<WhatsappBroadcast, WhatsappBroadcastVariables>(queryParams)
      .subscribe(({ data }) => {
        setConfig(data.whatsappBroadcast);
      });

    return () => subscription.unsubscribe();
  }, [client, queryParams]);

  const mapQueryConfig = useCallback(
    (cb: Idenitity<WhatsappBroadcast_whatsappBroadcast>) => {
      return mapQuery<WhatsappBroadcast>(client, queryParams, (data) => ({
        whatsappBroadcast: cb(data.whatsappBroadcast),
      }));
    },
    [client, queryParams],
  );

  const updateTitle = useCallback(
    (title: string) => mapQueryConfig(whatsappConfig.updateTitle(title)),
    [mapQueryConfig],
  );

  const updateDate = useCallback(
    (date) => mapQueryConfig(whatsappConfig.updateDate(date)),
    [mapQueryConfig],
  );

  const updateTime = useCallback(
    (time: string | null) =>
      mapQueryConfig(whatsappConfig.updateTime(time as string)),
    [mapQueryConfig],
  );

  const updateUserFilter = useCallback(
    (userFilter) => {
      if (disabled) {
        return undefined;
      }

      return mapQueryConfig(whatsappConfig.updateUserFilter(userFilter));
    },
    [disabled, mapQueryConfig],
  );

  const updateTriggerUserFilter = useCallback(
    (userFilter) =>
      mapQueryConfig(whatsappConfig.updateTriggerUserFilter(userFilter)),
    [mapQueryConfig],
  );

  const updateDaysOfWeek = useCallback(
    (daysOfWeek: string | null) =>
      // @ts-expect-error
      mapQueryConfig(whatsappConfig.updateDaysOfWeek(daysOfWeek)),
    [mapQueryConfig],
  );

  const updateDayOfMonth = useCallback(
    (daysOfMonth: number) =>
      mapQueryConfig(whatsappConfig.updateDayOfMonth(daysOfMonth)),
    [mapQueryConfig],
  );

  const setDefaultConfigByType = useCallback(
    (broadcastType: BroadcastTypes) =>
      mapQueryConfig((config) => {
        const updatedConfig = broadcastsTypeManager
          .getBroadcastByType(broadcastType)
          .getDefaultConfig(config);
        updatedConfig.userFilter = config.userFilter;
        return updatedConfig;
      }),
    [mapQueryConfig, broadcastsTypeManager],
  );

  const updpateTriggerSendIn = useCallback(
    (time: number, unit: TriggeredMessageIntervalTimeUnit) =>
      mapQueryConfig(whatsappConfig.updateTriggerSendIn(time, unit)),
    [mapQueryConfig],
  );

  const updateSendInTime = useCallback(
    (time: number | null) =>
      mapQueryConfig(whatsappConfig.updateTimeOfDay(time as number)),
    [mapQueryConfig],
  );

  const updateTriggerType = useCallback(
    (type: TriggeredMessageType) => {
      mapQueryConfig(whatsappConfig.updateTriggerType(type));
      mapQueryConfig(whatsappConfig.updateTriggerUserFilter(null));

      if (isShopifyTrigger(type)) {
        mapQueryConfig(
          whatsappConfig.updateOrderStatusUpdateStatus(
            OrderStatusUpdateStatus.in_transit,
          ),
        );
      }
    },
    [mapQueryConfig],
  );

  const updateOrderStatusUpdateStatus = useCallback(
    (type: OrderStatusUpdateStatus) =>
      mapQueryConfig(whatsappConfig.updateOrderStatusUpdateStatus(type)),
    [mapQueryConfig],
  );

  return {
    config,
    disabled,
    setDefaultConfigByType,
    updateTitle,
    updateDate,
    updateTime,
    updateUserFilter,
    updateTriggerUserFilter,
    updateDaysOfWeek,
    updateDayOfMonth,
    updpateTriggerSendIn,
    updateSendInTime,
    updateTriggerType,
    updateOrderStatusUpdateStatus,
  };
};

import React from 'react';
import { ButtonTextInputView } from './ButtonTextInputView';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import { ButtonErrors, TemplateButton } from '../../../form/types';

export interface CustomButtonViewProps {
  button: TemplateButton;
  disabled: boolean;
  buttonErrors: ButtonErrors;
  onButtonChange: (button: TemplateButton) => void;
}

export const CustomButtonView: React.FC<CustomButtonViewProps> = ({
  button,
  disabled,
  buttonErrors,
  onButtonChange,
}) => {
  const { t } = useSafeTranslation();

  return (
    <PluginControlLabel
      light
      label={t('pages.MessagesTemplates.message.buttonText')}
    >
      {() => (
        <ButtonTextInputView
          disabled={disabled}
          error={Boolean(buttonErrors?.text)}
          buttonText={button.text || ''}
          onButtonTextChange={(e) =>
            onButtonChange({ ...button, text: e.target.value })
          }
          data-testid={`messages-template-${button.type}__button-text`}
        />
      )}
    </PluginControlLabel>
  );
};

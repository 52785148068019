import { useCallback, useState } from 'react';
import { ApolloError } from 'apollo-client';
import Maybe from 'graphql/tsutils/Maybe';
import { useVerifyWhatsappCode } from './hooks';
import { useTimer } from '@utils/useTimer';
import { WhatsappVerificationErrorType } from '@globals';
import { useSafeTranslation } from '@utils/useSafeTranslation';

interface IsUnhandledErrorParams {
  called: boolean;
  errorType: Maybe<WhatsappVerificationErrorType>;
  error?: ApolloError;
}

const isUnhandledError = ({
  called,
  errorType,
  error,
}: IsUnhandledErrorParams) =>
  called &&
  ([
    WhatsappVerificationErrorType.unrecognized,
    WhatsappVerificationErrorType.tooManyRequests,
  ].includes(errorType as WhatsappVerificationErrorType) ||
    Boolean(error));

export interface RequestCodeHookParams extends IsUnhandledErrorParams {
  requestCode: (phone: string) => void;
}

export interface UseEnterVerificaitonCodeParams extends RequestCodeHookParams {
  phoneId: string;
  onSubmit: () => void;
}

export const useEnterVerificaitonCode = ({
  onSubmit,
  phoneId,
  ...requestHookParams
}: UseEnterVerificaitonCodeParams) => {
  const { t } = useSafeTranslation();
  const [code, setCode] = useState('');
  const [incorrectCode, setIncorrectCode] = useState('');
  const [expiredCode, setExpiredCode] = useState('');
  const verification = useVerifyWhatsappCode();
  const { verifyCode } = verification;
  const { requestCode } = requestHookParams;
  const timer = useTimer({ hours: 0, minutes: 1, seconds: 0 });
  const { startTimer } = timer;

  const errorType =
    verification.data?.verifyWhatsappCode?.errorType ||
    WhatsappVerificationErrorType.unrecognized;
  const inputErrorMessage: string | null = {
    [WhatsappVerificationErrorType.incorrectCode]: t(
      'pages.BotPage.HomeTab.Whatsapp.EnterVerificationCode.incorrectCode',
    ),
    [WhatsappVerificationErrorType.newCodeRequired]: t(
      'pages.BotPage.HomeTab.Whatsapp.EnterVerificationCode.newCodeRequired',
    ),
    [WhatsappVerificationErrorType.unrecognized]: null,
    [WhatsappVerificationErrorType.tooManyRequests]: null,
  }[errorType];
  const error =
    isUnhandledError(requestHookParams) || isUnhandledError(verification);

  const onResendClick = useCallback(() => {
    setCode('');
    requestCode(phoneId);
    startTimer();
  }, [phoneId, requestCode, startTimer]);

  const onSubmitClick = useCallback(() => {
    verifyCode({ phoneId, code }).then(({ data }) => {
      if (data?.verifyWhatsappCode?.success) {
        onSubmit();
      }

      if (
        data?.verifyWhatsappCode?.errorType ===
        WhatsappVerificationErrorType.incorrectCode
      ) {
        setIncorrectCode(code);
      }

      if (
        data?.verifyWhatsappCode?.errorType ===
        WhatsappVerificationErrorType.newCodeRequired
      ) {
        setExpiredCode(code);
      }
    });
  }, [code, onSubmit, phoneId, verifyCode]);

  const disabled = Boolean(
    (incorrectCode && code === incorrectCode) ||
      (expiredCode && code === expiredCode),
  );

  return {
    code,
    setCode,
    disabled,
    timer,
    error,
    verification,
    onResendClick,
    onSubmitClick,
    inputErrorMessage,
  };
};

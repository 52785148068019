import { lazyInjector } from '@utils/angularLazyInjector';
import { useReadyToRender } from '@utils/AngularRouting';
import { angular2react } from 'angular2react';
import * as React from 'react';
import { yandexMetrikaConfirmPageNg } from '../../../components/statistics/yandex-metrika-confirm-page';

const YandexMetrikaConfirmPageWrapperFunc = () => {
  return angular2react<{}>(
    'yandexMetrikaConfirmPage',
    yandexMetrikaConfirmPageNg,
    lazyInjector.$injector as any,
  );
};

const YandexMetrikaConfirmPageWrapper = YandexMetrikaConfirmPageWrapperFunc();

export const YandexMetrikaConfirmPage: React.FC = () => {
  const readyToRender = useReadyToRender();
  if (!readyToRender) {
    return <div />;
  }

  return <YandexMetrikaConfirmPageWrapper />;
};

import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { MESSAGES_TEMPLATE_FRAGMENT } from './messageTemplateFragments';
import {
  CreateMessageTemplate,
  CreateMessageTemplateVariables,
} from './@types/CreateMessageTemplate';
import { mapQuery } from '@utils/GQL/mapQuery';
import {
  GetMessageTemplates,
  GetMessageTemplatesVariables,
} from './@types/GetMessageTemplates';
import { GET_MESSAGE_TEMPLATES_QUERY } from './useGetMessageTemplates';
import { useCurrentBotId } from '@utils/Routing';
import { log } from 'cf-common/src/logger';
import { showSomethingWentWrongToaster } from '@services/MessageService';

export const CREATE_MESSAGE_TEMPLATE_MUTATION = gql`
  mutation CreateMessageTemplate(
    $botId: MongoObjectId!
    $template: MessagesTemplateInput!
  ) {
    createMessageTemplate(botId: $botId, template: $template) {
      ...messagesTemplateFragment
    }
  }
  ${MESSAGES_TEMPLATE_FRAGMENT}
`;

export const useCreateMessageTemplate = () => {
  const botId = useCurrentBotId();
  const [mutation, queryResult] = useMutation<
    CreateMessageTemplate,
    CreateMessageTemplateVariables
  >(CREATE_MESSAGE_TEMPLATE_MUTATION, {
    onError: (error) => {
      log.error({
        error,
        msg: 'create whatsapp template error',
      });
      showSomethingWentWrongToaster();
    },
    update: (cache, mutationResult) => {
      const newTemplate = mutationResult.data?.createMessageTemplate;
      if (!newTemplate || !botId) {
        return;
      }
      mapQuery<GetMessageTemplates, GetMessageTemplatesVariables>(
        cache,
        {
          query: GET_MESSAGE_TEMPLATES_QUERY,
          variables: {
            botId,
          },
        },
        (data) => {
          data.messageTemplates.unshift(newTemplate);
          return data;
        },
      );
    },
  });

  const createMessageTemplate = (variables: CreateMessageTemplateVariables) =>
    mutation({ variables });

  return [createMessageTemplate, queryResult] as const;
};

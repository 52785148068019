import { RoleIds } from '../../../../components/settings/admins/RolesList';
import { getRoleTexts } from './getRoleTexts';

export const DEFAULT_ROLES = [
  RoleIds.admin,
  RoleIds.editor,
  RoleIds.marketer,
  RoleIds.operator,
  RoleIds.viewer,
].map((roleId) => ({
  id: roleId,
  get title() {
    return getRoleTexts(roleId).title;
  },
  get tooltip() {
    return getRoleTexts(roleId).tooltip;
  },
}));

import cn from 'classnames';
import { FlowBuilder, ViewMode } from '@components/FlowBuilder';
import { TEMPLATES_MODAL_ROOT_ID } from '@components/FlowBuilder/consts';
import { getFlowControllerStrict } from '@components/FlowBuilder/PixiFieldRepository';
import { FlowData } from '@components/FlowBuilder/types';
import { useFlowBuilderStateWriter } from '@components/FlowBuilder/hooks';
import { USER_TEST_IN_FB_REF } from '@components/TestInFacebook/userTestId';
import { useTestThisWithOneTimeRef } from '@components/TestInFacebook/utils/useTestThisWithOneTimeRef';
import { SendToMessengerOverlay } from '@services/TestServices';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Anchor } from '@ui/Links';
import { CircleLoader } from '@ui/Loader/CircleLoader';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { useAdminFeatures } from '@utils/Data/Admin';
import { useCurrentTestPageId } from '@utils/FacebookPages/useCurrentTestPageId';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { tokenizeStringByLink } from '@utils/Links/tokenizeStringByLink';
import { getFlow as getAggregatedFlow } from 'cf-shared-flow/src/api/flow';
import { FlowGroupData, getFlowGroup } from 'cf-shared-flow/src/api/group';
import { identity, prop, uniqBy } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { CategoryIcon } from '@components/ItemsModal';
import { FlowLoader, FlowLoadingState } from '../../../../FlowLoader';
import { Template, TemplateId, TemplateLevel } from '../../types';
import { TogglableList } from '../TogglableList';
import * as css from './TemplateDetailsModal.css';
import { ForBeginnersBadge } from '../ForBeginnersBadge';
import { sendTemplateDetailsEvent as sendTemplateEvent } from '../../events';
import { UseCloneTemplate } from '../../hooks/useCloneTemplate';
import { Platform } from '@globals';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

const getEpTitlesFromFlowBlocks = (flow: FlowData) =>
  flow.blocks.filter(prop('entryPoint')).map(prop('title'));

interface TemplateDetailsModalProps extends UseCloneTemplate {
  template: Template;
  onChangeTemplate(templateId: TemplateId): void;
  onBackToList(): void;
}

export const TemplateDetailsModal: React.FC<TemplateDetailsModalProps> = ({
  template,
  onChangeTemplate,
  onBackToList,
  loading,
  cloneTemplate,
}) => {
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();
  const { isConnected } = usePageConnected();
  const [loadingState, setLoadingState] = useState<FlowLoadingState>(
    FlowLoadingState.none,
  );
  const [flowsLoading, setFlowsLoading] = useState(false);
  const [flow, setFlow] = useState<FlowData | null>(null);
  const [flowGroup, setFlowGroup] = useState<FlowGroupData | null>(null);
  const [flowTitles, setFlowTitles] = useState<string[] | null>(null);
  const [entryPointTitles, setEntryPointTitles] = useState<string[] | null>(
    null,
  );
  const { adminFeatures } = useAdminFeatures();
  const { pageId: defaultTestPageId } = useCurrentTestPageId();
  const testPageId = flow?.botStatus?.page_info?.id ?? '';

  useFlowBuilderStateWriter(template.flowId, loadingState);

  const { testThis } = useTestThisWithOneTimeRef({ blockId: template.flowId });
  const getFlowTitle = useCallback(
    (platform: Platform) => {
      switch (platform) {
        case Platform.facebook:
          return t('modernComponents.TemplatesModal.facebookFlow');
        case Platform.instagram:
          return t('modernComponents.TemplatesModal.instagramFlow');
        default:
          throw new Error('Unexpected platform');
      }
    },
    [t],
  );

  useEffect(() => {
    const button = SendToMessengerOverlay.getButton(USER_TEST_IN_FB_REF);
    if (button) {
      button.update({ pageId: testPageId });
    }
    return () => {
      if (button) {
        button.update({ pageId: defaultTestPageId || undefined });
      }
    };
  }, [defaultTestPageId, testPageId]);

  const sendTemplateDetailsEvent = useCallback(
    (action: string) => sendTemplateEvent(action, { isConnected, template }),
    [isConnected, template],
  );

  useEffect(() => {
    sendTemplateDetailsEvent('show second screen');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!flowsLoading && !flowTitles) {
      if (flow && !flowGroup) {
        // For single-flow template
        setFlowTitles([flow.title]);
        const epTitles = getEpTitlesFromFlowBlocks(flow);
        setEntryPointTitles(uniqBy(identity, epTitles));
      } else if (flowGroup) {
        // For group-flow template
        const flowIds = flowGroup.flows.map(prop('id')) ?? [];
        setFlowTitles(flowGroup.flows.map(prop('title')) ?? []);
        setFlowsLoading(true);
        Promise.all(flowIds.map(getAggregatedFlow)).then((flows) => {
          setFlowsLoading(false);
          const epTitles = flows.flatMap(getEpTitlesFromFlowBlocks);
          setEntryPointTitles(uniqBy(identity, epTitles));
        });
      }
    }
  }, [
    flowGroup,
    flow,
    flowTitles,
    flowsLoading,
    loadingState,
    setFlowsLoading,
  ]);

  return (
    <Flex
      className={
        isSmallScreenSize
          ? css.templateDetailsModalContainerMobile
          : css.templateDetailsModalContainer
      }
      flexDirection="column"
      justifyContent="space-between"
    >
      <Flex
        className={css.templateDetailsModalTop}
        flexDirection={isSmallScreenSize ? 'column' : undefined}
      >
        <Flex
          className={css.templateDetailsModalFlowData}
          flexDirection="column"
        >
          <Flex alignItems="center">
            <CategoryIcon
              category={template.category}
              style={{ marginRight: '8px' }}
            />
            <Type size="15px" weight="semibold">
              {getFlowTitle(template.platform)}
            </Type>
            <Spacer horizontalFactor={2} />
            {template.level === TemplateLevel.basic && <ForBeginnersBadge />}
          </Flex>
          <Spacer factor={2} />
          <div>
            {tokenizeStringByLink(template.details).map(({ type, data }) => (
              <React.Fragment key={data}>
                {type === 'string' ? (
                  <Type size="15px">{data}</Type>
                ) : (
                  <Anchor
                    hideArrow
                    intent="external"
                    href={data}
                    target="_blank"
                  >
                    {' '}
                    {data}{' '}
                  </Anchor>
                )}
              </React.Fragment>
            ))}
          </div>
          <Spacer factor={4} />
          <Flex
            className={css.templateDetailsModalFlowDataLists}
            flexDirection="column"
          >
            <TogglableList
              title={t('modernComponents.TemplatesModal.flows')}
              items={flowTitles}
            />
            <TogglableList
              title={t('modernComponents.TemplatesModal.entryPoints')}
              items={entryPointTitles}
            />
          </Flex>
          {template.level === TemplateLevel.advanced && template.alternativeId && (
            <div className={css.templateDetailsModalFlowDataAdvanced}>
              <Type size="15px">
                {t('modernComponents.TemplatesModal.advanced.title')}
              </Type>
              <Flex justifyContent="flex-end">
                <Button
                  intent="text"
                  onClick={() => {
                    sendTemplateDetailsEvent('go to basic');
                    onChangeTemplate(template.alternativeId!);
                  }}
                >
                  {t('modernComponents.TemplatesModal.advanced.goToBasic')}
                </Button>
              </Flex>
            </div>
          )}
        </Flex>
        <Spacer horizontalFactor={6} />
        <Flex
          className={cn(
            css.templateDetailsModalFlowEmbed,
            isSmallScreenSize && css.templateDetailsModalFlowEmbedMobile,
          )}
        >
          <FlowLoader state={loadingState} iconSize={150} />
          <FlowBuilder
            flowId={template.flowId}
            mode={ViewMode.view}
            loadFlow={async () => {
              const flowGroupData = await (template.flowGroupId
                ? getFlowGroup(template.flowGroupId)
                : Promise.resolve(null));
              setFlowGroup(flowGroupData);

              const flowData = await getAggregatedFlow(template.flowId);
              setFlow(flowData);

              setLoadingState(FlowLoadingState.rendering);

              return flowData;
            }}
            onLoading={() => setLoadingState(FlowLoadingState.fetching)}
            onReady={() => setLoadingState(FlowLoadingState.rendering)}
            onRendered={() => {
              setLoadingState(FlowLoadingState.ready);
              getFlowControllerStrict().fitScreen();
            }}
            onDestroy={() => {
              setLoadingState(FlowLoadingState.none);
            }}
            rootElementId={TEMPLATES_MODAL_ROOT_ID}
          />
        </Flex>
      </Flex>
      <Flex
        className={
          isSmallScreenSize
            ? css.templateDetailsModalBottomMobile
            : css.templateDetailsModalBottom
        }
        flexDirection={isSmallScreenSize ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          icon={<Icon size="12px" icon="arrowBack" color="black" />}
          intent="secondary"
          style={{ width: isSmallScreenSize ? '100%' : undefined }}
          onClick={() => {
            sendTemplateDetailsEvent('back to templates');
            onBackToList();
          }}
        >
          {t('modernComponents.TemplatesModal.backToListButton')}
        </Button>

        <Spacer factor={isSmallScreenSize ? 2 : 0} horizontalFactor={0} />

        <Flex
          flexDirection={isSmallScreenSize ? 'column' : 'row'}
          style={{ width: isSmallScreenSize ? '100%' : undefined }}
        >
          {adminFeatures?.flow_templates_test_button && (
            <>
              <Button
                onClick={() => {
                  sendTemplateDetailsEvent('open in messenger');
                  testThis();
                }}
                style={{ width: isSmallScreenSize ? '100%' : undefined }}
                icon={<Icon size="24px" icon="messenger" color="blue" />}
                intent="secondary"
              >
                {t('modernComponents.TemplatesModal.testInMessengerButton')}
              </Button>
              <Spacer factor={isSmallScreenSize ? 2 : 0} horizontalFactor={4} />
            </>
          )}
          <Button
            intent="primary"
            onClick={() => !loading && cloneTemplate(template)}
            style={{ width: isSmallScreenSize ? '100%' : undefined }}
          >
            {loading ? (
              <CircleLoader
                className={css.templateDetailsModalUseTemplateLoader}
              />
            ) : (
              t('modernComponents.TemplatesModal.useThisTemplateButton')
            )}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

import React from 'react';
import { SectionBox } from '@components/SectionBox';
import { useCreateWorkspaceBotFromHeader } from '@components/PageHeader/BotHeader/hooks';
import { Platform } from '@globals';
import { WhatsappBotNotCreatedView } from './WhatsappBotNotCreatedView';
import { isWhatsappEnabled } from '@utils/Whatsapp/isWhatsappEnabled';
import { useWorkspacesBots } from '@utils/Data/Workspaces/useWorkspacesBots';
import { getFlatBots } from '@utils/Data/Bot/utils';
import { WhatsappBotNotCreatedProps } from './types';

export const WorkspaceWhatsappBotNotCreated: React.FC<WhatsappBotNotCreatedProps> =
  ({ onClick, onClose }) => {
    const [workspaces, workspacesLoading] = useWorkspacesBots();
    const {
      onCreateBot: onCreateWorkspaceBot,
      loading: createdWorkspaceBotLoading,
    } = useCreateWorkspaceBotFromHeader();

    const hasAnyWhatsappBots = getFlatBots(workspaces).some(isWhatsappEnabled);

    if (hasAnyWhatsappBots) {
      return null;
    }

    const workspaceId = workspaces?.workspaces[0]?.workspace.id;

    return (
      <SectionBox data-testid="whatsapp-account">
        <WhatsappBotNotCreatedView
          loading={workspacesLoading}
          buttonLoading={createdWorkspaceBotLoading}
          onClick={() =>
            onClick(
              onCreateWorkspaceBot(workspaceId, [
                Platform.whatsapp,
              ]) as PromiseLike<any>,
            )
          }
          onClose={onClose}
        />
      </SectionBox>
    );
  };

import React from 'react';
import { HighlightBorderBoxHideable } from '@ui/Box/HighlightBorderBoxHideable';
import { TypeTranslation } from '@ui/Type';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { Footer } from '../common/Footer/Footer';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { IntroQuestionsBlockQuestionRadio } from '../IntroQuestionsBlock/components/IntroQuestionsBlockQuestionRadio';
import { Spacer } from '@ui/Spacer';
import { FirstSessionStep } from '../../consts';
import noop from 'lodash-es/noop';
import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';
import { CenteredLoader } from '@ui/Loader';
import css from './WAInitialSetup.css';
import { Platform } from '@globals';
import { sendEvent } from '@utils/Analytics';

interface WAInitialSetupProps {
  goNextStep: VoidFunction;
  goPrevStep?: VoidFunction;
  onFinish: VoidFunction;
}

enum HaveYouUsedWhatsApp {
  no = 'HaveYouUsedWhatsAppNo',
  yes = 'HaveYouUsedWhatsAppYes',
}

enum WhichPhoneNumber {
  new = 'WhichPhoneNumberNew',
  current = 'WhichPhoneNumberCurrent',
}

enum DoYouWantKeepTemplates {
  no = 'DoYouWantKeepTemplatesNo',
  yes = 'DoYouWantKeepTemplatesYes',
}

type InitialSetupData = {
  haveYouUsedWhatsApp: string[];
  whichPhoneNumber: string[];
  doYouWantKeepTemplates: string[];
};

const defaultInitialSetupData = {
  haveYouUsedWhatsApp: [HaveYouUsedWhatsApp.no],
  whichPhoneNumber: [WhichPhoneNumber.new],
  doYouWantKeepTemplates: [DoYouWantKeepTemplates.no],
};

export const WAInitialSetup: React.FC<WAInitialSetupProps> = ({
  goNextStep,
  goPrevStep,
  onFinish,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const { t } = useSafeTranslation();
  const [initialSetupData, setInitialSetupData, { loading, saving }] =
    useServerStorage<InitialSetupData>(
      ServerStorageItemKeys.firstSessionWAInitialSetup,
    );
  const { haveYouUsedWhatsApp, whichPhoneNumber, doYouWantKeepTemplates } =
    initialSetupData ?? defaultInitialSetupData;

  if (loading) {
    return <CenteredLoader className={css.loader} />;
  }

  const handleNextClick = () => {
    sendEvent({
      category: 'first-session',
      action: 'done step click',
      propertyBag: {
        platform: Platform.whatsapp,
        currentStep: FirstSessionStep.waInitialSetup,
      },
    });
    if (doYouWantKeepTemplates[0] === DoYouWantKeepTemplates.yes) {
      goNextStep();
      return;
    }
    onFinish();
  };

  return (
    <HighlightBorderBoxHideable
      hide={isSmallScreenSize}
      highlightEnabled={false}
      borderRadiusFactor={3}
    >
      <TypeTranslation
        i18nKey="pages.FirstSession.WAInitialSetup.initialSetUp"
        size={isSmallScreenSize ? '18px' : '24px'}
        weight="semibold"
      />
      <Spacer factor={4} />
      <IntroQuestionsBlockQuestionRadio
        id="haveYouUsedWhatsApp"
        title={t('pages.FirstSession.WAInitialSetup.haveYouUsedWhatsApp')}
        data-testid="haveYouUsedWhatsApp"
        onChange={(value) =>
          setInitialSetupData({
            haveYouUsedWhatsApp: value,
            whichPhoneNumber,
            doYouWantKeepTemplates:
              value[0] === HaveYouUsedWhatsApp.yes
                ? doYouWantKeepTemplates
                : [DoYouWantKeepTemplates.no],
          })
        }
        onBlur={noop}
        items={[
          {
            id: HaveYouUsedWhatsApp.no,
            title: t('pages.FirstSession.WAInitialSetup.no'),
          },
          {
            id: HaveYouUsedWhatsApp.yes,
            title: t('pages.FirstSession.WAInitialSetup.yes'),
          },
        ]}
        selectedItemIds={haveYouUsedWhatsApp}
        required
      />
      {haveYouUsedWhatsApp[0] === HaveYouUsedWhatsApp.yes && (
        <>
          <IntroQuestionsBlockQuestionRadio
            id="whichPhoneNumber"
            data-testid="whichPhoneNumber"
            title={t('pages.FirstSession.WAInitialSetup.whichPhoneNumber')}
            onChange={(value) =>
              setInitialSetupData({
                haveYouUsedWhatsApp,
                whichPhoneNumber: value,
                doYouWantKeepTemplates:
                  value[0] === WhichPhoneNumber.current
                    ? doYouWantKeepTemplates
                    : [DoYouWantKeepTemplates.no],
              })
            }
            onBlur={noop}
            items={[
              {
                id: WhichPhoneNumber.new,
                title: t('pages.FirstSession.WAInitialSetup.new'),
              },
              {
                id: WhichPhoneNumber.current,
                title: t('pages.FirstSession.WAInitialSetup.current'),
              },
            ]}
            selectedItemIds={whichPhoneNumber}
            required
          />
          {whichPhoneNumber[0] === WhichPhoneNumber.current && (
            <>
              <IntroQuestionsBlockQuestionRadio
                id="doYouWantKeepTemplates"
                data-testid="doYouWantKeepTemplates"
                title={t(
                  'pages.FirstSession.WAInitialSetup.doYouWantKeepTemplates',
                )}
                onChange={(value) =>
                  setInitialSetupData({
                    haveYouUsedWhatsApp,
                    whichPhoneNumber,
                    doYouWantKeepTemplates: value,
                  })
                }
                onBlur={noop}
                items={[
                  {
                    id: DoYouWantKeepTemplates.no,
                    title: t('pages.FirstSession.WAInitialSetup.no'),
                  },
                  {
                    id: DoYouWantKeepTemplates.yes,
                    title: t('pages.FirstSession.WAInitialSetup.yes'),
                  },
                ]}
                selectedItemIds={doYouWantKeepTemplates}
                required
              />
            </>
          )}{' '}
        </>
      )}
      <Spacer factor={2} />
      <Footer
        nextButton={{
          content:
            doYouWantKeepTemplates[0] === DoYouWantKeepTemplates.yes
              ? t('pages.FirstSession.WAInitialSetup.nextStep')
              : t('pages.FirstSession.WAInitialSetup.finish'),
          onClick: handleNextClick,
        }}
        loading={saving}
        onGoToPrevClick={goPrevStep}
      />
    </HighlightBorderBoxHideable>
  );
};

import React from 'react';
import { Moment } from '@ui/DatePicker';
import { DateUtils } from '@utils/DateTime';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import { Disableable, Errorable } from '../types';
import * as css from './DateControl.css';
import { DateInput } from '@ui/DateInput';

export interface DateControlProps extends Disableable, Errorable {
  value: Moment;
  onChange(value: Moment): void;
}

export const DateControl: React.FC<DateControlProps> = ({
  value,
  disabled,
  error,
  onChange,
}) => {
  const { t } = useSafeTranslation();

  return (
    <PluginControlLabel
      className={css.label}
      label={t('pages.BotPage.ReengageWhatsappTab.panel.date.label')}
      light
    >
      {() => (
        <DateInput
          isDayBlocked={(date) => !DateUtils.isAfterCurrentDayInclusive(date)}
          value={value}
          onChange={onChange}
          error={error}
          disabled={disabled}
        />
      )}
    </PluginControlLabel>
  );
};

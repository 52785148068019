import React, { useCallback } from 'react';
import { Input } from '@ui/Input';
import { Icon } from '@ui/Icon';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './MessagesTemplatesSearchInput.css';

interface MessagesTemplatesSearchInputProps {
  value: string;
  onChange: (value: string) => void;
}

export const MessagesTemplatesSearchInput: React.FC<MessagesTemplatesSearchInputProps> =
  ({ value, onChange }) => {
    const { t } = useSafeTranslation();
    const changeHandler = useCallback(
      ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        onChange(value);
      },
      [onChange],
    );

    return (
      <Input
        value={value}
        onChange={changeHandler}
        renderIcon={() => <Icon icon="search" />}
        containerClassName={css.input}
        data-testid="messages-templates_table_search-input"
        renderIconEnd={() =>
          value.trim().length ? (
            <Icon
              icon="close"
              className={css.cross}
              onClick={() => {
                onChange('');
              }}
            />
          ) : null
        }
        placeholder={t('pages.MessagesTemplates.Table.searchInput.placeholder')}
      />
    );
  };

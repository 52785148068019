import i18next from 'i18next';
import memoize from 'lodash-es/memoize';
import { PremiumBillingTab } from './types';

export const PREMIUM_BILLING_PATH = '/premium-billing';

export const BILLING_TAB_QUERY_PARAM = 'view';

export const getPremiumBillingTabs = memoize(() => [
  {
    id: PremiumBillingTab.invoices,
    title: i18next.t('pages.Billing.premiumPage.tabs.invoices'),
  },
  {
    id: PremiumBillingTab.usage,
    title: i18next.t('pages.Billing.premiumPage.tabs.usage'),
  },
]);

import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Price } from './types';
import { DescriptionItemView, TitleProps } from './Primitives';

export interface UpgradePlanProps extends Price, TitleProps {}

export const UpgradePlan: React.FC<UpgradePlanProps> = ({
  price,
  ...props
}) => {
  const { t } = useSafeTranslation();

  return (
    <DescriptionItemView
      title={t('pages.Billing.Table.upgradingPlan')}
      subscription={price}
      {...props}
    />
  );
};

import * as React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useCurrentRoutePath } from '@utils/Routing';
import { MessagesTemplate, MessagesTemplatesTable } from './components';

export const MessagesTemplatesTab: React.FC<RouteComponentProps> = (props) => {
  const path = useCurrentRoutePath(props.match);

  return (
    <Switch>
      <Route path={`${path}/:templateId`} render={() => <MessagesTemplate />} />
      <Route render={() => <MessagesTemplatesTable />} />
    </Switch>
  );
};

import React, { useCallback, useEffect } from 'react';
import { Flex } from '@ui/Flex';
import { useRolePermission } from '@utils/Roles';
import { Permission } from '@common/services/RoleService';
import { FlowBuilderWrapperBox } from '../FlowTab';
import { useLoadingState } from '../FlowTab/useLoadingState';
import { FlowBuilderContainer } from '../FlowTab/FlowBuilderContainer';
import { BroadcastsTypeManagerProvider } from './Broadcast';
import { ReEngageEditorPanelContainer } from './components/ReEngageEditorPanelContainer';
import { EditorPanelPlugin } from '@components/FlowBuilder/EditorPanel/components/editors/EditorPanelPlugin';
import { FlowLoadingState } from '../FlowTab/FlowLoader';
import { useWhatsappBroadcast } from './hooks/useWhatsappBroadcast';
import { OnboardingTourHTMLElementId } from '@globals';
import { useWhatsappTemplateConfig } from './hooks/useWhatsappTemplateConfig';
import { BroadcastStatusCallout } from './components/BroadcastStatusCallout';
import { useOnbordingContext } from '@components/Onboarding/OnboardingContext';
import { isEditableBroadcast } from './utils';

export interface WhatsappReEngageFlowBuilderViewProps {
  flowId: string;
  botId: string;
  broadcastId: string;
  showAside: boolean;
  setShowAside(showAside: boolean): void;
}

export const WhatsappReEngageFlowBuilderView: React.FC<WhatsappReEngageFlowBuilderViewProps> =
  ({ botId, broadcastId, flowId, showAside, setShowAside }) => {
    const { setFlowBuilderReady } = useOnbordingContext();
    const [loadingState, setLoadingState] = useLoadingState();
    const changeLoadingState = useCallback(
      (newState: FlowLoadingState) => {
        setLoadingState(newState);
        if (newState === FlowLoadingState.ready) setFlowBuilderReady(true);
      },
      [setLoadingState, setFlowBuilderReady],
    );

    useEffect(
      () => () => {
        setFlowBuilderReady(false);
      },
      [setFlowBuilderReady],
    );

    const { data, loading } = useWhatsappBroadcast(broadcastId);
    const { config, loading: configLoading } = useWhatsappTemplateConfig({
      flowId,
      botId,
    });
    const hasAnyErrors = Boolean(config?.validation_details);
    const { allowed: editAllowed, loading: permissionLoading } =
      useRolePermission({
        domain: 'flows',
        can: Permission.EDIT,
      });

    const canEdit = isEditableBroadcast(data?.whatsappBroadcast) && editAllowed;

    return (
      <Flex style={{ width: '100%', height: '100%' }}>
        <BroadcastsTypeManagerProvider>
          <ReEngageEditorPanelContainer
            flowId={flowId}
            botId={botId}
            pluginConfigLoading={configLoading}
            hasAnyErrors={hasAnyErrors}
            broadcastId={broadcastId}
            showAside={showAside}
            setShowAside={setShowAside}
          />
        </BroadcastsTypeManagerProvider>

        <FlowBuilderWrapperBox
          id={OnboardingTourHTMLElementId.WAReEngageFlowBuilderWrapper}
        >
          {loadingState === FlowLoadingState.ready && (
            <>
              <EditorPanelPlugin
                editorPanelId={OnboardingTourHTMLElementId.WAEditorPluginPanel}
              />
              <BroadcastStatusCallout id={config?.id} />
            </>
          )}

          <FlowBuilderContainer
            hideVideoTutorial
            flowId={flowId}
            botId={botId}
            loadingState={loadingState}
            permissionLoading={permissionLoading || loading}
            editAllowed={canEdit}
            setLoadingState={changeLoadingState}
          />
        </FlowBuilderWrapperBox>
      </Flex>
    );
  };

import React from 'react';
import { Button } from '@ui/Button';
import { useBotStatusCallout } from './hooks/useBotStatusCallout';
import { useRolePermission } from '@utils/Roles';
import { Permission } from '@common/services/RoleService';
import { BotStatusCalloutView } from './BotStatusCalloutView/BotStatusCalloutView';

export const BotStatusCallout: React.FC = () => {
  const { text, buttonText, action, show } = useBotStatusCallout();
  const { allowed, loading: roleLoading } = useRolePermission({
    domain: 'flows',
    can: Permission.EDIT,
  });

  return (
    <BotStatusCalloutView
      visible={show && !roleLoading}
      button={
        allowed && (
          <Button intent="orange" onClick={action}>
            {buttonText}
          </Button>
        )
      }
      text={text}
    />
  );
};

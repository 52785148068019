import React from 'react';
import { Button, ButtonUnstyled } from '@ui/Button';
import { Item, SimpleCombobox } from '@ui/SimpleCombobox';
import { propEq } from 'ramda';
import { Type } from '@ui/Type';
import { AiIntentFilterType } from '@globals';
import { MenuItem } from '@ui/Menu';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import * as css from './FilterTypeSelector.css';
import { SMALL_MOBILE_WIDTH } from '../../../../../../consts';
import { isMobileDevice } from 'cf-common/src/utils/isMobileDevice';
import { sendEvent } from '@utils/Analytics';
import { useIsWindowWidthLess } from '@utils/DOM/useIsWindowWidthLess';
import { getFilterTypeItems } from '@utils/Ai/getFilterTypeItems';

interface FilterTypeSelectorProps {
  value: AiIntentFilterType | null;
  onChange: (value: AiIntentFilterType) => void;
}

interface FilterTypeItem extends Item {
  tooltipContent: React.ReactNode;
}

export const FilterTypeSelector: React.FC<FilterTypeSelectorProps> = ({
  value,
  onChange,
}) => {
  const isMobileView = useIsWindowWidthLess(SMALL_MOBILE_WIDTH);
  const isMobile = isMobileDevice();
  const items = getFilterTypeItems();

  return (
    <SimpleCombobox<FilterTypeItem>
      selectedItem={items.find(propEq('id', value)) || null}
      items={items}
      downshiftContainerStyle={{
        width: isMobileView ? '100%' : undefined,
      }}
      menuItemStyle={{ minWidth: 150 }}
      onChange={(item) => {
        if (item) {
          onChange(item.id as AiIntentFilterType);
          sendEvent({
            category: 'keywords',
            label: 'keywords groups',
            action: 'keyword item filter type change',
          });
        }
      }}
      renderInput={({ getToggleButtonProps, selectedItem }) => {
        const title = selectedItem ? selectedItem.title : items[0].title;
        return (
          <>
            {isMobileView ? (
              <>
                <Button
                  type="button"
                  intent="secondary"
                  {...getToggleButtonProps()}
                  iconRight={<Icon icon="triangle" />}
                  className={css.button}
                  data-testid="keywords__keywords-groups__filter-type-selector-button-mobile"
                >
                  {title}
                </Button>
                <Spacer factor={2} />
              </>
            ) : (
              <ButtonUnstyled
                {...getToggleButtonProps()}
                title={title}
                data-testid="keywords__keywords-groups__filter-type-selector-button"
              >
                <Type size="15px">
                  <u>{title}</u>
                </Type>
              </ButtonUnstyled>
            )}
          </>
        );
      }}
      renderItem={({ getItemProps, item, index, highlightedIndex }) => (
        <MenuItem
          key={`${item.id}`}
          {...getItemProps({ item })}
          active={index === highlightedIndex}
          title={item.title}
          tooltip={!isMobile ? item.tooltipContent : undefined}
          tooltipType="small"
          tooltipBoundariesElement="viewport"
        />
      )}
    />
  );
};

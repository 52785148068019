import { useCurrentBotId } from '@utils/Routing';
import { useQuery } from '@apollo/react-hooks';
import { AI_GROUPS_QUERY } from './queries';
import {
  AiGroupsQuery,
  AiGroupsQuery_bot_aiBlock_cards_AIPlugin,
  AiGroupsQueryVariables,
} from './@types/AiGroupsQuery';

export const useAiGroups = () => {
  const botId = useCurrentBotId() || '';
  const { data, ...state } = useQuery<AiGroupsQuery, AiGroupsQueryVariables>(
    AI_GROUPS_QUERY,
    {
      skip: !botId,
      variables: {
        botId,
      },
    },
  );

  const aiCard = data?.bot.aiBlock
    .cards?.[0] as AiGroupsQuery_bot_aiBlock_cards_AIPlugin;
  return {
    groups: aiCard?.config.groups,
    cardId: aiCard?.id,
    ...state,
  };
};

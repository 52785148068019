import React, { useMemo } from 'react';
import {
  IntroQuestionsBlockQuestion,
  IntroQuestionsBlockQuestionProps,
} from '../IntroQuestionsBlockQuestion';
import { Spacer } from '@ui/Spacer';
import { Item, SimpleCombobox } from '@ui/SimpleCombobox';
import { Button } from '@ui/Button';
import * as css from './IntroQuestionsBlockQuestionMultiSelect.css';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Type } from '@ui/Type';
import { MenuItem } from '@ui/Menu';
import { Flex } from '@ui/Flex';
import { CheckBox } from '@ui/CheckBox';
import { Icon } from '@ui/Icon';
import noop from 'lodash-es/noop';
import { Input } from '@ui/Input';
import { MENU_ITEM_STYLE } from './consts';
import { OTHER_ITEM_ID } from '../../consts';
import { getOtherFieldId } from '../../helpers';
import {
  IntroQuestionsQuestionHasOtherProps,
  IntroQuestionsQuestionProps,
} from '../../types';

interface IntroQuestionsBlockQuestionMultiSelectProps
  extends IntroQuestionsBlockQuestionProps,
    IntroQuestionsQuestionProps,
    IntroQuestionsQuestionHasOtherProps {
  items: Item[];
  selectedItemIds: string[] | undefined;
  onChange: (selectedItemIds: string[]) => void;
}

export const IntroQuestionsBlockQuestionMultiSelect: React.FC<IntroQuestionsBlockQuestionMultiSelectProps> =
  ({
    id,
    title,
    required,
    items,
    selectedItemIds = [],
    showOtherItem,
    otherItemValue,
    onChangeOtherItemValue,
    onChange,
    onBlur,
    showErrorState,
    showOtherItemErrorState,
    onOtherItemBlur,
    disabled,
    'data-testid': dataTestid,
  }) => {
    const { t } = useSafeTranslation();

    const currentItems = useMemo<Item[]>(
      () => [
        ...items,
        ...(showOtherItem
          ? [
              {
                id: OTHER_ITEM_ID,
                title: t('pages.FirstSession.IntroQuestionsBlock.other'),
              },
            ]
          : []),
      ],
      [items, showOtherItem, t],
    );

    const selectedItemsStr = useMemo(
      () =>
        selectedItemIds
          .map((itemId) => currentItems.find(({ id }) => id === itemId)?.title)
          .join(', '),
      [currentItems, selectedItemIds],
    );

    return (
      <>
        <IntroQuestionsBlockQuestion
          title={title}
          required={required}
          data-testid={dataTestid}
        >
          <SimpleCombobox
            data-testid={`${dataTestid}_selector`}
            menuboxStyle={{ width: 'min(calc(100% - 136px), 800px)' }}
            renderInput={({ getToggleButtonProps }) => {
              const buttonProps = getToggleButtonProps();
              return (
                <Button
                  {...buttonProps}
                  disabled={disabled}
                  intent={showErrorState ? 'error' : 'secondary'}
                  iconRight={<Icon icon="triangle" />}
                  className={css.control}
                  name={id}
                  onBlur={(event) => {
                    buttonProps.onBlur(event);
                    onBlur(event);
                  }}
                  type="button"
                >
                  <Type
                    size="15px"
                    color={selectedItemIds.length ? 'black' : 'grey'}
                  >
                    {selectedItemsStr ||
                      t(
                        'pages.FirstSession.IntroQuestionsBlock.selectPlaceholder',
                      )}
                  </Type>
                </Button>
              );
            }}
            renderItem={({ item, index }) => (
              <MenuItem
                key={item.id}
                titleStyle={MENU_ITEM_STYLE}
                data-testid={`${dataTestid}_selector_item_${index}`}
                titleElement={
                  <Flex
                    alignItems="center"
                    className={css.item}
                    onClick={(event) => {
                      event.stopPropagation();
                      onChange([
                        ...selectedItemIds.filter((id) => id !== item.id),
                        ...(selectedItemIds.includes(item.id) ? [] : [item.id]),
                      ]);
                    }}
                  >
                    <CheckBox
                      data-testid={`${dataTestid}_selector_item_checkbox_${index}`}
                      checked={selectedItemIds.includes(item.id)}
                      onChange={noop}
                    />
                    <Spacer factor={1} horizontalFactor={2} />
                    <Type size="15px">{item.title}</Type>
                  </Flex>
                }
              />
            )}
            selectedItem={null}
            items={currentItems}
          />
          {selectedItemIds.includes(OTHER_ITEM_ID) && (
            <>
              <Spacer factor={2} />
              <Input
                className={css.control}
                value={otherItemValue || ''}
                onChange={({ currentTarget: { value } }) => {
                  onChangeOtherItemValue?.(value);
                }}
                placeholder={t(
                  'pages.FirstSession.IntroQuestionsBlock.otherPlaceholder',
                )}
                name={getOtherFieldId(id)}
                onBlur={onOtherItemBlur}
                error={showOtherItemErrorState}
                data-testid={`${dataTestid}_other-input`}
              />
            </>
          )}
        </IntroQuestionsBlockQuestion>
        <Spacer factor={8} />
      </>
    );
  };

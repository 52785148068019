import React from 'react';
import cn from 'classnames';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { CollapsibleSectionWithShadow } from '@ui/CollapsibleSection/CollapsibleSectionWithShadow/CollapsibleSectionWithShadow';
import { UserFilterControl } from '@components/FlowBuilder/EditorPanel/components/common/UserFilter';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { DateControl } from './DateControl';
import { TimeControl } from './TimeControl';
import { RepeatControl, RepeatControlProps } from './RepeatControl';
import * as css from './common.css';
import { Moment, TimePickerProps } from '@ui/DatePicker';
import { useCurrentBotId } from '@utils/Routing';
import { Disableable, WhatsappBroadcastConfig } from '../types';
import { UserFilterCountBroadcast } from './UserFilterCount/UserFilterCount';

interface DateControlProps {
  dateValue: Moment;
  dateError: boolean;
  onDateChange: (value: Moment) => void;
}

interface TimeControlProps {
  timeValue: string;
  timeError: boolean;
  onTimeChange: TimePickerProps['onChange'];
}

export interface AttributeFilterProps {
  updateConfig: (value: WhatsappBroadcastConfig) => void;
  config: WhatsappBroadcastConfig;
  isSaving: boolean;
  saveUserFilterConfig: () => void;
  saveUserFilterConfigAsync: () => Promise<unknown>;
}

export interface ScheduleSettingsBlockProps
  extends DateControlProps,
    TimeControlProps,
    AttributeFilterProps,
    RepeatControlProps,
    Disableable {
  isDraft: boolean;
}

export const ScheduleSettingsBlock: React.FC<ScheduleSettingsBlockProps> = ({
  isDraft,
  disabled,
  timeError,
  dateValue,
  dateError,
  onDateChange,
  timeValue,
  onTimeChange,
  config,
  updateConfig,
  saveUserFilterConfig,
  saveUserFilterConfigAsync,
  isSaving,
  repeatValue,
  onRepeatChange,
  customWeekdayValue,
  onCustomWeekdayClick,
}) => {
  const botId = useCurrentBotId()!;
  const { t } = useSafeTranslation();

  return (
    <div>
      <CollapsibleSectionWithShadow
        title={t(
          'pages.BotPage.ReengageWhatsappTab.panel.settings.schedule.scheduleMessage',
        )}
      >
        <Flex flexDirection="column" className={css.sectionWrapper}>
          <Flex>
            <DateControl
              error={dateError}
              disabled={!isDraft}
              value={dateValue}
              onChange={onDateChange}
            />

            <Spacer factor={0} horizontalFactor={3} />

            <TimeControl
              disabled={!isDraft}
              error={timeError}
              value={timeValue}
              onChange={onTimeChange}
            />
          </Flex>

          {(timeError || dateError) && (
            <>
              <Spacer factor={2} />

              <Type size="12px" color="red">
                {t('pages.BotPage.ReengageWhatsappTab.panel.timeError')}
              </Type>
            </>
          )}

          <Spacer factor={3} />

          <RepeatControl
            disabled={!isDraft}
            repeatValue={repeatValue}
            onRepeatChange={onRepeatChange}
            customWeekdayValue={customWeekdayValue}
            onCustomWeekdayClick={onCustomWeekdayClick}
          />
        </Flex>
      </CollapsibleSectionWithShadow>

      <Spacer factor={8} />

      <CollapsibleSectionWithShadow
        title={t(
          'pages.BotPage.ReengageWhatsappTab.panel.settings.schedule.choosePeopple',
        )}
      >
        <Flex
          flexDirection="column"
          className={cn(css.sectionWrapper, css.userFilterWrapper)}
        >
          <UserFilterControl
            disabled={disabled}
            botId={botId}
            config={config.userFilter}
            saveConfig={saveUserFilterConfig}
            saveConfigAsync={saveUserFilterConfigAsync}
            isLoading={isSaving}
            onConfigUpdate={(userFilter) =>
              updateConfig({ ...config, userFilter })
            }
            // TODO
            validationErrors={{}}
          />

          <Spacer factor={4} />

          {!disabled && (
            <UserFilterCountBroadcast segmentation={config.userFilter} />
          )}
        </Flex>
      </CollapsibleSectionWithShadow>
    </div>
  );
};

import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Icon } from '@ui/Icon';
import {
  Download,
  DownloadProps,
} from '@components/Payments/PaymentHistory/parts';

export interface DownloadButtonProps extends DownloadProps {
  error: boolean;
  hideLabel?: boolean;
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  onDownload,
  error,
  hideLabel,
}) => {
  const { t } = useSafeTranslation();

  return (
    <Flex>
      {error ? (
        <Flex alignItems="center">
          {hideLabel && (
            <>
              <Type size="12px" color="red" weight="medium">
                {t('pages.Billing.Table.paymentFailed')}
              </Type>
              <Spacer factor={0} horizontalFactor={2} />
            </>
          )}
          <Icon icon="info" size="24px" color="red" />
        </Flex>
      ) : (
        <Download
          onDownload={onDownload}
          data-testid="payment-history__download"
        />
      )}
    </Flex>
  );
};

import { ADMIN_PHONES_QUERY } from './queries';
import { useCallback, useState } from 'react';
import client from '@common/services/ApolloService';

export const useRefetchAdminPhones = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const refetch = useCallback(async () => {
    setLoading(true);
    await client.query({
      query: ADMIN_PHONES_QUERY,
      fetchPolicy: 'network-only',
    });
    setLoading(false);
  }, []);
  return { refetch, loading };
};

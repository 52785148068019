import { useDialoguesPricing } from '@utils/Data/Pricing';
import { log } from 'cf-common/src/logger';
import {
  DialogsPricingQuery,
  DialogsPricingQueryVariables,
} from '@utils/Data/Pricing/@types/DialogsPricingQuery';
import { Period, TierType } from '@globals';
import { QueryHookOptions } from 'react-apollo';

export interface DialoguesPricingDataProps
  extends ReturnType<typeof useDialoguesPricing> {
  isTrial: boolean;
  isPlanChanged: boolean;
  isAnnualSubscription: boolean;
  isAdjustmentFeeBillingUpcoming: boolean;
  shouldOfferSubscription: boolean;
}

export const useDialoguesPricingData = (
  options?: QueryHookOptions<DialogsPricingQuery, DialogsPricingQueryVariables>,
): DialoguesPricingDataProps => {
  const pricingData = useDialoguesPricing({
    ...options,
    onError: (error) => {
      log.error({ msg: 'Cannot load pricing data', error });
    },
  });

  const {
    current_tier,
    current_subscription_period,
    next_subscription_period,
    next_tier,
    adjustment_fee,
    invoice_total,
  } = pricingData.pricing || {};

  const isPlanChanged = next_tier !== null && next_tier !== TierType.trial;
  const isAnnualSubscription = current_subscription_period === Period.annual;
  const isNextAnnualSubscription = next_subscription_period === Period.annual;
  const isTrial = current_tier === TierType.trial;

  const isAdjustmentFeeBillingUpcoming =
    invoice_total === adjustment_fee?.price;

  const shouldOfferSubscription =
    !isTrial &&
    !next_tier &&
    [Period.monthly, Period.semiannual].includes(
      current_subscription_period as Period,
    ) &&
    !isNextAnnualSubscription;

  return {
    ...pricingData,
    isTrial,
    isPlanChanged,
    isAnnualSubscription,
    isAdjustmentFeeBillingUpcoming,
    shouldOfferSubscription,
  };
};

import React from 'react';
import { Box } from '@ui/Box';
import * as css from './ContactTagsCard.css';
import { Contact } from '../useContactQuery';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { ContactTag } from '../ContactTag/ContactTag';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Spacer } from '@ui/Spacer';
import { AddTagsCombobox } from '../AddTagsCombobox/AddTagsCombobox';
import { useUpdateBotTags } from '../useUpdateBotTags';
import { useRolePermission } from '@utils/Roles';
import { Permission } from '@common/services/RoleService';
import { sendEvent } from '@utils/Analytics';
import { useCurrentContactId } from '@utils/Routing';

export const ContactTagsCard: React.FC<{
  tags: Contact['tags'];
  onDelete: (tagId: string) => void;
}> = ({ tags, onDelete }) => {
  const { t } = useSafeTranslation();
  const contactId = useCurrentContactId();
  const { allowed: editAllowed } = useRolePermission({
    domain: 'people',
    can: Permission.EDIT,
  });

  const { addTag, createTag } = useUpdateBotTags(tags || []);

  const isNotEmpty = Boolean(tags?.length);
  return (
    <Box
      data-testid="user-card_tags-card"
      className={css.peopleCard}
      color="white"
      borderRadiusFactor={4}
      paddingFactor={4}
    >
      <Flex gapFactor={5}>
        {isNotEmpty && (
          <Type
            size="24px"
            weight="bold"
            color="baseNormal"
            data-testid="tags-card_tags-count"
          >
            {t('PeoplePage.tags', { count: tags.length })}
          </Type>
        )}
        {!isNotEmpty && (
          <Type
            size="24px"
            weight="bold"
            color="baseTertiary"
            data-testid="tags-card_no-tags"
          >
            {t('PeoplePage.emptyTags')}
          </Type>
        )}
      </Flex>

      {isNotEmpty && (
        <>
          <Spacer factor={2} />
          <Flex gapFactor={2} flexWrap>
            {tags.map((tag) => (
              <ContactTag
                key={tag.id}
                onDelete={
                  editAllowed
                    ? () => {
                        sendEvent({
                          category: 'contact profile',
                          action: 'delete',
                          label: 'tag',
                          propertyBag: {
                            contactId,
                            tab: 'people tab',
                          },
                        });
                        onDelete(tag.id);
                      }
                    : undefined
                }
              >
                {tag.name}
              </ContactTag>
            ))}
          </Flex>
        </>
      )}
      {editAllowed && (
        <>
          <Spacer factor={4} />
          <AddTagsCombobox
            data-testid="tags-card_add_new_tag"
            contactTags={tags}
            createTag={(name) => {
              sendEvent({
                category: 'contact profile',
                action: 'add',
                label: 'tag',
                propertyBag: {
                  contactId,
                  tab: 'people tab',
                },
              });
              return createTag(
                { name },
                {
                  contactTags: tags,
                  botTags: tags,
                },
              );
            }}
            selectTag={(item) => {
              sendEvent({
                category: 'contact profile',
                action: 'add',
                label: 'tag',
                propertyBag: {
                  contactId,
                  tab: 'people tab',
                },
              });
              return addTag(item);
            }}
          />
        </>
      )}
    </Box>
  );
};

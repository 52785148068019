import { sendEvent } from '@utils/Analytics';
import { HOME_TAB_EVENT_CATEGORY } from './consts';
import { CurrentBotQuery_bot } from '@utils/Data/Bot/CurrentBot/@types/CurrentBotQuery';

export const sendHomeTabEvent = (
  event: Omit<PureAnalyticsEvent, 'category'>,
) => {
  sendEvent({
    ...event,
    category: HOME_TAB_EVENT_CATEGORY,
  });
};

export const getSubscribersLeft = (bot: CurrentBotQuery_bot) =>
  bot.limits.usersLimit - (bot.pricingData?.reached_limit || 0);

import React from 'react';
import cn from 'classnames';
import { CenteredLoader } from '@ui/Loader';
import { SectionBox } from '@components/SectionBox';
import * as css from './AIStatisticsLoaderView.css';

export const AIStatisticsLoaderView = () => (
  <SectionBox>
    <CenteredLoader className={cn(css.block)} />
  </SectionBox>
);

import { showSomethingWentWrongToaster } from '@services/MessageService';
import { QueryHookOptions, useQuery } from 'react-apollo';
import { log } from 'cf-common/src/logger';
import { IntroQuestionsSavedDataQuery } from './@types/IntroQuestionsSavedDataQuery';
import { INTRO_QUESTIONS_SAVED_DATA_QUERY } from './queries';

export const useIntroQuestionsSavedDataQuery = (
  options?: QueryHookOptions<IntroQuestionsSavedDataQuery>,
) =>
  useQuery<IntroQuestionsSavedDataQuery>(INTRO_QUESTIONS_SAVED_DATA_QUERY, {
    onError: (error) => {
      log.warn({ error, msg: 'Error load intro questions saved data' });
      showSomethingWentWrongToaster();
    },
    ...options,
  });

import {
  Feature,
  FlowBuilder as FlowBuilderField,
  ViewMode,
} from '@components/FlowBuilder';
import React, { useEffect, useState } from 'react';
import { FlowLoader, FlowLoadingState } from './FlowLoader';
import { FlowNotFoundPlaceholder } from './TabPlaceholders/FlowNotFoundPlaceholder';
import { useFlowBuilderStateWriter } from '@components/FlowBuilder/hooks/flowBuilderStateLog/useFlowBuilderStateLog';
import { useLoadAggregatedFlow } from './useLoadAggregatedFlow';

export interface FlowBuilderContainerProps {
  flowId: string;
  botId: string;
  loadingState: FlowLoadingState;
  setLoadingState: (state: FlowLoadingState) => void;
  permissionLoading: boolean;
  editAllowed: boolean;
  hideVideoTutorial?: boolean;
}

export const FlowBuilderContainer: React.FC<FlowBuilderContainerProps> = ({
  flowId,
  botId,
  loadingState,
  permissionLoading,
  editAllowed,
  setLoadingState,
  hideVideoTutorial,
}) => {
  const [flowNotFound, setFlowNotFound] = useState(false);

  useFlowBuilderStateWriter(flowId, loadingState, flowNotFound);
  const loadFlow = useLoadAggregatedFlow(botId, () => setFlowNotFound(true));

  useEffect(() => {
    setFlowNotFound(false);
  }, [flowId]);

  if (flowNotFound) {
    return <FlowNotFoundPlaceholder flowId={flowId} />;
  }

  return botId && flowId ? (
    <>
      <FlowLoader state={loadingState} iconSize={150} />
      {!permissionLoading /** TODO: make loadMode for parallel loading of flow and permissions */ && (
        <FlowBuilderField
          flowId={flowId}
          availableFeatures={[Feature.gestures]}
          mode={editAllowed ? ViewMode.edit : ViewMode.view}
          loadFlow={() => loadFlow(botId, flowId)}
          onError={() => {
            setFlowNotFound(true);
            setLoadingState(FlowLoadingState.ready);
          }}
          onLoading={() => setLoadingState(FlowLoadingState.fetching)}
          onReady={() => setLoadingState(FlowLoadingState.rendering)}
          onDestroy={() => setLoadingState(FlowLoadingState.none)}
          onRendered={() => setLoadingState(FlowLoadingState.ready)}
          hideVideoTutorial={hideVideoTutorial}
        />
      )}
    </>
  ) : null;
};

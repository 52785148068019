import React, { useState } from 'react';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { BotSelector } from '@components/BotSelector';
import { Flex } from '@ui/Flex';
import { Button } from '@ui/Button';
import { useCurrentBotId } from '@utils/Routing';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useBotAllowedPlatforms } from '@utils/Platform/useBotAllowedPlatforms';

interface CloneFlowGroupModalProps {
  onSubmit: (params: { botId: string }) => void;
}

export const CloneFlowGroupModal: React.FC<CloneFlowGroupModalProps> = ({
  onSubmit,
}) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId();
  const [selectedBotId, setSelectedBotId] = useState(botId);

  const { allowedPlatforms } = useBotAllowedPlatforms(botId);

  if (!botId) {
    return null;
  }

  return (
    <div>
      <Type color="greyDark" size="15px_DEPRECATED">
        {t('MoveFlowGroupModal-JSXText--125-to-the-bot')}
      </Type>
      <Spacer factor={1} />
      <BotSelector
        onChange={({ id }) => {
          setSelectedBotId(id);
        }}
        platforms={allowedPlatforms}
        selectedBotId={selectedBotId}
        excludedBotIds={[]}
      />
      <Spacer factor={9} />
      <Flex justifyContent="flex-end">
        <Button
          disabled={!selectedBotId}
          onClick={() => {
            if (selectedBotId) {
              onSubmit({ botId: selectedBotId });
            }
          }}
        >
          {t('MoveFlowGroupModal-string--390-copy-group')}
        </Button>
      </Flex>
    </div>
  );
};

import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { MenuWithButton } from './MenuWithButton';
import { FooterMenuProps } from './FooterMenu';

export interface PausedButtonWithMenuProps extends FooterMenuProps {
  onPauseButtonClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const PausedButtonWithMenu: React.FC<PausedButtonWithMenuProps> = ({
  onPauseButtonClick,
  onCopyButtonClick,
  onDeleteButtonClick,
}) => {
  const { t } = useSafeTranslation();

  return (
    <MenuWithButton
      onClick={onPauseButtonClick}
      onCopyButtonClick={onCopyButtonClick}
      onDeleteButtonClick={onDeleteButtonClick}
      data-testId="reengage-panel__live-button"
    >
      {t('pages.BotPage.ReengageWhatsappTab.panel.buttons.paused')}
    </MenuWithButton>
  );
};

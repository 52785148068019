import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Loader } from '@ui/Loader';
import { getPlaceholderLeftShift } from '../utils';
import * as css from './FlowItemPlaceholder.css';
import { FlowElementType } from '../types';

interface FlowItemPlaceholderProps
  extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  loading?: boolean;
  elementType?: FlowElementType;
  nestedLevel?: number;
  icon?: React.ReactNode;
}

export const FlowItemPlaceholder: React.FC<FlowItemPlaceholderProps> = ({
  title,
  loading = true,
  nestedLevel,
  style,
  className,
  icon,
  ...props
}) => {
  return (
    <Flex
      {...props}
      data-testid="add-entry-point"
      alignItems="center"
      justifyContent="space-between"
      className={cn(css.placeholder, className)}
      style={{
        paddingLeft: getPlaceholderLeftShift(nestedLevel),
        cursor: props.onClick ? 'pointer' : 'initial',
        ...style,
      }}
    >
      <Flex alignItems="center">
        {icon}
        <Type as="span" color="greyDark" size="15px_DEPRECATED">
          {title}
        </Type>
      </Flex>
      {loading && <Loader size="s" />}
    </Flex>
  );
};

import { useMemo } from 'react';
import { BotStatusCalloutState } from '../consts';
import { useSafeTranslation } from '@utils/useSafeTranslation';

export const useBotStatusCalloutTexts = (state: BotStatusCalloutState) => {
  const { t } = useSafeTranslation();
  return useMemo(() => {
    switch (state) {
      case BotStatusCalloutState.activateEntryPoint:
        return {
          text: t(
            'pages.BotPage.FlowTab.BotStatusCallout.activateEntryPoint.text',
          ),
          buttonText: t(
            'pages.BotPage.FlowTab.BotStatusCallout.activateEntryPoint.buttonText',
          ),
        };
      case BotStatusCalloutState.addEntryPoint:
        return {
          text: t('pages.BotPage.FlowTab.BotStatusCallout.addEntryPoint.text'),
          buttonText: t(
            'pages.BotPage.FlowTab.BotStatusCallout.addEntryPoint.buttonText',
          ),
        };
      case BotStatusCalloutState.connectWhatsapp:
        return {
          text: t(
            'pages.BotPage.FlowTab.BotStatusCallout.connectWhatsapp.text',
          ),
          buttonText: t(
            'pages.BotPage.FlowTab.BotStatusCallout.connectWhatsapp.buttonText',
          ),
        };
      case BotStatusCalloutState.connectPage:
      default:
        return {
          text: t('pages.BotPage.FlowTab.BotStatusCallout.connectPage.text'),
          buttonText: t(
            'pages.BotPage.FlowTab.BotStatusCallout.connectPage.buttonText',
          ),
        };
    }
  }, [state, t]);
};

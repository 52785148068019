import { RoleIds } from '../../../../components/settings/admins/RolesList';
import i18n from 'i18next';
import { Translation } from '@translations';

export const getRoleTexts = (roleId: RoleIds) => {
  switch (roleId) {
    case RoleIds.admin:
      return {
        title: i18n.t<string, Translation>(
          'components.settings.admins.RolesList.admin',
        ),
        tooltip: i18n.t<string, Translation>(
          'components.settings.admins.RolesList.adminNote',
        ),
      };
    case RoleIds.editor:
      return {
        title: i18n.t<string, Translation>(
          'components.settings.admins.RolesList.editor',
        ),
        tooltip: i18n.t<string, Translation>(
          'components.settings.admins.RolesList.editorNote',
        ),
      };
    case RoleIds.marketer:
      return {
        title: i18n.t<string, Translation>(
          'components.settings.admins.RolesList.marketer',
        ),
        tooltip: i18n.t<string, Translation>(
          'components.settings.admins.RolesList.marketerNote',
        ),
      };
    case RoleIds.operator:
      return {
        title: i18n.t<string, Translation>(
          'components.settings.admins.RolesList.operator',
        ),
        tooltip: i18n.t<string, Translation>(
          'components.settings.admins.RolesList.operatorNote',
        ),
      };
    case RoleIds.viewer:
      return {
        title: i18n.t<string, Translation>(
          'components.settings.admins.RolesList.viewer',
        ),
        tooltip: i18n.t<string, Translation>(
          'components.settings.admins.RolesList.viewerNote',
        ),
      };
    case RoleIds.whiteLabel:
      return {
        title: i18n.t<string, Translation>(
          'components.settings.admins.RolesList.whiteLabel',
        ),
        tooltip: i18n.t<string, Translation>(
          'components.settings.admins.RolesList.whiteLabelNote',
        ),
      };
    default:
      return {
        title: '',
        tooltip: '',
      };
  }
};

import { PaymentDetailsStatus } from '@globals';
import Maybe from 'graphql/tsutils/Maybe';

const ACTIVE_PAID_STATUSES = [
  PaymentDetailsStatus.active,
  PaymentDetailsStatus.to_be_cancelled,
];

interface WorkspaceSubscriptionWithStatus {
  __typename: 'WorkspaceBotPageSubscription';
  status: PaymentDetailsStatus;
}

export interface WorkspaceWithSubscription {
  __typename: 'Workspace';
  id: string;
  subscription: WorkspaceSubscriptionWithStatus | null;
}

export const isPaidWorkspaceSubscription = <
  T extends WorkspaceWithSubscription,
>(
  workspace: Maybe<T>,
) =>
  workspace?.subscription?.status
    ? ACTIVE_PAID_STATUSES.includes(workspace.subscription.status)
    : false;

import React from 'react';
import { Input, InputProps } from '@ui/Input';
import { InputMenuCombobox } from '@ui/SimpleCombobox';
import { combineRefs } from '@utils/combineRefs';
import { MenuItem } from '@ui/Menu';
import { useCurrentBotId } from '@utils/Routing';
import { useAttributesValues } from '@utils/AttributesUtils/AttributesData';
import { VariablesType } from '@utils/AttributesUtils/AttributesUtilsTypes';
import { getAttributeSuggestsForParameter } from '@components/FlowBuilder/EditorPanel/components/common/UserFilter/components/GenericFilter';
import { UserAttributeType } from '@globals';

interface EditAttributeValueProps {
  variable: string;
  onChange: (name: string) => void;
  value: string;
  inputProps?: InputProps;
}

export const EditAttributeValue: React.FC<EditAttributeValueProps> = ({
  variable,
  onChange,
  value,
  inputProps = {},
}) => {
  const botId = useCurrentBotId();
  const { attributeValuesSuggests } = useAttributesValues(
    botId!,
    VariablesType.custom,
    variable,
    value,
  );

  const selectedAttributesValues = getAttributeSuggestsForParameter(
    { type: UserAttributeType.custom, name: variable },
    attributeValuesSuggests,
  );

  return (
    <InputMenuCombobox
      autoSelectFirstItem
      onChange={(item) => {
        onChange(item?.value || '');
      }}
      items={selectedAttributesValues}
      renderInput={({ popperReference, downshift, props }) => (
        <Input
          ref={combineRefs([popperReference.ref, props.inputRef])}
          {...downshift.getInputProps({
            onFocus: () => {
              downshift.openMenu();
              props.selectInputValue();
            },
          })}
          {...inputProps}
          value={value}
          onChange={(ev) => onChange(ev.target.value || '')}
        />
      )}
      renderEmptyState={() => null}
    >
      {({
        downshift: { getItemProps, highlightedIndex },
        props: { item, index },
      }) => (
        <MenuItem
          title={item.value}
          active={index === highlightedIndex}
          key={item.value}
          {...getItemProps({ item })}
        />
      )}
    </InputMenuCombobox>
  );
};

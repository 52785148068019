import React from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { DateFormat, DateUtils } from '@utils/DateTime';
import { DescriptionItemView, TitleProps } from './Primitives';
import { UnixTime } from '../types';

export interface PrepaymentDelayDebtProps extends TitleProps {
  firstDate: Maybe<UnixTime>;
  lastDate: Maybe<UnixTime>;
}

export const PrepaymentDelayDebt: React.FC<PrepaymentDelayDebtProps> = ({
  firstDate,
  lastDate,
  ...props
}) => {
  const { t } = useSafeTranslation();

  let subscription = '';
  let days = null;

  if (firstDate && lastDate) {
    const first = DateFormat.MMMDD(firstDate);
    const last = DateFormat.MMMDD(lastDate);

    subscription = `${first} - ${last}`;
    days = DateUtils.daysPassedFromDate(lastDate, firstDate);
  }

  return (
    <DescriptionItemView
      title={t('pages.Billing.Table.nDaysOfPaymentDelay', {
        days: days ? days.toString() : '',
        count: days || 0,
      })}
      subscription={subscription}
      {...props}
    />
  );
};

import React, { useEffect } from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { Flex } from '@ui/Flex';
import { Type, TypeProps } from '@ui/Type';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useBotFeatures } from '@utils/Data/Bot';
import { FormattedNumber } from './FormattedNumber';
import { StatItemBase } from '../AIStatistics/StatItem';
import { sendHomeTabEvent } from '../../utils';
import { Translation } from '@translations';
import { sendEventToGA } from '@utils/Analytics';
import { LS, LSKey } from 'cf-common/src/localStorage';
import { seenAtLeast1DialogEvent } from 'cf-common/src/conversionTracking';

interface ConversationsViewProps {
  numerator: number;
  denominator: Maybe<number>;
  captionKey: Translation;
  color?: TypeProps['color'];
  hideConversations?: boolean;
}

export const ConversationsView: React.FC<ConversationsViewProps> = ({
  numerator,
  denominator,
  color,
  hideConversations = false,
  captionKey,
}) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  const { botFeatures } = useBotFeatures(botId);
  useEffect(() => {
    if (
      numerator >= (LS.get(LSKey.testDialogsQtyForSendActiveUserEvent) || 10)
    ) {
      sendEventToGA({
        type: 'user',
        name: 'active',
        label: 'payment',
      });
    }
    if (numerator >= 1) {
      sendEventToGA({
        type: 'user',
        name: 'active1',
        label: 'payment',
      });
      seenAtLeast1DialogEvent(botId!);
    }
  }, [numerator, botId]);

  return (
    <StatItemBase
      linkTo={getTabLink(BotTabs.liveChat, botId)}
      caption={t(captionKey)}
      data-testid="bot-panel__conversation-link"
      onClick={() => sendHomeTabEvent({ action: 'go to live chat tab click' })}
      disabled={!botFeatures?.livechat_v2}
    >
      <Flex inline>
        <FormattedNumber number={numerator} color={color} />
        {!hideConversations && (
          <>
            <Type as="span" size="24px" weight="medium">
              /
            </Type>
            <FormattedNumber number={denominator} />
          </>
        )}
      </Flex>
    </StatItemBase>
  );
};

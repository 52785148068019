import { useCallback } from 'react';
import { mapQuery } from '@utils/GQL/mapQuery';
import gql from 'graphql-tag';
import { useApolloClient, useMutation } from 'react-apollo';
import {
  ChangeUserRoleIdInWorkspaceMutation,
  ChangeUserRoleIdInWorkspaceMutationVariables,
} from './@types/ChangeUserRoleIdInWorkspaceMutation';
import {
  WorkspaceAdminsAndInvitesQuery,
  WorkspaceAdminsAndInvitesQueryVariables,
} from './@types/WorkspaceAdminsAndInvitesQuery';
import { WORKSPACE_ADMINS_AND_INVITES_QUERY } from './useWorkspaceUsersAndInvites';

const CHANGE_USER_ROLE_ID_IN_WORKSPACE_MUTATION = gql`
  mutation ChangeUserRoleIdInWorkspaceMutation(
    $workspaceId: String!
    $userId: String!
    $roleId: String!
  ) {
    changeUserRoleIdInWorkspace(
      workspaceId: $workspaceId
      userId: $userId
      roleId: $roleId
    ) {
      changed
    }
  }
`;

export const useChangeUserRoleIdInWorkspace = (workspaceId: string) => {
  const client = useApolloClient();
  const [mutation, queryResult] = useMutation<
    ChangeUserRoleIdInWorkspaceMutation,
    ChangeUserRoleIdInWorkspaceMutationVariables
  >(CHANGE_USER_ROLE_ID_IN_WORKSPACE_MUTATION);

  const changeUserRoleId = useCallback(
    (variables: ChangeUserRoleIdInWorkspaceMutationVariables) => {
      return mutation({ variables }).then(() => {
        mapQuery<
          WorkspaceAdminsAndInvitesQuery,
          WorkspaceAdminsAndInvitesQueryVariables
        >(
          client,
          {
            query: WORKSPACE_ADMINS_AND_INVITES_QUERY,
            variables: { workspaceId },
          },
          (data) => {
            const admins =
              data.workspaceAdmins?.map((admin) =>
                admin.id === variables.userId
                  ? { ...admin, roleId: variables.roleId }
                  : admin,
              ) || [];

            return {
              ...data,
              workspaceAdmins: admins,
            };
          },
        );
      });
    },
    [client, mutation, workspaceId],
  );

  return [changeUserRoleId, queryResult] as const;
};

import React from 'react';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Page } from '@ui/Page';
import { PageContent } from '@ui/PageContent';
import { PageHeader } from '@components/PageHeader';
import { LogoSize } from '@components/PageHeader/WhiteLabelLogo';
import { WorkspaceName, WorkspaceNameProps } from './WorkspaceName';
import { PremiumBillingTab } from './types';
import { BILLING_TAB_QUERY_PARAM, getPremiumBillingTabs } from './constants';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useQueryParams } from '@utils/queryParams';
import { PageNavigationTabs } from '@ui/PageNavigationTabs';
import css from './PremiumBillingPage.css';

interface PremiumBillingContainerProps {
  workspaceName: WorkspaceNameProps['workspaceName'];
  children(tab: PremiumBillingTab): React.ReactNode;
}

export const PremiumBillingLayout: React.FC<PremiumBillingContainerProps> = ({
  children,
  workspaceName,
}) => {
  const { t } = useSafeTranslation();
  const { [BILLING_TAB_QUERY_PARAM]: activeTab = PremiumBillingTab.invoices } =
    useQueryParams();
  return (
    <Page className={css.page}>
      <PageHeader logoSize={LogoSize.default} />
      <PageContent>
        <Flex
          flexDirection="column"
          alignItems="center"
          className={css.premiumBillingPage}
        >
          <Spacer factor={6} />
          <Type size="24px" weight="semibold">
            {t('pages.Billing.premiumPage.billing')}
          </Type>
          <Spacer factor={1} />
          <WorkspaceName workspaceName={workspaceName} />
          <Spacer factor={3} />
          <PageNavigationTabs
            containerClassName={css.segmentButtonContainer}
            className={css.segmentButton}
            tabColor="toggleBlueOnly"
            tabs={getPremiumBillingTabs()}
            initialTabId={PremiumBillingTab.invoices}
            navParam={BILLING_TAB_QUERY_PARAM}
          />
          <Spacer factor={4} />
          {children(activeTab)}
          <Spacer factor={20} />
        </Flex>
      </PageContent>
    </Page>
  );
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { BackButton } from '@components/BackButton';
import { Header } from './Header';
import { NameYourMessageControl } from './NameYourMessageControl';
import { SendThisMessageControl } from './SendThisMessageControl';
import { SettingsBlocksFactory } from './SettingsBlocksFactory';
import { FooterButtonFactory } from './FooterButtonFactory';
import { useUpdateBroadcast } from '../hooks/useUpdateBroadcast';
import { useUpdateBroadcastStatus } from '../hooks/useUpdateBroadcastStatus';
import { useDeleteWhatsappBroadcast } from '../hooks/useDeleteWhatsappBroadcast';
import { BroadcastTypes, useBroadcastsTypeManager } from '../Broadcast';
import { useReengageEditorPanelProps } from '../hooks/useReengageEditorPanelProps';
import * as css from './ReEngageEditorPanel.css';
import { useWhatsappConfigChanged } from '../hooks/useWhatsappConfigChanged';
import { useCopyWhatsappBroadcastMutation } from '../hooks/useCopyWhatsappBroadcastMutation';
import { UseEditBroadcastCacheProps } from '../hooks/useEditBroadcastCache';
import {
  useGoToWhatsappBroadcastPage,
  BotTabs,
  getTabLink,
  ReEngageType,
  useCurrentBotId,
} from '@utils/Routing';
import { deleteBroadcastConfirm } from '../helpers/deleteBroadcastConfirm';
import { DEFAULT_DAYS, isStartOrUnpauseButtonDisabled, logger } from '../utils';
import { FormValidationErrors } from '../types';
import { toaster } from '@services/MessageService';
import { useShopifyAccount } from '@utils/Integrations/Shopify/useShopifyAccount';
import { truncateWithEllipses } from 'cf-common/src/utils/String/truncateWithEllipses';
import { BroadcastStatus, OnboardingTourHTMLElementId } from '@globals';
import { showConfirmationModal } from './ConfirmationModal';
import { useCreateWhatsappTemplateInCfEnable } from '@utils/Whatsapp/hooks/useCreateWhatsappTemplateInCfEnable';

export interface ReEngageEditorPanelProps extends UseEditBroadcastCacheProps {
  loading: boolean;
  startButtonDisabled: boolean;
}

export const ReEngageEditorPanel: React.FC<ReEngageEditorPanelProps> = ({
  loading: outsideLoading,
  startButtonDisabled,
  ...props
}) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  const { isCreateWhatsappTemplateInCfEnable } =
    useCreateWhatsappTemplateInCfEnable();
  const { account } = useShopifyAccount();
  const history = useHistory();
  const { broadcastsTypeManager } = useBroadcastsTypeManager();
  const {
    editBroadcast,
    disabled,
    dateValue,
    timeValue,
    triggerValue,
    sendInValue,
    repeatValue,
    onRepeatChange,
    onCustomWeekdayClick,
    onSendInValueChange,
    onSendInUnitChange,
    sendInUnitValue,
    orderStatusUpdateStatus,
    onOrderStatusUpdateStatusChange,
  } = useReengageEditorPanelProps(props);
  const [setBroadcastCopy, { loading: copyLoading }] =
    useCopyWhatsappBroadcastMutation();
  const { setBroadcastLive, setBroadcastPause, setBroadcastUnpause } =
    useUpdateBroadcastStatus();
  const [setBroadcastDelete, { loading: deleteLoading }] =
    useDeleteWhatsappBroadcast();
  const goToBroadcastPage = useGoToWhatsappBroadcastPage();
  const [updateBroadcast, { formError }] = useUpdateBroadcast(
    props.botId,
    editBroadcast.config,
  );
  const broadcastType = broadcastsTypeManager.getTypeFromConfig(
    editBroadcast.config,
  );

  useWhatsappConfigChanged(props.broadcastId, updateBroadcast);

  const items = broadcastsTypeManager.getBroadcasts().map((broadcast) => ({
    id: broadcast.type,
    title: broadcast.title,
  }));
  const startButtonTitle =
    broadcastsTypeManager.getBroadcastByType(broadcastType).buttonTitle;

  const loading = deleteLoading || copyLoading || outsideLoading;
  const isDraft = editBroadcast.config.status === BroadcastStatus.DRAFT;
  const isStartButtonDisabled = isStartOrUnpauseButtonDisabled(
    editBroadcast.config.triggerSettings?.triggerType,
    Boolean(account),
  );

  return (
    <Flex
      id={OnboardingTourHTMLElementId.WAReEngageEditorPanel}
      flexDirection="column"
      justifyContent="space-between"
      className={css.wrapper}
    >
      <Flex flexDirection="column">
        <BackButton
          url={getTabLink(BotTabs.reEngage, botId)}
          onClick={() => logger('back button click')}
          text={t('pages.BotPage.ReengageWhatsappTab.panel.back')}
          data-testid="reengage-panel__back"
        />

        <Spacer factor={3} />

        <Header hideNote={disabled} />

        <Spacer factor={15} />

        <NameYourMessageControl
          loading={outsideLoading}
          disabled={disabled}
          value={editBroadcast.config.title || ''}
          onChange={(e) => editBroadcast.updateTitle(e.target.value)}
          onBlur={() => {
            if (editBroadcast.config.title?.trim().length === 0) {
              return;
            }
            updateBroadcast(editBroadcast.config);
            logger('name updated');
          }}
        />

        <Spacer factor={8} />

        <SendThisMessageControl
          loading={outsideLoading}
          disabled={!isDraft}
          item={items.find(({ id }) => id === broadcastType)!}
          items={items}
          onChange={(item) => {
            editBroadcast.setDefaultConfigByType(item.id);
            logger('broadcast type changed', { item });
          }}
        />

        <Spacer factor={8} />

        <SettingsBlocksFactory
          isDraft={isDraft}
          disabled={disabled}
          timeError={formError === FormValidationErrors.timeInPast}
          dateError={formError === FormValidationErrors.dateInPast}
          from={broadcastType}
          isSaving={loading}
          loading={outsideLoading}
          onDateChange={editBroadcast.updateDate}
          onTimeChange={editBroadcast.updateTime}
          config={editBroadcast.config}
          updateTriggerUserFilter={(userFilter) => {
            editBroadcast.updateTriggerUserFilter(userFilter);
            logger('trigger user filter updated');
          }}
          updateConfig={({ userFilter }) => {
            editBroadcast.updateUserFilter(userFilter);
            logger('user filter updated');
          }}
          saveUserFilterConfig={() => updateBroadcast(editBroadcast.config)}
          saveUserFilterConfigAsync={() =>
            updateBroadcast(editBroadcast.config)
          }
          customWeekdayValue={
            editBroadcast.config.schedule?.daysOfWeek || DEFAULT_DAYS
          }
          onTriggerValueChange={(value) => {
            editBroadcast.updateTriggerType(value);
            logger('trigger value changed');
          }}
          sendInValueUnit={
            editBroadcast.config.triggerSettings?.waitIntervalTimeUnit!
          }
          dateValue={dateValue}
          timeValue={timeValue}
          triggerValue={triggerValue}
          sendInValue={sendInValue}
          repeatValue={repeatValue}
          sendInUnitValue={sendInUnitValue}
          onRepeatChange={(item) => {
            onRepeatChange(item);
            logger('repeat value updated', { item });
          }}
          onCustomWeekdayClick={(_, item, index) => {
            onCustomWeekdayClick(index);
            logger('custom weekday clicked', { item });
          }}
          onSendInValueChange={(_, rawValue, interval) => {
            onSendInValueChange(rawValue, interval);
            logger('send in date updated', { rawValue, interval });
          }}
          onSendInUnitChange={(item) => {
            onSendInUnitChange(item);
            logger('send in unit updated', { item });
          }}
          orderStatusUpdateStatus={orderStatusUpdateStatus}
          onOrderStatusUpdateStatusChange={(type) => {
            onOrderStatusUpdateStatusChange(type);
            logger('order status updated', { type });
          }}
        />
      </Flex>

      <Spacer factor={8} />

      <FooterButtonFactory
        from={editBroadcast.config.status}
        loading={loading}
        startButtonDisabled={
          isStartButtonDisabled || Boolean(formError) || startButtonDisabled
        }
        startButtonTitle={startButtonTitle}
        onStartButtonClick={async () => {
          logger('set broadcast live click');

          const broadcastType = broadcastsTypeManager.getTypeFromConfig(
            editBroadcast.config,
          );

          if (broadcastType === BroadcastTypes.oneTime) {
            const segmentation = editBroadcast.config.userFilter;
            await showConfirmationModal({ segmentation });
          }

          return setBroadcastLive(editBroadcast.config.id);
        }}
        onCopyButtonClick={() => {
          setBroadcastCopy(editBroadcast.config.id).then(
            ({ copyWhatsappBroadcast }) => {
              const { id, flowId, title } = copyWhatsappBroadcast;

              toaster.default({
                payload: {
                  message: t(
                    'pages.BotPage.ReengageWhatsappTab.clone.success',
                    {
                      name: truncateWithEllipses(title || '', 30),
                    },
                  ),
                  buttonLabel: t(
                    'pages.BotPage.ReengageWhatsappTab.clone.view',
                  ),
                  onButtonClick: () => {
                    history.push(
                      getTabLink(BotTabs.reEngage, props.botId, {
                        flowId,
                        broadcastType: ReEngageType.flow,
                        broadcastId: id,
                      }),
                    );
                  },
                },
              });
            },
          );
          logger('set broadcast copy click');
        }}
        onDeleteButtonClick={() => {
          deleteBroadcastConfirm(() => {
            setBroadcastDelete(editBroadcast.config.id).then(goToBroadcastPage);
          }, isCreateWhatsappTemplateInCfEnable);
          logger('set broadcast delete click');
        }}
        onPauseButtonClick={() => {
          setBroadcastPause(editBroadcast.config.id);
          logger('set broadcast pause click');
        }}
        onUnpauseButtonClick={() => {
          setBroadcastUnpause(editBroadcast.config.id);
          logger('set broadcast unpause click');
        }}
      />
    </Flex>
  );
};

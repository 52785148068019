import { useEffect } from 'react';
import { extractGQLErrorData } from '@utils/GQL/utils';
import { useHistory } from 'react-router-dom';
import { useCurrentBot } from '@utils/Data/Bot';

export const useRedirectToBotListIfBotNotFound = () => {
  const history = useHistory();
  const { error } = useCurrentBot();

  useEffect(() => {
    const gqlErrorData = extractGQLErrorData(error);
    if (gqlErrorData?.status === 404) {
      history.push('/bots');
    }
  }, [error, history]);
};

import React, { useCallback } from 'react';
import cn from 'classnames';
import { Avatar, AvatarSize } from '@ui/Avatar';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { PlatformConnectedHeaderWrapper } from '@pages/GrowPage/PlatformConnection';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import {
  WhatsappBusinessAccount,
  WhatsappPhone,
  WhatsappPhoneEntry,
} from '@utils/Data/Whatsapp';
import { useCurrentBotId } from '@utils/Routing';
import { useWhatsappLogin } from '@pages/MultiSystemAuth/hooks/useWhatsappLogin';
import { WhatsappPhoneSelect } from './WhatsappPhoneSelect/WhatsappPhoneSelect';
import disconnectedSrc from '../components/common/images/disconnected.svg';
import connectedSrc from '../components/common/images/connected.svg';
import * as css from './WhatsappTitle.css';
import { useAdminPhones } from '../components/WhatsappBuyPhoneDialog/hooks/useAdminPhones';
import { useShowWhatsappBuyPhoneDialog } from '../components/WhatsappBuyPhoneDialog/hooks/useShowWhatsappBuyPhoneDialog';

interface WhatsappTitleProps {
  isConfirmed: boolean;
  currentPhone?: WhatsappPhone;
  currentAccount: WhatsappBusinessAccount;
  whatsappPhoneEntries?: WhatsappPhoneEntry[];
  onPhoneChange?(next: WhatsappPhone): void;
  handlePhoneVerificationClick(phone: WhatsappPhone): void;
}

export const WhatsappTitle: React.FC<WhatsappTitleProps> = ({
  isConfirmed,
  currentAccount,
  currentPhone,
  whatsappPhoneEntries,
  onPhoneChange,
  handlePhoneVerificationClick,
}) => {
  const botId = useCurrentBotId()!;
  const { isSmallScreenSize } = useDeviceMedia();
  const { whatsappLogin, tokenIsUpdating } = useWhatsappLogin({ botId });
  const { onWhatsappLogin, loading } = useShowWhatsappBuyPhoneDialog();
  const { phones } = useAdminPhones();

  const addPhoneHandler = useCallback(() => {
    onWhatsappLogin({ onWhatsappLoginRequest: () => whatsappLogin() });
  }, [onWhatsappLogin, whatsappLogin]);

  const addAccountHandler = useCallback(() => {
    if (phones?.length) {
      whatsappLogin();
      return;
    }
    addPhoneHandler();
  }, [addPhoneHandler, phones, whatsappLogin]);

  return (
    <PlatformConnectedHeaderWrapper
      reversed={isSmallScreenSize}
      className={cn(css.box, { [css.boxMobile]: isSmallScreenSize })}
      data-testid="whatsapp-title"
    >
      <Avatar
        className={css.avatar}
        src={isConfirmed ? connectedSrc : disconnectedSrc}
        size={AvatarSize.big}
        style={{ backgroundColor: 'transparent' }}
        hideCoverImage
      />
      <Spacer horizontalFactor={3} />
      <Flex
        flexDirection="column"
        className={cn(css.whatsappPhoneContainer, {
          [css.whatsappPhoneContainerMobile]: isSmallScreenSize,
        })}
      >
        <WhatsappPhoneSelect
          loading={tokenIsUpdating || loading}
          isConfirmed={isConfirmed}
          whatsappPhoneEntries={whatsappPhoneEntries}
          currentAccount={currentAccount}
          currentPhone={currentPhone}
          onAddAccount={addAccountHandler}
          onAddPhone={addPhoneHandler}
          onPhoneChange={onPhoneChange}
          handlePhoneVerificationClick={handlePhoneVerificationClick}
        />
      </Flex>
    </PlatformConnectedHeaderWrapper>
  );
};

import React from 'react';
import cn from 'classnames';
import { BotStatusView, getStatusName } from '@components/BotLivelinessStatus';
import { Flex } from '@ui/Flex';
import * as css from './BotStatus.css';
import { useBotLivelinessStatus } from '@utils/Data/Bot';
import { BotLivelinessStatusTooltip } from '@components/BotLivelinessStatus/BotLivelinessStatusTooltip';

interface BotStatusProps {
  className?: string;
}

export const BotStatus: React.FC<BotStatusProps> = ({ className }) => {
  const { status, isLive } = useBotLivelinessStatus();

  if (!status) {
    return null;
  }

  return (
    <BotLivelinessStatusTooltip status={status}>
      <Flex
        className={cn(css.botStatusBadge, className)}
        data-testid="bot-panel__status-badge"
      >
        <BotStatusView active={isLive} size="15px">
          {getStatusName(status)}
        </BotStatusView>
      </Flex>
    </BotLivelinessStatusTooltip>
  );
};

import React from 'react';
import { Tooltip2, Tooltip2Props } from '@ui/Tooltip2';
import { ButtonProps } from '@ui/Button';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useBillingData } from '../Data/useBillingData';
import { UpgradeButton } from './UpgradeButton';

interface LimitExceededTooltipProps extends Omit<Tooltip2Props, 'content'> {}

const LimitExceededTooltip: React.FC<LimitExceededTooltipProps> = ({
  children,
  disabled,
}) => {
  const { t } = useSafeTranslation();
  const { planLimitReached, isTrial } = useBillingData();

  const content = t(
    isTrial
      ? 'modernComponents.EntryPointsModal.planLimitReached.dialogues.title.free'
      : 'modernComponents.EntryPointsModal.planLimitReached.dialogues.title.included',
    {
      planLimit: planLimitReached?.planLimit,
      nextPlan: planLimitReached?.nextPlanText,
      nextPlanLimit: planLimitReached?.nextPlanLimit,
      currentPlan: planLimitReached?.currentPlanText,
    },
  );

  return (
    <Tooltip2
      boundariesElement="viewport"
      content={content}
      disabled={disabled}
    >
      {children}
    </Tooltip2>
  );
};

export const LimitExceededButton: React.FC<ButtonProps> = (props) => {
  const { t } = useSafeTranslation();

  return (
    <LimitExceededTooltip disabled={props.disabled}>
      {(ref, bind) => (
        <UpgradeButton ref={ref} {...bind} intent="primary" {...props}>
          {t('pages.GrowPage.BotPanel.upgrade')}
        </UpgradeButton>
      )}
    </LimitExceededTooltip>
  );
};

import React, { useMemo } from 'react';
import {
  IntroQuestionsBlockQuestion,
  IntroQuestionsBlockQuestionProps,
} from '../IntroQuestionsBlockQuestion';
import { Spacer } from '@ui/Spacer';
import { Item, SimpleCombobox } from '@ui/SimpleCombobox';
import { Button } from '@ui/Button';
import * as css from './IntroQuestionsBlockQuestionSelect.css';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Type } from '@ui/Type';
import { Icon } from '@ui/Icon';
import { OTHER_ITEM_ID } from '../../consts';
import { Input } from '@ui/Input';
import {
  IntroQuestionsQuestionHasOtherProps,
  IntroQuestionsQuestionProps,
} from '../../types';
import { isMobileDevice } from 'cf-common/src/utils/isMobileDevice';
import { NativeOpacitySelect } from '../common/NativeOpacitySelect';
import { getOtherFieldId } from '../../helpers';

interface IntroQuestionsBlockQuestionSelectProps
  extends IntroQuestionsBlockQuestionProps,
    IntroQuestionsQuestionProps,
    IntroQuestionsQuestionHasOtherProps {
  items: Item[];
  selectedItemIds: string[] | undefined;
  onChange: (selectedItemIds: string[]) => void;
}

export const IntroQuestionsBlockQuestionSelect: React.FC<IntroQuestionsBlockQuestionSelectProps> =
  ({
    title,
    required,
    onChange,
    onBlur,
    items,
    selectedItemIds,
    id,
    showOtherItem,
    onChangeOtherItemValue,
    showErrorState,
    showOtherItemErrorState,
    onOtherItemBlur,
    otherItemValue,
    disabled,
    'data-testid': dataTestid,
  }) => {
    const { t } = useSafeTranslation();

    const selectedItemId = selectedItemIds?.[0];
    const currentItems = useMemo<Item[]>(
      () => [
        ...items,
        ...(showOtherItem
          ? [
              {
                id: OTHER_ITEM_ID,
                title: t('pages.FirstSession.IntroQuestionsBlock.other'),
              },
            ]
          : []),
      ],
      [items, showOtherItem, t],
    );

    const selectedItem = useMemo(
      () => currentItems.find(({ id }) => id === selectedItemId) || null,
      [currentItems, selectedItemId],
    );

    return (
      <>
        <IntroQuestionsBlockQuestion
          title={title}
          required={required}
          data-testid={dataTestid}
        >
          {isMobileDevice() ? (
            <div className={css.nativeSelectBox}>
              <NativeOpacitySelect
                disabled={disabled}
                className={css.nativeSelect}
                items={currentItems}
                name={id}
                onChange={({ currentTarget: { value } }) => {
                  onChange([value]);
                }}
                value={selectedItemId}
                onBlur={onBlur}
              />
              <Button
                disabled={disabled}
                intent={showErrorState ? 'error' : 'secondary'}
                iconRight={<Icon icon="triangle" />}
                className={css.button}
                type="button"
              >
                <Type size="15px" color={selectedItem ? 'black' : 'grey'}>
                  {selectedItem?.title ||
                    t(
                      'pages.FirstSession.IntroQuestionsBlock.selectPlaceholder',
                    )}
                </Type>
              </Button>
            </div>
          ) : (
            <SimpleCombobox
              menuboxStyle={{ width: 'min(calc(100% - 136px), 800px)' }}
              selectedItem={selectedItem}
              dataTestId={`${dataTestid}_selector`}
              onSelect={(selectedItem) => {
                if (!selectedItem) {
                  return;
                }
                onChange([selectedItem?.id]);
              }}
              renderInput={({ getToggleButtonProps, selectedItem }) => {
                const buttonProps = getToggleButtonProps();
                return (
                  <Button
                    {...buttonProps}
                    disabled={disabled}
                    intent={showErrorState ? 'error' : 'secondary'}
                    iconRight={<Icon icon="triangle" />}
                    className={css.button}
                    name={id}
                    type="button"
                    onBlur={(event) => {
                      buttonProps.onBlur(event);
                      onBlur(event);
                    }}
                  >
                    <Type
                      size="15px"
                      color={selectedItem ? 'black' : 'grey'}
                      data-testid="dropdown-placeholder"
                    >
                      {selectedItem?.title ||
                        t(
                          'pages.FirstSession.IntroQuestionsBlock.selectPlaceholder',
                        )}
                    </Type>
                  </Button>
                );
              }}
              items={currentItems}
              scrollboxStyle={{
                maxHeight: 'min(350px, 40vh)',
              }}
            />
          )}

          {selectedItemId === OTHER_ITEM_ID && (
            <>
              <Spacer factor={2} />
              <Input
                className={css.control}
                value={otherItemValue || ''}
                onChange={({ currentTarget: { value } }) => {
                  onChangeOtherItemValue?.(value);
                }}
                onBlur={onOtherItemBlur}
                error={showOtherItemErrorState}
                placeholder={t(
                  'pages.FirstSession.IntroQuestionsBlock.otherPlaceholder',
                )}
                name={getOtherFieldId(id)}
                data-testid={`${dataTestid}_other-input`}
              />
            </>
          )}
        </IntroQuestionsBlockQuestion>
        <Spacer factor={8} />
      </>
    );
  };

import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { log } from 'cf-common/src/logger';
import {
  showSomethingWentWrongToaster,
  toaster,
} from '@services/MessageService';
import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import { usePublishMessageTemplate } from '@pages/BotPage/MessagesTemplatesTab/data/usePublishMessageTemplate';
import { showSendTemplateForReviewModal } from './SendTemplateForReviewModal';
import { useExtendedFormAction } from '../../MessagesTemplate/form/ExtendFormActionsContext';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { logEvent } from '../utils';
import { getPublishTemplateErrorText } from '../../../helpers/getPublishTemplateErrorText';
import { getTemplateFieldErrorText } from '../../../helpers/getTemplateFieldErrorText';

export const useSendTemplateForReview = () => {
  const botId = useCurrentBotId()!;
  const history = useHistory();
  const { t } = useSafeTranslation();
  const { onBeforePublish, setFieldError, saveForm, setSubmitting, values } =
    useExtendedFormAction();
  const [publishTemplate, queryResult] = usePublishMessageTemplate();

  const [hideSendForReviewModal, setHideSendForReviewModal] =
    useServerStorage<boolean>(ServerStorageItemKeys.hideSendForReviewModal);
  const sections = values?.content.sections;

  const sendForReview = useCallback(
    async (templateId: string) => {
      try {
        if (!hideSendForReviewModal) {
          const data = await showSendTemplateForReviewModal({
            dontShowAgain: !!hideSendForReviewModal,
            onDismiss: () => {
              setSubmitting(false);
            },
          })?.onClose(() => {
            setSubmitting(false);
          });

          setHideSendForReviewModal(data!.dontShowAgain);
        }

        onBeforePublish();

        await saveForm();

        const { data } = await publishTemplate({ botId, templateId });

        setSubmitting(false);

        const errors = data?.publishMessageTemplate.errors;

        if (errors?.length === 0) {
          history.push(getTabLink(BotTabs.messagesTemplates, botId));
          toaster.default({
            payload: {
              message: t('pages.MessagesTemplates.submitToaster'),
            },
          });
          return;
        }

        // Неизвестные ошибки логируем на беке!
        logEvent({
          action: 'display errors',
          propertyBag: { errors },
        });

        errors?.forEach(({ error, field }) => {
          if (field) {
            const [fieldName, fieldIndex, subFieldName] = field.split('.');
            if (fieldName === 'buttons') {
              const buttonIndex = parseInt(fieldIndex, 10);
              let buttonCount = 0;
              sections?.forEach(({ children }, sectionIndex) => {
                children.forEach(() => {
                  if (buttonCount === buttonIndex) {
                    setFieldError(
                      `content.sections[${sectionIndex}].children[${buttonIndex}].${subFieldName}`,
                      getTemplateFieldErrorText(subFieldName),
                    );
                  }
                  buttonCount++;
                });
              });
            }

            return;
          }

          const errorText = getPublishTemplateErrorText(error);
          if (errorText) {
            toaster.error({
              payload: {
                message: errorText,
              },
            });
          }
        });
      } catch (error) {
        setSubmitting(false);
        showSomethingWentWrongToaster();
        log.error({
          msg: 'Failed to send template for review',
          data: {
            templateId,
            error,
            label: 'messages_templates',
          },
        });
      }
    },
    [
      botId,
      hideSendForReviewModal,
      history,
      onBeforePublish,
      publishTemplate,
      saveForm,
      setFieldError,
      setHideSendForReviewModal,
      setSubmitting,
      t,
      sections,
    ],
  );

  return [sendForReview, queryResult] as const;
};

import { useEffect, useState } from 'react';
import { outsideBlockFixedViewsService } from '@components/FlowBuilder/views/services';
import { FlowLoadingState } from './FlowLoader';

export const useLoadingState = () => {
  const [loadingState, setLoadingState] = useState<FlowLoadingState>(
    FlowLoadingState.none,
  );

  useEffect(() => {
    switch (loadingState) {
      case FlowLoadingState.fetching:
        outsideBlockFixedViewsService.pauseReRendering();
        break;
      case FlowLoadingState.rendering:
        outsideBlockFixedViewsService.startReRendering();
        break;
      default:
    }
  }, [loadingState]);

  return [loadingState, setLoadingState] as const;
};

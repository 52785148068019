import { useQuery } from 'react-apollo';
import {
  ROLES_AND_INVITES_QUERY as ROLES_AND_INVITES_QUERYTypes,
  ROLES_AND_INVITES_QUERYVariables,
} from '../../../../../components/settings/admins/@types/ROLES_AND_INVITES_QUERY';
import { ROLES_AND_INVITES_QUERY } from '../../../../../components/settings/admins/AdminsConst';

export const useBotAdminsAndInvites = (botId: string) => {
  const { data, loading } = useQuery<
    ROLES_AND_INVITES_QUERYTypes,
    ROLES_AND_INVITES_QUERYVariables
  >(ROLES_AND_INVITES_QUERY, { variables: { botId } });

  return {
    invites: data?.bot.invites,
    admins: data?.bot.admins,
    loading,
  };
};

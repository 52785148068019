import React from 'react';
import { Type } from '@ui/Type';
import * as css from './FirstSessionBlockTitle.css';
import { Spacer } from '@ui/Spacer';
import cn from 'classnames';

interface FirstSessionBlockTitleProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  active: boolean;
}

export const FirstSessionBlockTitle: React.FC<FirstSessionBlockTitleProps> = ({
  title,
  subtitle,
  active,
}) => (
  <div className={cn(css.box, active && css.active)}>
    <div className={css.title}>
      {/* временно убрали 16 фев 2023 <FirstSessionProgressBar />
      <Spacer factor={6} /> */}
      <Type size="24px" weight="bold" as="div">
        {title}
      </Type>
      <Spacer factor={1} />
      <Type size="15px" color="greyDark" as="div" whiteSpace="pre-line">
        {subtitle}
      </Type>
    </div>
  </div>
);

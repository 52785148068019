import { useMutation } from '@apollo/react-hooks';
import { updateCacheAfterAddedFlow } from '@utils/Data/Flow/updateCacheAfterAddedFlow';
import { RECREATE_REMOVED_FLOW_MUTATION } from './queries';
import {
  RecreateRemovedFlowMutation,
  RecreateRemovedFlowMutationVariables,
} from './@types/RecreateRemovedFlowMutation';

export const useTryToRecreateRemovedFlow = () => {
  const [recreateRemovedFlowMutation] = useMutation<
    RecreateRemovedFlowMutation,
    RecreateRemovedFlowMutationVariables
  >(RECREATE_REMOVED_FLOW_MUTATION);

  // returns recreated flowId or null if can't
  const tryToRecreateRemovedFlow = async (botId: string, flowId: string) => {
    return new Promise<string | null>((resolve) => {
      recreateRemovedFlowMutation({
        variables: {
          botId,
          flowId,
        },
        update: (cache, { data }) => {
          if (!data?.recreateRemovedFlow) {
            resolve(null);
            return;
          }
          updateCacheAfterAddedFlow(
            cache,
            botId,
            data.recreateRemovedFlow.flow,
            data.recreateRemovedFlow.parentGroupId,
          );
          const createdFlowId = data?.recreateRemovedFlow?.flow.id ?? null;
          resolve(createdFlowId);
        },
      });
    });
  };

  return tryToRecreateRemovedFlow;
};

import React from 'react';
import { ButtonUnstyled } from '@ui/Button';
import { TextEllipsis } from '@ui/TextEllipsis';
import { Type } from '@ui/Type';
import { Icon } from '@ui/Icon';
import { WhatsappBusinessAccount, WhatsappPhone } from '@utils/Data/Whatsapp';
import { WHATSAPP_BUSINESS_ACCOUNT_DEFAULT_NAME } from '../../../consts';
import * as css from './CurrentPhoneToggle.css';

interface CurrentPhoneToggleProps extends React.HTMLProps<HTMLButtonElement> {
  currentPhone?: WhatsappPhone;
  currentAccount: WhatsappBusinessAccount;
  isOpen?: boolean;
  disabled?: boolean;
}

export const CurrentPhoneToggle = React.forwardRef<
  HTMLButtonElement,
  CurrentPhoneToggleProps
>(({ currentPhone, currentAccount, isOpen, disabled, ...restProps }, ref) => {
  return (
    <>
      <ButtonUnstyled
        className={css.input}
        data-testid="whatsapp-phone-select__toggle"
        {...restProps}
        ref={ref}
        disabled={disabled}
      >
        <TextEllipsis title={currentAccount.name ?? WHATSAPP_BUSINESS_ACCOUNT_DEFAULT_NAME}>
          <Type size="24px" weight="semibold">
            {currentAccount.name}
          </Type>
        </TextEllipsis>
        <Icon
          className={css.toggle}
          icon={isOpen ? 'chevronUp' : 'chevronDown'}
          size="24px"
          color={disabled ? 'greyDark' : 'blue'}
        />
      </ButtonUnstyled>
      {currentPhone && (
        <div className={css.phoneContainer}>
          <Type data-testid="whatsapp-phone__current-phone">
            {currentPhone!.display_phone_number}
          </Type>
        </div>
      )}
    </>
  );
});

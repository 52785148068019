import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import { UpdateWhatsappBroadcastInput } from '@globals';
import { removeTypename } from '@utils/GQL/utils';
import {
  UpdateWhatsappBroadcast,
  UpdateWhatsappBroadcastVariables,
} from './@types/UpdateWhatsappBroadcast';
import { UPDATE_WHATSAPP_BROADCAST_MUTATION } from './queries';
import {
  defaultErrorHandler,
  graphqlErrorToBroadcastFormError,
} from '../utils';
import { FormValidationErrors, WhatsappBroadcastConfig } from '../types';

export interface UpdateBroadcastConfig extends UpdateWhatsappBroadcastInput {
  id: string;
}

export const useUpdateBroadcast = (
  botId: string,
  currentBroadcastConfig: WhatsappBroadcastConfig,
) => {
  const [formError, setFormError] = useState<FormValidationErrors | null>(null);
  const [updateWhatsappBroadcast, { loading, error, ...queryResult }] =
    useMutation<UpdateWhatsappBroadcast, UpdateWhatsappBroadcastVariables>(
      UPDATE_WHATSAPP_BROADCAST_MUTATION,
    );

  useEffect(() => {
    if (loading) {
      return;
    }

    const errorEnum = graphqlErrorToBroadcastFormError(
      error,
      currentBroadcastConfig,
    );
    if (formError !== errorEnum) {
      setFormError(errorEnum);
    }
  }, [error, loading, formError, setFormError, currentBroadcastConfig]);

  const saveBroadcast = useCallback(
    (broadcast: UpdateBroadcastConfig) => {
      return updateWhatsappBroadcast({
        variables: {
          botId,
          broadcastId: broadcast.id,
          broadcast: removeTypename({
            title: broadcast.title?.trim(),
            userFilter: broadcast.userFilter,
            triggerSettings: broadcast.triggerSettings,
            schedule: broadcast.schedule,
          }),
        },
      }).catch(
        defaultErrorHandler({
          msg: 'Could not save broadcast',
          data: { botId, broadcast },
        }),
      );
    },
    [updateWhatsappBroadcast, botId],
  );

  return [
    saveBroadcast,
    { ...queryResult, loading, error, formError },
  ] as const;
};

import { TemplateButtonType, MessagesTemplateButtonInput } from '@globals';

export const DEFAULT_PHONE_BUTTON: MessagesTemplateButtonInput = {
  text: '',
  type: TemplateButtonType.phone,
  block_id: null,
  counter_id: null,
  phone_number: null,
  url: null,
  attribute_examples: [],
};

export const DEFAULT_URL_BUTTON: MessagesTemplateButtonInput = {
  text: '',
  type: TemplateButtonType.url,
  block_id: null,
  counter_id: null,
  phone_number: null,
  url: '',
  attribute_examples: [],
};

export const DEFAULT_OPT_OUT_BUTTON: MessagesTemplateButtonInput = {
  text: '',
  type: TemplateButtonType.marketing_opt_out,
  block_id: null,
  counter_id: null,
  phone_number: null,
  url: null,
  attribute_examples: [],
};

export const DEFAULT_CUSTOM_BUTTON: MessagesTemplateButtonInput = {
  text: '',
  type: TemplateButtonType.quick_reply,
  block_id: null,
  counter_id: null,
  phone_number: null,
  url: null,
  attribute_examples: [],
};

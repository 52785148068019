import React from 'react';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './SentButton.css';
import { FooterMenuProps } from './FooterMenu';

export interface DeleteAndCopyButtonsProps extends FooterMenuProps {}

export const DeleteAndCopyButtons: React.FC<DeleteAndCopyButtonsProps> = ({
  onCopyButtonClick,
  onDeleteButtonClick,
}) => {
  const { t } = useSafeTranslation();

  return (
    <Flex alignItems="center">
      <Button
        intent="secondary"
        className={css.button}
        labelTextClassName={css.label}
        onClick={onDeleteButtonClick}
        data-testid="reengage-panel__delete-button"
      >
        <Icon icon="delete" color="blue" />
        <Type size="15px" color="blue" weight="medium">
          {t('pages.BotPage.ReengageWhatsappTab.panel.buttons.delete')}
        </Type>
      </Button>

      <Spacer factor={0} horizontalFactor={3} />

      <Button
        intent="secondary"
        className={css.button}
        labelTextClassName={css.label}
        onClick={onCopyButtonClick}
        data-testid="reengage-panel__copy-button"
      >
        <Icon icon="copy" color="blue" />
        <Type size="15px" color="blue" weight="medium">
          {t('pages.BotPage.ReengageWhatsappTab.panel.buttons.copy')}
        </Type>
      </Button>
    </Flex>
  );
};

import React from 'react';
import { sendEvent } from '@utils/Analytics';
import { useHistory } from 'react-router-dom';
import { DeepLinksMode } from '@pages/DeepLinks/types';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import { Button } from '@ui/Button';
import { Callout } from '@ui/Callout';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { useWhatsappConnected } from '@utils/Data/Whatsapp';
import * as css from './MessagesTemplateHeaderCallout.css';

export const MessagesTemplateHeaderCallout = () => {
  const botId = useCurrentBotId()!;
  const history = useHistory();
  const { isWhatsappEnabled, loading: whatsappEnabledLoading } =
    useWhatsappEnabled();
  const {
    isConnected: isWhatsappConnected,
    loading: whatsappConnectedLoading,
  } = useWhatsappConnected(botId);

  if (whatsappConnectedLoading || whatsappEnabledLoading) {
    return null;
  }

  const shouldConnectWhatsapp = isWhatsappEnabled && !isWhatsappConnected;

  if (!shouldConnectWhatsapp) {
    return null;
  }

  const onClick = () => {
    sendEvent({
      category: 'whatsapp template callout',
      action: 'connect whatsapp button click',
    });

    history.push(
      getTabLink(BotTabs.home, botId, {
        dlMode: DeepLinksMode.connectWhatsapp,
      }),
    );
  };

  return (
    <Callout
      data-testid="messages-template-header__callout"
      type="warning"
      iconDisabled
      text="Connect your WhatsApp Business Account to send this template for Meta review"
      button={
        <Button
          data-testid="callout_connect_button"
          intent="orange"
          onClick={onClick}
        >
          Connect ↗︎
        </Button>
      }
      className={css.banner}
    />
  );
};

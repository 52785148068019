import React from 'react';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import {
  ConnectPageField,
  ConnectPageOrigin,
  useConnectPage,
} from '@components/ConnectPageDialog';
import { useCurrentBotId } from '@utils/Routing';
import { MovedToAnotherPlaceLoaderView } from './MovedToAnotherPlaceLoaderView';
import * as css from './MovedToAnotherPlace.css';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { SectionBox } from '@components/SectionBox';

interface MovedToAnotherPlaceViewProps extends TestLocator {
  title: string;
  text: string;
  buttonTitle: string;
  onClick: () => void;
}

const MovedToAnotherPlaceView: React.FC<MovedToAnotherPlaceViewProps> = ({
  title,
  text,
  buttonTitle,
  onClick,
  ...props
}) => (
  <SectionBox className={css.wrapper} data-testid={props['data-testid']}>
    <Type size="24px" weight="medium" align="center">
      {title}
    </Type>
    <Spacer factor={2} />
    <Type size="15px" align="center">
      {text}
    </Type>
    <Spacer factor={6} />
    <Flex justifyContent="center">
      <Button
        className={css.button}
        onClick={onClick}
        data-testid="go-to-button"
      >
        {buttonTitle}
      </Button>
    </Flex>
  </SectionBox>
);

interface MovedToAnotherPlaceProps extends MovedToAnotherPlaceViewProps {
  loading?: boolean;
}

export const MovedToAnotherPlace: React.FC<MovedToAnotherPlaceProps> = ({
  onClick,
  loading,
  ...props
}) => {
  const botId = useCurrentBotId()!;
  const { isConnected, loading: pageLoading } = usePageConnected();

  const { connectPage } = useConnectPage({
    botId,
    urlParams: {
      [ConnectPageField.origin]: ConnectPageOrigin.movedBlock,
    },
    onPageConnected: onClick,
  });

  const handleClick = () => {
    if (isConnected) {
      onClick();
    } else {
      connectPage();
    }
  };

  if (loading || pageLoading) {
    return <MovedToAnotherPlaceLoaderView />;
  }

  return <MovedToAnotherPlaceView {...props} onClick={handleClick} />;
};

import React, { useMemo } from 'react';
import i18next from 'i18next';
import cn from 'classnames';
import { TriggeredMessageType } from '@globals';
import { ComboboxWithTriangleButton } from '@ui/SimpleCombobox';
import { Type } from '@ui/Type';
import { MenuItem } from '@ui/Menu';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useIsShopifyV3 } from '@utils/Integrations/Shopify/useIsShopifyV3';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import * as commonCSS from './common.css';
import * as css from './TriggerControl.css';
import { Disableable } from '../types';
import { Flex } from '@ui/Flex';
import { useCreateWhatsappTemplateInCfEnable } from '@utils/Whatsapp/hooks/useCreateWhatsappTemplateInCfEnable';

enum TriggerControlKind {
  shopify,
}

interface ItemType {
  id: TriggeredMessageType;
  title: string;
  kind?: TriggerControlKind;
}

const items = (shopifyEnabled?: boolean): ItemType[] => {
  const shopifyItems = [
    {
      id: TriggeredMessageType.abandoned_cart_update,
      kind: TriggerControlKind.shopify,
      title: i18next.t(
        'pages.BotPage.ReengageWhatsappTab.panel.trigger.type.abandonedCartUpdate',
      ),
    },
    {
      id: TriggeredMessageType.order_confirmation,
      kind: TriggerControlKind.shopify,
      title: i18next.t(
        'pages.BotPage.ReengageWhatsappTab.panel.trigger.type.orderConfirmation',
      ),
    },
    {
      id: TriggeredMessageType.order_status_update,
      kind: TriggerControlKind.shopify,
      title: i18next.t(
        'pages.BotPage.ReengageWhatsappTab.panel.trigger.type.orderStatusUpdate',
      ),
    },
  ];

  return [
    {
      id: TriggeredMessageType.first_interaction,
      title: i18next.t(
        'pages.BotPage.ReengageWhatsappTab.panel.trigger.type.firstInteraction',
      ),
    },
    {
      id: TriggeredMessageType.last_interaction,
      title: i18next.t(
        'pages.BotPage.ReengageWhatsappTab.panel.trigger.type.lastInteraction',
      ),
    },
    {
      id: TriggeredMessageType.user_filter,
      title: i18next.t(
        'pages.BotPage.ReengageWhatsappTab.panel.trigger.type.userFilter',
      ),
    },
    ...(shopifyEnabled ? shopifyItems : []),
  ];
};

export interface TriggerControlProps extends Disableable {
  triggerValue: TriggeredMessageType;
  onTriggerValueChange: (value: TriggeredMessageType) => void;
}

export const TriggerControl: React.FC<TriggerControlProps> = ({
  disabled,
  triggerValue,
  onTriggerValueChange,
}) => {
  const { t } = useSafeTranslation();
  const { isShopifyV3 } = useIsShopifyV3();
  const { isCreateWhatsappTemplateInCfEnable } =
    useCreateWhatsappTemplateInCfEnable();
  const currentItem = useMemo(
    () => items(isShopifyV3).find(({ id }) => id === triggerValue)!,
    [triggerValue, isShopifyV3],
  );

  return (
    <PluginControlLabel
      label={t('pages.BotPage.ReengageWhatsappTab.panel.trigger.label')}
      light
    >
      {() => (
        <>
          <ComboboxWithTriangleButton
            disabled={disabled}
            itemToString={(item) => item?.title || ''}
            selectedItem={currentItem}
            className={cn(commonCSS.dropdown, css.dropdown)}
            buttonChildren={() => (
              <Flex alignItems="center">
                {currentItem.kind === TriggerControlKind.shopify ? (
                  <Icon icon="shopifyBag" color="cobaltTertiary" />
                ) : null}
                <Spacer factor={0} horizontalFactor={1} />
                <Type size="15px">{currentItem.title}</Type>
              </Flex>
            )}
            items={items(isShopifyV3)}
            onChange={(item) => onTriggerValueChange(item.id)}
            data-testid="reengage-editor__trigger-type-dropdown"
          >
            {({
              downshift: { getItemProps, highlightedIndex },
              props: { item, index },
            }) => (
              <MenuItem
                title={item.title}
                active={index === highlightedIndex}
                key={item.id}
                leftElement={
                  item.kind === TriggerControlKind.shopify ? (
                    <Icon icon="shopifyBag" />
                  ) : null
                }
                {...getItemProps({ item })}
              />
            )}
          </ComboboxWithTriangleButton>
          {currentItem.id === TriggeredMessageType.last_interaction && (
            <>
              <Spacer factor={1} />
              <Type size="12px" as="div" color="greyDark">
                {isCreateWhatsappTemplateInCfEnable
                  ? t('components.broadcast.note')
                  : t('components.broadcast.note_old')}
              </Type>
            </>
          )}
        </>
      )}
    </PluginControlLabel>
  );
};

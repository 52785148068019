import gql from 'graphql-tag';

export const REORDER_FLOW_GROUP_MUTATION = gql`
  mutation ReorderFlowGroupMutation($flowGroup: FlowGroupInput!) {
    updateFlowGroup(flowGroup: $flowGroup) {
      id
    }
  }
`;

export const REORDER_FLOW_ITEM_MUTATION = gql`
  mutation ReorderFlowItemMutation(
    $groupId: ID
    $flow: FlowInput!
    $flowGroup: FlowGroupInput!
  ) {
    updateFlow(groupId: $groupId, flow: $flow) {
      id
    }
    updateFlowGroup(flowGroup: $flowGroup) {
      id
      collapsed
    }
  }
`;

import React, { useMemo } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { sendEvent } from '@utils/Analytics';
import { useAutomateEnabled } from '@utils/Data/Admin/Automate';
import { ButtonUnstyled } from '@ui/Button';
import { Input } from '@ui/Input';
import { Icon } from '@ui/Icon';
import * as css from './KeywordsSearchField.css';
import { useAiIntents } from '@utils/Data/Ai/Groups';
import { intentsFilter } from '@utils/Data/Ai/Groups/helpers';
import { Platform } from '@globals';

interface AiSearchFieldProps {
  intentsFilterTerm: string;
  platform: Platform;
  onIntentsFitlerTermChange(value: string): void;
}

export const KeywordsSearchField: React.FC<AiSearchFieldProps> = ({
  intentsFilterTerm,
  platform,
  onIntentsFitlerTermChange,
}) => {
  const { t } = useSafeTranslation();
  const { isAutomateEnabled } = useAutomateEnabled();
  const { aiIntents } = useAiIntents();

  const validSearch = useMemo(
    () =>
      intentsFilterTerm === '' ||
      aiIntents?.some(intentsFilter(intentsFilterTerm, platform)),
    [aiIntents, intentsFilterTerm, platform],
  );

  return (
    <Input
      data-testid="keywords__search-input"
      containerClassName={css.input}
      value={intentsFilterTerm}
      onChange={(event) => onIntentsFitlerTermChange(event.currentTarget.value)}
      onClick={() => {
        sendEvent({
          category: 'keywords',
          label: 'search field',
          action: 'click search',
        });
      }}
      renderIcon={() => <Icon icon="search" color="grey" />}
      renderIconEnd={() =>
        intentsFilterTerm.length ? (
          <ButtonUnstyled
            onClick={() => {
              onIntentsFitlerTermChange('');
              sendEvent({
                category: 'keywords',
                label: 'search field',
                action: 'clear search field click',
              });
            }}
            data-testid="keywords__keywords-groups__clear-search-field-button"
          >
            <Icon icon="close" color="black" />
          </ButtonUnstyled>
        ) : null
      }
      placeholder={
        isAutomateEnabled
          ? t('pages.Keywords.SearchField.placeholderBlocks')
          : t('pages.Keywords.SearchField.placeholderFlows')
      }
      error={!validSearch}
    />
  );
};

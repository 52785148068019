import { pixiFieldRepository } from '@components/FlowBuilder/PixiFieldRepository';
import {
  connectPage,
  ConnectPageField,
  ConnectPageOrigin,
  getConnectPageUrlParams,
} from '@components/ConnectPageDialog';
import { DeepLinksMode } from '../../../DeepLinks/types';
import { DeepLinkLocationParams } from './types';

interface UpdateFlowEntryPointEnableStateParams {
  botId: string;
  flowId: string;
  entryPointId: string;
  isConnected: boolean;
  onEnabled?(): void;
  onClose?(): void;
}
export const updateFlowEntryPointEnableState = async ({
  botId,
  // flowId,
  entryPointId,
  isConnected,
  onEnabled,
  onClose,
}: UpdateFlowEntryPointEnableStateParams) => {
  if (isConnected) {
    const controller = await pixiFieldRepository.getActiveControllerWhenReady();
    if (!controller) return;
    await controller.allNodesWhenReady();
    controller.focusOnBlock(entryPointId);
    // TODO Enable after making validation explicit
    // controller.updateEntryPointEnableState(flowId, entryPointId);
    onEnabled?.();
  } else {
    connectPage({
      botId,
      urlParams: {
        [ConnectPageField.origin]: ConnectPageOrigin.entryPointDeepLink,
        [ConnectPageField.entryPointId]: entryPointId,
      },
      onClose,
      async onPageConnected({ status, pageId, verifiedPermissions, close }) {
        const controller =
          await pixiFieldRepository.getActiveControllerWhenReady();
        if (!controller) return;

        controller.updateFlowBuilderPage(status, pageId, verifiedPermissions);

        await controller.allNodesWhenReady();
        controller.focusOnBlock(entryPointId);
        // TODO Enable after making validation explicit
        // controller.updateEntryPointEnableState(flowId, entryPointId);

        close?.();
        onEnabled?.();
      },
    });
  }
};

export const getDeepLinkMode = (locationParams: DeepLinkLocationParams) => {
  const urlParams = getConnectPageUrlParams();
  const origin = urlParams[ConnectPageField.origin];

  if (origin === ConnectPageOrigin.entryPointDeepLink) {
    return DeepLinksMode.enableEntryPointFb;
  }

  if (origin === ConnectPageOrigin.testThis) {
    return DeepLinksMode.testThisFb;
  }

  if (locationParams.dlEntryPointId) {
    return DeepLinksMode.enableEntryPointSelected;
  }

  if (locationParams.dlTestFlowId) {
    return DeepLinksMode.testThisFlowSelected;
  }

  return locationParams.dlMode ?? null;
};

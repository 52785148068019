import { isEllipsisDetect } from '@utils/DOM/isEllipsisDetect';
import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { BotTabs, useCurrentBotId } from '@utils/Routing';
import { Type } from '@ui/Type';
import { sendEvent } from '@utils/Analytics';
import { FlowsQuery_bot_flows_entry_points } from '@utils/Data/Flow/@types/FlowsQuery';
import { getLeftShift } from '../utils';
import * as css from './EntryPointItem.css';

interface EntryPointItemProps {
  nestedLevel: number;
  flowId: string;
  entryPoint: FlowsQuery_bot_flows_entry_points;
}

export const EntryPointItem: React.FC<EntryPointItemProps> = ({
  nestedLevel,
  flowId,
  entryPoint,
}) => {
  const botId = useCurrentBotId();
  const titleRef = useRef<HTMLSpanElement | null>(null);

  return (
    <NavLink
      data-testid="flow_entry_point_item"
      to={`/bot/${botId}/${BotTabs.flows}/${flowId}?blockId=${entryPoint.id}`}
      className={css.item}
      onClick={() => {
        sendEvent({
          category: 'flow navigation',
          action: 'entry point click',
          propertyBag: {
            botId,
            flowId,
            entryPointId: entryPoint.id,
            entryPointType: entryPoint.entry_point_type,
          },
        });
      }}
      style={{
        paddingLeft: getLeftShift(nestedLevel),
      }}
    >
      <Type
        color={entryPoint.enabled ? 'black' : 'greyDark'}
        size="15px_DEPRECATED"
      >
        <div
          className={css.titleContainer}
          title={
            isEllipsisDetect(titleRef.current) ? entryPoint.title : undefined
          }
        >
          <Type
            color={entryPoint.enabled ? 'black' : 'greyDark'}
            innerRef={titleRef}
            size="15px_DEPRECATED"
          >
            {entryPoint.title}
          </Type>
        </div>
      </Type>
    </NavLink>
  );
};

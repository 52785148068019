import React, { useMemo } from 'react';
import {
  FROM_CHATFUEL,
  FROM_USER,
  I18_IGNORE_ATTRIBUTES,
  mapId,
  MESSAGE_COMMON_DATA,
  TEMPLATE_COMMON_DATA,
} from './common';
import { EMPTY_MESSAGE_DATA } from '@components/QuickAccessToolbar/TestThis/TestThisChatfuel/utils';
import img1 from './images/img1.png';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { MessagesTemplatesTableSlide } from '../MessagesTemplatesTableSlide';
import ava1 from './images/ava1.png';

const DAYS_3_AND_SOME = 3600 * 24 * 3 * 1000 + 3600 * 12 * Math.random() * 1000;
const MINUTES_2 = 60 * 2 * 1000;
const DATE_NOW = Date.now();

export const MessageSlide1 = () => {
  const { t } = useSafeTranslation();

  const messages = useMemo(
    () =>
      [
        {
          ...MESSAGE_COMMON_DATA,
          date: (DATE_NOW - DAYS_3_AND_SOME - MINUTES_2).toString(),
          message: {
            ...EMPTY_MESSAGE_DATA,
            whatsapp_template: {
              ...TEMPLATE_COMMON_DATA,
              body: t(
                'pages.MessagesTemplates.Table.emptyState.slider.item1.message1.text',
                I18_IGNORE_ATTRIBUTES,
              ),
              footer: t(
                'pages.MessagesTemplates.Table.emptyState.slider.item1.message1.subtext',
              ),
            },
          },
          from: FROM_CHATFUEL,
        },
        {
          ...MESSAGE_COMMON_DATA,
          date: (DATE_NOW - DAYS_3_AND_SOME).toString(),
          message: {
            ...EMPTY_MESSAGE_DATA,
            text: t(
              'pages.MessagesTemplates.Table.emptyState.slider.item1.message2.text',
            ),
          },
          from: FROM_USER,
        },
        {
          ...MESSAGE_COMMON_DATA,
          date: DATE_NOW.toString(),
          message: {
            ...EMPTY_MESSAGE_DATA,
            whatsapp_template: {
              ...TEMPLATE_COMMON_DATA,
              header_image: img1,
              body: t(
                'pages.MessagesTemplates.Table.emptyState.slider.item1.message3.text',
                I18_IGNORE_ATTRIBUTES,
              ),
            },
          },
          from: FROM_CHATFUEL,
        },
      ].map(mapId),
    [t],
  );

  return (
    <MessagesTemplatesTableSlide messages={messages} userAvatarSrc={ava1} />
  );
};

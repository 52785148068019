import i18next from 'i18next';
import memoize from 'lodash-es/memoize';
import { WhatsappBuyPhoneDialogStep } from '../types';

export const getStepsTitles = memoize(() => ({
  [WhatsappBuyPhoneDialogStep.pickOption]: i18next.t(
    'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.pickOption.title',
  ),
  [WhatsappBuyPhoneDialogStep.chooseCountryCode]: i18next.t(
    'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.chooseCountryCode.title',
  ),
  [WhatsappBuyPhoneDialogStep.generate]: i18next.t(
    'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.generate.title',
  ),
  [WhatsappBuyPhoneDialogStep.registerToReceiveMessages]: i18next.t(
    'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.registerToReceiveMessages.title',
  ),
  [WhatsappBuyPhoneDialogStep.needFbConnect]: i18next.t(
    'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.needFbConnect.title',
  ),
}));

import React from 'react';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import { insertText } from '@utils/Slite/insertText';
import { Editor } from 'slate-react';
import { AttributesData } from '@utils/AttributesUtils/AttributesData';
import { Input } from '@ui/Input';
import {
  deserialize,
  TextWithAttributesEditor,
} from '@ui/TextWithAttributesEditor';
import { useCurrentBotId } from '@utils/Routing';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Platform } from '@globals';

interface TeachYourBotItemTextProps {
  value: string;
  onChange: (value: string) => void;
  autoFocus: boolean;
  platform: Platform;
}

const TEXT_LIMIT = 2000;

export const FrequentUnrecognizedMessagesItemText: React.FC<TeachYourBotItemTextProps> =
  ({ value, onChange, autoFocus, platform }) => {
    const { t } = useSafeTranslation();
    const botId = useCurrentBotId();
    return (
      <TextOutsideControls
        onInsertRequest={(text, el) => {
          insertText(text, el as Editor);
        }}
        currentTextLimit={TEXT_LIMIT - (value.length || 0)}
        shouldShowOutsideControls={{
          emoji: true,
          symbolsLimit: true,
          attributes: true,
        }}
        tight
        initialShow={false}
        boxStyle={{ width: '100%' }}
      >
        {({ ref, getInputProps }) => (
          <AttributesData botId={botId || ''} platform={platform}>
            {({ attributes }) => (
              <Input
                render={() => (
                  <TextWithAttributesEditor
                    defaultValue={deserialize(value)}
                    attributes={attributes}
                    onStringChange={onChange}
                    maxLength={TEXT_LIMIT}
                    editorRef={ref}
                    style={{ width: '100%', wordBreak: 'break-word' }}
                    onFocus={getInputProps().onFocus}
                    onBlur={(event) => {
                      (getInputProps().onBlur as Function)(event);
                    }}
                    autoFocus={autoFocus}
                    placeholder={t(
                      'pages.Keywords.FrequentUnrecognizedMessages.item.textResponse.response',
                    )}
                    data-testid="keywords__frequent-unrecognized-messages__response-text-input"
                  />
                )}
              />
            )}
          </AttributesData>
        )}
      </TextOutsideControls>
    );
  };

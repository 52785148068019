import { WorkspacesBots_workspacesBots_botsWithoutWorkspace } from '@utils/Data/Workspaces/@types/WorkspacesBots';
import { useWorkspacesBots } from '@utils/Data/Workspaces/useWorkspacesBots';
import { useMemo, useState } from 'react';

const botsFilter =
  (search: string) =>
  (bot: WorkspacesBots_workspacesBots_botsWithoutWorkspace) =>
    bot.title.toUpperCase().includes(search) ||
    bot.status?.page_info?.title.toUpperCase().includes(search);

export const useSearchInWorkspaceBots = () => {
  const [search, setSearch] = useState('');
  const [data, loading] = useWorkspacesBots();
  const {
    workspaces: baseWorkspaces = [],
    botsWithoutWorkspace: baseBotsWithoutWorkspace = [],
  } = data ?? {};

  const searchString = search.trim().toUpperCase();

  const workspacesBots = useMemo(() => {
    if (!searchString)
      return {
        workspaces: baseWorkspaces,
        botsWithoutWorkspace: baseBotsWithoutWorkspace,
      };

    const workspaces = baseWorkspaces
      .map((workspace) => ({
        ...workspace,
        bots: workspace.bots.filter(botsFilter(searchString)),
      }))
      .filter((workspace) => workspace.bots.length);

    const botsWithoutWorkspace = baseBotsWithoutWorkspace.filter(
      botsFilter(searchString),
    );

    return {
      workspaces,
      botsWithoutWorkspace,
    };
  }, [searchString, baseWorkspaces, baseBotsWithoutWorkspace]);

  return {
    search,
    setSearch,
    loading,
    ...workspacesBots,
  };
};

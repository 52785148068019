import React from 'react';
import { useFormik } from 'formik';
import { Modal } from '@services/index';
import { Button } from '@ui/Button';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Divider } from '@ui/Menu';
import { CheckBox } from '@ui/CheckBox';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './SendTemplateForReviewModal.css';
import { logEvent } from '../utils';

interface SendTemplateForReviewForm {
  dontShowAgain: boolean;
}

export interface SendTemplateForReviewModalProps {
  dontShowAgain: boolean;
  onSubmit: (form: SendTemplateForReviewForm) => void;
  onDismiss: VoidFunction;
}

export const SendTemplateForReviewModal: React.FC<SendTemplateForReviewModalProps> =
  ({ onSubmit, onDismiss, dontShowAgain }) => {
    const { values, handleChange, handleSubmit } =
      useFormik<SendTemplateForReviewForm>({
        initialValues: { dontShowAgain },
        onSubmit: (values) => {
          logEvent({ action: 'approve send for review' });
          onSubmit(values);
        },
      });
    const { t } = useSafeTranslation();

    return (
      <DefaultDialog
        small
        header={
          <Type size="18px" weight="medium">
            {t('pages.MessagesTemplates.sendForReviewModal.header')}
          </Type>
        }
        onDismiss={onDismiss}
        className={css.dialog}
      >
        <form onSubmit={handleSubmit}>
          <Type>{t('pages.MessagesTemplates.sendForReviewModal.text')}</Type>

          <Spacer factor={4} />

          {!dontShowAgain && (
            <>
              <label className={css.label}>
                <CheckBox
                  name="dontShowAgain"
                  checked={values.dontShowAgain}
                  onChange={handleChange}
                />
                <Spacer factor={0} horizontalFactor={2} />
                <Type>
                  {t(
                    'pages.MessagesTemplates.sendForReviewModal.dontShowAgain',
                  )}
                </Type>
              </label>

              <Spacer factor={4} />
            </>
          )}

          <Divider style={{ margin: 0 }} />

          <Spacer factor={4} />

          <Flex>
            <Button data-testid="submit-button" intent="primary" type="submit">
              {t('pages.MessagesTemplates.sendForReviewModal.submit')}
            </Button>
            <Spacer factor={0} horizontalFactor={2} />
            <Button
              data-testid="cancel-button"
              intent="secondary"
              onClick={onDismiss}
            >
              {t('pages.MessagesTemplates.sendForReviewModal.cancel')}
            </Button>
          </Flex>
        </form>
      </DefaultDialog>
    );
  };

interface ShowSendTemplateForReviewModalProps
  extends Omit<SendTemplateForReviewModalProps, 'onSubmit'> {}

export const showSendTemplateForReviewModal = ({
  dontShowAgain,
  onDismiss,
}: ShowSendTemplateForReviewModalProps) =>
  Modal.show<SendTemplateForReviewForm>(({ resolve, close }) => (
    <SendTemplateForReviewModal
      dontShowAgain={dontShowAgain}
      onDismiss={() => {
        close();
        onDismiss();
      }}
      onSubmit={resolve}
    />
  ));

import {
  ServerStorageItemKeys,
  SetCallbackType,
  useServerStorage,
} from '@utils/ServerStorage';
import { FirstSessionStep, OldFirstSessionStep } from '../consts';

const deprecatedStepsToFirstSessionStep = {
  [OldFirstSessionStep.zero]: FirstSessionStep.introQuestions,
  [OldFirstSessionStep.first]: FirstSessionStep.introQuestions,
  [OldFirstSessionStep.intentsAndTest]: FirstSessionStep.introQuestions,
  [OldFirstSessionStep.second]: FirstSessionStep.activateBot,
  [OldFirstSessionStep.third]: FirstSessionStep.paywall,
  [OldFirstSessionStep.done]: FirstSessionStep.done,
  /** временно выключили шаг productTour из за нехватки ресурсов на созвоны */
  [FirstSessionStep.productTour]: FirstSessionStep.paywall,
  [FirstSessionStep.waInitialSetup]: FirstSessionStep.introQuestions,
  [FirstSessionStep.waDiscounts]: FirstSessionStep.introQuestions,
} as const;

const isStepInDeprecatedSteps = (
  step: OldFirstSessionStep | FirstSessionStep,
): step is keyof typeof deprecatedStepsToFirstSessionStep => {
  return step in deprecatedStepsToFirstSessionStep;
};

const getCurrentStep = (
  step: FirstSessionStep | OldFirstSessionStep | undefined,
) => {
  if (!step) return FirstSessionStep.introQuestions;
  return isStepInDeprecatedSteps(step)
    ? deprecatedStepsToFirstSessionStep[step]
    : step;
};

export const useFirstSessionStep: () => [
  FirstSessionStep,
  SetCallbackType<FirstSessionStep>,
  {
    loading: boolean;
    saving: boolean;
  },
] = () => {
  const [step, setStep, state] = useServerStorage<
    FirstSessionStep | OldFirstSessionStep
  >(ServerStorageItemKeys.firstSessionStep);

  return [getCurrentStep(step), setStep, state];
};

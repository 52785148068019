import React from 'react';
import { MessagesTemplateAttributeExampleInput } from '@globals';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { Input } from '@ui/Input';
import { Icon } from '@ui/Icon';
import { Tooltip2 } from '@ui/Tooltip2';
import { TextEllipsis } from '@ui/TextEllipsis';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import { ControlDecoratorProps } from '@ui/Input/ControlDecorator';
import { InputLikeElement, insertText } from '@utils/documentExecCommand';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import attributeExamples from './assets/attribute_examples.png';
import * as css from './AttributeExample.css';
import { ATTR_SIGN } from '@ui/TextWithAttributesEditor';
import { useExtendedFormAction } from '../form/ExtendFormActionsContext';
import { AttributeExamples } from '../form/types';

export const ATTRIBUTE_EXAMPLE_MAX = 100;

export interface AttributeExampleItemProps
  extends MessagesTemplateAttributeExampleInput {
  disabled: boolean;
  error: ControlDecoratorProps['error'];
  onExampleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const AttributeExampleItem: React.FC<AttributeExampleItemProps> = ({
  name,
  value,
  error,
  disabled,
  onExampleChange,
}) => {
  const { saveForm } = useExtendedFormAction();
  const { t } = useSafeTranslation();

  return (
    <div className={css.item}>
      <TextEllipsis>
        <Type color="blue">
          {ATTR_SIGN.start}
          {name}
          {ATTR_SIGN.end}
        </Type>
      </TextEllipsis>
      <TextOutsideControls
        className={css.outsideControls}
        onInsertRequest={(text, el) => {
          insertText(text, el as InputLikeElement);
        }}
        currentTextLimit={ATTRIBUTE_EXAMPLE_MAX - (value || '').trim().length}
        shouldShowOutsideControls={{ symbolsLimit: true }}
        initialShow={false}
        tight
      >
        {({ ref, getInputProps }) => (
          <Input
            error={error}
            data-testid="messages-template-message__example-input"
            disabled={disabled}
            placeholder={t(
              'pages.MessagesTemplates.attributeExample.placeholder',
            )}
            className={css.input}
            value={value || ''}
            onChange={onExampleChange}
            maxLength={ATTRIBUTE_EXAMPLE_MAX}
            {...getInputProps({
              onBlur: () => saveForm(),
            } as any)}
            ref={ref}
          />
        )}
      </TextOutsideControls>
    </div>
  );
};

const AttributeExampleTooltip = () => {
  const { t } = useSafeTranslation();

  return (
    <div className={css.tooltip}>
      <img className={css.tooltip} src={attributeExamples} alt="" />
      <Spacer factor={4} />
      <Type size="12px" color="white">
        {t('pages.MessagesTemplates.attributeExample.tooltip')}
      </Type>
    </div>
  );
};

export interface AttributeExampleProps {
  disabled: boolean;
  attributeExamples: MessagesTemplateAttributeExampleInput[];
  errors?: AttributeExamples;
  onExampleChange: (
    exmaple: MessagesTemplateAttributeExampleInput,
    index: number,
  ) => void;
}

export const AttributeExample: React.FC<AttributeExampleProps> = ({
  disabled,
  errors,
  attributeExamples,
  onExampleChange,
}) => {
  const { t } = useSafeTranslation();

  return (
    <div className={css.wrapper}>
      <Flex justifyContent="space-between">
        <Type size="12px">
          {t('pages.MessagesTemplates.attributeExample.text')}
        </Type>

        <Spacer factor={0} horizontalFactor={3} />

        <Tooltip2
          type="small"
          content={<AttributeExampleTooltip />}
          placement="right"
          boundariesElement="viewport"
        >
          {(ref, bind) => (
            <Icon ref={ref} {...bind} size="20px" color="blue" icon="info" />
          )}
        </Tooltip2>
      </Flex>
      <Spacer factor={4} />
      <div className={css.list}>
        {attributeExamples.map((attribute, index) => (
          <AttributeExampleItem
            disabled={disabled}
            error={errors?.[index]?.value}
            name={attribute.name}
            value={attribute.value}
            onExampleChange={(event) =>
              onExampleChange(
                { name: attribute.name, value: event.target.value },
                index,
              )
            }
          />
        ))}
      </div>
    </div>
  );
};

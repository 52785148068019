import React from 'react';
import { useToaster } from '@ui/Toaster/ToasterProvider';
import { sendEvent } from '@utils/Analytics';
import { useCurrentBotId } from '@utils/Routing';
import { useFlowGroupSharingUpdate } from './FlowGroupMutations';
import { ShareFlowModal } from '../ShareFlowModal';
import { FlowSharingInput } from '../../../../../../@types/globalTypes';
import { useFlowGroupShareState } from '../../common/useFlowsShareState';

interface ShareGroupModalProps {
  groupId: string;
  flowIds: string[];
  sharingParams?: FlowSharingInput | null;
}

export const ShareGroupModal: React.FC<ShareGroupModalProps> = ({
  groupId,
  flowIds,
  sharingParams,
}) => {
  const botId = useCurrentBotId();
  const { addToaster } = useToaster();
  const {
    hasSharedFlowGroup,
    setHasSharedFlowGroup,
  } = useFlowGroupShareState();

  const {
    updateFlowGroupSharing: updateEnableSharing,
  } = useFlowGroupSharingUpdate({
    botId,
    groupId,
    flowIds,
    onCompleted: (data) => {
      addToaster({
        type: 'info',
        content: `${window.i18next.t(
          'ShareGroupModal-Template-1600-flow-sharing-is',
        )}${
          data?.updateFlowGroupSharing.sharing_params?.sharing_enabled
            ? window.i18next.t('ShareGroupModal-string--160-enabled')
            : window.i18next.t('ShareGroupModal-string-2709-disabled')
        }`,
        timeout: 1000,
        closeButton: true,
      });
    },
  });
  const {
    updateFlowGroupSharing: updateAllowDuplicate,
  } = useFlowGroupSharingUpdate({
    botId,
    groupId,
    flowIds,
    onCompleted: (data) => {
      addToaster({
        type: 'info',
        content: `${window.i18next.t(
          'ShareGroupModal-Template-1995-flow-duplicating-from-shared-preview-is',
        )}${
          data?.updateFlowGroupSharing.sharing_params?.allow_cloning
            ? window.i18next.t('ShareGroupModal-string--160-enabled')
            : window.i18next.t('ShareGroupModal-string-2709-disabled')
        }`,
        timeout: 2000,
        closeButton: true,
      });
    },
  });

  return (
    <ShareFlowModal
      label="group"
      footerTitle={window.i18next.t(
        'ShareGroupModal-string-1619-allow-viewers-to-copy-this-group-all-flows-included-to-a-new-bot',
      )}
      linkSearchQuery={`?flowGroup=${groupId}`}
      sharingParams={sharingParams}
      onAllowCloningToggle={() => {
        sendEvent({
          category: 'share flows',
          action: 'change allow duplicate',
          propertyBag: {
            botId,
            groupId,
            allowDuplicate: !sharingParams?.allow_cloning,
          },
        });
        updateAllowDuplicate({
          sharingParams: {
            allow_cloning: !sharingParams?.allow_cloning,
            sharing_enabled: !!sharingParams?.sharing_enabled,
          },
        });
      }}
      onCopyInviteLink={() => {
        sendEvent({
          category: 'share flows',
          action: 'copy share link',
          propertyBag: {
            botId,
            groupId,
          },
        });
      }}
      onCopyShareCode={() => {
        sendEvent({
          category: 'share flows',
          action: 'copy share code',
          propertyBag: {
            botId,
            groupId,
          },
        });
      }}
      onEnableSharingToggle={(value) => {
        sendEvent({
          category: 'share flows',
          action: 'change enable sharing',
          propertyBag: {
            botId,
            groupId,
            sharingEnabled: value,
          },
        });
        if (value && !hasSharedFlowGroup) {
          setHasSharedFlowGroup(true);
        }
        updateEnableSharing({
          sharingParams: {
            allow_cloning: !!sharingParams?.allow_cloning,
            sharing_enabled: value,
          },
        });
      }}
    />
  );
};

import { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { BLOCK_SUBTYPES } from '@components/FlowBuilder/consts';
import { PluginType } from '@components/FlowBuilder/types';
import { FLOW_BLOCKS_VALIDATION_QUERY } from './queries';
import {
  FlowBlocksValidationQuery,
  FlowBlocksValidationQueryVariables,
} from './@types/FlowBlocksValidationQuery';

export interface UseWhatsappTemplateConfigParams {
  botId: string;
  flowId: string;
}

export const useWhatsappTemplateConfig = ({
  botId,
  flowId,
}: UseWhatsappTemplateConfigParams) => {
  const { data, ...queryResult } = useQuery<
    FlowBlocksValidationQuery,
    FlowBlocksValidationQueryVariables
  >(FLOW_BLOCKS_VALIDATION_QUERY, {
    variables: { botId, flowId },
  });

  const config = useMemo(() => {
    const broadcastFlowRoot = data?.bot?.flowBlocks?.find(
      ({ subtype }) => subtype === BLOCK_SUBTYPES.broadcast_flow_root,
    );
    const config = broadcastFlowRoot?.cards?.find(
      ({ plugin_id }) => plugin_id === PluginType.whatsapp_template,
    );

    return config;
  }, [data]);

  return {
    config,
    ...queryResult,
  };
};

import React from 'react';
import { Flex } from '@ui/Flex';
import { ReactComponent as Smile } from './smile.svg';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Spacer } from '@ui/Spacer';
import { useCreateWhatsappTemplateInCfEnable } from '@utils/Whatsapp/hooks/useCreateWhatsappTemplateInCfEnable';

interface NoMessageFoundProps {}

export const NoMessageFound: React.FC<NoMessageFoundProps> = () => {
  const { isCreateWhatsappTemplateInCfEnable } =
    useCreateWhatsappTemplateInCfEnable();
  const { t } = useSafeTranslation();

  return (
    <Flex flexDirection="column" gapFactor={3} alignItems="center">
      <Spacer factor={28} />
      <Smile />
      <Type size="24px" weight="semibold" align="center">
        {isCreateWhatsappTemplateInCfEnable
          ? t('pages.BotPage.ReengageWhatsappTab.noMessageFound.noMessageFound')
          : t(
              'pages.BotPage.ReengageWhatsappTab.noMessageFound_old.noMessageFound',
            )}
      </Type>
      <Type whiteSpace="pre" align="center">
        {isCreateWhatsappTemplateInCfEnable
          ? t('pages.BotPage.ReengageWhatsappTab.noMessageFound.weCouldnt')
          : t('pages.BotPage.ReengageWhatsappTab.noMessageFound_old.weCouldnt')}
      </Type>
    </Flex>
  );
};

import { useApolloClient, useQuery } from '@apollo/react-hooks';
import debounce from 'lodash-es/debounce';

import { useCallback, useEffect, useMemo } from 'react';
import {
  AI_INTENT_FRAGMENT,
  FIRST_SESSION_AI_INTENTS_QUERY,
} from '@utils/Data/Ai/common/queries';
import { clone } from 'ramda';
import {
  createDefaultIntent,
  intentsFilter,
} from '@utils/Data/Ai/Groups/helpers';
import { useCurrentBotId } from '@utils/Routing';
import { useUpdateAiIntentsBulk } from '@utils/Data/Ai/Groups/useUpdateAiIntentsBulk';
import { removeTypename } from '@utils/GQL/utils';
import { useAdminFeatures } from '@utils/Data/Admin';
import {
  AiIntentFilterType,
  FirstSessionAiIntentsType,
  Platform,
} from '@globals';
import client from '../../../../../common/services/ApolloService';
import { detectLanguage } from '../../../../../i18n';
import { showSomethingWentWrongToaster } from '@services/MessageService';
import { log } from 'cf-common/src/logger';
import { useOneTimeState } from 'cf-common/src/utils/hooks';
import {
  FirstSessionAiIntentsQuery,
  FirstSessionAiIntentsQuery_firstSessionAiIntents_intents,
  FirstSessionAiIntentsQueryVariables,
} from '@utils/Data/Ai/common/@types/FirstSessionAiIntentsQuery';

export const useFirstSessionAiIntents = (platform: Platform) => {
  const client = useApolloClient();
  const botId = useCurrentBotId() || '';
  const locale = detectLanguage();
  const {
    updateAiIntents,
    loading: saving,
    data: savedData,
  } = useUpdateAiIntentsBulk();
  const { adminFeatures } = useAdminFeatures();
  const [needSave, setNeedSave] = useOneTimeState<boolean>(false);

  const filterType =
    adminFeatures?.first_session_filter_type === 'contains'
      ? AiIntentFilterType.contains
      : AiIntentFilterType.default;

  const { data, refetch, ...queryState } = useQuery<
    FirstSessionAiIntentsQuery,
    FirstSessionAiIntentsQueryVariables
  >(FIRST_SESSION_AI_INTENTS_QUERY, {
    variables: {
      botId,
      locale,
    },
    skip: !botId,
    onError: (error) => {
      log.warn({ error, msg: 'Error load first session ai intents' });
      showSomethingWentWrongToaster();
    },
  });

  const updateFirstSessionAiIntentInCache = useCallback(
    (intent: FirstSessionAiIntentsQuery_firstSessionAiIntents_intents) =>
      client.writeFragment({
        id: intent.id,
        fragmentName: 'aiIntentFragment',
        fragment: AI_INTENT_FRAGMENT,
        data: intent,
      }),
    [client],
  );

  const readAndCloneFirstSessionAiIntentsQuery = useCallback(() => {
    try {
      return (
        clone(
          client.readQuery<FirstSessionAiIntentsQuery>({
            query: FIRST_SESSION_AI_INTENTS_QUERY,
            variables: {
              botId,
              locale,
            },
          }),
        ) || null
      );
    } catch {
      return null;
    }
  }, [botId, client, locale]);

  const writeFirstSessionAiIntentsQuery = useCallback(
    (intentsData: FirstSessionAiIntentsQuery) => {
      client.writeQuery<FirstSessionAiIntentsQuery>({
        query: FIRST_SESSION_AI_INTENTS_QUERY,
        data: intentsData,
        variables: {
          botId,
          locale,
        },
      });
    },
    [botId, client, locale],
  );

  const crateNewIntentInCache = useCallback(() => {
    const clonedIntentsData = readAndCloneFirstSessionAiIntentsQuery();
    if (!clonedIntentsData) {
      return;
    }
    clonedIntentsData.firstSessionAiIntents.intents.push(
      createDefaultIntent(platform, filterType),
    );
    writeFirstSessionAiIntentsQuery(clonedIntentsData);
  }, [
    filterType,
    platform,
    readAndCloneFirstSessionAiIntentsQuery,
    writeFirstSessionAiIntentsQuery,
  ]);

  const removeFirstSessionAiIntentInCache = useCallback(
    (intentId: string) => {
      const clonedIntentsData = readAndCloneFirstSessionAiIntentsQuery();
      if (!clonedIntentsData) {
        return;
      }
      const index = clonedIntentsData.firstSessionAiIntents.intents.findIndex(
        ({ id }) => id === intentId,
      );
      if (index === -1) {
        return;
      }
      clonedIntentsData.firstSessionAiIntents.intents.splice(index, 1);
      writeFirstSessionAiIntentsQuery(clonedIntentsData);
    },
    [readAndCloneFirstSessionAiIntentsQuery, writeFirstSessionAiIntentsQuery],
  );

  const rawFirstSessionAiIntents = data?.firstSessionAiIntents.intents || [];

  const saveAiIntentsRequest = useCallback(() => {
    rawFirstSessionAiIntents.forEach((intent) => {
      // eslint-disable-next-line no-param-reassign
      intent.filter_type = filterType;
    });
    updateAiIntents(removeTypename(rawFirstSessionAiIntents));
    const clonedIntentsData = readAndCloneFirstSessionAiIntentsQuery();
    if (!clonedIntentsData) {
      return;
    }
    clonedIntentsData.firstSessionAiIntents.type =
      FirstSessionAiIntentsType.saved;
    writeFirstSessionAiIntentsQuery(clonedIntentsData);
  }, [
    rawFirstSessionAiIntents,
    updateAiIntents,
    readAndCloneFirstSessionAiIntentsQuery,
    writeFirstSessionAiIntentsQuery,
    filterType,
  ]);

  const saveAiIntents = useMemo(
    () =>
      debounce(() => {
        setNeedSave(true);
      }, 100),
    [setNeedSave],
  );

  useEffect(() => {
    if (needSave) {
      saveAiIntentsRequest();
    }
  }, [needSave, saveAiIntentsRequest]);

  const currentIntentsFilter = useCallback(intentsFilter('', platform), [
    platform,
  ]);

  const firstSessionAiIntents = useMemo<
    FirstSessionAiIntentsQuery_firstSessionAiIntents_intents[]
  >(
    () => rawFirstSessionAiIntents.filter(currentIntentsFilter),
    [currentIntentsFilter, rawFirstSessionAiIntents],
  );

  const refetchFirstSessionAiIntents = useCallback(() => {
    const locale = detectLanguage();
    return refetch({
      botId,
      locale,
      forceUseTemplate: true,
    });
  }, [botId, refetch]);

  return {
    type: data?.firstSessionAiIntents.type,
    saveAiIntents,
    firstSessionAiIntents,
    updateFirstSessionAiIntentInCache,
    removeFirstSessionAiIntentInCache,
    crateNewIntentInCache,
    saving,
    saved: !!savedData,
    refetchFirstSessionAiIntents,
    ...queryState,
  };
};

export const refetchFirstSessionAiIntents = async (botId: string) => {
  const locale = detectLanguage();
  try {
    return await client.query<
      FirstSessionAiIntentsQuery,
      FirstSessionAiIntentsQueryVariables
    >({
      query: FIRST_SESSION_AI_INTENTS_QUERY,
      variables: {
        botId,
        locale,
        forceUseTemplate: true,
      },
      fetchPolicy: 'network-only',
    });
  } catch (error) {
    log.warn({ error, msg: 'Error refetch first session ai intents' });
    showSomethingWentWrongToaster();
  }
  return undefined;
};

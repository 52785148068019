import { BroadcastStatus, WhatsappBroadcastType } from '@globals';
import { useState } from 'react';
import { useWhatsappBroadcastsList } from '../../hooks/useWhatsappBroadcastsList';

export const useBroadcastTable = () => {
  const [selectedStatusFilter, setSelectedStatusFilter] =
    useState<BroadcastStatus | null>(null);
  const [selectedMessageFilter, setSelectedMessageFilter] =
    useState<WhatsappBroadcastType | null>(null);
  const { whatsappBroadcastsList, loading, fetchMore, isFetchMoreAvailable } =
    useWhatsappBroadcastsList({
      status: selectedStatusFilter,
      type: selectedMessageFilter,
      options: {
        fetchPolicy: 'network-only',
      },
    });

  return {
    selectedStatusFilter,
    setSelectedStatusFilter,
    selectedMessageFilter,
    setSelectedMessageFilter,
    whatsappBroadcastsList,
    loading,
    fetchMore,
    isFetchMoreAvailable,
  };
};

import React from 'react';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Tooltip2 } from '@ui/Tooltip2';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PauseFooter } from '../PauseFooter';
import { PlanBlockHeader, PlanBlockHeaderProps } from './PlanBlockHeader';
import { PlanBlockLayout } from './PlanBlockLayout';

export interface PausedPlanBlockProps extends PlanBlockHeaderProps {}

export const PausedPlanBlock: React.FC<PausedPlanBlockProps> = (props) => {
  const { t } = useSafeTranslation();
  const { getValueDueToSize } = useDeviceMedia();

  return (
    <PlanBlockLayout
      headerView={<PlanBlockHeader {...props} />}
      footerView={<PauseFooter />}
      statusView={
        <Flex alignItems="center">
          <Type size="15px" color="red">
            {t('pages.Pricing.status.paused')}
          </Type>
          <Spacer factor={1} horizontalFactor={1} />
          <Tooltip2
            placement={getValueDueToSize('bottom', 'right')}
            boundariesElement="viewport"
            content={t('pages.Billing.pro.pause')}
            positionFixed
            hoverable
          >
            {(ref, bind) => (
              <Icon ref={ref} {...bind} color="red" icon="info" />
            )}
          </Tooltip2>
        </Flex>
      }
    />
  );
};

import { useAdminFeatures } from '@utils/Data/Admin';
import { LSKey, useLocalStorage } from '@utils/LocalStorage';
import { useIsLiveChatWebView } from '@utils/useIsLiveChatWebView';

export const useShowFirstSession = () => {
  const { adminFeatures, adminFeaturesLoading } = useAdminFeatures();
  const [skipFirstSession] = useLocalStorage(LSKey.skipFirstSession);
  const { isLiveChatWebView } = useIsLiveChatWebView();
  return {
    isShowFirstSession:
      !skipFirstSession &&
      !!adminFeatures?.show_first_session &&
      !isLiveChatWebView,
    showFirstSessionLoading: adminFeaturesLoading,
  };
};

import React from 'react';
import { BotListItemLoader } from '@ui/BotListItem';
import css from './WorkspaceList.css';
import { Type } from '@ui/Type';
import {
  WorkspacesBots_workspacesBots_botsWithoutWorkspace,
  WorkspacesBots_workspacesBots_workspaces,
  WorkspacesBots_workspacesBots_workspaces_workspace,
} from '@utils/Data/Workspaces/@types/WorkspacesBots';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import { ContactUs } from './ContactUs/ContactUs';
import { Workspace } from './Workspace/Workspace';
import { Bot } from './Bot/Bot';
import { Spacer } from '@ui/Spacer';
import { BotListNothingFound } from '@components/BotList';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { SortTypeDropdown } from './components/SortTypeDropdown';
import { LSKey, useLocalStorage } from '@utils/LocalStorage';
import { sortBots, SortTypesIds } from './helpers';

const loader = (
  <>
    {Array.from({ length: 3 }).map((_, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <BotListItemLoader key={i} className={css.botListItem} />
    ))}
  </>
);

interface WorkspaceContentProps {
  loading: boolean;
  workspaces: WorkspacesBots_workspacesBots_workspaces[];
  botsWithoutWorkspace: WorkspacesBots_workspacesBots_botsWithoutWorkspace[];
  workspaceWithEditingList: WorkspacesBots_workspacesBots_workspaces_workspace[];
}

export const WorkspaceContent: React.FC<WorkspaceContentProps> = ({
  loading,
  workspaces,
  botsWithoutWorkspace,
  workspaceWithEditingList,
}) => {
  const { getValueDueToSize } = useDeviceMedia();
  const { t } = useSafeTranslation();
  const isEmptyLists = !workspaces.length && !botsWithoutWorkspace.length;
  const [currentSortTypeId, setCurrentSortTypeId] = useLocalStorage(
    LSKey.botSortType,
    SortTypesIds.planMostUsed,
  );

  if (loading) return loader;
  if (isEmptyLists) {
    return (
      <Flex
        flexDirection="column"
        style={{ height: '100%' }}
        justifyContent="center"
        alignItems="center"
      >
        <BotListNothingFound />
      </Flex>
    );
  }
  return (
    <>
      <SortTypeDropdown
        value={currentSortTypeId}
        onChange={setCurrentSortTypeId}
      />
      <Spacer factor={8} />
      {Boolean(workspaces.length) && (
        <Flex
          justifyContent="space-between"
          flexDirection={getValueDueToSize('column', 'row')}
        >
          <Type size="24px" weight="semibold">
            {t('BotList.workspaces')}
          </Type>
          <ContactUs />
        </Flex>
      )}
      {
        /** Workspace section */
        workspaces.map(({ workspace, bots }) => (
          <Workspace key={workspace.id} workspace={workspace}>
            {sortBots(bots, currentSortTypeId).map((bot) => (
              <Bot
                key={bot.id}
                bot={bot}
                workspaceWithEditingList={workspaceWithEditingList}
                currentWorkspace={workspace}
              />
            ))}
          </Workspace>
        ))
      }
      <Spacer factor={8} />
      <Type size="24px" weight="semibold">
        {t('BotList.bots')}
      </Type>
      {sortBots(botsWithoutWorkspace, currentSortTypeId).map((bot) => (
        <Bot
          key={bot.id}
          bot={bot}
          workspaceWithEditingList={workspaceWithEditingList}
        />
      ))}
    </>
  );
};

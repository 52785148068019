import { useSafeTranslation } from '@utils/useSafeTranslation';
import React, { useContext } from 'react';
import { BroadcastsTypeManager } from './BroadcastsTypeManager';
import { OneTimeBroadcastType } from './OneTimeBroadcastType';
import { ScheduleBroadcastType } from './ScheduleBroadcastType';
import { TriggerBroadcastType } from './TriggerBroadcastType';

interface BroadcastsTypeManagerContextProps {
  broadcastsTypeManager: BroadcastsTypeManager;
}

const BroadcastsManagerContext =
  React.createContext<BroadcastsTypeManagerContextProps>({
    broadcastsTypeManager: null as unknown as BroadcastsTypeManager,
  });

export const BroadcastsTypeManagerProvider: React.FC = ({ children }) => {
  const { t } = useSafeTranslation();

  const broadcastsTypeManager = new BroadcastsTypeManager([
    new OneTimeBroadcastType(t),
    new ScheduleBroadcastType(t),
    new TriggerBroadcastType(t),
  ]);

  return (
    <BroadcastsManagerContext.Provider value={{ broadcastsTypeManager }}>
      {children}
    </BroadcastsManagerContext.Provider>
  );
};

export const useBroadcastsTypeManager = () =>
  useContext(BroadcastsManagerContext);

import React, { HTMLProps } from 'react';
import { Item } from '@ui/SimpleCombobox';
import cn from 'classnames';
import * as css from './NativeOpacitySelect.css';
import { useSafeTranslation } from '@utils/useSafeTranslation';

interface NativeOpacitySelectProps extends HTMLProps<HTMLSelectElement> {
  items: Item[];
}

export const NativeOpacitySelect: React.FC<NativeOpacitySelectProps> = ({
  items,
  className,
  ...props
}) => {
  const { t } = useSafeTranslation();
  return (
    <select className={cn(css.select, className)} {...props}>
      <option key="placeholder" value="">
        {t('pages.FirstSession.IntroQuestionsBlock.selectPlaceholder')}
      </option>
      {items.map(({ id, title }) => (
        <option key={id} value={id}>
          {title}
        </option>
      ))}
    </select>
  );
};

import { useLazyQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { AdminTest, AdminTestVariables } from './@types/AdminTest';
import { useCallback } from 'react';

export const ADMINS = gql`
  query AdminTest($botId: String!) {
    bot(id: $botId) {
      id
      admins {
        id
      }
    }
  }
`;

export const useBotAdminsLazy = () => {
  const [getAdminsBase, { data, ...queryResult }] = useLazyQuery<
    AdminTest,
    AdminTestVariables
  >(ADMINS);

  const getAdmins = useCallback(
    (variables: AdminTestVariables) => {
      getAdminsBase({ variables });
    },
    [getAdminsBase],
  );

  return [
    getAdmins,
    { admins: data?.bot.admins || [], ...queryResult },
  ] as const;
};

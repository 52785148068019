import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { FilterableSearchField } from '@components/FilterableSearchField';
import { sendEvent } from '@utils/Analytics';
import { PluginType } from '@components/FlowBuilder/types';
import { EntryPointStatusFilterableInput } from './components/EntryPointStatusFilter';
import { FlowsFilter } from './types';
import { Translation } from '@translations';

const getFlowsFilterableSearchI18nKey = (key: string) =>
  `pages.BotPage.FlowTab.FlowList.FilterableSearch.${key}` as Translation;

export const FlowsFilterableSearch: React.FC<{}> = () => {
  const { t } = useSafeTranslation();
  return (
    <FilterableSearchField<FlowsFilter>
      searchPlaceholderText={t(
        getFlowsFilterableSearchI18nKey('searchPlaceholderText'),
      )}
      filterMenuTitle={t(getFlowsFilterableSearchI18nKey('filterMenuTitle'))}
      width="326px"
      onPrepareFilter={(preparedFilter) => {
        sendEvent({
          category: 'flows',
          action: 'prepare filter',
          label: preparedFilter,
        });
      }}
      onSearchClick={() => {
        sendEvent({
          category: 'flows',
          action: 'click search',
        });
      }}
    >
      {[
        {
          id: FlowsFilter.entryPointStatus,
          title: t(
            getFlowsFilterableSearchI18nKey('entryPointStatusFilterTitle'),
          ),
          icon: PluginType.ref_link_entry_point,
          type: 'item',
          activationString: t(
            getFlowsFilterableSearchI18nKey(
              'entryPointStatusFilterActivationString',
            ),
          ),
          renderFilterableInput: (preparedFilter, config) => (
            <EntryPointStatusFilterableInput
              preparedFilter={preparedFilter}
              {...config}
            />
          ),
        },
      ]}
    </FilterableSearchField>
  );
};

import React from 'react';
import { useCurrentBotId } from '@utils/Routing';
import { useBotFeatures } from '@utils/Data/Bot';
import { Spacer } from '@ui/Spacer';
import { SectionBox } from '@components/SectionBox';
import { DialogsPricingBotPanelView } from './DialogsPricingBotPanelView';
import { OldPricingBotPanelView } from './OldPricingBotPanelView';
import * as css from './BotPanel.css';

export const BotPanel = () => {
  const botId = useCurrentBotId()!;
  const { botFeatures, botFeaturesLoading } = useBotFeatures(botId);

  return (
    <>
      <SectionBox className={css.section} data-testid="bot-panel">
        {botFeatures?.dialogs_pricing_enabled ? (
          <DialogsPricingBotPanelView loading={botFeaturesLoading} />
        ) : (
          <OldPricingBotPanelView loading={botFeaturesLoading} />
        )}
      </SectionBox>

      <Spacer factor={9} />
    </>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { useResetMetaForMobile } from '@utils/css/useResetMetaForMobile';
import {
  usePremiumPricingInfo,
  usePremiumPricingUsage,
} from '@utils/Data/Pricing/hooks';
import { PremiumPricingUsageQuery_premiumPricingUsage as PremiumPricingUsageData } from '@utils/Data/Pricing/@types/PremiumPricingUsageQuery';
import { DatePickerRow, HeaderRow, UsageDataRow } from './components';
import { sendEvent } from '@utils/Analytics';
import { MONTHS_IN_YEAR_COUNT } from './constants';
import * as css from './UsageTable.css';

interface UsageTableProps {
  data: PremiumPricingUsageData | undefined | null;
  loading?: boolean;
  selectedYear?: number;
  onYearChange(year: number): void;
}

const UsageTable: React.FC<UsageTableProps> = ({
  data,
  loading,
  selectedYear,
  onYearChange,
}) => (
  <div className={css.tableContainer}>
    <table className={css.table}>
      <thead className={css.tableHeader}>
        <DatePickerRow
          selectedYear={selectedYear}
          onYearChange={onYearChange}
          cols={Math.max(MONTHS_IN_YEAR_COUNT, data?.usage.length ?? 0)}
          years={data?.years}
        />
        <HeaderRow usage={data?.usage} loading={loading} />
      </thead>
      <tbody className={css.tableBody}>
        <UsageDataRow
          pages={data?.pages}
          usage={data?.usage}
          loading={loading}
        />
      </tbody>
    </table>
  </div>
);

export const UsageTableContainer: React.FC<{}> = () => {
  useResetMetaForMobile();

  const [year, setYear] = useState<number | undefined>();

  const { data: pricing } = usePremiumPricingInfo();
  const { data, getPremiumPricingUsage, loading } = usePremiumPricingUsage(
    pricing?.subscriptionId,
    ({ premiumPricingUsage }) => {
      const years = premiumPricingUsage?.years;
      if (!year && years) {
        setYear(years[years.length - 1]);
      }
    },
  );

  useEffect(() => {
    sendEvent({
      category: 'usage table',
      action: 'show table',
    });
  }, []);

  const handleYearChange = useCallback(
    (year: number) => {
      sendEvent({
        category: 'usage table',
        action: 'set year',
        propertyBag: {
          year,
        },
      });
      setYear(year);
      getPremiumPricingUsage(year);
    },
    [getPremiumPricingUsage],
  );

  return (
    <UsageTable
      data={data}
      loading={loading}
      selectedYear={year}
      onYearChange={handleYearChange}
    />
  );
};

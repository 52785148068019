import * as React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useResetMetaForMobile } from '@utils/css/useResetMetaForMobile';
import { AbandonedCartPage } from '../../GrowPage/AbandonedCart/AbandonedCartPage';
import { useCurrentRoutePath } from '@utils/Routing';
import { ClickToMessengerAdsSettingsPage } from '../../GrowPage/ClickToMessenger/ClickToMessengerAdsSettingsPage/ClickToMessengerAdsSettingsPage';
import { ClickToMessengerFullCampaignsListPage } from '../../GrowPage/ClickToMessenger/ClickToMessengerFullCampaignsListPage/ClickToMessengerFullCampaignsListPage';
import { HomeTabView } from './components/HomeTabView';
import { FacebookMessengerAdsPage } from '../../GrowPage/ClickToMessenger/FacebookMessengerAdsPage';
import { AquireUsersFromComments } from '../../AquireUsersFromComments';
import { useFlowTabDeepLinks } from '../FlowTab/FlowTabDeepLinks';

export const HomeTab: React.FC<RouteComponentProps> = (props) => {
  useResetMetaForMobile();
  useFlowTabDeepLinks({});
  const path = useCurrentRoutePath(props.match);

  return (
    <Switch>
      <Route
        path={`${path}/abandoned-cart/:pageTabId`}
        render={() => <AbandonedCartPage />}
      />
      <Route
        path={`${path}/ctm-ads-settings/:pageTabId?`}
        render={() => <ClickToMessengerAdsSettingsPage />}
      />
      <Route
        path={`${path}/ctm-all-campaigns`}
        render={() => <ClickToMessengerFullCampaignsListPage />}
      />
      <Route
        path={`${path}/facebook-messenger-ads`}
        render={() => <FacebookMessengerAdsPage />}
      />
      <Route
        path={`${path}/aquire-users-from-comments`}
        render={() => <AquireUsersFromComments />}
      />
      <Route render={() => <HomeTabView />} />
    </Switch>
  );
};

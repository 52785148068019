import Maybe from 'graphql/tsutils/Maybe';
import { sendEvent } from '@utils/Analytics';
import { useDownloadPremiumPricingInvoice } from '@utils/Data/Pricing';
import { useBillingColumns } from '../Table/useBillingColumns';

export const usePremiumBillingColumns = (subscriptionId: Maybe<string>) => {
  const [downloadInvoice] = useDownloadPremiumPricingInvoice();

  return useBillingColumns({
    onDownload: ({ id: invoiceId, date }) => {
      sendEvent({
        category: 'billing page',
        action: 'download invoice',
        label: 'premium',
        propertyBag: {
          invoiceId,
        },
      });
      return invoiceId && date && subscriptionId
        ? downloadInvoice(subscriptionId, invoiceId, String(date))
        : Promise.reject();
    },
  });
};

import { useEffect } from 'react';
import { IntroQuestionsKeywordsSelector, Platform } from '@globals';
import { IntroQuestionsConfigQuery_introQuestionsConfig_variables } from './@types/IntroQuestionsConfigQuery';
import { IntroQuestionsValues } from '../types';
import { PlatformSelector } from '../../../types';
import { testValue } from '../helpers';
import { isSomeEnum } from '@utils/isSomeEnum';

export interface useIntroQuestionsPlatformSwitchParams
  extends PlatformSelector {
  questionsConfig:
    | IntroQuestionsConfigQuery_introQuestionsConfig_variables[]
    | undefined;
  values: IntroQuestionsValues;
}

const getPlatform = (value: string | string[]): Platform => {
  if (Array.isArray(value))
    return (
      (value.find((item) => isSomeEnum(Platform, item)) as Platform) ??
      Platform.facebook
    );
  return isSomeEnum(Platform, value) ? value : Platform.facebook;
};

export const useIntroQuestionsPlatformSwitch = ({
  questionsConfig,
  platform,
  values,
  onPlatformChange,
}: useIntroQuestionsPlatformSwitchParams) => {
  useEffect(() => {
    const platformQuestionId = questionsConfig?.find(
      ({ keywords_selector: selector }) =>
        selector === IntroQuestionsKeywordsSelector.platform,
    )?.id;

    if (
      platformQuestionId &&
      !testValue(platform, values[platformQuestionId] || '')
    ) {
      onPlatformChange(getPlatform(values[platformQuestionId] ?? ''));
    }
  }, [onPlatformChange, platform, questionsConfig, values]);
};

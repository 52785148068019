import React, { useCallback, useEffect } from 'react';
import { useAdminFeatures } from '@utils/Data/Admin';
import { FlowPlaceholder } from '@utils/Data/Flow';
import { useCurrentBotId, useCurrentFlowId } from '@utils/Routing';
import { FlowControls } from './FlowControls';
import { FlowLoadingState } from './FlowLoader';
import * as css from './FlowTab.css';
import {
  EmptyTabPlaceholder,
  LoadingErrorPlaceholder,
  RemovedTabPlaceholder,
  TabPlaceholderWithTemplates,
} from './TabPlaceholders';
import { FlowList } from './FlowList';
import { FilterableSearchContextProvider } from '@components/FilterableSearchField';
import { FlowBuilderMobileWarning } from './FlowBuiilderMobileWarning/FlowBuilderMobileWarning';
import { useRolePermission } from '@utils/Roles';
import { Permission } from '../../../common/services/RoleService';
import { BotStatusCallout } from './BotStatusCallout';
import { useTemplateCheckList } from '@utils/Template/useTemplateCheckList';
import { useLoadingState } from './useLoadingState';
import { FlowBuilderContainer } from './FlowBuilderContainer';
import { useOnbordingContext } from '@components/Onboarding/OnboardingContext';

export const FlowBuilderWrapperBox: React.FC<{ id?: string }> = ({
  children,
  id,
}) => (
  <div id={id} className={css.flowBuilderWrapper}>
    {children}
  </div>
);

interface FlowTabProps {
  showAside: boolean;
  setShowAside(showAside: boolean): void;
}

export const FlowTab: React.FC<FlowTabProps> = ({
  showAside,
  setShowAside,
}) => {
  const botId = useCurrentBotId()!;
  const flowId = useCurrentFlowId()!;
  const [loadingState, setLoadingState] = useLoadingState();
  const { setFlowBuilderReady } = useOnbordingContext();

  const changeLoadingState = useCallback(
    (newState: FlowLoadingState) => {
      setLoadingState(newState);
      if (newState === FlowLoadingState.ready) setFlowBuilderReady(true);
    },
    [setLoadingState, setFlowBuilderReady],
  );

  useEffect(
    () => () => {
      setFlowBuilderReady(false);
    },
    [setFlowBuilderReady],
  );

  const { adminFeatures } = useAdminFeatures();
  const { allowed: editAllowed, loading: permissionLoading } =
    useRolePermission({
      domain: 'flows',
      can: Permission.EDIT,
    });

  // checklist for instructions on how to create a flow for templates
  useTemplateCheckList();

  const getFlowView = () => {
    switch (flowId) {
      case FlowPlaceholder.new:
        return adminFeatures?.flow_templates ? (
          <TabPlaceholderWithTemplates />
        ) : (
          <EmptyTabPlaceholder />
        );
      case FlowPlaceholder.removed:
        return adminFeatures?.flow_templates ? (
          <TabPlaceholderWithTemplates />
        ) : (
          <RemovedTabPlaceholder />
        );
      case FlowPlaceholder.error:
        return <LoadingErrorPlaceholder />;
      default:
        return (
          <>
            {loadingState === FlowLoadingState.ready && (
              <>
                <FlowControls />
                <BotStatusCallout />
              </>
            )}
            <FlowBuilderContainer
              loadingState={loadingState}
              permissionLoading={permissionLoading}
              editAllowed={editAllowed}
              setLoadingState={changeLoadingState}
              botId={botId}
              flowId={flowId}
            />
          </>
        );
    }
  };

  return (
    <FlowBuilderMobileWarning>
      <FilterableSearchContextProvider>
        <FlowList
          readOnly={!editAllowed}
          showPanel={showAside}
          setShowPanel={setShowAside}
          loadingState={loadingState}
          onFlowAdded={() => changeLoadingState(FlowLoadingState.none)}
        />
      </FilterableSearchContextProvider>
      <div className={css.flowTab}>
        <FlowBuilderWrapperBox>{getFlowView()}</FlowBuilderWrapperBox>
      </div>
    </FlowBuilderMobileWarning>
  );
};

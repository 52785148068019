import React from 'react';
import { Hover } from 'react-powerplug';
import { NavLink } from 'react-router-dom';
import Maybe from 'graphql/tsutils/Maybe';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Statistics } from '@ui/Statistics/Statistics';
import { FormattedNumber } from '../BotStatistic/FormattedNumber';
import * as css from './StatItem.css';

interface StatItemBaseProps extends TestLocator {
  caption: string;
  linkTo: string;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
}

export const StatItemBase: React.FC<StatItemBaseProps> = ({
  caption,
  children,
  className,
  linkTo,
  onClick,
  disabled,
  ...props
}) => (
  <Statistics caption={caption} className={className}>
    {disabled ? (
      <Flex alignItems="center">{children}</Flex>
    ) : (
      <Hover>
        {({ hovered, bind }) => (
          <NavLink
            {...bind}
            to={linkTo}
            className={css.reset}
            style={{
              textDecoration: hovered ? 'underline' : 'none',
            }}
            data-testid={props['data-testid']}
            onClick={onClick}
          >
            <Flex alignItems="center">
              {children}
              {hovered && (
                <>
                  <Spacer horizontalFactor={3} />
                  <Icon icon="redirect" />
                </>
              )}
            </Flex>
          </NavLink>
        )}
      </Hover>
    )}
  </Statistics>
);

interface StatItemTypeProps extends StatItemBaseProps {
  value: Maybe<number>;
}

export const StatItem: React.FC<StatItemTypeProps> = ({ value, ...props }) => (
  <StatItemBase {...props}>
    <FormattedNumber number={value} />
  </StatItemBase>
);

import React from 'react';
import { useBotSectionData } from '../BotSectionView/Data/useBotSectionData';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { StatItem } from '../AIStatistics/StatItem';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import { sendHomeTabEvent } from '../../utils';

export const Teammates = () => {
  const botId = useCurrentBotId()!;
  const { t } = useSafeTranslation();
  const { teammates } = useBotSectionData();

  return (
    <StatItem
      value={teammates}
      linkTo={getTabLink(BotTabs.configure, botId)}
      caption={t('pages.GrowPage.BotPanel.statistics.teammates')}
      data-testid="bot-panel__teammates-link"
      onClick={() => sendHomeTabEvent({ action: 'go to configure tab click' })}
    />
  );
};

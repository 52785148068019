import React from 'react';
import { BotPanelLoaderView } from '../BotPanelLoaderView';
import { BotSectionHeader } from '../BotSectionHeader';
import * as css from './OldPricingBotPanelView.css';

export interface OldPricingBotPanelViewProps {
  loading?: boolean;
}

export const OldPricingBotPanelView: React.FC<OldPricingBotPanelViewProps> = ({
  loading,
}) => {
  if (loading) {
    return <BotPanelLoaderView className={css.loader} />;
  }

  return <BotSectionHeader />;
};

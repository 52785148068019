import React from 'react';
import { ButtonProps } from '@ui/Button';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { GoToPlansButton } from './GoToPlansButton';
import { useBillingData } from '../Data/useBillingData';
import { PricingPlanType } from '../../../../../Plans/types';

export const UpgradeButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { isLastTier } = useBillingData();
    const { t } = useSafeTranslation();

    return (
      <GoToPlansButton
        plan={
          isLastTier ? PricingPlanType.enterprise : PricingPlanType.business
        }
        ref={ref}
        {...props}
      >
        {t('pages.GrowPage.BotPanel.upgrade')}
      </GoToPlansButton>
    );
  },
);

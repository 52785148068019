import React, { useCallback } from 'react';
import cn from 'classnames';
import { useGoToPlans } from '@utils/Routing';
import * as css from './common.css';
import { PricingPlanType } from '../../../../../Plans/types';
import { getSubscribersLeft, sendHomeTabEvent } from '../../../utils';
import {
  SizeDependentButton,
  SizeDependentButtonProps,
} from './SizeDependentButton';
import { useCurrentBot } from '@utils/Data/Bot';
import { Tooltip2 } from '@ui/Tooltip2';
import { combineRefs } from '@utils/combineRefs';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useBotSwitcherNavItemData } from '@components/LeftNavigationPanel/components/BotSwitcher/hooks/useBotSwitcherNavItemData';

export interface UpgradeOldPricingButtonProps extends SizeDependentButtonProps {
  plan?: PricingPlanType;
}

export const UpgradeOldPricingButton = React.forwardRef<
  HTMLButtonElement,
  UpgradeOldPricingButtonProps
>(({ children, plan, onClick, ...props }, ref) => {
  const { isPaymentFailed } = useBotSwitcherNavItemData();
  const goToPlans = useGoToPlans({
    plan: plan || PricingPlanType.business,
    isDialogsPricing: false,
  });
  const { bot } = useCurrentBot();
  const { t } = useSafeTranslation();

  const onButtonClick = useCallback(
    (event) => {
      sendHomeTabEvent({ action: 'go to pricing' });
      goToPlans();
      onClick?.(event);
    },
    [onClick, goToPlans],
  );

  const isLimitReached = bot && getSubscribersLeft(bot) <= 0;
  const error = isPaymentFailed || isLimitReached;

  return (
    <Tooltip2
      boundariesElement="viewport"
      content={t(
        'modernComponents.EntryPointsModal.planLimitReached.subscribers.tooltip',
        { planLimit: bot?.limits.usersLimit },
      )}
      disabled={!isLimitReached}
    >
      {(tooltipRef, bind) => (
        <SizeDependentButton
          data-testid="bot-panel__go-to-old-pricing-button"
          intent={error ? 'red' : 'primary'}
          onClick={onButtonClick}
          className={cn(css.button, props.className)}
          ref={combineRefs([ref, tooltipRef])}
          {...bind}
          {...props}
        >
          {isPaymentFailed
            ? t('modernComponents.BotSwitcher.update')
            : t('modernComponents.BotSwitcher.upgrade')}
        </SizeDependentButton>
      )}
    </Tooltip2>
  );
});

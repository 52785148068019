import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useCurrentBotId } from '@utils/Routing';
import { MESSAGES_TEMPLATE_FRAGMENT } from './messageTemplateFragments';
import {
  GetMessageTemplate,
  GetMessageTemplateVariables,
} from './@types/GetMessageTemplate';

export const GET_MESSAGE_TEMPLATE_QUERY = gql`
  query GetMessageTemplate(
    $botId: MongoObjectId!
    $templateId: MongoObjectId!
  ) {
    messageTemplate(botId: $botId, templateId: $templateId) {
      ...messagesTemplateFragment
    }
  }

  ${MESSAGES_TEMPLATE_FRAGMENT}
`;

export const useGetMessageTemplate = (templateId: string) => {
  const botId = useCurrentBotId()!;
  const queryResult = useQuery<GetMessageTemplate, GetMessageTemplateVariables>(
    GET_MESSAGE_TEMPLATE_QUERY,
    { variables: { botId, templateId } },
  );

  return queryResult;
};

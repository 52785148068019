import React, { useCallback, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { removeEntity } from '@components/FlowBuilder/components';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useCurrentBotId } from '@utils/Routing';
import { removeTypename } from '@utils/GQL/utils';
import { getNextItemName } from '@utils/Clone/getNextItemName';
import { useDeleteMessageTemplate } from '../../../data/useDeleteMessageTemplate';
import { useCreateMessageTemplate } from '../../../data/useCreateMessageTemplate';
import { GetMessageTemplates_messageTemplates } from '../../../data/@types/GetMessageTemplates';
import { useGetMessageTemplates } from '../../../data/useGetMessageTemplates';
import { DEFAULT_TEMPLATE_CONTENT } from '../../../consts';
import { useNavigateToTemplate } from './useNavigateToTemplate';
import { useSendTemplateForReview } from './useSendTemplateForReview';
import { logEvent } from '../utils';
import { showInfoToaster } from '@services/MessageService';

export const useMessageTemplates = () => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  const [createNewTemplateMutation, { loading: creatingNew }] =
    useCreateMessageTemplate();
  const [cloneTemplateMutation, { loading: cloning }] =
    useCreateMessageTemplate();
  const [deleteTemplate, { loading: deleting }] = useDeleteMessageTemplate();
  const { navigateToTemplate } = useNavigateToTemplate();
  const { templates } = useGetMessageTemplates();

  const [sendForReview, { loading: reviewing }] = useSendTemplateForReview();

  const removeTemplate = useCallback(
    (templateId: string) => {
      return removeEntity({
        onSubmit: () => {
          logEvent({ action: 'delete template' });
          deleteTemplate({
            templateId,
            botId,
          });
        },
        renderActionText: () =>
          t('pages.MessagesTemplates.Table.deleteConfirm.button'),
        renderHeading: () =>
          t('pages.MessagesTemplates.Table.deleteConfirm.title'),
        renderNoteText: () => (
          <Trans
            t={t}
            i18nKey="pages.MessagesTemplates.Table.deleteConfirm.text"
          />
        ),
      })?.then(() => {
        showInfoToaster('pages.MessagesTemplates.removed', false);
      });
    },
    [botId, deleteTemplate, t],
  );

  const createNewTemplate = useCallback(async () => {
    if (!botId) {
      return;
    }
    const { data } = await createNewTemplateMutation({
      botId,
      template: {
        name: '',
        category: null,
        language: null,
        content: DEFAULT_TEMPLATE_CONTENT,
      },
    });
    const id = data?.createMessageTemplate.id;
    if (id) {
      navigateToTemplate(id);
    }
  }, [botId, createNewTemplateMutation, navigateToTemplate]);

  const names = useMemo<string[] | undefined>(
    () => templates?.map(({ name }) => name),
    [templates],
  );

  const cloneTemplate = useCallback(
    async (template: GetMessageTemplates_messageTemplates) => {
      if (!botId || !names) {
        return;
      }

      const { name, category, language, content } = removeTypename(
        template,
      ) as GetMessageTemplates_messageTemplates;

      const { data } = await cloneTemplateMutation({
        botId,
        template: {
          name: getNextItemName(name, names),
          category,
          language,
          content,
        },
      });
      const id = data?.createMessageTemplate.id;
      if (id) {
        navigateToTemplate(id);
      }
    },
    [botId, cloneTemplateMutation, names, navigateToTemplate],
  );

  return {
    createNewTemplate,
    sendForReview,
    removeTemplate,
    cloneTemplate,
    creatingNew,
    cloning,
    deleting,
    reviewing,
  };
};

import React, { useCallback } from 'react';
import * as css from './ActivateBotBlock.css';
import {
  IntroQuestionsCodes,
  IntroQuestionsIndustyOptions,
  Step,
} from '../../types';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import { Button } from '@ui/Button';
import { useFirstSessionStepState } from '../common/useFirstSessionStepState';
import { MOBILE_WIDTH } from '../../consts';
import {
  ConnectInstagramRestorationQueryField,
  ConnectInstagramVariant,
  useConnectInstagramDialog,
} from '@components/dialogs/ConnectInstagramDialog';
import {
  ConnectPageField,
  ConnectPageOrigin,
  useConnectPage,
} from '@components/ConnectPageDialog';
import { useCurrentBotId } from '@utils/Routing';
import { OriginalAccountType, Platform } from '@globals';
import { sendEvent } from '@utils/Analytics';
import { useIsWindowWidthLess } from '@utils/DOM/useIsWindowWidthLess';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import cn from 'classnames';
import { isMobileDevice } from 'cf-common/src/utils/isMobileDevice';
import { Icon } from '@ui/Icon';
import { HighlightBorderBoxHideable } from '@ui/Box/HighlightBorderBoxHideable';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { Header } from './components/Header/Header';
import { Cards } from './components/Cards/Cards';
import { useIntroQuestionVariables } from '../../hooks/useIntroQuestionVariables';
import { useAdminData } from '../../../../../../utils/Data/Admin';

interface ActivateBotBlockProps extends Step {
  platform: Platform;
}

const introQuestionVariables = [IntroQuestionsCodes.industry];

export const ActivateBotBlock: React.FC<ActivateBotBlockProps> = ({
  enabled,
  done,
  platform,
  onDone,
  current,
  stepSaving,
  onGoToPrevRequest,
}) => {
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();
  const isMobileWindowSize = useIsWindowWidthLess(MOBILE_WIDTH);
  const {
    data: [industryIntroQuestion],
    loading: introQuestionsSavedDataLoading,
  } = useIntroQuestionVariables(introQuestionVariables);

  const isEcommerce = industryIntroQuestion
    ? industryIntroQuestion.value.includes(
        IntroQuestionsIndustyOptions.eCommerce,
      )
    : false;

  const { adminData } = useAdminData();
  const isGoogleAuth =
    adminData?.me.original_account_type === OriginalAccountType.google;

  const botId = useCurrentBotId() || '';
  const { isConnected: isPageConnected } = usePageConnected();

  const { scrollTargetRef, expand, stepBoxClickHandler, stepDoneHandler } =
    useFirstSessionStepState({
      done,
      onDone,
      current,
      enabled,
    });

  const isInstagramConnect = platform === Platform.instagram;

  const { connectInstagram } = useConnectInstagramDialog(
    ConnectInstagramVariant.full,
    {
      [ConnectInstagramRestorationQueryField.name]: 'first-session',
    },
    stepDoneHandler,
  );

  const { connectPage } = useConnectPage({
    botId,
    urlParams: {
      [ConnectPageField.origin]: isInstagramConnect
        ? ConnectPageOrigin.instagramConnect
        : ConnectPageOrigin.default,
    },
    onPageConnected() {
      if (isInstagramConnect) {
        connectInstagram();
      } else {
        stepDoneHandler();
      }
    },
    onAccountConnected() {
      sendEvent({
        category: 'first-session',
        action: 'fb account connected',
        propertyBag: {
          platform,
        },
      });
    },
    dontShowSuccessState: true,
  });

  const doneHandler = useCallback(() => {
    sendEvent({
      category: 'first-session',
      action: 'connect page click',
      propertyBag: {
        platform,
      },
    });
    if (!isPageConnected) {
      connectPage();
      return;
    }
    if (isPageConnected && platform === Platform.instagram) {
      connectInstagram();
      return;
    }
    stepDoneHandler();
  }, [
    platform,
    isPageConnected,
    stepDoneHandler,
    connectPage,
    connectInstagram,
  ]);

  const skipFbConnection = useCallback(() => {
    sendEvent({
      category: 'first-session',
      action: 'skip connect page',
      propertyBag: {
        platform,
      },
    });
    stepDoneHandler();
  }, [platform, stepDoneHandler]);

  if (!enabled && isMobileWindowSize) {
    return null;
  }

  const basedOnOurCustomer = (
    <Type weight="medium" color="baseTertiary">
      {t('pages.FirstSession.ActivateBotBlock.basedOnOurCustomer')}
    </Type>
  );

  return (
    <HighlightBorderBoxHideable
      hide={isMobileWindowSize}
      ref={scrollTargetRef}
      highlightEnabled={false}
      borderRadiusFactor={3}
      className={cn(
        css.box,
        enabled && !expand && !isMobileDevice() && css.hover,
      )}
      innerClassName={css.innerBox}
      onClick={stepBoxClickHandler}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
        fullHeight={isSmallScreenSize}
      >
        {/* временно убрали 16 фев 2023 <FirstSessionProgressBar /> */}
        <Header
          loading={introQuestionsSavedDataLoading}
          isEcommerce={isEcommerce}
        />
        <Cards
          loading={introQuestionsSavedDataLoading}
          isEcommerce={isEcommerce}
        />
        {isSmallScreenSize && (
          <Flex flexDirection="column" alignItems="flex-start" fullWidth>
            <Spacer factor={4} />
            {basedOnOurCustomer}
          </Flex>
        )}
        {enabled && expand && (
          <>
            <Spacer factor={8} />
            <Flex className={css.footer} alignItems="center">
              {!isSmallScreenSize && basedOnOurCustomer}
              <Button
                onClick={() => {
                  onGoToPrevRequest?.();
                }}
                intent="secondary"
                fitToText
                className={css.backButton}
                data-testid="first-session__activate-bot-step__back-button"
              >
                <Icon icon="arrowBack" size="16px" />
              </Button>
              {isGoogleAuth && (
                <>
                  <Spacer horizontalFactor={4} factor={0} />
                  <Button
                    intent="secondary"
                    loading={stepSaving}
                    onClick={skipFbConnection}
                    data-testid="first-session__activate-bot-step__skip-page-button"
                  >
                    {t('pages.FirstSession.ActivateBotBlock.skip')}
                  </Button>
                </>
              )}
              <Spacer horizontalFactor={4} factor={0} />
              <Button
                intent="primary"
                loading={stepSaving}
                onClick={doneHandler}
                data-testid="first-session__activate-bot-step__connect-page-button"
              >
                {t(
                  platform === Platform.facebook
                    ? 'pages.FirstSession.ActivateBotBlock.button'
                    : 'pages.FirstSession.ActivateBotBlock.buttonInstagram',
                )}
              </Button>
            </Flex>
          </>
        )}
      </Flex>
    </HighlightBorderBoxHideable>
  );
};

import React from 'react';
import cn from 'classnames';
import { Input } from '@ui/Input';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Button, ButtonUnstyled } from '@ui/Button';
import { DateFormat } from '@utils/DateTime';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { KeyboardEventUtils } from '@utils/Event/KeyboardEventUtils';
import * as css from './EnterVerificationCodeView.css';
import { insertText } from '@utils/documentExecCommand';

const CodeProblem: React.FC = ({ children }) => (
  <Flex className={css.codeProblem}>
    <Type color="accent3Text">{children}</Type>
  </Flex>
);

const CODE_LENGTH = 6;
const NOT_DIGIT_SYMBOLS_REGEXP = /[^0-9]+/g;

export interface EnterVerificationCodeViewProps {
  error: boolean;
  code: string;
  setCode: (code: string) => void;
  disabled: boolean;
  inputErrorMessage: string | null;
  canRetry: boolean;
  timeLeft: Date;
  onResendClick: VoidFunction;
  onSubmit: VoidFunction;
  submitLoading: boolean;
}

export const EnterVerificationCodeView: React.FC<EnterVerificationCodeViewProps> =
  ({
    error,
    code,
    disabled,
    setCode,
    inputErrorMessage,
    canRetry,
    timeLeft,
    onResendClick,
    onSubmit,
    submitLoading,
  }) => {
    const { t } = useSafeTranslation();
    const { isSmallScreenSize } = useDeviceMedia();
    return (
      <>
        <Type>
          {t('pages.BotPage.HomeTab.Whatsapp.EnterVerificationCode.text')}
        </Type>

        <Spacer factor={4} />

        {error && (
          <>
            <CodeProblem>
              {t(
                'pages.BotPage.HomeTab.Whatsapp.EnterVerificationCode.codeError',
              )}
            </CodeProblem>

            <Spacer factor={4} />
          </>
        )}

        <Input
          className={css.input}
          type="text"
          maxLength={CODE_LENGTH + 1}
          onPaste={(event) => {
            event.preventDefault();
            const { clipboardData, currentTarget } = event;
            const text = (clipboardData?.getData('text/plain') || '')
              .replace(NOT_DIGIT_SYMBOLS_REGEXP, '')
              .substr(0, CODE_LENGTH);
            insertText(text, currentTarget);
          }}
          onKeyDown={(event) => {
            if (
              KeyboardEventUtils.isNonDigit(event) &&
              !KeyboardEventUtils.isEditingInput(event) &&
              !KeyboardEventUtils.isCtrlShortcut(event) &&
              event.key !== '-'
            ) {
              event.preventDefault();
            }
          }}
          onChange={({ currentTarget: { value } }) => {
            const n = parseInt(value.replace(NOT_DIGIT_SYMBOLS_REGEXP, ''), 10);
            if (
              value === '' ||
              (!Number.isNaN(n) && n < 10 ** CODE_LENGTH && n >= 0)
            ) {
              setCode(n.toString());
            }
          }}
          placeholder={t(
            'pages.BotPage.HomeTab.Whatsapp.EnterVerificationCode.placeholder',
          )}
          error={Boolean(inputErrorMessage)}
          renderErrorText={() => (
            <Type size="12px" color="red" weight="medium">
              {inputErrorMessage}
            </Type>
          )}
        />

        <Spacer factor={inputErrorMessage ? 4 : 10} />

        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexDirection={isSmallScreenSize ? 'column-reverse' : 'row'}
        >
          {canRetry ? (
            <Flex
              alignItems="center"
              className={cn(css.retryBox, css.resendButton)}
            >
              <Type color="cobaltSemilight" weight="medium">
                {t(
                  'pages.BotPage.HomeTab.Whatsapp.EnterVerificationCode.retryIn',
                  { time: DateFormat.mmss(timeLeft) },
                )}
              </Type>
            </Flex>
          ) : (
            <ButtonUnstyled
              className={css.resendButton}
              onClick={onResendClick}
              data-testid="whatsapp__resend-code"
            >
              <Type color="blue" weight="medium">
                {t(
                  'pages.BotPage.HomeTab.Whatsapp.EnterVerificationCode.resend',
                )}
              </Type>
            </ButtonUnstyled>
          )}

          {isSmallScreenSize && <Spacer factor={3} />}

          <Button
            intent="primary"
            loading={submitLoading}
            disabled={disabled || code.length !== CODE_LENGTH}
            onClick={onSubmit}
            fullWidth={isSmallScreenSize}
            data-testid="whatsapp__verify-code"
          >
            {t('pages.BotPage.HomeTab.Whatsapp.EnterVerificationCode.verify')}
          </Button>
        </Flex>
      </>
    );
  };

import React from 'react';
import { TextEllipsis } from '@ui/TextEllipsis';
import { Type } from '@ui/Type';
import { IconButton } from '@ui/IconButton';
import { sendEvent } from '@utils/Analytics';
import * as css from './BusinessAccountItem.css';

interface BusinessAccountItemProps {
  id: string;
  title: string;
  hasPhones: boolean;
  onAccountMissClick(): void;
  onAddPhone(whatsappBusinessAccountId: string): void;
}

export const BusinessAccountItem: React.FC<BusinessAccountItemProps> = ({
  id,
  title,
  hasPhones,
  onAccountMissClick,
  onAddPhone,
}) => {
  return (
    <div className={css.groupTitle} data-testid="whatsapp-phone__waba-item">
      <TextEllipsis
        title={title}
        aria-hidden="true"
        onClick={hasPhones ? undefined : onAccountMissClick}
        data-testid="whatsapp-phone-select__waba"
      >
        <Type weight="bold">{title}</Type>
      </TextEllipsis>
      <IconButton
        className={css.addPhone}
        icon="phone"
        color="blue"
        size="24px"
        onClick={() => {
          sendEvent({
            category: 'whatsapp',
            action: 'add phone',
            propertyBag: {
              whatsappBusinessAccountId: id,
            },
          });
          onAddPhone(id);
        }}
        data-testid="whatsapp-phone-select__add-phone"
      />
    </div>
  );
};

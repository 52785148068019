import gql from 'graphql-tag';

export const ATTRIBUTE_EXAMPLES_FRAGMENT = gql`
  fragment attributeExamplesFragment on MessagesTemplateAttributeExample {
    name
    value
  }
`;

export const MESSAGES_TEMPLATE_ATTACHMENT_FRAGMENT = gql`
  fragment messagesTemplateAttachmentFragment on ManagedWhatsappAttachment {
    id
    type
    url
    filename
  }
`;

export const MESSAGES_TEMPLATE_HEADER_FRAGMENT = gql`
  fragment messagesTemplateHeaderFragment on MessagesTemplateHeader {
    text
    type
    attachment {
      ...messagesTemplateAttachmentFragment
    }
    attribute_examples {
      ...attributeExamplesFragment
    }
  }

  ${MESSAGES_TEMPLATE_ATTACHMENT_FRAGMENT}
  ${ATTRIBUTE_EXAMPLES_FRAGMENT}
`;

export const MESSAGES_TEMPLATE_BODY_FRAGMENT = gql`
  fragment messagesTemplateBodyFragment on MessagesTemplateBody {
    text
    attribute_examples {
      ...attributeExamplesFragment
    }
  }

  ${ATTRIBUTE_EXAMPLES_FRAGMENT}
`;

export const MESSAGES_TEMPLATE_FOOTER_FRAGMENT = gql`
  fragment messagesTemplateFooterFragment on MessagesTemplateFooter {
    text
  }

  ${ATTRIBUTE_EXAMPLES_FRAGMENT}
`;

export const MESSAGES_TEMPLATE_BUTTON_FRAGMENT = gql`
  fragment messagesTemplateButtonFragment on MessagesTemplateButton {
    text
    type
    block_id
    counter_id
    phone_number
    url
    attribute_examples {
      ...attributeExamplesFragment
    }
  }

  ${ATTRIBUTE_EXAMPLES_FRAGMENT}
`;

export const MESSAGES_TEMPLATE_CONTENT_FRAGMENT = gql`
  fragment messagesTemplateContentFragment on MessagesTemplateContent {
    header {
      ...messagesTemplateHeaderFragment
    }
    body {
      ...messagesTemplateBodyFragment
    }
    footer {
      ...messagesTemplateFooterFragment
    }
    buttons {
      ...messagesTemplateButtonFragment
    }
  }

  ${MESSAGES_TEMPLATE_HEADER_FRAGMENT}
  ${MESSAGES_TEMPLATE_BODY_FRAGMENT}
  ${MESSAGES_TEMPLATE_FOOTER_FRAGMENT}
  ${MESSAGES_TEMPLATE_BUTTON_FRAGMENT}
`;

export const MESSAGES_TEMPLATE_FRAGMENT = gql`
  fragment messagesTemplateFragment on MessagesTemplate {
    id
    bot_id
    name
    category
    language
    content {
      ...messagesTemplateContentFragment
    }
    template_id
    status
    errors {
      field
      error
    }
    last_edited
    last_published
    quality_score {
      score
    }
  }

  ${MESSAGES_TEMPLATE_CONTENT_FRAGMENT}
`;

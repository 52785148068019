import React, { useMemo } from 'react';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import { Input } from '@ui/Input';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { InputLikeElement, insertText } from '@utils/documentExecCommand';
import { useExtendedFormAction } from '../../../../form/ExtendFormActionsContext';

export const MAX_FOOTER_LENGTH = 60;

export const MessagesTemplateFooter = () => {
  const { t } = useSafeTranslation();
  const {
    values,
    setFooterText,
    saveForm,
    formDisabled,
    hasOptOutButton,
    templateLanguage,
  } = useExtendedFormAction();

  const value =
    (hasOptOutButton
      ? templateLanguage?.opt_out_footer_text
      : values.content.footer.text) || '';

  const currentTextLimit = useMemo(
    () => MAX_FOOTER_LENGTH - value.trim().length,
    [value],
  );

  return (
    <TextOutsideControls
      onInsertRequest={(text, el) => {
        insertText(text, el as InputLikeElement);
      }}
      currentTextLimit={currentTextLimit}
      shouldShowOutsideControls={{ emoji: true, symbolsLimit: true }}
      initialShow={false}
      boxStyle={{ width: '100%' }}
      tight
    >
      {({ ref, getInputProps }) => (
        <Input
          placeholder={
            templateLanguage &&
            t('pages.MessagesTemplates.message.footer.placeholder', {
              lang: templateLanguage?.display_name,
            })
          }
          disabled={formDisabled}
          value={value}
          onChange={(e) => setFooterText(e.target.value)}
          maxLength={MAX_FOOTER_LENGTH}
          {...getInputProps({ onBlur: () => saveForm() } as any)}
          ref={ref}
          data-testid="messages-template-message__footer-text-input"
        />
      )}
    </TextOutsideControls>
  );
};

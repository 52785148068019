import { Modal } from '@services/index';
import { once } from 'ramda';
import React from 'react';
import { DoneModal } from './DoneModal';

export const showDoneModal = () => {
  Modal.show(
    ({ close }) => (
      <DoneModal
        onCloseRequest={() => {
          close(true);
        }}
      />
    ),
    {
      id: 'ActivateBotDoneDialog',
      mobileAdaptive: true,
      preventDefaultClose: true,
    },
  );
};

export const showDoneModalOnce = once(showDoneModal);

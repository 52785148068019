import React from 'react';
import { Flex } from '../../../../modern-ui/Flex';
import { ButtonUnstyled } from '../../../../modern-ui/Button';
import { Icon } from '../../../../modern-ui/Icon';
import { Color } from '../../../../modern-ui/_common/colors';

import * as css from './BotsListBanner.css';

interface BotsListBannerProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  onRequestClose: () => void;
  title: React.ReactNode;
  color: Color;
  subtitle?: React.ReactNode;
  icon?: React.ReactNode;
  actionBlock?: React.ReactNode;
}

export const BotsListBanner: React.FC<BotsListBannerProps> = ({
  color,
  title,
  subtitle,
  icon,
  actionBlock,
  onRequestClose,
  ...props
}) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      className={css.BotsListBanner}
      style={{ backgroundColor: color }}
      {...props}
    >
      <Flex>
        {icon && <div className={css.icon}>{icon}</div>}

        <div>
          <div className={css.title}>{title}</div>
          {subtitle && <div className={css.text}>{subtitle}</div>}
        </div>
      </Flex>
      {actionBlock}
      <ButtonUnstyled className={css.closeButton} onClick={onRequestClose}>
        <Icon icon="close" />
      </ButtonUnstyled>
    </Flex>
  );
};

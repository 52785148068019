import React from 'react';
import { MoreMenu, MoreMenuItem } from '@ui/MoreMenu';
import { useSafeTranslation } from '@utils/useSafeTranslation';

export enum MenuId {
  delete = 'delete',
  copy = 'copy',
}

export interface FooterMenuProps {
  onDeleteButtonClick: () => void;
  onCopyButtonClick: () => void;
}

export const FooterMenu: React.FC<FooterMenuProps> = ({
  onDeleteButtonClick,
  onCopyButtonClick,
}) => {
  const { t } = useSafeTranslation();

  const items: MoreMenuItem[] = [
    {
      id: MenuId.delete,
      title: t('pages.BotPage.ReengageWhatsappTab.panel.buttons.delete'),
      onClick: onDeleteButtonClick,
      'data-testid': 'reengage-panel__delete-button',
    },
    {
      id: MenuId.copy,
      title: t('pages.BotPage.ReengageWhatsappTab.panel.buttons.copy'),
      onClick: onCopyButtonClick,
      'data-testid': 'reengage-panel__copy-button',
    },
  ];

  return <MoreMenu items={items} data-testid="reengage-editor__menu-button" />;
};

import React, { useCallback, useContext } from 'react';
import { lensPath, set } from 'ramda';
import { FormikProps } from 'formik';
import { onDropParams } from '@utils/Dnd';
import { swapImmutable } from '@components/FlowBuilder/views/utils';
import {
  ButtonSectionType,
  ButtonTreeType,
  MessagesTemplateLanguage,
  MessagesTemplateRawType,
  MessagesTemplateFormType,
  TemplateButton,
} from './types';
import {
  ManagedWhatsappAttachmentInput,
  MessagesTemplateAttributeExampleInput,
  MessagesTemplateBodyInput,
  MessagesTemplateCategory,
  MessagesTemplateHeaderInput,
  MessagesTemplateHeaderType,
  TemplateButtonType,
  WhatsappTemplateStatus,
} from '@globals';
import { DEFAULT_TEMPLATE_CONTENT } from '../../../consts';
import { updateAttributeExamples } from './utils';
import { useUpdateMessageTemplate } from '@pages/BotPage/MessagesTemplatesTab/data/useUpdateMessageTemplate';
import { DEFAULT_CUSTOM_BUTTON } from './consts';
import { createTemplateButton } from '../utils';
import { showSomethingWentWrongToaster } from '@services/MessageService';

type Values = MessagesTemplateFormType;

export interface ExtendedFormActionContextProps extends FormikProps<Values> {
  setLanguage: (language: string) => Values;
  setCategory: (category: MessagesTemplateCategory) => Values;
  setAttachment: (attachment: ManagedWhatsappAttachmentInput) => Values;
  setHeaderText: (text: string) => Values;
  updateHeaderAttributeExamples: (attributeNames: string[]) => Values;
  setHeaderAttributeExamples: (
    example: MessagesTemplateAttributeExampleInput,
    index: number,
  ) => Values;
  clearHeader: () => Values;
  setBodyText: (text: string) => Values;
  updateBodyAttributeExamples: (attributeNames: string[]) => Values;
  setBodyAttributeExamples: (
    example: MessagesTemplateAttributeExampleInput,
    index: number,
  ) => Values;
  setName: (name: string) => Values;
  isGeneralSettingsValid: () => boolean;
  setFooterText: (text: string) => Values;
  mapSection: (
    section: ButtonSectionType,
    f: (x: TemplateButton[]) => TemplateButton[],
  ) => Values;
  removeFromSectionAt: (section: ButtonSectionType, index: number) => Values;
  updateSectionAt: (
    section: ButtonSectionType,
    button: TemplateButton,
    index: number,
  ) => Values;
  onNodeDrag: (params: onDropParams) => Values;
  onButtonsDrag: (params: onDropParams) => Values;
  onBeforePublish: () => void;
  contentPartDisabled: boolean;
  templateLanguage: MessagesTemplateLanguage | undefined;
  hasOptOutButton: boolean;
  formDisabled: boolean;
  saveForm: (values?: Values) => void;
}

export const ExtendFormActionContext =
  React.createContext<ExtendedFormActionContextProps>(
    {} as ExtendedFormActionContextProps,
  );

export const useExtendedFormAction = () =>
  useContext<ExtendedFormActionContextProps>(ExtendFormActionContext);

export enum MessagesTemplateFields {
  category = 'category',
  name = 'name',
  language = 'language',
}

const headerLens = lensPath(['content', 'header']);
const footerTextLens = lensPath(['content', 'footer', 'text']);
const headerExamplesLens = lensPath([
  'content',
  'header',
  'attribute_examples',
]);

const bodyLens = lensPath(['content', 'body']);
const bodyExamplesLens = lensPath(['content', 'body', 'attribute_examples']);
const sectionsLens = lensPath(['content', 'sections']);

export interface ExtendFormActionProps extends FormikProps<Values> {
  template: MessagesTemplateRawType;
  languages: MessagesTemplateLanguage[];
}

export const ExtendFormActions: React.FC<ExtendFormActionProps> = ({
  children,
  template,
  languages,
  ...formikProps
}) => {
  const [onUpdateMessageTemplate] = useUpdateMessageTemplate(template.id);
  const { values, errors, setValues } = formikProps;
  const templateLangauge = languages.find(({ id }) => id === values.language);
  const contentPartDisabled = !values.language;
  const optOutButton = values.content.sections
    .flatMap(({ children }) => children)
    .find(({ type }) => type === TemplateButtonType.marketing_opt_out);
  const formDisabled = template.status !== WhatsappTemplateStatus.DRAFT;

  const saveForm = useCallback(
    (manualValues?: Values) =>
      onUpdateMessageTemplate(manualValues || values).catch(() =>
        showSomethingWentWrongToaster(false),
      ),
    [onUpdateMessageTemplate, values],
  );

  const setCategory = useCallback(
    (category: MessagesTemplateCategory) => {
      let newValues = values;

      if (category === MessagesTemplateCategory.utility) {
        newValues = {
          ...values,
          category,
          content: {
            ...values.content,
            sections: values.content.sections.map((section) =>
              section.type === ButtonSectionType.quickReply
                ? {
                    ...section,
                    children: section.children.map((button) =>
                      button.type === TemplateButtonType.marketing_opt_out
                        ? createTemplateButton(DEFAULT_CUSTOM_BUTTON)
                        : button,
                    ),
                  }
                : section,
            ),
          },
        };
      } else {
        newValues = { ...values, category };
      }

      setValues(newValues);
      return newValues;
    },
    [setValues, values],
  );

  const setName = useCallback(
    (name: string) => {
      const newValues = { ...values, name };
      setValues(newValues);
      return newValues;
    },
    [setValues, values],
  );

  const clearHeader = useCallback(() => {
    const newValues = set(headerLens, DEFAULT_TEMPLATE_CONTENT.header, values);
    setValues(newValues);
    return newValues;
  }, [setValues, values]);

  const setAttachment = useCallback(
    (attachment: ManagedWhatsappAttachmentInput) => {
      const newHeader: MessagesTemplateHeaderInput = {
        attribute_examples: [],
        text: '',
        type: MessagesTemplateHeaderType.attachment,
        attachment,
      };

      const newValues = set(headerLens, newHeader, values);
      setValues(newValues);
      return newValues;
    },
    [setValues, values],
  );

  const setHeaderText = useCallback(
    (text: string) => {
      const newHeader: MessagesTemplateHeaderInput = {
        ...values.content.header,
        type: MessagesTemplateHeaderType.text,
        text,
      };

      const newValues = set(headerLens, newHeader, values);
      setValues(newValues);
      return newValues;
    },
    [setValues, values],
  );

  const setBodyText = useCallback(
    (text: string) => {
      const newBody: MessagesTemplateBodyInput = {
        ...values.content.body,
        text,
      };

      const newValues = set(bodyLens, newBody, values);
      setValues(newValues);
      return newValues;
    },
    [setValues, values],
  );

  const setHeaderAttributeExamples = useCallback(
    (example: MessagesTemplateAttributeExampleInput, index: number) => {
      const newValues = set(
        lensPath(['content', 'header', 'attribute_examples', index]),
        example,
        values,
      );

      setValues(newValues);
      return newValues;
    },
    [setValues, values],
  );

  const setBodyAttributeExamples = useCallback(
    (example: MessagesTemplateAttributeExampleInput, index: number) => {
      const newValues = set(
        lensPath(['content', 'body', 'attribute_examples', index]),
        example,
        values,
      );

      setValues(newValues);
      return newValues;
    },
    [setValues, values],
  );

  const updateHeaderAttributeExamples = useCallback(
    (attributeNames: string[]) => {
      const headerExamples = values.content.header.attribute_examples || [];

      const newExamples = updateAttributeExamples(
        headerExamples,
        attributeNames,
      );
      const newValues = set(headerExamplesLens, newExamples, values);

      setValues(newValues);
      return newValues;
    },
    [setValues, values],
  );

  const updateBodyAttributeExamples = useCallback(
    (attributeNames: string[]) => {
      const bodyExamples = values.content.body.attribute_examples || [];

      const newExamples = updateAttributeExamples(bodyExamples, attributeNames);
      const newValues = set(bodyExamplesLens, newExamples, values);

      setValues(newValues);
      return newValues;
    },
    [setValues, values],
  );

  const isGeneralSettingsValid = () => {
    const categoryValid = values.category && !errors.category;
    const nameValid = values.name && !errors.name;
    const languageValid = values.language && !errors.language;

    return Boolean(categoryValid && nameValid && languageValid);
  };

  const setFooterText = (text: string) => {
    const newValues = set(footerTextLens, text, values);
    setValues(newValues);
    return newValues;
  };

  const mapSection = useCallback(
    (
      section: ButtonSectionType,
      f: (x: TemplateButton[]) => TemplateButton[],
    ) => {
      const newButtons: Array<ButtonTreeType> = values.content.sections.map(
        (node) =>
          node.type === section
            ? { ...node, children: f(node.children) }
            : node,
      );

      const newValues = set(sectionsLens, newButtons, values);
      setValues(newValues);
      return newValues;
    },
    [setValues, values],
  );

  const removeFromSectionAt = useCallback(
    (section: ButtonSectionType, index: number) => {
      const newButtons: Array<ButtonTreeType> = values.content.sections.map(
        (node) =>
          node.type === section
            ? { ...node, children: node.children.filter((_, i) => i !== index) }
            : node,
      );

      const newValues = set(sectionsLens, newButtons, values);
      setValues(newValues);
      return newValues;
    },
    [setValues, values],
  );

  const updateSectionAt = useCallback(
    (section: ButtonSectionType, button: TemplateButton, index: number) => {
      const newButtons: Array<ButtonTreeType> = values.content.sections.map(
        (node) =>
          node.type === section
            ? {
                ...node,
                children: node.children.map((leaf, i) =>
                  index === i ? button : leaf,
                ),
              }
            : node,
      );

      const newValues = set(sectionsLens, newButtons, values);

      setValues(newValues);
      return newValues;
    },
    [setValues, values],
  );

  const setLanguage = (language: string) => {
    const templateLangauge = languages.find(({ id }) => id === language);
    const newValues = {
      ...values,
      content: {
        ...values.content,
        sections: values.content.sections.map((section) =>
          section.type === ButtonSectionType.quickReply
            ? {
                ...section,
                children: section.children.map((button) =>
                  button.type === TemplateButtonType.marketing_opt_out
                    ? {
                        ...button,
                        text: templateLangauge!.opt_out_button_text,
                      }
                    : button,
                ),
              }
            : section,
        ),
      },
      language,
    };

    setValues(newValues);
    return newValues;
  };

  const onBeforePublish = () => {
    if (!optOutButton) {
      return;
    }

    setFooterText(templateLangauge!.opt_out_footer_text);
  };

  const onNodeDrag = useCallback(
    ({ draggingId, position }: onDropParams) => {
      const index = values.content.sections.findIndex(
        ({ type }) => draggingId === type,
      );

      if (index !== position) {
        const newSections = swapImmutable(
          values.content.sections,
          index,
          position,
        );
        const newValues = set(sectionsLens, newSections, values);
        setValues(newValues);
        return newValues;
      }

      return values;
    },
    [setValues, values],
  );

  const onButtonsDrag = useCallback(
    ({ draggingId, position }: onDropParams) => {
      const section = values.content.sections.find((node) =>
        node.children.find(({ id }) => id === draggingId),
      )!;

      const newButtons: Array<ButtonTreeType> = values.content.sections.map(
        (node) => {
          if (node.type === section.type) {
            const index = node.children.findIndex(
              ({ id }) => draggingId === id,
            );

            if (index !== position) {
              return {
                ...node,
                children: swapImmutable(node.children, index, position),
              };
            }
          }

          return node;
        },
      );

      const newValues = set(sectionsLens, newButtons, values);
      setValues(newValues);
      return newValues;
    },
    [setValues, values],
  );

  return (
    <ExtendFormActionContext.Provider
      value={{
        ...formikProps,
        setLanguage,
        setCategory,
        setAttachment,
        setHeaderText,
        setHeaderAttributeExamples,
        updateHeaderAttributeExamples,
        clearHeader,
        setBodyText,
        setBodyAttributeExamples,
        updateBodyAttributeExamples,
        setName,
        isGeneralSettingsValid,
        setFooterText,
        mapSection,
        removeFromSectionAt,
        updateSectionAt,
        onNodeDrag,
        onButtonsDrag,
        onBeforePublish,
        contentPartDisabled,
        templateLanguage: templateLangauge,
        hasOptOutButton: Boolean(optOutButton),
        formDisabled,
        saveForm,
      }}
    >
      {children}
    </ExtendFormActionContext.Provider>
  );
};

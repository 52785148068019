import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { useCurrentBotId, useFlowsTabLink } from '@utils/Routing';
import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';
import { ApolloError } from 'apollo-client';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCloneFlowGroup } from '../../FlowGroup/FlowGroupMutations';
import { useFlowClone } from '../../FlowItem/FlowsMutations';
import { sendTemplateDetailsEvent } from '../events';
import { Template, TemplateLevel } from '../types';

interface UseCloneTemplateParams {
  groupId: string;
  onCreated: () => void;
  onError: (error: ApolloError) => void;
}

export interface UseCloneTemplate {
  loading: boolean;
  error: ApolloError | undefined;
  cloneTemplate: (template: Template) => void;
}

export const useCloneTemplate = ({
  groupId,
  onCreated,
  onError,
}: UseCloneTemplateParams): UseCloneTemplate => {
  const botId = useCurrentBotId();
  const { isConnected } = usePageConnected();
  const getFlowTabLink = useFlowsTabLink();
  const history = useHistory();
  const [template, setTemplate] = useState<Template | null>(null);
  const [isBasicTemplateAdded, setIsBasicTemplateAdded] = useServerStorage(
    ServerStorageItemKeys.isBasicTemplateAdded,
  );
  const [isAdvancedTemplateAdded, setIsAdvancedTemplateAdded] =
    useServerStorage(ServerStorageItemKeys.isAdvancedTemplateAdded);

  const setTemplateAddedFlag = () => {
    if (
      template!.level === TemplateLevel.advanced &&
      !isAdvancedTemplateAdded
    ) {
      setIsAdvancedTemplateAdded(true);
    } else if (
      template!.level === TemplateLevel.basic &&
      !isBasicTemplateAdded
    ) {
      setIsBasicTemplateAdded(true);
    }
  };

  const {
    cloneFlow,
    loading: flowCloneLoading,
    error: flowCloneError,
  } = useFlowClone({
    onError,
    onCompleted: (createdFlow) => {
      setTemplateAddedFlag();
      onCreated();

      const queryParams = new URLSearchParams(window.location.search);
      const blockId = createdFlow?.cloneFlow.id;
      const path = `${getFlowTabLink(blockId)}?${queryParams}`;
      history.push(path);
    },
  });

  const {
    cloneFlowGroup,
    loading: cloneFlowGroupLoading,
    error: cloneFlowGroupError,
  } = useCloneFlowGroup({
    botId,
    onError,
    onCompleted: (createdFlowGroup) => {
      setTemplateAddedFlag();
      onCreated();

      const queryParams = new URLSearchParams(window.location.search);
      const blockId = createdFlowGroup.cloneFlowGroup.flow_ids?.[0]!;
      const path = `${getFlowTabLink(blockId)}?${queryParams}`;
      history.push(path);
    },
  });

  const cloneTemplate = useCallback(
    (template: Template) => {
      setTemplate(template);
      sendTemplateDetailsEvent('use this template', { isConnected, template });

      if (template.flowGroupId) {
        cloneFlowGroup(botId!, template.flowGroupId);
      } else {
        cloneFlow(groupId, template.flowId);
      }
    },
    [botId, groupId, isConnected, cloneFlow, cloneFlowGroup, setTemplate],
  );

  return {
    loading: flowCloneLoading || cloneFlowGroupLoading,
    error: flowCloneError || cloneFlowGroupError,
    cloneTemplate,
  };
};

import React from 'react';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { Spacer } from '@ui/Spacer';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { WhatsappSelectProps } from './types';
import { DesktopWhatsappPhoneSelect } from './DesktopWhatsappPhoneSelect/DesktopWhatsappPhoneSelect';
import { MobileWhatsappPhoneSelect } from './MobileWhatsappPhoneSelect/MobileWhatsappPhoneSelect';

export const WhatsappPhoneSelect: React.FC<WhatsappSelectProps> = (props) => {
  const { loading, whatsappPhoneEntries } = props;

  const { isSmallScreenSize } = useDeviceMedia();

  if (loading) {
    return (
      <>
        <LoadingPlaceholder style={{ height: 32, width: 200 }} />
        <Spacer factor={1} />
        <LoadingPlaceholder style={{ height: 16, width: 200 }} />
      </>
    );
  }

  const hasNoPhones = whatsappPhoneEntries?.every(
    ({ phones }) => phones?.length === 0,
  );

  if (isSmallScreenSize) {
    return <MobileWhatsappPhoneSelect hasNoPhones={hasNoPhones} {...props} />;
  }

  return <DesktopWhatsappPhoneSelect hasNoPhones={hasNoPhones} {...props} />;
};

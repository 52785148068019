import React, { useCallback } from 'react';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ShopifySubscriptionStatus, TriggeredMessageType } from '@globals';
import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { CenteredLoader } from '@ui/Loader';
import { Divider } from '@ui/Menu';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { useShopifyAccount } from '@utils/Integrations/Shopify/useShopifyAccount';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import { sendEvent } from '@utils/Analytics';
import { ConnectedShopifyAccountView } from '../../../../components/settings/shopify/ConnectedShopifyAccountView';
import {
  OrderStatusUpdateStatusControl,
  OrderStatusUpdateStatusControlProps,
} from './OrderStatusUpdateStatusControl';
import * as css from './ShopifyControl.css';
import { ShopifyPaymentView } from '@components/ShopifyPaymentView/ShopifyPaymentView';

export interface ShopifyControlProps
  extends OrderStatusUpdateStatusControlProps {
  triggerValue: TriggeredMessageType;
}

export const ShopifyControl: React.FC<ShopifyControlProps> = ({
  triggerValue,
  children,
  ...props
}) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  const { account, loading } = useShopifyAccount();

  const onConnectClick = useCallback(() => {
    sendEvent({
      category: 'whatsapp reengage page',
      action: 'connect shopify click',
    });
    window.open('https://apps.shopify.com/chatfuel', '_blank');
  }, []);

  return (
    <>
      {triggerValue === TriggeredMessageType.order_status_update && (
        <>
          <OrderStatusUpdateStatusControl {...props} />

          <Spacer factor={3} />
        </>
      )}

      {children}

      <Divider />
      <Spacer factor={3} />

      {loading ? (
        <CenteredLoader />
      ) : account ? (
        <>
          <ConnectedShopifyAccountView showIcon account={account}>
            <Trans i18nKey="Shopify-connect.disconnectNote" t={t}>
              <Type size="12px">
                Go&nbsp;
                <NavLink
                  className={css.here}
                  to={getTabLink(BotTabs.configure, botId)}
                >
                  here
                </NavLink>
                &nbsp;to disconnect
              </Type>
            </Trans>
          </ConnectedShopifyAccountView>

          {account.subscription?.status !==
            ShopifySubscriptionStatus.active && (
            <>
              <Spacer factor={3} />
              <Divider />
              <Spacer factor={3} />

              <ShopifyPaymentView forceMobile />
            </>
          )}
        </>
      ) : (
        <Button
          data-testid="reengage__connect-shopify"
          onClick={onConnectClick}
        >
          <Flex alignItems="center" justifyContent="center">
            <Icon icon="shopifyBag" color="white" className={css.shopifyIcon} />
            <Type size="15px" color="white" weight="medium">
              {t('Shopify-connect.connect')}
            </Type>
          </Flex>
        </Button>
      )}
    </>
  );
};

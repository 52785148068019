import { useSafeTranslation } from '@utils/useSafeTranslation';
import React from 'react';
import { Price } from './types';
import { DescriptionItemView, TitleProps } from './Primitives';

export interface PausePlanProps extends Price, TitleProps {}

export const PausePlan: React.FC<PausePlanProps> = ({ price, ...props }) => {
  const { t } = useSafeTranslation();

  return (
    <DescriptionItemView
      title={t('pages.Billing.Table.pausingPlan')}
      subscription={price}
      {...props}
    />
  );
};

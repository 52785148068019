import React, { useCallback, useEffect, useState } from 'react';
import { useResetMetaForMobile } from '@utils/css/useResetMetaForMobile';
import { Flex } from '@ui/Flex';
import { HeaderBox } from './components/HeaderBox';
import { OriginalAccountType, Platform } from '@globals';
import {
  BotTabs,
  getDefaultTab,
  getTabLink,
  useCurrentBotId,
  useFirstSessionTabParams,
} from '@utils/Routing';
import { useHistory, useLocation } from 'react-router-dom';
import { ActivateBotBlock } from './components/ActivateBotBlock';
import { Spacer } from '@ui/Spacer';
import { CenteredLoader } from '@ui/Loader';
import { removeGlobalLoader } from 'cf-common/src/utils/removeGlobalLoader';
import { useFirstSessionStep } from './hooks/useFirstSessionStep';
import { FirstSessionStep } from './consts';
import { LSKey, useLocalStorageForId } from '@utils/LocalStorage';
import { usePrevious } from 'cf-common/src/utils/hooks';
import { IntroQuestionsBlock } from './components/IntroQuestionsBlock';
import { sendEvent } from '@utils/Analytics';
import { PlansPaywallStep } from './components/PlansPaywallStep';
import { isPaywallActive, useBotPaywallStatus } from '@utils/Data/Paywall';
import { usePageConnectedByRedirectListener } from '@pages/BotPage/events';
import { showDoneModalOnce } from './components/ActivateBotBlock/components/DoneModal';
import { ProductTour } from './components/ProductTour/ProductTour';
import { useCheckPageConnected } from './useCheckPageConnected';
import * as css from './FirstSessionPage.css';
import { useChangeBotAllowedPlatformsMutation } from '@utils/Data/Bot/useChangeBotAllowedPlatformsMutation';
import { useShopifyIntroQuestionsOverrides } from '@pages/ShopifyChooseBot/useShopifyIntroQuestionsOverrides';
import { useAdminData, useAdminFeatures } from '@utils/Data/Admin';
import { getCookie } from 'cf-analytics/src/analytics/utils';

const SKIP_IQ_BY_ADS_COOKIE = 'skip-iq-by-ads';

const stepsOrder = {
  [FirstSessionStep.introQuestions]: 0,
  [FirstSessionStep.waInitialSetup]: 1,
  [FirstSessionStep.waDiscounts]: 1,
  [FirstSessionStep.activateBot]: 1,
  [FirstSessionStep.productTour]: 2,
  [FirstSessionStep.paywall]: 3,
  [FirstSessionStep.done]: 4,
};

export const FirstSessionPage: React.FC = () => {
  useResetMetaForMobile();
  const history = useHistory();
  const location = useLocation();
  const botId = useCurrentBotId();

  const {
    paywallStatus,
    loading: statusLoading,
    requested: statusRequested,
    error: paywallStatusError,
  } = useBotPaywallStatus();
  const { platform } = useFirstSessionTabParams();
  const [step, setStep, { loading, saving }] = useFirstSessionStep();

  const { adminData } = useAdminData();
  const { adminFeatures } = useAdminFeatures();
  const isGoogleAuth =
    adminData?.me.original_account_type === OriginalAccountType.google;

  const [changeBotAllowedPlatforms, { loading: botAllowedPlatformsLoading }] =
    useChangeBotAllowedPlatformsMutation();

  const [savedPlatform = Platform.facebook, setSavedPlatform] =
    useLocalStorageForId<Platform>(LSKey.firstSessionTabPlatform, botId);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const { handleIntroQuestionsDone: handleShopifyIntroQuestionsDone } =
    useShopifyIntroQuestionsOverrides();

  if (step && step !== FirstSessionStep.done && !inProgress) {
    setInProgress(true);
  }

  useEffect(() => {
    if (!adminFeatures) {
      return;
    }

    if (
      adminFeatures.skip_iq_for_ads_campaign &&
      botId &&
      getCookie(SKIP_IQ_BY_ADS_COOKIE)
    ) {
      setStep(FirstSessionStep.done);
      changeBotAllowedPlatforms(botId, [Platform.whatsapp])
        .then(() =>
          handleShopifyIntroQuestionsDone(() =>
            history.push(getDefaultTab(botId)),
          ),
        )
        .then(() => removeGlobalLoader());
    } else {
      removeGlobalLoader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminFeatures, botId]);

  useEffect(() => {
    if (!adminFeatures) {
      return;
    }

    sendEvent({
      category: 'first-session',
      action: 'view',
      propertyBag: {
        platform,
        willSkip: !!adminFeatures.skip_iq_for_ads_campaign,
        isPaid: !!getCookie(SKIP_IQ_BY_ADS_COOKIE),
      },
    });
  }, [platform, adminFeatures]);

  const prevPlatform = usePrevious(platform);

  useEffect(() => {
    if (platform && platform !== prevPlatform) {
      setSavedPlatform(platform);
    }
  }, [platform, prevPlatform, setSavedPlatform]);

  useEffect(() => {
    if (!platform && savedPlatform) {
      history.push({
        ...location,
        pathname: getTabLink(BotTabs.firstSession, botId, {
          platform: savedPlatform,
        }),
      });
    }
  }, [botId, history, location, platform, savedPlatform]);

  useEffect(() => {
    if (!inProgress && step === FirstSessionStep.done && botId) {
      history.push(getDefaultTab(botId));
    }
  }, [botId, history, inProgress, step]);

  const platformChangeHandler = useCallback(
    (updatedPlatform: Platform) => {
      if (savedPlatform === updatedPlatform) {
        return;
      }

      history.push({
        ...location,
        pathname: getTabLink(BotTabs.firstSession, botId, {
          platform: updatedPlatform,
        }),
      });
    },
    [botId, history, location, savedPlatform],
  );

  const connectPageDoneHandler = useCallback(() => {
    setStep(FirstSessionStep.productTour);
    sendEvent({
      category: 'first-session',
      action: 'done step click',
      propertyBag: {
        step,
      },
    });
  }, [step, setStep]);

  const getStepPrevHandler = (prevStep: FirstSessionStep) => () => {
    setStep(prevStep);
    sendEvent({
      category: 'first-session',
      action: 'prev step click',
      propertyBag: {
        step,
      },
    });
  };

  const handleIntroQuestionsDone = async () => {
    if (platform === Platform.whatsapp && botId) {
      setStep(FirstSessionStep.done);
      await changeBotAllowedPlatforms(botId, [Platform.whatsapp]);
      handleShopifyIntroQuestionsDone(() => history.push(getDefaultTab(botId)));
      return;
    }

    setStep(FirstSessionStep.activateBot);
    sendEvent({
      category: 'first-session',
      action: 'done step click',
      propertyBag: {
        platform,
        step: FirstSessionStep.activateBot,
      },
    });
  };

  const finalHandler = useCallback(async () => {
    await setStep(FirstSessionStep.done);
    sendEvent({
      category: 'first-session',
      action: 'first session finished',
    });
  }, [setStep]);

  const isShowStep = useCallback(
    (targetStep: FirstSessionStep) => step === targetStep,
    [step],
  );

  const isPaywallStep = isShowStep(FirstSessionStep.paywall);

  const handlePageConnected = useCallback(() => {
    if (platform === Platform.facebook && !isPaywallStep) {
      connectPageDoneHandler();
    }
  }, [platform, isPaywallStep, connectPageDoneHandler]);

  usePageConnectedByRedirectListener(handlePageConnected);

  const isPaywallSetupStep = isPaywallStep && isPaywallActive(paywallStatus);
  const isPaywallSetupStepDone =
    isPaywallStep &&
    (isGoogleAuth || (statusRequested && !statusLoading)) &&
    !paywallStatusError &&
    !isPaywallActive(paywallStatus);
  const isPaywallSetupStepLoading = isPaywallStep && statusLoading;

  useEffect(() => {
    if (isPaywallSetupStepDone) {
      finalHandler().then(() => {
        showDoneModalOnce();
      });
    }
  }, [isPaywallSetupStepDone, finalHandler]);

  useCheckPageConnected({ isPaywallStep, setStep, canBeSkipped: isGoogleAuth });

  if (!platform || loading || botAllowedPlatformsLoading) {
    return null;
  }

  return (
    <Flex flexDirection="column" className={css.wrapper}>
      <Flex justifyContent="center" className={css.page}>
        <div className={css.content}>
          {isShowStep(FirstSessionStep.introQuestions) && (
            <>
              <HeaderBox />
              <IntroQuestionsBlock
                enabled={
                  stepsOrder[step] >=
                  stepsOrder[FirstSessionStep.introQuestions]
                }
                done={
                  stepsOrder[step] > stepsOrder[FirstSessionStep.introQuestions]
                }
                current={step === FirstSessionStep.introQuestions}
                stepSaving={saving}
                onDone={handleIntroQuestionsDone}
                platform={platform}
                onPlatformChange={platformChangeHandler}
                isLastStep={platform === Platform.whatsapp}
              />
            </>
          )}
          {isShowStep(FirstSessionStep.activateBot) && (
            <ActivateBotBlock
              platform={platform}
              enabled={
                stepsOrder[step] > stepsOrder[FirstSessionStep.introQuestions]
              }
              done={stepsOrder[step] > stepsOrder[FirstSessionStep.activateBot]}
              current={step === FirstSessionStep.activateBot}
              stepSaving={saving}
              onDone={connectPageDoneHandler}
              onGoToPrevRequest={getStepPrevHandler(
                FirstSessionStep.introQuestions,
              )}
            />
          )}
          {isShowStep(FirstSessionStep.productTour) && <ProductTour />}
          {isPaywallSetupStepLoading && <CenteredLoader />}
          {isPaywallSetupStep && (
            <PlansPaywallStep
              enabled={
                stepsOrder[step] > stepsOrder[FirstSessionStep.activateBot]
              }
              done={stepsOrder[step] > stepsOrder[FirstSessionStep.paywall]}
              current={step === FirstSessionStep.paywall}
              stepSaving={saving}
              status={paywallStatus}
              onDone={finalHandler}
            />
          )}
          <Spacer factor={1} horizontalFactor={1} />
        </div>
      </Flex>
    </Flex>
  );
};

import { usePaymentError, usePaymentInfo } from '@utils/Data/Pricing';
import { PaymentInfoQuery_bot_paymentInformation as PaymentInfo } from '@utils/Data/Pricing/@types/PaymentInfoQuery';
import { WatchQueryFetchPolicy } from 'apollo-client';

interface PaymentErrorPayload {
  isPaymentFailed: boolean;
  amountToPay: number;
}

interface PaymentDataPayload {
  loading: boolean;
  paymentError: PaymentErrorPayload | undefined;
  paymentInfo: PaymentInfo | undefined;
}

export const usePaymentData = (
  fetchPolicy?: WatchQueryFetchPolicy,
): PaymentDataPayload => {
  const { paymentsHistoryLoading, ...paymentError } =
    usePaymentError(fetchPolicy);
  const { paymentInfo, paymentInfoLoading } = usePaymentInfo();

  const value: PaymentDataPayload = {
    loading: paymentsHistoryLoading || paymentInfoLoading,
    paymentInfo,
    paymentError,
  };

  return value;
};

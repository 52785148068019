import React, { useMemo } from 'react';
import { Flex } from '@ui/Flex';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Type } from '@ui/Type';
import { SortMode } from '../../types';
import { MenuCombobox } from '@ui/SimpleCombobox';
import { ButtonUnstyled } from '@ui/Button';
import { sendEvent } from '@utils/Analytics';

interface MessagesTemplatesSortProps {
  setSortMode: (mode: SortMode) => void;
}

export const MessagesTemplatesSort: React.FC<MessagesTemplatesSortProps> = ({
  setSortMode,
}) => {
  const { t } = useSafeTranslation();

  const sortModeItems = useMemo(
    () => [
      {
        id: SortMode.nameAZ,
        title: t('pages.MessagesTemplates.Table.sort.nameAZ'),
      },
      {
        id: SortMode.nameZA,
        title: t('pages.MessagesTemplates.Table.sort.nameZA'),
      },
      {
        id: SortMode.lastUpdated,
        title: t('pages.MessagesTemplates.Table.sort.lastUpdated'),
      },
    ],
    [t],
  );

  return (
    <Flex alignItems="center">
      <Type>{t('pages.MessagesTemplates.Table.sort.sortBy')}&nbsp;</Type>
      <MenuCombobox
        initialSelectedItem={sortModeItems[0]}
        items={sortModeItems}
        data-testid="messages-templates_table_sort-selector"
        onSelect={({ id }) => {
          sendEvent({
            category: 'messages templates',
            label: 'table',
            action: 'sort mode change',
            propertyBag: {
              id,
            },
          });
          setSortMode(id);
        }}
        renderInput={({ popperReference, downshift }) => (
          <ButtonUnstyled
            ref={popperReference.ref}
            {...downshift.getToggleButtonProps()}
          >
            <Type color="accent1Normal">{downshift.selectedItem?.title}</Type>
          </ButtonUnstyled>
        )}
      />
    </Flex>
  );
};

import React from 'react';
import { Flex } from '@ui/Flex';
import { ButtonUnstyled } from '@ui/Button';
import cn from 'classnames';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './PlatformSelector.css';
import { Platform } from '@globals';
import { ReactComponent as FacebookLogo } from './images/facebook.svg';
import { ReactComponent as InstagramLogo } from './images/instagram.svg';
import { ReactComponent as FacebookActiveLogo } from './images/facebook_active.svg';
import { ReactComponent as InstagramActiveLogo } from './images/instagram_active.svg';
import { sendEvent } from '@utils/Analytics';
import { FlexProps } from '@ui/Flex/Flex';
import { KeywordsPlacements } from '../../consts';

interface PlatformSelectorProps extends Omit<FlexProps, 'onChange' | 'ref'> {
  currentPlatform: Platform;
  onChange: (platform: Platform) => void;
  placement: KeywordsPlacements;
  narrow?: boolean;
}

export const PlatformSelector: React.FC<PlatformSelectorProps> = ({
  currentPlatform,
  onChange,
  className,
  narrow,
  placement,
  ...props
}) => {
  const { t } = useSafeTranslation();
  const isFacebookActive = currentPlatform === Platform.facebook;
  const isInstagramActive = currentPlatform === Platform.instagram;

  return (
    <Flex alignItems="center" className={cn(css.box, className)} {...props}>
      <ButtonUnstyled
        className={cn(css.button, css.facebook, {
          [css.active]: isFacebookActive,
          [css.narrow]: narrow,
        })}
        onClick={() => {
          onChange(Platform.facebook);
          sendEvent({
            category: placement,
            label: 'platform selector',
            action: 'facebook click',
          });
        }}
        data-testid={`${placement}__platform-selector__facebook-button`}
      >
        <Flex alignItems="center" justifyContent="center">
          {isFacebookActive ? <FacebookActiveLogo /> : <FacebookLogo />}
          <Spacer horizontalFactor={2} factor={1} />
          <Type size="15px" color={isFacebookActive ? 'white' : 'black'}>
            {t('pages.Keywords.PlatformSelector.facebook')}
          </Type>
        </Flex>
      </ButtonUnstyled>
      <Spacer horizontalFactor={1} factor={1} />
      <ButtonUnstyled
        className={cn(css.button, css.instagram, {
          [css.active]: isInstagramActive,
          [css.narrow]: narrow,
        })}
        onClick={() => {
          onChange(Platform.instagram);
          sendEvent({
            category: placement,
            label: 'platform selector',
            action: 'instagram click',
          });
        }}
        data-testid={`${placement}__platform-selector__instagram-button`}
      >
        <Flex alignItems="center" justifyContent="center">
          {isInstagramActive ? <InstagramActiveLogo /> : <InstagramLogo />}
          <Spacer horizontalFactor={2} factor={1} />
          <Type size="15px" color={isInstagramActive ? 'white' : 'black'}>
            {t('pages.Keywords.PlatformSelector.instagram')}
          </Type>
        </Flex>
      </ButtonUnstyled>
    </Flex>
  );
};

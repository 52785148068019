import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import {
  PremiumBillingInfo,
  PremiumBillingInfoLoading,
  PremiumBillingInfoProps,
} from '../PremiumBillingInfo/PremiumBillingInfo';
import { PlanBlock, PlanBlockProps } from '../PlanBlock/PlanBlock';
import { Invoices, InvoicesLoader, InvoicesProps } from '../Invoices';
import { NextCharge } from './NextChange/NextCharge';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import css from './BillingLayout.css';

interface BillingLayoutProps<InvoiceItem>
  extends Omit<PlanBlockProps, 'loading'>,
    PremiumBillingInfoProps {
  invoices: Pick<
    InvoicesProps<InvoiceItem>,
    | 'errorMessage'
    | 'items'
    | 'columns'
    | 'isError'
    | 'columnClassName'
    | 'headers'
    | 'itemHeight'
  >;
  planBlockLoading: boolean;
  billingInfoLoading: boolean;
  invoicesLoading: boolean;
  nextCharge: InvoiceItem | null;
  nextChargeParts: number;
}

export const BillingLayout = <InvoiceItem,>({
  invoices,
  planBlockLoading,
  billingInfoLoading,
  invoicesLoading,
  nextCharge,
  paymentEntityType,
  nextChargeParts,
  ...props
}: BillingLayoutProps<InvoiceItem>) => {
  const { getValueDueToSize } = useDeviceMedia();

  return (
    <div className={css.wrapper}>
      <Flex flexDirection="column">
        <PlanBlock
          {...props}
          paymentEntityType={paymentEntityType}
          loading={planBlockLoading}
        />
        <Spacer factor={getValueDueToSize(2, 4)} />
        {billingInfoLoading ? (
          <PremiumBillingInfoLoading />
        ) : (
          <PremiumBillingInfo {...props} />
        )}
      </Flex>

      <Flex flexDirection="column">
        <NextCharge<InvoiceItem>
          headers={invoices.headers}
          columns={invoices.columns}
          nextCharge={nextCharge}
          nextChargeParts={nextChargeParts}
          columnClassName={invoices.columnClassName}
          itemHeight={invoices.itemHeight}
        />
        {invoicesLoading ? (
          <InvoicesLoader
            className={cn(
              css.invoices,
              invoices.items.length > 6 ? css.large : css.small,
            )}
            {...invoices}
          />
        ) : (
          <Invoices<InvoiceItem>
            className={cn(
              css.invoices,
              invoices.items.length > 6 ? css.large : css.small,
            )}
            listHeight={285}
            data-testid="payment-history__row"
            {...invoices}
          />
        )}
      </Flex>
    </div>
  );
};

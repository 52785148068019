import gql from 'graphql-tag';

export const INTRO_QUESTIONS_CONFIG_QUERY = gql`
  query IntroQuestionsConfigQuery($locale: String!, $type: String) {
    introQuestionsConfig(locale: $locale, type: $type) {
      id
      variables {
        id
        title
        type
        random
        show_other
        keywords_selector
        required
        disabled
        default_value
        dependency {
          question
          option
        }
        options {
          id
          title
          description
        }
      }
    }
  }
`;

export const INTRO_QUESTIONS_SAVED_DATA_QUERY = gql`
  query IntroQuestionsSavedDataQuery {
    savedIntroQuestions {
      scheme_id
      variables {
        code
        value
      }
    }
  }
`;

export const INTRO_QUESTIONS_SAVE_DATA_MUTATION = gql`
  mutation IntroQuestionsSaveDataMutation(
    $data: IntroQuestionsPayload!
    $locale: String!
  ) {
    saveIntroQuestions(data: $data, locale: $locale)
  }
`;

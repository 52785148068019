import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SegmentationInput } from '@globals';
import { ButtonUnstyled } from '@ui/Button';
import { FontSizeKey, Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { removeTypename } from '@utils/GQL/utils';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useCurrentBotId } from '@utils/Routing';
import { ColorKey } from '@ui/_common/colors';
import { useUserCount } from '../../hooks/useUserCount';
import { logger } from '../../utils';
import * as css from './UserFilterCount.css';

export interface AccompanyingTextProps {
  text: string;
  textColor?: ColorKey;
}

export interface UserFilterCountProps extends AccompanyingTextProps {
  segmentation: SegmentationInput | null;
  onBeforeRedirect?: VoidFunction;
  textSize?: FontSizeKey;
}

export const UserFilterCount: React.FC<UserFilterCountProps> = ({
  text,
  textSize,
  textColor,
  segmentation,
  onBeforeRedirect,
}) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  const history = useHistory();
  const [fetchUserCount, { data, loading }] = useUserCount();

  useEffect(() => {
    fetchUserCount({
      variables: {
        botId,
        withInstagram: false,
        segmentation: segmentation ? removeTypename(segmentation) : null,
      },
    });
  }, [botId, segmentation, fetchUserCount]);

  const onClick = useCallback(() => {
    logger('people button click');
    onBeforeRedirect?.();
    history.push(`/bot/${botId}/users`, { filter: segmentation });
  }, [botId, history, onBeforeRedirect, segmentation]);

  const people = data?.bot?.userCountByFilter ?? undefined;

  return (
    <Flex inline>
      <ButtonUnstyled
        className={css.button}
        onClick={onClick}
        data-testid="reengage-panel__people"
      >
        {loading && (
          <>
            <LoadingPlaceholder color="blue" width={36} height={6} />
            &nbsp;
          </>
        )}

        <Type size={textSize} color="blue">
          {t('pages.BotPage.ReengageWhatsappTab.panel.userFilterCount.people', {
            count: people,
          })}
        </Type>
      </ButtonUnstyled>

      <Type size={textSize} color={textColor}>
        &nbsp;
        {text}
      </Type>
    </Flex>
  );
};

export interface UserFilterCountBroadcastProps
  extends Pick<UserFilterCountProps, 'segmentation'> {}

export const UserFilterCountBroadcast: React.FC<UserFilterCountBroadcastProps> =
  (props) => {
    const { t } = useSafeTranslation();

    return (
      <UserFilterCount
        {...props}
        textSize="12px"
        textColor="baseSecondary"
        text={t(
          'pages.BotPage.ReengageWhatsappTab.panel.userFilterCount.audience',
        )}
      />
    );
  };

import { sendEvent } from '@utils/Analytics';

export const sendTemplateDetailsEvent = (
  action: string,
  { isConnected, template }: any,
) => {
  sendEvent({
    category: 'templates first session',
    action,
    propertyBag: {
      'page connected': isConnected.toString(),
      templateid: template.id,
      templatename: template.title,
      category: template.category,
      level: template.level,
    },
  });
};

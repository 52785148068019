import gql from 'graphql-tag';
import { WHATSAPP_BROADCAST_FRAGMENT } from './queries';

export const COPY_WHATSAPP_BROADCAST_MUTATION = gql`
  mutation CopyWhatsappBroadcast($botId: String!, $broadcastId: String!) {
    copyWhatsappBroadcast(botId: $botId, broadcastId: $broadcastId) {
      ...whatsappBroadcastFragment
    }
  }
  ${WHATSAPP_BROADCAST_FRAGMENT}
`;

import React, { useCallback } from 'react';
import {
  IntroQuestionsBlockQuestion,
  IntroQuestionsBlockQuestionProps,
} from '../IntroQuestionsBlockQuestion';
import { IntroQuestionsQuestionProps } from '../../types';
import { Spacer } from '@ui/Spacer';
import { PhoneInput } from '@ui/PhoneInput/PhoneInput';
import { MIN_PHONE_LENGTH } from '../../consts';
import { NOT_DIGIT_SYMBOLS_REGEXP } from '@ui/PhoneInput/consts';

interface IntroQuestionsBlockQuestionSelectProps
  extends IntroQuestionsBlockQuestionProps,
    Omit<IntroQuestionsQuestionProps, 'showErrorState'> {
  onChange: (value: string) => void;
}

export const IntroQuestionsBlockQuestionPhoneInput: React.FC<IntroQuestionsBlockQuestionSelectProps> =
  ({ id, title, required, onChange, disabled, 'data-testid': dataTestid }) => {
    const changeHandler = useCallback(
      (value: string) => {
        const phoneNumber = value.replace(NOT_DIGIT_SYMBOLS_REGEXP, '');
        onChange(phoneNumber.length > MIN_PHONE_LENGTH ? phoneNumber : '');
      },
      [onChange],
    );

    return (
      <IntroQuestionsBlockQuestion
        title={title}
        required={required}
        data-testid={dataTestid}
      >
        <PhoneInput
          disabled={disabled}
          name={id}
          onChange={changeHandler}
          format
          onlyNumberInput
        />
        <Spacer factor={8} />
      </IntroQuestionsBlockQuestion>
    );
  };

import React from 'react';
import { BotStatusCalloutView } from '@pages/BotPage/FlowTab/BotStatusCallout/BotStatusCalloutView/BotStatusCalloutView';
import Maybe from 'graphql/tsutils/Maybe';
import { getPluginData } from '@components/FlowBuilder/StatefulPlugin/ApolloState/utils';
import { whatsappTemplatePluginFragment_config as WhatsappTemplatePluginConfig } from '@components/Plugins/WhatsappTemplatePlugin/@types/whatsappTemplatePluginFragment';
import { WhatsappTemplateStatus } from '@globals';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useCreateWhatsappTemplateInCfEnable } from '@utils/Whatsapp/hooks/useCreateWhatsappTemplateInCfEnable';

interface BroadcastStatusCalloutProps {
  id: Maybe<string>;
}

export const BroadcastStatusCallout: React.FC<BroadcastStatusCalloutProps> = ({
  id,
}) => {
  const { t } = useSafeTranslation();
  const { isCreateWhatsappTemplateInCfEnable } =
    useCreateWhatsappTemplateInCfEnable();

  const pluginData = id
    ? getPluginData<WhatsappTemplatePluginConfig>(id)
    : undefined;

  const templateIsNotApproved =
    pluginData?.card.config.template?.status !==
    WhatsappTemplateStatus.APPROVED;

  return (
    <BotStatusCalloutView
      visible={templateIsNotApproved}
      button={undefined}
      text={
        isCreateWhatsappTemplateInCfEnable
          ? t('pages.BotPage.ReengageWhatsappTab.templateIsNotApproved')
          : t('pages.BotPage.ReengageWhatsappTab.templateIsNotApproved_old')
      }
    />
  );
};

import React from 'react';
import { Modal } from '@services/.';
import { DefaultDialog } from '@ui/Dialog';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Spacer } from '@ui/Spacer';
import { Divider } from '@ui/Menu';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { SegmentationInput } from '@globals';
import { UserFilterCount } from './UserFilterCount/UserFilterCount';
import { logger } from '../utils';
import { useCreateWhatsappTemplateInCfEnable } from '@utils/Whatsapp/hooks/useCreateWhatsappTemplateInCfEnable';

export interface ConfirmationModalProps {
  onDismiss: VoidFunction;
  onSubmit: VoidFunction;
  segmentation: SegmentationInput | null;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  onDismiss,
  onSubmit,
  segmentation,
}) => {
  const { t } = useSafeTranslation();
  const { isCreateWhatsappTemplateInCfEnable } =
    useCreateWhatsappTemplateInCfEnable();

  return (
    <DefaultDialog
      header={
        <Type as="h1" weight="extrabold" size="18px">
          {isCreateWhatsappTemplateInCfEnable
            ? t('pages.BotPage.ReengageWhatsappTab.confirmModal.title')
            : t('pages.BotPage.ReengageWhatsappTab.confirmModal_old.title')}
        </Type>
      }
      small
      onDismiss={onDismiss}
    >
      <UserFilterCount
        segmentation={segmentation}
        onBeforeRedirect={onDismiss}
        text={
          isCreateWhatsappTemplateInCfEnable
            ? t('pages.BotPage.ReengageWhatsappTab.confirmModal.message')
            : t('pages.BotPage.ReengageWhatsappTab.confirmModal_old.message')
        }
      />

      <Spacer factor={4} />

      <Divider style={{ margin: 0 }} />

      <Spacer factor={4} />

      <Flex gapFactor={3}>
        <Button
          intent="primary"
          onClick={() => {
            logger('confirmation modal submit click');
            onSubmit();
          }}
          data-testid="confirmation-modal__submit"
        >
          <Flex alignItems="center" justifyContent="center">
            <Icon icon="send" color="white" />
            <Type size="15px" color="white" weight="medium">
              {isCreateWhatsappTemplateInCfEnable
                ? t('pages.BotPage.ReengageWhatsappTab.confirmModal_old.submit')
                : t(
                    'pages.BotPage.ReengageWhatsappTab.confirmModal_old.submit',
                  )}
            </Type>
          </Flex>
        </Button>
        <Button
          onClick={() => {
            logger('confirmation modal cancel click');
            onDismiss();
          }}
          intent="secondary"
          data-testid="confirmation-modal__cancel"
        >
          {isCreateWhatsappTemplateInCfEnable
            ? t('pages.BotPage.ReengageWhatsappTab.confirmModal.cancel')
            : t('pages.BotPage.ReengageWhatsappTab.confirmModal_old.cancel')}
        </Button>
      </Flex>
    </DefaultDialog>
  );
};

export interface ShowConfirmationModalProps
  extends Pick<ConfirmationModalProps, 'segmentation'> {}

export const showConfirmationModal = ({
  segmentation,
}: ShowConfirmationModalProps) =>
  Modal.show(({ close, resolve }) => (
    <ConfirmationModal
      onDismiss={close}
      onSubmit={resolve}
      segmentation={segmentation}
    />
  ));

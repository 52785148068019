import { useMutation } from '@apollo/react-hooks';
import {
  INTRO_QUESTIONS_SAVE_DATA_MUTATION,
  INTRO_QUESTIONS_SAVED_DATA_QUERY,
} from './queries';
import {
  IntroQuestionsSaveDataMutation,
  IntroQuestionsSaveDataMutationVariables,
} from './@types/IntroQuestionsSaveDataMutation';
import { useCallback } from 'react';
import { IntroQuestionsValues } from '../types';
import { showSomethingWentWrongToaster } from '@services/MessageService';
import { log } from 'cf-common/src/logger';
import { detectLanguage } from '../../../../../../../i18n';
import { useIntroQuestionsSavedDataQuery } from './useIntroQuestionsSavedDataQuery';
import { getClid } from 'cf-common/src/analytics';
import { getUserId } from 'cf-common/src/userId';
import { useAdminData } from '@utils/Data/Admin';

export const useIntroQuestionsData = (schemeId: string | undefined) => {
  const locale = detectLanguage();
  const admin = useAdminData();
  const [introQuestionsSaveDataMutation, { loading: savingIntroQuestions }] =
    useMutation<
      IntroQuestionsSaveDataMutation,
      IntroQuestionsSaveDataMutationVariables
    >(INTRO_QUESTIONS_SAVE_DATA_MUTATION, {
      onError: (error) => {
        log.warn({ error, msg: 'Error save intro questions data' });
        showSomethingWentWrongToaster();
      },
    });
  const { data, ...state } = useIntroQuestionsSavedDataQuery();
  const adminEmail = admin.adminData?.me.email || '';

  const saveIntroQuestionsData = useCallback(
    async (data: IntroQuestionsValues) => {
      if (!schemeId) {
        return;
      }
      window.tomi?.track?.('lead_submit', {
        em: adminEmail,
        ph: data.phone || '',
        lid: getClid(),
        uid: getUserId(),
      });
      await introQuestionsSaveDataMutation({
        variables: {
          data: {
            schemeId,
            variables: Object.entries(data).map(([code, value = '']) => ({
              code,
              value: Array.isArray(value) ? value : [value],
            })),
          },
          locale,
        },
        refetchQueries: [{ query: INTRO_QUESTIONS_SAVED_DATA_QUERY }],
      });
    },
    [adminEmail, introQuestionsSaveDataMutation, locale, schemeId],
  );

  return {
    saveIntroQuestionsData,
    saving: savingIntroQuestions,
    introQuestionsSavedData: data?.savedIntroQuestions?.variables,
    ...state,
  };
};

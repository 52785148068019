import React from 'react';
import cn from 'classnames';
import { TriggeredMessageType } from '@globals';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { CollapsibleSectionWithShadow } from '@ui/CollapsibleSection/CollapsibleSectionWithShadow/CollapsibleSectionWithShadow';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useCurrentBotId } from '@utils/Routing';
import { UserFilterControl } from '@components/FlowBuilder/EditorPanel/components/common/UserFilter';
import { AttributeFilterProps } from './ScheduleSettingsBlock';
import { TriggerControl, TriggerControlProps } from './TriggerControl';
import * as css from './common.css';
import { WhatsappBroadcast_whatsappBroadcast_triggerSettings_userFilter as UserFilter } from '../hooks/@types/WhatsappBroadcast';
import { UserFilterCountBroadcast } from './UserFilterCount/UserFilterCount';

export interface TriggerSettingsBlockProps
  extends TriggerControlProps,
    AttributeFilterProps {
  updateTriggerUserFilter: (userFilter: UserFilter) => void;
}

export const TriggerSettingsBlock: React.FC<TriggerSettingsBlockProps> = ({
  config,
  isSaving,
  disabled,
  saveUserFilterConfig,
  saveUserFilterConfigAsync,
  updateConfig,
  triggerValue,
  onTriggerValueChange,
  updateTriggerUserFilter,
  children,
}) => {
  const botId = useCurrentBotId()!;
  const { t } = useSafeTranslation();

  return (
    <div>
      <CollapsibleSectionWithShadow
        title={t(
          'pages.BotPage.ReengageWhatsappTab.panel.settings.trigger.addTrigger',
        )}
      >
        <Flex flexDirection="column" className={css.sectionWrapper}>
          <TriggerControl
            disabled={disabled}
            triggerValue={triggerValue}
            onTriggerValueChange={onTriggerValueChange}
          />

          <Spacer factor={2.5} />

          {triggerValue === TriggeredMessageType.user_filter && (
            <UserFilterControl
              hideButton
              hideDelete
              disableGenericFilter
              botId={botId}
              disabled={disabled}
              config={config.triggerSettings?.userFilter || null}
              saveConfig={saveUserFilterConfig}
              saveConfigAsync={saveUserFilterConfigAsync}
              isLoading={isSaving}
              onConfigUpdate={updateTriggerUserFilter}
              // TODO
              validationErrors={{}}
            />
          )}

          {children}
        </Flex>
      </CollapsibleSectionWithShadow>

      <Spacer factor={8} />

      <CollapsibleSectionWithShadow
        title={t(
          'pages.BotPage.ReengageWhatsappTab.panel.settings.schedule.choosePeopple',
        )}
      >
        <Flex
          flexDirection="column"
          className={cn(css.sectionWrapper, css.userFilterWrapper)}
        >
          <UserFilterControl
            botId={botId}
            disabled={disabled}
            config={config.userFilter}
            saveConfig={saveUserFilterConfig}
            saveConfigAsync={saveUserFilterConfigAsync}
            isLoading={isSaving}
            onConfigUpdate={(userFilter) =>
              updateConfig({ ...config, userFilter })
            }
            // TODO
            validationErrors={{}}
          />

          <Spacer factor={4} />

          {!disabled && (
            <UserFilterCountBroadcast segmentation={config.userFilter} />
          )}
        </Flex>
      </CollapsibleSectionWithShadow>
    </div>
  );
};

import { useLazyQuery } from '@apollo/react-hooks';
import { useEntryPointsModal } from '@components/EntryPointsModal';
import {
  EntryPointEventTrigger,
  entryPointsEmitter,
  EntryPointsEvents,
} from '@components/FlowBuilder/views/entry-points/events';
import { EntryPointEventPayload } from '@components/FlowBuilder/views/entry-points/types';
import { Modal } from '@services/.';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Tooltip2 } from '@ui/Tooltip2';
import { Type } from '@ui/Type';
import { useFilterableSearch } from '@components/FilterableSearchField';
import { sendEvent } from '@utils/Analytics';
import { combineRefs } from '@utils/combineRefs';
import {
  FLOW_ITEM_QUERY,
  FlowPlaceholder,
  FLOWS_QUERY,
  useAddFlow,
  useAddFlowGroup,
} from '@utils/Data/Flow';
import { useFlowGroups } from '@utils/Data/Flow/Groups/useFlowGroups';
import {
  FlowItemQuery,
  FlowItemQueryVariables,
} from '@utils/Data/Flow/@types/FlowItemQuery';
import {
  FlowsQuery,
  FlowsQueryVariables,
} from '@utils/Data/Flow/@types/FlowsQuery';
import { DndDraggable, DndDroppable, DndProvider } from '@utils/Dnd';
import {
  BotTabs,
  getEntryPointsModalLink,
  useCurrentBotId,
  useFlowTabParams,
} from '@utils/Routing';
import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import isFunction from 'lodash-es/isFunction';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Platform } from '@globals';
import { useFlowTabDeepLinks } from '../FlowTabDeepLinks';
import { AddElementMenu } from './AddElementMenu';
import { FlowGroup } from './FlowGroup';
import { FlowItemPlaceholder } from './FlowItemPlaceholder';
import { FlowListLoader } from './FlowListLoader';
import { useReorderFlow, useReorderFlowGroup } from './reorderMutations';
import { TemplatesModal } from './TemplatesModal';
import { EmptyListPlaceholder } from './EmptyListPlaceholder';
import {
  FlowAddParams,
  FlowElementType,
  FlowWithTemplateAddParams,
} from './types';
import { useIntercomEntryPointsAddedSync } from './hooks';
import {
  FlowsFilter,
  FlowsFilterableSearch,
  useFilterFlows,
} from './FilterableSearch';

import { FlowLoadingState } from '../FlowLoader';
import { assert } from '@utils/Assert';
import { useTemplatesModalForNewUsers } from './useTemplatesModalForNewUsers';

import * as css from './FlowList.css';
import { CollapseButton } from '@components/FlowBuilder/EditorPanel/components/editors/components/CollapseButton';

const FLOW_DEFAULT_TITLE = 'Flow';

interface FlowListProps {
  loadingState: FlowLoadingState;
  showPanel?: boolean;
  setShowPanel?(showPanel: boolean): void;
  readOnly: boolean;
  onFlowAdded?(): void;
}

// using for styling dnd and not impact automate tab
const DND_MIRROR_CONTAINER_ID = 'flowNavigationPanel';

export const FlowList: React.FC<FlowListProps> = ({
  showPanel,
  setShowPanel,
  readOnly,
  loadingState,
  onFlowAdded,
}) => {
  const { t } = useSafeTranslation();
  const history = useHistory();
  const botId = useCurrentBotId()!;
  const flowTabParams = useFlowTabParams();

  const [addingFlowGroup, setAddingFlowGroup] = useState(false);
  const [addingFlowInGroup, setAddingFlowInGroup] = useState<string | null>(
    null,
  );

  const [addingElementTitle, setAddingElementTitle] = useState('');
  const [groupsOrder, setGroupsOrder] = useState<string[]>([]);
  const [flowsOrder, setFlowsOrder] = useState<Record<string, string[]>>({});
  const [filteredGroupsOrder, setFilteredGroupsOrder] = useState<string[]>([]);
  const [filteredFlowsOrder, setFilteredFlowsOrder] = useState<
    Record<string, string[]>
  >({});
  const { reorderFlowGroup } = useReorderFlowGroup();
  const { reorderFlowItem } = useReorderFlow();

  const {
    data: flowGroupsData,
    loading: flowGroupsLoading,
    refetch: refetchFlowGroups,
    called: flowGroupsCalled,
    error: flowGroupsError,
  } = useFlowGroups();
  const { addFlowGroup } = useAddFlowGroup({
    onCompleted: ({ id }) => {
      setAddingFlowGroup(false);
      setAddingElementTitle('');
      sendEvent({
        category: 'flow navigation',
        action: 'add group',
        propertyBag: {
          botId,
          groupId: id,
        },
      });
    },
    onError: () => {
      setAddingFlowGroup(false);
      setAddingElementTitle('');
    },
  });

  const { value: flowsFilterValue } = useFilterableSearch<FlowsFilter>();

  const [
    getFlows,
    {
      loading: flowsLoading,
      called: flowsCalled,
      refetch: refetchFlows,
      data: flowsQueryData,
      error: flowsError,
    },
  ] = useLazyQuery<FlowsQuery, FlowsQueryVariables>(FLOWS_QUERY, {
    variables: { botId: botId ?? '' },
    fetchPolicy: 'network-only',
  });
  const flows = flowsQueryData?.bot?.flows;

  useIntercomEntryPointsAddedSync(flows);

  const flowGroups = flowGroupsData?.bot.flow_groups;
  const filteredFlows = useFilterFlows({ flowGroups, flows });

  useFlowTabDeepLinks({ onDataUpdated: refetchFlows, loadingState });

  const { addFlow } = useAddFlow({
    onCompleted: ({ id, platform }) => {
      onFlowAdded?.();
      history.push(getEntryPointsModalLink(botId, id, platform));
      setAddingFlowInGroup(null);
      setAddingElementTitle('');
      sendEvent({
        category: 'flow navigation',
        action: 'add flow',
        propertyBag: {
          botId,
          flowId: id,
        },
      });
      if (isFunction(refetchFlows)) refetchFlows();
    },
    onError: () => {
      setAddingFlowInGroup(null);
      setAddingElementTitle('');
    },
  });

  const handleBlankFlowAdd = useCallback(
    ({ groupId, block, onCreated, onAdded, platform }: FlowAddParams) => {
      setAddingFlowInGroup(groupId || null);
      addFlow({
        parentGroupId: groupId,
        platform,
        block,
      }).then((addFlowData) => {
        setAddingElementTitle(addFlowData?.addFlow.title ?? FLOW_DEFAULT_TITLE);
        onCreated?.();
        onAdded?.();
      });
    },
    [addFlow],
  );

  const firstFlowGroupId = flowGroups?.[0]?.id;
  const isThereAnyGroup = Boolean(flowGroups?.length);

  const isFlowGroupsReady =
    !flowGroupsLoading && flowGroupsCalled && !flowGroupsError;
  useEffect(() => {
    if (!isThereAnyGroup && isFlowGroupsReady) {
      addFlowGroup(botId);
      history.replace(`/bot/${botId}/${BotTabs.flows}/${FlowPlaceholder.new}`);
    }
  }, [
    addFlowGroup,
    botId,
    isThereAnyGroup,
    isFlowGroupsReady,
    flowGroupsLoading,
    history,
  ]);

  useEffect(() => {
    if (isThereAnyGroup) {
      getFlows();
    }
  }, [isThereAnyGroup, getFlows]);

  // add this class to style dnd mirror (we need to save automate tab styles)
  useEffect(() => {
    document.body.classList.add('flows');
    return () => {
      document.body.classList.remove('flows');
    };
  }, []);

  useEffect(() => {
    if (botId) {
      refetchFlowGroups({ botId });
    }
  }, [botId, refetchFlowGroups]);

  // set groups and flows for default folder order while getting new data from backend
  useEffect(() => {
    if (!flowGroupsLoading) {
      setGroupsOrder(flowGroups?.map((group) => group.id) || []);
      setFlowsOrder(
        flowGroups?.reduce((acc, group) => {
          return Object.assign(acc, {
            [group.id]: group.flow_ids || [],
          });
        }, {}) || {},
      );
    }
  }, [flowGroups, flowGroupsLoading]);

  useEffect(() => {
    const newFlowsOrder = Object.keys(flowsOrder).reduce((acc, flowGroupId) => {
      const filteredGroup = flowsOrder[flowGroupId].filter((flowId) =>
        filteredFlows.some(({ id }) => id === flowId),
      );
      if (!flowsFilterValue || filteredGroup.length) {
        acc[flowGroupId] = filteredGroup;
      }
      return acc;
    }, {} as Record<string, string[]>);

    setFilteredGroupsOrder(Object.keys(newFlowsOrder));
    setFilteredFlowsOrder(newFlowsOrder);
  }, [flows, groupsOrder, flowsOrder, filteredFlows, flowsFilterValue]);

  const [updateFlow] = useLazyQuery<FlowItemQuery, FlowItemQueryVariables>(
    FLOW_ITEM_QUERY,
    { fetchPolicy: 'network-only' },
  );

  useEffect(() => {
    return entryPointsEmitter.on<EntryPointEventPayload>(
      EntryPointEventTrigger.flowBuilder,
      (payload) => {
        if (payload.data.event === EntryPointsEvents.updateEnabled) {
          refetchFlows({ botId: botId ?? '' });
        }
        if (payload.data.event !== EntryPointsEvents.updateEnabled) {
          const { flowId } = payload.data;
          if (flowId === FlowPlaceholder.new) {
            return;
          }
          updateFlow({ variables: { botId: botId ?? '', flowId } });
        }
      },
    );
  }, [refetchFlows, botId, updateFlow]);

  const handleFlowAddWithTemplate = useCallback(
    ({ groupId, block, onAdded }: FlowWithTemplateAddParams) => {
      assert(groupId, { msg: 'groupId should be defined' });

      Modal.show(
        ({ close }) => {
          return (
            <TemplatesModal
              groupId={groupId}
              onCreateBlankFlow={(platform) => {
                handleBlankFlowAdd({
                  groupId,
                  block,
                  platform,
                  onAdded,
                  onCreated: close,
                });
              }}
              onClose={close}
              onCreated={close}
            />
          );
        },
        {
          mobileAdaptive: true,
        },
      );
    },
    [handleBlankFlowAdd],
  );

  const { showEntryPointModal } = useEntryPointsModal({
    onDataUpdated: refetchFlows,
  });

  // need to develop button 'Add flow' on tab placeholders
  // we pass the information about adding element through the query params
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const createdElementType = queryParams.get('createdElementType');
    const platform = (queryParams.get('platform') ||
      Platform.facebook) as Platform;
    if (createdElementType === FlowElementType.flow && firstFlowGroupId) {
      handleBlankFlowAdd({
        groupId: firstFlowGroupId,
        platform,
        onAdded: () => {
          history.push({ search: '' });
        },
      });
    }
  }, [firstFlowGroupId, handleBlankFlowAdd, history, location]);

  const {
    shouldShowTemplatesModal,
    setIsTemplatesModalForNewUserShowed,
    isTemplatesModalForNewUserShowedLoading,
  } = useTemplatesModalForNewUsers();

  useEffect(() => {
    if (
      !firstFlowGroupId ||
      !shouldShowTemplatesModal ||
      isTemplatesModalForNewUserShowedLoading
    ) {
      return;
    }
    setIsTemplatesModalForNewUserShowed(true);
    Modal.show(
      ({ close: closeTemplatesModal }) => {
        return (
          <TemplatesModal
            groupId={firstFlowGroupId}
            onCreateBlankFlow={(platform) => {
              handleBlankFlowAdd({
                groupId: firstFlowGroupId,
                platform,
                onCreated: closeTemplatesModal,
              });
            }}
            onClose={() => {
              closeTemplatesModal();
            }}
            onCreated={closeTemplatesModal}
          />
        );
      },
      { mobileAdaptive: true },
    );
  }, [
    firstFlowGroupId,
    handleBlankFlowAdd,
    shouldShowTemplatesModal,
    isTemplatesModalForNewUserShowedLoading,
    setIsTemplatesModalForNewUserShowed,
  ]);

  const dndShouldBeDisabled = !!flowsFilterValue;

  // redirect to the first flow from global list
  // if flowId is empty
  if (!flowTabParams?.flowId && flowGroupsData && firstFlowGroupId) {
    let firstFlowId = FlowPlaceholder.new as string;
    flowGroups?.forEach((group) => {
      if (group?.flow_ids?.[0]) {
        if (firstFlowId === FlowPlaceholder.new) {
          // eslint-disable-next-line prefer-destructuring
          firstFlowId = group.flow_ids[0];
        }
      }
    });
    const queryParams = new URLSearchParams(location.search);
    return (
      <Redirect
        to={`/bot/${botId}/${BotTabs.flows}/${firstFlowId}?${queryParams}`}
      />
    );
  }
  const fetchError = Boolean(flowGroupsError || flowsError);

  return (
    <DndProvider
      onDrop={({ type, draggingId, sourceId, targetId, position }) => {
        let innerPosition = position;
        switch (type) {
          case FlowElementType.group:
            setGroupsOrder((currentOrder) => {
              currentOrder.splice(currentOrder.indexOf(draggingId), 1);
              currentOrder.splice(position, 0, draggingId);
              return [...currentOrder];
            });
            reorderFlowGroup({
              groupId: draggingId,
              position,
            });
            break;
          case FlowElementType.flow:
            setFlowsOrder((currentOrder) => {
              const currentPosition = Math.max(
                currentOrder[targetId].length - 1,
                0,
              );
              if (innerPosition > currentPosition) {
                innerPosition = currentPosition;
              }
              currentOrder[sourceId].splice(
                currentOrder[sourceId].indexOf(draggingId),
                1,
              );
              currentOrder[targetId].splice(innerPosition, 0, draggingId);
              return { ...currentOrder };
            });

            reorderFlowItem({
              targetGroupId: targetId,
              flowId: draggingId,
              position: innerPosition,
            });
            break;
          default:
            break;
        }
      }}
    >
      <div
        className={cn(css.botNavigationPanelWrapper, {
          [css.collapsed]: !showPanel,
        })}
        id={DND_MIRROR_CONTAINER_ID}
        style={{ flexBasis: showPanel ? 325 : 24 }}
      >
        <CollapseButton
          className={css.collapseBtn}
          onClick={() => {
            setShowPanel?.(!showPanel);
          }}
          active={!!showPanel}
        />
        {!flowGroupsCalled ||
        !flowsCalled ||
        flowGroupsLoading ||
        flowsLoading ? (
          <FlowListLoader />
        ) : (
          <Flex
            flexDirection="column"
            className={cn(css.botNavigationPanel, {
              [css.collapsed]: !showPanel,
            })}
          >
            <div className={css.botNavigationPanelLayout}>
              <Flex
                justifyContent="space-between"
                data-testid="flow-list-tab__flows-title"
                className={css.flowsTitle}
                alignItems="center"
              >
                <Type size="18px" weight="medium">
                  {t('FlowPlatformSelector-string-6975-all-flows')}
                </Type>
                <AddElementMenu
                  botId={botId}
                  groupId={firstFlowGroupId}
                  onAddGroup={() => {
                    setAddingFlowGroup(true);
                    addFlowGroup().then(({ data }) => {
                      setAddingElementTitle(data?.addFlowGroup.title || '');
                    });
                  }}
                  handleBlankFlowAdd={handleBlankFlowAdd}
                  handleFlowAddWithTemplate={handleFlowAddWithTemplate}
                  renderInput={({ getToggleButtonProps, ref }) => (
                    <Tooltip2
                      placement="top"
                      boundariesElement="viewport"
                      content={
                        <Type as="p" size="12px" color="white">
                          {t('FlowList-JSXText-1564-add-group-or-flow')}
                        </Type>
                      }
                      type="small"
                      positionFixed
                    >
                      {(tooltipRef, bind) => (
                        <Button
                          {...bind}
                          {...getToggleButtonProps()}
                          ref={combineRefs([ref, tooltipRef])}
                          icon={<Icon icon="plus" />}
                          intent="text"
                          size="s"
                          disabled={addingFlowGroup || readOnly}
                        />
                      )}
                    </Tooltip2>
                  )}
                />
              </Flex>
              <Spacer factor={6} />

              {showPanel && <FlowsFilterableSearch />}

              <Spacer factor={4} />
            </div>
            {(filteredFlows.length !== 0 ||
              filteredGroupsOrder.length !== 0) && (
              <div className={css.botNavigationPanelInner}>
                <DndDroppable
                  notDroppable={readOnly}
                  type={FlowElementType.group}
                  id="groupsContainer"
                  style={{ position: 'relative', paddingBottom: '10px' }}
                >
                  {addingFlowGroup && (
                    <FlowItemPlaceholder title={addingElementTitle} />
                  )}

                  {filteredGroupsOrder
                    .map((groupId) =>
                      flowGroups?.find((group) => group.id === groupId),
                    )
                    .map((group) => {
                      if (!group) {
                        return null;
                      }
                      const groupItem = (
                        <FlowGroup
                          readOnly={readOnly}
                          isLast={flowGroups?.length === 1}
                          group={group}
                          nestedLevel={0}
                          flowsOrder={filteredFlowsOrder[group?.id]}
                          handleBlankFlowAdd={handleBlankFlowAdd}
                          handleFlowAddWithTemplate={handleFlowAddWithTemplate}
                          addingElementTitle={
                            addingFlowInGroup === group.id
                              ? addingElementTitle
                              : undefined
                          }
                          refetchFlows={refetchFlows}
                          onEntryPointAdd={showEntryPointModal}
                          dndShouldBeDisabled={dndShouldBeDisabled}
                        />
                      );

                      return (
                        <DndDraggable
                          data-testid="flow-group"
                          key={group.id}
                          id={group.id}
                          type={FlowElementType.group}
                        >
                          {groupItem}
                        </DndDraggable>
                      );
                    })}
                </DndDroppable>

                <Spacer factor={16} />
              </div>
            )}

            {!filteredFlows.length && !filteredGroupsOrder.length && (
              <EmptyListPlaceholder
                error={fetchError}
                refetch={
                  fetchError
                    ? () => {
                        if (!botId) return;
                        if (flowGroupsError) {
                          refetchFlowGroups({ botId });
                        } else if (flowsError) {
                          refetchFlows({ botId });
                        }
                      }
                    : undefined
                }
              />
            )}
          </Flex>
        )}
      </div>
    </DndProvider>
  );
};

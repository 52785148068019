import { useMutation } from 'react-apollo';
import { PURCHASE_ADMIN_PHONE_NUMBER_MUTATION } from './queries';
import {
  PurchaseAdminPhoneNumberMutation,
  PurchaseAdminPhoneNumberMutationVariables,
} from './@types/PurchaseAdminPhoneNumberMutation';
import { log } from 'cf-common/src/logger';
import { showSomethingWentWrongToaster } from '@services/MessageService';

export const usePurchaseAdminPhoneNumber = () => {
  const [purchaseAdminPhoneNumber, { data, ...rest }] = useMutation<
    PurchaseAdminPhoneNumberMutation,
    PurchaseAdminPhoneNumberMutationVariables
  >(PURCHASE_ADMIN_PHONE_NUMBER_MUTATION, {
    onError: (error) => {
      log.error({
        error,
        msg: 'Purchase admin phone number error',
      });
      showSomethingWentWrongToaster();
    },
  });
  return {
    purchaseAdminPhoneNumber,
    phones: data?.purchaseAdminPhoneNumber.phones.phoneNumbers,
    ...rest,
  };
};

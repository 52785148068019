import React from 'react';
import { BroadcastStatus } from '@globals';
import {
  DeleteAndCopyButtons,
  DeleteAndCopyButtonsProps,
  StartButtonProps,
  PausedButtonWithMenu,
  PausedButtonWithMenuProps,
  StartButtonWithMenu,
  StartButtonWithMenuProps,
} from './FooterButtons';
import { Button } from '@ui/Button';

interface FooterButtonFactoryProps
  extends StartButtonProps,
    PausedButtonWithMenuProps,
    DeleteAndCopyButtonsProps,
    StartButtonWithMenuProps {
  from: BroadcastStatus;
  loading: boolean;
  onUnpauseButtonClick: () => void;
}

export const FooterButtonFactory: React.FC<FooterButtonFactoryProps> = ({
  from,
  loading,
  startButtonDisabled,
  startButtonTitle,
  onStartButtonClick,
  onCopyButtonClick,
  onDeleteButtonClick,
  onPauseButtonClick,
  onUnpauseButtonClick,
}) => {
  if (loading) {
    return (
      <Button intent="secondary" loading={loading}>
        {' '}
      </Button>
    );
  }

  return {
    [BroadcastStatus.DRAFT]: (
      <StartButtonWithMenu
        startButtonDisabled={startButtonDisabled}
        startButtonTitle={startButtonTitle}
        onCopyButtonClick={onCopyButtonClick}
        onDeleteButtonClick={onDeleteButtonClick}
        onStartButtonClick={onStartButtonClick}
      />
    ),
    [BroadcastStatus.SCHEDULED]: (
      <PausedButtonWithMenu
        onCopyButtonClick={onCopyButtonClick}
        onDeleteButtonClick={onDeleteButtonClick}
        onPauseButtonClick={onPauseButtonClick}
      />
    ),
    [BroadcastStatus.SENT]: (
      <DeleteAndCopyButtons
        onDeleteButtonClick={onDeleteButtonClick}
        onCopyButtonClick={onCopyButtonClick}
      />
    ),
    [BroadcastStatus.LIVE]: (
      <PausedButtonWithMenu
        onCopyButtonClick={onCopyButtonClick}
        onDeleteButtonClick={onDeleteButtonClick}
        onPauseButtonClick={onPauseButtonClick}
      />
    ),
    [BroadcastStatus.PAUSED]: (
      <StartButtonWithMenu
        startButtonDisabled={startButtonDisabled}
        startButtonTitle={startButtonTitle}
        onCopyButtonClick={onCopyButtonClick}
        onDeleteButtonClick={onDeleteButtonClick}
        onStartButtonClick={onUnpauseButtonClick}
      />
    ),
  }[from];
};

import { useCurrentBotId } from '@utils/Routing';
import { useQuery } from '@apollo/react-hooks';
import { BOT_PRICING_DATA_QUERY } from './queries';
import {
  BotPricingDataQuery,
  BotPricingDataQueryVariables,
} from './@types/BotPricingDataQuery';

export const useBotOldPricingData = () => {
  const botId = useCurrentBotId();
  const { data, ...queryState } = useQuery<
    BotPricingDataQuery,
    BotPricingDataQueryVariables
  >(BOT_PRICING_DATA_QUERY, {
    variables: { botId: botId || '' },
    skip: !botId,
  });
  return {
    ...queryState,
    pricingData: data?.bot.pricingData,
  };
};

import { useDownloadInvoice } from '@utils/Data/Pricing';
import { sendEvent } from '@utils/Analytics';
import { useBillingColumns } from '../Table/useBillingColumns';

export const useProBillingInvoiceColumns = (botId: string) => {
  const { downloadInvoice } = useDownloadInvoice();

  return useBillingColumns({
    onDownload: ({ paymentResult, date }) => {
      const invoiceId = paymentResult?.id;
      sendEvent({
        category: 'billing',
        action: 'download invoice',
        label: 'new dialogs pricing',
        propertyBag: { botId, invoiceId },
      });
      return invoiceId
        ? downloadInvoice(invoiceId, String(date))
        : Promise.reject();
    },
  });
};

import { useCurrentBotId } from '@utils/Routing';
import { useCallback } from 'react';
import { useMutation } from 'react-apollo';
import { DataProxy } from 'apollo-cache';
import { defaultErrorHandler } from '../utils';
import {
  CopyWhatsappBroadcast,
  CopyWhatsappBroadcastVariables,
} from './@types/CopyWhatsappBroadcast';
import { COPY_WHATSAPP_BROADCAST_MUTATION } from './mutations';
import {
  WhatsappBroadcasts,
  WhatsappBroadcastsVariables,
  WhatsappBroadcasts_whatsappBroadcastList_broadcasts as Broadcast,
} from './@types/WhatsappBroadcasts';
import { WHATSAPP_BROADCASTS_QUERY } from './queries';
import Maybe from 'graphql/tsutils/Maybe';
import { mapQuery } from '@utils/GQL/mapQuery';

const updateList = (
  store: DataProxy,
  variables: WhatsappBroadcastsVariables,
  copiedBroadcastId: string,
  newBroadcast: Maybe<CopyWhatsappBroadcast>,
) => {
  if (!newBroadcast) return;

  mapQuery<WhatsappBroadcasts, WhatsappBroadcastsVariables>(
    store,
    { query: WHATSAPP_BROADCASTS_QUERY, variables },
    (data) => {
      if (!data.whatsappBroadcastList) return data;
      const index = data.whatsappBroadcastList.broadcasts.findIndex(
        ({ id }) => copiedBroadcastId === id,
      );

      const broadcast: Broadcast = {
        statusUpdatedAt: null,
        ...newBroadcast.copyWhatsappBroadcast,
        type: data.whatsappBroadcastList.broadcasts[index].type,
        stats: null,
      };

      const newList = [broadcast, ...data.whatsappBroadcastList.broadcasts];

      return {
        whatsappBroadcastList: {
          ...data.whatsappBroadcastList,
          broadcasts: newList,
        },
      };
    },
  );
};

export const useCopyWhatsappBroadcastMutation = () => {
  const botId = useCurrentBotId()!;
  const [mutation, result] = useMutation<
    CopyWhatsappBroadcast,
    CopyWhatsappBroadcastVariables
  >(COPY_WHATSAPP_BROADCAST_MUTATION);

  const onCopy = useCallback(
    (broadcastId: string) =>
      mutation({
        variables: {
          botId,
          broadcastId,
        },
        update(store, { data }) {
          // TODO: cache
          updateList(
            store,
            { botId, status: null, type: null },
            broadcastId,
            data,
          );
        },
      })
        .then(({ data }) => {
          if (!data) {
            throw new Error();
          }
          return data;
        })
        .catch(
          defaultErrorHandler({
            msg: `Could not copy broadcast`,
            data: { broadcastId },
          }),
        ) as Promise<CopyWhatsappBroadcast>,
    [mutation, botId],
  );

  return [onCopy, result] as const;
};

import React, { useMemo, useState } from 'react';
import { Divider, MenuItem } from '@ui/Menu';
import { MenuCombobox } from '@ui/SimpleCombobox';
import { Button } from '@ui/Button';
import { ReactComponent as AddTagIcon } from '../icons/addTag.svg';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import css from './AddTagsCombobox.css';
import { Spacer } from '@ui/Spacer';
import { Input } from '@ui/Input';
import { BotTag, useBotTags } from '../useBotTags';
import { sendEvent } from '@utils/Analytics';
import { useCurrentContactId } from '@utils/Routing';

interface AddTagsComboboxProps {
  createTag: (name: string) => Promise<any>;
  selectTag: (tag: BotTag) => Promise<any>;
  contactTags?: BotTag[];
}

export const AddTagsCombobox: React.FC<AddTagsComboboxProps> = ({
  contactTags,
  createTag,
  selectTag,
}) => {
  const { t } = useSafeTranslation();
  const { tags } = useBotTags();
  const contactId = useCurrentContactId();

  const [search, setSearch] = useState('');

  const contactTagIds = new Set(contactTags?.map((t) => t.id));
  const contactTagNames = new Set(contactTags?.map((t) => t.name));

  const filteredItems = useMemo(() => {
    const searchLc = search.toLocaleLowerCase();
    return tags
      .filter((t) => !contactTagIds.has(t.id))
      .filter((i) => i.name.toLocaleLowerCase().includes(searchLc))
      .map((t) => ({
        id: t.id,
        title: t.name,
      }));
  }, [contactTagIds, search, tags]);

  return (
    <MenuCombobox
      containerClassName={css.menuBoxClassNameContainer}
      menuBoxClassName={css.menuBoxClassName}
      selectedItem={null}
      placement="bottom"
      onChange={(item) => {
        selectTag({ name: item.title, id: item.id }).then(() => setSearch(''));
      }}
      items={filteredItems}
      data-testid="add-tags-combobox"
      renderMenuHead={({ downshift }) => (
        <div>
          <Input
            data-testid="add-tags-combobox_tag_input"
            autoFocus
            {...downshift.getInputProps()}
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            error={contactTagNames.has(search)}
            maxLength={256}
          />
          <Spacer factor={2} />
        </div>
      )}
      renderInput={({ popperReference, downshift }) => {
        const { onClick, ...buttonProps } = downshift.getToggleButtonProps();
        return (
          <Button
            ref={popperReference.ref}
            intent="secondary"
            size="m"
            type="button"
            fullWidth
            fitToText
            icon={<AddTagIcon />}
            {...buttonProps}
            onClick={(ev) => {
              sendEvent({
                category: 'contact profile',
                action: 'click',
                label: 'add tag',
                propertyBag: {
                  contactId,
                  tab: 'people tab',
                },
              });
              onClick?.(ev);
            }}
          >
            {t('PeoplePage.addTag')}
          </Button>
        );
      }}
      renderFooterItem={({ downshift }) => {
        return (
          <div>
            <Divider />
            <Spacer factor={2} />
            <Button
              data-testid="add-tags-combobox_add-tag-button"
              fullWidth
              onClick={() => {
                createTag(search);
                downshift?.closeMenu();
                setSearch('');
              }}
              disabled={search ? contactTagNames.has(search) : false}
            >
              {t('PeoplePage.add')}
            </Button>
          </div>
        );
      }}
    >
      {({
        downshift: { getItemProps, highlightedIndex },
        props: { item, index },
      }) => (
        <MenuItem
          title={item.title}
          active={index === highlightedIndex}
          key={item.id}
          tabIndex={0}
          {...getItemProps({ item })}
        />
      )}
    </MenuCombobox>
  );
};

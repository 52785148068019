import React from 'react';
import { useCurrentContactId } from '@utils/Routing';
import { useContactQuery } from './useContactQuery';
import { ContactCard } from './ContactCard/ContactCard';
import { Flex } from '@ui/Flex';
import { ContactTagsCard } from './ContactsTagsCard/ContactTagsCard';
import * as css from './ContactPage.css';
import { ContactPageHeader } from './ContactPageHeader/ContactPageHeader';
import { Spacer } from '@ui/Spacer';
import { ContactAttributesCard } from './ContactAttributesCard/ContactAttributesCard';
import { useUpdateBotTags } from '@pages/BotPage/ContactPage/useUpdateBotTags';

export const ContactPage: React.FC = () => {
  const contactId = useCurrentContactId();
  const { contact } = useContactQuery(contactId);

  const { deleteTag } = useUpdateBotTags(contact?.tags || []);

  if (!contact) {
    return null;
  }
  return (
    <div className={css.page} data-testid="user-card-page">
      <ContactPageHeader contact={contact} />
      <Spacer factor={2} />
      <Flex flexDirection="row" gapFactor={2}>
        <Flex flexDirection="column" gapFactor={2} className={css.leftColumn}>
          <ContactCard contact={contact} />
          <ContactTagsCard
            tags={contact.tags}
            onDelete={(id: string) => {
              deleteTag(id);
            }}
          />
        </Flex>
        <ContactAttributesCard contact={contact} />
      </Flex>
    </div>
  );
};

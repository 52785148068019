import React from 'react';
import { VisuallyDisabled } from '@ui/VisuallyDisabled';
import { useCurrentBotId } from '../../../../utils/Routing';
import { usePageConnected } from '../../../../utils/FacebookPages/usePageConnected';

export const DisableIfPageNotConnected: React.FC = ({ children }) => {
  const botId = useCurrentBotId();
  const { isConnected, loading: pageLoading } = usePageConnected(botId);
  const disable = pageLoading || !isConnected;

  return <VisuallyDisabled disable={disable}>{children}</VisuallyDisabled>;
};

import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';
import { useWhatsappEnabled } from '@utils/Whatsapp';

export const useTemplatesModalForNewUsers = () => {
  const { isWhatsappEnabled, loading: whatsappLoading } = useWhatsappEnabled();
  const [
    isTemplatesModalForNewUserShowed,
    setIsTemplatesModalForNewUserShowed,
    { loading: serverStorageLoading },
  ] = useServerStorage<boolean>(
    ServerStorageItemKeys.isTemplatesModalForNewUserShowed,
  );

  return {
    shouldShowTemplatesModal:
      !isTemplatesModalForNewUserShowed && !isWhatsappEnabled,
    setIsTemplatesModalForNewUserShowed,
    isTemplatesModalForNewUserShowedLoading:
      whatsappLoading || serverStorageLoading,
  };
};

import React from 'react';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { FooterMenu, FooterMenuProps } from './FooterMenu';

export interface MenuWithButtonProps extends TestLocator, FooterMenuProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const MenuWithButton: React.FC<MenuWithButtonProps> = ({
  onClick,
  children,
  onCopyButtonClick,
  onDeleteButtonClick,
  ...props
}) => {
  return (
    <Flex>
      <FooterMenu
        onCopyButtonClick={onCopyButtonClick}
        onDeleteButtonClick={onDeleteButtonClick}
      />

      <Spacer factor={0} horizontalFactor={3} />

      <Button
        style={{ width: '100%' }}
        intent="secondary"
        onClick={onClick}
        data-testid={props['data-testid']}
      >
        <Flex alignItems="center" justifyContent="center">
          <Icon icon="pause" color="blue" />
          <Type size="15px" color="blue" weight="medium">
            {children}
          </Type>
        </Flex>
      </Button>
    </Flex>
  );
};

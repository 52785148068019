import React from 'react';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { IconButton } from '@ui/IconButton';
import { MenuItem } from '@ui/Menu';
import { MenuCombobox } from '@ui/SimpleCombobox';
import cn from 'classnames';
import { Spacer } from '@ui/Spacer';
import css from './FilterButton.css';

interface Item {
  id: string;
  node: React.ReactNode;
}

interface FilterButtonProps {
  title: string;
  items: Item[];
  selectedItem: string | null;
  setSelectedItem(id: string): void;
}

export const FilterButton: React.FC<FilterButtonProps> = ({
  title,
  items,
  selectedItem,
  setSelectedItem,
}: FilterButtonProps) => (
  <Flex alignItems="center">
    {title}
    <Spacer horizontalFactor={1} />
    {/* @ts-expect-error */}
    <MenuCombobox<Item>
      placement="bottom"
      menuBoxClassName={css.menuBoxClassName}
      onSelect={(item) => setSelectedItem(item.id)}
      items={items}
      data-testid="whatsapp-reengage__filter-dropdown"
      renderInput={({ popperReference, downshift }) => (
        <div className={css.iconBox}>
          <IconButton
            ref={popperReference.ref}
            {...downshift.getToggleButtonProps()}
            icon="filter"
          />
          {selectedItem && <div className={css.enabled} />}
        </div>
      )}
    >
      {({
        downshift: { getItemProps, highlightedIndex },
        props: { item, index },
      }) => (
        <MenuItem
          active={index === highlightedIndex}
          key={item.id}
          className={cn(css.menuItem, index === highlightedIndex && css.active)}
          titleElement={item.node}
          rightMenuElement={
            item.id === selectedItem ? (
              <Icon
                icon="check"
                size="20px"
                color="accent1Normal"
                className={css.checkIcon}
              />
            ) : null
          }
          {...getItemProps({ item })}
        />
      )}
    </MenuCombobox>
  </Flex>
);

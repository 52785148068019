import React from 'react';
import cn from 'classnames';
import * as css from './KeywordsPageBlockBox.css';

interface KeywordsPageBlockBoxProps extends React.HTMLProps<HTMLDivElement> {}

export const KeywordsPageBlockBox: React.FC<KeywordsPageBlockBoxProps> = ({
  className,
  ...props
}) => <div className={cn(css.box, className)} {...props} />;

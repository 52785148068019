import { PluginType } from '@components/Plugins/common/PluginTypes';
import { useAdminFeatures } from '@utils/Data/Admin';
import { useMemo } from 'react';
import { AddFlowItemType } from './constants';
import {
  ServerStorageItemKeys,
  serverStorageItemSet,
} from '@utils/ServerStorage';

export const getLeftShift = (nestedLevel = 0) => {
  switch (nestedLevel) {
    case 0:
      return 28;
    case 1:
      return 55;
    case 2:
      return 79;
    default:
      return 0;
  }
};

export const getPlaceholderLeftShift = (nestedLevel = 0) => {
  switch (nestedLevel) {
    case 0:
      return 28;
    case 1:
      return 58;
    case 2:
      return 79;
    default:
      return 0;
  }
};

/**
 * filter menu items based on feature toggles and modify them if need
 *
 * example: Add Messenger flow -> Add flow (without Instagram automation)
 * @param items
 */
export const useAddMenuItemsByAdminFeatures = (items: AddFlowItemType[]) => {
  const {
    adminFeatures: {
      comments_autoreply_entry_point: isShowCommentsAutoreply,
    } = {},
  } = useAdminFeatures();

  return useMemo(
    () =>
      items.filter(
        ({ id }) =>
          isShowCommentsAutoreply ||
          id !== PluginType.comments_autoreply_entry_point,
      ),
    [items, isShowCommentsAutoreply],
  );
};

export const setTemplatesModalForNewUserShowed = () =>
  serverStorageItemSet(
    ServerStorageItemKeys.isTemplatesModalForNewUserShowed,
    true,
  );

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AnalyzeTab } from './AnalyzeTab';
import { LiveChatPage } from '../LiveChat';
import { UpgradePage } from '../UpgradePage';
import { PlansPage } from '../Plans';
import { BillingPage, BillingShopifyPage } from '../Billing';
import { AiSetupPage } from '../AiSetupPage';
import { ConfigureTab } from './ConfigureTab';
import { PeopleTab } from './PeopleTab';
import { AutomateTab } from './AutomateTab';
import { ReEngageTab } from './ReEngageTab/ReEngageTab';
import { HomeTab } from './HomeTab';
import { MessagesTemplatesTab } from './MessagesTemplatesTab';
import { FlowTab } from './FlowTab';
import { KeywordsPage } from './KeywordsPage';
import { ContactPage } from './ContactPage/ContactPage';
import { BOT_TAB_ID } from '../GrowPage/AbandonedCart';
import { NoMatch } from '../NoMatch';
import { FirstSessionPage } from './KeywordsPage/FirstSessionPage';
import { ReferralProgramForm } from '@components/ReferralProgram';
import { WhatsappReEngageTab } from './WhatsappReEngageTab/WhatsappReEngageTab';
import { WAMigrationSetup } from './WAMigrationSetup/WAMigrationSetup';
import { useAutomateEnabled } from '@utils/Data/Admin/Automate';
import { useTimeLimitedTrial } from '@utils/Data/Pricing/useTimeLimitedTrial';
import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';
import Maybe from 'graphql/tsutils/Maybe';
import { BotTabs, getDefaultTab, getTabLink } from '@utils/Routing';
import { isWhiteLabelDomain } from '@utils/WhiteLabelUtils';
import { useIsLiveChatWebView } from '@utils/useIsLiveChatWebView';
import { InitAuthProps } from '@utils/AuthFlow';
import { AdminFeaturesQuery_me_features } from '@utils/Data/Admin/@types/AdminFeaturesQuery';
import css from './BotPage.css';
import { useShopifyAccount } from '@utils/Integrations/Shopify/useShopifyAccount';
import { PlansShopifyPage } from '../Plans/PlansShopifyPage';

interface BotPageRoutingProps {
  path: string;
  history: InitAuthProps['history'];
  dialogPricingEnabled: boolean | undefined;
  livechatV2: boolean | undefined;
  workspaceId: Maybe<string>;
  botId: string;
  showAside: boolean;
  setShowAside(value: boolean): void;
  adminFeatures: AdminFeaturesQuery_me_features | undefined;
  isWhatsappEnabled: boolean;
  isCreateWhatsappTemplateInCfEnable: boolean;
}

export const BotPageRouting: React.FC<BotPageRoutingProps> = ({
  path,
  dialogPricingEnabled,
  livechatV2,
  workspaceId,
  botId,
  showAside,
  setShowAside,
  history,
  adminFeatures,
  isWhatsappEnabled,
  isCreateWhatsappTemplateInCfEnable,
}) => {
  const { isAutomateEnabled } = useAutomateEnabled();
  const [firstWABotId] = useServerStorage<String>(
    ServerStorageItemKeys.firstWABotId,
  );
  const isWhiteLabel = isWhiteLabelDomain();
  const { currentTierTimeLimitedTrial, trialExpired, timeLimitedTrialLoading } =
    useTimeLimitedTrial();
  const disabledByTimeLimitedTrial = Boolean(
    currentTierTimeLimitedTrial && trialExpired && !timeLimitedTrialLoading,
  );
  const { isLiveChatWebView } = useIsLiveChatWebView();
  const { account, loading: shopifyAccountLoading } = useShopifyAccount();
  const hasShopifyAccount = shopifyAccountLoading
    ? undefined
    : Boolean(account);

  if (isLiveChatWebView) {
    return (
      <Switch>
        <Route
          path={`${path}/${BotTabs.liveChat}`}
          render={() => (livechatV2 ? <LiveChatPage /> : null)}
        />
        <Route
          path={`${path}/*`}
          render={() => <Redirect to={getTabLink(BotTabs.liveChat, botId)} />}
        />
      </Switch>
    );
  }

  return (
    <>
      {!disabledByTimeLimitedTrial && (
        <Switch>
          <Route
            path={`${path}/`}
            render={() => <Redirect to={getDefaultTab(botId)} />}
            exact
          />
          <Route
            // keep grow tab link for compatibility
            path={[`${path}/${BotTabs.grow}`, `${path}/${BotTabs.home}`]}
            render={(routeProps) => (
              <div className={css.botTab} id={BOT_TAB_ID}>
                <HomeTab {...routeProps} />
              </div>
            )}
          />
          {!isWhiteLabel && (
            <Route
              path={`${path}/${BotTabs.referral}`}
              render={() => <ReferralProgramForm />}
            />
          )}
          <Route
            path={[
              `${path}/${BotTabs.flows}/:flowId`,
              `${path}/${BotTabs.flows}`,
            ]}
            render={() => (
              <FlowTab showAside={!!showAside} setShowAside={setShowAside} />
            )}
          />
          <Route
            path={[
              `${path}/${BotTabs.automate}/:blockId`,
              `${path}/${BotTabs.automate}`,
            ]}
            render={() =>
              isAutomateEnabled ? (
                <AutomateTab />
              ) : (
                <NoMatch history={history} />
              )
            }
          />
          <Route
            path={`${path}/${BotTabs.liveChat}`}
            render={() =>
              livechatV2 ? (
                <LiveChatPage />
              ) : (
                <Redirect to={getDefaultTab(botId)} />
              )
            }
          />
          <Route
            path={[
              `${path}/${BotTabs.keywords}`,
              `${path}/${BotTabs.keywords}/:platform`,
              `${path}/${BotTabs.keywords}/:platform/:page`,
            ]}
            render={() => {
              // show keyword tab for all whatsapp bots,
              // because all new users already have keyword tab instead of setup AI
              // and it will be additional work to support whatsapp there
              const showKeywordTab =
                adminFeatures?.show_keywords_tab || isWhatsappEnabled;
              return showKeywordTab ? <KeywordsPage /> : <AiSetupPage />;
            }}
          />
          <Route
            path={`${path}/${BotTabs.ai}`}
            render={() => <Redirect to={getTabLink(BotTabs.keywords, botId)} />}
          />
          <Route
            path={`${path}/${BotTabs.people}/:segmentId?`}
            render={() => <PeopleTab />}
          />
          <Route
            path={`${path}/${BotTabs.messagesTemplates}`}
            render={(props) =>
              isCreateWhatsappTemplateInCfEnable ? (
                <MessagesTemplatesTab {...props} />
              ) : null
            }
          />
          <Route
            path={`${path}/${BotTabs.contacts}/:contactId`}
            render={() => <ContactPage />}
          />
          <Route
            path={`${path}/${BotTabs.reEngage}`}
            render={(routeProps) =>
              isWhatsappEnabled ? (
                <WhatsappReEngageTab
                  showAside={!!showAside}
                  setShowAside={setShowAside}
                  {...routeProps}
                />
              ) : (
                <ReEngageTab {...routeProps} />
              )
            }
          />
          <Route
            path={`${path}/${BotTabs.configure}`}
            render={() => <ConfigureTab />}
          />
          <Route
            path={`${path}/${BotTabs.analyze}`}
            render={() => <AnalyzeTab />}
          />
          {!dialogPricingEnabled && !workspaceId && (
            <Route
              path={`${path}/${BotTabs.upgrade}`}
              render={() => <UpgradePage />}
            />
          )}
        </Switch>
      )}
      <Switch>
        {dialogPricingEnabled &&
          !workspaceId &&
          hasShopifyAccount === false && (
            <Route
              path={`${path}/${BotTabs.plans}`}
              render={() => <PlansPage />}
            />
          )}
        {dialogPricingEnabled &&
          !workspaceId &&
          hasShopifyAccount === false && (
            <Route
              path={`${path}/${BotTabs.billing}`}
              render={() => <BillingPage />}
            />
          )}
        {dialogPricingEnabled && !workspaceId && hasShopifyAccount === true && (
          <Route
            path={`${path}/${BotTabs.plans}`}
            render={() => <PlansShopifyPage />}
          />
        )}
        {dialogPricingEnabled && !workspaceId && hasShopifyAccount === true && (
          <Route
            path={`${path}/${BotTabs.billing}`}
            render={() => <BillingShopifyPage />}
          />
        )}
        <Route
          path={[
            `${path}/${BotTabs.firstSession}`,
            `${path}/${BotTabs.firstSession}/:platform`,
          ]}
          render={() => <FirstSessionPage />}
        />
        {firstWABotId === botId && (
          <Route
            path={`${path}/${BotTabs.waMigrationSetup}`}
            render={() => <WAMigrationSetup />}
          />
        )}
        {disabledByTimeLimitedTrial && (
          <Route
            path={`${path}/*`}
            render={() => <Redirect to={getTabLink(BotTabs.plans, botId)} />}
          />
        )}
      </Switch>
    </>
  );
};

import React from 'react';
import { CenteredLoader } from '@ui/Loader';

export interface BotPanelLoaderViewProps {
  className?: string;
}

export const BotPanelLoaderView: React.FC<BotPanelLoaderViewProps> = ({
  className,
}) => <CenteredLoader className={className} />;

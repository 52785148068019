import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { MoreMenu, MoreMenuItem } from '@ui/MoreMenu';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import React, { useCallback, useMemo } from 'react';
import { PauseProDialog } from '@components/CancelProDialog/PauseProDialog/PauseProDialog';
import { Modal } from '@services/index';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import { useHistory } from 'react-router-dom';
import css from './PlanBlock.css';
import { showTransferPlanDialog } from '@components/TransferPlanDialog';
import { useWhatsappEnabled } from '@utils/Whatsapp';

interface SettingProps {
  onPause: VoidFunction;
  onCancel: VoidFunction;
  canceled: boolean;
  isTrial: boolean;
}

const mobileModalProps = { className: css.gradientModal };

export const Setting: React.FC<SettingProps> = ({
  onPause,
  onCancel,
  canceled,
  isTrial,
}) => {
  const { t } = useSafeTranslation();
  const history = useHistory();
  const botId = useCurrentBotId();
  const { isWhatsappEnabled } = useWhatsappEnabled();

  const handleUpgrade = useCallback(() => {
    if (botId) history.push(getTabLink(BotTabs.plans, botId));
  }, [botId, history]);

  const handlePause = useCallback(() => {
    Modal.show(
      ({ close }) => (
        <PauseProDialog
          onPaused={() => {
            onPause();
            close();
          }}
          onCancel={close}
          onClose={close}
        />
      ),
      { mobileAdaptive: true, mobileProps: mobileModalProps },
    );
  }, [onPause]);

  const menuItems = useMemo(() => {
    const items: MoreMenuItem[] = [
      {
        leftElement: <Icon icon="tabs_upgrade" noColor />,
        title: t('pages.Billing.pro.upgrade'),
        onClick: handleUpgrade,
      },
    ];

    if (!isTrial)
      items.push({
        leftElement: <Icon icon="pause" noColor />,
        title: t('pages.Billing.pro.pausePlan'),
        onClick: handlePause,
      });

    if (!canceled) {
      if (!isWhatsappEnabled) {
        items.push({
          leftElement: <Icon icon="transferPlan" noColor />,
          title: t('pages.Billing.pro.transferPlan'),
          onClick: showTransferPlanDialog,
        });
      }

      items.push({
        leftElement: <Icon icon="close" noColor />,
        title: t('pages.Billing.pro.cancelPlan'),
        className: css.cancelItem,
        onClick: onCancel,
      });
    }

    return items;
  }, [
    t,
    handleUpgrade,
    isTrial,
    handlePause,
    canceled,
    isWhatsappEnabled,
    onCancel,
  ]);

  return (
    <MoreMenu
      data-testid="plan-block__settings"
      items={menuItems}
      position="bottom-end"
      className={css.settings}
      button={<Button intent="secondary" icon={<Icon icon="settings" />} />}
    />
  );
};

import React from 'react';
import { Button } from '@ui/Button';
import { sendEvent } from '@utils/Analytics';
import { useSafeTranslation } from '@utils/useSafeTranslation';

interface AddAccountButtonProps {
  onAddAccount(): void;
}

export const AddAccountButton: React.FC<AddAccountButtonProps> = ({
  onAddAccount,
}) => {
  const { t } = useSafeTranslation();

  return (
    <Button
      intent="secondary"
      fullWidth
      onClick={() => {
        sendEvent({
          category: 'whatsapp',
          action: 'open embedded signup',
          label: 'add new account',
        });
        onAddAccount();
      }}
      data-testid="whatsapp-phone-select__addAccount"
    >
      {t('pages.BotPage.HomeTab.Whatsapp.addAccount')}
    </Button>
  );
};

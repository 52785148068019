import React from 'react';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { ReactComponent as GoatSmallIcon } from './images/GoatSmall.svg';
import { ReactComponent as GoatMediumIcon } from './images/GoatMedium.svg';
import * as css from './DoneSetupBox.css';

export const DoneSetupBox = React.memo(() => {
  const { t } = useSafeTranslation();
  const { isSmallScreenSize, getValueDueToSize } = useDeviceMedia();
  const topPadding = getValueDueToSize(16, 24);
  const bottomPadding = getValueDueToSize(16, 32);
  const leftAndRightPadding = getValueDueToSize(16, 32);
  const padding = `${topPadding}px ${leftAndRightPadding}px ${bottomPadding}px`;

  return (
    <Flex
      flexDirection={isSmallScreenSize ? 'column' : 'row'}
      className={css.box}
    >
      {isSmallScreenSize && <GoatSmallIcon className={css.picture} />}
      <div style={{ padding }}>
        <Type as="h1" size="24px" weight="semibold" color="white">
          {t('pages.FirstSession.DoneSetupBox.title')}
        </Type>
        <Type as="p" size="15px" color="white">
          {t('pages.FirstSession.DoneSetupBox.description')}
        </Type>
      </div>
      {!isSmallScreenSize && <GoatMediumIcon className={css.picture} />}
    </Flex>
  );
});

import React, { useMemo } from 'react';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { PaymentDate } from '../components/Invoices/PaymentDate';
import { Column } from '@components/Payments/PaymentHistory';
import { PaymentHistoryItem, PaymentHistoryType } from './types';
import {
  SimplePaymentFactory,
  ComplexPaymentView,
  Dash,
  ComplexTitleView,
  SimplePaymentView,
  DownloadButton,
} from './components';

export interface BillingColumnsProps<T> {
  onDownload: (item: T) => Promise<void>;
}

export const useBillingColumns = <T extends PaymentHistoryItem>(
  props: BillingColumnsProps<T>,
) => {
  const { isSmallScreenSize } = useDeviceMedia();

  const columns = useMemo((): Array<Column<T>> => {
    const dateColumn: Column<T> = {
      columnId: 'date',
      render: ({ date, paymentResult }) => (
        <PaymentDate
          date={Number(date)}
          error={!isSmallScreenSize && Boolean(paymentResult?.failed)}
        />
      ),
    };

    const descriptionColumn: Column<T> = {
      columnId: 'description',
      render: (tableData) =>
        ({
          [PaymentHistoryType.empty]: <Dash />,
          [PaymentHistoryType.complex]: (
            <ComplexPaymentView
              paymentEntityType={tableData.paymentEntityType}
              parts={tableData.parts}
            />
          ),
          [PaymentHistoryType.default]: (
            <SimplePaymentFactory
              part={tableData.parts[0]}
              paymentEntityType={tableData.paymentEntityType}
            />
          ),
        }[tableData.type]),
    };

    const totalColumn: Column<T> = {
      columnId: 'total',
      render: (tableData) =>
        ({
          [PaymentHistoryType.empty]: <Dash />,
          [PaymentHistoryType.complex]: (
            <ComplexTitleView
              {...tableData}
              data-testid="payment-history-price-amount"
            />
          ),
          [PaymentHistoryType.default]: (
            <SimplePaymentView
              {...tableData}
              data-testid="payment-history-price-amount"
            />
          ),
        }[tableData.type]),
    };

    const downloadColumn: Column<T> = {
      columnId: 'download',
      render: (item) => {
        if (!item.paymentResult) {
          return null;
        }

        return (
          <DownloadButton
            onDownload={() => props.onDownload(item)}
            hideLabel={isSmallScreenSize}
            error={Boolean(item.paymentResult?.failed)}
          />
        );
      },
    };

    return [dateColumn, descriptionColumn, totalColumn, downloadColumn];
    // eslint-disable-next-line
  }, [props]);

  return columns;
};

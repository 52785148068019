export enum WhatsappBuyPhoneDialogStep {
  pickOption = 'pickOption',
  registerToReceiveMessages = 'registerToReceiveMessages',
  chooseCountryCode = 'chooseCountryCode',
  generate = 'generate',
  needFbConnect = 'needFbConnect',
}

export enum PhoneOption {
  need = 'need',
  have = 'have',
}

import React from 'react';
import { AiFacebookStatistics } from './AiFacebookStatistics';
import { AiInstagramStatistics } from './AiInstagramStatistics';
import * as css from './AIStatisticsBlock.css';

export const AIStatisticsBlock = () => (
  <div className={css.wrapper}>
    <AiFacebookStatistics />

    <AiInstagramStatistics />
  </div>
);

import React from 'react';
import cn from 'classnames';
import { UserFilterControl } from '@components/FlowBuilder/EditorPanel/components/common/UserFilter';
import { CollapsibleSectionWithShadow } from '@ui/CollapsibleSection/CollapsibleSectionWithShadow/CollapsibleSectionWithShadow';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { useCurrentBotId } from '@utils/Routing';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { AttributeFilterProps } from './ScheduleSettingsBlock';
import * as css from './ScheduleSettingsBlock.css';
import * as commonCSS from './common.css';
import { Disableable } from '../types';
import { UserFilterCountBroadcast } from './UserFilterCount/UserFilterCount';

export interface OneTimeSettingsBlockProps
  extends AttributeFilterProps,
    Disableable {}

export const OneTimeSettingsBlock: React.FC<OneTimeSettingsBlockProps> = ({
  config,
  isSaving,
  disabled,
  updateConfig,
  saveUserFilterConfig,
  saveUserFilterConfigAsync,
}) => {
  const botId = useCurrentBotId()!;
  const { t } = useSafeTranslation();

  return (
    <CollapsibleSectionWithShadow
      title={t(
        'pages.BotPage.ReengageWhatsappTab.panel.settings.rightNow.choosePeopple',
      )}
    >
      <Flex
        flexDirection="column"
        className={cn(css.wrapper, commonCSS.userFilterWrapper)}
      >
        <UserFilterControl
          botId={botId}
          disabled={disabled}
          config={config.userFilter}
          saveConfig={saveUserFilterConfig}
          saveConfigAsync={saveUserFilterConfigAsync}
          isLoading={isSaving}
          onConfigUpdate={(userFilter) =>
            updateConfig({ ...config, userFilter })
          }
          // TODO
          validationErrors={{}}
        />

        <Spacer factor={4} />

        {!disabled && (
          <UserFilterCountBroadcast segmentation={config.userFilter} />
        )}
      </Flex>
    </CollapsibleSectionWithShadow>
  );
};

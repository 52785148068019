import {
  AdminDeletionMutation,
  AdminDeletionMutationVariables,
} from '@components/AdminPermissionManagerDialog/@types/AdminDeletionMutation';
import { ADMIN_DELETION_MUTATION } from '@components/AdminPermissionManagerDialog/AdminPermissionManagerDialog';
import { ROLES_AND_INVITES_QUERY } from '../../../../../../components/settings/admins/AdminsConst';
import { useMutation } from 'react-apollo';

export const useRemoveBotAdmin = (botId: string, userId: string) => {
  return useMutation<AdminDeletionMutation, AdminDeletionMutationVariables>(
    ADMIN_DELETION_MUTATION,
    {
      variables: { botId, userId },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: ROLES_AND_INVITES_QUERY,
          variables: { botId },
        },
      ],
    },
  );
};

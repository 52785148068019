import React, { useState } from 'react';
import { DefaultDialog } from '@ui/Dialog';
import * as css from './WhatsappBuyPhoneDialog.css';
import { getStepsTitles } from './helpers/getStepsTitles';
import { PhoneOption, WhatsappBuyPhoneDialogStep } from './types';
import { PickOption } from './steps/PickOption';
import { RegisterToReceiveMessages } from './steps/RegisterToReceiveMessages';
import { Generate } from './steps/Generate';
import { ChooseCountryCode } from './steps/ChooseCountryCode';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useWhatsappBuyPhoneDialogStep } from './hooks/useWhatsappBuyPhoneDialogStep';
import { CenteredLoader } from '@ui/Loader';
import { FBBAConnectDialog } from '../../../../../../MultiSystemAuth/pages/FBBAConnectDialog/FBBAConnectDialog';

interface WhatsappBuyPhoneDialogProps {
  onWhatsappLoginRequest: VoidFunction;
  onDismiss: VoidFunction;
}

export const WhatsappBuyPhoneDialog: React.FC<WhatsappBuyPhoneDialogProps> = ({
  onDismiss,
  onWhatsappLoginRequest,
}) => {
  const { step, setStep, loading } = useWhatsappBuyPhoneDialogStep();
  const { isSmallScreenSize } = useDeviceMedia();
  const [afterLoginOption, setAfterLoginOption] = useState<PhoneOption>();

  const handlePickOption = (option: PhoneOption) => {
    switch (option) {
      case PhoneOption.need:
        setStep(WhatsappBuyPhoneDialogStep.registerToReceiveMessages);
        break;
      case PhoneOption.have:
      default:
        onWhatsappLoginRequest();
        onDismiss();
        break;
    }
  };

  return (
    <DefaultDialog
      mobileAdaptive={isSmallScreenSize}
      header={getStepsTitles()[step || WhatsappBuyPhoneDialogStep.pickOption]}
      onDismiss={onDismiss}
      className={isSmallScreenSize ? css.mobileDialog : css.dialog}
      contentClassName={css.content}
      closeButtonClassName={css.closeButton}
    >
      {loading && <CenteredLoader />}
      {step === WhatsappBuyPhoneDialogStep.pickOption && (
        <PickOption
          onDone={async (option, needFBToken) => {
            if (needFBToken) {
              setAfterLoginOption(option);
              setStep(WhatsappBuyPhoneDialogStep.needFbConnect);
              return;
            }

            handlePickOption(option);
          }}
        />
      )}
      {step === WhatsappBuyPhoneDialogStep.needFbConnect && (
        <FBBAConnectDialog
          onLogin={() => handlePickOption(afterLoginOption!)}
        />
      )}
      {step === WhatsappBuyPhoneDialogStep.registerToReceiveMessages && (
        <RegisterToReceiveMessages
          onDone={() => {
            setStep(WhatsappBuyPhoneDialogStep.chooseCountryCode);
          }}
        />
      )}
      {step === WhatsappBuyPhoneDialogStep.chooseCountryCode && (
        <ChooseCountryCode
          onDone={() => {
            setStep(WhatsappBuyPhoneDialogStep.generate);
          }}
        />
      )}
      {step === WhatsappBuyPhoneDialogStep.generate && (
        <Generate
          onDone={onDismiss}
          onWhatsappLoginRequest={onWhatsappLoginRequest}
        />
      )}
    </DefaultDialog>
  );
};

import ng from 'angular';
import React from 'react';
import { Router } from 'react-router-dom';
import { react2angular } from 'react2angular';
import { ApolloProvider } from '@apollo/react-common';
import { ToasterProvider } from '@ui/Toaster';
import { globalHistory } from '@utils/Routing';
import { ExportChartButton, ExportChartButtonProps } from './ExportChartButton';
import client from '../../../../common/services/ApolloService';

const ExportChartButtonWrapper: React.FC<ExportChartButtonProps> = (props) => (
  <Router history={globalHistory}>
    <ToasterProvider>
      <ApolloProvider client={client}>
        <ExportChartButton {...props} />
      </ApolloProvider>
    </ToasterProvider>
  </Router>
);

export const ngExportChartButton = ng
  .module('app.modernUi.exportChartButton', [])
  .component(
    'exportChartButton',
    react2angular(
      ExportChartButtonWrapper,
      [
        'className',
        'statsType',
        'startDate',
        'endDate',
        'disabled',
        'platform',
      ],
      [],
    ),
  ).name;

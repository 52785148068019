import React from 'react';
import { PlatformConnectedWrapper } from '@pages/GrowPage/PlatformConnection/PlatformConnected/PlatformConnectedWrapper';
import { PlatformConnectedButtonsWrapper } from '@pages/GrowPage/PlatformConnection';
import { PlatformConnectionLoading } from '@pages/GrowPage/PlatformConnection/PlatformConnectionLoading/PlatformConnectionLoading';
import { MoreMenu } from '@ui/MoreMenu';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { WhatsappTitle } from '../WhatsappTitle/WhatsappTitle';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { isPhoneVerified } from '@utils/Data/Whatsapp';
import { MobileButtons } from '../MobileButtons/MobileButtons';
import { WhatsappAccountBody } from './WhatsappAccountBody';
import * as css from './WhatsappInitialized.css';
import { useWhatsappConnectionState } from '../hooks/useWhatsappConnectionState';
import { useMoreMenuItems } from '../hooks/useMoreMenuItems';
import { useRolePermission } from '@utils/Roles';
import { Permission } from '@common/services/RoleService';
import { useAdminData } from '@utils/Data/Admin';
import { useCurrentBotId } from '@utils/Routing';
import { RoleIds } from '../../../../../../components/settings/admins/RolesList';
import { useBotFeatures } from '@utils/Data/Bot';

export const WhatsappInitialized: React.FC = () => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  const { isSmallScreenSize } = useDeviceMedia();
  const {
    isConfirmed,
    isConnected,
    whatsappPhoneEntries,
    currentAccount,
    currentPhone,
    handlePickPhone,
    handleUpdatePhone,
    handleNoPhoneConnectedClick,
    handlePhoneVerificationClick,
    tokenIsUpdating,
    updating,
  } = useWhatsappConnectionState();
  const { checkRoleByBotId } = useAdminData();
  const { allowed: isEditAllowed } = useRolePermission({
    domain: 'grow',
    can: Permission.EDIT,
  });

  const { botFeatures } = useBotFeatures(botId);
  const isBuisnessAppIntegration =
    botFeatures?.whatsapp_business_app_integration;

  const { menuItems, whatsappActionToMenuItem, disconnecting, reregistering } =
    useMoreMenuItems({
      isConnected,
      isConfirmed,
      currentPhone,
      currentAccount,
      /**
       * Изменения может делать только Admin бота. Если сдесь использовать `allowed` из
       * хука {@link useRolePermission}, то роль Editor тоже сможет редактировать, что
       * в данном случае не верно
       */
      isEditAllowed: checkRoleByBotId(RoleIds.admin, botId),
    });

  if (disconnecting || reregistering) {
    return <PlatformConnectionLoading data-testid="whatsapp__loading" />;
  }

  const phoneIsVerified = isPhoneVerified(
    currentPhone,
    isBuisnessAppIntegration,
  );

  const renderWhatsappAccountBody = () => {
    if (isConfirmed) {
      return null;
    }

    if (!currentPhone) {
      return (
        <WhatsappAccountBody
          note={t('pages.BotPage.HomeTab.Whatsapp.noPhoneNumber')}
          buttonTitle={t('pages.BotPage.HomeTab.Whatsapp.connect')}
          loading={tokenIsUpdating}
          onClick={handleNoPhoneConnectedClick}
          data-testid="whatsapp-phone-select__connect"
          disabled={!isEditAllowed}
        />
      );
    }

    if (!phoneIsVerified) {
      return (
        <WhatsappAccountBody
          buttonTitle={t('pages.BotPage.HomeTab.Whatsapp.connect')}
          note={t('pages.BotPage.HomeTab.Whatsapp.phoneIsNotVerified')}
          loading={tokenIsUpdating}
          onClick={() => {
            handlePhoneVerificationClick();
          }}
          data-testid="whatsapp-phone-select__verify"
          disabled={!isEditAllowed}
        />
      );
    }

    if (!isConfirmed) {
      return (
        <WhatsappAccountBody
          note={t('pages.BotPage.HomeTab.Whatsapp.reviewAccountData')}
          buttonTitle={t('pages.BotPage.HomeTab.Whatsapp.confirm')}
          loading={updating}
          onClick={handleUpdatePhone}
          data-testid="whatsapp-connected__confirm"
          disabled={!isEditAllowed}
        />
      );
    }

    return null;
  };

  return (
    <PlatformConnectedWrapper data-testid="whatsapp-connected">
      <WhatsappTitle
        isConfirmed={isConfirmed}
        whatsappPhoneEntries={whatsappPhoneEntries}
        currentAccount={currentAccount}
        currentPhone={currentPhone}
        onPhoneChange={handlePickPhone}
        handlePhoneVerificationClick={handlePhoneVerificationClick}
      />
      <PlatformConnectedButtonsWrapper
        className={css.unconfirmed}
        data-testid="whatsapp-connected__buttons"
      >
        {renderWhatsappAccountBody()}
        {isSmallScreenSize ? (
          <MobileButtons
            isConfirmed={isConfirmed}
            whatsappActionToMenuItem={whatsappActionToMenuItem}
          />
        ) : (
          <MoreMenu
            items={menuItems}
            position="top-end"
            data-testid="whatsapp-connected__more-menu"
          />
        )}
      </PlatformConnectedButtonsWrapper>
    </PlatformConnectedWrapper>
  );
};

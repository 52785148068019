import React from 'react';
import * as css from './MessagesTemplatesTableView.css';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { truncateWithEllipses } from 'cf-common/src/utils/String/truncateWithEllipses';
import { Flex } from '@ui/Flex';
import { IconButton } from '@ui/IconButton';
import { GetMessageTemplates_messageTemplates } from '../../../../data/@types/GetMessageTemplates';
import { DateFormat } from '@utils/DateTime';
import { Status } from './components/Status';
import { getStatusRepresentation } from '@utils/Whatsapp/Templates/utils';
import { sendEvent } from '@utils/Analytics';
import { logEvent } from '../../utils';

type ItemActionHandler = (id: string) => void;

interface MessagesTemplatesTableViewProps {
  items: GetMessageTemplates_messageTemplates[];
  onItemClick: ItemActionHandler;
  onDeleteRequest: ItemActionHandler;
  onCloneRequest: (template: GetMessageTemplates_messageTemplates) => void;
}

export const MessagesTemplatesTableView: React.FC<MessagesTemplatesTableViewProps> =
  ({ items, onItemClick, onCloneRequest, onDeleteRequest }) => {
    const { t } = useSafeTranslation();

    return (
      <table data-testid="messages-templates_table_view" className={css.table}>
        <thead>
          <tr>
            <th>
              <Type color="baseNormal" weight="semibold">
                {t('pages.MessagesTemplates.Table.header.name')}
              </Type>
            </th>
            <th>
              <Type color="baseNormal" weight="semibold">
                {t('pages.MessagesTemplates.Table.header.category')}
              </Type>
            </th>
            <th>
              <Type color="baseNormal" weight="semibold">
                {t('pages.MessagesTemplates.Table.header.status')}
              </Type>
            </th>
            <th>
              <Type color="baseNormal" weight="semibold">
                {t('pages.MessagesTemplates.Table.header.language')}
              </Type>
            </th>
            <th>
              <Type color="baseNormal" weight="semibold">
                {t('pages.MessagesTemplates.Table.header.lastUpdated')}
              </Type>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {items.map((template) => {
            const {
              id,
              name,
              category,
              language,
              last_edited: lastEdited,
              status,
              quality_score,
            } = template;
            return (
              <tr
                key={id}
                data-testid="messages-templates_table_row"
                onClick={() => {
                  sendEvent({
                    category: 'messages templates',
                    label: 'table',
                    action: 'item click',
                  });
                  onItemClick(id);
                }}
              >
                <td>
                  <Type color="baseNormal" weight="semibold">
                    {truncateWithEllipses(name, 30)}
                  </Type>
                </td>
                <td>
                  {category ? (
                    <Type color="baseSecondary">
                      {t(`pages.MessagesTemplates.category.${category}`)}
                    </Type>
                  ) : (
                    '-'
                  )}
                </td>
                <td>
                  <Status
                    {...getStatusRepresentation({
                      status,
                      quality_score,
                    })}
                    status={status}
                  />
                </td>
                <td>
                  <Type color="baseSecondary">{language}</Type>
                </td>
                <td>
                  <Type color="baseSecondary">
                    {lastEdited ? DateFormat.DMMMYY(lastEdited) : ''}
                  </Type>
                </td>
                <td>
                  <Flex gapFactor={1} className={css.buttons}>
                    <IconButton
                      icon="duplicate"
                      data-testid="messages-templates_table_row_clone-button"
                      onClick={(event) => {
                        logEvent({ action: 'copy template' });
                        event.stopPropagation();
                        onCloneRequest(template);
                      }}
                    />
                    <IconButton
                      icon="delete"
                      data-testid="messages-templates_table_row_delete-button"
                      onClick={(event) => {
                        event.stopPropagation();
                        onDeleteRequest(id);
                      }}
                    />
                  </Flex>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

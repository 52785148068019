import gql from 'graphql-tag';
import { AI_BLOCK_FRAGMENT } from '@utils/Data/Ai/common/queries';

export const FREQUENT_UNRECOGNIZED_MESSAGES_FRAGMENT = gql`
  fragment frequentUnrecognizedMessagesFragment on Bot {
    id
    aiPhraseGroups(platform: $platform) {
      phrase
      count
    }
  }
`;

export const CREATE_AND_TEACH_KEYWORDS_INTENT_MUTATION = gql`
  mutation CreateAndTeachKeywordsIntentMutation(
    $botId: String!
    $intent: AiIntentInput!
    $phrase: String!
    $platform: Platform!
  ) {
    removeAiPhraseGroup(botId: $botId, phrase: $phrase, platform: $platform) {
      ...frequentUnrecognizedMessagesFragment
    }
    addAiIntentToDefaultGroup(botId: $botId, intent: $intent) {
      ...aiBlockFragment
    }
  }
  ${FREQUENT_UNRECOGNIZED_MESSAGES_FRAGMENT}
  ${AI_BLOCK_FRAGMENT}
`;

export const TEACH_KEYWORDS_INTENT_MUTATION = gql`
  mutation TeachKeywordsIntentMutation(
    $botId: String!
    $intentId: String!
    $phrase: String!
    $platform: Platform!
  ) {
    removeAiPhraseGroup(botId: $botId, phrase: $phrase, platform: $platform) {
      ...frequentUnrecognizedMessagesFragment
    }
    updateAiIntentAndReturnAiBlock(
      botId: $botId
      intentId: $intentId
      phrase: $phrase
    ) {
      ...aiBlockFragment
    }
  }
  ${FREQUENT_UNRECOGNIZED_MESSAGES_FRAGMENT}
  ${AI_BLOCK_FRAGMENT}
`;

export const REMOVE_KEYWORDS_PHRASE_TO_BLACKLIST_MUTATION = gql`
  mutation RemoveKeywordsPhraseToBlacklistMutation(
    $botId: String!
    $phrase: String!
    $platform: Platform!
  ) {
    removeAiPhraseGroup(
      botId: $botId
      phrase: $phrase
      platform: $platform
      blacklist: true
    ) {
      ...frequentUnrecognizedMessagesFragment
    }
  }
  ${FREQUENT_UNRECOGNIZED_MESSAGES_FRAGMENT}
`;

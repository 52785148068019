import { MessagesTemplateAttributeExampleInput } from '@globals';
import { getFullAttributesBoundaries } from '@ui/TextWithAttributesEditor';

export const updateAttributeExamples = (
  attributeExamples: MessagesTemplateAttributeExampleInput[],
  attributeNames: string[],
) => {
  const headerExamplesMap = attributeExamples.reduce(
    (prev, curr) => ({ ...prev, [curr.name]: curr.value }),
    {} as Record<string, string>,
  );

  const newExamples = attributeNames.map((attribute) => ({
    name: attribute,
    value: headerExamplesMap[attribute] || '',
  }));

  return newExamples;
};

export const getAttributeExamples = (value: string): string[] => {
  return (
    getFullAttributesBoundaries(value)
      .map((attr) => attr.text)
      // если в строке будет "{{}}", то text будет пустой строкой
      .filter(Boolean)
  );
};

import i18next from 'i18next';
import { EntryPointStatus } from '../../types';

export const TITLE_ITEM = {
  id: '_title',
  type: 'title',
};

export const ENTRY_POINT_STATUS_MENU_ITEMS = () => [
  {
    id: EntryPointStatus.ACTIVE,
    title: i18next.t(
      'pages.BotPage.FlowTab.FlowList.FilterableSearch.entryPointStatusFilterActive',
    ),
  },
  {
    id: EntryPointStatus.INACTIVE,
    title: i18next.t(
      'pages.BotPage.FlowTab.FlowList.FilterableSearch.entryPointStatusFilterInactive',
    ),
  },
];

import { useWorkspacePermission } from '@utils/Data/Workspaces/useWorkspacePermission';
import { removeGlobalLoader } from 'cf-common/src/utils/removeGlobalLoader';
import { Permission } from '@common/services/RoleService';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { PremiumBillingPage } from './PremiumBillingPage';

export const PremiumBillingPageRedirect: React.FC = () => {
  const [permission, loading] = useWorkspacePermission();
  const isAvailable = permission === Permission.EDIT;

  useEffect(() => {
    if (!loading) {
      removeGlobalLoader();
    }
  }, [loading]);

  if (loading) {
    return null;
  }

  if (!isAvailable) {
    return <Redirect to="/bots" />;
  }

  return <PremiumBillingPage />;
};

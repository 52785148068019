import { useEffect, useRef } from 'react';
import {
  isPhoneVerified,
  useUpdateWhatsappSettings,
  useWhatsappConnected,
  WhatsappPhoneEntry,
} from '@utils/Data/Whatsapp';
import Maybe from 'graphql/tsutils/Maybe';
import { useAdminPhones } from './components/WhatsappBuyPhoneDialog/hooks/useAdminPhones';
import { useBotFeatures } from '@utils/Data/Bot';

interface UseAutoSelectWhatsappAccountArgs {
  botId: Maybe<string>;
  whatsappPhoneEntries: WhatsappPhoneEntry[];
  loading: boolean;
}

export const useAutoSelectWhatsappAccount = ({
  botId,
  whatsappPhoneEntries,
  loading,
}: UseAutoSelectWhatsappAccountArgs) => {
  const hadPhoneEntriesRef = useRef<boolean | null>(null);
  const {
    updateWhatsappSettings,
    loading: phoneConnecting,
    called: updated,
  } = useUpdateWhatsappSettings(botId);
  const { botFeatures, botFeaturesLoading } = useBotFeatures(
    botId ?? undefined,
  );
  const isBuisnessAppIntegration =
    botFeatures?.whatsapp_business_app_integration;

  const {
    lastNotUtilizedWhatsappPhone,
    lastNotUtilizedPhoneWhatsappEntry,
    loading: adminPhonesLoading,
    refetch: refetchAdminPhones,
  } = useAdminPhones();
  const { isConnected, loading: whatsappConnectedLoading } =
    useWhatsappConnected(botId);

  useEffect(() => {
    if (
      loading ||
      adminPhonesLoading ||
      updated ||
      whatsappConnectedLoading ||
      botFeaturesLoading
    )
      return;

    if (whatsappPhoneEntries.length === 0) {
      hadPhoneEntriesRef.current = false;
      return;
    }

    const justConnectedOnePhone =
      whatsappPhoneEntries.length === 1 &&
      whatsappPhoneEntries[0]?.phones?.length === 1 &&
      isPhoneVerified(
        whatsappPhoneEntries[0]?.phones[0],
        isBuisnessAppIntegration,
      ) &&
      hadPhoneEntriesRef.current === false;

    // автоматически коннектим купленный номер, если он не "утилизирован" (не имеет пейджи)
    const justConnectedLastNotUtilizedWhatsappPhone =
      lastNotUtilizedWhatsappPhone &&
      lastNotUtilizedPhoneWhatsappEntry &&
      isPhoneVerified(lastNotUtilizedWhatsappPhone, isBuisnessAppIntegration) &&
      !updated &&
      !isConnected;

    if (!justConnectedOnePhone && !justConnectedLastNotUtilizedWhatsappPhone) {
      hadPhoneEntriesRef.current = true;
      return;
    }

    if (justConnectedOnePhone) {
      const [{ businessAccount, phones }] = whatsappPhoneEntries;
      updateWhatsappSettings({
        whatsappBusinessAccountId: businessAccount.id,
        phoneId: phones![0].id,
      });
    }

    if (justConnectedLastNotUtilizedWhatsappPhone) {
      updateWhatsappSettings({
        whatsappBusinessAccountId:
          lastNotUtilizedPhoneWhatsappEntry.businessAccount.id,
        phoneId: lastNotUtilizedWhatsappPhone.id,
      });
    }
  }, [
    whatsappPhoneEntries,
    updateWhatsappSettings,
    loading,
    adminPhonesLoading,
    updated,
    lastNotUtilizedWhatsappPhone,
    refetchAdminPhones,
    lastNotUtilizedPhoneWhatsappEntry,
    isBuisnessAppIntegration,
    whatsappConnectedLoading,
    botFeaturesLoading,
    isConnected,
  ]);

  return {
    phoneConnecting,
  };
};

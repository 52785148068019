import { useServerStorage, ServerStorageItemKeys } from '@utils/ServerStorage';

export const useFlowShareState = () => {
  const [hasSharedFlow, setHasSharedFlow, { loading: loadingShareFlow }] =
    useServerStorage<boolean>(ServerStorageItemKeys.hasSharedFlow);

  const [hasExportedFlow, setHasExportedFlow, { loading: loadingExportFlow }] =
    useServerStorage<boolean>(ServerStorageItemKeys.hasExportedFlow);

  return {
    hasSharedFlow,
    setHasSharedFlow,
    hasExportedFlow,
    setHasExportedFlow,
    loadingShareFlow,
    loadingExportFlow,
  };
};

export const useFlowGroupShareState = () => {
  const [
    hasSharedFlowGroup,
    setHasSharedFlowGroup,
    { loading: loadingShareFlowGroup },
  ] = useServerStorage<boolean>(ServerStorageItemKeys.hasSharedFlowGroup);

  return {
    hasSharedFlowGroup,
    setHasSharedFlowGroup,
    loadingShareFlowGroup,
  };
};

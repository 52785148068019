import React from 'react';
import { ItemsList } from '@components/ItemsModal';
import { TemplateModalHandlers } from '../../../TemplatesModal';
import { Template } from '../../../types';
import { CreateBlankFlow } from '../CreateBlankFlow';
import { TemplateItem } from '../TemplateItem';
import { FreeTemplates } from '../FreeTemplates';
import { CreateFlowForMe } from '../CreateFlowForMe';

interface TemplatesListProps extends TemplateModalHandlers {
  templatesToShow: Template[];
  callout?: React.ReactNode;
}

export const TemplatesList: React.FC<TemplatesListProps> = ({
  templatesToShow,
  onTemplateSelect,
  onCreateBlankFlow,
  callout,
}) => (
  <ItemsList<Template>
    callout={callout}
    short={!!callout}
    itemsToShow={templatesToShow}
    renderBeforeItems={() => [
      <CreateBlankFlow onCreateBlankFlow={onCreateBlankFlow} />,
      <CreateFlowForMe />,
      <FreeTemplates />,
    ]}
    renderItem={(template) => (
      <TemplateItem onTemplateSelect={onTemplateSelect} template={template} />
    )}
  />
);

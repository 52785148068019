import { DataProxy } from 'apollo-cache';
import { useCallback } from 'react';
import { useMutation } from 'react-apollo';
import { useCurrentBotId } from '@utils/Routing';
import {
  DELETE_WHATSAPP_BROADCAST_MUTATION,
  WHATSAPP_BROADCASTS_QUERY,
} from './queries';
import { defaultErrorHandler } from '../utils';
import {
  WhatsappBroadcasts,
  WhatsappBroadcastsVariables,
} from './@types/WhatsappBroadcasts';
import { mapQuery } from '@utils/GQL/mapQuery';

const updateList = (
  store: DataProxy,
  variables: WhatsappBroadcastsVariables,
  broadcastId: string,
) => {
  mapQuery<WhatsappBroadcasts, WhatsappBroadcastsVariables>(
    store,
    { query: WHATSAPP_BROADCASTS_QUERY, variables },
    (data) => {
      if (!data.whatsappBroadcastList) return data;

      return {
        whatsappBroadcastList: {
          ...data.whatsappBroadcastList,
          broadcasts: data.whatsappBroadcastList.broadcasts.filter(
            ({ id }) => id !== broadcastId,
          ),
        },
      };
    },
  );
};

export const useDeleteWhatsappBroadcast = () => {
  const botId = useCurrentBotId()!;
  const [deleteWhatsappBroadcastMutation, queryResult] = useMutation(
    DELETE_WHATSAPP_BROADCAST_MUTATION,
  );

  const deleteWhatsappBroadcast = useCallback(
    (broadcastId: string) => {
      return deleteWhatsappBroadcastMutation({
        variables: { botId, broadcastId },
        update(data) {
          // TODO: context
          updateList(data, { botId, status: null, type: null }, broadcastId);
        },
      }).catch(
        defaultErrorHandler({
          msg: `Could not delete broadcast`,
          data: { broadcastId },
        }),
      );
    },
    [deleteWhatsappBroadcastMutation, botId],
  );

  return [deleteWhatsappBroadcast, queryResult] as const;
};

import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DeepLinksMode, DeepLinksQueryParam } from '@pages/DeepLinks/types';
import { useRolePermission } from '@utils/Roles';
import { Permission } from '@common/services/RoleService';

export const useProcessQueryParams = (
  loginWhatsapp: () => void,
  ready: boolean,
) => {
  const location = useLocation();
  const history = useHistory();
  const { allowed: isEditAllowed } = useRolePermission({
    domain: 'grow',
    can: Permission.EDIT,
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const deeplinkMode = urlParams.get(DeepLinksQueryParam.mode);
    if (deeplinkMode === DeepLinksMode.connectWhatsapp && ready) {
      history.replace({ ...location, search: undefined });
      if (!isEditAllowed) {
        return;
      }
      loginWhatsapp();
    }
  }, [history, isEditAllowed, location, location.search, loginWhatsapp, ready]);
};

export enum FlowItemMenuType {
  none = 'none',
  rename = 'rename',
  copy = 'copy',
  move = 'move',
  delete = 'delete',
  share = 'share',
  manageAttributes = 'manageAttributes',
}

export function isFlowItemMenuType(item: string): item is FlowItemMenuType {
  return Object.values(FlowItemMenuType).includes(item as FlowItemMenuType);
}

export interface FlowActionsEventPayload {
  data: { actionId: FlowItemMenuType; flowId: string };
}

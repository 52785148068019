import { PaymentHistory, PaymentHistoryProps } from '@components/Payments';
import { Flex } from '@ui/Flex';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import React from 'react';
import cn from 'classnames';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { MobileInvoices } from './MobileInvoices/MobileInvoices';
import css from './Invoices.css';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';

export interface InvoicesProps<Item>
  extends Pick<
    PaymentHistoryProps<Item>,
    | 'listHeight'
    | 'items'
    | 'getRejectedItem'
    | 'isError'
    | 'columns'
    | 'columnClassName'
    | 'itemHeight'
  > {
  className?: string;
  title?: string;
  errorMessage?: React.ReactNode;
  headers: string[];
}

// TODO: Переверстать таблицу инвойсов
export const Invoices = <Item,>({
  title,
  items,
  className,
  listHeight,
  errorMessage,
  columnClassName,
  headers,
  ...paymentHistoryProps
}: InvoicesProps<Item>) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const { t } = useSafeTranslation();

  if (!items.length)
    return (
      <div className={cn(css.invoices, className)}>
        <Type size="24px" weight="semibold" className={css.title}>
          {t('pages.Billing.invoices.invoices')}
        </Type>
        <Spacer factor={2} />
        <Type size="15px" className={css.description}>
          {t('pages.Billing.invoices.invoicesWillAppear')}
        </Type>
        <Spacer factor={6} />
      </div>
    );

  return (
    <Flex flexDirection="column" className={cn(css.invoices, className)}>
      <Type size="24px" weight="semibold" className={css.title}>
        {title ?? t('pages.Billing.invoices.invoices')}
      </Type>
      {errorMessage}
      <Spacer factor={5} />
      {!isSmallScreenSize && (
        <>
          <Flex justifyContent="space-between">
            {headers.map((text, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i} className={cn(css.paymentsColumn, columnClassName)}>
                <Type size="12px" weight="semibold" color="greyDark">
                  {text}
                </Type>
              </div>
            ))}
          </Flex>
          <div className={css.separrator} />
        </>
      )}
      <div className={css.paymentsHistory}>
        <AutoSizer defaultHeight={listHeight} disableWidth>
          {({ height }) =>
            isSmallScreenSize ? (
              <MobileInvoices
                listHeight={height}
                rowClassName={css.paymentsRow}
                columnClassName={cn(css.paymentsColumn, columnClassName)}
                items={items}
                headers={headers}
                {...paymentHistoryProps}
              />
            ) : (
              <PaymentHistory<Item>
                listHeight={height}
                rowClassName={css.paymentsRow}
                columnClassName={cn(css.paymentsColumn, columnClassName)}
                items={items}
                {...paymentHistoryProps}
              />
            )
          }
        </AutoSizer>
      </div>
    </Flex>
  );
};

interface InvoicesLoaderProps {
  className?: string;
  columnClassName?: string;
  headers: string[];
}

export const InvoicesLoader = ({
  className,
  columnClassName,
  headers,
}: InvoicesLoaderProps) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const { t } = useSafeTranslation();

  return (
    <Flex flexDirection="column" className={cn(css.invoices, className)}>
      <Type size="24px" weight="semibold" className={css.title}>
        {t('pages.Billing.invoices.invoices')}
      </Type>
      <Spacer factor={5} />
      {!isSmallScreenSize && (
        <>
          <Flex justifyContent="space-between">
            {headers.map((text, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i} className={cn(css.paymentsColumn, columnClassName)}>
                <Type size="12px" weight="semibold" color="greyDark">
                  {text}
                </Type>
              </div>
            ))}
          </Flex>
          <div className={css.separrator} />
        </>
      )}
      <div className={css.paymentsHistory}>
        <Flex flexDirection="column" style={{ padding: '12px 24px 8px 24px' }}>
          <Flex>
            <LoadingPlaceholder width={96} height={12} />
            <Spacer horizontalFactor={5} factor={0} />
            <LoadingPlaceholder width={236} height={12} />
            <Spacer horizontalFactor={5} factor={0} />
            <LoadingPlaceholder width={136} height={12} />
          </Flex>

          <Spacer factor={3} />

          {[1, 2].map((_, i) => (
            <>
              <Flex>
                <Spacer horizontalFactor={29} factor={0} />
                <LoadingPlaceholder width={140} height={8} />
                <Spacer horizontalFactor={45} factor={0} />
                <LoadingPlaceholder width={72} height={8} />
              </Flex>

              <Spacer factor={3} />

              <Flex>
                <Spacer horizontalFactor={29} factor={0} />
                <LoadingPlaceholder width={120} height={8} />
                <Spacer horizontalFactor={68} factor={0} />
              </Flex>

              {i !== 1 && <Spacer factor={5} />}
            </>
          ))}

          <Spacer factor={3} />

          {[1, 2].map((_, i) => (
            <>
              <Flex>
                <LoadingPlaceholder width={96} height={12} />
                <Spacer horizontalFactor={5} factor={0} />
                <LoadingPlaceholder width={236} height={12} />
                <Spacer horizontalFactor={5} factor={0} />
                <LoadingPlaceholder width={136} height={12} />
              </Flex>

              <Spacer factor={3} />

              <Flex>
                <Spacer horizontalFactor={29} factor={0} />
                <LoadingPlaceholder width={140} height={8} />
                <Spacer horizontalFactor={45} factor={0} />
                <LoadingPlaceholder width={72} height={8} />
              </Flex>

              {i !== 1 && <Spacer factor={5} />}
            </>
          ))}
        </Flex>
      </div>
    </Flex>
  );
};

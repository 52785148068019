import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { usePaymentError } from '@utils/Data/Pricing';
import { useCurrentBotId, useGoToUpdateCardInfo } from '@utils/Routing';
import { useBotFeatures, useBotProStatus } from '@utils/Data/Bot';
import { RoleConsumer } from '@utils/Roles';
import { Callout } from '@ui/Callout';
import { Button } from '@ui/Button';
import { Permission } from '@common/services/RoleService';
import { sendHomeTabEvent } from '../../utils';
import { usePlanButtonType } from '../BotSectionView/PlanButton/hooks';
import { PlanButtonType } from '../BotSectionView/PlanButton/types';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import * as css from './PaymentFailedCallout.css';
import { useAdminPhones } from '../WhatsappAccount/components/WhatsappBuyPhoneDialog/hooks/useAdminPhones';
import { useBotWorkspaceId } from '@utils/Data/Bot/useBotWorkspaceId';

interface PaymentFailedCalloutViewProps {
  text: string;
  buttonTitle: string;
  onClick: () => void;
  className?: string;
}

const PaymentFailedCalloutView: React.FC<PaymentFailedCalloutViewProps> = ({
  text,
  buttonTitle,
  onClick,
  className,
}) => {
  const { getValueDueToSize } = useDeviceMedia();

  return (
    <Callout
      type="error"
      text={text}
      data-testid="payment-failed-callout"
      flexDirection={getValueDueToSize('column', 'row')}
      alignItems={getValueDueToSize('flex-start', 'center')}
      className={className}
      button={
        <RoleConsumer domain="pro" can={Permission.EDIT}>
          {({ allowed }) => (
            <Button
              onClick={onClick}
              intent="secondary"
              disabled={!allowed}
              className={css.button}
              data-testid="payment-failed-callout__update-card-button"
            >
              {buttonTitle}
            </Button>
          )}
        </RoleConsumer>
      }
    />
  );
};

export interface PaymentFailedCalloutProps {
  className?: string;
}

export const PaymentFailedCallout: React.FC<PaymentFailedCalloutProps> = ({
  className,
}) => {
  const botId = useCurrentBotId()!;
  const { t } = useSafeTranslation();
  const [workspaceId, workspaceLoading] = useBotWorkspaceId(botId);
  const { botFeatures, botFeaturesLoading } = useBotFeatures(botId);
  const goToUpdateCard = useGoToUpdateCardInfo();
  const { paymentsHistoryLoading, isPaymentFailed } = usePaymentError();
  const { botIsManualPro, loading: botStatusLoading } = useBotProStatus();
  const planButtonType = usePlanButtonType();
  const { lastNotUtilizedPhone, loading: loadingPhones } = useAdminPhones();

  if (
    paymentsHistoryLoading ||
    botFeaturesLoading ||
    botStatusLoading ||
    workspaceLoading ||
    workspaceId ||
    !isPaymentFailed ||
    botIsManualPro ||
    loadingPhones ||
    planButtonType !== PlanButtonType.updateCard
  ) {
    return null;
  }

  return (
    <PaymentFailedCalloutView
      text={
        lastNotUtilizedPhone
          ? t('pages.GrowPage.PaymentFailedCallout.textForAdminWithPhone')
          : t('pages.GrowPage.PaymentFailedCallout.text')
      }
      buttonTitle={t('pages.GrowPage.PaymentFailedCallout.updateCardInfo')}
      className={className}
      onClick={() => {
        sendHomeTabEvent({ action: 'update card info click' });
        goToUpdateCard(Boolean(botFeatures?.dialogs_pricing_enabled));
      }}
    />
  );
};

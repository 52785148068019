import React from 'react';
import { Flex } from '@ui/Flex';
import { BroadcastListItem } from './BroadcastListItem/BroadcastListItem';
import { FilterButton } from './FilterButton';
import { BroadcastStatus, WhatsappBroadcastType } from '@globals';
import { Status } from './BroadcastListItem/Status/Status';
import { TypeTranslation } from '@ui/Type';
import { BroadcastTypeIcon } from './BroadcastListItem/MainInformation/MainInformation';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Spacer } from '@ui/Spacer';
import { BroadcastListItemLoader } from './BroadcastListItem/BroadcastListItemLoader';
import { DynamicListView } from '@components/DynamicListView';
import AutoSizer from 'react-virtualized-auto-sizer';
import { WhatsappBroadcasts_whatsappBroadcastList_broadcasts } from '../../hooks/@types/WhatsappBroadcasts';
import { CenteredLoader } from '@ui/Loader';
import { NoMessageFound } from './NoMessageFound/NoMessageFound';
import { TopView } from '../TopView';
import { EmptyState } from '../EmptyState/EmptyState';
import { useBroadcastTable } from './useBroadcastTable';
import css from './BroadcastTable.css';
import { logger } from './utils';
import { useCreateWhatsappTemplateInCfEnable } from '@utils/Whatsapp/hooks/useCreateWhatsappTemplateInCfEnable';

const STATUS_FILTERS = [
  {
    id: BroadcastStatus.DRAFT,
    node: <Status status={BroadcastStatus.DRAFT} />,
  },
  { id: BroadcastStatus.LIVE, node: <Status status={BroadcastStatus.LIVE} /> },
  {
    id: BroadcastStatus.PAUSED,
    node: <Status status={BroadcastStatus.PAUSED} />,
  },
  {
    id: BroadcastStatus.SCHEDULED,
    node: <Status status={BroadcastStatus.SCHEDULED} />,
  },
  { id: BroadcastStatus.SENT, node: <Status status={BroadcastStatus.SENT} /> },
];

const MESSAGE_FILTERS = [
  {
    id: WhatsappBroadcastType.ONE_TIME,
    node: (
      <Flex gapFactor={1} alignItems="center">
        <BroadcastTypeIcon icon="send" />
        <TypeTranslation i18nKey="pages.BotPage.ReengageWhatsappTab.filters.oneTime" />
      </Flex>
    ),
  },
  {
    id: WhatsappBroadcastType.TRIGGER,
    node: (
      <Flex gapFactor={1} alignItems="center">
        <BroadcastTypeIcon icon="connectBlock" />
        <TypeTranslation i18nKey="pages.BotPage.ReengageWhatsappTab.filters.triggered" />
      </Flex>
    ),
  },
  {
    id: WhatsappBroadcastType.WEEKLY,
    node: (
      <Flex gapFactor={1} alignItems="center">
        <BroadcastTypeIcon icon="bellPlus" />
        <TypeTranslation i18nKey="pages.BotPage.ReengageWhatsappTab.filters.recurring" />
      </Flex>
    ),
  },
];

const Row: React.FC<{
  data: {
    whatsappBroadcastsList: WhatsappBroadcasts_whatsappBroadcastList_broadcasts[];
  };
  index: number;
  style: React.CSSProperties;
}> = ({ index, data: { whatsappBroadcastsList }, style }) => {
  if (index >= whatsappBroadcastsList.length) {
    return (
      <Flex
        className={css.loader}
        alignItems="center"
        justifyContent="center"
        style={style}
      >
        <CenteredLoader />
      </Flex>
    );
  }

  const {
    stats,
    status,
    type,
    id,
    flowId,
    title,
    schedule,
    triggerSettings,
    statusUpdatedAt,
  } = whatsappBroadcastsList[index];
  return (
    <BroadcastListItem
      id={id}
      flowId={flowId}
      title={title ?? ''}
      schedule={schedule}
      stats={stats}
      status={status}
      type={type}
      triggerType={triggerSettings?.triggerType}
      style={style}
      statusUpdatedAt={statusUpdatedAt}
    />
  );
};

export const BroadcastsTable: React.FC = () => {
  const { t } = useSafeTranslation();
  const { isCreateWhatsappTemplateInCfEnable } =
    useCreateWhatsappTemplateInCfEnable();
  const {
    selectedStatusFilter,
    setSelectedStatusFilter,
    selectedMessageFilter,
    setSelectedMessageFilter,
    whatsappBroadcastsList,
    loading,
    fetchMore,
    isFetchMoreAvailable,
  } = useBroadcastTable();

  const isFilterSelected = Boolean(
    selectedStatusFilter || selectedMessageFilter,
  );

  if (!isFilterSelected && !whatsappBroadcastsList.length && !loading)
    return <EmptyState />;

  const getTable = () => {
    if (loading)
      return (
        <div>
          <BroadcastListItemLoader />
          <BroadcastListItemLoader />
          <BroadcastListItemLoader />
        </div>
      );

    if (isFilterSelected && !whatsappBroadcastsList.length)
      return <NoMessageFound />;

    return (
      <div className={css.listContainer} data-testid="whatsapp-reengage__table">
        <AutoSizer disableWidth>
          {({ height }) => (
            <DynamicListView
              className={css.dinamicList}
              height={height}
              width="100%"
              itemSize={92}
              itemData={{ whatsappBroadcastsList }}
              isItemLoaded={(index) => index < whatsappBroadcastsList.length}
              itemCount={
                whatsappBroadcastsList.length + (isFetchMoreAvailable ? 1 : 0)
              }
              threshold={2}
              loadMoreItems={fetchMore}
            >
              {Row}
            </DynamicListView>
          )}
        </AutoSizer>
      </div>
    );
  };

  return (
    <>
      <TopView />
      <Spacer factor={10} />
      <Flex flexDirection="column" className={css.container}>
        <Flex className={css.filterContainer}>
          <FilterButton
            title={t('pages.BotPage.ReengageWhatsappTab.filters.status')}
            items={STATUS_FILTERS}
            selectedItem={selectedStatusFilter}
            setSelectedItem={(id: BroadcastStatus) => {
              logger('change status filter', { id });
              setSelectedStatusFilter(selectedStatusFilter === id ? null : id);
            }}
          />
          <Spacer horizontalFactor={15} />
          <FilterButton
            title={
              isCreateWhatsappTemplateInCfEnable
                ? t('pages.BotPage.ReengageWhatsappTab.filters.message')
                : t('pages.BotPage.ReengageWhatsappTab.filters.message_old')
            }
            items={MESSAGE_FILTERS}
            selectedItem={selectedMessageFilter}
            setSelectedItem={(id: WhatsappBroadcastType) => {
              logger('change message filter', { id });
              setSelectedMessageFilter(
                selectedMessageFilter === id ? null : id,
              );
            }}
          />
        </Flex>
        <Spacer factor={5} />
        {getTable()}
      </Flex>
    </>
  );
};

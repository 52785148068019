import React from 'react';
import { SectionBox } from '@components/SectionBox';
import { useCurrentBotId } from '@utils/Routing';
import { useWhatsappConnected } from '@utils/Data/Whatsapp';
import { PlatformConnectionLoading } from '@pages/GrowPage/PlatformConnection/PlatformConnectionLoading/PlatformConnectionLoading';
import { WhatsappInitialized } from './WhatsappInitialized/WhatsappInitialized';
import { WhatsappUninitialized } from './WhatsappUninitialized/WhatsappUninitialized';
import { useAutoSelectWhatsappAccount } from './useAutoSelectWhatsappAccount';
import { OnboardingTourHTMLElementId } from '@globals';
import { WhatsappObtainedPhoneCallout } from './components/WhatsappObtainedPhoneCallout';

export const WhatsappAccount: React.FC = () => {
  const botId = useCurrentBotId();
  const { hasSharedAccounts, whatsappPhoneEntries, loading } =
    useWhatsappConnected(botId);
  const { phoneConnecting } = useAutoSelectWhatsappAccount({
    botId,
    whatsappPhoneEntries,
    loading,
  });

  const renderContent = () => {
    if (loading || phoneConnecting) {
      return <PlatformConnectionLoading data-testid="whatsapp__loading" />;
    }

    if (hasSharedAccounts) {
      return <WhatsappInitialized />;
    }

    return <WhatsappUninitialized />;
  };

  return (
    <SectionBox
      data-testid="whatsapp-account"
      id={OnboardingTourHTMLElementId.HomeTabWhatsappAccountBlock}
    >
      {!loading && <WhatsappObtainedPhoneCallout />}
      {renderContent()}
    </SectionBox>
  );
};

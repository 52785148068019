import React from 'react';
import { usePremiumTiersData } from '@utils/Data/Pricing/hooks';
import { PremiumBillingLayout } from './PremiumBillingLayout';
import { PremiumBillingTab } from './types';
import { PremiumBillingContainer } from './PremiumBillingContainer';
import { UsageTableContainer } from '../components/UsageTable';

export const PremiumBillingPage: React.FC = () => {
  const { data: premiumData } = usePremiumTiersData();

  return (
    <PremiumBillingLayout workspaceName={premiumData?.workspaceName}>
      {(tab) => {
        switch (tab) {
          case PremiumBillingTab.invoices:
            return <PremiumBillingContainer />;
          case PremiumBillingTab.usage:
            return <UsageTableContainer />;
          default:
            return null;
        }
      }}
    </PremiumBillingLayout>
  );
};

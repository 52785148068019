import { useEffect } from 'react';
import { EventEmitter } from '@utils/EventEmitter';
import { OnPageConnectedParams } from '@components/ConnectPageDialog/ConnectPageDialog';

type PageConnectListener = (params: OnPageConnectedParams) => void;

const REDIRECT_PAGE_CONNECTED_EVENT = 'REDIRECT_PAGE_CONNECTED_EVENT';

const pageConnectedEventEmitter = new EventEmitter();

const emitPageConnectedByRedirect = (payload: OnPageConnectedParams) => {
  return pageConnectedEventEmitter.emit(REDIRECT_PAGE_CONNECTED_EVENT, payload);
};

const onPageConnectedByRedirect = (listener: PageConnectListener) => {
  return pageConnectedEventEmitter.on(REDIRECT_PAGE_CONNECTED_EVENT, listener);
};

export const usePageConnectedByRedirectListener = (
  listener: PageConnectListener,
) => {
  useEffect(() => {
    return onPageConnectedByRedirect(listener);
  }, [listener]);
};

export const usePageConnectedByRedirectEmitter = () => {
  return { emitPageConnectedByRedirect };
};

import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { formatDateShort } from '@utils/DateTime/formatDate';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import React from 'react';

interface PaymentDateProps {
  date: number;
  error?: boolean;
}

export const PaymentDate: React.FC<PaymentDateProps> = ({ date, error }) => {
  const { t } = useSafeTranslation();
  const [day, year] = formatDateShort(date).split(',');

  return (
    <Flex flexDirection="column">
      <Flex alignItems="baseline" data-testid="payments-history__payment-date">
        <Type size="15px" color="black">
          {day},
        </Type>
        <Spacer factor={0} horizontalFactor={1} />
        <Type size="12px" color="black">
          {year}
        </Type>
      </Flex>
      {error && (
        <Type size="12px" color="red" weight="medium">
          {t('pages.Billing.Table.paymentFailed')}
        </Type>
      )}
    </Flex>
  );
};

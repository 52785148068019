import React from 'react';
import cn from 'classnames';
import { BroadcastStatus } from '@globals';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { IconButton } from '@ui/IconButton';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import css from './Actions.css';

interface ActionsProps {
  className: string;
  disabled: boolean;
  status: BroadcastStatus;
  onUnpause: VoidFunction;
  onPause: VoidFunction;
  onCopy: VoidFunction;
  onDelete: VoidFunction;
  onEdit: VoidFunction;
  isSetLiveDisabled?: boolean;
}

export const Actions: React.FC<ActionsProps> = ({
  className,
  disabled,
  status,
  onUnpause,
  onPause,
  onCopy,
  onDelete,
  onEdit,
  isSetLiveDisabled,
}) => {
  const { t } = useSafeTranslation();

  const getButton = () => {
    if (status === BroadcastStatus.DRAFT)
      return (
        <Button
          icon={<Icon noColor icon="edit" />}
          onClick={onEdit}
          data-testid="whatsapp-reengage__reengage-actions__edit"
        >
          {t('pages.BotPage.ReengageWhatsappTab.edit')}
        </Button>
      );

    if (status === BroadcastStatus.PAUSED)
      return (
        <Button
          icon={<Icon noColor icon="send" />}
          onClick={onUnpause}
          disabled={isSetLiveDisabled}
          data-testid="whatsapp-reengage__reengage-actions__unpause"
        >
          {t('pages.BotPage.ReengageWhatsappTab.setLive')}
        </Button>
      );

    if (status === BroadcastStatus.LIVE)
      return (
        <Button
          intent="text"
          className={css.secondaryButton}
          textClassName={css.secondaryButtonText}
          icon={<Icon noColor icon="pause" />}
          onClick={onPause}
          data-testid="whatsapp-reengage__reengage-actions__pause"
        >
          {t('pages.BotPage.ReengageWhatsappTab.pause')}
        </Button>
      );

    return null;
  };
  return (
    <Flex
      gapFactor={4}
      className={cn(css.container, className, disabled && css.disabled)}
    >
      {getButton()}
      <IconButton
        icon="copy"
        noColor
        className={css.secondaryButton}
        onClick={onCopy}
        data-testid="whatsapp-reengage__reengage-actions__copy"
      />
      <IconButton
        icon="delete"
        noColor
        className={css.secondaryButton}
        onClick={onDelete}
        data-testid="whatsapp-reengage__reengage-actions__delete"
      />
    </Flex>
  );
};

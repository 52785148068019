import React from 'react';
import * as css from './PhoneNumberView.css';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { ButtonUnstyled } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { useCopyText } from '@utils/Copy';
import { Color, ColorKey } from '@ui/_common/colors';

interface PhoneNumberViewProps {
  code: string;
  phone: string;
  color: ColorKey;
  borderColor?: ColorKey;
}

export const PhoneNumberView: React.FC<PhoneNumberViewProps> = ({
  code,
  phone,
  color,
  borderColor,
}) => {
  const { copy } = useCopyText({
    showToaster: true,
  });
  const boxStyle = {
    borderColor: Color[borderColor || color],
  };

  return (
    <Flex gapFactor={2}>
      <div className={css.number} style={boxStyle}>
        <Type
          color={color}
          weight="semibold"
          data-testid="purchase-phone__generated-number-country-code"
        >
          {code}
        </Type>
      </div>
      <ButtonUnstyled
        onClick={() => {
          copy(phone);
        }}
      >
        <Flex
          className={css.number}
          style={boxStyle}
          gapFactor={1}
          alignItems="center"
        >
          <Type
            color="accent1Normal"
            weight="semibold"
            data-testid="purchase-phone__generated-number-rest-number"
          >
            {phone}
          </Type>
          <Icon icon="copy" color="accent1Normal" />
        </Flex>
      </ButtonUnstyled>
    </Flex>
  );
};

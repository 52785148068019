import React, { useCallback } from 'react';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { Button, ButtonProps } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Icon } from '@ui/Icon';
import { Flex } from '@ui/Flex';
import * as css from './WhatsappAccountBody.css';
import { OnboardingTourHTMLElementId } from '@globals';
import {
  OnboardingState,
  OnboardingManualStateEmitter,
} from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';

export interface WhatsappAccountBodyProps extends ButtonProps {
  note: string;
  buttonTitle: string;
}

export const WhatsappAccountBody: React.FC<WhatsappAccountBodyProps> = ({
  buttonTitle,
  note,
  onClick,
  ...props
}) => {
  const { getValueDueToSize, isSmallScreenSize } = useDeviceMedia();

  const emitOnboardingEvent = useCallback(() => {
    OnboardingManualStateEmitter.emit(OnboardingState.abort);
  }, []);

  return (
    <>
      <Spacer factor={getValueDueToSize(2, 0)} horizontalFactor={4} />
      <Flex
        justifyContent={getValueDueToSize('flex-start', 'center')}
        className={css.wrapper}
      >
        {isSmallScreenSize ? (
          <>
            <Flex gapFactor={2}>
              <Icon icon="warning" color="accent3OrangeSecondary" size="24px" />
              <Type>{note}</Type>
            </Flex>
            <Spacer factor={3} />
          </>
        ) : (
          <Type>{note}</Type>
        )}
      </Flex>
      <Spacer factor={getValueDueToSize(2, 0)} horizontalFactor={4} />
      <Button
        id={
          OnboardingTourHTMLElementId.HomeTabWhatsappAccountBlockConnectionButton
        }
        onClick={(e) => {
          onClick?.(e);
          emitOnboardingEvent();
        }}
        className={css.button}
        {...props}
      >
        {buttonTitle}
      </Button>
      <Spacer factor={0} horizontalFactor={1} />
    </>
  );
};

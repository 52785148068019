import { Item } from '@ui/SimpleCombobox';
import { IconProps } from '@ui/Icon';
import { MenuItemType } from '@ui/Menu';
import { CSSProperties } from 'react';
import { TFunction } from 'i18next';
import { Platform } from '@globals';
import { FlowElementType } from './types';
import * as css from './FlowList.css';

export const NAV_PANEL_COLLAPSE_BUTTON_ID =
  'bot-navigation-panel__collapse-button';

export type AddFlowItemType = Item<{
  id: FlowElementType | string | Platform;
  title?: string;
  icon?: IconProps;
  type: MenuItemType;
  style?: CSSProperties;
  badgeName?: string;
}>;

const getAddPlatformFlowMenuItems = (
  t: TFunction,
): Record<Platform, AddFlowItemType> => ({
  [Platform.facebook]: {
    id: Platform.facebook,
    icon: { icon: 'messengerLogo', className: css.platformIcon, size: '16px' },
    title: t('pages.BotPage.FlowTab.FlowList.addFlowItems.facebook'),
    type: 'item',
    style: { height: '36px' },
  },
  [Platform.instagram]: {
    id: Platform.instagram,
    icon: { icon: 'directLogo', className: css.platformIcon, size: '16px' },
    title: t('pages.BotPage.FlowTab.FlowList.addFlowItems.instagram'),
    type: 'item',
    style: { height: '36px' },
  },
  [Platform.whatsapp]: {
    id: Platform.whatsapp,
    // TODO change logo
    icon: { icon: 'whatsappLogo', className: css.platformIcon, size: '16px' },
    title: t('pages.BotPage.FlowTab.FlowList.addFlowItems.whatsapp'),
    type: 'item',
    style: { height: '36px' },
  },
});

export const getAddPlatformFlowMenuItemsAsArray = (
  t: TFunction,
  isWhatsappEnabled: boolean,
) => {
  const items = Object.values(getAddPlatformFlowMenuItems(t));

  return isWhatsappEnabled
    ? items.filter(({ id }) => id === Platform.whatsapp)
    : items.filter(({ id }) => id !== Platform.whatsapp);
};

export const getAddFlowItemsInGroup = (
  t: TFunction,
  isWhatsappEnabled: boolean,
): AddFlowItemType[] => [
  ...(isWhatsappEnabled
    ? []
    : [
        {
          id: FlowElementType.template,
          icon: {
            icon: 'template',
            className: css.icon,
            size: '24px',
            noColor: true,
          },
          title: t('pages.BotPage.FlowTab.FlowList.addFlowItems.template'),
          type: 'item',
          style: { height: '36px' },
        } as AddFlowItemType,
      ]),
  ...getAddPlatformFlowMenuItemsAsArray(t, isWhatsappEnabled),
  {
    id: FlowElementType.createFlowForMe,
    icon: {
      icon: 'magic',
      className: css.icon,
      size: '24px',
      noColor: true,
    },
    title: t(
      'pages.BotPage.FlowTab.FlowList.addFlowItems.tailor_a_flow_for_me',
    ),
    type: 'item',
    style: { height: '36px' },
    fixedToFooter: true,
  } as AddFlowItemType,
];

export const getAddFlowItems = (
  t: TFunction,
  isWhatsappEnabled: boolean,
): AddFlowItemType[] => [
  {
    id: FlowElementType.group,
    icon: { icon: 'folder', className: css.icon, size: '24px', noColor: true },
    title: t('pages.BotPage.FlowTab.FlowList.addFlowItems.add_new_group'),
    type: 'item',
    style: { height: '36px' },
  },
  ...getAddFlowItemsInGroup(t, isWhatsappEnabled),
];

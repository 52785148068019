import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import {
  getBotTags,
  getBotTags_bot_tags,
  getBotTagsVariables,
} from './@types/getBotTags';
import { useCurrentBotId } from '@utils/Routing';

export type BotTag = Omit<getBotTags_bot_tags, '__typename'>;

export const BOT_TAGS_QUERY = gql`
  query getBotTags($botId: String) {
    bot(id: $botId) {
      id
      tags {
        id
        name
      }
    }
  }
`;

export const useBotTags = () => {
  const botId = useCurrentBotId();
  const { data } = useQuery<getBotTags, getBotTagsVariables>(BOT_TAGS_QUERY, {
    variables: {
      botId: botId!,
    },
    skip: !botId,
  });

  return {
    tags: data?.bot.tags || [],
  };
};

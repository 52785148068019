import React from 'react';
import cn from 'classnames';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { Input } from '@ui/Input';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Disableable } from '../types';
import * as commonCSS from './common.css';

export interface NameYourMessageControlProps extends Disableable {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  loading?: boolean;
}

export const NameYourMessageControl: React.FC<NameYourMessageControlProps> = ({
  value,
  loading,
  onChange,
  onBlur,
  disabled,
}) => {
  const { t } = useSafeTranslation();

  return (
    <PluginControlLabel
      label={t('pages.BotPage.ReengageWhatsappTab.panel.message.label')}
      description={
        !disabled &&
        t('pages.BotPage.ReengageWhatsappTab.panel.message.description')
      }
    >
      {() =>
        loading ? (
          <div className={cn(commonCSS.dropdown, commonCSS.dropdownLoader)}>
            <LoadingPlaceholder color="baseTertiary" width={124} height={8} />
          </div>
        ) : (
          <Input
            maxLength={255}
            className={commonCSS.dropdown}
            value={value}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            error={!value.length}
            data-testid="reengage-editor__title-input"
          />
        )
      }
    </PluginControlLabel>
  );
};

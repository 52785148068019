import React from 'react';
import { Modal } from '@services/index';
import { WhatsappSelectProps } from '../types';
import { CurrentPhoneToggle } from '../CurrentPhoneToggle/CurrentPhoneToggle';
import { MobilePhoneSelectDialog } from './MobilePhoneSelectDialog';

interface MobileWhatsappPhoneSelectProps extends WhatsappSelectProps {
  hasNoPhones?: boolean;
}

export const MobileWhatsappPhoneSelect: React.FC<MobileWhatsappPhoneSelectProps> =
  ({
    currentPhone,
    currentAccount,
    whatsappPhoneEntries,
    hasNoPhones,
    onAddPhone,
    onAddAccount,
    onPhoneChange,
    isConfirmed,
    handlePhoneVerificationClick,
  }) => {
    const showMobileSelectModal = () => {
      Modal.show(
        ({ close }) => (
          <MobilePhoneSelectDialog
            isConfirmed={isConfirmed}
            currentPhone={currentPhone}
            whatsappPhoneEntries={whatsappPhoneEntries}
            hasNoPhones={hasNoPhones}
            close={close}
            onAddPhone={onAddPhone}
            onAddAccount={onAddAccount}
            onPhoneChange={onPhoneChange}
            handlePhoneVerificationClick={handlePhoneVerificationClick}
          />
        ),
        {
          mobileAdaptive: true,
          mobileProps: {
            fitHeight: true,
          },
        },
      );
    };
    return (
      <CurrentPhoneToggle
        currentAccount={currentAccount}
        currentPhone={currentPhone}
        onClick={showMobileSelectModal}
        disabled={!onPhoneChange}
      />
    );
  };

import React, { useEffect } from 'react';
import { CollapsableEditorPanelViewProps } from '@components/FlowBuilder/EditorPanel/components/editors/EditorPanelView';
import { useWhatsappBroadcast } from '../hooks/useWhatsappBroadcast';
import {
  ReEngageEditorPanel,
  ReEngageEditorPanelProps,
} from './ReEngageEditorPanel';
import { WhatsappBroadcast_whatsappBroadcast } from '../hooks/@types/WhatsappBroadcast';
import { useBroadcastsTypeManager } from '../Broadcast';
import * as css from './ReEngageEditorPanelContainer.css';
import { extractGQLErrorData } from '@utils/GQL/utils';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import { useHistory } from 'react-router-dom';
import { log } from 'cf-common/src/logger';

export interface ReEngageEditorPanelContainerProps
  extends Omit<
    ReEngageEditorPanelProps,
    'defaultConfig' | 'loading' | 'startButtonDisabled'
  > {
  pluginConfigLoading: boolean;
  hasAnyErrors: boolean;
  flowId: string;
  showAside: boolean;
  setShowAside(showAside: boolean): void;
}

export const ReEngageEditorPanelContainer: React.FC<ReEngageEditorPanelContainerProps> =
  ({
    flowId,
    pluginConfigLoading,
    hasAnyErrors,
    showAside,
    setShowAside,
    ...props
  }) => {
    const botId = useCurrentBotId();
    const history = useHistory();
    const {
      data,
      loading: broadcastLoading,
      error,
    } = useWhatsappBroadcast(props.broadcastId);
    const { broadcastsTypeManager } = useBroadcastsTypeManager();

    useEffect(() => {
      if (error) {
        history.push(getTabLink(BotTabs.reEngage, botId));
      }
      if (extractGQLErrorData(error)?.status !== 404) {
        log.warn({ error, msg: 'reEngage loading error' });
      }
    }, [botId, error, history]);

    const loading = !data?.whatsappBroadcast || broadcastLoading;
    const defaultConfig = loading
      ? /**
         * Костыль. нужен для того чтобы был хоть какой то конфиг, из которого можно было брать поля.
         * Сами значения полей не важны, тк модалка находится в состоянии загрузки
         */
        broadcastsTypeManager
          .getDefaultBroadcast()
          .getDefaultConfig({} as WhatsappBroadcast_whatsappBroadcast)
      : data?.whatsappBroadcast;

    return (
      <CollapsableEditorPanelViewProps
        showAside={showAside}
        setShowAside={setShowAside}
        className={css.wrapper}
        width={464}
      >
        <ReEngageEditorPanel
          key={data?.whatsappBroadcast.id ?? 'uninitialized'}
          {...props}
          loading={loading || pluginConfigLoading}
          startButtonDisabled={hasAnyErrors}
          defaultConfig={defaultConfig}
        />
      </CollapsableEditorPanelViewProps>
    );
  };

import {
  ConnectPageField,
  ConnectPageOrigin,
  useConnectPage,
} from '@components/ConnectPageDialog';
import { useUnpausePlan } from '@components/DialogsPricing/hooks';
import { ChargingEntityType, PaymentDetailsStatus, Platform } from '@globals';
import { BotActionType } from '@ui/BotListItem/BotAction/BotAction';
import { WorkspacesBots_workspacesBots_workspaces_bots } from '@utils/Data/Workspaces/@types/WorkspacesBots';
import { useRolePermission } from '@utils/Roles';
import { BotTabs, getPlansPageUrl, getTabLink } from '@utils/Routing';
import { redirect } from '@utils/UrlUtils';
import { noop } from 'lodash-es';
import { Permission } from '@common/services/RoleService';
import { testAllowedPlatforms } from '@utils/Platform/testAllowedPlatforms';
import { useCallback } from 'react';
import { sendEvent } from '@utils/Analytics';
import { DeepLinksMode } from '../../../../DeepLinks/types';
import { useHistory } from 'react-router-dom';

export const useBotActions = (
  {
    id: botId,
    status,
    dialogsPricing,
    pro,
    allowedPlatforms,
  }: WorkspacesBots_workspacesBots_workspaces_bots,
  isWorkspace: boolean,
) => {
  const page = status?.page_info;
  const isWhatsappBot =
    allowedPlatforms &&
    testAllowedPlatforms([Platform.whatsapp], allowedPlatforms);

  const history = useHistory();

  const { allowed: isProPermissionEdit } = useRolePermission({
    domain: 'pro',
    can: Permission.EDIT,
    botId,
  });
  const { allowed: isGrowPermissionEdit } = useRolePermission({
    domain: 'grow',
    can: Permission.EDIT,
    botId,
  });

  const { connectPage } = useConnectPage({
    botId,
    urlParams: {
      [ConnectPageField.origin]: ConnectPageOrigin.botList,
      [ConnectPageField.botId]: botId,
    },
  });

  const connectPhone = useCallback(() => {
    sendEvent({
      category: 'bot list',
      action: 'connect',
      label: 'bot',
      propertyBag: { isWhatsappBot },
    });
    history.push(
      getTabLink(BotTabs.home, botId, {
        dlMode: DeepLinksMode.connectWhatsapp,
      }),
    );
  }, [botId, history, isWhatsappBot]);

  const { unpausePlan, loading } = useUnpausePlan(botId);

  if (!page && isWhatsappBot)
    return {
      actionType: BotActionType.connectPhone,
      onActionClick: connectPhone,
      loading,
    };

  if (!page && isGrowPermissionEdit && !isWhatsappBot)
    return {
      actionType: BotActionType.connectPage,
      onActionClick: connectPage,
      loading,
    };

  if (isWorkspace)
    return {
      actionType: BotActionType.none,
      onActionClick: noop,
      loading,
    };

  if (
    page &&
    dialogsPricing.status === PaymentDetailsStatus.trial &&
    isProPermissionEdit &&
    !pro?.manual
  )
    return {
      actionType: BotActionType.upgradeToPro,
      onActionClick: () => {
        redirect(
          getPlansPageUrl(
            botId,
            dialogsPricing.charging_entity_type === ChargingEntityType.dialogs,
            'bots-list',
          ),
        );
      },
      loading,
    };

  if (page && dialogsPricing.hasDebt && isProPermissionEdit && !pro?.manual)
    return {
      actionType: BotActionType.updatePayments,
      onActionClick: () => {
        redirect(
          getPlansPageUrl(
            botId,
            dialogsPricing.charging_entity_type === ChargingEntityType.dialogs,
            'bots-list',
          ),
        );
      },
      loading,
    };
  if (pro?.is_paused && isProPermissionEdit)
    return {
      actionType: BotActionType.unpause,
      onActionClick: unpausePlan,
      loading,
    };

  return {
    actionType: BotActionType.none,
    onActionClick: noop,
    loading,
  };
};

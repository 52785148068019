import { Weekdays } from '@utils/DateTime/types';
import i18next from 'i18next';
import memoize from 'lodash-es/memoize';
import { Repeat } from '../types';

export const getRepeatItems = memoize(() => {
  return [
    {
      id: Repeat.none,
      title: i18next.t(
        'pages.BotPage.ReengageWhatsappTab.panel.repeat.type.none',
      ),
    },
    {
      id: Repeat.everyDay,
      title: i18next.t(
        'pages.BotPage.ReengageWhatsappTab.panel.repeat.type.everyDay',
      ),
    },
    {
      id: Repeat.weekends,
      title: i18next.t(
        'pages.BotPage.ReengageWhatsappTab.panel.repeat.type.weekends',
      ),
    },
    {
      id: Repeat.everyMonth,
      title: i18next.t(
        'pages.BotPage.ReengageWhatsappTab.panel.repeat.type.everyMonth',
      ),
    },
    {
      id: Repeat.workdays,
      title: i18next.t(
        'pages.BotPage.ReengageWhatsappTab.panel.repeat.type.workdays',
      ),
    },
    {
      id: Repeat.custom,
      title: i18next.t(
        'pages.BotPage.ReengageWhatsappTab.panel.repeat.type.custom',
      ),
    },
  ];
});

export const getWeekdaysShort = memoize(() => [
  {
    id: Weekdays.monday,
    name: i18next.t(
      'pages.BotPage.ReengageWhatsappTab.panel.repeat.weekdays.mon',
    ),
  },
  {
    id: Weekdays.tuesday,
    name: i18next.t(
      'pages.BotPage.ReengageWhatsappTab.panel.repeat.weekdays.tue',
    ),
  },
  {
    id: Weekdays.wednesday,
    name: i18next.t(
      'pages.BotPage.ReengageWhatsappTab.panel.repeat.weekdays.wed',
    ),
  },
  {
    id: Weekdays.thursday,
    name: i18next.t(
      'pages.BotPage.ReengageWhatsappTab.panel.repeat.weekdays.thu',
    ),
  },
  {
    id: Weekdays.friday,
    name: i18next.t(
      'pages.BotPage.ReengageWhatsappTab.panel.repeat.weekdays.fri',
    ),
  },
  {
    id: Weekdays.saturday,
    name: i18next.t(
      'pages.BotPage.ReengageWhatsappTab.panel.repeat.weekdays.sat',
    ),
  },
  {
    id: Weekdays.sunday,
    name: i18next.t(
      'pages.BotPage.ReengageWhatsappTab.panel.repeat.weekdays.sun',
    ),
  },
]);

import React from 'react';
import { formatPrice } from '@utils/Format';
import { getTierNameByType } from '@components/DialogsPricing/constants';
import { getTooltipContent } from '../utils';
import { useBillingData } from '../../BotSectionView/Data/useBillingData';
import { useBotSectionData } from '../../BotSectionView/Data/useBotSectionData';
import { formatPricingDate } from '@utils/DateTime/formatDate';
import { NextChargeDetailsView } from './NextChargeDetailsView';
import { PlanTooltip } from '../PlanTooltip';
import { useTimeLimitedTrial } from '@utils/Data/Pricing/useTimeLimitedTrial';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';

const Tooltip: React.FC = ({ children }) => {
  const { pricing, tier } = useBillingData();
  const { isPaused, pausePricingAmount } = useBotSectionData();

  if (!pricing || !tier) {
    return null;
  }

  const { invoice_total, adjustment_fee, currency, next_tier } = pricing;

  const tierName = getTierNameByType(next_tier || tier.type);

  const tooltipContent = getTooltipContent({
    isPaused,
    invoice_total,
    currency,
    tierName,
    pausePricingAmount,
    adjustmentPrice: adjustment_fee?.price,
  });

  return <PlanTooltip tooltipContent={tooltipContent}>{children}</PlanTooltip>;
};

export const NextChargeDetails: React.FC = () => {
  const { pricing, tier } = useBillingData();
  const { isPaused, pausePricingAmount } = useBotSectionData();
  const { currentTierTimeLimitedTrial, trialRemainingDays, trialEndDate } =
    useTimeLimitedTrial();
  const { t } = useSafeTranslation();

  if (!pricing || !tier) {
    return null;
  }

  if (currentTierTimeLimitedTrial) {
    if (trialRemainingDays === null || typeof trialEndDate !== 'number')
      return null;
    return trialRemainingDays <= 0 ? (
      <Type size="15px" color="accent4Secondary">
        {t('pages.Pricing.ended')}
      </Type>
    ) : (
      <Type
        size="15px"
        color={trialRemainingDays < 3 ? 'accent3Orange' : 'black'}
      >
        {t('pages.Pricing.dayLeft', { count: trialRemainingDays })}
      </Type>
    );
  }

  const { next_prepayment_date, invoice_total, currency } = pricing;

  const date = formatPricingDate(next_prepayment_date);

  const price = isPaused ? pausePricingAmount : invoice_total;
  const amount = price ? formatPrice(price, { currency }) : null;

  if (!amount) {
    return null;
  }

  return (
    <NextChargeDetailsView tooltip={Tooltip} amount={amount} date={date} />
  );
};

import gql from 'graphql-tag';

export const BOT_PRICING_DATA_QUERY = gql`
  query BotPricingDataQuery($botId: String!) {
    bot(id: $botId) {
      id
      pricingData {
        currentMaxUsers
        currency
        currentPrice
        pricing
        needPrice
        adjustmentFee {
          price
          currency
        }
      }
    }
  }
`;

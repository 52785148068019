import { NestedMenuItem } from '@ui/NestedMenu/types';

export interface BotListMenuItemType<ID extends string = string>
  extends NestedMenuItem<BotListMenuItemType<ID>> {
  id: ID;
  workspaceId?: string;
  onClick?: () => void;
}

export interface MoveToWokspaceFunction {
  (workspaceID: string, botId: string): void;
}

export enum BotListMenuItem {
  leave = 'leave',
  moveTo = 'moveTo',
  manageUsers = 'manageUsers',
  rename = 'rename',
  copy = 'copy',
  copyToWorkspace = 'copyToWorkspace',
  delete = 'delete',
  removeFromWorkspace = 'removeFromWorkspace',
}

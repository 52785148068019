import { FeaturesPromoGallery } from '@components/FeaturesPromoGallery/FeaturesPromoGallery';
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useApolloClient } from 'react-apollo';
import { useLocation, useParams } from 'react-router-dom';
import { InitAuthProps } from '@utils/AuthFlow';
import { LeftNavigationPanel } from '@components/LeftNavigationPanel';
import { Page } from '@ui/Page';
import { Flex } from '@ui/Flex';
import { PageContent } from '@ui/PageContent';
import { RoleProvider } from '@utils/Roles';
import {
  BotPageRouteParams,
  BotTabs,
  useCurrentBotId,
  useCurrentRoutePath,
  useCurrentTab,
  useIsFirstSessionTab,
} from '@utils/Routing';
import {
  ServerStorageItemKeys,
  useServerStorageForBot,
} from '@utils/ServerStorage';
import { useWhiteLabelAgencyStyles } from '@utils/WhiteLabel/useWhiteLabelAgencyStyles';
import { useBotFeatures } from '@utils/Data/Bot';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import {
  connectPage,
  ConnectPageField,
  ConnectPageOrigin,
} from '@components/ConnectPageDialog';
import { useDefaultAnswerToAiDialog } from '@components/dialogs/DefaultAnswerToAiDialog';
import { usePlanLimitReachedDialog } from '@components/DialogsPricing/hooks';
import { GlobalLoader } from '@components/GlobalLoader/GlobalLoader';
import { Oops } from '../Oops';
import { BOT_CONTENT_ELEMENT_ID } from './consts';
import { useRedirectToAvailableTabIfNeed } from '@common/services/NgRoutePermissionsService';
import { useRedirectToBotListIfBotNotFound } from './HomeTab/hooks/useRedirectToBotListIfBotNotFound';
import { SET_BOT_LAST_OPENED_DATE } from '@common/services/GQLqueries/BotGQService.const';
import {
  SetBotLastOpenedDate,
  SetBotLastOpenedDateVariables,
} from '@common/services/GQLqueries/@types/SetBotLastOpenedDate';
import { useAdminFeatures } from '@utils/Data/Admin';
import { useBotWorkspaceId } from '@utils/Data/Bot/useBotWorkspaceId';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { PageHeader } from '@components/PageHeader';
import { usePageConnectedByRedirectEmitter } from './events';
import { sendHitOnce } from '@utils/Analytics';
import { Onboarding } from '@components/Onboarding/Onboarding';
import { OnboardingProvider } from '@components/Onboarding/OnboardingContext';
import * as css from './BotPage.css';
import { BotPageRouting } from './BotPageRouting';
import { useCreateWhatsappTemplateInCfEnable } from '@utils/Whatsapp/hooks/useCreateWhatsappTemplateInCfEnable';

const useAsideShowByTab = (currentTab: string | undefined) => {
  const botId = useCurrentBotId();
  const useAsideWithoutSave = useState<boolean>(true);
  const useAsideWithSave = useServerStorageForBot<boolean>(
    ServerStorageItemKeys.isBotPageAsideVisible,
    botId,
  );
  return currentTab === BotTabs.flows ? useAsideWithSave : useAsideWithoutSave;
};

export const useConnectPageDefaultListener = (botId: string) => {
  const location = useLocation();
  const { emitPageConnectedByRedirect } = usePageConnectedByRedirectEmitter();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const queryOrigin = urlParams.get(ConnectPageField.origin);
    if (queryOrigin === ConnectPageOrigin.default) {
      connectPage({
        botId,
        onPageConnected: emitPageConnectedByRedirect,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const NEW_BACKGROUND_PAGES = [BotTabs.plans, BotTabs.billing];

export const BotPage: React.FC<InitAuthProps> = (props) => {
  const path = useCurrentRoutePath(props.match);
  const { botId } = useParams<BotPageRouteParams>();
  const currentTab = useCurrentTab();
  const { isWhatsappEnabled } = useWhatsappEnabled();
  const client = useApolloClient();

  const { isSmallScreenSize } = useDeviceMedia();
  const { data, loading: WLLoading } = useWhiteLabelAgencyStyles();

  useRedirectToBotListIfBotNotFound();

  const {
    botFeatures: { dialogs_pricing_enabled, livechat_v2 } = {},
    botFeaturesLoading,
  } = useBotFeatures(botId!);
  const [workspaceId, workspaceIdLoading] = useBotWorkspaceId(botId);

  const isFirstSessionTab = useIsFirstSessionTab();

  const { adminFeatures, adminFeaturesLoading } = useAdminFeatures();

  useRedirectToAvailableTabIfNeed();

  useConnectPageDefaultListener(botId!);

  useDefaultAnswerToAiDialog([BotTabs.automate, BotTabs.keywords]);

  usePlanLimitReachedDialog();

  const {
    isCreateWhatsappTemplateInCfEnable = false,
    loading: createWhatsappTemplateInCfEnableLoading,
  } = useCreateWhatsappTemplateInCfEnable();

  useEffect(() => {
    if (isWhatsappEnabled) {
      sendHitOnce('visit_whatsapp_bot');
    }
  }, [isWhatsappEnabled]);

  useEffect(() => {
    if (botId) {
      client.mutate<SetBotLastOpenedDate, SetBotLastOpenedDateVariables>({
        mutation: SET_BOT_LAST_OPENED_DATE,
        variables: { botId },
      });
    }
  }, [botId, client]);

  const [showAsideOpt, setShowAside, updateState] =
    useAsideShowByTab(currentTab);

  if (
    (updateState && updateState.loading) ||
    WLLoading ||
    botFeaturesLoading ||
    adminFeaturesLoading ||
    workspaceIdLoading ||
    createWhatsappTemplateInCfEnableLoading
  ) {
    return <GlobalLoader />;
  }

  if (!botId) {
    return <Oops reason="bot id missed in url" />;
  }

  const isTabLeftNavigationDisabled = isFirstSessionTab;

  const getPageHeader = () => {
    if (!isSmallScreenSize) {
      return isTabLeftNavigationDisabled ? <PageHeader /> : null;
    }
    return <div className={css.dummyHeader} />;
  };

  return (
    <Page>
      <RoleProvider botId={botId}>
        <OnboardingProvider>
          <PageContent
            className={cn(css.botPage, {
              [css.botPageNewBackground]: currentTab
                ? NEW_BACKGROUND_PAGES.includes(currentTab as BotTabs)
                : false,
            })}
          >
            {(!isTabLeftNavigationDisabled || isSmallScreenSize) && (
              <LeftNavigationPanel whiteLabelAgencyStyles={data} />
            )}
            <div id="page-content" className={css.pageContent}>
              {getPageHeader()}
              <Flex id={BOT_CONTENT_ELEMENT_ID} className={css.botContent}>
                <BotPageRouting
                  path={path}
                  dialogPricingEnabled={dialogs_pricing_enabled}
                  livechatV2={livechat_v2}
                  workspaceId={workspaceId}
                  botId={botId}
                  showAside={showAsideOpt ?? true}
                  setShowAside={setShowAside}
                  history={props.history}
                  adminFeatures={adminFeatures}
                  isWhatsappEnabled={isWhatsappEnabled}
                  isCreateWhatsappTemplateInCfEnable={
                    isCreateWhatsappTemplateInCfEnable
                  }
                />
              </Flex>
              <Onboarding />
            </div>
          </PageContent>
        </OnboardingProvider>
      </RoleProvider>
      <FeaturesPromoGallery />
    </Page>
  );
};

import { Flex } from '@ui/Flex';
import React from 'react';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Spacer } from '@ui/Spacer';
import { ReactComponent as PicSvg } from './images/pic.svg';
import * as css from './KeywordsGroupsEmpty.css';

export const KeywordsGroupsNotFound: React.FC = () => {
  const { t } = useSafeTranslation();

  return (
    <Flex justifyContent="center" alignItems="center">
      <Flex flexDirection="column" alignItems="center" className={css.box}>
        <Spacer factor={20} />
        <PicSvg />
        <Spacer factor={6} />
        <Type size="24px" weight="semibold">
          {t('pages.Keywords.KeywordGroups.empty.notFound')}
        </Type>
        <Spacer factor={4} />
        <Type size="15px">
          {t('pages.Keywords.KeywordGroups.empty.notFoundText')}
        </Type>
        <Spacer factor={20} />
      </Flex>
    </Flex>
  );
};

import React, { useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { YouTubeFramePlayer } from '@components/YouTubeFramePlayer';
import { Button } from '@ui/Button';
import { sendEvent } from '@utils/Analytics';
import { Icon } from '@ui/Icon';
import { PortalForYoutubePlayer } from '@components/PortalForYoutubePlayer/PortalForYoutubePlayer';
import { useCurrentBotId, useCurrentBlockId } from '@utils/Routing';
import { useComponentWillUnmount } from '../../../../../../common/src/utils/hooks';
import * as css from './FlowBuilderVideo.css';

const FLOW_BUILDER_VIDEO_ID = 'uVmQRB11WUI';

interface FlowBuilderVideoPlayerProps {
  containerWidth: number | undefined;
}

const PLAYER_POSITION = {
  bottom: 0,
  left: 0,
};

export const FlowBuilderVideoPlayer = React.memo<FlowBuilderVideoPlayerProps>(
  ({ containerWidth }) => {
    const { t } = useSafeTranslation();
    const botId = useCurrentBotId();
    const blockId = useCurrentBlockId();
    const [isVisibleVideo, setIsVisibleVideoState] = useState(false);

    const sendEventVideoVisibility = (isVisibleVideoValue: boolean) => {
      sendEvent({
        category: 'automate',
        action: `flowbuilder video ${
          isVisibleVideoValue ? 'opened' : 'closed'
        }`,
        propertyBag: {
          botId,
          blockId,
        },
      });
    };

    const setIsVisibleVideo = (value: boolean) => {
      if (value !== isVisibleVideo) {
        sendEventVideoVisibility(value);
      }
      setIsVisibleVideoState(value);
    };

    useComponentWillUnmount(() => {
      if (isVisibleVideo) {
        sendEventVideoVisibility(false);
      }
    });

    const onChangeSize = () => {
      sendEvent({
        category: 'automate',
        action: `flowbuilder video changed size`,
        propertyBag: {
          botId,
          blockId,
        },
      });
    };
    return isVisibleVideo ? (
      <PortalForYoutubePlayer
        style={{ ...PLAYER_POSITION, zIndex: 'var(--zindex-over-mask)' as any }}
      >
        <YouTubeFramePlayer
          videoContainerClassName={css.videoContainerClassName}
          initialPosition={PLAYER_POSITION}
          videoId={FLOW_BUILDER_VIDEO_ID}
          onChangeSize={onChangeSize}
          onClose={() => setIsVisibleVideo(false)}
        />
      </PortalForYoutubePlayer>
    ) : (
      <Button
        intent="secondary"
        className={css.playVideoButtonTitle}
        icon={<Icon icon="play" size="28px" noColor />}
        style={{ width: containerWidth }}
        onClick={() => setIsVisibleVideo(true)}
      >
        {t('pages.Automate.FlowBuilderVideo.playVideoButtonTitle')}
      </Button>
    );
  },
);

import React, { useCallback, useEffect, useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { log } from 'cf-common/src/logger';
import { propEq } from 'ramda';
import { sendEvent } from '@utils/Analytics';
import { Messages, toaster } from '@services/MessageService';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { DefaultDialog } from '@ui/Dialog';
import { Category, CategoryContext } from '@components/ItemsModal';
import { useComponentWillUnmount } from 'cf-common/src/utils/hooks';
import { TemplatesListModal } from './components/TemplatesListModal';
import { TemplateDetailsModal } from './components/TemplateDetailsModal';
import { Template, TemplateId } from './types';
import { getTemplates } from './templates';
import { Platform } from '@globals';
import { useCloneTemplate } from './hooks/useCloneTemplate';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import * as css from './TemplatesModal.css';

export type OnCreateBlankFlow = (platform: Platform) => void;

export interface TemplateModalHandlers {
  onCreateBlankFlow: OnCreateBlankFlow;
  onTemplateSelect: (template: Template | null) => void;
}

interface TemplatesModalProps {
  templateId?: TemplateId;
  groupId: string;
  onClose(): void;
  onCreateBlankFlow: OnCreateBlankFlow;
  onCreated(): void;
}

export const TemplatesModal: React.FC<TemplatesModalProps> = ({
  templateId,
  groupId,
  onClose,
  onCreateBlankFlow,
  onCreated,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const { isConnected } = usePageConnected();
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null,
  );
  const [activeCategory, setActiveCategory] = useState<Category>(
    Category.facebook,
  );
  const { t } = useSafeTranslation();
  const cloneTemplateParams = useCloneTemplate({
    groupId,
    onCreated,
    onError: (error) => {
      toaster.error({
        payload: {
          message: Messages.somethingWentWrong,
        },
      });
      log.error({
        error,
        data: { label: 'flow_builder_template' },
      });
    },
  });

  const templates = getTemplates();

  useEffect(() => {
    const template = templates.find(propEq('id', templateId));
    if (template) {
      setSelectedTemplate(template);
    }
  }, [templateId, templates]);

  useComponentWillUnmount(() => {
    sendEvent({
      category: 'templates first session',
      action: selectedTemplate ? 'close second screen' : 'close first screen',
      propertyBag: Object.assign(
        {
          'page connected': isConnected.toString(),
        },
        selectedTemplate
          ? {
              templateid: selectedTemplate!.id,
              templatename: selectedTemplate!.title,
              category: selectedTemplate!.category,
              level: selectedTemplate!.level,
            }
          : undefined,
      ),
    });
  });

  const onDismiss = useCallback(() => {
    sendEvent({
      category: 'templates first session',
      action: 'close button click',
    });
    onClose();
  }, [onClose]);

  const onChangeTemplate = useCallback(
    (templateId) => {
      if (templateId) {
        setSelectedTemplate(null);
        setTimeout(() => {
          setSelectedTemplate(templates.find(propEq('id', templateId)) ?? null);
        });
      }
    },
    [templates],
  );

  const dialogStyle = {
    backgroundColor: 'var(--white)',
    margin: !isSmallScreenSize ? '16px' : 0,
    ...(!selectedTemplate && {
      width: isSmallScreenSize ? '100%' : 'calc(100vw - 32px)',
      height: '650px',
      maxWidth: '1048px',
    }),
    ...(selectedTemplate && {
      height: isSmallScreenSize ? '100%' : 'calc(100vh - 10vw)',
      width: '90vw',
    }),
  };

  const closeButtonStyle = {
    backgroundColor: 'var(--white)',
  };

  const header =
    selectedTemplate?.title || t('modernComponents.TemplatesModal.title');

  return (
    <DefaultDialog
      header={header}
      onDismiss={onDismiss}
      dialogStyle={dialogStyle}
      closeButtonStyle={closeButtonStyle}
      mobileAdaptive={isSmallScreenSize}
      contentClassName={css.content}
    >
      <CategoryContext.Provider
        value={{
          activeCategory,
          setActiveCategory,
        }}
      >
        {selectedTemplate ? (
          <TemplateDetailsModal
            template={selectedTemplate}
            onChangeTemplate={onChangeTemplate}
            onBackToList={() => setSelectedTemplate(null)}
            {...cloneTemplateParams}
          />
        ) : (
          <TemplatesListModal
            templates={templates}
            onTemplateSelect={setSelectedTemplate}
            onCreateBlankFlow={onCreateBlankFlow}
            onClose={onClose}
          />
        )}
      </CategoryContext.Provider>
    </DefaultDialog>
  );
};

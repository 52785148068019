import React from 'react';
import { BotTabPlaceholder } from '@components/BotTab';
import Icon from './images/rocket.png';
import * as css from './TabPlaceholders.css';

export const RemovedTabPlaceholder: React.FC = () => {
  return (
    <BotTabPlaceholder
      icon={<img src={Icon} className={css.icon} alt="removed" />}
      title={window.i18next.t(
        'RemovedTabPlaceholder-string--324-time-to-build-something-awesome',
      )}
      subtitle={
        <span>
          {window.i18next.t(
            'RemovedTabPlaceholder-JSXText-1885-create-a-new-flow-or-copy-an-existing',
          )}
          <br />
          {window.i18next.t(
            'RemovedTabPlaceholder-JSXText-3994-flow-to-get-started',
          )}
        </span>
      }
    />
  );
};

import { combineRefs } from '@utils/combineRefs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { HoverDisclosure } from '@ui/HoverDisclosure';
import { Flex } from '@ui/Flex';
import { Button, ButtonUnstyled } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import { CollapsibleSection } from '@ui/CollapsibleSection';
import { BotTabs, useCurrentBotId, useFlowTabParams } from '@utils/Routing';
import { Modal } from '@ui/Modal';
import { DefaultDialog } from '@ui/Dialog/DefaultDialog';
import { Type } from '@ui/Type';
import { useToaster } from '@ui/Toaster/ToasterProvider';
import { Loader } from '@ui/Loader';
import {
  DndDraggable,
  DndDraggableAnchor,
  DndDroppable,
  useDnd,
} from '@utils/Dnd';
import { Autofocus } from '@ui/Autofocus';
import { sendEvent } from '@utils/Analytics';
import { isEllipsisDetect } from '@utils/DOM/isEllipsisDetect';
import { Icon } from '@ui/Icon';
import { FlowPlaceholder } from '@utils/Data/Flow';
import { FlowGroupsQuery_bot_flow_groups } from '@utils/Data/Flow/@types/FlowGroupsQuery';
import { Tooltip2 } from '@ui/Tooltip2';
import { plurals } from '@utils/Plurals';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { Platform } from '@globals';
import { AddElementMenu } from '../AddElementMenu';
import { getLeftShift } from '../utils';
import { FlowGroupMenu } from './FlowGroupMenu';
import {
  useCloneFlowGroup,
  useFlowGroupUpdate,
  useRemoveFlowGroup,
  useUpdateCollapsedFlowGroup,
} from './FlowGroupMutations';
import { CloneFlowGroupModal } from './CloneFlowGroupModal';
import { ShareGroupModal } from './ShareGroupModal';
import { FlowItem } from '../FlowItem';
import { EditFlowItem } from '../EditFlowItem';
import {
  FlowAddParams,
  FlowElementType,
  FlowWithTemplateAddParams,
} from '../types';
import { FlowItemPlaceholder } from '../FlowItemPlaceholder';
import { FlowGroupMenuType } from './types';
import * as css from './FlowGroup.css';
import { getAddFlowItemsInGroup } from '../constants';

interface FlowGroupProps {
  group: FlowGroupsQuery_bot_flow_groups;
  nestedLevel: number;
  flowsOrder?: string[];
  isDragging?: boolean;
  isDefault?: boolean;
  isLast: boolean;
  handleBlankFlowAdd(params: FlowAddParams): void;
  handleFlowAddWithTemplate(params: FlowWithTemplateAddParams): void;
  onEntryPointAdd(flowId: string, platform?: Platform | null): void;
  addingElementTitle?: string;
  refetchFlows: () => void;
  dndShouldBeDisabled?: boolean;
  readOnly: boolean;
}

export const FlowGroup: React.FC<FlowGroupProps> = ({
  group,
  nestedLevel,
  flowsOrder,
  isDefault,
  isLast,
  handleBlankFlowAdd,
  handleFlowAddWithTemplate,
  onEntryPointAdd,
  addingElementTitle,
  refetchFlows,
  dndShouldBeDisabled,
  readOnly,
}) => {
  const { t } = useSafeTranslation();
  const { dndState, setDisabledDnd } = useDnd();
  const botId = useCurrentBotId();
  const flowTabParams = useFlowTabParams();
  const { addToaster } = useToaster();
  const history = useHistory();
  const [innerTitle, setInnerTitle] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [shownModalId, setShownModalId] = useState<FlowGroupMenuType>(
    FlowGroupMenuType.none,
  );
  const [loading, setLoading] = useState(false);
  const itemDivRef = useRef<HTMLDivElement | null>(null);
  const titleRef = useRef<HTMLSpanElement | null>(null);
  const { isWhatsappEnabled } = useWhatsappEnabled();

  const onRemoveModalDismiss = useCallback(() => {
    setShownModalId(FlowGroupMenuType.none);
  }, []);
  const [toasterTargetBotId, setToasterTargetBotId] = useState<string | null>(
    null,
  );
  const changeExtendedTimeoutRef = useRef(setTimeout(() => true, 1000));

  const [extendedWhileDnd, setExtendedWhileDnd] = useState(false);

  const { onUpdateFlowGroup } = useFlowGroupUpdate({
    groupId: group.id,
    onCompleted: (data) => {
      sendEvent({
        category: 'flow navigation',
        action: 'rename',
        label: 'group',
        propertyBag: {
          botId,
          groupId: group.id,
          title: data.updateFlowGroup.title,
        },
      });
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
      setInnerTitle(group.title);
      addToaster({
        type: 'error',
        content: (
          <div style={{ minWidth: '300px', whiteSpace: 'nowrap' }}>
            {window.i18next.t('FlowGroup-JSXText-9988-changing-group')}
            {group.title}
            {window.i18next.t(
              'FlowGroup-JSXText--107-failed-please-try-again-later',
            )}
          </div>
        ),
        timeout: 3000,
        closeButton: true,
      });
    },
  });

  const { onUpdateCollapsedFlowGroup } = useUpdateCollapsedFlowGroup({
    groupId: group.id,
  });

  const { onRemoveFlowGroup } = useRemoveFlowGroup({
    groupId: group.id,
    onCompleted: () => {
      refetchFlows();
      addToaster({
        type: 'info',
        content: (
          <div style={{ minWidth: '300px', whiteSpace: 'nowrap' }}>
            {window.i18next.t('FlowGroup-JSXText-7249-group')}
            {group.title}
            {window.i18next.t(
              'FlowGroup-JSXText-5019-was-successfully-removed',
            )}
          </div>
        ),
        timeout: 3000,
        closeButton: true,
      });

      // we will redirect only if we are in flow inside the group
      if (group.flow_ids?.find((flowId) => flowId === flowTabParams?.flowId)) {
        history.push(
          `/bot/${botId}/${BotTabs.flows}/${FlowPlaceholder.removed}`,
        );
      }
    },
  });

  const { cloneFlowGroup } = useCloneFlowGroup({
    botId,
    groupId: group.id,
    onCompleted: (data) => {
      setLoading(false);
      addToaster({
        type: 'info',
        closeButton: true,
        timeout: 5000,
        buttonText: window.i18next.t('FlowGroup-string-2666-view'),
        onButtonClick: () => {
          history.push(
            `/bot/${toasterTargetBotId}/flows/${
              data.cloneFlowGroup.flow_ids?.[0] || ''
            }`,
          );
        },
        content: (
          <div style={{ width: '300px', whiteSpace: 'nowrap' }}>
            {window.i18next.t(
              'FlowGroup-JSXText-1733-group-was-successfully-cloned',
            )}
          </div>
        ),
      });
    },
  });

  useEffect(() => {
    if (group) {
      setLoading(false);
      setInnerTitle(group.title);
    }
  }, [group]);

  useEffect(() => {
    if (dndShouldBeDisabled) {
      setDisabledDnd(true);
      return;
    }

    setDisabledDnd?.(editMode);
  }, [dndShouldBeDisabled, editMode, setDisabledDnd]);

  return (
    <div style={{ opacity: dndState?.draggingId === group.id ? 0.5 : 1 }}>
      <CollapsibleSection
        defaultExtended={!group.collapsed}
        unmountHiddenSection={false}
        anchor={({ changeExtended, extended }) => {
          // we need extend collapsed group during the dragging over it
          if (
            dndState?.hoveredId?.includes(group.id) &&
            group.collapsed &&
            !extended
          ) {
            // but make it debounce
            if (changeExtendedTimeoutRef.current) {
              clearTimeout(changeExtendedTimeoutRef.current);
            }
            changeExtendedTimeoutRef.current = setTimeout(changeExtended, 500);
            setExtendedWhileDnd(true);
          }
          if (!dndState?.hoveredId?.includes(group.id) && extendedWhileDnd) {
            if (changeExtendedTimeoutRef.current) {
              clearTimeout(changeExtendedTimeoutRef.current);
            }
            if (dndState?.isDragging && extended) {
              changeExtended();
            }
            setExtendedWhileDnd(false);
          }

          const changeAndSaveExtended = () => {
            changeExtended();
            onUpdateCollapsedFlowGroup(!!extended);
          };

          const extendGroup = () => {
            if (!extended) {
              changeAndSaveExtended();
            }
          };

          const itemStyle = { paddingLeft: getLeftShift(nestedLevel) };

          return (
            <HoverDisclosure
              render={({ bind, isVisible }) => {
                const shouldBeHighlighted =
                  !dndState?.isDragging &&
                  flowTabParams?.flowId &&
                  flowsOrder?.includes(flowTabParams.flowId) &&
                  !extended;

                return (
                  <DndDroppable
                    {...bind}
                    type={FlowElementType.flow}
                    id={group.id}
                    notDroppable
                    ref={(node) => {
                      bind.ref(node);
                      itemDivRef.current = node;
                    }}
                    className={cn(css.item, css.sectionTitle, {
                      [css.hovered]: shouldBeHighlighted || isVisible,
                    })}
                    style={{
                      pointerEvents:
                        dndState?.isDragging &&
                        dndState?.draggingType !== FlowElementType.flow
                          ? 'none'
                          : 'auto',
                    }}
                  >
                    {editMode ? (
                      <EditFlowItem
                        onSubmit={(itemTitle) => {
                          const title =
                            itemTitle ?? group.title ?? 'default name';
                          setInnerTitle(title);
                          setLoading(true);
                          setEditMode(false);
                          onUpdateFlowGroup(title, false);
                        }}
                        defaultValue={group.title}
                        onDismiss={() => setEditMode(false)}
                        disabled={loading}
                        style={itemStyle}
                      />
                    ) : (
                      <>
                        <DndDraggableAnchor
                          type={FlowElementType.group}
                          className={css.titleWrapper}
                          onClick={() => {
                            // eslint-disable-next-line @typescript-eslint/unbound-method
                            (document.activeElement as HTMLElement).blur?.();
                            onUpdateCollapsedFlowGroup(!!extended);
                            changeExtended();
                            setTimeout(() => {
                              if (itemDivRef.current) {
                                itemDivRef.current.blur();
                              }
                            }, 100);
                          }}
                          onDoubleClick={() => {
                            if (!isDefault && !readOnly) {
                              setEditMode(true);
                            }
                          }}
                          style={itemStyle}
                        >
                          <ButtonUnstyled
                            onSubmit={changeAndSaveExtended}
                            className={css.arrow}
                            style={{
                              transform: extended
                                ? 'rotate(0deg)'
                                : 'rotate(-90deg)',
                            }}
                          >
                            <Icon icon="triangle" />
                          </ButtonUnstyled>
                          <Spacer horizontalFactor={1} factor={1} />
                          <div
                            className={css.title}
                            title={
                              !isDefault && isEllipsisDetect(titleRef.current)
                                ? innerTitle
                                : undefined
                            }
                          >
                            <div className={css.flowGroupTitleName}>
                              <Type
                                as="p"
                                innerRef={titleRef}
                                noWrap
                                size="15px_DEPRECATED"
                              >
                                {isDefault
                                  ? window.i18next.t(
                                      'FlowGroup-string-3006-default-group',
                                    )
                                  : innerTitle}
                              </Type>
                            </div>
                            <Spacer horizontalFactor={2} />
                            {group.sharing_params?.sharing_enabled && (
                              <Icon size="16px" icon="shared" />
                            )}
                          </div>
                        </DndDraggableAnchor>
                        {loading && (
                          <>
                            <Spacer horizontalFactor={2} />
                            <Loader size="s" />
                            <Spacer horizontalFactor={2} />
                          </>
                        )}
                        {isVisible &&
                          dndState?.draggingId !== group.id &&
                          !dndState?.isDragging &&
                          !readOnly && (
                            <Flex alignItems="center">
                              <Spacer horizontalFactor={2} />
                              <AddElementMenu
                                groupId={group.id}
                                botId={botId}
                                items={getAddFlowItemsInGroup(
                                  t,
                                  isWhatsappEnabled,
                                )}
                                handleBlankFlowAdd={(params) => {
                                  extendGroup();
                                  handleBlankFlowAdd(params);
                                }}
                                handleFlowAddWithTemplate={(params) => {
                                  extendGroup();
                                  handleFlowAddWithTemplate(params);
                                }}
                                renderInput={({
                                  getToggleButtonProps,
                                  ref,
                                }) => (
                                  <Tooltip2
                                    placement="top"
                                    boundariesElement="viewport"
                                    content={
                                      <Type as="p" size="12px" color="white">
                                        {window.i18next.t(
                                          'FlowGroup-JSXText--107-add-flow',
                                        )}
                                      </Type>
                                    }
                                    type="small"
                                    positionFixed
                                  >
                                    {(tooltipRef, bind) => (
                                      <Button
                                        {...bind}
                                        {...getToggleButtonProps()}
                                        ref={combineRefs([ref, tooltipRef])}
                                        data-testid="flow-list__flow-group__add-flow-button"
                                        icon={<Icon icon="plus" />}
                                        intent="text"
                                        size="xs"
                                        disabled={!!addingElementTitle}
                                      />
                                    )}
                                  </Tooltip2>
                                )}
                              />
                              <Spacer horizontalFactor={2} />
                              <FlowGroupMenu
                                botId={botId}
                                groupId={group.id}
                                isDefault={!!isDefault}
                                isLast={isLast}
                                setEditMode={setEditMode}
                                setShownModalId={setShownModalId}
                              />
                            </Flex>
                          )}
                      </>
                    )}
                  </DndDroppable>
                );
              }}
            />
          );
        }}
      >
        {({ bind }) => (
          <div {...bind}>
            <DndDroppable
              type={FlowElementType.flow}
              id={group.id}
              className={css.flowsContainer}
              placeholderStyle={{
                left: getLeftShift(nestedLevel + 1),
              }}
              notDroppable={readOnly}
            >
              {addingElementTitle && (
                <FlowItemPlaceholder
                  title={
                    addingElementTitle ||
                    window.i18next.t('FlowGroup-string-1383-new-flow')
                  }
                  nestedLevel={nestedLevel + 1}
                />
              )}

              {flowsOrder?.map((flowId) => {
                const flowItem = (
                  <FlowItem
                    readOnly={readOnly}
                    flowId={flowId}
                    nestedLevel={nestedLevel + 1}
                    parentGroup={group}
                    refetchFlows={refetchFlows}
                    onEntryPointAdd={onEntryPointAdd}
                    dndShouldBeDisabled={dndShouldBeDisabled}
                  />
                );

                return (
                  <DndDraggable
                    data-testid="flow-item"
                    key={flowId}
                    type={FlowElementType.flow}
                    id={flowId}
                  >
                    {flowItem}
                  </DndDraggable>
                );
              })}

              {!flowsOrder?.length && !addingElementTitle && (
                <FlowItemPlaceholder
                  title={window.i18next.t(
                    'FlowGroup-string--213-no-flows-in-this-group-yet',
                  )}
                  loading={false}
                  nestedLevel={nestedLevel + 1}
                />
              )}
            </DndDroppable>
          </div>
        )}
      </CollapsibleSection>
      {shownModalId !== FlowGroupMenuType.none && (
        <Modal onDismiss={onRemoveModalDismiss}>
          <DefaultDialog
            dialogStyle={{
              ...(shownModalId === FlowGroupMenuType.share && {
                width: '580px',
              }),
            }}
            header={() => {
              switch (shownModalId) {
                case FlowGroupMenuType.delete:
                  return `${window.i18next.t(
                    'FlowGroup-Template--107-delete',
                  )}${group.title}`;
                case FlowGroupMenuType.copy:
                  return `${window.i18next.t('FlowGroup-Template-6529-copy')}${
                    group.title
                  }`;
                case FlowGroupMenuType.share:
                  return `${window.i18next.t('FlowGroup-Template--576-share')}${
                    group.title
                  }${window.i18next.t('FlowGroup-Template--996-of')}${plurals(
                    group.flow_ids?.length!,
                    window.i18next.t('FlowGroup-string-3146-flow'),
                    window.i18next.t('FlowGroup-string-9752-flows'),
                  )}`;
                default:
                  return '';
              }
            }}
            onDismiss={onRemoveModalDismiss}
          >
            {shownModalId === FlowGroupMenuType.delete && (
              <Autofocus
                render={({ bind }) => (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      sendEvent({
                        category: 'flow navigation',
                        action: 'delete',
                        label: 'group',
                        propertyBag: {
                          botId,
                          groupId: group.id,
                        },
                      });
                      onRemoveModalDismiss();
                      setLoading(true);
                      onRemoveFlowGroup();
                    }}
                  >
                    <Type size="15px_DEPRECATED">
                      {window.i18next.t(
                        'FlowGroup-JSXText--148-you-are-about-to-delete',
                      )}
                      {group.title}
                      {window.i18next.t(
                        'FlowGroup-JSXText--784-all-flows-in-this-group-will-be-also-deleted',
                      )}
                    </Type>
                    <Spacer factor={9} />
                    <Flex justifyContent="flex-end">
                      <Button intent="secondary" onClick={onRemoveModalDismiss}>
                        {window.i18next.t('FlowGroup-JSXText-2673-cancel')}
                      </Button>
                      <Spacer horizontalFactor={4} factor={0} />
                      <Button {...bind} intent="red" type="submit">
                        {window.i18next.t('FlowGroup-JSXText-1210-delete')}
                      </Button>
                    </Flex>
                  </form>
                )}
              />
            )}
            {shownModalId === FlowGroupMenuType.share && (
              <ShareGroupModal
                groupId={group.id}
                flowIds={group.flow_ids ?? []}
                sharingParams={group.sharing_params}
              />
            )}
            {shownModalId === FlowGroupMenuType.copy && (
              <CloneFlowGroupModal
                onSubmit={({ botId: targetBotId }) => {
                  sendEvent({
                    category: 'flow navigation',
                    action: shownModalId as string,
                    label: 'group',
                    propertyBag: {
                      fromBotId: botId,
                      toBotId: targetBotId,
                      groupId: group.id,
                    },
                  });
                  setToasterTargetBotId(targetBotId);
                  setLoading(true);
                  if (shownModalId === FlowGroupMenuType.copy) {
                    cloneFlowGroup(targetBotId);
                  }
                  setShownModalId(FlowGroupMenuType.none);
                }}
              />
            )}
          </DefaultDialog>
        </Modal>
      )}
    </div>
  );
};

import * as React from 'react';
import * as ng from 'angular';
import { Flex } from '@ui/Flex';
import { angular2react } from 'angular2react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useReadyToRender } from '../../../utils/AngularRouting';
import { lazyInjector } from '../../../utils/angularLazyInjector';
import template from '../../../components/broadcast/templates/broadcast.content.html';
import controller from '../../../components/broadcast/content.controller';
import nullTemplate from '../../../components/broadcast/templates/broadcast.null.html';
import NullController from '../../../components/broadcast/null.controller';
import sentTemplate from '../../../components/broadcast/templates/broadcast.sent.html';
import broadcastOmniboxContainer from '../../../components/broadcast/broadcast-omnibox-container';
import history from '../../../components/broadcast/history';
import timePicker from '../../../components/broadcast/time-picker';
import { BotTabAngular } from '../../../modern-components/BotTab';
import {
  BotTabs,
  ReEngageType,
  useCurrentRoutePath,
} from '../../../utils/Routing';
import { ReEngageAside } from './ReEngageAside';
import * as css from '../BotPage.css';
import { MiniOnbordingTab } from '@components/MiniOnboarding/MiniOnbordingTab';

const reEngageTabWrapper = {
  template: () => template,
  controller,
  controllerAs: 'vm',
  bindings: {
    blockId: '<',
  },
};
const reEngageNullTabWrapper = {
  template: () => nullTemplate,
  controller: NullController,
  controllerAs: 'vm',
};
const reEngageSentTabWrapper = {
  template: () => sentTemplate,
};

export const ngReEngageTabWrapper = ng
  .module('app.pages.reEngageTab', [
    broadcastOmniboxContainer,
    history,
    timePicker,
  ])
  .component('reEngageTabWrapper', reEngageTabWrapper).name;

export const ngReEngageNullTabWrapper = ng
  .module('app.pages.reEngageNullTab', [])
  .component('reEngageNullTabWrapper', reEngageNullTabWrapper).name;

export const ngReEngageSentTabWrapper = ng
  .module('app.pages.reEngageSentTab', [])
  .component('reEngageSentTabWrapper', reEngageSentTabWrapper).name;

const ReEngageTabWrapperFunc = () => {
  return angular2react<{ blockId?: string }>(
    'reEngageTabWrapper',
    reEngageTabWrapper,
    lazyInjector.$injector as any,
  );
};

const ReEngageNullTabWrapperFunc = () => {
  return angular2react<{}>(
    'reEngageNullTabWrapper',
    reEngageNullTabWrapper,
    lazyInjector.$injector as any,
  );
};

const ReEngageSentTabWrapperFunc = () => {
  return angular2react<{}>(
    'reEngageSentTabWrapper',
    reEngageSentTabWrapper,
    lazyInjector.$injector as any,
  );
};

const ReEngageTabWrapper = ReEngageTabWrapperFunc();
const ReEngageNullTabWrapper = ReEngageNullTabWrapperFunc();
const ReEngageSentTabWrapper = ReEngageSentTabWrapperFunc();

export const ReEngageTab: React.FC<RouteComponentProps> = (props) => {
  const readyToRender = useReadyToRender('common');
  const broadcastReadyToRender = useReadyToRender(BotTabs.reEngage);
  const path = useCurrentRoutePath(props.match);

  return (
    <MiniOnbordingTab page={BotTabs.reEngage}>
      <Flex style={{ width: '100%', height: '100%' }}>
        <div className={css.asideContainer}>
          <ReEngageAside />
        </div>

        <BotTabAngular tabName="editor" rcol>
          <Switch>
            {readyToRender && (
              <Route
                path={`${path}/${ReEngageType.sent}`}
                render={() => <ReEngageSentTabWrapper />}
              />
            )}
            {broadcastReadyToRender && (
              <Route
                path={[
                  `${path}/:blockId/:broadcastId/:broadcastType`,
                  `${path}/:blockId/:broadcastType`,
                ]}
                render={() => <ReEngageTabWrapper />}
              />
            )}
            {readyToRender && (
              <Route path={path} render={() => <ReEngageNullTabWrapper />} />
            )}
          </Switch>
        </BotTabAngular>
      </Flex>
    </MiniOnbordingTab>
  );
};

import React, { useCallback, useContext } from 'react';
import cn from 'classnames';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ButtonUnstyled } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { CategoryContext } from '@components/ItemsModal';
import * as commonCSS from '../TemplatesListModal.css';
import * as css from './CreateFlowForMe.css';
import { getPlatformByCategory } from '../../../helpers';
import { ReactComponent as MagicSvg } from './images/magic.svg';
import { Badge } from '@ui/Badge';
import { sendEvent } from '@utils/Analytics';
import { createFlowForMe } from '@utils/Intercom/createFlowForMe';

export const CreateFlowForMe: React.FC = () => {
  const { t } = useSafeTranslation();
  const { activeCategory: category } = useContext(CategoryContext);
  const platform = getPlatformByCategory(category);

  const clickHandler = useCallback(() => {
    sendEvent({
      category: 'templates first session',
      action: 'create flow for me click',
      propertyBag: {
        category,
        platform,
      },
    });
    createFlowForMe();
  }, [category, platform]);

  return (
    <figure className={css.wrapper}>
      <ButtonUnstyled
        data-testid="create-blank-flow"
        className={cn(css.button, commonCSS.templateImage)}
        onClick={clickHandler}
      >
        <MagicSvg />
      </ButtonUnstyled>
      <Spacer factor={2} />
      <Type weight="semibold" whiteSpace="pre-wrap" size="15px" as="figcaption">
        {t('modernComponents.TemplatesModal.templates.createFlowForMe.title')}
      </Type>
      <Badge className={css.badge} kind="blue">
        {t('modernComponents.TemplatesModal.templates.createFlowForMe.bage')}
      </Badge>
    </figure>
  );
};

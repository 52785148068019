import React from 'react';
import cn from 'classnames';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { DefaultDialog } from '@ui/Dialog';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import * as css from './DisconnectWhatsappDialog.css';

interface DisconnectWhatsappDialogProps {
  close(): void;
  disconnect(): void;
}

export const DisconnectWhatsappDialog: React.FC<DisconnectWhatsappDialogProps> =
  ({ close, disconnect }) => {
    const { t } = useSafeTranslation();
    const { isSmallScreenSize } = useDeviceMedia();

    return (
      <DefaultDialog
        header={t('pages.BotPage.HomeTab.Whatsapp.DisconnectWhatsapp')}
        footer={
          <Flex
            className={cn({ [css.mobileFooter]: isSmallScreenSize })}
            flexDirection={isSmallScreenSize ? 'column' : 'row'}
            gapFactor={3}
          >
            <Button
              intent="red"
              onClick={() => {
                disconnect();
                close();
              }}
              fullWidth={isSmallScreenSize}
              data-testid="whatsapp-disconnect__disconnect"
            >
              {t('pages.BotPage.HomeTab.Whatsapp.disconnect')}
            </Button>
            <Button
              intent="secondary"
              onClick={close}
              fullWidth={isSmallScreenSize}
              data-testid="whatsapp-disconnect__cancel"
            >
              {t('pages.BotPage.HomeTab.Whatsapp.cancel')}
            </Button>
            {isSmallScreenSize && <Spacer factor={6} />}
          </Flex>
        }
        dialogStyle={{ width: '400px' }}
        onDismiss={close}
        mobileAdaptive={isSmallScreenSize}
      >
        <Type>{t('pages.BotPage.HomeTab.Whatsapp.areYouSure')}</Type>
        <Spacer factor={5} />
        <Type>{t('pages.BotPage.HomeTab.Whatsapp.yourBotWontSend')}</Type>
      </DefaultDialog>
    );
  };

import React from 'react';
import { SectionBox } from '@components/SectionBox';
import { useCreateBlankBot } from '@utils/Data/Bot/useCreateBlankBot';
import { Platform } from '@globals';
import { WhatsappBotNotCreatedView } from './WhatsappBotNotCreatedView';
import { useQuery } from 'react-apollo';
import { BotsListQuery } from '@common/services/GQLqueries/@types/BotsListQuery';
import { BOTS_LIST_QUERY } from '@common/services/GQLqueries/BotGQService.const';
import { isWhatsappEnabled } from '@utils/Whatsapp/isWhatsappEnabled';
import { WhatsappBotNotCreatedProps } from './types';

export const NonWorkspaceWhatsappBotNotCreated: React.FC<WhatsappBotNotCreatedProps> =
  ({ onClick, onClose }) => {
    const { data: botListQuery, loading: botListLoading } =
      useQuery<BotsListQuery>(BOTS_LIST_QUERY);
    const [onCreateBlankBot, { loading: blankBotLoading }] = useCreateBlankBot({
      bots: botListQuery?.bots || [],
    });

    const hasAnyWhatsappBots = botListQuery?.bots.some(isWhatsappEnabled);

    if (hasAnyWhatsappBots) {
      return null;
    }

    return (
      <SectionBox data-testid="whatsapp-account">
        <WhatsappBotNotCreatedView
          loading={botListLoading}
          buttonLoading={blankBotLoading}
          onClick={() => onClick(onCreateBlankBot(null, [Platform.whatsapp]))}
          onClose={onClose}
        />
      </SectionBox>
    );
  };

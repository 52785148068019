import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { WAInitialSetup } from '../KeywordsPage/FirstSessionPage/components/WAInitialSetup/WAInitialSetup';
import { WADiscounts } from '../KeywordsPage/FirstSessionPage/components/WADiscounts/WADiscounts';
import { Flex } from '@ui/Flex';
import css from './WAMigrationSetup.css';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import { useWhatsappMigrationStatusMutation } from '@utils/Whatsapp/hooks/useWhatsappMigrationStatusMutation';
import { WhatsappMigrationStatus } from '@globals';
import { CenteredLoader } from '@ui/Loader';

interface WAMigrationSetupProps {}

export const WAMigrationSetup: React.FC<WAMigrationSetupProps> = () => {
  const [step, setStep] = useState(0);
  const botId = useCurrentBotId();
  const history = useHistory();
  const [setWAMigrationStatus, { loading: WAMigrationStatusLoading }] =
    useWhatsappMigrationStatusMutation({
      onCompleted() {
        history.push(getTabLink(BotTabs.home, botId));
      },
    });

  return (
    <Flex justifyContent="center" className={css.page}>
      {WAMigrationStatusLoading ? (
        <CenteredLoader />
      ) : (
        <div className={css.content}>
          {step === 0 && (
            <WAInitialSetup
              goNextStep={() => setStep(1)}
              onFinish={() =>
                setWAMigrationStatus({
                  variables: {
                    botId: botId!,
                    status: WhatsappMigrationStatus.none,
                  },
                })
              }
            />
          )}
          {step === 1 && (
            <WADiscounts
              goPrevStep={() => setStep(0)}
              onFinish={(status) =>
                setWAMigrationStatus({
                  variables: {
                    botId: botId!,
                    status,
                  },
                })
              }
            />
          )}
        </div>
      )}
    </Flex>
  );
};

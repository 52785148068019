import React from 'react';
import { CenteredLoader } from '@ui/Loader';
import { SectionBox } from '@components/SectionBox';
import * as css from './TemplatesPanelLoaderView.css';

export const TemplatesPanelLoaderView = () => (
  <SectionBox>
    <CenteredLoader className={css.block} />
  </SectionBox>
);

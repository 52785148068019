import React, { useCallback } from 'react';
import { sendEvent } from '@utils/Analytics';
import { ApolloProvider } from 'react-apollo';
import { Router } from 'react-router-dom';
import { PluginType } from '@components/FlowBuilder/types';
import { Button } from '@ui/Button';
import memoize from 'lodash-es/memoize';
import { FlowSelectorModalContainer } from '@components/FlowSelectorDialog/containers/FlowSelectorModalContainer';
import { globalHistory } from '@utils/Routing';
import { useSetupEntryPoint } from '@components/FlowBuilder/utils/useSetupEntryPoint';
import client from '../../../../../common/services/ApolloService';

export const SetUpCustomerChatButton = () => {
  const {
    showFlowSelector,
    isAddFlowInProgress,
    onSetUp,
    onCloseFlowSelector,
    onSelectFlowForSetUp,
  } = useSetupEntryPoint({ pluginType: PluginType.customer_chat_entry_point });

  const onSelectFlowForSetUpWrapper = useCallback(
    (flowId: string) => {
      onSelectFlowForSetUp(flowId);
      sendEvent({
        category: 'grow tab',
        action: 'set up in flow',
        label: 'customer chat card',
      });
    },
    [onSelectFlowForSetUp],
  );

  const onCreateNewFlowWrapper = useCallback(
    (flowId: string) => {
      onSelectFlowForSetUp(flowId);
      sendEvent({
        category: 'grow tab',
        action: 'set up in new flow',
        label: 'customer chat card',
      });
    },
    [onSelectFlowForSetUp],
  );

  return (
    <>
      <Button intent="primary" onClick={onSetUp}>
        {window.i18next.t(
          'SetUpCustomerChatButton-JSXText--122-set-up-in-flows',
        )}
      </Button>

      {showFlowSelector && (
        <FlowSelectorModalContainer
          forceLoading={isAddFlowInProgress}
          onSelectExisting={onSelectFlowForSetUpWrapper}
          onCreateNewFlow={onCreateNewFlowWrapper}
          onClose={onCloseFlowSelector}
        />
      )}
    </>
  );
};

export const createSetupCustomerChatButton = memoize(() => {
  return (
    <ApolloProvider client={client}>
      <Router history={globalHistory}>
        <SetUpCustomerChatButton />
      </Router>
    </ApolloProvider>
  );
});

import React from 'react';
import { TextEllipsis } from '@ui/TextEllipsis';
import { Type } from '@ui/Type';
import { ButtonUnstyled } from '@ui/Button';
import { RoleConsumer } from '@utils/Roles';
import { Permission } from '../../../../../common/services/RoleService';

interface BotTitleViewProps {
  onDoubleClick: () => void;
  className?: string;
}

export const BotTitleView: React.FC<BotTitleViewProps> = ({
  children,
  className,
  onDoubleClick,
}) => (
  <RoleConsumer domain="pro" can={Permission.EDIT}>
    {({ allowed }) => (
      <ButtonUnstyled
        onDoubleClick={allowed ? onDoubleClick : undefined}
        className={className}
        data-testid="bot-panel__rename"
      >
        <Type size="24px" weight="bold">
          <TextEllipsis width={400}>{children}</TextEllipsis>
        </Type>
      </ButtonUnstyled>
    )}
  </RoleConsumer>
);

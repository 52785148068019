import memoize from 'lodash-es/memoize';
import i18next from 'i18next';

export const getTemplateFieldErrorText = memoize((fieldName: string) => {
  switch (fieldName) {
    case 'url':
      return i18next.t('pages.MessagesTemplates.invalidURL');
    case 'phone_number':
      return i18next.t('pages.MessagesTemplates.invalidPhone');
    default:
      return '';
  }
});

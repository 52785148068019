import Maybe from 'graphql/tsutils/Maybe';
import { sendEvent } from '@utils/Analytics';
import { DateUtils, Granularity } from '@utils/DateTime';
import { log, LogParamsBase } from 'cf-common/src/logger';
import { Messages, toaster } from '@services/MessageService';
import { FormValidationErrors, Repeat, WhatsappBroadcastConfig } from './types';
import { getWeekdaysShort } from './components/constants';
import {
  BroadcastStatus,
  TriggeredMessageIntervalTimeUnit,
  TriggeredMessageType,
  WhatsappBroadcastType,
} from '@globals';
import { TimeIntervalsIds } from '@ui/TimeoutInput';
import { invertObj } from 'ramda';
import { WhatsappBroadcast_whatsappBroadcast } from './hooks/@types/WhatsappBroadcast';
import { extractGQLErrorData } from '@utils/GQL/utils';
import { ApolloError } from 'apollo-client';

export const DEFAULT_DAYS = '1000000';

export const defaultErrorHandler =
  (logParams: LogParamsBase) => (error: any) => {
    const errorData = extractGQLErrorData(error);
    if (
      errorData?.message.includes(
        'Only broadcasts in DRAFT status can be updated',
      ) ||
      errorData?.message.includes('Broadcast start date is in past')
    ) {
      return;
    }
    toaster.error({ payload: { message: Messages.somethingWentWrong } });
    log.error({ error, ...logParams });
  };

export const calculateDaysOfWeek = (
  daysOfWeek: Maybe<string>,
  index: number,
) => {
  const swap = (value: string) => (value === '0' ? '1' : '0');
  const updatedDaysOfWeek = (daysOfWeek || DEFAULT_DAYS)
    .split('')
    .map((value, idx) => Number(index === idx ? swap(value) : value));
  if (updatedDaysOfWeek.filter((dayState) => dayState === 1).length === 0) {
    return daysOfWeek || DEFAULT_DAYS;
  }
  return updatedDaysOfWeek.join('');
};

export const weekdayIdsFromValue = (value: Maybe<string>): string[] => {
  if (!value) {
    return [];
  }

  return value.split('').reduce((prev, curr, currIndex) => {
    if (curr === '1') {
      return [...prev, getWeekdaysShort()[currIndex].id];
    }
    return prev;
  }, [] as string[]);
};

export const REPEAT_TYPES = {
  [Repeat.custom]: '1000000',
  [Repeat.everyDay]: '1111111',
  [Repeat.weekends]: '0000011',
  [Repeat.workdays]: '1111100',
};

// @ts-expect-error other cases are not defined
export const INTERVAL_TO_UNIT: Record<
  TimeIntervalsIds,
  TriggeredMessageIntervalTimeUnit
> = {
  [TimeIntervalsIds.days]: TriggeredMessageIntervalTimeUnit.day,
  [TimeIntervalsIds.hours]: TriggeredMessageIntervalTimeUnit.hour,
  [TimeIntervalsIds.minutes]: TriggeredMessageIntervalTimeUnit.minute,
  [TimeIntervalsIds.seconds]: TriggeredMessageIntervalTimeUnit.second,
} as const;

export const UNIT_TO_INTERVAL = invertObj(INTERVAL_TO_UNIT) as Record<
  TriggeredMessageIntervalTimeUnit,
  TimeIntervalsIds
>;

export const timeToSeconds = (time: string): number => {
  const [hours, minutes] = time.split(':');

  return +hours * 60 * 60 + +minutes * 60;
};

export const secondsToTime = (seconds: number): string => {
  const date = new Date(0);
  date.setSeconds(seconds);
  return date.toISOString().substring(11, 16);
};

export const repeatTypeFromConfig = (
  config: WhatsappBroadcast_whatsappBroadcast,
): Repeat => {
  if (!config.schedule?.dayOfMonth && !config.schedule?.daysOfWeek) {
    return Repeat.none;
  }

  if (config.schedule?.dayOfMonth) {
    return Repeat.everyMonth;
  }

  // @ts-expect-error
  const predefinedType: Repeat | undefined =
    invertObj(REPEAT_TYPES)[config.schedule.daysOfWeek!];

  if (predefinedType) {
    return predefinedType;
  }

  if (config.schedule.daysOfWeek) {
    return Repeat.custom;
  }

  return Repeat.none;
};

export const logger = (action: string, propertyBag?: Object) =>
  sendEvent({
    category: 'reengage editor panel',
    action,
    label: 'Whatsapp',
    propertyBag,
  });

export const graphqlErrorToBroadcastFormError = (
  error: ApolloError | undefined,
  config: WhatsappBroadcastConfig,
): FormValidationErrors | null => {
  const errorData = extractGQLErrorData(error);
  const isDateError = errorData?.message.includes(
    'Broadcast start date is in past',
  );
  const configDate = config.schedule?.startingTimestamp
    ? new Date(config.schedule?.startingTimestamp * 1000)
    : null;
  const isSameDay = configDate
    ? DateUtils.isSame(configDate, Granularity.day)
    : null;

  if (isDateError) {
    return isSameDay
      ? FormValidationErrors.timeInPast
      : FormValidationErrors.dateInPast;
  }

  return null;
};

export const isEditableBroadcast = (
  broadcast: WhatsappBroadcast_whatsappBroadcast | undefined,
) => {
  if (broadcast?.schedule?.startingTimestamp) {
    if (broadcast.status === BroadcastStatus.DRAFT) {
      return true;
    }

    if (broadcast.status === BroadcastStatus.SCHEDULED) {
      const isTimeLeft =
        broadcast.schedule.startingTimestamp * 1000 - Date.now() > 0;
      return isTimeLeft;
    }

    return false;
  }

  if (broadcast?.type === WhatsappBroadcastType.ONE_TIME) {
    return broadcast.status === BroadcastStatus.DRAFT;
  }

  return [
    BroadcastStatus.LIVE,
    BroadcastStatus.PAUSED,
    BroadcastStatus.SCHEDULED,
    BroadcastStatus.DRAFT,
  ].includes(broadcast?.status as BroadcastStatus);
};

export const isShopifyTrigger = (trigger: Maybe<TriggeredMessageType>) => {
  return [
    TriggeredMessageType.abandoned_cart_update,
    TriggeredMessageType.order_confirmation,
    TriggeredMessageType.order_status_update,
  ].includes(trigger as TriggeredMessageType);
};

export const isStartOrUnpauseButtonDisabled = (
  trigger: Maybe<TriggeredMessageType>,
  hasShopifyAccount: boolean,
) => {
  return isShopifyTrigger(trigger) && !hasShopifyAccount;
};

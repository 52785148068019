import React, { useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Toggle } from '@ui/Toggle';
import { Tooltip2 } from '@ui/Tooltip2';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Anchor } from '@ui/Links';
import { Input } from '@ui/Input';
import { ReactComponent as InfoIcon } from '@ui/_deprecated/Icon/icons/ic_alert_circle2.svg';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { CheckBox } from '@ui/CheckBox';
import { useToaster } from '@ui/Toaster/ToasterProvider';
import { copyTextFromElement } from '@utils/copyText';
import { getLandingHostname } from '@utils/Environment';
import { getLinkByVideoHash } from '@utils/Youtube/getLinkByVideoHash';
import { PluginTutorialYoutube } from '../../../../../constants/externalLinks';
import { ReactComponent as ShareFlowPreview } from '../../common/assets/share-flow-preview.svg';
import { FlowSharingInput } from '../../../../../../@types/globalTypes';
import * as css from './ShareFlowModal.css';

interface ShareFlowModalProps {
  label: 'group' | 'flow';
  footerTitle: string;
  linkSearchQuery: string;
  onCopyInviteLink(): void;
  onCopyShareCode(): void;
  onEnableSharingToggle(enable: boolean): void;
  onAllowCloningToggle(): void;
  sharingParams?: FlowSharingInput | null;
  disabled?: boolean;
}

const INVITE_LINK_FIELD_ID = 'invite_link_field_id';
const SHARE_FLOW_CODE_ID = 'share_flow_code_id';

export const ShareFlowModal: React.FC<ShareFlowModalProps> = ({
  label,
  footerTitle,
  linkSearchQuery,
  onCopyInviteLink,
  onCopyShareCode,
  onAllowCloningToggle,
  onEnableSharingToggle,
  sharingParams,
  disabled,
}) => {
  const { t } = useSafeTranslation();
  const { addToaster } = useToaster();

  const [width, setWidth] = useState<string | undefined>('640');
  const [height, setHeight] = useState<string | undefined>('480');

  const link = `https://${getLandingHostname()}/share/${linkSearchQuery}`;

  const handleCopyInviteLink = () => {
    copyTextFromElement(INVITE_LINK_FIELD_ID);
    onCopyInviteLink();
    addToaster({
      type: 'info',
      content: window.i18next.t(
        'ShareFlowModal-string--195-link-copied-to-clipboard',
      ),
      timeout: 2000,
      closeButton: true,
    });
  };

  const handleCopyShareCode = () => {
    copyTextFromElement(SHARE_FLOW_CODE_ID);
    onCopyShareCode();
    addToaster({
      type: 'info',
      content: window.i18next.t(
        'ShareFlowModal-string--536-code-copied-to-clipboard',
      ),
      timeout: 2000,
      closeButton: true,
    });
  };

  const shareCodeSnippet = `<iframe src="${link}" width="${width}" height="${height}" frameBorder="0" allowfullscreen></iframe>`;

  return (
    <div>
      <div className={css.shareFormEnableToggleContainer}>
        <label>
          <Flex style={{ height: '48px' }} alignItems="center">
            <Toggle
              value={!!sharingParams?.sharing_enabled}
              onChange={onEnableSharingToggle}
              disabled={disabled}
            />
            <Spacer horizontalFactor={4} />
            <Type weight="semibold" size="15px_DEPRECATED">
              {window.i18next.t('ShareFlowModal-JSXText--110-share-to-the-web')}
            </Type>
            <Spacer horizontalFactor={2} />
            <Tooltip2
              placement="right"
              boundariesElement="viewport"
              content={
                <>
                  <ShareFlowPreview />
                  <br />
                  <br />
                  <Type as="p" size="12px" color="white">
                    {window.i18next.t(
                      'ShareFlowModal-JSXText--114-anyone-with-the-link-can-view-the',
                    )}
                    <br />
                    {label}.
                  </Type>
                  <br />
                  <Anchor
                    intent="tooltip"
                    size="small"
                    href={getLinkByVideoHash(PluginTutorialYoutube.shareLink)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('tutorial.FlowTutorial.ShareLinkTutorial')}
                  </Anchor>
                </>
              }
              type="small"
              positionFixed
              hoverable
            >
              {(ref, bind) => (
                <div ref={ref} className={css.infoIconContainer}>
                  <InfoIcon {...bind} />
                </div>
              )}
            </Tooltip2>
            <Spacer horizontalFactor={4} />
          </Flex>
        </label>
      </div>
      <div className={css.shareForms}>
        {!sharingParams?.sharing_enabled && (
          <div className={css.shareFormVeil} />
        )}
        <Spacer factor={8} />
        <Type as="div" weight="semibold" size="15px_DEPRECATED">
          {label.charAt(0).toUpperCase() + label.slice(1)}
          {window.i18next.t('ShareFlowModal-JSXText-1527-link')}
        </Type>
        <Spacer factor={1} />
        <Flex justifyContent="space-between">
          <Input
            id={INVITE_LINK_FIELD_ID}
            containerClassName={css.shareBigInput}
            value={link}
            readOnly
          />
          <Spacer horizontalFactor={4} />
          <Button intent="primary" onClick={handleCopyInviteLink}>
            {window.i18next.t('ShareFlowModal-JSXText-6076-copy')}
          </Button>
        </Flex>
        <Spacer factor={8} />
        <Flex alignItems="center">
          <Type weight="semibold" size="15px_DEPRECATED">
            {window.i18next.t('ShareFlowModal-JSXText--271-embed-code')}
          </Type>
          <Spacer horizontalFactor={2} />
          <Input
            containerClassName={css.shareSmallInput}
            value={width}
            error={!width}
            onChange={(e) => setWidth(e.target.value)}
          />
          <Spacer horizontalFactor={1} />
          <Type size="18px">×</Type>
          <Spacer horizontalFactor={1} />
          <Input
            containerClassName={css.shareSmallInput}
            value={height}
            error={!height}
            onChange={(e) => setHeight(e.target.value)}
          />
        </Flex>
        <Spacer factor={2} />
        <Flex justifyContent="space-between" alignItems="flex-end">
          <Input
            containerClassName={css.shareBigInput}
            render={({ getInputProps }) => (
              <textarea
                {...getInputProps({
                  value: shareCodeSnippet,
                  style: {
                    fontSize: 15,
                    resize: 'none',
                    border: 'none',
                    width: '100%',
                    maxWidth: 400,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 4,
                    backgroundColor: 'transparent',
                  },
                })}
                id={SHARE_FLOW_CODE_ID}
                rows={6}
                readOnly
              />
            )}
          />
          <Spacer horizontalFactor={4} />
          <Button
            intent="primary"
            disabled={!width || !height}
            onClick={handleCopyShareCode}
          >
            {window.i18next.t('ShareFlowModal-JSXText-6076-copy')}
          </Button>
        </Flex>
        <Spacer factor={2} />
        <div className={css.allowCloningContainer}>
          <label>
            <CheckBox
              checked={!!sharingParams?.allow_cloning}
              onChange={onAllowCloningToggle}
              disabled={disabled || !sharingParams?.sharing_enabled}
            />
            <Spacer horizontalFactor={2} />
            <Type size="15px_DEPRECATED">{footerTitle}</Type>
          </label>
        </div>
      </div>
    </div>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { propEq } from 'ramda';
import cn from 'classnames';
import * as css from './BotsListBannerBox.css';
import {
  ServerStorageItemKeys,
  useServerStorage,
} from '../../../../utils/ServerStorage';

export interface BotsListBannerProps {
  onRequestClose: () => void;
}

interface BotsListBannerConfig {
  id: string;
  renderBanner: (onRequestClose: () => void) => React.ReactElement;
  showLimit?: number;
  customCanBeShown?: () => Promise<boolean>;
}

const BOTS_LIST_BANNERS: BotsListBannerConfig[] = [];

interface BotsListBannerServerState {
  lastShowedBannerId: string;
  counters: Record<string, number>;
}

export const BotsListBannerBox: React.FC<React.HTMLProps<HTMLDivElement>> = (
  props,
) => {
  const [botsListBannerServerState, setBotsListBannerServerState, { loading }] =
    useServerStorage<BotsListBannerServerState>(
      ServerStorageItemKeys.botsListBannerServerState,
    );
  const [currentBanner, setCurrentBanner] = useState<
    BotsListBannerConfig | undefined
  >();
  const [activeBanners, setActiveBanners] = useState<
    BotsListBannerConfig[] | undefined
  >();

  const { lastShowedBannerId, counters } = botsListBannerServerState || {};

  useEffect(
    function prepareActiveBannersList() {
      if (activeBanners || loading) {
        return;
      }
      Promise.all(
        BOTS_LIST_BANNERS.map(async ({ customCanBeShown, id, showLimit = 0 }) =>
          customCanBeShown
            ? customCanBeShown()
            : (counters?.[id] || 0) < showLimit,
        ),
      ).then((bannersShowFlags) => {
        setActiveBanners(
          BOTS_LIST_BANNERS.filter((_, i) => bannersShowFlags[i]),
        );
      });
    },
    [activeBanners, counters, setActiveBanners, loading],
  );

  /**
   *  Select next banner for show in active banner list
   *  When we reach the end of the list - start over
   */
  useEffect(
    function showNextBanner() {
      if (
        !activeBanners?.length ||
        currentBanner ||
        BOTS_LIST_BANNERS.length === 0
      ) {
        return;
      }

      const currentBannerIndex = !lastShowedBannerId
        ? 0
        : activeBanners.findIndex(({ id }) => lastShowedBannerId === id) + 1;

      const safeCurrentBannerIndex =
        currentBannerIndex > activeBanners.length - 1 ? 0 : currentBannerIndex;

      const safeCurrentBanner = activeBanners[safeCurrentBannerIndex];
      setCurrentBanner(safeCurrentBanner);

      setBotsListBannerServerState({
        lastShowedBannerId: safeCurrentBanner.id,
        counters: {
          ...counters,
          [safeCurrentBanner.id]: (counters?.[safeCurrentBanner.id] || 0) + 1,
        },
      });
    },
    [
      lastShowedBannerId,
      counters,
      activeBanners,
      botsListBannerServerState,
      loading,
      currentBanner,
      setBotsListBannerServerState,
    ],
  );

  const onRequestCloseHandler = useCallback(() => {
    if (!currentBanner || !botsListBannerServerState) {
      return;
    }
    setBotsListBannerServerState({
      ...botsListBannerServerState,
      counters: {
        ...botsListBannerServerState?.counters,
        [currentBanner.id]:
          BOTS_LIST_BANNERS.find(propEq('id', currentBanner?.id))?.showLimit ||
          0,
      },
    });

    setActiveBanners(undefined);
    setCurrentBanner(undefined);
  }, [currentBanner, botsListBannerServerState, setBotsListBannerServerState]);

  if (!currentBanner) {
    return null;
  }

  return (
    <div {...props} className={cn(css.botsListBannerBox, props.className)}>
      {currentBanner.renderBanner(onRequestCloseHandler)}
    </div>
  );
};

import {
  OrderStatusUpdateStatus,
  TriggeredMessageIntervalTimeUnit,
  TriggeredMessageType,
} from '@globals';
import { compose, curry, lensPath, set } from 'ramda';
import { WhatsappBroadcast_whatsappBroadcast } from './hooks/@types/WhatsappBroadcast';
import {
  fromTimestampInSeconds,
  getTimestampInSeconds,
} from '@utils/DateTime/DateUtils';

type Config = WhatsappBroadcast_whatsappBroadcast;

const titleLens = lensPath(['title']);
const timestampLens = lensPath(['schedule', 'startingTimestamp']);
const userFitlerLens = lensPath(['userFilter']);
const daysOfWeekLens = lensPath(['schedule', 'daysOfWeek']);
const daysOfMonthLens = lensPath(['schedule', 'dayOfMonth']);
const intervalLens = lensPath(['triggerSettings', 'waitInterval']);
const timeUnitLens = lensPath(['triggerSettings', 'waitIntervalTimeUnit']);
const timeOfDayLens = lensPath(['triggerSettings', 'timeOfDay']);
const triggerTypeLens = lensPath(['triggerSettings', 'triggerType']);
const triggerUserFilter = lensPath(['triggerSettings', 'userFilter']);
const orderStatusLens = lensPath([
  'triggerSettings',
  'orderStatusUpdateStatus',
]);

export const whatsappConfig = {
  updateTitle: curry((title: string, config: Config) =>
    set(titleLens, title, config),
  ),
  updateDate: curry((date: moment.Moment, config: Config) => {
    // @ts-expect-error
    const newDate = new Date(date);
    const oldDate = fromTimestampInSeconds(
      config.schedule?.startingTimestamp ?? 0,
    );

    oldDate.setDate(newDate.getDate());
    oldDate.setMonth(newDate.getMonth());
    oldDate.setFullYear(newDate.getFullYear());

    return set(timestampLens, getTimestampInSeconds(oldDate), config);
  }),
  updateTime: curry((time: string | null, config: Config) => {
    if (!time) {
      return config;
    }

    const [hours, minutes] = time.split(':').map(Number);

    const date = fromTimestampInSeconds(
      config.schedule?.startingTimestamp ?? 0,
    );
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setMilliseconds(0);

    return compose<Config, Config>(
      set(timestampLens, getTimestampInSeconds(date)),
    )(config);
  }),
  updateTriggerUserFilter: curry((userFilter: any, config: Config) =>
    set(triggerUserFilter, userFilter, config),
  ),
  updateUserFilter: curry((userFilter: any, config: Config) =>
    set(userFitlerLens, userFilter, config),
  ),
  updateDaysOfWeek: curry((daysOfWeek: string | null, config: Config) =>
    compose<Config, Config, Config>(
      set(daysOfWeekLens, daysOfWeek),
      set(daysOfMonthLens, null),
    )(config),
  ),
  updateDayOfMonth: curry((daysOfMonth: number, config: Config) =>
    compose<Config, Config, Config>(
      set(daysOfWeekLens, null),
      set(daysOfMonthLens, daysOfMonth),
    )(config),
  ),
  updateTriggerSendIn: curry(
    (time: number, unit: TriggeredMessageIntervalTimeUnit, config: Config) =>
      compose<Config, Config, Config, Config>(
        set(intervalLens, time),
        set(timeUnitLens, unit),
        set(timeOfDayLens, 0),
      )(config),
  ),
  updateTimeOfDay: curry((time: number | null, config: Config) => {
    if (!time) {
      return config;
    }

    return set(timeOfDayLens, time, config);
  }),
  updateTriggerType: curry((type: TriggeredMessageType, config: Config) =>
    set(triggerTypeLens, type, config),
  ),
  updateOrderStatusUpdateStatus: curry(
    (type: OrderStatusUpdateStatus, config: Config) =>
      set(orderStatusLens, type, config),
  ),
} as const;

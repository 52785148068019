import { useGetMessageTemplates } from '../../../data/useGetMessageTemplates';
import { useMemo, useState } from 'react';
import { SortMode } from '../types';

export const useMessageTemplatesTableItems = () => {
  const [searchString, setSearchString] = useState<string>('');
  const [sortMode, setSortMode] = useState<SortMode>(SortMode.nameAZ);
  const { templates, loading } = useGetMessageTemplates({
    fetchPolicy: 'network-only',
  });

  const clearSearchString = useMemo(
    () => searchString.trim().toLowerCase(),
    [searchString],
  );

  const items = useMemo(() => {
    if (!templates) {
      return [];
    }
    const filteredItems = templates.filter(
      ({
        name,
        category,
        language,
        content: {
          header: { text: headerText },
          footer: { text: footerText },
          body: { text: bodyText },
          buttons,
        },
      }) =>
        clearSearchString.length === 0 ||
        [
          name,
          category,
          headerText,
          footerText,
          bodyText,
          language,
          ...buttons?.map(({ text }) => text),
        ].some(
          (text) => !!text?.trim().toLowerCase().includes(clearSearchString),
        ),
    );

    filteredItems.sort((a, b) => {
      switch (sortMode) {
        case SortMode.lastUpdated:
          return (b.last_edited || 0) - (a.last_edited || 0);
        case SortMode.nameZA:
          return b.name.localeCompare(a.name);
        case SortMode.nameAZ:
        default:
          return a.name.localeCompare(b.name);
      }
    });

    return filteredItems;
  }, [clearSearchString, templates, sortMode]);

  return {
    items,
    loading,
    searchString,
    setSearchString,
    sortMode,
    setSortMode,
    allItems: templates,
  };
};

import React from 'react';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { ChangeBillingPeriodButton } from './ChangeBillingPeriodButton';
import { ConnectPageButton } from './ConnectPageButton';
import { LimitExceededButton } from './LimitExceededButton';
import { PlanButtonType } from './types';
import { UpdateCardInfoButton } from './UpdateCardInfoButton';
import { UpgradeButton } from './UpgradeButton';
import { UnpausePlanButton } from './UnpausePlanButton';
import { TimeLimitTrialExpiredButton } from './TimeLimitTrialExpiredButton';

export interface PlanButtonViewProps {
  type: PlanButtonType;
  disabled: boolean;
  className?: string;
  shortText?: boolean;
  small?: boolean;
  onClick?: () => void;
}

export const PlanButtonView: React.FC<PlanButtonViewProps> = ({
  type,
  small,
  disabled,
  className,
  shortText,
  onClick,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const style = isSmallScreenSize ? { width: '100%' } : undefined;
  const commonProps = { small, disabled, style, className, onClick };

  return {
    [PlanButtonType.updateCard]: (
      <UpdateCardInfoButton shortText={shortText} {...commonProps} />
    ),
    [PlanButtonType.paused]: <UnpausePlanButton {...commonProps} />,
    [PlanButtonType.withoutPage]: <ConnectPageButton {...commonProps} />,
    [PlanButtonType.limitReached]: <LimitExceededButton {...commonProps} />,
    [PlanButtonType.offerSubscription]: (
      <ChangeBillingPeriodButton shortText={shortText} {...commonProps} />
    ),
    [PlanButtonType.none]: null,
    [PlanButtonType.default]: <UpgradeButton {...commonProps} />,
    [PlanButtonType.timeLimitedTrialExpired]: (
      <TimeLimitTrialExpiredButton {...commonProps} />
    ),
  }[type];
};

import React, { useState } from 'react';

import { Box } from '@ui/Box';
import * as css from './ContactAttributesCard.css';
import { Input } from '@ui/Input';
import { ReactComponent as ChatfuelLogo } from '../icons/logos/chatfuel.svg';
import { ReactComponent as ShopifyLogo } from '../icons/logos/shopify.svg';
import { Flex, FlexItem } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Contact } from '../useContactQuery';
import { Type } from '@ui/Type';
import { SystemVariableSource } from '@globals';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { IconButton } from '@ui/IconButton';
import { Icon } from '@ui/Icon';
import { AddAttributesDropdown } from '../AddAttributesDropdown/AddAttributesDropdown';
import { useContactAttributes } from '../useContactAttributes';
import { removeTypename } from '@utils/GQL/utils';
import { useRolePermission } from '@utils/Roles';
import { Permission } from '@common/services/RoleService';
import { sendEvent } from '@utils/Analytics';
import { EditAttributeValue } from '../EditAttributeValue/EditAttributeValue';
import { DateUtils } from '@utils/DateTime/DateUtils';

export const SourceIcon: React.FC<{ source?: SystemVariableSource | null }> = ({
  source,
}) => {
  if (!source) {
    return null;
  }
  switch (source) {
    case SystemVariableSource.shopify:
      return <ShopifyLogo />;
    case SystemVariableSource.kommo: // currently there is no other attrs
    case SystemVariableSource.chatfuel:
    default:
      return <ChatfuelLogo />;
  }
};

const attributeFormatter = (attribute: {
  value: string;
  isDateTime: boolean;
}) => {
  if (attribute.isDateTime) {
    return DateUtils.formatFullDate(Number(attribute.value));
  }
  return attribute.value;
};

export const ContactAttributesCard: React.FC<{ contact: Contact }> = ({
  contact,
}) => {
  const { t } = useSafeTranslation();
  const [search, setSearch] = useState('');
  const { allowed: editAllowed } = useRolePermission({
    domain: 'people',
    can: Permission.EDIT,
  });

  const searchLowerCased = search.toLocaleLowerCase();
  const customAttributes = contact.customAttributes.filter(
    ({ name, value }) =>
      name.toLocaleLowerCase().includes(searchLowerCased) ||
      value.toLocaleLowerCase().includes(searchLowerCased),
  );
  const systemAttributes = contact.systemAttributes.filter(
    ({ name, value }) =>
      name.toLocaleLowerCase().includes(searchLowerCased) ||
      value.toLocaleLowerCase().includes(searchLowerCased),
  );

  const { deleteAttribute, updateAttributeCache, syncAttribute } =
    useContactAttributes();

  return (
    <Box
      data-testid="user-attributes-card"
      className={css.card}
      color="white"
      borderRadiusFactor={4}
      paddingFactor={4}
    >
      <Flex gapFactor={3}>
        <FlexItem grow>
          <Input
            data-testid="user-attributes-card_search-panel"
            type="search"
            renderIcon={() => <Icon size="20px" icon="search" color="grey" />}
            value={search}
            placeholder={t('PeoplePage.search')}
            onChange={(event) => {
              setSearch(event?.target?.value);
            }}
          />
        </FlexItem>

        {editAllowed && <AddAttributesDropdown contact={contact} />}
      </Flex>
      <Spacer horizontalFactor={6} />
      <Flex gapFactor={6} data-testid="user-attributes-card_attributes-table">
        <FlexItem equalWidth>
          {systemAttributes.length > 0 ? (
            <Type weight="bold" size="24px" color="baseNormal">
              {t('PeoplePage.systemAttributes', {
                count: systemAttributes.length,
              })}
            </Type>
          ) : (
            <Type weight="bold" size="24px" color="baseTertiary">
              {t('PeoplePage.emptySystemAttributes')}
            </Type>
          )}

          <table className={css.table} data-testid="system-attributes-table">
            {systemAttributes.map((sa) => (
              <tr key={sa.name} data-testid="system-attributes-row">
                <td className={css.iconCell}>
                  <SourceIcon source={sa.source} />
                </td>
                <Type
                  data-testid="system-attributes-table_attribute-name"
                  as="td"
                  size="15px"
                  className={css.minWidth}
                  color="baseNormal"
                >
                  {sa.name}
                </Type>
                <td className={css.input}>
                  <Input
                    data-testid="system-attributes-table_attribute-value"
                    value={attributeFormatter(sa)}
                    className={css.input}
                    readOnly
                  />
                </td>
              </tr>
            ))}
          </table>
        </FlexItem>
        <FlexItem equalWidth data-testid="user-attributes-custom-attributes">
          {customAttributes.length > 0 ? (
            <Type weight="bold" size="24px" color="baseNormal">
              {t('PeoplePage.customAttributes', {
                count: customAttributes.length,
              })}
            </Type>
          ) : (
            <Type
              weight="bold"
              size="24px"
              color="baseTertiary"
              data-testid="empty-custom-attributes"
            >
              {t('PeoplePage.emptyCustomAttributes')}
            </Type>
          )}
          <table className={css.table} data-testid="empty-custom-attributes">
            {customAttributes.map((ca) => (
              <tr key={ca.name} data-testid="custom-attributes-row">
                <td className={css.minWidth}>
                  <Type
                    as="td"
                    size="15px"
                    color="baseNormal"
                    data-testid="custom-attributes_attribute-name"
                  >
                    {ca.name}
                  </Type>
                </td>
                <td
                  className={css.input}
                  data-testid="custom-attributes_value-input"
                >
                  <EditAttributeValue
                    variable={ca.name}
                    onChange={(value) => {
                      const newAttribute = {
                        ...removeTypename(ca),
                        value,
                      };
                      updateAttributeCache(newAttribute);
                      syncAttribute(newAttribute);
                    }}
                    value={ca.value}
                    inputProps={{
                      className: css.input,
                      readOnly: !editAllowed,
                    }}
                  />
                </td>
                {editAllowed && (
                  <td>
                    <IconButton
                      ata-testid="custom-attributes_delete-button"
                      icon="delete"
                      color="accent4Normal"
                      onClick={() => {
                        sendEvent({
                          category: 'contact profile',
                          action: 'delete',
                          label: 'custom attribute',
                          propertyBag: {
                            contactId: contact.id,
                            tab: 'people tab',
                          },
                        });
                        return deleteAttribute(ca.name);
                      }}
                    />
                  </td>
                )}
              </tr>
            ))}
          </table>
        </FlexItem>
      </Flex>
    </Box>
  );
};

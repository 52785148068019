import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { BotTabs, useCurrentBotId } from '@utils/Routing';

export const useNavigateToTemplate = () => {
  const history = useHistory();
  const botId = useCurrentBotId();

  const navigateToTemplate = useCallback(
    (id: string) => {
      if (!botId) {
        return;
      }
      history.push(`/bot/${botId}/${BotTabs.messagesTemplates}/${id}`);
    },
    [botId, history],
  );

  return { navigateToTemplate };
};

import React, { useCallback, useState } from 'react';
import { Type } from '@ui/Type';
import { humanizeNumber } from '@utils/humanizeNumber';
import { Flex } from '@ui/Flex';
import { Button, ButtonUnstyled } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import nanoid from 'nanoid';
import { Hover } from 'react-powerplug';
import { removeTypename } from '@utils/GQL/utils';
import { useCurrentBotId } from '@utils/Routing';
import { useMutation } from '@apollo/react-hooks';
import { sendEvent } from '@utils/Analytics';
import { Tooltip2 } from '@ui/Tooltip2';
import {
  Messages,
  ServiceMessageType,
  toaster,
} from '@services/MessageService';
import { removeEntity } from '@components/FlowBuilder/components';
import { useAutomateEnabled } from '@utils/Data/Admin/Automate';
import { IconButton } from '@ui/IconButton';
import {
  CreateAndTeachKeywordsIntentMutation,
  CreateAndTeachKeywordsIntentMutationVariables,
} from './@types/CreateAndTeachKeywordsIntentMutation';
import * as css from './FrequentUnrecognizedMessagesItem.css';
import {
  FrequentUnrecognizedMessagesQuery,
  FrequentUnrecognizedMessagesQuery_bot_aiPhraseGroups,
  FrequentUnrecognizedMessagesQueryVariables,
} from '../@types/FrequentUnrecognizedMessagesQuery';
import { FrequentUnrecognizedMessagesItemIntentSelector } from './FrequentUnrecognizedMessagesItemIntentSelector';
import { FrequentUnrecognizedMessagesItemText } from './FrequentUnrecognizedMessagesItemText';
import { AiIntentActionItemType, Platform } from '@globals';
import {
  TeachKeywordsIntentMutation,
  TeachKeywordsIntentMutation_removeAiPhraseGroup,
  TeachKeywordsIntentMutationVariables,
} from './@types/TeachKeywordsIntentMutation';
import {
  RemoveKeywordsPhraseToBlacklistMutation,
  RemoveKeywordsPhraseToBlacklistMutationVariables,
} from './@types/RemoveKeywordsPhraseToBlacklistMutation';
import {
  CREATE_AND_TEACH_KEYWORDS_INTENT_MUTATION,
  REMOVE_KEYWORDS_PHRASE_TO_BLACKLIST_MUTATION,
  TEACH_KEYWORDS_INTENT_MUTATION,
} from './queries';
import {
  ResponseTypeSelector,
  TeachVariantsIds,
} from '../../common/ResponseTypeSelector/ResponseTypeSelector';
import { BlocksSelectorWithData } from '../../common/BlocksSelectorWithData';
import { FREQUENT_UNRECOGNIZED_MESSAGES_QUERY } from '../queries';
import { MOBILE_WIDTH } from '../../../consts';
import { TextEllipsis } from '@ui/TextEllipsis';
import { Icon } from '@ui/Icon';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { AI_INTENTS_LIST_QUERY } from '@utils/Data/Ai/Groups/queries';
import i18next from 'i18next';
import client from '../../../../../../common/services/ApolloService';
import { BlockGroupsQuery_bot_blocksGroups_blocks } from '../../../../../AiSetupPage/@types/BlockGroupsQuery';
import { useIsWindowWidthLess } from '@utils/DOM/useIsWindowWidthLess';

interface TeachYourBotItemProps
  extends FrequentUnrecognizedMessagesQuery_bot_aiPhraseGroups {
  platform: Platform;
  showDivider: boolean;
}

interface ItemState {
  type: TeachVariantsIds;
  touched: boolean;
  text?: string;
  blocks?: BlockGroupsQuery_bot_blocksGroups_blocks[];
  groupId?: string;
  intentId?: string;
}

interface ShowSuccessToasterForPhraseArg {
  removeAiPhraseGroup: TeachKeywordsIntentMutation_removeAiPhraseGroup;
}

const EVENT_ACTION_BY_TEACH_ID = {
  [TeachVariantsIds.text]: 'set up text',
  [TeachVariantsIds.block_or_flow]: 'set up block or flow',
  [TeachVariantsIds.ai_intent]: 'set up intent',
};

const isItemValid = (state: ItemState) => {
  switch (state.type) {
    case TeachVariantsIds.text:
      return !!state.text?.length;
    case TeachVariantsIds.block_or_flow:
      return !!state.blocks?.length;
    case TeachVariantsIds.ai_intent:
      return !!state.intentId;
    default:
      throw new Error('Invalid teach variant');
  }
};

const SHOW_TOOLTIP_PHRASE_LIMIT = 19;

const showErrorToaster = () => {
  toaster.show({
    type: ServiceMessageType.error,
    payload: {
      message: Messages.somethingWentWrong,
    },
  });
};

const showSuccessToaster = (phrase: string) => {
  toaster.show({
    type: ServiceMessageType.default,
    payload: {
      message: i18next.t(
        'pages.Keywords.FrequentUnrecognizedMessages.item.doneToaster',
        { phrase },
      ),
    },
  });
};

const SHOW_ALL_DONE_TIMEOUT = 1000;

export const FrequentUnrecognizedMessagesItem: React.FC<TeachYourBotItemProps> =
  ({ phrase, count, platform, showDivider }) => {
    const isMobileWindowSize = useIsWindowWidthLess(MOBILE_WIDTH);
    const botId = useCurrentBotId();
    const { t } = useSafeTranslation();
    const [expand, setExpand] = useState<boolean>(false);
    const [itemState, setItemState] = useState<ItemState>({
      type: TeachVariantsIds.text,
      touched: false,
    });

    const { isAutomateEnabled } = useAutomateEnabled();

    const showSuccessToasterForPhrase = useCallback(
      ({
        removeAiPhraseGroup: { aiPhraseGroups },
      }: ShowSuccessToasterForPhraseArg) => {
        showSuccessToaster(phrase);
        if (!aiPhraseGroups.length) {
          setTimeout(() => {
            toaster.show({
              type: ServiceMessageType.default,
              payload: {
                message: `${t(
                  'pages.Keywords.FrequentUnrecognizedMessages.item.addDone',
                )}`,
              },
            });
          }, SHOW_ALL_DONE_TIMEOUT);
        }
      },
      [phrase, t],
    );

    const [createAndTeachAiIntentMutation, { loading: creationLoading }] =
      useMutation<
        CreateAndTeachKeywordsIntentMutation,
        CreateAndTeachKeywordsIntentMutationVariables
      >(CREATE_AND_TEACH_KEYWORDS_INTENT_MUTATION, {
        onError: showErrorToaster,
        onCompleted: showSuccessToasterForPhrase,
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: AI_INTENTS_LIST_QUERY, variables: { botId } },
        ],
      });

    const [teachAiIntentMutation, { loading: updateLoading }] = useMutation<
      TeachKeywordsIntentMutation,
      TeachKeywordsIntentMutationVariables
    >(TEACH_KEYWORDS_INTENT_MUTATION, {
      onError: showErrorToaster,
      onCompleted: showSuccessToasterForPhrase,
      awaitRefetchQueries: true,
      refetchQueries: [{ query: AI_INTENTS_LIST_QUERY, variables: { botId } }],
    });

    const [removePhraseToBlacklistMutation] = useMutation<
      RemoveKeywordsPhraseToBlacklistMutation,
      RemoveKeywordsPhraseToBlacklistMutationVariables
    >(REMOVE_KEYWORDS_PHRASE_TO_BLACKLIST_MUTATION, {
      onError: showErrorToaster,
    });

    const createAndTeachAiIntent = useCallback(() => {
      if (!botId) {
        throw new Error('Empty botId');
      }
      const id = nanoid();
      const isTextIntent = itemState.type === TeachVariantsIds.text;

      const actionItems = [
        {
          item_type: isTextIntent
            ? AiIntentActionItemType.text
            : AiIntentActionItemType.block,
          blocks: isTextIntent ? null : removeTypename(itemState.blocks || {}),
          text: isTextIntent ? itemState.text : null,
        },
      ];

      createAndTeachAiIntentMutation({
        variables: {
          botId,
          platform,
          phrase,
          intent: {
            id,
            intent_id: id,
            lines: [phrase],
            action: {
              random: false,
              items: platform === Platform.facebook ? actionItems : [],
            },
            ig_action: {
              random: false,
              items: platform === Platform.instagram ? actionItems : [],
            },
            wa_action: {
              random: false,
              items: platform === Platform.whatsapp ? actionItems : [],
            },
          },
        },
      });
    }, [
      botId,
      itemState.type,
      itemState.blocks,
      itemState.text,
      createAndTeachAiIntentMutation,
      platform,
      phrase,
    ]);

    const teachAiIntent = useCallback(() => {
      const { intentId } = itemState;
      if (!botId || !intentId) {
        return;
      }
      teachAiIntentMutation({
        variables: {
          botId,
          phrase,
          intentId,
          platform,
        },
      });
    }, [itemState, botId, teachAiIntentMutation, phrase, platform]);

    const removePhraseToBlacklist = useCallback(() => {
      if (!botId) {
        return;
      }

      sendEvent({
        category: 'keywords',
        label: 'frequent unrecognized messages',
        action: 'delete confirm',
      });

      let frequentUnrecognizedMessagesQuery: FrequentUnrecognizedMessagesQuery | null;
      try {
        frequentUnrecognizedMessagesQuery = client.readQuery<
          FrequentUnrecognizedMessagesQuery,
          FrequentUnrecognizedMessagesQueryVariables
        >({
          query: FREQUENT_UNRECOGNIZED_MESSAGES_QUERY,
          variables: {
            platform,
            botId,
          },
        });
      } catch {
        frequentUnrecognizedMessagesQuery = null;
      }

      removePhraseToBlacklistMutation({
        variables: {
          platform,
          botId,
          phrase,
        },
        optimisticResponse: frequentUnrecognizedMessagesQuery
          ? {
              removeAiPhraseGroup: {
                ...frequentUnrecognizedMessagesQuery.bot,
                aiPhraseGroups:
                  frequentUnrecognizedMessagesQuery.bot.aiPhraseGroups.filter(
                    ({ phrase: phraseItem }) => phraseItem !== phrase,
                  ),
              },
            }
          : undefined,
      });
    }, [botId, removePhraseToBlacklistMutation, platform, phrase]);

    const removePhrase = useCallback(() => {
      removeEntity({
        onSubmit: removePhraseToBlacklist,
        renderHeading: () =>
          `${t(
            'pages.Keywords.FrequentUnrecognizedMessages.item.removePhraseTooltip',
            { phrase },
          )}`,
        renderActionText: () =>
          t('pages.Keywords.FrequentUnrecognizedMessages.item.remove'),
        renderNoteText: () => t('pages.BotPage.HomeTab.TeachYourBot.cantUndo'),
      });
      sendEvent({
        category: 'keywords',
        label: 'frequent unrecognized messages',
        action: 'delete click',
      });
    }, [phrase, removePhraseToBlacklist, t]);

    if (!botId) {
      return null;
    }

    return (
      <Hover>
        {({ hovered, bind }) => (
          <div
            className={css.row}
            data-testid="keywords__frequent-unrecognized-messages__row"
            {...bind}
          >
            <ButtonUnstyled
              className={css.expandHit}
              onClick={() => {
                setExpand((expand) => !expand && isMobileWindowSize);
                sendEvent({
                  category: 'keywords',
                  label: 'frequent unrecognized messages',
                  action: 'expand item click',
                });
              }}
              data-testid="keywords__keywords-groups__expand-button"
            >
              <Flex alignItems="center" className={css.messageCol}>
                {phrase.length > SHOW_TOOLTIP_PHRASE_LIMIT ? (
                  <Tooltip2
                    content={
                      <div style={{ wordWrap: 'break-word' }}>{phrase}</div>
                    }
                    placement="right"
                    type="small"
                    boundariesElement="viewport"
                  >
                    {(ref, bind) => (
                      <Flex
                        alignItems="center"
                        className={css.message}
                        ref={ref}
                        {...bind}
                      >
                        <TextEllipsis>
                          <Type
                            data-testid="keywords__frequent-unrecognized-messages__phrase"
                            size="15px"
                          >
                            {phrase}
                          </Type>
                        </TextEllipsis>
                      </Flex>
                    )}
                  </Tooltip2>
                ) : (
                  <Flex alignItems="center" className={css.message}>
                    <TextEllipsis>
                      <Type
                        data-testid="keywords__frequent-unrecognized-messages__phrase"
                        size="15px"
                      >
                        {phrase}
                      </Type>
                    </TextEllipsis>
                  </Flex>
                )}
              </Flex>
              <Flex
                justifyContent="center"
                alignItems="center"
                className={css.frequencyCol}
              >
                <Type
                  data-testid="keywords__frequent-unrecognized-messages__frequency"
                  size="15px_DEPRECATED"
                >
                  {humanizeNumber(count)}
                </Type>
              </Flex>
              {isMobileWindowSize && (
                <Flex className={css.triangleCol} alignItems="center">
                  <Icon
                    icon="triangle"
                    style={{
                      transform: `rotate(${expand ? '-180deg' : '0deg'})`,
                    }}
                  />
                </Flex>
              )}
            </ButtonUnstyled>
            {(!isMobileWindowSize || expand) && (
              <Flex alignItems="center" className={css.selectorsBox}>
                <Flex className={css.selectorsSubBox}>
                  <ResponseTypeSelector
                    showBlocksVariant={
                      isAutomateEnabled && platform !== Platform.instagram
                    }
                    onChange={(item) => {
                      setItemState({
                        ...itemState,
                        touched: true,
                        type: item?.id as TeachVariantsIds,
                      });
                    }}
                  />
                  <Spacer factor={4} horizontalFactor={2} />

                  {itemState.type === TeachVariantsIds.text && (
                    <FrequentUnrecognizedMessagesItemText
                      value={itemState.text || ''}
                      onChange={(value) => {
                        setItemState({
                          ...itemState,
                          text: value,
                        });
                      }}
                      autoFocus={itemState.touched}
                      platform={platform}
                    />
                  )}
                  {itemState.type === TeachVariantsIds.block_or_flow && (
                    <BlocksSelectorWithData
                      selectedBlocks={itemState.blocks || []}
                      onBlocksSelected={(blocksSelected) => {
                        setItemState({
                          ...itemState,
                          blocks: blocksSelected,
                        });
                      }}
                      autoFocus={itemState.touched}
                      platform={platform}
                      data-testid="keywords__frequent-unrecognized-messages__response-blocks-selector"
                    />
                  )}
                  {itemState.type === TeachVariantsIds.ai_intent && (
                    <FrequentUnrecognizedMessagesItemIntentSelector
                      selectedIntentId={itemState.intentId}
                      onIntentSelected={(intentId) => {
                        setItemState({
                          ...itemState,
                          intentId,
                        });
                      }}
                      autoFocus={itemState.touched}
                      platform={platform}
                    />
                  )}

                  <Spacer factor={4} horizontalFactor={4} />
                  <Flex>
                    <Button
                      className={css.actionButton}
                      data-testid="keywords__frequent-unrecognized-messages__set_up-button"
                      intent="primary"
                      disabled={
                        !isItemValid(itemState) ||
                        creationLoading ||
                        updateLoading
                      }
                      onClick={() => {
                        if (itemState.type === TeachVariantsIds.ai_intent) {
                          teachAiIntent();
                        } else {
                          createAndTeachAiIntent();
                        }
                        sendEvent({
                          category: 'keywords',
                          label: 'frequent unrecognized messages',
                          action: EVENT_ACTION_BY_TEACH_ID[itemState.type],
                        });
                      }}
                    >
                      {t(
                        'pages.Keywords.FrequentUnrecognizedMessages.item.setUp',
                      )}
                    </Button>
                    {isMobileWindowSize && (
                      <>
                        <Spacer factor={1} horizontalFactor={4} />
                        <Button
                          className={css.actionButton}
                          data-testid="keywords__frequent-unrecognized-messages__delete-button"
                          onClick={removePhrase}
                          intent="secondary"
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </Flex>
                  {isMobileWindowSize && showDivider && (
                    <>
                      <Spacer factor={7} />
                      <div className={css.divider} />
                      <Spacer factor={4} />
                    </>
                  )}
                  <Flex className={css.deleteButton} alignItems="center">
                    <Spacer horizontalFactor={1} factor={2} />
                    {hovered && !isMobileWindowSize ? (
                      <IconButton
                        data-testid="keywords__frequent-unrecognized-messages__delete"
                        icon="delete"
                        onClick={removePhrase}
                      />
                    ) : (
                      <Spacer factor={1} horizontalFactor={6} />
                    )}
                  </Flex>
                </Flex>
              </Flex>
            )}
          </div>
        )}
      </Hover>
    );
  };

import { Spacer } from '@ui/Spacer';
import { equals } from 'ramda';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import { insertText } from '@utils/Slite/insertText';
import { Editor } from 'slate-react';
import { wrapAllAttributes } from '@ui/TextWithAttributesEditor/attributesBoundariesUtils';
import { Input } from '@ui/Input';
import cn from 'classnames';
import {
  deserialize,
  TextWithAttributesEditor,
} from '@ui/TextWithAttributesEditor';
import { filterAttributes } from '@utils/AttributesUtils/AttributesUtils';
import { Flex } from '@ui/Flex';
import { FocusWithin } from '@ui/FocusWithin';
import React, { ForwardRefExoticComponent, useEffect, useState } from 'react';
import { useAutomateEnabled } from '@utils/Data/Admin/Automate';
import { useAttributes } from '@utils/AttributesUtils/AttributesData';
import { useCurrentBotId } from '@utils/Routing';
import { AiIntentsListQuery_bot_aiBlock_cards_AIPlugin_config_groups_intents_action_items_blocks as Block } from '@utils/Data/Ai/Groups/@types/AiIntentsListQuery';
import { FlexProps } from '@ui/Flex/Flex';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { AiIntentActionItemType, Platform } from '@globals';
import { TeachVariantsIds } from '../ResponseTypeSelector/ResponseTypeSelector';
import {
  AI_INTENT_ACTION_ITEM_TYPE_TO_RESPONSE_TYPE,
  RESPONSE_TYPE_TO_AI_INTENT_ACTION_ITEM_TYPE,
} from '../ResponseTypeSelector/consts';
import { ResponseTypeSelector } from '../ResponseTypeSelector';
import { BlocksSelectorWithData } from '../BlocksSelectorWithData';
import * as css from './Respond.css';
import { sendEvent } from '@utils/Analytics';
import { VariableSuggestType } from '@utils/AttributesUtils/AttributesUtilsTypes';
import { usePrevious } from 'cf-common/src/utils/hooks';

interface RespondProps extends Omit<FlexProps, 'onBlur'> {
  platform: Platform;
  type: AiIntentActionItemType;
  text: string | undefined;
  blocks: Block[] | undefined;
  onTypeChange: (type: AiIntentActionItemType) => void;
  onTextChange: (text: string) => void;
  onBlocksChange: (blocks: Block[]) => void;
  eventLabel: string;
  autoFocus?: boolean;
  rightElement?: React.ReactNode;
  required?: boolean;
  'data-testid'?: string;
}

const TEXT_LIMIT = 2000;

export const Respond: React.FC<RespondProps> = ({
  platform,
  type,
  text = '',
  blocks: initBlocks = [],
  onTypeChange,
  onTextChange,
  onBlocksChange,
  ref,
  autoFocus,
  rightElement,
  className,
  eventLabel,
  required,
  'data-testid': dataTestid,
  ...flexProps
}) => {
  const { t } = useSafeTranslation();
  const { isAutomateEnabled } = useAutomateEnabled();
  const botId = useCurrentBotId();
  const [blocks, setBlocks] = useState<Block[]>(initBlocks);
  const { attributes } = useAttributes(
    botId,
    VariableSuggestType.template,
    platform,
  );
  const [currentText, setCurrentText] = useState<string>(text);

  const prevPlatform = usePrevious(platform);

  useEffect(() => {
    if (platform !== prevPlatform) {
      setBlocks(initBlocks);
    }
  }, [initBlocks, prevPlatform, platform]);

  return (
    <Flex
      className={cn(className, css.box)}
      {...(flexProps as ForwardRefExoticComponent<FlexProps>)}
      data-testid={dataTestid}
    >
      <ResponseTypeSelector
        key={platform}
        showBlocksVariant={isAutomateEnabled && platform === Platform.facebook}
        onChange={({ id }) => {
          const updatedType =
            RESPONSE_TYPE_TO_AI_INTENT_ACTION_ITEM_TYPE[
              id as TeachVariantsIds
            ] || AiIntentActionItemType.text;
          if (updatedType !== type) {
            onTypeChange(updatedType);
            sendEvent({
              category: 'keywords',
              label: eventLabel,
              action: 'response type change',
              propertyBag: {
                updatedType,
              },
            });
          }
        }}
        data-testid={dataTestid}
        excludeIds={[TeachVariantsIds.ai_intent]}
        initialSelectedId={AI_INTENT_ACTION_ITEM_TYPE_TO_RESPONSE_TYPE[type]}
      />
      <Spacer horizontalFactor={2} factor={4} />
      <FocusWithin
        render={({ focusedWithin, bind }) => (
          <div className={css.inputBox} {...bind}>
            {type === AiIntentActionItemType.text && (
              <TextOutsideControls
                onInsertRequest={(text, el) => {
                  insertText(text, el as Editor);
                }}
                currentTextLimit={
                  TEXT_LIMIT
                    ? TEXT_LIMIT -
                      wrapAllAttributes(currentText, () => '').trim().length
                    : undefined
                }
                shouldShowOutsideControls={{
                  emoji: true,
                  symbolsLimit: true,
                  attributes: true,
                }}
                initialShow={false}
                boxStyle={{
                  width: '100%',
                }}
                tight
              >
                {({ ref, getInputProps }) => (
                  <Input
                    error={required && !currentText && !focusedWithin} // prevent red blink on first save
                    render={() => (
                      <Flex alignItems="center">
                        <TextWithAttributesEditor
                          placeholder={t(
                            'pages.Keywords.KeywordGroups.textPlaceholder',
                          )}
                          autoFocus={autoFocus}
                          key={platform}
                          data-testid={`${dataTestid}__response-text-input`}
                          className={css.input}
                          defaultValue={deserialize(text || '')}
                          attributes={filterAttributes(attributes, false)}
                          maxLength={TEXT_LIMIT}
                          editorRef={ref}
                          hasManageAttributes
                          onFocus={getInputProps().onFocus}
                          onStringChange={setCurrentText}
                          onKeyDown={(event) => {
                            const { key, shiftKey } = event as KeyboardEvent;
                            if (key === 'Enter' && !shiftKey) {
                              ref.current?.blur();
                              return false;
                            }
                            return undefined;
                          }}
                          onBlur={(event, value) => {
                            onTextChange(value);
                            (getInputProps().onBlur as Function)(event);
                            sendEvent({
                              category: 'keywords',
                              label: eventLabel,
                              action: 'response text change',
                            });
                          }}
                        />
                        {rightElement}
                      </Flex>
                    )}
                  />
                )}
              </TextOutsideControls>
            )}
            {type === AiIntentActionItemType.block && (
              <BlocksSelectorWithData
                key={platform}
                style={{
                  width: 'calc(100% + 24px)',
                }}
                autoFocus={!!autoFocus}
                onBlocksSelected={(updatedBlocks) => {
                  if (equals(blocks, updatedBlocks)) {
                    return;
                  }
                  sendEvent({
                    category: 'keywords',
                    label: eventLabel,
                    action: 'response blocks change',
                  });
                  setBlocks(updatedBlocks);
                  onBlocksChange(updatedBlocks);
                }}
                selectedBlocks={blocks}
                platform={platform}
                rightElement={rightElement}
                error={required && !blocks.length && !focusedWithin}
                data-testid={`${dataTestid}__response-blocks-selector`}
              />
            )}
          </div>
        )}
      />
    </Flex>
  );
};

import React, { useEffect } from 'react';
import { BotTabPlaceholder } from '@components/BotTab';
import { log } from 'cf-common/src/logger';
import { ReactComponent as ExclamationMarkIcon } from '../../../../assets/zero-state/exclamation-mark.svg';

export const FlowNotFoundPlaceholder = ({ flowId }: { flowId?: string }) => {
  useEffect(() => {
    log.error({
      msg: 'Flow loading error',
      data: { flowId, label: 'flow_builder_core' },
    });
  }, [flowId]);

  return (
    <BotTabPlaceholder
      icon={<ExclamationMarkIcon />}
      title={window.i18next.t(
        'FlowNotFoundPlaceholder-string-1197-couldnt-load-flow',
      )}
      subtitle={
        <span>
          {window.i18next.t(
            'FlowNotFoundPlaceholder-JSXText-3095-there-was-an-error-loading-this-flow',
          )}
          <br />
          {window.i18next.t(
            'FlowNotFoundPlaceholder-JSXText--103-please-check-the-url-and-try-again',
          )}
        </span>
      }
    />
  );
};

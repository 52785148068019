import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import React, { useState } from 'react';
import { HighlightBorderBoxHideable } from '@ui/Box/HighlightBorderBoxHideable';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { IntroQuestionsBlockQuestionRadio } from '../IntroQuestionsBlock/components/IntroQuestionsBlockQuestionRadio';
import { Footer } from '../common/Footer/Footer';
import { FirstSessionStep } from '../../consts';
import noop from 'lodash-es/noop';
import { ReactComponent as DiscountSvg } from './discount.svg';
import css from './WADiscounts.css';
import { Platform, WhatsappMigrationStatus } from '@globals';
import { sendEvent } from '@utils/Analytics';

interface WADiscountsProps {
  goPrevStep: VoidFunction;
  onFinish(status: WhatsappMigrationStatus): void;
}

enum Discount {
  yes = 'yes',
  no = 'no',
}

export const WADiscounts: React.FC<WADiscountsProps> = ({
  goPrevStep,
  onFinish,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const { t } = useSafeTranslation();
  const [discount, setDiscount] = useState<string[]>([Discount.yes]);
  const handleNextClick = () => {
    sendEvent({
      category: 'first-session',
      action: 'done step click',
      propertyBag: {
        platform: Platform.whatsapp,
        currentStep: FirstSessionStep.waDiscounts,
      },
    });
    const status =
      discount[0] === Discount.yes
        ? WhatsappMigrationStatus.prolong_trial
        : WhatsappMigrationStatus.need_migration;
    onFinish(status);
  };

  return (
    <HighlightBorderBoxHideable
      hide={isSmallScreenSize}
      highlightEnabled={false}
      borderRadiusFactor={3}
    >
      <div className={css.title}>
        <Type size={isSmallScreenSize ? '18px' : '24px'} weight="semibold">
          {t('pages.FirstSession.WADiscounts.get')}{' '}
        </Type>
        <DiscountSvg className={css.discountSvg} />
        <Type
          size={isSmallScreenSize ? '18px' : '24px'}
          weight="semibold"
          color="violet"
        >
          {' '}
          {t('pages.FirstSession.WADiscounts.monthsOfSubscriptionFREE')}{' '}
        </Type>
        <Type size={isSmallScreenSize ? '18px' : '24px'} weight="semibold">
          {t('pages.FirstSession.WADiscounts.forCreatingNewTemplates')}
        </Type>
      </div>
      <Spacer factor={2} />
      <Type whiteSpace="pre-line">
        {t('pages.FirstSession.WADiscounts.transferringExistingTemplates')}
      </Type>
      <Spacer factor={5} />
      <IntroQuestionsBlockQuestionRadio
        id="discount_radio_buttons"
        data-testid="discount_radio_buttons"
        required
        title=""
        onChange={setDiscount}
        onBlur={noop}
        items={[
          {
            id: Discount.yes,
            title: (
              <div>
                <Type weight="semibold">
                  {t('pages.FirstSession.WADiscounts.get')}{' '}
                </Type>
                <Type weight="semibold" color="violet">
                  {t('pages.FirstSession.WADiscounts.monthsOfFreeSubscription')}
                </Type>
              </div>
            ),
            description: t(
              'pages.FirstSession.WADiscounts.andCreateNewTemplates',
            ),
          },
          {
            id: Discount.no,
            title: t('pages.FirstSession.WADiscounts.noThanks'),
            description: t('pages.FirstSession.WADiscounts.iWantKeepExisting'),
          },
        ]}
        selectedItemIds={discount}
      />
      <Spacer factor={8} />
      <Footer
        nextButton={{
          content: t('pages.FirstSession.WADiscounts.finish'),
          onClick: handleNextClick,
        }}
        onGoToPrevClick={goPrevStep}
      />
    </HighlightBorderBoxHideable>
  );
};

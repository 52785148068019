import React from 'react';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import { Input } from '@ui/Input';
import { InputLikeElement, insertText } from '@utils/documentExecCommand';
import {
  MessagesTemplateFields,
  useExtendedFormAction,
} from '../../form/ExtendFormActionsContext';

export const MAX_NAME_LENGTH = 512;
const TEMPLATE_NAME_INPUT_REGEXP = new RegExp('[a-zA-Z0-9_ ]');
const SPACE_REGEXP = new RegExp('[ ]', 'g');
const TEMPLATE_NAME_REGEXP = new RegExp('[^a-z0-9_]+', 'g');
const toProperName = (name: string) =>
  name
    .toLowerCase()
    .replace(SPACE_REGEXP, '_')
    .replace(TEMPLATE_NAME_REGEXP, '');

export const NameInput = () => {
  const {
    values,
    setName,
    touched,
    errors,
    saveForm,
    setFieldTouched,
    formDisabled,
  } = useExtendedFormAction();

  return (
    <TextOutsideControls
      onInsertRequest={(text, el) => {
        insertText(text, el as InputLikeElement);
      }}
      currentTextLimit={MAX_NAME_LENGTH - (values.name || '').trim().length}
      shouldShowOutsideControls={{
        symbolsLimit: true,
      }}
      tight
      initialShow={false}
    >
      {({ ref, getInputProps }) => (
        <Input
          disabled={formDisabled}
          error={touched.name && errors.name}
          data-testid="messages-template-header__name-input"
          value={values.name || ''}
          onKeyPress={(e) => {
            if (!TEMPLATE_NAME_INPUT_REGEXP.test(e.key)) {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            setName(toProperName(e.target.value));
          }}
          maxLength={MAX_NAME_LENGTH}
          {...getInputProps({
            onBlur: () => {
              saveForm();
              setFieldTouched(MessagesTemplateFields.name, true);
            },
          } as any)}
          ref={ref}
        />
      )}
    </TextOutsideControls>
  );
};

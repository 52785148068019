import React, { useCallback } from 'react';
import { useBillingData } from '../Data/useBillingData';
import { useCurrentBotId } from '@utils/Routing';
import { Tooltip2, Tooltip2ChildrenProps } from '@ui/Tooltip2';
import { Modal } from '@services/.';
import { PageBillingPeriodDialog } from '../../../../../Plans/components/BillingPeriodDialog/PageBillingPeriodDialog';
import { BillingPeriodActionType } from '../../../../../Plans/components/TierCard/types';
import { usePaymentFlow } from '../../../../../Plans/hooks/usePaymentFlow';
import { Period } from '@globals';
import { formatPrice } from '@utils/Format';
import { getTierNameByType } from '@components/DialogsPricing/constants';
import { usePricingPeriodList } from '@utils/Data/Pricing/hooks';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  SizeDependentButton,
  SizeDependentButtonProps,
} from './SizeDependentButton';

interface ChangeBillingTooltipProps {
  disabled: boolean;
  children: Tooltip2ChildrenProps;
}

const ChangeBillingTooltip: React.FC<ChangeBillingTooltipProps> = ({
  children,
  disabled,
}) => {
  const { t } = useSafeTranslation();
  const { tier, pricing, isAnnualSubscription, moreExpensiveTier } =
    useBillingData();

  const botId = useCurrentBotId()!;

  const { data } = usePricingPeriodList({
    botId,
    pricingPlanId: pricing?.pricing_plan_id,
    currency: pricing?.currency,
    tierType: tier?.type,
  });

  const annualPeriod = data.find(({ period }) => period === Period.annual);

  const saveValue =
    annualPeriod &&
    formatPrice(annualPeriod.save, {
      currency: pricing?.currency,
      decimals: 2,
    });
  const plan = moreExpensiveTier && getTierNameByType(moreExpensiveTier.type);

  const tooltipContent = !isAnnualSubscription
    ? t('pages.Billing.unabledPaymentsCallout.payAndSave', {
        saveValue,
        plan,
      })
    : null;

  return (
    <Tooltip2
      boundariesElement="viewport"
      content={tooltipContent}
      disabled={!tooltipContent || disabled}
    >
      {children}
    </Tooltip2>
  );
};

export interface ChangeBillingPeriodButtonProps
  extends SizeDependentButtonProps {
  shortText?: boolean;
}

export const ChangeBillingPeriodButton: React.FC<ChangeBillingPeriodButtonProps> =
  ({ shortText, onClick, ...props }) => {
    const { t } = useSafeTranslation();
    const { tier, tiers, pricing } = useBillingData();

    const botId = useCurrentBotId()!;
    const { onUpgradeTier } = usePaymentFlow({
      botId,
      pricing,
      currentTier: tier,
      tiers: tiers!,
    });

    const onButtonClick = useCallback(() => {
      if (!tier || !pricing) {
        return null;
      }

      return Modal.show(
        ({ close }) => (
          <PageBillingPeriodDialog
            actionType={BillingPeriodActionType.change}
            close={close}
            pricing={pricing}
            tierType={tier.type}
            currency={pricing.currency}
            onUpdate={(tier, period) => onUpgradeTier(tier, period)}
          />
        ),
        {
          mobileAdaptive: true,
          mobileProps: {
            fitHeight: true,
          },
        },
      );
    }, [pricing, tier, onUpgradeTier]);

    return (
      <ChangeBillingTooltip disabled={Boolean(props.disabled)}>
        {(ref, bind) => (
          <SizeDependentButton
            ref={ref}
            {...bind}
            data-testid="bot-panel__change-period-button"
            intent="secondary"
            onClick={onButtonClick}
            {...props}
          >
            {shortText
              ? t('pages.Billing.periodDialog.changePeriodShort')
              : t('pages.Billing.periodDialog.changePeriod')}
          </SizeDependentButton>
        )}
      </ChangeBillingTooltip>
    );
  };

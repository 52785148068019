import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import { sendEvent } from '@utils/Analytics';
import { useWhatsappConnected } from '@utils/Data/Whatsapp';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import { CenteredLoader } from '@ui/Loader';
import * as css from './WhatsappNotConnected.css';

export const WhatsappNotConnected: React.FC = ({ children }) => {
  const botId = useCurrentBotId()!;
  const { t } = useSafeTranslation();
  const { hasSharedAccounts, loading } = useWhatsappConnected(botId);
  const history = useHistory();
  const goToHomeTab = useCallback(() => {
    sendEvent({
      category: 'whatsapp reengage page',
      action: 'connect whatsapp click',
    });
    history.push(getTabLink(BotTabs.home, botId));
  }, [botId, history]);

  if (loading) return <CenteredLoader />;

  if (!hasSharedAccounts) {
    return (
      <Flex alignItems="center" justifyContent="center" className={css.wrapper}>
        <Flex
          flexDirection="column"
          alignItems="center"
          className={css.container}
        >
          <Type as="h1" size="24px" weight="semibold" align="center">
            {t('pages.BotPage.ReengageWhatsappTab.whatsappNotConnected.header')}
          </Type>

          <Spacer factor={6} />

          <Button
            intent="primary"
            onClick={goToHomeTab}
            data-testid="reengage__connect-whatsapp"
          >
            {t(
              'pages.BotPage.ReengageWhatsappTab.whatsappNotConnected.buttonTitle',
            )}
          </Button>
        </Flex>
      </Flex>
    );
  }

  return <>{children}</>;
};

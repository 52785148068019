import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { IconButton } from '@ui/IconButton';
import { Button } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import {
  BotTabs,
  getTabLink,
  useCurrentBotId,
  useCurrentMessageTemplateId,
} from '@utils/Routing';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { BackButton } from '@components/BackButton';
import { useMessageTemplates } from '../../MessagesTemplatesTable/hooks/useMessageTemplates';
import * as css from './MessagesTemplateHeader.css';
import { useExtendedFormAction } from '../form/ExtendFormActionsContext';
import { logEvent } from '../utils';
import { MessagesTemplateHeaderCallout } from './MessagesTemplateHeaderCallout';

export interface MessagesTemplateHeaderProps {
  submitLoading: boolean;
  deleteLoading: boolean;
}

export const MessagesTemplateHeader: React.FC<MessagesTemplateHeaderProps> = ({
  submitLoading,
  deleteLoading,
}) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  const messageTemplateId = useCurrentMessageTemplateId()!;
  const { sendForReview, removeTemplate, deleting, reviewing } =
    useMessageTemplates();
  const { isValid, formDisabled } = useExtendedFormAction();
  const history = useHistory();

  const templatesTableLink = getTabLink(BotTabs.messagesTemplates, botId);

  const onDeleteClick = useCallback(() => {
    removeTemplate(messageTemplateId)?.then(() => {
      history.push(templatesTableLink);
    });
  }, [history, messageTemplateId, removeTemplate, templatesTableLink]);

  const onSendForReviewClick = useCallback(() => {
    logEvent({ action: 'send for review' });
    sendForReview(messageTemplateId);
  }, [messageTemplateId, sendForReview]);

  return (
    <header className={css.header}>
      <MessagesTemplateHeaderCallout />

      <Flex justifyContent="space-between">
        <Flex alignItems="center">
          <BackButton
            url={templatesTableLink}
            text={t('pages.MessagesTemplates.back')}
            data-testid="messages-template-header__back-button"
          />
          <Spacer factor={0} horizontalFactor={4} />
          <Type size="12px" weight="regular" color="cobaltTertiary">
            {t('pages.MessagesTemplates.header.autosave')}
          </Type>
        </Flex>

        <Flex alignItems="center">
          <IconButton
            icon="delete"
            color="blue"
            disabled={deleting || deleteLoading}
            onClick={onDeleteClick}
            data-testid="messages-template-header__delete"
          />
          <Spacer factor={0} horizontalFactor={3} />
          <Button
            type="submit"
            loading={reviewing || submitLoading}
            disabled={formDisabled || !isValid || reviewing || submitLoading}
            onClick={onSendForReviewClick}
            data-testid="messages-template-header__send-for-review"
          >
            {t('pages.MessagesTemplates.header.submit')}
          </Button>
        </Flex>
      </Flex>
    </header>
  );
};

import React from 'react';
import { useToaster } from '@ui/Toaster/ToasterProvider';
import { sendEvent } from '@utils/Analytics';
import { useCurrentBotId } from '@utils/Routing';
import { useFlowSharingUpdate } from './FlowsMutations';
import { ShareFlowModal } from '../ShareFlowModal';
import { FlowSharingInput } from '../../../../../../@types/globalTypes';
import { useFlowShareState } from '../../common/useFlowsShareState';

interface ShareFlowModalProps {
  flowId: string;
  sharingParams?: FlowSharingInput | null;
  disabled: boolean;
}

export const ShareItemModal: React.FC<ShareFlowModalProps> = ({
  flowId,
  sharingParams,
  disabled,
}) => {
  const botId = useCurrentBotId();
  const { addToaster } = useToaster();
  const { hasSharedFlow, setHasSharedFlow } = useFlowShareState();

  const { updateFlowSharing: updateEnableSharing } = useFlowSharingUpdate({
    flowId,
    onCompleted: (data) => {
      addToaster({
        type: 'info',
        content: `${window.i18next.t(
          'ShareItemModal-Template-1600-flow-sharing-is',
        )}${
          data?.updateFlowSharing.sharing_params?.sharing_enabled
            ? window.i18next.t('ShareItemModal-string--160-enabled')
            : window.i18next.t('ShareItemModal-string-2709-disabled')
        }`,
        timeout: 1000,
        closeButton: true,
      });
    },
  });
  const { updateFlowSharing: updateAllowDuplicate } = useFlowSharingUpdate({
    flowId,
    onCompleted: (data) => {
      addToaster({
        type: 'info',
        content: `${window.i18next.t(
          'ShareItemModal-Template-1995-flow-duplicating-from-shared-preview-is',
        )}${
          data?.updateFlowSharing.sharing_params?.allow_cloning
            ? window.i18next.t('ShareItemModal-string--160-enabled')
            : window.i18next.t('ShareItemModal-string-2709-disabled')
        }`,
        timeout: 2000,
        closeButton: true,
      });
    },
  });

  return (
    <ShareFlowModal
      label={window.i18next.t('ShareItemModal-string-3146-flow')}
      footerTitle={window.i18next.t(
        'ShareItemModal-string-1531-allow-viewers-to-duplicate-this-flow',
      )}
      linkSearchQuery={`?flow=${flowId}`}
      sharingParams={sharingParams}
      disabled={disabled}
      onAllowCloningToggle={() => {
        sendEvent({
          category: 'share flow',
          action: 'change allow duplicate',
          propertyBag: {
            botId,
            flowId,
            allowDuplicate: !sharingParams?.allow_cloning,
          },
        });
        updateAllowDuplicate({
          sharingParams: {
            allow_cloning: !sharingParams?.allow_cloning,
            sharing_enabled: !!sharingParams?.sharing_enabled,
          },
        });
      }}
      onCopyInviteLink={() => {
        sendEvent({
          category: 'share flow',
          action: 'copy share link',
          propertyBag: {
            botId,
            flowId,
          },
        });
      }}
      onCopyShareCode={() => {
        sendEvent({
          category: 'share flow',
          action: 'copy share code',
          propertyBag: {
            botId,
            flowId,
          },
        });
      }}
      onEnableSharingToggle={(value) => {
        sendEvent({
          category: 'share flow',
          action: 'change enable sharing',
          propertyBag: {
            botId,
            flowId,
            sharingEnabled: value,
          },
        });
        if (value && !hasSharedFlow) {
          setHasSharedFlow(true);
        }
        updateEnableSharing({
          sharingParams: {
            allow_cloning: !!sharingParams?.allow_cloning,
            sharing_enabled: value,
          },
        });
      }}
    />
  );
};

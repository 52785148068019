import React, { useRef, useState } from 'react';
import AutosizeInput, { AutosizeInputProps } from 'react-input-autosize';
import { Flex } from '@ui/Flex';
import { IconButton } from '@ui/IconButton';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import { InputLikeElement, insertText } from '@utils/documentExecCommand';
import * as css from './KeywordInputItem.css';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useIsKeywordExist } from '@utils/Data/Ai/Groups/useIsKeywordExist';
import cn from 'classnames';
import { Tooltip2 } from '@ui/Tooltip2';
import { sendEvent } from '@utils/Analytics';
import { Color, ColorKey } from '@ui/_common/colors';
import { KeywordsPlacements } from '../../../../../../../../consts';
import { Platform } from '@globals';

interface KeywordInputProps
  extends Omit<AutosizeInputProps, 'onChange' | 'onBlur'> {
  value: string;
  platform: Platform;
  placement: KeywordsPlacements;
  onBlur: (value: string) => void;
  onDeleteRequest: () => void;
  onChange?: (value: string) => void;
  color?: ColorKey;
}

export const KeywordInputItem: React.FC<KeywordInputProps> = ({
  value: initValue,
  onChange,
  onBlur,
  onDeleteRequest,
  autoFocus,
  platform,
  placement,
  color,
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const { t } = useSafeTranslation();
  const { isKeywordExist } = useIsKeywordExist(platform, placement);
  const [value, setValue] = useState<string>(initValue);
  const isAlreadyExists = isKeywordExist(value);

  return (
    <Flex className={css.box}>
      <Tooltip2
        content={t('pages.Keywords.KeywordGroups.validation.alreadyExists')}
        disabled={!isAlreadyExists}
      >
        {(ref, bind) => (
          <Flex
            ref={ref}
            alignItems="center"
            className={cn(css.inputBox, {
              [css.isAlreadyExists]: isAlreadyExists,
            })}
            style={{
              backgroundColor: color && Color[color],
              borderColor: color && Color[color],
            }}
            onClick={() => {
              inputRef.current?.focus();
              sendEvent({
                category: placement,
                label: 'keywords groups',
                action: 'keyword item click',
              });
            }}
            data-testid={`${placement}__keywords-groups__keyword-item`}
            {...bind}
          >
            <TextOutsideControls
              onInsertRequest={(text, el) => {
                insertText(text, el as InputLikeElement);
              }}
              shouldShowOutsideControls={{
                emoji: true,
              }}
              tight
              initialShow={false}
              controlsBoxStyle={{
                right: -32,
              }}
              boxStyle={{
                maxWidth: '100%',
              }}
            >
              {({ ref, getInputProps }) => (
                <AutosizeInput
                  defaultValue={value}
                  onChange={(e: any) => {
                    setValue(e.target.value);
                  }}
                  onBlur={(event) => {
                    onChange?.(value);
                    getInputProps().onBlur(event);
                    onBlur(value);
                    sendEvent({
                      category: placement,
                      label: 'keywords groups',
                      action: 'keyword item change',
                    });
                  }}
                  onFocus={(event) => {
                    getInputProps().onFocus(event);
                  }}
                  onKeyDown={({ target, key }) => {
                    if (key === 'Enter') {
                      onChange?.(value);
                      (target as HTMLInputElement).blur();
                    }
                  }}
                  inputRef={(el) => {
                    if (el) {
                      // eslint-disable-next-line no-param-reassign
                      ref.current = el;
                      inputRef.current = el;
                    }
                  }}
                  autoFocus={autoFocus}
                  inputClassName={css.input}
                  className={css.inputAutosize}
                  title={value}
                  data-testid={`${placement}__keywords-groups__keyword-item-input`}
                />
              )}
            </TextOutsideControls>
            <IconButton
              icon="close"
              size="16px"
              className={css.removeButton}
              onMouseDown={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
              onClick={(event) => {
                sendEvent({
                  category: placement,
                  label: 'keywords groups',
                  action: 'keyword item delete click',
                });
                onDeleteRequest();
                event.preventDefault();
                event.stopPropagation();
              }}
              data-testid={`${placement}__keywords-groups__remove-keyword-item-button`}
            />
          </Flex>
        )}
      </Tooltip2>
    </Flex>
  );
};

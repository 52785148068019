import React from 'react';
import noop from 'lodash-es/noop';
import { Input } from '@ui/Input';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import { ButtonTextInputView } from './ButtonTextInputView';
import { ButtonViewChildrenGrid } from './ButtonViewChildrenGrid';
import { useExtendedFormAction } from '../../../form/ExtendFormActionsContext';
import { TemplateButton } from '../../../form/types';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Tooltip2 } from '@ui/Tooltip2';

export interface OptInButtonViewProps {
  disabled: boolean;
  button: TemplateButton;
}

export const OptInButtonView: React.FC<OptInButtonViewProps> = ({
  button,
  disabled,
}) => {
  const { templateLanguage } = useExtendedFormAction();
  const { t } = useSafeTranslation();

  return (
    <>
      <ButtonViewChildrenGrid>
        <PluginControlLabel
          light
          label={
            <Flex alignItems="center">
              {t('pages.MessagesTemplates.message.buttonText')}
              <Tooltip2
                content={t('pages.MessagesTemplates.message.buttonTextTooltip')}
                positionFixed
                boundariesElement="viewport"
              >
                {(ref, bind) => (
                  <Icon
                    ref={ref}
                    icon="info"
                    color="grey"
                    size="20px"
                    {...bind}
                  />
                )}
              </Tooltip2>
            </Flex>
          }
        >
          {() => (
            <ButtonTextInputView
              disabled={disabled}
              buttonText={button.text}
              onButtonTextChange={noop}
              data-testid={`messages-template-${button.type}__button-text`}
            />
          )}
        </PluginControlLabel>

        <PluginControlLabel
          light
          label={
            <Flex alignItems="center">
              {t('pages.MessagesTemplates.message.footerText')}
              <Tooltip2
                content={t('pages.MessagesTemplates.message.footerTextTooltip')}
                positionFixed
                boundariesElement="viewport"
              >
                {(ref, bind) => (
                  <Icon
                    ref={ref}
                    icon="info"
                    color="grey"
                    size="20px"
                    {...bind}
                  />
                )}
              </Tooltip2>
            </Flex>
          }
        >
          {() => (
            <Input
              disabled={disabled}
              value={templateLanguage!.opt_out_footer_text}
              onChange={noop}
              data-testid={`messages-template-${button.type}__footer-text`}
            />
          )}
        </PluginControlLabel>
      </ButtonViewChildrenGrid>

      <Type size="12px">{t('pages.MessagesTemplates.message.optOutNote')}</Type>
    </>
  );
};

import React, { useCallback, useMemo } from 'react';
import get from 'lodash-es/get';
import { propEq, remove } from 'ramda';
import {
  DndDraggable,
  DndDraggableAnchor,
  DndDroppable,
  DndProvider,
} from '@utils/Dnd';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { IconButton } from '@ui/IconButton';
import { ButtonUnstyled } from '@ui/Button';
import { ComboboxWithTriangleButton } from '@ui/SimpleCombobox';
import { Icon } from '@ui/Icon';
import { MessagesTemplateCategory, TemplateButtonType } from '@globals';
import { FormContainer } from '../FormContainer';
import { useExtendedFormAction } from '../../form/ExtendFormActionsContext';
import {
  DEFAULT_CUSTOM_BUTTON,
  DEFAULT_OPT_OUT_BUTTON,
  DEFAULT_PHONE_BUTTON,
  DEFAULT_URL_BUTTON,
} from '../../form/consts';
import { WebsiteButtonView } from './MessageButtons/WebsiteButtonView';
import { CallButtonView } from './MessageButtons/CallButtonView';
import { OptInButtonView } from './MessageButtons/OptInButtonView';
import { ButtonView, ButtonViewProps } from './MessageButtons/ButtonView';
import { CustomButtonView } from './MessageButtons/CustomButtonView';
import { useGetMessageTemplateLanguages } from '@pages/BotPage/MessagesTemplatesTab/data/useGetMessageTemplateLanguages';
import {
  ButtonErrors,
  ButtonSectionType,
  ItemButton,
  TemplateButton,
} from '../../form/types';
import {
  ALL_BUTTONS_MAX_COUNT,
  BUTTON_TYPE_TO_SECTION_TYPE,
  CALL_BUTTONS_MAX_COUNT,
  DEFAULT_CONFIG_BY_TYPE,
  DndType,
  getCallToActionButtons,
  getDropdownButtons,
  getQuickReplyButtons,
  OPT_OUT_BUTTONS_MAX_COUNT,
  URL_BUTTONS_MAX_COUNT,
} from './utils';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { createTemplateButton, logEvent } from '../../utils';
import { Spacer } from '@ui/Spacer';
import { VisuallyDisabled } from '@ui/VisuallyDisabled';
import { clone } from 'lodash-es';

export interface DndHeaderProps {
  disabled: boolean;
  title: string;
}

export const DndHeader: React.FC<DndHeaderProps> = ({
  disabled,
  title,
  children,
}) => {
  const icon = (
    <IconButton disabled={disabled} icon="drag" color="accent1Normal" />
  );

  return (
    <Flex flexDirection="column" gapFactor={3}>
      <Flex justifyContent="space-between" alignItems="center" fullWidth>
        <Type weight="medium">{title}</Type>
        {disabled ? (
          icon
        ) : (
          <DndDraggableAnchor
            type={DndType.section}
            data-testid="messages-template-button__section-dnd-anchor"
          >
            {icon}
          </DndDraggableAnchor>
        )}
      </Flex>
      {children}
      <Spacer factor={1} />
    </Flex>
  );
};

export const MessageButtons = () => {
  const { t } = useSafeTranslation();
  const {
    values,
    errors,
    onNodeDrag,
    onButtonsDrag,
    mapSection,
    removeFromSectionAt,
    updateSectionAt,
    formDisabled,
    saveForm,
    templateLanguage,
  } = useExtendedFormAction();

  const buttons = values.content.sections.flatMap(({ children }) => children);

  const buttonsCount = useMemo(
    () =>
      buttons.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.type]: (prev[curr.type] || 0) + 1,
        }),
        {} as Record<TemplateButtonType, number>,
      ),
    [buttons],
  );

  const hideCallButton =
    buttonsCount[TemplateButtonType.phone] >= CALL_BUTTONS_MAX_COUNT;
  const hideUrlButton =
    buttonsCount[TemplateButtonType.url] >= URL_BUTTONS_MAX_COUNT;
  const hideOptOutButton =
    values.category === MessagesTemplateCategory.utility ||
    buttonsCount[TemplateButtonType.marketing_opt_out] >=
      OPT_OUT_BUTTONS_MAX_COUNT;

  const allDropdownItems = getDropdownButtons();
  const filterFn = useCallback(
    (item: ItemButton) => {
      if (item.id === TemplateButtonType.url) {
        return !hideUrlButton;
      }

      if (item.id === TemplateButtonType.marketing_opt_out) {
        return !hideOptOutButton;
      }

      if (item.id === TemplateButtonType.phone) {
        return !hideCallButton;
      }

      return true;
    },
    [hideCallButton, hideOptOutButton, hideUrlButton],
  );
  const filteredDropdownItems = useMemo(
    () => allDropdownItems.filter(filterFn),
    [allDropdownItems, filterFn],
  );
  const { data } = useGetMessageTemplateLanguages();
  const language = data?.messageTemplateLanguages.find(
    ({ id }) => values.language === id,
  )!;
  const callToActionButtons = getCallToActionButtons().filter(filterFn);
  const quickReplyButtons = getQuickReplyButtons().filter(filterFn);

  const renderButton = useCallback(
    (button: TemplateButton, sectionIndex: number) => {
      const buttonIndex = values.content.sections[
        sectionIndex
      ].children.findIndex(({ id }) => id === button.id);
      const selectedItem = allDropdownItems.find(propEq('id', button.type))!;
      const onDropdownChange: ButtonViewProps['onDropdownChange'] = (item) => {
        let defaultConfig = DEFAULT_CONFIG_BY_TYPE[item.id];

        logEvent({
          action: 'change button',
          propertyBag: { new_button_type: item.id },
        });

        if (item.id === TemplateButtonType.marketing_opt_out) {
          defaultConfig = {
            ...defaultConfig,
            text: templateLanguage!.opt_out_button_text,
          };
        }
        const section = BUTTON_TYPE_TO_SECTION_TYPE[item.id];
        const config = createTemplateButton(defaultConfig);

        const newForm = mapSection(section, (buttons) => {
          if (item.id === TemplateButtonType.marketing_opt_out) {
            return [...remove(buttonIndex, 1, buttons), config];
          }

          return buttons.map((button, index) =>
            buttonIndex === index ? config : button,
          );
        });

        saveForm(newForm);
      };

      const sectionType = BUTTON_TYPE_TO_SECTION_TYPE[button.type];
      const buttonPath = `content.sections[${sectionIndex}].children[${buttonIndex}]`;
      const buttonErrors: ButtonErrors = get(errors, buttonPath);
      const dropdownItems = {
        [ButtonSectionType.callToAction]: callToActionButtons,
        [ButtonSectionType.quickReply]: quickReplyButtons,
      }[sectionType].filter(({ id }) => id !== button.type);

      return (
        <ButtonView
          selectedItem={selectedItem}
          disabled={formDisabled}
          dropdownItems={dropdownItems}
          onDropdownChange={onDropdownChange}
          data-testid={`messages-template-button__${button.type}`}
          actionButtons={
            <>
              <ButtonUnstyled
                data-testid="messages-template-button__remove"
                disabled={formDisabled}
                onClick={() => {
                  logEvent({
                    action: 'delete button',
                    propertyBag: {
                      button_type: button.type,
                    },
                  });

                  saveForm(removeFromSectionAt(sectionType, buttonIndex));
                }}
              >
                <Icon icon="delete" color="blue" />
              </ButtonUnstyled>
              {button.type !== TemplateButtonType.marketing_opt_out && (
                <DndDraggableAnchor
                  type={DndType.button}
                  data-testid="messages-template-button__button-dnd-anchor"
                >
                  <Icon icon="drag" color="blue" />
                </DndDraggableAnchor>
              )}
            </>
          }
        >
          {button.type === TemplateButtonType.quick_reply && (
            <CustomButtonView
              disabled={formDisabled}
              button={button}
              buttonErrors={buttonErrors}
              onButtonChange={(button) => {
                updateSectionAt(
                  ButtonSectionType.quickReply,
                  button,
                  buttonIndex,
                );
              }}
            />
          )}
          {button.type === TemplateButtonType.url && (
            <WebsiteButtonView
              disabled={formDisabled}
              button={button}
              buttonErrors={buttonErrors}
              onButtonChange={(button, save) => {
                const newValues = updateSectionAt(
                  ButtonSectionType.callToAction,
                  button,
                  buttonIndex,
                );

                if (save) saveForm(newValues);
              }}
            />
          )}
          {button.type === TemplateButtonType.marketing_opt_out && (
            <OptInButtonView disabled button={button} />
          )}
          {button.type === TemplateButtonType.phone && (
            <CallButtonView
              disabled={formDisabled}
              button={button}
              buttonErrors={buttonErrors}
              onButtonChange={(button) => {
                updateSectionAt(
                  ButtonSectionType.callToAction,
                  button,
                  buttonIndex,
                );
              }}
            />
          )}
        </ButtonView>
      );
    },
    [
      allDropdownItems,
      callToActionButtons,
      errors,
      formDisabled,
      mapSection,
      quickReplyButtons,
      removeFromSectionAt,
      saveForm,
      templateLanguage,
      updateSectionAt,
      values.content.sections,
    ],
  );

  return (
    <FormContainer>
      <ComboboxWithTriangleButton
        data-testid="messages-template-message__buttons-add-button"
        icon="chevronDown"
        color="blue"
        disabled={formDisabled || buttons.length >= ALL_BUTTONS_MAX_COUNT}
        onChange={(item, helpers) => {
          if (!item) {
            return;
          }

          logEvent({
            action: 'add button',
            propertyBag: { button_type: item.id },
          });

          const action = {
            [TemplateButtonType.phone]: () =>
              mapSection(ButtonSectionType.callToAction, (buttons) => [
                ...buttons,
                createTemplateButton(DEFAULT_PHONE_BUTTON),
              ]),
            [TemplateButtonType.url]: () =>
              mapSection(ButtonSectionType.callToAction, (buttons) => [
                ...buttons,
                createTemplateButton(DEFAULT_URL_BUTTON),
              ]),
            [TemplateButtonType.marketing_opt_out]: () =>
              mapSection(ButtonSectionType.quickReply, (buttons) => [
                ...buttons,
                createTemplateButton({
                  ...DEFAULT_OPT_OUT_BUTTON,
                  text: language.opt_out_button_text,
                }),
              ]),
            [TemplateButtonType.quick_reply]: () =>
              mapSection(ButtonSectionType.quickReply, (buttons) => {
                const buttonsClone = clone(buttons);
                const optOutButtonIndex = buttonsClone.findIndex(
                  ({ type }) => type === TemplateButtonType.marketing_opt_out,
                );
                const customButton = createTemplateButton(
                  DEFAULT_CUSTOM_BUTTON,
                );

                if (optOutButtonIndex === -1) {
                  return [...buttonsClone, customButton];
                }

                buttonsClone.splice(optOutButtonIndex, 0, customButton);

                return buttonsClone;
              }),
          };

          saveForm(action[item.id]());
          helpers.clearSelection();
        }}
        items={filteredDropdownItems}
        intent="secondary"
        buttonChildren={() => (
          <Flex alignItems="center">
            <Icon icon="plus" size="24px" color="blue" />

            <Type weight="medium" size="15px" color="blue">
              {t('pages.MessagesTemplates.message.addButton')}
            </Type>
          </Flex>
        )}
      />

      {values.content.sections.length > 0 && (
        <VisuallyDisabled disable={formDisabled}>
          <DndProvider
            onDrop={(params) => {
              if (params.sourceId !== params.targetId) {
                return;
              }
              let newValues = values;

              if (params.type === DndType.section) {
                newValues = onNodeDrag(params);
              }
              if (params.type === DndType.button) {
                newValues = onButtonsDrag(params);
              }

              saveForm(newValues);
            }}
          >
            <DndDroppable
              style={{ position: 'relative' }}
              type={DndType.section}
              id="sections"
            >
              {values.content.sections.map((section, sectionIndex) => (
                <DndDraggable
                  hidePlaceholder
                  key={section.title}
                  id={section.type}
                  type={DndType.section}
                >
                  {section.children.length > 0 && (
                    <DndHeader disabled={formDisabled} title={section.title}>
                      <DndDroppable
                        style={{ position: 'relative' }}
                        type={DndType.button}
                        id={section.type}
                      >
                        {section.children
                          .filter(
                            ({ type }) =>
                              type !== TemplateButtonType.marketing_opt_out,
                          )
                          .map((button) => (
                            <DndDraggable
                              hidePlaceholder
                              key={button.id}
                              id={button.id}
                              type={DndType.button}
                            >
                              {renderButton(button, sectionIndex)}
                            </DndDraggable>
                          ))}
                      </DndDroppable>

                      {section.children
                        .filter(
                          ({ type }) =>
                            type === TemplateButtonType.marketing_opt_out,
                        )
                        .map((button) => (
                          <React.Fragment key={button.id}>
                            {renderButton(button, sectionIndex)}
                          </React.Fragment>
                        ))}
                    </DndHeader>
                  )}
                </DndDraggable>
              ))}
            </DndDroppable>
          </DndProvider>
        </VisuallyDisabled>
      )}
    </FormContainer>
  );
};

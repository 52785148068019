import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import {
  PublishMessageTemplate,
  PublishMessageTemplateVariables,
} from './@types/PublishMessageTemplate';

export const PUBLISH_MESSAGE_TEMPLATE_MUTATION = gql`
  mutation PublishMessageTemplate(
    $botId: MongoObjectId!
    $templateId: MongoObjectId!
  ) {
    publishMessageTemplate(botId: $botId, templateId: $templateId) {
      id
      errors {
        field
        error
      }
    }
  }
`;

export const usePublishMessageTemplate = () => {
  const [mutation, queryResult] = useMutation<
    PublishMessageTemplate,
    PublishMessageTemplateVariables
  >(PUBLISH_MESSAGE_TEMPLATE_MUTATION);

  const onPublishMessageTemplate = (
    variables: PublishMessageTemplateVariables,
  ) => mutation({ variables });

  return [onPublishMessageTemplate, queryResult] as const;
};

import { useEffect } from 'react';
import { UpdateWhatsappBroadcastInput } from '@globals';
import isEqual from 'lodash-es/isEqual';
import { Observable, ObservableInput } from 'rxjs/Observable';
import { debounceTime, map, distinctUntilChanged, skip } from 'rxjs/operators';
import { ApolloQueryResult } from 'apollo-client';
import { useApolloClient } from 'react-apollo';
import { pick } from 'ramda';
import {
  WhatsappBroadcast,
  WhatsappBroadcastVariables,
  WhatsappBroadcast_whatsappBroadcast,
} from './@types/WhatsappBroadcast';
import { WHATSAPP_BROADCAST_QUERY } from './queries';
import { useCurrentBotId } from '@utils/Routing';

const watchFields: UpdateWhatsappBroadcastInput = {
  // title: null, тайтл лучше сохранять на onBlur
  userFilter: null,
  triggerSettings: null,
  schedule: null,
};

const pickWatchFields = pick(Object.keys(watchFields));

export const useWhatsappConfigChanged = (
  broadcastId: string,
  cb: (config: WhatsappBroadcast_whatsappBroadcast) => void,
) => {
  const botId = useCurrentBotId()!;
  const client = useApolloClient();

  useEffect(() => {
    const observable$ = Observable.from(
      client.watchQuery<WhatsappBroadcast, WhatsappBroadcastVariables>({
        query: WHATSAPP_BROADCAST_QUERY,
        variables: { botId, broadcastId },
      }) as ObservableInput<ApolloQueryResult<WhatsappBroadcast>>,
    )
      .pipe(
        map((x) => x.data.whatsappBroadcast),
        debounceTime(500),
        distinctUntilChanged((lhs, rhs) =>
          isEqual(pickWatchFields(lhs), pickWatchFields(rhs)),
        ),
        skip(1),
      )
      .subscribe((config: WhatsappBroadcast_whatsappBroadcast) => {
        cb(config);
      });

    return () => observable$.unsubscribe();
  }, [client, broadcastId, botId, cb]);
};

import React from 'react';
import { UserListItem } from '@components/ManageUsersDialog/UserListItem/UserListItem';
import { Type } from '@ui/Type';
import { ROLES_AND_INVITES_QUERY_bot_admins } from '../../../../../../components/settings/admins/@types/ROLES_AND_INVITES_QUERY';
import { RoleIds } from '../../../../../../components/settings/admins/RolesList';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { isSomeEnum } from '@utils/isSomeEnum';
import { getRoleTexts } from '../../getRoleTexts';
import { useRemoveBotAdmin } from './useRemoveBotAdmin';
import { useSetBotAdminRole } from './useSetBotAdminRole';

type BotUserListItemProps = {
  adminId?: string;
  botId: string;
} & ROLES_AND_INVITES_QUERY_bot_admins;

export const BotUserListItem: React.FC<BotUserListItemProps> = ({
  adminId,
  id,
  name,
  picture,
  role,
  botId,
}) => {
  const [removeBotAdmin, { loading: removing }] = useRemoveBotAdmin(botId, id);
  const [setBotAdminRole, { loading: changingRole }] =
    useSetBotAdminRole(botId);
  const isYou = id === adminId;
  const { t } = useSafeTranslation();

  return (
    <UserListItem
      pending={removing || changingRole}
      onRemove={isYou ? undefined : () => removeBotAdmin()}
      user={{
        id,
        name: isYou ? (
          <>
            {name}
            <Type size="15px" color="greyDark">
              {' '}
              - {t('BotList.you')}
            </Type>
          </>
        ) : (
          name
        ),
        pictureUrl: picture,
        subtitle: isSomeEnum(RoleIds, role.id)
          ? getRoleTexts(role.id).title
          : '',
      }}
      onMenuItemSelect={(item) =>
        setBotAdminRole({ variables: { botId, userId: id, roleId: item.id } })
      }
      menuItems={
        isYou
          ? undefined
          : [
              {
                id: RoleIds.admin,
                title: getRoleTexts(RoleIds.admin).title,
              },
              {
                id: RoleIds.editor,
                title: getRoleTexts(RoleIds.editor).title,
              },
              {
                id: RoleIds.marketer,
                title: getRoleTexts(RoleIds.marketer).title,
              },
              {
                id: RoleIds.operator,
                title: getRoleTexts(RoleIds.operator).title,
              },
              {
                id: RoleIds.viewer,
                title: getRoleTexts(RoleIds.viewer).title,
              },
            ]
      }
    />
  );
};

import { getFullAttributesBoundaries } from '@ui/TextWithAttributesEditor';

const SPACER_REG = /\s+/;

export const currentAttributesAllowed = (text: string) => {
  const boundaries = getFullAttributesBoundaries(text);
  if (boundaries.length === 0) {
    return true;
  }
  const allowedAttributesQty = Math.floor(
    (text.trim().split(SPACER_REG).length - 1) / 3,
  );

  return boundaries.length <= allowedAttributesQty;
};

import React, { useCallback } from 'react';
import { Modal } from '@services/index';
import { BotListMenuItem, BotListMenuItemType } from '../types';
import {
  useCopyBotDialog,
  useDeleteBotDialog,
  useLeaveBotDialog,
  useStateForRenameBot,
} from '@components/BotActions';
import { CopyBotDialogProps } from '@components/CopyBotDialog';
import { sendEvent } from '@utils/Analytics';
import { BotManageUsersDialog } from './BotManageUsersDialog/BotManageUsersDialog';
import { useMoveToWorkspace } from '@utils/Data/Workspaces/useMoveToWorkspace';
import { useRemoveFromWorkspace } from '@utils/Data/Workspaces/useRemoveFromWorkspace';
import { Bot } from '@components/CopyBotDialog/types';

export interface BotOptionsHandlerProps {
  userId: string;
  currentWorkspaceId?: string;
  onCopyCompleted?: CopyBotDialogProps['onCompleted'];
}

export const useBotOptionsHandler = ({
  userId,
  currentWorkspaceId,
  onCopyCompleted,
}: BotOptionsHandlerProps) => {
  const showCopyBotDialog = useCopyBotDialog({ onCopyCompleted });
  const showBotLeaveDialog = useLeaveBotDialog({ userId });
  const showDeleteBotDialog = useDeleteBotDialog();
  const { setRenameMode, removeRenameMode, isRenameMode } =
    useStateForRenameBot();
  const [moveToWorkspace, moveToWorkspaceLoading] = useMoveToWorkspace();
  const [removeFromWorkspace, { loading: removing }] = useRemoveFromWorkspace();

  const handleOptionSelect = useCallback(
    (bot: Bot, selectedItem: BotListMenuItemType<BotListMenuItem>) => {
      const optionsMap: Record<BotListMenuItem, (bot: Bot) => void> = {
        [BotListMenuItem.rename]: (bot) => {
          sendEvent({
            category: 'bot list page',
            action: 'click on rename bot',
            label: 'premium',
            propertyBag: { botId: bot.id },
          });

          setRenameMode(bot);
        },
        [BotListMenuItem.delete]: (bot) => {
          sendEvent({
            category: 'bot list page',
            action: 'click on delete bot',
            label: 'premium',
            propertyBag: { botId: bot.id },
          });

          showDeleteBotDialog(bot);
        },
        [BotListMenuItem.leave]: (bot) => {
          sendEvent({
            category: 'bot list page',
            action: 'click on leave bot',
            label: 'premium',
            propertyBag: { botId: bot.id },
          });

          showBotLeaveDialog(bot);
        },
        [BotListMenuItem.copy]: (bot) => {
          sendEvent({
            category: 'bot list page',
            action: 'click on copy bot',
            label: 'premium',
            propertyBag: { botId: bot.id },
          });

          showCopyBotDialog(bot, { copyToWorkspace: false });
        },
        [BotListMenuItem.copyToWorkspace]: (bot) => {
          sendEvent({
            category: 'bot list page',
            action: 'click on copy to workspace',
            label: 'premium',
            propertyBag: { botId: bot.id },
          });

          showCopyBotDialog(bot, { copyToWorkspace: true });
        },
        [BotListMenuItem.manageUsers]: (bot) => {
          sendEvent({
            category: 'bot list page',
            action: 'click on manage users in bot',
            label: 'premium',
            propertyBag: { currentWorkspaceId, botId: bot.id },
          });

          Modal.show(
            ({ close }) => (
              <BotManageUsersDialog
                botId={bot.id}
                title={bot.title}
                onClose={close}
              />
            ),
            { mobileAdaptive: true },
          );
        },
        [BotListMenuItem.moveTo]: (bot) => {
          sendEvent({
            category: 'bot list page',
            action: 'move to workspace',
            label: 'premium',
            propertyBag: {
              workspaceId: selectedItem.workspaceId,
              botId: bot.id,
            },
          });

          moveToWorkspace({
            botId: bot.id,
            workspaceId: selectedItem.workspaceId!,
          });
        },
        [BotListMenuItem.removeFromWorkspace]: (bot) => {
          sendEvent({
            category: 'bot list page',
            action: 'remove from workspace',
            label: 'premium',
            propertyBag: {
              currentWorkspaceId,
              botId: bot.id,
            },
          });
          removeFromWorkspace(bot.id);
        },
      };
      optionsMap[selectedItem.id](bot);
    },
    [
      showDeleteBotDialog,
      showBotLeaveDialog,
      showCopyBotDialog,
      setRenameMode,
      removeFromWorkspace,
      moveToWorkspace,
      currentWorkspaceId,
    ],
  );

  const loading = moveToWorkspaceLoading || removing;

  return {
    isRenameMode,
    handleOptionSelect,
    exitRenameMode: removeRenameMode,
    loading,
  };
};

import React, { useCallback } from 'react';
import { ReactComponent as RocketPic } from './images/rocket.svg';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import * as css from './DoneModal.css';
import { Button } from '@ui/Button';
import { BotTabs, useGoToTab } from '@utils/Routing';
import { useAdminData } from '@utils/Data/Admin';
import { DefaultDialog } from '@ui/Dialog';
import { sendEvent } from '@utils/Analytics';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

interface DoneModalProps {
  onCloseRequest: () => void;
}

export const DoneModal: React.FC<DoneModalProps> = ({ onCloseRequest }) => {
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();
  const goToTab = useGoToTab();
  const { adminData } = useAdminData();
  const goToHomeTab = useCallback(() => {
    sendEvent({
      category: 'first-session',
      action: 'go to home button click',
      label: 'activate-done-modal',
    });
    onCloseRequest();
    goToTab(BotTabs.home);
  }, [goToTab, onCloseRequest]);

  return (
    <DefaultDialog
      header={null}
      mobileAdaptive={isSmallScreenSize}
      className={isSmallScreenSize ? css.dialogMobile : css.dialog}
    >
      <Flex flexDirection="column" alignItems="center">
        <Spacer factor={7} />
        <RocketPic />
        <Spacer factor={6} />
        <Type size="24px" align="center" weight="semibold">
          {t('pages.FirstSession.ActivateBotBlock.doneModal.header', {
            name: adminData?.me.name,
          })}
        </Type>
        <Spacer factor={4} />
        <div className={css.text}>
          <Type size="15px" as="div" align="center">
            {t('pages.FirstSession.ActivateBotBlock.doneModal.text1')}
            <br />
            <br />
            {t('pages.FirstSession.ActivateBotBlock.doneModal.text2')}
          </Type>
        </div>
        <Spacer factor={6} />
        <Button
          data-testid="first-session__activate-done-modal__button"
          intent="primary"
          onClick={goToHomeTab}
        >
          {t('pages.FirstSession.ActivateBotBlock.doneModal.button')}
        </Button>
        <Spacer factor={4} />
      </Flex>
    </DefaultDialog>
  );
};

import React from 'react';
import gql from 'graphql-tag';
import nanoid from 'nanoid';
import { find, findIndex, pathOr, propOr, propEq } from 'ramda';
import debounce from 'lodash-es/debounce';
import { sendEvent } from '@utils/Analytics';
import { RoleConsumer } from '@utils/Roles';
import { TabProps, TabsSet } from '@ui/Tabs';
import { Modal } from '@ui/Modal';
import { ConfirmDeleteGroupDialog } from '@components/ConfirmDeleteGroupDialog';
import { GroupActionsDialog } from '@components/GroupActionsDialog';
import { GroupExtended } from './types';

import * as css from './AiGroupSelector.css';
import { AiIntentFilterType, Platform } from '@globals';
import i18next from 'i18next';
import {
  ADD_AI_GROUP_MUTATION,
  AI_CARD_FRAGMENT,
  CLONE_AI_GROUP_MUTATION,
  REMOVE_AI_GROUP_MUTATION,
  UPDATE_AI_GROUP_TITLE_MUTATION,
} from './queries';
import {
  UpdateAiGroupTitleMutation,
  UpdateAiGroupTitleMutationVariables,
} from './@types/UpdateAiGroupTitleMutation';
import {
  AddAiGroupMutation,
  AddAiGroupMutationVariables,
} from './@types/AddAiGroupMutation';
import {
  CloneAiGroupMutation,
  CloneAiGroupMutationVariables,
} from './@types/CloneAiGroupMutation';
import {
  RemoveAiGroupMutation,
  RemoveAiGroupMutationVariables,
} from './@types/RemoveAiGroupMutation';
import client from '../../../../../common/services/ApolloService';
import { getIncrementName } from '../../../../../common/services/NamingService';
import { Permission } from '../../../../../common/services/RoleService';
import { aiGroupsFragment_groups as Group } from './@types/aiGroupsFragment';
import { aiCardFragment as AiPlugin } from './@types/aiCardFragment';
import { intentsFilter } from '@utils/Data/Ai/Groups/helpers';

interface AiGroupSelectorProps {
  activeGroupIndex: number;
  onChange: (id: number) => void;
  groups: Group[];
  cardId: string;
  currentBotId: string;
  intentsFilterTerm: string;
  platform: Platform;
}

interface AiGroupSelectorState {
  showPopUp: boolean;
  actionType: string;
  errorMessage?: string;
}

const findById = (id: string, groups: Group[]) =>
  find(propEq('id', id), groups);
const findIndexById = (id: string, groups: Group[]) =>
  findIndex(propEq('id', id), groups);

const titleProp = propOr('', 'title');

export interface GroupTabsProps extends TabProps {
  isOver?: boolean;
}

export class AiGroupSelector extends React.Component<
  AiGroupSelectorProps,
  AiGroupSelectorState
> {
  state = {
    actionType: '',
    showPopUp: false,
    // eslint-disable-next-line react/no-unused-state
    errorMessage: undefined,
  };

  mutateGroupTitle = debounce((groupId: string, groupName: string) => {
    const { cardId } = this.props;
    client.mutate<
      UpdateAiGroupTitleMutation,
      UpdateAiGroupTitleMutationVariables
    >({
      mutation: UPDATE_AI_GROUP_TITLE_MUTATION,
      variables: {
        groupId,
        cardId,
        title: groupName,
      },
    });
  }, 300);

  handleAddGroupClick = () => {
    sendEvent({
      category: 'keywords',
      label: 'folders',
      action: 'add',
    });
    const { groups, cardId } = this.props;

    const title = getIncrementName(
      i18next.t('pages.Keywords.KeywordGroups.GroupsSelector.newGroup'),
      groups.map(titleProp),
    );
    const activeIndex = groups.length;
    const tmpIntentId = nanoid();
    client.mutate<AddAiGroupMutation, AddAiGroupMutationVariables>({
      mutation: ADD_AI_GROUP_MUTATION,
      variables: {
        title,
        cardId,
      },
      optimisticResponse: {
        addAiGroup: {
          title,
          __typename: 'AiGroup',
          id: title,
          intents: [
            {
              __typename: 'AiIntent',
              id: tmpIntentId,
              intent_id: tmpIntentId,
              filter_type: AiIntentFilterType.default,
              lines: [],
              goto_block: null,
              name: null,
              action: {
                __typename: 'AiIntentAction',
                random: false,
                items: [],
              },
              ig_action: {
                __typename: 'AiIntentAction',
                random: false,
                items: [],
              },
              wa_action: {
                __typename: 'AiIntentAction',
                random: false,
                items: [],
              },
            },
          ],
        },
      },
      update: (store, { data }) => {
        if (data && data.addAiGroup) {
          const cardFragment: AiPlugin | null = store.readFragment({
            id: cardId,
            fragmentName: 'aiCardFragment',
            fragment: AI_CARD_FRAGMENT,
          });

          if (cardFragment) {
            const {
              config: { groups },
            } = cardFragment;
            cardFragment.config.groups = [
              ...groups,
              data.addAiGroup,
            ] as Group[];

            store.writeFragment({
              id: cardId,
              fragmentName: 'aiCardFragment',
              fragment: AI_CARD_FRAGMENT,
              data: cardFragment,
            });
          }

          this.props.onChange(activeIndex);
        }
      },
    });
  };

  handleCloneGroup = (variables: { botId: string }) => {
    sendEvent({
      category: 'keywords',
      label: 'folders',
      action: 'clone',
    });

    this.handleDismiss();
    const { activeGroupIndex } = this.props;
    const { groups, cardId, currentBotId } = this.props;
    const activeIndex = groups.length;
    const currentGroup = groups[activeGroupIndex];
    const title = getIncrementName(
      `${currentGroup.title} Copy`,
      groups.map(titleProp),
    );
    client.mutate<CloneAiGroupMutation, CloneAiGroupMutationVariables>({
      mutation: CLONE_AI_GROUP_MUTATION,
      variables: {
        ...variables,
        cardId,
        groupId: currentGroup.id,
      },
      optimisticResponse: {
        cloneAiGroupToBot: {
          title,
          __typename: 'AiGroup',
          id: title,
          intents: [...currentGroup.intents],
        },
      },
      update: (store, { data }) => {
        if (variables.botId !== currentBotId) {
          return;
        }
        if (data && data.cloneAiGroupToBot) {
          const cardFragment: AiPlugin | null = store.readFragment({
            id: cardId,
            fragmentName: 'aiCardFragment',
            fragment: AI_CARD_FRAGMENT,
          });
          if (cardFragment) {
            const {
              config: { groups },
            } = cardFragment;
            cardFragment.config.groups = [
              ...groups,
              data.cloneAiGroupToBot,
            ] as Group[];

            store.writeFragment({
              id: cardId,
              fragmentName: 'aiCardFragment',
              fragment: AI_CARD_FRAGMENT,
              data: cardFragment,
            });
          }
          this.props.onChange(activeIndex);
        }
      },
    });
  };

  handleRenameGroup = (groupIndex: number, newName: string) => {
    sendEvent({
      category: 'keywords',
      label: 'folders',
      action: 'rename',
    });

    const { groups } = this.props;
    const group = groups[groupIndex];

    if (group) {
      client.writeFragment({
        id: group.id,
        fragment: gql`
          fragment group on AiGroup {
            title
          }
        `,
        data: {
          __typename: 'AiGroup',
          title: newName,
        },
      });
    }

    this.mutateGroupTitle(group.id, newName);
  };

  handleGroupDeleteClick = (groupIndex: number) => {
    sendEvent({
      category: 'keywords',
      label: 'folders',
      action: 'delete',
    });

    const { groups } = this.props;
    const group = groups[groupIndex];

    if (group?.intents.length > 0) {
      this.setState({
        showPopUp: true,
        actionType: 'delete',
      });
    } else {
      this.handleGoDeleteClickInPopup(group.id);
    }
  };

  handleGoDeleteClickInPopup = (groupId: string) => {
    sendEvent({
      category: 'keywords',
      label: 'folders',
      action: 'delete popup',
    });

    const { cardId, groups } = this.props;
    const currentIndex = groups.findIndex(propEq('id', groupId));
    const newIndex = currentIndex > 0 ? currentIndex - 1 : 0;

    this.props.onChange(newIndex);

    this.setState({
      showPopUp: false,
      actionType: '',
    });

    client.mutate<RemoveAiGroupMutation, RemoveAiGroupMutationVariables>({
      mutation: REMOVE_AI_GROUP_MUTATION,
      variables: {
        groupId,
        cardId,
      },
      optimisticResponse: {
        removeAiGroup: true,
      },
      update: (store, { data }) => {
        if (data && data.removeAiGroup) {
          const cardFragment: AiPlugin | null = store.readFragment({
            id: cardId,
            fragmentName: 'aiCardFragment',
            fragment: AI_CARD_FRAGMENT,
          });

          if (cardFragment) {
            const {
              config: {
                groups: [...newGroups],
              },
            } = cardFragment;
            const groupIndex = findIndexById(groupId, newGroups as Group[]);
            newGroups.splice(groupIndex, 1);
            cardFragment.config.groups = newGroups;

            store.writeFragment({
              id: cardId,
              fragmentName: 'aiCardFragment',
              fragment: AI_CARD_FRAGMENT,
              data: cardFragment,
            });
          }
        }
      },
    });
  };

  handleDismiss = () => this.setState({ showPopUp: false, actionType: '' });

  validate = (newName: string, tabIndex: number) => {
    if (newName === '') {
      return {
        valid: false,
        errorMessage: i18next.t(
          'pages.Keywords.KeywordGroups.GroupsSelector.groupNameCanNotBeEmpty',
        ),
      };
    }

    const tabId: string = pathOr('', ['props', 'groups', tabIndex, 'id'], this);
    const groups = this.props.groups.filter(propEq('title', newName));

    if (groups.length > 1 || pathOr(tabId, [0, 'id'], groups) !== tabId) {
      return {
        valid: false,
        errorMessage: i18next.t(
          'pages.Keywords.KeywordGroups.GroupsSelector.groupWithThisNameAlreadyExists',
        ),
      };
    }

    return {
      valid: true,
      errorMessage: undefined,
    };
  };

  render() {
    const { activeGroupIndex } = this.props;
    const { groups, intentsFilterTerm, platform } = this.props;
    const currentGroup = groups[activeGroupIndex];
    const activeGroupId = currentGroup?.id;

    const groupsWithFade: GroupExtended[] =
      intentsFilterTerm !== ''
        ? groups.map((group) => ({
            ...group,
            faded: !group.intents.some(
              intentsFilter(intentsFilterTerm, platform),
            ),
          }))
        : groups;

    return (
      <React.Fragment>
        <div className={`${css.lineLayout} ${css.tabsBoxLayout}`}>
          <RoleConsumer domain="ai" can={Permission.EDIT}>
            {({ allowed }) => (
              <TabsSet
                tabs={groupsWithFade}
                activeTabIndex={activeGroupIndex}
                createNewTab={this.handleAddGroupClick}
                renameTab={this.handleRenameGroup}
                removeTab={
                  groupsWithFade.length > 1
                    ? this.handleGroupDeleteClick
                    : undefined
                }
                cloneTab={() =>
                  this.setState({ showPopUp: true, actionType: 'clone' })
                }
                validate={(name, tabIndex = -1) =>
                  this.validate(name, tabIndex).valid
                }
                getErrorMessage={(name, tabIndex = -1) =>
                  this.validate(name, tabIndex).errorMessage
                }
                onTabClick={(tabIndex) => {
                  this.props.onChange(tabIndex);
                }}
                allowCreateNewTabs={allowed}
                allowEditTabs={allowed}
              />
            )}
          </RoleConsumer>
        </div>
        {this.state.showPopUp && (
          <Modal
            onDismiss={() =>
              this.setState({
                showPopUp: false,
              })
            }
          >
            {this.state.actionType === 'clone' && (
              <GroupActionsDialog
                onSubmit={this.handleCloneGroup}
                renderHeading={() =>
                  i18next.t(
                    'pages.Keywords.KeywordGroups.GroupsSelector.cloneGroup',
                  )
                }
                renderActionText={() =>
                  i18next.t('pages.Keywords.KeywordGroups.GroupsSelector.clone')
                }
                currentBotId={this.props.currentBotId}
                onDismiss={this.handleDismiss}
              />
            )}
            {this.state.actionType === 'delete' && (
              <ConfirmDeleteGroupDialog
                onDismiss={this.handleDismiss}
                onConfirmDelete={this.handleGoDeleteClickInPopup}
                renderHeading={() =>
                  i18next.t(
                    'pages.Keywords.KeywordGroups.GroupsSelector.confirmDeletion',
                  )
                }
                renderActionText={() =>
                  i18next.t(
                    'pages.Keywords.KeywordGroups.GroupsSelector.delete',
                  )
                }
                renderDescription={() =>
                  i18next.t(
                    'pages.Keywords.KeywordGroups.GroupsSelector.deleteText',
                  )
                }
                confirmationText={i18next.t(
                  'modernComponents.Aside.GroupContextMenu.deleteConfirmationText',
                )}
                group={findById(activeGroupId, groups)}
              />
            )}
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

import React from 'react';
import { showIntercom } from '@utils/Intercom';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ButtonUnstyled } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import css from './ContactUs.css';

export const ContactUs = () => {
  const { t } = useSafeTranslation();

  return (
    <Flex alignItems="center">
      <ButtonUnstyled className={css.contactUs} onClick={showIntercom}>
        {t('BotList.contactUs')}
      </ButtonUnstyled>
      <Type size="15px"> {t('BotList.toManageWorkspaces')}</Type>
    </Flex>
  );
};

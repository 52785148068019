import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { DeepLinksQueryParam } from './types';

export const useDeepLinkCleanup = () => {
  const history = useHistory();
  const deepLinkCleanup = useCallback(() => {
    const url = new URL(window.location.href);
    Object.values(DeepLinksQueryParam).forEach((queryParam) => {
      url.searchParams.delete(queryParam);
    });
    history.push(`${url.pathname}${url.search}`);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return { deepLinkCleanup };
};

import React from 'react';
import { MessagesList } from '../../../../../../../LiveChat/components/Messages/MessagesList';
import { MessagesQuery_livechatMessages_items as Message } from '../../../../../../../LiveChat/components/Messages/@types/MessagesQuery';
import { Platform } from '@globals';

interface MessagesTemplatesTableSlideProps {
  messages: Message[];
  userAvatarSrc?: string;
}

export const MessagesTemplatesTableSlide: React.FC<MessagesTemplatesTableSlideProps> =
  ({ messages, userAvatarSrc = '' }) => (
    <MessagesList
      user={{
        name: '',
        avatarUrl: userAvatarSrc,
      }}
      page={{
        picture: null,
        title: '',
      }}
      platform={Platform.facebook}
      conversationId=""
      adminsById={{}}
      items={messages}
      noAccent
    />
  );

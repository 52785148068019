import React, { useContext, useEffect } from 'react';
import { sendEvent } from '@utils/Analytics';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { usePlanLimitReached } from '@utils/Data/Pricing';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import {
  getCategoriesData,
  CategoryContext,
  CategoryMenu,
} from '@components/ItemsModal';
import {
  PlanLimitReachedCallout,
  GetPlanLimitReachedTextPath,
} from '@components/PlanLimitReachedCallout';
import { TemplatesList } from './TemplatesList';
import { Template } from '../../types';
import { TemplateModalHandlers } from '../../TemplatesModal';
import { TierType } from '@globals';

const getPlanLimitReachedTextPath: GetPlanLimitReachedTextPath = (
  dialogPricingEnabled: boolean,
  currentTier?: string | null,
) => {
  if (!dialogPricingEnabled)
    return {
      title:
        'modernComponents.EntryPointsModal.planLimitReached.subscribers.title',
      button:
        'modernComponents.EntryPointsModal.planLimitReached.subscribers.button',
    };
  return {
    title:
      currentTier !== TierType.trial
        ? 'modernComponents.EntryPointsModal.planLimitReached.dialogues.title.included'
        : 'modernComponents.EntryPointsModal.planLimitReached.dialogues.title.free',
    button:
      'modernComponents.EntryPointsModal.planLimitReached.dialogues.button',
  };
};

interface TemplatesListModalProps extends TemplateModalHandlers {
  templates: Template[];
  onClose: VoidFunction;
}

export const TemplatesListModal: React.FC<TemplatesListModalProps> = ({
  templates,
  onTemplateSelect,
  onCreateBlankFlow,
  onClose,
}) => {
  const { activeCategory } = useContext(CategoryContext);
  const { isConnected } = usePageConnected();
  const categories = getCategoriesData(templates);

  const planLimitReached = usePlanLimitReached();

  const templatesToShow = templates.filter(
    (tmp) => !activeCategory || tmp.category === activeCategory,
  );

  useEffect(() => {
    sendEvent({
      category: 'templates first session',
      action: 'show first screen',
      propertyBag: {
        'page connected': isConnected.toString(),
      },
    });
  }, [isConnected]);

  return (
    <Flex flexDirection="column">
      <CategoryMenu
        categories={categories}
        onSelect={(category) => {
          sendEvent({
            category: 'templates first session',
            action: category
              ? `click ${category.toLowerCase()} templates`
              : 'click all templates',
            propertyBag: {
              'page connected': isConnected.toString(),
            },
          });
        }}
      />
      <Spacer factor={4} horizontalFactor={4} />
      <TemplatesList
        callout={
          planLimitReached?.visible && (
            <PlanLimitReachedCallout
              {...planLimitReached}
              sourcePage="template list modal"
              onClick={onClose}
              getTextPath={getPlanLimitReachedTextPath}
            />
          )
        }
        templatesToShow={templatesToShow}
        onCreateBlankFlow={onCreateBlankFlow}
        onTemplateSelect={onTemplateSelect}
      />
    </Flex>
  );
};

import { FlowsQuery_bot_flows as Flow } from '@utils/Data/Flow/@types/FlowsQuery';
import { FlowGroupsQuery_bot_flow_groups as FlowGroup } from '@utils/Data/Flow/@types/FlowGroupsQuery';

export const matchStringWithFilterValue = (str: string, value: string) =>
  str.toLowerCase().includes(value.toLowerCase().trim());

const matchFlowGroupWithFilterValue = (
  flowGroups: FlowGroup[],
  flow: Flow,
  flowsFilterValue: string,
) => {
  const group = flowGroups?.find((v) => v.flow_ids?.includes(flow.id));
  if (group?.title) {
    return matchStringWithFilterValue(group.title, flowsFilterValue);
  }
  return false;
};

export const matchFlowOrFlowGroupTitleWithFilterValue = (
  flowGroups: FlowGroup[],
  flow: Flow,
  value: string,
) =>
  matchStringWithFilterValue(flow.title, value) ||
  (flowGroups && matchFlowGroupWithFilterValue(flowGroups, flow, value));

import { useQuery } from '@apollo/react-hooks';
import { getFirstFlowGroupId } from '@utils/Data/Flow/GroupingFlows/utils';
import {
  connectPage,
  ConnectPageField,
  getConnectPageUrlParams,
} from '@components/ConnectPageDialog';
import { EntryPointSelectorDialog } from '@components/EntryPointSelectorDialog';
import { useEntryPointsModal } from '@components/EntryPointsModal';
import { pixiFieldRepository } from '@components/FlowBuilder/PixiFieldRepository';
import { FlowSelectorDialogDeeplinksContainer } from '@components/FlowSelectorDialog/containers/FlowSelectorDialogDeeplinksContainer';
import { DialogLocalizationContextProvider } from '@components/FlowSelectorDialog/LocalizationContext';
import { Modal } from '@services/index';
import { sendEvent } from '@utils/Analytics';
import { FLOW_GROUPS_QUERY, FLOWS_QUERY, useAddFlow } from '@utils/Data/Flow';
import {
  FlowGroupsQuery,
  FlowGroupsQueryVariables,
} from '@utils/Data/Flow/@types/FlowGroupsQuery';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import {
  BotTabs,
  useCurrentBotId,
  useFlowTabParams,
  useLocationParams,
} from '@utils/Routing';
import { T } from 'ramda';
import React, { useCallback, useEffect } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useHistory } from 'react-router-dom';
import { testThisInChatfuel } from '@components/QuickAccessToolbar/TestThis';
import { Platform } from '@globals';
import { useDeepLinkCleanup } from '../../../DeepLinks/helpers';
import { DeepLinksMode, DeepLinksQueryParam } from '../../../DeepLinks/types';
import { TemplatesModal } from '../FlowList/TemplatesModal';
import { getDeepLinkMode, updateFlowEntryPointEnableState } from './helpers';
import { DeepLinkLocationParams } from './types';
import { FlowLoadingState } from '../FlowLoader';

enum FlowTabDeepLinksModal {
  templates = 'templates',
  enableEntryPoint = 'enableEntryPoint',
  entryPointAddFlowSelector = 'entryPointAddFlowSelector',
  testThisFlowSelector = 'testThisFlowSelector',
  testThis = 'testThis',
}

const ENTRY_POINT_DEFAULT_POSITION = { x: 0, y: 240 };

interface UseDeepLinksParams {
  onDataUpdated?: () => void;
  loadingState?: FlowLoadingState;
}

export const useFlowTabDeepLinks = ({
  onDataUpdated,
  loadingState,
}: UseDeepLinksParams) => {
  const history = useHistory();
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  const { flowId: currentFlowId } = useFlowTabParams() ?? {};
  const { isConnected, loading: loadingIsConnected } = usePageConnected(botId);

  const { addFlow } = useAddFlow({
    refetchQueries: [{ query: FLOWS_QUERY, variables: { botId: botId ?? '' } }],
    onCompleted: ({ id }) => {
      history.push(`/bot/${botId}/${BotTabs.flows}/${id}`);
      onDataUpdated?.();
    },
  });

  const { data } = useQuery<FlowGroupsQuery, FlowGroupsQueryVariables>(
    FLOW_GROUPS_QUERY,
    {
      variables: { botId: botId || '' },
      skip: !botId,
    },
  );
  const defaultFlowGroupId = getFirstFlowGroupId(data?.bot);

  const { deepLinkCleanup } = useDeepLinkCleanup();
  const locationParams = useLocationParams<DeepLinkLocationParams>();
  const deepLinkMode = getDeepLinkMode(locationParams);

  const { showEntryPointModal } = useEntryPointsModal({ onDataUpdated });

  const handleTestFlow = useCallback(() => {
    deepLinkCleanup();
    testThisInChatfuel();
  }, [deepLinkCleanup]);

  // On redirect from /go route we should show proper modal
  useEffect(() => {
    if (loadingIsConnected) {
      return;
    }

    if (deepLinkMode === DeepLinksMode.templatesModal && defaultFlowGroupId) {
      deepLinkCleanup();
      Modal.hideAll();
      Modal.show(
        ({ close }) => {
          const handleClose = () => {
            close();
          };
          return (
            <TemplatesModal
              groupId={defaultFlowGroupId}
              onCreateBlankFlow={(platform) => {
                addFlow({
                  parentGroupId: defaultFlowGroupId,
                  platform,
                }).finally(handleClose);
              }}
              onClose={handleClose}
              onCreated={handleClose}
            />
          );
        },
        {
          name: FlowTabDeepLinksModal.templates,
          mobileAdaptive: true,
        },
      );
    } else if (deepLinkMode === DeepLinksMode.entryPointsModal) {
      const flowId = locationParams[DeepLinksQueryParam.flowId];
      const isCurrentFlow = flowId === currentFlowId;

      if (flowId) {
        if (!isCurrentFlow || loadingState !== FlowLoadingState.ready) {
          return;
        }

        deepLinkCleanup();
        showEntryPointModal(
          flowId,
          locationParams[DeepLinksQueryParam.platform] || Platform.facebook,
          ENTRY_POINT_DEFAULT_POSITION,
        );
      } else {
        deepLinkCleanup();
        // FlowSelectorDialog => EntryPointModal => create EP for selected flow
        Modal.show<{ flowId: string; isNew?: boolean; platform?: Platform }>(
          ({ close, resolve }) => {
            const handleClose = () => {
              close();
            };
            return (
              <DialogLocalizationContextProvider
                header="modernComponents.FlowTabDeepLinks.entryPointsModal.flowSelector.header"
                flowsDropdownTitle="modernComponents.FlowTabDeepLinks.entryPointsModal.flowSelector.flow"
                createFlowButtonTitle="modernComponents.FlowTabDeepLinks.entryPointsModal.flowSelector.create"
                selectFlowButtonTitle="modernComponents.FlowTabDeepLinks.entryPointsModal.flowSelector.add"
              >
                <FlowSelectorDialogDeeplinksContainer
                  triggerSelectIfOneFlow
                  onSelectExisting={resolve}
                  onCreateNewFlow={resolve}
                  onDismiss={handleClose}
                />
              </DialogLocalizationContextProvider>
            );
          },
          {
            name: FlowTabDeepLinksModal.entryPointAddFlowSelector,
            skipOverloadActive: (options) => {
              return options?.name !== FlowTabDeepLinksModal.enableEntryPoint;
            },
          },
        )?.then((params) => {
          deepLinkCleanup();

          if (!params?.flowId) {
            return;
          }

          const { flowId, isNew, platform } = params;
          sendEvent({
            category: 'deeplink',
            action: 'choose flow',
            label: 'add entrypoint',
            propertyBag: { flowId, isNew, platform },
          });

          if (flowId) {
            showEntryPointModal(flowId, platform);
          }
        });
      }
    } else if (deepLinkMode === DeepLinksMode.testThis) {
      Modal.show<{ flowId: string; isNew?: boolean }>(
        ({ close, resolve }) => {
          const handleClose = () => {
            close();
            deepLinkCleanup();
          };
          return (
            <DialogLocalizationContextProvider
              header="modernComponents.FlowTabDeepLinks.testThis.flowSelector.header"
              flowsDropdownTitle="modernComponents.FlowTabDeepLinks.entryPointsModal.flowSelector.flow"
              selectFlowButtonTitle="modernComponents.FlowTabDeepLinks.entryPointsModal.flowSelector.select"
            >
              <FlowSelectorDialogDeeplinksContainer
                onDismiss={handleClose}
                triggerSelectIfOneFlow
                onSelectExisting={resolve}
                showCreateButton={false}
              />
            </DialogLocalizationContextProvider>
          );
        },
        {
          name: FlowTabDeepLinksModal.testThisFlowSelector,
          skipOverloadActive: T,
          onOverlayDismiss: deepLinkCleanup,
        },
      )?.then((params) => {
        if (!params?.flowId) {
          return;
        }

        const { flowId, isNew } = params;
        sendEvent({
          category: 'deeplink',
          action: 'select flow',
          label: 'test flow',
          propertyBag: { flowId, isNew },
        });

        if (flowId !== currentFlowId) {
          const queryParams = new URLSearchParams(history.location.search);
          queryParams.set(DeepLinksQueryParam.testFlowId, flowId);
          history.push(
            `/bot/${botId}/${BotTabs.flows}/${flowId}?${queryParams}`,
          );
        } else {
          handleTestFlow();
        }
      });
    } else if (deepLinkMode === DeepLinksMode.testThisFlowSelected) {
      const { dlTestFlowId } = locationParams;
      if (dlTestFlowId) {
        handleTestFlow();
      }
    } else if (deepLinkMode === DeepLinksMode.testThisFb) {
      if (currentFlowId) {
        handleTestFlow();
      }
    } else if (deepLinkMode === DeepLinksMode.enableEntryPoint) {
      // Select EP
      Modal.show<{ flowId: string; entryPointId: string }>(
        ({ close, resolve }) => {
          const handleClose = () => {
            close();
            deepLinkCleanup();
          };
          return (
            <EntryPointSelectorDialog
              triggerSelectIfOneEP
              onNoEntryPoints={() => {
                const queryParams = new URLSearchParams(
                  history.location.search,
                );
                queryParams.set(
                  DeepLinksQueryParam.mode,
                  DeepLinksMode.entryPointsModal,
                );
                history.push(
                  `/bot/${botId}/${BotTabs.flows}/${
                    currentFlowId ?? ''
                  }?${queryParams}`,
                );
              }}
              onDismiss={handleClose}
              onSelectExisting={resolve}
            />
          );
        },
        {
          id: DeepLinksMode.enableEntryPoint,
          name: FlowTabDeepLinksModal.enableEntryPoint,
          skipOverloadActive: T,
          onOverlayDismiss: deepLinkCleanup,
        },
      )?.then((params) => {
        if (!params) {
          return;
        }

        const { flowId, entryPointId } = params;
        sendEvent({
          category: 'deeplink',
          action: 'select entry point',
          label: 'test flow',
          propertyBag: {
            flowId,
            blockId: entryPointId,
          },
        });
        if (flowId !== currentFlowId) {
          const queryParams = new URLSearchParams(history.location.search);
          queryParams.set(DeepLinksQueryParam.entryPointId, entryPointId);
          history.push(
            `/bot/${botId}/${BotTabs.flows}/${flowId}?${queryParams}`,
          );
        } else {
          updateFlowEntryPointEnableState({
            botId,
            flowId,
            entryPointId,
            isConnected,
            onEnabled: deepLinkCleanup,
            onClose: deepLinkCleanup,
          });
        }
      });
    } else if (deepLinkMode === DeepLinksMode.enableEntryPointSelected) {
      const { dlEntryPointId } = locationParams;
      if (dlEntryPointId && currentFlowId) {
        updateFlowEntryPointEnableState({
          botId,
          flowId: currentFlowId,
          entryPointId: dlEntryPointId,
          isConnected,
          onEnabled: deepLinkCleanup,
          onClose: deepLinkCleanup,
        });
      }
    } else if (deepLinkMode === DeepLinksMode.enableEntryPointFb) {
      const urlParams = getConnectPageUrlParams();
      const urlBotId = urlParams[ConnectPageField.botId];

      const cpEntryPointId = urlParams[ConnectPageField.entryPointId];
      const dlEntryPointId = locationParams[DeepLinksQueryParam.entryPointId];
      const entryPointId = cpEntryPointId || dlEntryPointId;

      if (entryPointId && currentFlowId) {
        connectPage({
          urlParams,
          botId: (urlBotId ?? botId)!,
          onClose: deepLinkCleanup,
          async onPageConnected({
            status,
            pageId,
            verifiedPermissions,
            close,
          }) {
            const controller =
              await pixiFieldRepository.getActiveControllerWhenReady();
            if (!controller) return;
            close?.();

            controller.updateFlowBuilderPage(
              status,
              pageId,
              verifiedPermissions,
            );
            await controller.allNodesWhenReady();
            controller.focusOnBlock(entryPointId);
            // TODO Enable after making validation explicit
            // controller.updateEntryPointEnableState(currentFlowId, entryPointId);

            deepLinkCleanup();
          },
        });
      }
    }
  }, [
    loadingState,
    addFlow,
    botId,
    currentFlowId,
    deepLinkCleanup,
    deepLinkMode,
    defaultFlowGroupId,
    handleTestFlow,
    history,
    isConnected,
    loadingIsConnected,
    locationParams,
    showEntryPointModal,
    t,
  ]);
};

import React, { useMemo } from 'react';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { useCurrentBotId } from '@utils/Routing';
import { useBotProStatus } from '@utils/Data/Bot';
import {
  useTiersData,
  usePaymentInfo,
  useBillingInfo,
} from '@utils/Data/Pricing';
import { sendEvent } from '@utils/Analytics';
import { isWarning } from '@components/Premium/Payments/PaymentStatus/subscriptionStatus';
import { BillingDetailsDialog } from '@components/ProPlanSection/components/BillingDetails';
import { Modal } from '@services/index';
import { BillingLayout } from './components/BillingLayout/BillingLayout';
import { useProBillingInvoiceColumns } from './BillingPageTable/useProBillingInvoiceColumns';
import { AnnualPricingCallout } from '@components/AnnualPricingCallout';
import { Permission } from '@common/services/RoleService';
import { useRolePermission } from '@utils/Roles';
import { useResetMetaForMobile } from '@utils/css/useResetMetaForMobile';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { formatPrice } from '@utils/Format';
import css from './BillingPage.css';
import { useUpdatePayments } from './useUpdatePayments';
import { getBillingAddress } from './PremiumBillingPage/utils';
import { useProBillingInvoiceHeaders } from './BillingPageTable/useProBillingInvoiceHeaders';
import {
  useBillingPaymentsHistory,
  useDialoguesPricing,
} from '@utils/Data/Pricing/hooks';
import {
  calculateHeight,
  invoicesTableAdapter,
  getNextCharge,
  chargingEntityTypeToPaymentEntityType,
} from './Table/utils';
import { PaymentHistoryItem } from './Table/types';
import { PaymentFailedCallout } from '@pages/BotPage/HomeTab/components/PaymentFailedCallout';
import { useWhatsappEnabled } from '@utils/Whatsapp';

export const BillingPage: React.FC<{}> = () => {
  useResetMetaForMobile();

  const { getValueDueToSize, isSmallScreenSize } = useDeviceMedia();
  const botId = useCurrentBotId()!;
  const { allowed: editAllowed } = useRolePermission({
    domain: 'pro',
    can: Permission.EDIT,
  });
  const { isWhatsappEnabled, loading: whatsappStatusLoading } =
    useWhatsappEnabled();
  const { pricing, pricingLoading } = useDialoguesPricing();
  const { billingInfo, billingInfoLoading } = useBillingInfo();
  const {
    pricing_plan_id: pricingPlanId,
    currency,
    current_tier: currentTierType,
    charging_entity_type,
  } = pricing ?? {};

  const { getTier } = useTiersData(botId, pricingPlanId, currency);

  const tier = getTier(currentTierType);
  const { botIsPaused } = useBotProStatus();

  const { paymentInfo, paymentInfoLoading } = usePaymentInfo();
  const { paymentsHistory, paymentsHistoryLoading } =
    useBillingPaymentsHistory();

  const updatePayments = useUpdatePayments(botId);
  const items = invoicesTableAdapter(paymentsHistory);

  const showBillingInfoDialog = () => {
    sendEvent({
      category: 'billing',
      action:
        billingInfo && Object.keys(billingInfo).length
          ? 'click address edit'
          : 'click address add',
      label: 'new dialogs pricing',
      propertyBag: { botId },
    });
    Modal.show(
      ({ close }) => (
        <BillingDetailsDialog
          botId={botId}
          onRequestClose={() => {
            sendEvent({
              category: 'billing',
              action: 'click save edited billing address',
              label: 'new dialogs pricing',
              propertyBag: { botId },
            });
            close();
          }}
        />
      ),
      { mobileAdaptive: true },
    );
  };

  const columns = useProBillingInvoiceColumns(botId);
  const headers = useProBillingInvoiceHeaders();

  const nextCharge = useMemo(
    () => getNextCharge(pricing, paymentsHistory),
    [pricing, paymentsHistory],
  );

  const paymentEntityType = chargingEntityTypeToPaymentEntityType(
    charging_entity_type || null,
  );

  const loading = paymentsHistoryLoading;
  const isBillingInfoLoading = billingInfoLoading || paymentInfoLoading;

  return (
    <Flex flexDirection="column" alignItems="center" className={css.page}>
      {editAllowed && !whatsappStatusLoading && !isWhatsappEnabled && (
        <AnnualPricingCallout />
      )}
      <Spacer factor={getValueDueToSize(2, 6)} />
      <BillingLayout<PaymentHistoryItem>
        isPaused={botIsPaused}
        planBlockLoading={pricingLoading}
        currentTier={pricing?.current_tier}
        nextTier={pricing?.next_tier}
        conversations={{
          limit: pricing?.current_tier_limit ?? 0,
          reached: pricing?.reached_limit ?? 0,
        }}
        nextPrepaymentDate={pricing?.next_prepayment_date}
        paymentEntityType={paymentEntityType}
        status={pricing?.status}
        billingCard={{
          lastDigits: paymentInfo?.active_card?.card4LastDigits,
          cardType: null,
          error: paymentInfo ? isWarning(paymentInfo) : false,
        }}
        adjustmentFeePerDialog={
          tier?.adjustment_fee_per_dialog
            ? formatPrice(tier.adjustment_fee_per_dialog, { currency })
            : null
        }
        onUpdatePaymentMethod={updatePayments}
        billingAdress={getBillingAddress(billingInfo)}
        onUpdateBillingAdress={showBillingInfoDialog}
        billingInfoLoading={isBillingInfoLoading}
        invoices={{
          items,
          columns,
          columnClassName: css.invoiceColumn,
          headers,
          errorMessage: <PaymentFailedCallout className={css.paymentFailed} />,
          itemHeight: (index) =>
            calculateHeight(
              (items[index]?.parts || []).length,
              isSmallScreenSize,
            ),
        }}
        nextCharge={nextCharge}
        nextChargeParts={nextCharge?.parts.length ?? 0}
        invoicesLoading={loading}
      />
    </Flex>
  );
};

import { TType } from '@utils/useSafeTranslation';
import { WhatsappBroadcastConfig } from '../types';
import { IBroadcastType, BroadcastTypes } from './types';
import { getTimestampInSeconds } from '@utils/DateTime/DateUtils';
import { QuantIds, QUANT_MULTIPLEXER } from '@utils/DateTime/quant';

export class ScheduleBroadcastType implements IBroadcastType {
  public readonly type = BroadcastTypes.schedule;
  public readonly title: string;
  public readonly buttonTitle: string;

  constructor(public readonly t: TType) {
    this.title = t('pages.BotPage.ReengageWhatsappTab.broadcasts.schedule');
    this.buttonTitle = t(
      'pages.BotPage.ReengageWhatsappTab.panel.buttons.schedule',
    );
  }

  public getDefaultConfig(
    config: WhatsappBroadcastConfig,
  ): WhatsappBroadcastConfig {
    const currentDate = new Date(
      Date.now() + QUANT_MULTIPLEXER[QuantIds.hours],
    );

    return {
      ...config,
      userFilter: null,
      triggerSettings: null,
      schedule: {
        __typename: 'BroadcastSchedule',
        startingTimestamp: getTimestampInSeconds(currentDate),
        useBotTimeZone: true,
        dayOfMonth: null,
        daysOfWeek: null,
      },
    };
  }

  public isCurrentBroadcast(config: WhatsappBroadcastConfig): boolean {
    return Boolean(config.schedule && !config.triggerSettings);
  }
}

import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { clone } from 'ramda';
import {
  DeleteUserFromWorkspaceMutation,
  DeleteUserFromWorkspaceMutationVariables,
} from './@types/DeleteUserFromWorkspaceMutation';
import { WORKSPACE_ADMINS_AND_INVITES_QUERY } from './useWorkspaceUsersAndInvites';
import {
  WorkspaceAdminsAndInvitesQuery,
  WorkspaceAdminsAndInvitesQueryVariables,
} from './@types/WorkspaceAdminsAndInvitesQuery';
import Maybe from 'graphql/tsutils/Maybe';

const DELETE_USER_FROM_WORKSPACE_MUTATION = gql`
  mutation DeleteUserFromWorkspaceMutation(
    $workspaceId: String!
    $userId: String!
  ) {
    deleteUserFromWorkspace(workspaceId: $workspaceId, userId: $userId) {
      removed
      userId
    }
  }
`;

export const useDeleteUserFromWorkspace = (workspaceId: string) => {
  return useMutation<
    DeleteUserFromWorkspaceMutation,
    DeleteUserFromWorkspaceMutationVariables
  >(DELETE_USER_FROM_WORKSPACE_MUTATION, {
    update: (store, { data }) => {
      if (!data?.deleteUserFromWorkspace?.removed) {
        return;
      }
      let workspaceAdminsAndInvitesQuery: Maybe<WorkspaceAdminsAndInvitesQuery>;
      const queryParams = {
        query: WORKSPACE_ADMINS_AND_INVITES_QUERY,
        variables: { workspaceId },
      };
      try {
        workspaceAdminsAndInvitesQuery = clone(
          store.readQuery<
            WorkspaceAdminsAndInvitesQuery,
            WorkspaceAdminsAndInvitesQueryVariables
          >(queryParams),
        );
      } catch {
        workspaceAdminsAndInvitesQuery = null;
      }
      if (!workspaceAdminsAndInvitesQuery) {
        return;
      }
      workspaceAdminsAndInvitesQuery.workspaceAdmins =
        workspaceAdminsAndInvitesQuery.workspaceAdmins?.filter(
          ({ id }) => id !== data?.deleteUserFromWorkspace?.userId,
        ) || null;
      store.writeQuery({
        ...queryParams,
        data: workspaceAdminsAndInvitesQuery,
      });
    },
  });
};

import React from 'react';
import { CenteredLoader } from '@ui/Loader';
import * as css from './MovedToAnotherPlace.css';
import { SectionBox } from '@components/SectionBox';

export const MovedToAnotherPlaceLoaderView = () => (
  <SectionBox className={css.wrapper}>
    <CenteredLoader />
  </SectionBox>
);

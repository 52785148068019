import {
  InviteAdminDialog,
  InviteAdminDialogProps,
} from '@components/InviteAdminDialog/InviteAdminDialog';
import { getInviteLink } from '@utils/Data/Invite/getInviteLink';
import { useCreateWorkspaceInvite } from '@utils/Data/Invite/useCreateWorkspaceInvite';
import React from 'react';
import { DEFAULT_ROLES } from '../consts';

interface InviteWorkspaceAdminDialogProps {
  workspaceId: string;
  title: string;
  onClose: InviteAdminDialogProps['onClose'];
}

export const InviteWorkspaceAdminDialog: React.FC<InviteWorkspaceAdminDialogProps> =
  ({ workspaceId, title, onClose }) => {
    const [createInvite, { loading, data }] = useCreateWorkspaceInvite();
    const handleSelectRole: InviteAdminDialogProps['onRoleSelect'] = (
      roleId,
    ) => {
      createInvite({
        variables: {
          workspaceId,
          roleId,
        },
      });
    };
    const inviteLink = data
      ? getInviteLink(data.createWorkspaceInvite)
      : undefined;

    return (
      <InviteAdminDialog
        items={DEFAULT_ROLES}
        title={title}
        onClose={onClose}
        onRoleSelect={handleSelectRole}
        inviteLoading={loading}
        inviteLink={inviteLink}
      />
    );
  };

import cn from 'classnames';
import { Button, ButtonUnstyled } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import Maybe from 'graphql/tsutils/Maybe';
import React, { useCallback } from 'react';
import css from './PremiumBillingInfo.css';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { useWhatsappConnected } from '@utils/Data/Whatsapp';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import { useHistory } from 'react-router-dom';
import { DeepLinksMode } from '../../../DeepLinks/types';
import { sendEvent } from '@utils/Analytics';

export interface PremiumBillingInfoProps {
  billingAdress: {
    company: Maybe<string>;
    email: Maybe<string>;
    adress: Maybe<string>;
    postcode: Maybe<string>;
    country: Maybe<string>;
  };
  onUpdateBillingAdress: VoidFunction;
  onUpdatePaymentMethod: VoidFunction;
  billingCard: {
    cardType: Maybe<string>;
    lastDigits: Maybe<string>;
    error: boolean;
  };
}

export const PremiumBillingInfo: React.FC<PremiumBillingInfoProps> = ({
  billingAdress,
  onUpdateBillingAdress,
  billingCard,
  onUpdatePaymentMethod,
}) => {
  const { t } = useSafeTranslation();
  const history = useHistory();
  const botId = useCurrentBotId();
  const { isWhatsappEnabled, loading: enabledLoading } = useWhatsappEnabled();
  const { isConnected, loading: connectedLoading } =
    useWhatsappConnected(botId);
  const isNeedShowWhatsappText =
    !enabledLoading && !connectedLoading && isWhatsappEnabled && !isConnected;

  const connectClickHandler = useCallback(() => {
    sendEvent({
      category: 'plans',
      label: 'payment method',
      action: 'connect whatsapp button click',
    });
    history.push(
      getTabLink(BotTabs.home, botId, {
        dlMode: DeepLinksMode.connectWhatsapp,
      }),
    );
  }, [botId, history]);

  const billingAdressItems = [
    {
      id: 'company',
      title: t('pages.Billing.info.company'),
      value: billingAdress?.company,
    },
    {
      id: 'email',
      title: t('pages.Billing.info.email'),
      value: billingAdress?.email,
    },
    {
      id: 'adress',
      title: t('pages.Billing.info.adress'),
      value: billingAdress?.adress,
    },
    {
      id: 'postcode',
      title: t('pages.Billing.info.postcode'),
      value: billingAdress?.postcode,
    },
    {
      id: 'country',
      title: t('pages.Billing.info.country'),
      value: billingAdress?.country,
    },
  ].filter((item) => item.value);

  return (
    <div className={css.billingInfo}>
      <Type size="24px" weight="semibold">
        {t('pages.Billing.info.header')}
      </Type>
      <Flex className={css.block} flexDirection="column">
        <Flex justifyContent="space-between">
          <Flex flexDirection="column">
            <Type
              size="18px"
              weight="semibold"
              className={css.cardPlaceholder}
              color={billingCard?.error ? 'red' : 'black'}
            >
              {t('pages.Billing.info.cardPlaceholder')}
            </Type>
            {billingCard?.lastDigits && (
              <>
                <Spacer factor={2} />
                <Type
                  size="15px"
                  color={billingCard?.error ? 'red' : 'black'}
                  data-testid="billing-info__last-digits"
                >
                  ... {billingCard.lastDigits}
                </Type>
                <Spacer factor={3.5} />
              </>
            )}
          </Flex>
          {billingCard?.lastDigits ? (
            <Button
              data-testid="billing-info__edit-payment-info"
              onClick={onUpdatePaymentMethod}
              intent={billingCard?.error ? 'error' : 'secondary'}
              className={cn(billingCard?.error && css.buttonRed)}
              icon={
                <Icon
                  icon="edit"
                  color={billingCard?.error ? 'white' : 'black'}
                />
              }
            />
          ) : isNeedShowWhatsappText ? null : (
            <Button
              data-testid="billing-info__add-payment-info"
              onClick={onUpdatePaymentMethod}
              icon={<Icon icon="plus" color="white" />}
            />
          )}
        </Flex>
        {isNeedShowWhatsappText && (
          <>
            <Spacer factor={2} />
            <div>
              <ButtonUnstyled
                onClick={connectClickHandler}
                className={css.connectButton}
                data-testid="billing-info__connect-whatsapp-button"
              >
                <Type color="blue" size="12px">
                  {' '}
                  {t('pages.Billing.info.whatsappConnect.button')}
                </Type>
              </ButtonUnstyled>{' '}
              <Type size="12px">
                {t('pages.Billing.info.whatsappConnect.text')}
              </Type>
            </div>
            <Spacer factor={4} />
          </>
        )}
      </Flex>
      <Flex className={css.block} flexDirection="column">
        <Flex justifyContent="space-between" alignItems="center">
          <Type size="18px" weight="semibold">
            {t('pages.Billing.info.addressPlaceholder')}
          </Type>
          {billingAdressItems.length ? (
            <Button
              data-testid="billing-info__edit-billing-address"
              onClick={onUpdateBillingAdress}
              intent="secondary"
              icon={<Icon icon="edit" color="black" />}
            />
          ) : (
            <Button
              data-testid="billing-info__add-billing-address"
              onClick={onUpdateBillingAdress}
              icon={<Icon icon="plus" color="white" />}
            />
          )}
        </Flex>
        {!!billingAdressItems.length && (
          <>
            <Spacer factor={3} />
            {billingAdressItems.map(({ title, value, id }) => (
              <Flex className={css.options} key={id}>
                <Type size="15px" color="greyDark" className={css.optionTitle}>
                  {title}
                </Type>
                <Type size="15px" className={css.optionValue}>
                  {value}
                </Type>
              </Flex>
            ))}
          </>
        )}
      </Flex>
    </div>
  );
};

export const PremiumBillingInfoLoading = () => {
  const { t } = useSafeTranslation();

  return (
    <div className={css.billingInfo}>
      <Type size="24px" weight="semibold">
        {t('pages.Billing.info.header')}
      </Type>
      <Flex className={css.block} flexDirection="column">
        <Flex justifyContent="space-between">
          <Flex flexDirection="column">
            <Type
              size="18px"
              weight="semibold"
              className={css.cardPlaceholder}
              color="black"
            >
              {t('pages.Billing.info.cardPlaceholder')}
            </Type>

            <Spacer factor={2} />
            <LoadingPlaceholder height={20} />
            <Spacer factor={3.5} />
          </Flex>

          <Button
            className={css.buttonLoader}
            data-testid="billing-info__payment-info-loader"
            loading
            intent="secondary"
          >
            _
          </Button>
        </Flex>
      </Flex>

      <Flex className={css.block} flexDirection="column">
        <Flex justifyContent="space-between" alignItems="center">
          <Type size="18px" weight="semibold">
            {t('pages.Billing.info.addressPlaceholder')}
          </Type>

          <Button
            className={css.buttonLoader}
            data-testid="billing-info__edit-billing-loader"
            loading
            intent="secondary"
          >
            _
          </Button>
        </Flex>

        <Spacer factor={3} />

        <Flex className={css.options}>
          <LoadingPlaceholder width={84} height={20} />
          <Spacer horizontalFactor={2} factor={0} />
          <LoadingPlaceholder width={192} height={20} />
        </Flex>

        <Flex className={css.options}>
          <LoadingPlaceholder width={84} height={20} />
          <Spacer horizontalFactor={2} factor={0} />
          <LoadingPlaceholder width={160} height={20} />
        </Flex>
      </Flex>
    </div>
  );
};

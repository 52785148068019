import React from 'react';
import { Category } from '@components/ItemsModal';
import { Platform } from '@globals';

export enum TemplateLevel {
  basic = 'basic',
  advanced = 'advanced',
}

export type TemplateId =
  | 'instagramAlternativeToLinkInBio'
  | 'instagramBrandMention'
  | 'instagramFAQ'
  | 'instagramGiveaway'
  | 'instagramShopifyStarter'
  | 'instagramProductRecommendation'
  | 'instagramCollectFeedback'
  | 'instagramCollectEmails'
  | 'instagramQualifyLeads'
  | 'instagramSpecialOffer'
  | 'instagramGivePromoCode'
  | 'faqBasic'
  | 'faqAdvanced'
  | 'leadgenBasic'
  | 'segmentAudienceAdvanced'
  | 'scoreLeadsAdvanced'
  | 'engageFromCommentsBasic'
  | 'facebookAdsBasic'
  | 'collectFeedbackBasic'
  | 'remindEventBasic'
  | 'backInStockAlertBasic'
  | 'ecommerceAdvanced'
  | 'realtorAdvanced'
  | 'hotelAdvanced'
  | 'coffeeHouseAdvanced'
  | 'restaurantAdvanced'
  | 'conferenceAdvanced'
  | 'datePickerBasic'
  | 'covidAssistantAdvanced'
  | 'chatbotAgenciesAdvanced'
  | 'walletlyAdvanced'
  | 'shopifyAbandonedCart'
  | 'shopifyPopup'
  | 'calendly';

export type Template<T extends Category = Category> = {
  id: TemplateId;
  category: T;
  title: string;
  description: string;
  details: string;
  icon: React.FC<{ className?: string }>;
  flowId: string;
  platform: Platform;
  flowGroupId?: string;
  level?: TemplateLevel;
  alternativeId?: TemplateId;
  isNew?: boolean;
};

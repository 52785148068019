import { BlockProps } from '@utils/Data/Flow';
import { Platform } from '@globals';

export enum FlowElementType {
  none = '',
  group = 'group',
  flow = 'flow',
  template = 'template',
  entryPoint = 'entryPoint',
  createFlowForMe = 'createFlowForMe',
}

export interface FlowAddParams {
  groupId?: string;
  block?: BlockProps;
  platform?: Platform;
  onAdded?(): void;
  onCreated?(): void;
}

export interface FlowWithTemplateAddParams
  extends Omit<FlowAddParams, 'onCreated'> {}

import React, { useEffect, useMemo } from 'react';
import cn from 'classnames';
import { useFormik } from 'formik';
import { CenteredLoader } from '@ui/Loader';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { useCurrentMessageTemplateId } from '@utils/Routing';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  MessagesTemplateHeader,
  MessagesTemplateSettings,
  MessagesTemplateMessage,
  MessagesTemplatePreview,
} from './components';
import { useGetMessageTemplate } from '../../data/useGetMessageTemplate';
import { useGetMessageTemplateLanguages } from '../../data/useGetMessageTemplateLanguages';
import { ExtendFormActions } from './form/ExtendFormActionsContext';
import {
  MessagesTemplateRawType,
  MessagesTemplateFormType,
  MessagesTemplateLanguage,
} from './form/types';
import { messageTemplateToFormType } from './utils';
import * as css from './MessagesTemplate.css';
import { templateValidationSchema } from './form/TemplateValidationSchema';
import { logEvent } from '../MessagesTemplatesTable/utils';

interface MessagesTemplateFormProps {
  template: MessagesTemplateRawType;
  languages: MessagesTemplateLanguage[];
}

const MessagesTemplateForm: React.FC<MessagesTemplateFormProps> = ({
  template,
  languages,
}) => {
  const initialValues = messageTemplateToFormType(template);
  const validationSchema = useMemo(templateValidationSchema, []);
  const props = useFormik<MessagesTemplateFormType>({
    initialValues,
    validationSchema,
    onSubmit: () => undefined,
  });

  // с validateOnMount: true почему то зависает вкладка
  // возможно как то связанно с ручным проставлением touched
  useEffect(() => {
    props.validateForm(props.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ExtendFormActions languages={languages} template={template} {...props}>
      <form className={css.page} onSubmit={props.handleSubmit}>
        <MessagesTemplateHeader
          submitLoading={props.isSubmitting}
          deleteLoading={props.isSubmitting}
        />

        <main className={cn(css.main, css.fullHeight)}>
          <MessagesTemplateSettings />
          <MessagesTemplateMessage />
          <MessagesTemplatePreview />
        </main>
      </form>
    </ExtendFormActions>
  );
};

export const MessagesTemplate = () => {
  const { t } = useSafeTranslation();
  const messageTemplateId = useCurrentMessageTemplateId()!;
  const template = useGetMessageTemplate(messageTemplateId);
  const languages = useGetMessageTemplateLanguages();

  useEffect(() => logEvent({ action: 'visit' }), []);

  if (template.loading || languages.loading) {
    return (
      <div className={css.page}>
        <MessagesTemplateHeader submitLoading deleteLoading />
        <Flex
          className={css.fullHeight}
          alignItems="center"
          justifyContent="center"
        >
          <Flex flexDirection="column">
            <CenteredLoader size="xl" />
            <Spacer factor={8} />
            <Type weight="medium">
              {t('pages.MessagesTemplates.loadingText')}
            </Type>
          </Flex>
        </Flex>
      </div>
    );
  }

  return (
    <MessagesTemplateForm
      template={template.data!.messageTemplate}
      languages={languages.data!.messageTemplateLanguages}
    />
  );
};

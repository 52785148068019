import React, { useCallback, useState } from 'react';
import { MenuComboboxBase } from '@ui/SimpleCombobox/Combobox/MenuComboboxBase';
import { Spacer } from '@ui/Spacer';
import { Divider } from '@ui/Menu';
import { ScrollBox } from '@ui/ScrollBox';
import { isPhoneVerified } from '@utils/Data/Whatsapp';
import { WhatsappSelectProps } from '../types';
import { NoPhonesCallout } from '../../NoPhonesCallout/NoPhonesCallout';
import { CurrentPhoneToggle } from '../CurrentPhoneToggle/CurrentPhoneToggle';
import { renderItemTitle } from '../utils';
import { AddAccountButton } from '../AddAccountButton/AddAccountButton';
import { PhoneNumberItem } from '../PhoneNumberItem/PhoneNumberItem';
import { BusinessAccountItem } from '../BusinessAccountItem/BusinessAccountItem';
import { WHATSAPP_BUSINESS_ACCOUNT_DEFAULT_NAME } from '../../../consts';
import * as css from './DesktopWhatsappPhoneSelect.css';
import { useBotsTitlesMap } from '@utils/Data/Bot/useBotsTitlesMap';
import { useBotFeatures } from '@utils/Data/Bot';
import { useCurrentBotId } from '@utils/Routing';

interface DesktopWhatsappPhoneSelectProps extends WhatsappSelectProps {
  hasNoPhones?: boolean;
}

export const DesktopWhatsappPhoneSelect: React.FC<DesktopWhatsappPhoneSelectProps> =
  ({
    currentPhone,
    currentAccount,
    whatsappPhoneEntries,
    hasNoPhones,
    onAddPhone,
    onAddAccount,
    onPhoneChange,
    isConfirmed,
    handlePhoneVerificationClick,
  }) => {
    const [showNoPhonesCallout, setShowNoPhonesCallout] = useState(false);
    const handleAccountMissClick = useCallback(() => {
      setShowNoPhonesCallout(true);
    }, []);
    const botIdToTitle = useBotsTitlesMap();

    const botId = useCurrentBotId()!;
    const { botFeatures } = useBotFeatures(botId);
    const isBuisnessAppIntegration =
      botFeatures?.whatsapp_business_app_integration;

    return (
      <MenuComboboxBase
        data-testid="whatsapp-phone-select"
        itemToString={renderItemTitle}
        menuBoxClassName={css.menuBox}
        onChange={onPhoneChange}
        renderMenuHead={() =>
          (hasNoPhones || showNoPhonesCallout) && <NoPhonesCallout />
        }
        renderInput={({ popperReference, downshift }) => (
          <CurrentPhoneToggle
            disabled={!onPhoneChange}
            currentAccount={currentAccount}
            currentPhone={currentPhone}
            ref={popperReference.ref}
            isOpen={downshift.isOpen}
            onAddPhone={onAddPhone}
            {...downshift.getToggleButtonProps()}
          />
        )}
        renderFooterItem={() => (
          <div
            onMouseDown={(event) => {
              event.preventDefault();
            }}
          >
            <Spacer factor={2} />
            <Divider />
            <Spacer factor={2} />
            <AddAccountButton onAddAccount={onAddAccount} />
          </div>
        )}
      >
        {({ downshift: { getItemProps, highlightedIndex } }) => {
          let globalIndex = 0;
          return (
            <ScrollBox
              onMouseDown={(event) => {
                event.preventDefault();
              }}
              data-testid="whatsapp-menu-box__waba-list"
            >
              {whatsappPhoneEntries?.map((entry) => {
                const { id } = entry.businessAccount;
                const title =
                  entry.businessAccount.name ??
                  WHATSAPP_BUSINESS_ACCOUNT_DEFAULT_NAME;
                const phones = entry.phones ?? [];
                return (
                  <React.Fragment key={id}>
                    <BusinessAccountItem
                      id={id}
                      title={title}
                      hasPhones={phones.length > 0}
                      onAddPhone={onAddPhone}
                      onAccountMissClick={handleAccountMissClick}
                    />
                    {phones.map((phone) => {
                      const index = globalIndex++;
                      const phoneIsVerified = isPhoneVerified(
                        phone,
                        isBuisnessAppIntegration,
                      );
                      return (
                        <PhoneNumberItem
                          key={phone.id}
                          account={entry.businessAccount}
                          phone={phone}
                          currentPhone={currentPhone}
                          phoneIsVerified={phoneIsVerified}
                          hovered={index === highlightedIndex}
                          isConfirmed={isConfirmed}
                          botIdToTitle={botIdToTitle}
                          handlePhoneVerificationClick={
                            handlePhoneVerificationClick
                          }
                          {...getItemProps({
                            item: phone,
                            index,
                            disabled: !phoneIsVerified,
                          })}
                        />
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </ScrollBox>
          );
        }}
      </MenuComboboxBase>
    );
  };

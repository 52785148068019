import gql from 'graphql-tag';
import { AI_INTENT_FRAGMENT } from '@utils/Data/Ai/common/queries';

export const GROUPS_FRAGMENT = gql`
  fragment aiGroupsFragment on AIPluginConfig {
    groups {
      ...aiGroupSettingsFragment
      intents {
        ...aiIntentFragment
      }
    }
  }
  fragment aiGroupSettingsFragment on AiGroup {
    id
    title
  }
  ${AI_INTENT_FRAGMENT}
`;

export const AI_CARD_FRAGMENT = gql`
  fragment aiCardFragment on AIPlugin {
    config {
      ...aiGroupsFragment
    }
  }
  ${GROUPS_FRAGMENT}
`;

export const AI_GROUPS_QUERY = gql`
  query AiGroupsQuery($botId: String!) {
    bot(id: $botId) {
      aiBlock {
        id
        cards {
          id
          ...aiCardFragment
        }
      }
    }
  }
  ${AI_CARD_FRAGMENT}
`;

export const ADD_AI_GROUP_MUTATION = gql`
  mutation AddAiGroupMutation($cardId: String!, $title: String!) {
    addAiGroup(cardId: $cardId, title: $title) {
      id
      title
      intents {
        ...aiIntentFragment
      }
    }
  }
  ${AI_INTENT_FRAGMENT}
`;

export const UPDATE_AI_GROUP_TITLE_MUTATION = gql`
  mutation UpdateAiGroupTitleMutation(
    $cardId: String!
    $groupId: String!
    $title: String!
  ) {
    updateAiGroupTitle(cardId: $cardId, groupId: $groupId, title: $title)
  }
`;

export const REMOVE_AI_GROUP_MUTATION = gql`
  mutation RemoveAiGroupMutation($cardId: String!, $groupId: String!) {
    removeAiGroup(cardId: $cardId, groupId: $groupId)
  }
`;

export const CLONE_AI_GROUP_MUTATION = gql`
  mutation CloneAiGroupMutation(
    $cardId: String!
    $groupId: String!
    $botId: String!
  ) {
    cloneAiGroupToBot(cardId: $cardId, groupId: $groupId, botId: $botId) {
      id
      title
      intents {
        ...aiIntentFragment
      }
    }
  }
  ${AI_INTENT_FRAGMENT}
`;

import React from 'react';
import cn from 'classnames';
import * as css from './ContactTag.css';
import { Type } from '@ui/Type';
import { IconButton } from '@ui/IconButton';
import { Tooltip2 } from '@ui/Tooltip2';

interface ContactTag {
  onDelete?: () => void;
  children: React.ReactNode;
}

export const ContactTag: React.FC<ContactTag> = ({ onDelete, children }) => {
  return (
    <div className={cn(css.tagContainer)} data-testid="user-tags-list_tag">
      <Tooltip2
        data-testid="tags-card_tag-tooltip"
        content={children}
        placement="top-start"
        style={{ maxWidth: 380 }}
      >
        {(ref, bind) => (
          <div
            ref={ref}
            className={cn(css.tag, {
              [css.canDelete]: !!onDelete,
            })}
            {...bind}
          >
            <Type
              data-testid="user-tags-list_tag-title"
              color="baseNormal"
              as="div"
              size="15px"
              className={css.tagOverflow}
            >
              {children}
            </Type>
          </div>
        )}
      </Tooltip2>
      {onDelete && (
        <IconButton
          noBackground
          color="baseNormal"
          icon="close"
          onClick={onDelete}
          className={css.iconButton}
        />
      )}
    </div>
  );
};

import React from 'react';
import {
  IntroQuestionsBlockQuestion,
  IntroQuestionsBlockQuestionProps,
} from '../IntroQuestionsBlockQuestion';

import { Flex } from '@ui/Flex';
import * as css from './IntroQuestionsBlockQuestionRadio.css';
import { IntroQuestionsQuestionProps } from '../../types';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { RadioButton } from '../../../common/RadioButton/RadioButton';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

type Item = {
  id: string;
  title: React.ReactNode;
  description?: string | null;
};

interface IntroQuestionsBlockQuestionSelectProps
  extends IntroQuestionsBlockQuestionProps,
    Omit<IntroQuestionsQuestionProps, 'showErrorState'> {
  items: Item[];
  selectedItemIds: string[] | undefined;
  onChange: (selectedItemIds: string[]) => void;
}

export const IntroQuestionsBlockQuestionRadio: React.FC<IntroQuestionsBlockQuestionSelectProps> =
  ({
    id,
    title,
    required,
    onChange,
    onBlur,
    items,
    selectedItemIds,
    disabled,
    'data-testid': dataTestid,
  }) => {
    const { isSmallScreenSize } = useDeviceMedia();
    const selectedItemId = selectedItemIds?.[0];
    return (
      <IntroQuestionsBlockQuestion
        title={title}
        required={required}
        data-testid={dataTestid}
      >
        <Flex
          className={css.box}
          flexDirection={isSmallScreenSize ? 'column' : 'row'}
        >
          {items.map((item, index) => (
            <RadioButton
              key={item.id}
              name={id}
              id={item.id}
              value={selectedItemId}
              className={css.radioButton}
              disabled={disabled}
              renderLabel={() => (
                <Flex
                  flexDirection="column"
                  fullHeight
                  justifyContent="space-between"
                  className={css.label}
                >
                  <Type weight="semibold">{item.title}</Type>
                  {item.description && (
                    <>
                      <Spacer factor={4} />
                      <Type>{item.description}</Type>
                    </>
                  )}
                </Flex>
              )}
              data-testid={`${dataTestid}_radiobutton_${index}`}
              onChange={({ currentTarget: { value } }) => {
                onChange([value]);
              }}
              onBlur={onBlur}
            />
          ))}
        </Flex>
        <Spacer factor={8} />
      </IntroQuestionsBlockQuestion>
    );
  };

import { UserListItem } from '@components/ManageUsersDialog/UserListItem/UserListItem';
import { Type } from '@ui/Type';
import { useDeleteInvite } from '@utils/Data/Invite';
import { isSomeEnum } from '@utils/isSomeEnum';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { timeFormat } from '../../../../../components/settings/admins/AdminsList/AdminsList';
import { RoleIds } from '../../../../../components/settings/admins/RolesList';
import React from 'react';
import { getRoleTexts } from '../getRoleTexts';
import { WorkspaceAdminsAndInvitesQuery_workspaceInvites } from './@types/WorkspaceAdminsAndInvitesQuery';

interface InviteWorkspaceUserListItemProps
  extends WorkspaceAdminsAndInvitesQuery_workspaceInvites {
  workspaceId: string;
}

export const InviteWorkspaceUserListItem: React.FC<InviteWorkspaceUserListItemProps> =
  ({ id, roleId, created, login, workspaceId }) => {
    const { t } = useSafeTranslation();
    const [deleteInvite, { loading: inviteDeleting }] = useDeleteInvite({
      workspaceId,
    });

    return (
      <UserListItem
        user={{
          id,
          name: (
            <Type size="15px" color="greyDark">
              {login
                ? t('components.settings.adminList.loginPending', {
                    login,
                  })
                : t('components.settings.adminList.newPending', {
                    role: isSomeEnum(RoleIds, roleId)
                      ? getRoleTexts(roleId).title
                      : '',
                  })}
            </Type>
          ),
          subtitle: created
            ? t('components.settings.adminList.addedTime', {
                time: timeFormat(created),
              })
            : '',
        }}
        onRemove={() =>
          deleteInvite({
            variables: { inviteId: id },
          })
        }
        pending={inviteDeleting}
      />
    );
  };

import i18next from 'i18next';
import { sendEvent } from '@utils/Analytics';
import {
  GetMessageTemplate_messageTemplate_content_buttons as Buttons,
  GetMessageTemplate_messageTemplate as Template,
} from '../../data/@types/GetMessageTemplate';
import { removeTypename } from '@utils/GQL/utils';
import {
  ButtonSectionType,
  MessagesTemplateSectionType,
  MessagesTemplateFormType,
  TemplateButton,
} from './form/types';
import {
  MessagesTemplateButtonInput,
  MessagesTemplateInput,
  TemplateButtonType,
} from '@globals';
import nanoid from 'nanoid';

export const formTypeToMessageTemplate = ({
  category,
  name,
  language,
  content,
}: MessagesTemplateFormType): MessagesTemplateInput =>
  removeTypename({
    name,
    category,
    language,
    content: {
      header: content.header,
      body: content.body,
      footer: content.footer,
      buttons: content.sections
        .flatMap(({ children }) => children)
        .map(({ id: _, ...button }) => button),
    },
  });

const isCallToActionButton = (type: TemplateButtonType) =>
  [TemplateButtonType.phone, TemplateButtonType.url].includes(type);
const isQuickReplyButton = (type: TemplateButtonType) =>
  [
    TemplateButtonType.quick_reply,
    TemplateButtonType.marketing_opt_out,
  ].includes(type);

export const createTemplateButton = (
  button: MessagesTemplateButtonInput,
): TemplateButton => ({
  ...button,
  id: nanoid(),
  counter_id: button.counter_id || nanoid(),
});

export const createMessageTempalteSecitons = (
  buttons: Buttons[],
): MessagesTemplateSectionType[] => {
  const callToActionButtons = buttons.filter((b) =>
    isCallToActionButton(b.type),
  );
  const quickReplyButtons = buttons.filter((b) => isQuickReplyButton(b.type));

  const ctaSection = {
    type: ButtonSectionType.callToAction,
    title: i18next.t('pages.MessagesTemplates.message.ctaHeader'),
    children: callToActionButtons.map(createTemplateButton),
  };

  const qrSection = {
    type: ButtonSectionType.quickReply,
    title: i18next.t('pages.MessagesTemplates.message.qrHeader'),
    children: quickReplyButtons.map(createTemplateButton),
  };

  return isCallToActionButton(buttons[0]?.type)
    ? [ctaSection, qrSection]
    : [qrSection, ctaSection];
};

export const messageTemplateToFormType = (
  messageTemplate: Template,
): MessagesTemplateFormType => ({
  name: messageTemplate.name,
  category: messageTemplate.category,
  language: messageTemplate.language,
  content: {
    header: removeTypename(messageTemplate.content.header),
    body: removeTypename(messageTemplate.content.body),
    footer: removeTypename(messageTemplate.content.footer),
    sections: createMessageTempalteSecitons(messageTemplate.content.buttons),
  },
});

export const logEvent = (event: Omit<PureAnalyticsEvent, 'category'>) => {
  sendEvent({
    category: 'whatsapp template',
    ...event,
  });
};

import React, { useCallback } from 'react';
import { useCurrentBotId } from '@utils/Routing';
import {
  ConnectPageField,
  ConnectPageOrigin,
  useConnectPage,
} from '@components/ConnectPageDialog';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  SizeDependentButton,
  SizeDependentButtonProps,
} from './SizeDependentButton';
import { sendHomeTabEvent } from '../../../utils';

export const ConnectPageButton: React.FC<SizeDependentButtonProps> = (
  props,
) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  const { connectPage } = useConnectPage({
    botId,
    urlParams: {
      [ConnectPageField.origin]: ConnectPageOrigin.hometabUpgrade,
      [ConnectPageField.botId]: botId,
    },
  });

  const onButtonClick = useCallback(() => {
    sendHomeTabEvent({ action: 'connect facebook page click' });
    connectPage();
  }, [connectPage]);

  return (
    <SizeDependentButton
      {...props}
      data-testid="bot-panel__connect-facebook-page-button"
      intent="primary"
      onClick={onButtonClick}
    >
      {t('pages.GrowPage.BotPanel.upgrade')}
    </SizeDependentButton>
  );
};

import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import {
  UpdateMessageTemplate,
  UpdateMessageTemplateVariables,
} from './@types/UpdateMessageTemplate';
import { useCurrentBotId } from '@utils/Routing';
import { formTypeToMessageTemplate } from '../components/MessagesTemplate/utils';
import { MessagesTemplateFormType } from '../components/MessagesTemplate/form/types';

export const UPDATE_MESSAGE_TEMPLATE_MUTATION = gql`
  mutation UpdateMessageTemplate(
    $botId: MongoObjectId!
    $templateId: MongoObjectId!
    $template: MessagesTemplateInput!
  ) {
    updateMessageTemplate(
      botId: $botId
      templateId: $templateId
      template: $template
    ) {
      id
    }
  }
`;

export const useUpdateMessageTemplate = (templateId: string) => {
  const botId = useCurrentBotId()!;
  const [mutation, queryResult] = useMutation<
    UpdateMessageTemplate,
    UpdateMessageTemplateVariables
  >(UPDATE_MESSAGE_TEMPLATE_MUTATION);

  const onUpdateMessageTemplate = (form: MessagesTemplateFormType) =>
    mutation({
      variables: {
        botId,
        templateId,
        template: formTypeToMessageTemplate(form),
      },
    });

  return [onUpdateMessageTemplate, queryResult] as const;
};

import React from 'react';
import useResizeObserver from 'use-resize-observer';
import * as ng from 'angular';
import { Flex } from '@ui/Flex';
import { angular2react } from 'angular2react';
import { useParams } from 'react-router-dom';
import { Aside } from '@components/Aside';
import { BotPageRouteParams, BotTabs } from '@utils/Routing';
import { FB_YOUTUBE_FRAME_PLAYER_PORTAL_ID } from '@components/PortalForYoutubePlayer/constants';
import { FlowBuilderVideoPlayer } from './FlowBuilderVideo';
import { useReadyToRender } from '../../../utils/AngularRouting';
import { lazyInjector } from '../../../utils/angularLazyInjector';
import { BotTabAngular } from '../../../modern-components/BotTab';
import template from '../../../components/structure/templates/structure.content.html';
import templateNull from '../../../components/structure/templates/structure.null.html';
import controller from '../../../components/structure/content.controller';
import controllerNull from '../../../components/structure/null.controller';
import { ngEnableBuiltinBlock } from './EnableBuiltinBlock';
import { BOT_TAB_ID } from '../../GrowPage/AbandonedCart';
import * as css from '../BotPage.css';

const automateTabWrapper = {
  template: () => template,
  controller,
  controllerAs: 'vm',
};

const automateNullTabWrapper = {
  template: () => templateNull,
  controller: controllerNull,
  controllerAs: 'vm',
};

export const ngAutomateTabWrapper = ng
  .module('app.pages.automateTab', [ngEnableBuiltinBlock])
  .component('automateTabWrapper', automateTabWrapper).name;

export const ngAutomateNullTabWrapper = ng
  .module('app.pages.automateNullTab', [])
  .component('automateNullTabWrapper', automateNullTabWrapper).name;

const AutomateTabWrapperFunc = () => {
  return angular2react<{}>(
    'automateTabWrapper',
    automateTabWrapper,
    lazyInjector.$injector as any,
  );
};

const AutomateNullTabWrapperFunc = () => {
  return angular2react<{}>(
    'automateNullTabWrapper',
    automateNullTabWrapper,
    lazyInjector.$injector as any,
  );
};

const AutomateTabWrapper = AutomateTabWrapperFunc();
const AutomateNullTabWrapper = AutomateNullTabWrapperFunc();

export const AutomateTab: React.FC = () => {
  const { ref: containerRef, width } = useResizeObserver<HTMLDivElement>();
  const readyToRender = useReadyToRender('common');
  const readyToRenderAutomate = useReadyToRender(BotTabs.automate);
  const { blockId } = useParams<BotPageRouteParams>();

  if (!readyToRender) {
    return <div />;
  }

  return (
    <Flex style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div
        id={FB_YOUTUBE_FRAME_PLAYER_PORTAL_ID}
        className={css.flowBuilderPortal}
      />
      <div className={css.asideContainer} ref={containerRef}>
        <Aside />
      </div>
      {/* При рефакторинге надо согласовать с тестированием удаление/перенесение этого блока с айдишником */}
      <div style={{ height: '100%', width: '100%' }} id={BOT_TAB_ID}>
        <BotTabAngular tabName="editor" rcol>
          {blockId ? (
            readyToRenderAutomate && <AutomateTabWrapper />
          ) : (
            <AutomateNullTabWrapper />
          )}
        </BotTabAngular>
      </div>
      <FlowBuilderVideoPlayer containerWidth={width} />
    </Flex>
  );
};

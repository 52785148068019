import React, { useEffect } from 'react';
import {
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
} from 'react-router-dom';
import {
  BotTabs,
  getTabLink,
  useCurrentBotId,
  useCurrentBroadcastId,
  useCurrentRoutePath,
} from '@utils/Routing';
import { WhatsappReEngageTabView } from './WhatsappReEngageTabView';
import { WhatsappReEngageFlowBuilderView } from './WhatsappReEngageFlowBuilderView';
import { useSingleBroadcastViewAllowed } from './hooks/useSingleBroadcastViewAllowed';

interface WhatsappReEngageTabProps extends RouteComponentProps {
  showAside: boolean;
  setShowAside(showAside: boolean): void;
}

export const WhatsappReEngageTab: React.FC<WhatsappReEngageTabProps> = (
  {
    showAside,
    setShowAside,
    match
  }
) => {
  const path = useCurrentRoutePath(match);
  const currentBroadcastId = useCurrentBroadcastId();
  const botId = useCurrentBotId();
  const history = useHistory();

  const { allowed, loading: loadingPermissions } =
    useSingleBroadcastViewAllowed();

  useEffect(() => {
    if (!allowed && currentBroadcastId && !loadingPermissions) {
      history.push(getTabLink(BotTabs.reEngage, botId));
    }
  }, [botId, currentBroadcastId, allowed, history, loadingPermissions]);

  return (
    <Switch>
      <Route
        path={`${path}/:broadcastId/flow/:flowId`}
        render={(props) => (
          <WhatsappReEngageFlowBuilderView
            showAside={showAside}
            setShowAside={setShowAside}
            {...props.match.params}
          />
        )}
      />
      <Route render={() => <WhatsappReEngageTabView />} />
    </Switch>
  );
};

import React from 'react';
import * as css from './MessagesTemplatesTableEmptyState.css';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { ReactComponent as Img1Svg } from './images/i1.svg';
import { ReactComponent as Img2Svg } from './images/i2.svg';
import { ReactComponent as Img3Svg } from './images/i3.svg';
import { AnchorUnstyled } from '@ui/Links';
import { Trans } from 'react-i18next';
import { Button } from '@ui/Button';
import { MessagesTemplatesTableEmptyStateSlider } from './components/MessagesTemplatesTableEmptyStateSlider';
import { useMessageTemplates } from '../../hooks/useMessageTemplates';
import { logEvent } from '../../utils';

export const MessagesTemplatesTableEmptyState: React.FC = () => {
  const { t } = useSafeTranslation();
  const { createNewTemplate, creatingNew } = useMessageTemplates();

  return (
    <div className={css.box}>
      <div className={css.content}>
        <Type
          data-testid="messages-templates-empty-state__title"
          size="44px"
          weight="extrabold"
          fontName="accent"
          color="baseNormal"
        >
          {t('pages.MessagesTemplates.Table.emptyState.title')}
        </Type>
        <Spacer factor={8} />
        <Flex gapFactor={4}>
          <div className={css.item}>
            <Img1Svg className={css.img} />
            <Spacer factor={12} />
            <Type color="baseNormal">
              <Trans
                i18nKey="pages.MessagesTemplates.Table.emptyState.boxes.item1.text"
                t={t}
              />
            </Type>
            <Spacer factor={2} />
            <AnchorUnstyled
              href="https://docs.chatfuel.com/en/articles/8717134-how-to-create-whatsapp-templates-for-broadcasts"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                logEvent({
                  action: 'click link',
                });
              }}
            >
              <Type color="accent1Normal">
                <Trans
                  i18nKey="pages.MessagesTemplates.Table.emptyState.boxes.item1.link"
                  t={t}
                />
              </Type>
            </AnchorUnstyled>
          </div>
          <div className={css.item}>
            <Img2Svg className={css.img} />
            <Spacer factor={12} />
            <Type color="baseNormal">
              <Trans
                i18nKey="pages.MessagesTemplates.Table.emptyState.boxes.item2.text"
                t={t}
              />
            </Type>
            <Spacer factor={2} />
            <AnchorUnstyled
              href="https://developers.facebook.com/docs/whatsapp/pricing/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                logEvent({
                  action: 'click link',
                });
              }}
            >
              <Type color="accent1Normal">
                {t('pages.MessagesTemplates.Table.emptyState.boxes.item2.link')}
              </Type>
            </AnchorUnstyled>
          </div>
          <div className={css.item}>
            <Img3Svg className={css.img} />
            <Spacer factor={12} />
            <Type color="baseNormal">
              {t('pages.MessagesTemplates.Table.emptyState.boxes.item3.text')}
            </Type>
          </div>
        </Flex>
        <Spacer factor={20} />
        <Button
          intent="primary"
          className={css.button}
          onClick={() => {
            logEvent({
              action: 'start button click',
            });
            createNewTemplate();
          }}
          loading={creatingNew}
          data-testid="messages-templates_create-button"
        >
          {t('pages.MessagesTemplates.Table.emptyState.button')}
        </Button>
      </div>
      <MessagesTemplatesTableEmptyStateSlider />
    </div>
  );
};

import React, { useState } from 'react';
import { Box } from '@ui/Box';
import * as css from './ContactCard.css';
import cn from 'classnames';
import { Contact } from '../useContactQuery';
import { Flex, FlexItem } from '@ui/Flex';
import { Type } from '@ui/Type';
import { ReactComponent as DefaultProfilePic } from '../icons/profile.svg';
import { ReactComponent as FbLogo } from '../icons/logos/FB.svg';
import { ReactComponent as IgLogo } from '../icons/logos/instagram.svg';
import { ReactComponent as WaLogo } from '../icons/logos/whatsApp2.svg';

import { Icon } from '@ui/Icon';
import { Platform } from '@globals';
import { Button } from '@ui/Button';
import { useHistory } from 'react-router-dom';
import { useCurrentBotId } from '@utils/Routing';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { sendEvent } from '@utils/Analytics';
import { Anchor } from '@ui/Links';

const PlatformIcon: React.FC<{ platform: Platform; className?: string }> = ({
  platform,
  className,
}) => {
  switch (platform) {
    case Platform.instagram:
      return <IgLogo className={className} />;
    case Platform.whatsapp:
      return <WaLogo className={className} />;
    case Platform.facebook:
    default:
      return <FbLogo className={className} />;
  }
};

export const ImageWithFallback = (
  props: Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'onError'>,
) => {
  const [error, setError] = useState(false);
  if (error || !props.src) {
    return <DefaultProfilePic />;
  }

  return <img onError={() => setError(true)} alt="" {...props} />;
};

export const ContactCard: React.FC<{ contact: Contact }> = ({ contact }) => {
  const { t } = useSafeTranslation();
  const history = useHistory();
  const currentBotid = useCurrentBotId();
  return (
    <Box
      data-testid="contact-card_user-info-card"
      className={css.peopleCard}
      color="white"
      borderRadiusFactor={4}
      paddingFactor={4}
    >
      <Flex gapFactor={5} alignItems="flex-start">
        <FlexItem grow>
          <Type
            size="32px"
            weight="bold"
            color="baseNormal"
            data-testid="user-info-card_contact-name"
          >
            {contact.name}
          </Type>
        </FlexItem>
        <div className={css.avatar} data-testid="user-info-card_avatar">
          <ImageWithFallback
            src={contact.profilePic || undefined}
            className={css.rounded}
            height={60}
            width={60}
          />
          <PlatformIcon
            platform={contact.platform}
            className={cn(css.avatarPlatform, css.rounded)}
          />
        </div>
      </Flex>
      <Flex flexDirection="column" gapFactor={3} className="rows">
        {contact.phone && (
          <Flex alignItems="center" gapFactor={1}>
            <Icon icon="call" size="24px" color="cobaltTertiary" />
            <Type
              size="15px"
              color="baseNormal"
              data-testid="user-info-card_contact-phone"
            >
              {contact.phone}
            </Type>
          </Flex>
        )}
        {contact.phone && contact.platform === Platform.whatsapp && (
          <Flex alignItems="center" gapFactor={1}>
            <WaLogo />
            <Anchor
              data-testid="user-info-card_wa-link"
              intent="external"
              href={`https://wa.me/${contact.phone}`}
              target="_blank"
              rel="noopener noreferrer"
              hideArrow
              style={{
                border: 0,
              }}
            >
              {`https://wa.me/${contact.phone}`}
            </Anchor>
          </Flex>
        )}
        {contact.livechatConversationId ? (
          <Button
            data-testid="user-info-card_go-to-live-chat-button"
            intent="secondary"
            size="m"
            type="button"
            fullWidth
            icon={<Icon icon="tabs_liveChat" />}
            onClick={() => {
              sendEvent({
                category: 'contact profile',
                action: 'click',
                label: 'go to livechat',
                propertyBag: {
                  contactId: contact.id,
                  tab: 'people tab',
                },
              });
              history.push(
                `/bot/${currentBotid}/livechat?folder=all&conversationId=${contact.livechatConversationId}&platform=${contact.platform}`,
              );
            }}
          >
            {t('PeoplePage.goToLivechat')}
          </Button>
        ) : (
          <Type
            size="15px"
            color="baseTertiary"
            align="center"
            data-testid="user-info-card_no-conversations"
          >
            {t('PeoplePage.noConversation')}
          </Type>
        )}
      </Flex>
    </Box>
  );
};

import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useMutation } from 'react-apollo';
import { Button } from '@ui/Button';
import { Tooltip2 } from '@ui/Tooltip2';
import { Type } from '@ui/Type';
import { Icon } from '@ui/Icon';
import { useToaster } from '@ui/Toaster';
import { useCurrentBotId } from '@utils/Routing';
import { redirect } from '@utils/UrlUtils';
import { sendEvent } from '@utils/Analytics';
import { GENERATE_CHART_EXPORT_URL } from './queries';
import {
  GenerateChartExportUrl,
  GenerateChartExportUrlVariables,
} from './@types/GenerateChartExportUrl';
import { Platform, StatsType } from '@globals';
import { DateFormat } from '@utils/DateTime';

export interface ExportChartButtonProps {
  startDate: string;
  endDate: string;
  statsType: StatsType;
  className?: string;
  disabled?: boolean;
  platform?: Platform;
}

export const ExportChartButton: React.FC<ExportChartButtonProps> = ({
  startDate,
  endDate,
  statsType,
  className,
  disabled,
  platform,
}) => {
  const { t } = useSafeTranslation();
  const { addToaster } = useToaster();
  const botId = useCurrentBotId()!;

  const [generateExportChartUrl] = useMutation<
    GenerateChartExportUrl,
    GenerateChartExportUrlVariables
  >(GENERATE_CHART_EXPORT_URL, {
    variables: {
      botId,
      statsType,
      startDate: DateFormat.YMMDD(startDate),
      endDate: DateFormat.YMMDD(endDate),
      platform,
    },
    onCompleted: ({ generateChartExportUrl: downloadUrl }) =>
      redirect(downloadUrl),
    onError: () => {
      addToaster({
        type: 'error',
        content: t('pages.Analyze.downloadFailedText'),
        timeout: 5000,
        closeButton: true,
      });
    },
  });

  const handleClick = () => {
    sendEvent({
      category: 'analytics',
      action: 'download csv',
      propertyBag: {
        chart: statsType,
        botId,
        startDate,
        endDate,
      },
    });
    addToaster({
      type: 'info',
      content: t('pages.Analyze.downloadStartedText'),
      timeout: 2000,
    });
    return generateExportChartUrl();
  };

  return (
    <Tooltip2
      content={
        <Type size="12px" color="white">
          {t('pages.Analyze.exportChartButtonTooltip')}
        </Type>
      }
      placement="top"
      type="small"
    >
      {(ref, bind) => (
        <Button
          data-testid="analyze__export-chart-button"
          {...bind}
          ref={ref}
          className={className}
          disabled={disabled}
          intent="secondary"
          size="m"
          icon={<Icon icon="download" />}
          onClick={handleClick}
        />
      )}
    </Tooltip2>
  );
};

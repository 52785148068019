import { WorkspacesBots_workspacesBots_workspaces_bots } from '@utils/Data/Workspaces/@types/WorkspacesBots';
import memoizeOne from 'memoize-one';

export enum SortTypesIds {
  planMostUsed = 'planMostUsed',
  planLeastUsed = 'planLeastUsed',
  nameAZ = 'nameAZ',
  nameZA = 'nameZA',
  lastOpened = 'lastOpened',
  dateBuilt = 'dateBuilt',
}

const compareByConnectedStatus = (
  { status: a }: WorkspacesBots_workspacesBots_workspaces_bots,
  { status: b }: WorkspacesBots_workspacesBots_workspaces_bots,
) => {
  const aConnected = Boolean(a?.page_info);
  const bConnected = Boolean(b?.page_info);
  if (aConnected && !bConnected) {
    return -1;
  }
  if (!aConnected && bConnected) {
    return 1;
  }
  return 0;
};

export const sortBots = memoizeOne(
  (
    bots: WorkspacesBots_workspacesBots_workspaces_bots[],
    sortType: SortTypesIds,
  ) =>
    bots.sort((a, b) => {
      switch (sortType) {
        case SortTypesIds.planMostUsed:
          return (
            compareByConnectedStatus(a, b) ||
            -(
              (a.dialogsPricing?.reached_limit || 0) -
              (b.dialogsPricing?.reached_limit || 0)
            )
          );
        case SortTypesIds.planLeastUsed:
          return (
            compareByConnectedStatus(a, b) ||
            (a.dialogsPricing?.reached_limit || 0) -
              (b.dialogsPricing?.reached_limit || 0)
          );
        case SortTypesIds.nameAZ:
          return a.title.localeCompare(b.title);
        case SortTypesIds.nameZA:
          return b.title.localeCompare(a.title);
        case SortTypesIds.dateBuilt:
          return -(
            parseInt(a.date_added || '0', 10) -
            parseInt(b.date_added || '0', 10)
          );
        case SortTypesIds.lastOpened:
          return -(
            parseInt(
              a.last_opened_date_for_current_user || a.last_opened_date || '0',
              10,
            ) -
            parseInt(
              b.last_opened_date_for_current_user || b.last_opened_date || '0',
              10,
            )
          );
        default:
          return 0;
      }
    }),
);

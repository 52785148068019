import React from 'react';
import { useShowChurnSurveyAdviceDialog } from '@components/CancelProDialog/ChurnSurveyAdvice/showChurnSurveyAdviceDialog';
import { usePausePro } from '@components/CancelProDialog/usePausePro';
import { PricingStatusBadgeProps } from '@components/DialogsPricing/PricingStatusBadge/PricingStatusBadge';
import { PaymentDetailsStatus } from '@globals';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { sendEvent } from '@utils/Analytics';
import { showIntercom } from '@utils/Intercom';
import Maybe from 'graphql/tsutils/Maybe';
import css from './PlanBlock.css';
import { PaymentEntityType } from '@pages/Billing/Table/types';
import {
  ChoosePlanBlock,
  PausedPlanBlock,
  PremiumPlanBlock,
  DefaultPlanBlock,
} from './components';

export interface PlanBlockProps {
  currentTier: Maybe<string>;
  nextTier: Maybe<string>;
  conversations: {
    reached: number;
    limit: number;
  };
  nextPrepaymentDate: PricingStatusBadgeProps['nextPrepaymentDate'];
  status: Maybe<PaymentDetailsStatus>;
  paymentEntityType: Maybe<PaymentEntityType>;
  loading?: boolean;
  isPremium?: boolean;
  isPaused: boolean;
  adjustmentFeePerDialog?: Maybe<string>;
}

export const PlanBlock: React.FC<PlanBlockProps> = ({
  currentTier,
  nextTier,
  conversations,
  nextPrepaymentDate,
  status,
  paymentEntityType,
  loading,
  isPremium = false,
  isPaused,
  adjustmentFeePerDialog,
}) => {
  const { show: showChurnSurvey, loading: cancelProLoading } =
    useShowChurnSurveyAdviceDialog();
  const [pausePro, pauseProLoading] = usePausePro();

  const handleShowIntercom = () => {
    sendEvent({
      category: 'billing page',
      action: 'show intercom',
      propertyBag: {
        currentTier,
      },
    });
    showIntercom();
  };

  if (loading || cancelProLoading || pauseProLoading)
    return (
      <LoadingPlaceholder
        height={180}
        fullWidth
        color="white"
        className={css.planBlock}
      />
    );

  if (!currentTier) {
    return <ChoosePlanBlock onClick={handleShowIntercom} />;
  }

  if (isPaused) {
    return <PausedPlanBlock currentTier={currentTier} nextTier={nextTier} />;
  }

  if (isPremium) {
    return (
      <PremiumPlanBlock
        currentTier={currentTier}
        nextTier={nextTier}
        onClick={handleShowIntercom}
        conversations={conversations}
        paymentEntityType={paymentEntityType}
      />
    );
  }

  return (
    <DefaultPlanBlock
      adjustmentFeePerDialog={adjustmentFeePerDialog}
      nextPrepaymentDate={nextPrepaymentDate}
      showChurnSurvey={showChurnSurvey}
      paymentEntityType={paymentEntityType}
      conversations={conversations}
      currentTier={currentTier}
      pausePro={pausePro}
      nextTier={nextTier}
      status={status}
    />
  );
};

import React, { useMemo } from 'react';
import { propEq } from 'ramda';
import cn from 'classnames';
import { defaultItemToString, Item, SimpleCombobox } from '@ui/SimpleCombobox';
import i18next from 'i18next';
import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon';
import * as css from './ResponseTypeSelector.css';
import { TextEllipsis } from '@ui/TextEllipsis';
import { MenuItem } from '@ui/Menu';
import { useAutomateEnabled } from '@utils/Data/Admin/Automate';

export enum TeachVariantsIds {
  text = 'text',
  block_or_flow = 'block_or_flow',
  ai_intent = 'ai_intent',
}

interface ResponseTypeSelectorProps {
  showBlocksVariant: boolean;
  onChange: (item: Item) => void;
  initialSelectedId?: TeachVariantsIds;
  excludeIds?: TeachVariantsIds[];
  'data-testid'?: string;
}

const TEACH_VARIANTS: () => Item[] = () => [
  {
    id: TeachVariantsIds.text,
    title: i18next.t('pages.Keywords.FrequentUnrecognizedMessages.item.text'),
  },
  {
    id: TeachVariantsIds.block_or_flow,
    title: i18next.t(
      'pages.Keywords.FrequentUnrecognizedMessages.item.blockOrFlow',
    ),
  },
  {
    id: TeachVariantsIds.ai_intent,
    title: i18next.t(
      'pages.Keywords.FrequentUnrecognizedMessages.item.existingIntent',
    ),
  },
];

const TEACH_VARIANTS_NO_BLOCKS: () => Item[] = () => [
  {
    id: TeachVariantsIds.text,
    title: i18next.t('pages.Keywords.FrequentUnrecognizedMessages.item.text'),
  },
  {
    id: TeachVariantsIds.block_or_flow,
    title: i18next.t('pages.Keywords.FrequentUnrecognizedMessages.item.flow'),
  },
  {
    id: TeachVariantsIds.ai_intent,
    title: i18next.t(
      'pages.Keywords.FrequentUnrecognizedMessages.item.existingIntent',
    ),
  },
];

export const ResponseTypeSelector: React.FC<ResponseTypeSelectorProps> = ({
  showBlocksVariant,
  onChange,
  initialSelectedId,
  excludeIds = [],
  'data-testid': dataTestid,
}) => {
  const { isAutomateEnabled } = useAutomateEnabled();
  const items = useMemo<Item[]>(
    () =>
      (showBlocksVariant
        ? TEACH_VARIANTS()
        : TEACH_VARIANTS_NO_BLOCKS()
      ).filter(({ id }) => !excludeIds.includes(id as TeachVariantsIds)),
    [showBlocksVariant, excludeIds],
  );

  return (
    <SimpleCombobox
      downshiftContainerClassName={cn(
        css.selectorBox,
        isAutomateEnabled && css.withBlock,
      )}
      initialSelectedItem={
        initialSelectedId && items.find(propEq('id', initialSelectedId))
      }
      items={items}
      menuItemStyle={{ minWidth: 150 }}
      onChange={(item) => {
        if (item) {
          onChange(item);
        }
      }}
      renderInput={({ getToggleButtonProps, selectedItem }) => {
        const title = selectedItem
          ? selectedItem.title
          : TEACH_VARIANTS()[0].title;
        return (
          <Button
            type="button"
            intent="secondary"
            {...getToggleButtonProps()}
            iconRight={<Icon icon="triangle" />}
            className={css.button}
            title={title}
            data-testid={`${dataTestid}__response-type-selector-button`}
          >
            <TextEllipsis>{title}</TextEllipsis>
          </Button>
        );
      }}
      renderItem={({ getItemProps, item, index, highlightedIndex }) => (
        <MenuItem
          key={item.id}
          {...getItemProps({ item })}
          active={index === highlightedIndex}
          title={defaultItemToString(item)}
          data-testid={`${dataTestid}__response-type-selector-item__${item.id}`}
        />
      )}
    />
  );
};

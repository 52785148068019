import React from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PaymentEntityType } from '@pages/Billing/Table/types';
import { paymentEntityTypeTranslationKey } from './utils';

export interface PaymentEntityAdjustmentFeeProps {
  adjustmentFeePerDialog: string;
  paymentEntityType: Maybe<PaymentEntityType>;
}

export const PaymentEntityAdjustmentFee: React.FC<PaymentEntityAdjustmentFeeProps> =
  ({ adjustmentFeePerDialog, paymentEntityType }) => {
    const { t } = useSafeTranslation();

    const i18nKey = paymentEntityTypeTranslationKey(paymentEntityType);

    return (
      <Flex flexDirection="column">
        <Type size="15px" weight="semibold">
          {adjustmentFeePerDialog}
        </Type>
        {i18nKey && (
          <Type size="15px" color="greyDark">
            {`${t('pages.Billing.premiumPage.perExtra')} ${t(i18nKey)}`}
          </Type>
        )}
      </Flex>
    );
  };

import React from 'react';
import cn from 'classnames';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Button, ButtonUnstyled } from '@ui/Button';
import {
  TemplateItem,
  TemplateItemProps,
} from '../../../FlowTab/FlowList/TemplatesModal/components/TemplatesListModal/TemplateItem';
import { Template } from '../../../FlowTab/FlowList/TemplatesModal/types';
import * as css from './TemplatesPanelView.css';
import { HOME_TAB_EVENT_CATEGORY } from '../../consts';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { SectionBox } from '@components/SectionBox';
import { useSafeTranslation } from '@utils/useSafeTranslation';

interface TemplatesPanelViewProps
  extends Pick<TemplateItemProps, 'onTemplateSelect'> {
  title: string;
  templates: Array<Template>;
  templatesLeft: number;
  onButtonClick: () => void;
}

export const TemplatesPanelView: React.FC<TemplatesPanelViewProps> = ({
  title,
  templates,
  templatesLeft,
  onButtonClick,
  onTemplateSelect,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const { t } = useSafeTranslation();

  return (
    <SectionBox data-testid="templates-panel">
      <Type size="24px" weight="medium">
        {title}
      </Type>

      <Spacer factor={6} />

      <Flex justifyContent="space-between" className={css.templatesListBox}>
        <div className={cn(css.templates)}>
          {templates.map((template) => (
            <TemplateItem
              key={template.id}
              className={css.tempalteItem}
              iconClassName={css.templateIcon}
              buttonClassName={css.templateButton}
              template={template}
              onTemplateSelect={onTemplateSelect}
              eventCategory={HOME_TAB_EVENT_CATEGORY}
            />
          ))}
        </div>
        {templatesLeft > 0 &&
          (isSmallScreenSize ? (
            <Button
              intent="secondary"
              onClick={onButtonClick}
              className={cn(css.buttonMobile)}
              data-testid="template_plus-button"
            >
              {t('modernComponents.TemplatesModal.seeMore')}
            </Button>
          ) : (
            <ButtonUnstyled
              onClick={onButtonClick}
              className={cn(css.button)}
              data-testid="template_plus-button"
            >
              <Type size="15px" weight="medium">
                + {templatesLeft}
              </Type>
            </ButtonUnstyled>
          ))}
      </Flex>
    </SectionBox>
  );
};

import React, { useCallback, useEffect } from 'react';
import { PaymentEntityType } from './Table/types';
import * as css from './BillingShopifyPage.css';
import { DefaultPlanBlock } from './components/PlanBlock/components';
import { removeEntity } from '@components/FlowBuilder/components';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PaymentDetailsStatus, ShopifySubscriptionStatus } from '@globals';
import { useShopifyAccount } from '@utils/Integrations/Shopify/useShopifyAccount';
import { useShopifyPricingPlan } from '../Plans/hooks/useShopifyPricingPlan';
import { useDialoguesPricing } from '@utils/Data/Pricing';
import noop from 'lodash-es/noop';
import { CenteredLoader } from '@ui/Loader';
import { useCancelShopifySubscription } from './hocks/useCancelShopifySubscription';
import { useHistory } from 'react-router-dom';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import { formatPrice } from '@utils/Format';

enum ShopifyTiers {
  business = 'business',
  trial = 'trial',
}

const SHOPIFY_TO_PAYMENT_STATUS = {
  [ShopifySubscriptionStatus.active]: PaymentDetailsStatus.active,
  [ShopifySubscriptionStatus.cap_reached]: PaymentDetailsStatus.active,
  [ShopifySubscriptionStatus.cancelled]: PaymentDetailsStatus.to_be_cancelled,
  [ShopifySubscriptionStatus.pending]: PaymentDetailsStatus.cancelled,
};

export const BillingShopifyPage: React.FC<{}> = () => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId();
  const history = useHistory();
  const { account, loading: shopifyAccountLoading } =
    useShopifyAccount('network-only');
  const { shopifyPricingPlan, loading: shopifyPricingPlanLoading } =
    useShopifyPricingPlan();
  const { pricing, pricingLoading } = useDialoguesPricing();
  const {
    cancelShopifySubscription,
    loading: cancelShopifySubscriptionLoading,
  } = useCancelShopifySubscription();
  const isTrial = Date.now() < (account?.subscription?.trialEndsOn || 0);
  const status = account?.subscription?.status;

  const goToPlans = useCallback(() => {
    history.push(getTabLink(BotTabs.plans, botId));
  }, [botId, history]);

  const cancelClickHandler = useCallback(() => {
    removeEntity({
      onSubmit: async () => {
        await cancelShopifySubscription();
        goToPlans();
      },
      renderActionText: () => t('pages.Billing.shopify.cancelConfirm.button'),
      renderHeading: () => t('pages.Billing.shopify.cancelConfirm.title'),
      renderNoteText: () => t('pages.Billing.shopify.cancelConfirm.text'),
      renderCancelText: () => t('pages.Billing.shopify.cancelConfirm.cancel'),
    });
  }, [cancelShopifySubscription, goToPlans, t]);

  useEffect(() => {
    if (status === ShopifySubscriptionStatus.pending) {
      goToPlans();
    }
  }, [goToPlans, status]);

  if (
    shopifyAccountLoading ||
    pricingLoading ||
    shopifyPricingPlanLoading ||
    cancelShopifySubscriptionLoading
  ) {
    return <CenteredLoader className={css.loader} />;
  }

  return (
    <div className={css.box}>
      <DefaultPlanBlock
        adjustmentFeePerDialog={
          shopifyPricingPlan?.extraDialogPrice &&
          formatPrice(parseFloat(shopifyPricingPlan?.extraDialogPrice), {
            currency: shopifyPricingPlan?.subscriptionCurrency,
            decimals: 2,
          })
        }
        nextPrepaymentDate={null}
        showChurnSurvey={noop}
        paymentEntityType={PaymentEntityType.dialogs}
        conversations={{
          reached: pricing?.reached_limit || 0,
          limit: shopifyPricingPlan?.includedConversations || 0,
        }}
        currentTier={isTrial ? ShopifyTiers.trial : ShopifyTiers.business}
        pausePro={noop}
        nextTier={isTrial ? ShopifyTiers.business : null}
        status={status && SHOPIFY_TO_PAYMENT_STATUS[status]}
        notUseTimeLimitedTrial
        onCancel={cancelClickHandler}
        showAdjForTrial
      />
    </div>
  );
};

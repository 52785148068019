import Maybe from 'graphql/tsutils/Maybe';
import React from 'react';
import { Tooltip2 } from '@ui/Tooltip2';

interface PlanTooltipProps {
  tooltipContent: Maybe<string>;
}

export const PlanTooltip: React.FC<PlanTooltipProps> = ({
  tooltipContent,
  children,
}) => (
  <Tooltip2
    content={tooltipContent}
    disabled={!tooltipContent}
    placement="top"
    type="small"
  >
    {(ref, bind) => (
      <div ref={ref} {...bind} style={{ display: 'inline' }}>
        {children}
      </div>
    )}
  </Tooltip2>
);

import {
  MessagesQuery_livechatMessages_items as Message,
  MessagesQuery_livechatMessages_items_from,
  MessagesQuery_livechatMessages_items_message_whatsapp_template,
  MessagesQuery_livechatMessages_items_message_whatsapp_template_buttons,
} from '../../../../../../../../LiveChat/components/Messages/@types/MessagesQuery';
import { FromType } from '@globals';
import chatfuelLogo from './images/chatfuel.svg';

export const I18_IGNORE_ATTRIBUTES = { interpolation: { prefix: '_' } };

export const MESSAGE_COMMON_DATA = {
  __typename: 'LivechatMessageV3' as const,
  seen: true,
  conversationId: '0',
  outpacingId: null,
  status: null,
  error: null,
};

export const GALLERY_COMMON_DATA = {
  __typename: 'Gallery' as const,
  type: 'square',
  items: [],
};

export const GALLERY_ITEM_COMMON_DATA = {
  __typename: 'GalleryItem' as const,
  buttons: null,
  url: null,
  image_url: null,
  product_id: null,
  title: null,
  description: null,
};

export const ATTACHMENT_COMMON_DATA = {
  __typename: 'MessageAttachment' as const,
  id: '',
  attachment_id: '',
  type: 'image',
  url: '',
  mime_type: null,
  filename: null,
  caption: null,
  file_size: null,
  expired: null,
};

export const TEMPLATE_BUTTON_COMMON_DATA: MessagesQuery_livechatMessages_items_message_whatsapp_template_buttons =
  {
    __typename: 'WhatsappTemplateButton' as const,
    type: null,
    text: '',
    url: null,
  };

export const TEMPLATE_COMMON_DATA: MessagesQuery_livechatMessages_items_message_whatsapp_template =
  {
    __typename: 'WhatsappTemplateMessage' as const,
    name: null,
    body: null,
    footer: null,
    header_text: null,
    header_document: null,
    header_image: null,
    header_video: null,
    buttons: [],
  };

export const FROM_CHATFUEL: MessagesQuery_livechatMessages_items_from = {
  __typename: 'From' as const,
  type: FromType.admin,
  admin_id: null,
  persona: {
    __typename: 'Persona' as const,
    name: '',
    picture: chatfuelLogo,
  },
};

export const FROM_USER: MessagesQuery_livechatMessages_items_from = {
  __typename: 'From' as const,
  type: FromType.user,
  admin_id: null,
  persona: null,
};

export const mapId = (item: Omit<Message, 'id' | 'mid'>, index: number) => ({
  id: index.toString(),
  mid: index.toString(),
  ...item,
});

import React, { useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import cn from 'classnames';

import { Anchor } from '@ui/Links';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';

import * as css from './TogglableList.css';

interface TogglableListProps {
  title: string;
  items: string[] | null;
  itemsToShowNumber?: number;
}

const DEFAULT_ITEMS_TO_SHOW = 3;

export const TogglableList: React.FC<TogglableListProps> = ({
  title,
  items,
  itemsToShowNumber = DEFAULT_ITEMS_TO_SHOW,
}) => {
  const { t, i18n } = useSafeTranslation();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const itemsToShow =
    items?.length && items.length > itemsToShowNumber && isCollapsed
      ? items.slice(0, itemsToShowNumber)
      : items;

  return (itemsToShow?.length ?? 0) > 0 ? (
    <>
      <Type weight="semibold" size="15px_DEPRECATED">
        {title}
      </Type>
      <ul
        className={cn({
          [css.togglableList]: itemsToShow?.length && itemsToShow.length > 0,
          [css.togglableListLoading]: !itemsToShow?.length,
        })}
      >
        {itemsToShow ? (
          itemsToShow.map((item) => (
            <li key={item}>
              <Type size="15px_DEPRECATED">{item}</Type>
            </li>
          ))
        ) : (
          <div>
            {Array.from({ length: itemsToShowNumber }).map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={index}>
                <LoadingPlaceholder />
                <Spacer factor={2} />
              </React.Fragment>
            ))}
          </div>
        )}
        {items?.length && items.length > itemsToShowNumber && (
          <Anchor
            key="show_all_toggler"
            intent="internal"
            onClick={() => {
              setIsCollapsed(!isCollapsed);
            }}
          >
            {isCollapsed
              ? i18n.t('modernComponents.TemplatesModal.showAll', {
                  flowsNum: items.length - itemsToShowNumber,
                })
              : t('modernComponents.TemplatesModal.showLess')}
          </Anchor>
        )}
      </ul>
    </>
  ) : null;
};

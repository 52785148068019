import Maybe from 'graphql/tsutils/Maybe';
import { BotLivelinessStatus } from '@globals';
import { useCurrentBot } from '@utils/Data/Bot';
import { QueryHookOptions } from 'react-apollo';
import {
  CurrentBotQuery,
  CurrentBotQuery_bot,
  CurrentBotQueryVariables,
} from '@utils/Data/Bot/CurrentBot/@types/CurrentBotQuery';

interface BotSectionDataPayload {
  bot: CurrentBotQuery_bot | undefined;
  loading: boolean;
  teammates: Maybe<number>;
  subscribers: Maybe<number>;
  pausePricingAmount: number | undefined;
  isPaused: boolean;
  isLive: boolean;
  isWithoutPage: boolean;
}

export const useBotSectionData = (
  options?: QueryHookOptions<CurrentBotQuery, CurrentBotQueryVariables>,
): BotSectionDataPayload => {
  const { bot, loading } = useCurrentBot(options);

  const teammates = bot && bot.admins.length - 1;

  const subscribers = bot?.statistic?.current_week_total_subscribed_users;

  const isPaused = bot?.botLivelinessStatus === BotLivelinessStatus.PAUSED;
  const isLive = bot?.botLivelinessStatus === BotLivelinessStatus.LIVE;
  const isWithoutPage =
    bot?.botLivelinessStatus === BotLivelinessStatus.OFF_WITHOUT_PAGE;

  const pausePricingAmount = bot?.pausePricing?.[0]?.[1];

  const value: BotSectionDataPayload = {
    bot,
    loading,
    teammates,
    subscribers,
    pausePricingAmount,
    isPaused,
    isLive,
    isWithoutPage,
  };

  return value;
};

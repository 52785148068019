import { useCurrentBotId } from '@utils/Routing';
import { useQuery } from '@apollo/react-hooks';
import {
  FrequentUnrecognizedMessagesQuery,
  FrequentUnrecognizedMessagesQueryVariables,
} from '../@types/FrequentUnrecognizedMessagesQuery';
import { FREQUENT_UNRECOGNIZED_MESSAGES_QUERY } from '../queries';
import { useRolePermission } from '@utils/Roles';
import { WatchQueryFetchPolicy } from 'apollo-client';
import { Permission } from '../../../../../../common/services/RoleService';
import { Platform } from '@globals';

export const useFrequentUnrecognizedMessages = (
  platform: Platform,
  fetchPolicy?: WatchQueryFetchPolicy,
) => {
  const botId = useCurrentBotId();
  const { allowed, loading: permissionLoading } = useRolePermission({
    domain: 'ai',
    can: Permission.VIEW,
  });
  const { data, ...state } = useQuery<
    FrequentUnrecognizedMessagesQuery,
    FrequentUnrecognizedMessagesQueryVariables
  >(FREQUENT_UNRECOGNIZED_MESSAGES_QUERY, {
    skip: !botId || permissionLoading || !allowed,
    variables: {
      platform,
      botId: botId || '',
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy,
  });

  return {
    ...state,
    aiPhraseGroups: data?.bot.aiPhraseGroups,
  };
};

import React from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PaymentEntityType } from '@pages/Billing/Table/types';
import css from '../PlanBlock.css';
import { paymentEntityTypeTranslationKey } from './utils';

export interface Conversations {
  reached: number;
  limit: number;
}

export interface PaymentEntityStatisticsProps extends Conversations {
  paymentEntityType: Maybe<PaymentEntityType>;
}

export const PaymentEntityStatistics: React.FC<PaymentEntityStatisticsProps> =
  ({ reached, limit, paymentEntityType }) => {
    const { t } = useSafeTranslation();

    const color = reached >= limit ? 'red' : 'black';

    const i18nKey = paymentEntityTypeTranslationKey(paymentEntityType);

    return (
      <Flex flexDirection="column">
        <Type
          size="15px"
          weight="semibold"
          data-testid="payment-entity__statistics"
        >
          <Type size="15px" weight="semibold" color={color}>
            {reached}
          </Type>{' '}
          / {limit}
        </Type>
        {i18nKey && (
          <Type size="15px" color="greyDark" className={css.thisMonth}>
            {`${t(i18nKey, { count: 2 })} ${t(
              'pages.Billing.premiumPage.thisMonth',
            )}`}
          </Type>
        )}
      </Flex>
    );
  };

import { useAdminId } from '@utils/Admin/useAdminId';
import React, { useMemo } from 'react';
import { useBotAdminsAndInvites } from '../useBotAdminsAndInvites';
import {
  ManageUsersDialog,
  ManageUsersDialogProps,
} from '@components/ManageUsersDialog/ManageUsersDialog';
import { BotUserListItem } from './BotUserListItem';
import { InviteUserListItem } from './InviteUserListItem';
import { Modal } from '@services/index';
import { InviteBotAdminDialog } from './InviteBotAdminDialog';
import { CenteredLoader } from '@ui/Loader';

interface BotManageUsersDialogProps
  extends Pick<ManageUsersDialogProps, 'onClose' | 'title'> {
  botId: string;
}

export const BotManageUsersDialog: React.FC<BotManageUsersDialogProps> = ({
  botId,
  onClose,
  ...props
}) => {
  const { id: adminId } = useAdminId();
  const { invites, admins, loading } = useBotAdminsAndInvites(botId);
  const showInviteAdminPopup = () => {
    Modal.show(
      ({ close }) => (
        <InviteBotAdminDialog
          title={props.title}
          botId={botId}
          onClose={close}
        />
      ),
      { mobileAdaptive: true },
    );
  };
  const users = useMemo(() => {
    const items: React.ReactNode[] = [];

    if (loading) return [<CenteredLoader key="loader" />];

    if (invites)
      items.push(
        ...invites.map((invite) => (
          <InviteUserListItem {...invite} key={invite.id} botId={botId} />
        )),
      );

    if (admins)
      items.push(
        ...admins.map((admin) => (
          <BotUserListItem
            {...admin}
            key={admin.id}
            adminId={adminId}
            botId={botId}
          />
        )),
      );

    return items;
  }, [admins, invites, adminId, botId, loading]);

  const handleInviteClick = () => {
    onClose();
    showInviteAdminPopup();
  };

  return (
    <ManageUsersDialog
      {...props}
      onClose={onClose}
      users={users}
      onInviteClick={handleInviteClick}
    />
  );
};

import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import { Emoji } from '@ui/Emoji';
import React from 'react';
import { Type } from '@ui/Type';
import * as css from './CountryCode.css';
import { CountryCodeItem } from '../../types';

interface CountryCodeProps {
  item: CountryCodeItem;
}

export const CountryCode: React.FC<CountryCodeProps> = ({ item }) => {
  const { t } = useSafeTranslation();
  return (
    <Flex
      className={css.item}
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex alignItems="center" gapFactor={1}>
        <Emoji emoji={item.flag} size={20} />
        {item.title}
      </Flex>
      <Type color="accent2Normal">
        {t(
          'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.chooseCountryCode.free',
        )}
      </Type>
    </Flex>
  );
};

import React from 'react';
import cn from 'classnames';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { TimePicker, TimePickerProps } from '@ui/DatePicker';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as commonCSS from './common.css';
import * as css1 from './TimeControl.css';
import { Disableable } from '../types';

export interface SendInUnitsControlProps extends Disableable {
  sendInUnitValue: string;
  onSendInUnitChange: TimePickerProps['onChange'];
}

export const SendInUnitsControl: React.FC<SendInUnitsControlProps> = ({
  disabled,
  sendInUnitValue,
  onSendInUnitChange,
}) => {
  const { t } = useSafeTranslation();

  return (
    <>
      <Spacer factor={0} horizontalFactor={2.5} />
      <Type size="15px">{t('pages.BotPage.ReengageWhatsappTab.panel.at')}</Type>
      <Spacer factor={0} horizontalFactor={2.5} />

      <TimePicker
        disabled={disabled}
        defaultValue={sendInUnitValue}
        className={cn(commonCSS.dropdown, css1.timePicker)}
        onChange={onSendInUnitChange}
        data-testid="reengage-editor__send-in-time-picker"
      />
    </>
  );
};

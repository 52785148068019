import React, { useState } from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { CenteredLoader } from '@ui/Loader';
import { useAdminData } from '@utils/Data/Admin';
import { useUnmountRef } from '@utils/useUnmountRef';
import { visuallyHidden } from '@utils/css/styles';
import { Flex } from '@ui/Flex';
import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon';
import css from './CallendlyDialog.css';

export interface CallendlyDialogProps {
  close: VoidFunction;
  onEventScheduled: VoidFunction;
  callendlyUrl: string;
}

export const CallendlyDialog: React.FC<CallendlyDialogProps> = ({
  onEventScheduled,
  close,
  callendlyUrl,
}) => {
  const isUnmountRef = useUnmountRef();
  const [calendlyLoading, setCallendleLoading] = useState(true);
  const { adminData, adminDataLoading } = useAdminData();

  useCalendlyEventListener({
    onEventScheduled: () => {
      onEventScheduled();
      setTimeout(() => {
        if (isUnmountRef.current) return;
        close();
      }, 5000);
    },
    onEventTypeViewed: () => setCallendleLoading(false),
  });

  const loading = calendlyLoading || adminDataLoading;

  return (
    <Flex className={css.container} alignItems="center" justifyContent="center">
      {loading && <CenteredLoader className={css.loader} />}
      <InlineWidget
        url={callendlyUrl}
        prefill={{
          email: adminData?.me.email ?? undefined,
          name: adminData?.me.name ?? undefined,
        }}
        styles={
          loading
            ? visuallyHidden
            : {
                width: '100%',
                height: '100%',
                opacity: 1,
                pointerEvents: 'all',
              }
        }
      />
      <Button
        intent="secondary"
        icon={<Icon icon="close" />}
        className={css.close}
        onClick={close}
        data-testid="callendly-close-button"
      />
    </Flex>
  );
};

import i18next from 'i18next';
import memoize from 'lodash-es/memoize';
import * as Yup from 'yup';
import {
  MessagesTemplateButtonInput,
  MessagesTemplateCategory,
  TemplateButtonType,
} from '@globals';
import { MAX_NAME_LENGTH } from '../components/MessagesTemplateSettings/NameInput';
import { currentAttributesAllowed } from '../components/MessagesTemplateMessage/components/MessagesTempalteBody/helpers/currentAttributesAllowed';
import { getAttributeExamples } from './utils';
import { isValidUrl } from '@utils/UrlUtils';
import { getFullAttributesBoundaries } from '@ui/TextWithAttributesEditor';

const MAX_ATTRIBUTES = 1;

export const templateValidationSchema = memoize(() => {
  const requiredFieldText = i18next.t('pages.MessagesTemplates.requiredField');

  return Yup.object().shape({
    category: Yup.mixed<MessagesTemplateCategory>()
      .oneOf(Object.values(MessagesTemplateCategory))
      .required(),
    name: Yup.string().required(requiredFieldText).max(MAX_NAME_LENGTH),
    language: Yup.string().required(),
    content: Yup.object().shape({
      header: Yup.object().shape({
        text: Yup.string().test({
          params: { max: MAX_ATTRIBUTES },
          message: i18next.t('pages.MessagesTemplates.header.error', {
            max: MAX_ATTRIBUTES,
          }) as string,
          test: (value) => getAttributeExamples(value).length <= MAX_ATTRIBUTES,
        }),
        attribute_examples: Yup.array().of(
          Yup.object().shape({
            value: Yup.string().required(requiredFieldText),
          }),
        ),
      }),
      body: Yup.object().shape({
        text: Yup.string()
          .required(requiredFieldText)
          .test({
            message: i18next.t(
              'pages.MessagesTemplates.message.body.error',
            ) as string,
            test: currentAttributesAllowed,
          }),
        attribute_examples: Yup.array().of(
          Yup.object().shape({
            value: Yup.string().required(requiredFieldText),
          }),
        ),
      }),
      sections: Yup.array().of(
        Yup.object().shape({
          children: Yup.array().of(
            Yup.lazy<MessagesTemplateButtonInput>((item) => {
              if (item.type === TemplateButtonType.phone) {
                return Yup.object<MessagesTemplateButtonInput>().shape({
                  text: Yup.string().required(requiredFieldText),
                  phone_number: Yup.string()
                    .required(requiredFieldText)
                    .min(
                      12,
                      i18next.t(
                        'pages.MessagesTemplates.invalidPhone',
                      ) as string,
                    ),
                });
              }
              if (item.type === TemplateButtonType.url) {
                return Yup.object().shape({
                  text: Yup.string().required(requiredFieldText),
                  url: Yup.string()
                    .test({
                      message: i18next.t(
                        'pages.MessagesTemplates.invalidURL',
                      ) as string,
                      test: (value) =>
                        isValidUrl(value) ||
                        getFullAttributesBoundaries(value).length > 0,
                    })
                    .required(requiredFieldText),
                  attribute_examples: Yup.array().of(
                    Yup.object().shape({
                      value: Yup.string().required(requiredFieldText),
                    }),
                  ),
                }) as any;
              }

              if (item.type === TemplateButtonType.quick_reply) {
                return Yup.object<MessagesTemplateButtonInput>().shape({
                  text: Yup.string().required(requiredFieldText),
                });
              }

              return Yup.object();
            }),
          ),
        }),
      ),
    }),
  });
});

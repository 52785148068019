import React from 'react';
import cn from 'classnames';
import { VariableSizeList } from 'react-window';
import { ListItem } from '@ui/List';
import * as css from './MobileInvoices.css';

interface Column<T> {
  columnId: string;
  render(item: T, error: boolean, index: number): React.ReactElement | null;
}

export interface MobileInvoicesProps<T> {
  items: T[];
  columns: Column<T>[];
  getItemId?(item: T, index: number): string | number;
  getRejectedItem?(item: T, index: number): boolean;
  isError?(item: T): boolean;
  itemHeight: number | ((index: number) => number);
  rowClassName?: string;
  headers: string[];
  listHeight: number;
  columnClassName?: string;
  itemClassName?: string;
}

export function MobileInvoices<T>({
  listHeight,
  itemHeight,
  items,
  columns,
  getItemId = (_, index) => index,
  getRejectedItem = () => false,
  isError = () => false,
  rowClassName,
  headers,
  columnClassName,
  itemClassName,
}: MobileInvoicesProps<T>) {
  return (
    <VariableSizeList
      height={listHeight}
      width="100%"
      itemSize={
        typeof itemHeight === 'function' ? itemHeight : () => itemHeight
      }
      itemCount={items.length}
      style={{ overflow: 'hidden auto' }}
    >
      {({ index, style }) => {
        const item = items[index];
        const id = getItemId(item, index);
        const rejected = getRejectedItem(item, index);
        return (
          <div style={style}>
            <ListItem
              boxClassName={css.listItem}
              itemClassName={cn(css.item, itemClassName, {
                [css.itemError]: isError(item),
              })}
              drawDivider={index < items.length - 1}
            >
              <div
                className={cn(css.row, rowClassName, {
                  [css.rowDanger]: rejected,
                })}
              >
                {columns.map((column, i) => {
                  const header = headers[i];
                  return (
                    <div
                      className={cn(css.column, columnClassName)}
                      key={id + column.columnId}
                    >
                      {header && <div className={css.header}>{header}</div>}
                      {column.render(item, isError(item), index)}
                    </div>
                  );
                })}
              </div>
            </ListItem>
          </div>
        );
      }}
    </VariableSizeList>
  );
}

import React from 'react';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useCreateWhatsappTemplateInCfEnable } from '@utils/Whatsapp/hooks/useCreateWhatsappTemplateInCfEnable';

interface HeaderProps {
  hideNote: boolean;
}

export const Header: React.FC<HeaderProps> = ({ hideNote }) => {
  const { t } = useSafeTranslation();
  const { isCreateWhatsappTemplateInCfEnable } =
    useCreateWhatsappTemplateInCfEnable();

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Type as="h1" size="18px" weight="medium">
        {isCreateWhatsappTemplateInCfEnable
          ? t('pages.BotPage.ReengageWhatsappTab.panel.header')
          : t('pages.BotPage.ReengageWhatsappTab.panel.header_old')}
      </Type>
      {!hideNote && (
        <Type size="12px" weight="regular" color="cobaltTertiary">
          {t('pages.BotPage.ReengageWhatsappTab.panel.note')}
        </Type>
      )}
    </Flex>
  );
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import { clone } from 'ramda';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { AiIntentsListQuery_bot_aiBlock_cards_AIPlugin_config_groups_intents as AiIntent } from '@utils/Data/Ai/Groups/@types/AiIntentsListQuery';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ButtonUnstyled } from '@ui/Button';
import { Icon } from '@ui/Icon';
import {
  curriedUpdateNItemInIntent,
  EMPTY_ACTION_ITEM,
} from '@utils/Data/Ai/Groups/helpers';
import { ACTION_FIELD_BY_PLATFORM } from '@utils/Data/Ai/consts';
import { IconButton } from '@ui/IconButton';
import { KeywordsPageBlockBox } from '../../../common/KeywordsPageBlockBox';
import * as css from './KeywordsGroupIntent.css';
import { FilterTypeSelector } from './components/FilterTypeSelector';
import { KeywordsInput } from './components/KeywordsInput';
import { Respond } from '../../../common/Respond';
import { MoreMenu } from './components/MoreMenu';
import { MoreMenuItemIds } from './components/MoreMenu/MoreMenu';
import { KeywordsPlacements, SMALL_MOBILE_WIDTH } from '../../../../consts';
import { sendEvent } from '@utils/Analytics';
import {
  IntentName,
  IntentNameInstance,
} from './components/IntentName/IntentName';
import { useIsWindowWidthLess } from '@utils/DOM/useIsWindowWidthLess';
import { Platform } from '@globals';

interface KeywordsGroupIntent {
  platform: Platform;
  aiIntent: AiIntent;
  onChange: (aiIntent: AiIntent) => void;
  onSaveRequest: (aiIntent: AiIntent) => void;
  onDeleteRequest: (id: string) => void;
  isNewAiIntentInit: boolean;
}

export const KeywordsGroupIntent: React.FC<KeywordsGroupIntent> = ({
  aiIntent,
  onChange,
  onSaveRequest,
  onDeleteRequest,
  platform,
  isNewAiIntentInit,
}) => {
  const { t } = useSafeTranslation();
  const [respondAdded, setRespondAdded] = useState<boolean>(false);
  const addButtonRef = useRef<HTMLButtonElement>(null);
  const isSmallWindowSize = useIsWindowWidthLess(SMALL_MOBILE_WIDTH);
  const currentIntent = useRef<AiIntent>(aiIntent);
  const intentNameRef = useRef<IntentNameInstance | null>(null);

  const { name, filter_type: filterType, lines } = aiIntent;

  const boxRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!respondAdded) {
      return undefined;
    }
    const timeout = window.setTimeout(() => {
      if (addButtonRef.current) {
        scrollIntoView(addButtonRef.current, {
          scrollMode: 'if-needed',
          behavior: 'smooth',
          block: 'end',
        });
      }
      setRespondAdded(false); // after set focus
    });
    return () => {
      window.clearTimeout(timeout);
    };
  }, [respondAdded]);

  const updateIntent = useCallback(
    (callback: (intent: AiIntent) => AiIntent) => {
      const updatedIntent = callback(clone(currentIntent.current));
      currentIntent.current = updatedIntent;
      onChange(updatedIntent);
      onSaveRequest(updatedIntent);
    },
    [onChange, onSaveRequest],
  );

  const updateName = useCallback(
    (value: string) => {
      updateIntent((intent) => ({
        ...intent,
        name: value,
      }));
    },
    [updateIntent],
  );

  const actionFiled = ACTION_FIELD_BY_PLATFORM[platform];

  const updateNItemInKeywordGroup = curriedUpdateNItemInIntent(platform);

  const actionItems = aiIntent[actionFiled].items;

  return (
    <>
      <Spacer factor={5} />
      <KeywordsPageBlockBox
        className={css.box}
        data-testid="keywords__keywords-groups__intent-item"
      >
        <Flex className={css.colBox} ref={boxRef}>
          <div className={css.leftCol}>
            <Flex alignItems="center" className={css.header}>
              <IntentName
                ref={intentNameRef}
                value={name || ''}
                isNewAiIntentInit={isNewAiIntentInit}
                onChange={(value) => {
                  updateName(value);
                  sendEvent({
                    category: 'keywords',
                    label: 'keywords groups',
                    action: 'keywords group name change',
                  });
                }}
                data-testid="keywords__keywords-groups__keywords-group"
              />
            </Flex>
            <Spacer factor={2} />
            <Flex className={css.keywordsBox}>
              <FilterTypeSelector
                value={filterType}
                onChange={(updatedFilterType) => {
                  updateIntent((intent) => ({
                    ...intent,
                    filter_type: updatedFilterType,
                  }));
                }}
              />
              <KeywordsInput
                placement={KeywordsPlacements.keywords}
                platform={platform}
                initLines={lines}
                onChange={(updatedLines) => {
                  updateIntent((intent) => ({
                    ...intent,
                    lines: updatedLines,
                  }));
                }}
              />
            </Flex>
          </div>

          <div className={css.rightCol}>
            <Flex flexDirection="column" className={css.intentsBox}>
              {actionItems.map((item, index, { length }) => {
                if (!item) {
                  return null;
                }
                const key = `${index}_${length}`;
                const { item_type: type, blocks, text } = item;
                const isShowRemoveButton =
                  aiIntent[ACTION_FIELD_BY_PLATFORM[platform]].items.length > 1;

                return (
                  <div className={css.respondBox} key={key}>
                    {isSmallWindowSize && (
                      <div>
                        <Type size="15px" weight="semibold">
                          {t(
                            index > 0
                              ? 'pages.Keywords.KeywordGroups.Response'
                              : 'pages.Keywords.KeywordGroups.RespondWith',
                            { n: index + 1 },
                          )}
                        </Type>
                        <Spacer factor={4} />
                      </div>
                    )}
                    <Respond
                      required
                      eventLabel="keywords groups"
                      data-testid="keywords__keywords-groups__response"
                      autoFocus={index === length - 1 && respondAdded}
                      platform={platform}
                      type={type}
                      text={text || ''}
                      blocks={blocks || []}
                      onTypeChange={(updatedType) => {
                        updateIntent((intent) =>
                          updateNItemInKeywordGroup(
                            (item) => ({
                              ...item,
                              item_type: updatedType,
                            }),
                            index,
                            intent,
                          ),
                        );
                      }}
                      onTextChange={(value) => {
                        updateIntent((intent) =>
                          updateNItemInKeywordGroup(
                            (item) => ({
                              ...item,
                              text: value,
                            }),
                            index,
                            intent,
                          ),
                        );
                      }}
                      onBlocksChange={(updatedBlocks) => {
                        updateIntent((intent) =>
                          updateNItemInKeywordGroup(
                            (item) => ({
                              ...item,
                              blocks: updatedBlocks,
                            }),
                            index,
                            intent,
                          ),
                        );
                      }}
                      rightElement={
                        isShowRemoveButton && (
                          <IconButton
                            icon="close"
                            noBackground
                            className={css.removeButton}
                            onClick={() => {
                              updateIntent((intent) => {
                                const action =
                                  intent[ACTION_FIELD_BY_PLATFORM[platform]];
                                action.items.splice(index, 1);
                                action.random = action.items.length > 1;
                                return intent;
                              });
                              sendEvent({
                                category: 'keywords',
                                label: 'keywords groups',
                                action: 'remove response click',
                              });
                            }}
                            data-testid="keywords__keywords-groups__remove-response-button"
                          />
                        )
                      }
                    />
                  </div>
                );
              })}
            </Flex>

            <Spacer factor={isSmallWindowSize ? 5 : 2} />
            <Flex justifyContent="flex-end">
              <ButtonUnstyled
                ref={addButtonRef}
                onClick={() => {
                  sendEvent({
                    category: 'keywords',
                    label: 'keywords groups',
                    action: 'add random response click',
                  });
                  updateIntent((intent) => {
                    const action = intent[ACTION_FIELD_BY_PLATFORM[platform]];
                    action.items.push(EMPTY_ACTION_ITEM);
                    action.random = action.items.length > 1;
                    return intent;
                  });
                  setRespondAdded(true);
                }}
                data-testid="keywords__keywords-groups__add-random-respond-button"
              >
                <Flex alignItems="center">
                  <Icon icon="plus" size="24px" />
                  <Spacer factor={1} horizontalFactor={1} />
                  {t('pages.Keywords.KeywordGroups.AddRandomRespond')}
                </Flex>
              </ButtonUnstyled>
            </Flex>
          </div>

          <MoreMenu
            className={css.more}
            onSelect={(actionId) => {
              switch (actionId) {
                case MoreMenuItemIds.delete:
                  onDeleteRequest(aiIntent.id);
                  break;
                case MoreMenuItemIds.rename:
                  intentNameRef.current?.startRename();
                  break;
                default:
              }
            }}
          />
        </Flex>
      </KeywordsPageBlockBox>
    </>
  );
};

import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useAiStatistics } from '@utils/Data/Ai';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import { AIStatisticsView } from './AIStatisticsView';
import { AIStatisticsLoaderView } from './AIStatisticsLoaderView';
import { Platform } from '@globals';

export const AiInstagramStatistics: React.FC = () => {
  const botId = useCurrentBotId()!;
  const { instagramStatistics, loading } = useAiStatistics({
    fetchPolicy: 'network-only',
  });
  const { t } = useSafeTranslation();

  if (loading) {
    return <AIStatisticsLoaderView />;
  }

  return (
    <AIStatisticsView
      platform={Platform.instagram}
      icon="instagramFilled"
      title={t('pages.GrowPage.AIStatistics.instagram')}
      replied={instagramStatistics?.aiIntents}
      unrecognized={instagramStatistics?.unmatchedPhrases}
      linkTo={getTabLink(BotTabs.keywords, botId, {
        platform: Platform.instagram,
      })}
    />
  );
};

import React from 'react';
import cn from 'classnames';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { Title, Ul } from './Primitives';
import * as css from './ComplexPaymentView.css';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PartType, PaymentEntityType } from '../types';
import { SimplePaymentFactory } from './SimplePaymentFactory';

export interface ComplexPaymentViewProps {
  parts: Array<PartType>;
  paymentEntityType: PaymentEntityType;
}

export const ComplexPaymentView: React.FC<ComplexPaymentViewProps> = ({
  parts,
  paymentEntityType,
}) => {
  const { t } = useSafeTranslation();

  return (
    <Flex flexDirection="column">
      <Title>{t('pages.Billing.Table.complexPayment')}</Title>
      <Spacer factor={2} />
      <Ul className={css.unorderedList}>
        {parts.map((part, i) => (
          <li className={cn({ [css.listItem]: i !== 0 })}>
            <SimplePaymentFactory
              className={css.title}
              paymentEntityType={paymentEntityType}
              part={part}
            />
          </li>
        ))}
      </Ul>
    </Flex>
  );
};

import React, { memo, useCallback } from 'react';
import {
  BroadcastStatus,
  ShopifySubscriptionStatus,
  TriggeredMessageType,
  WhatsappBroadcastType,
} from '@globals';
import { Flex } from '@ui/Flex';
import { useGoToWhatsappBroadcast } from '@utils/Routing';
import Maybe from 'graphql/tsutils/Maybe';
import { useCopyWhatsappBroadcastMutation } from '@pages/BotPage/WhatsappReEngageTab/hooks/useCopyWhatsappBroadcastMutation';
import { useUpdateBroadcastStatus } from '@pages/BotPage/WhatsappReEngageTab/hooks/useUpdateBroadcastStatus';
import {
  isShopifyTrigger,
  isStartOrUnpauseButtonDisabled,
} from '@pages/BotPage/WhatsappReEngageTab/utils';
import { useShopifyAccount } from '@utils/Integrations/Shopify/useShopifyAccount';
import { Schedule, WhatsappBroadcastStats } from '../../type';
import { MainInformation } from './MainInformation/MainInformation';
import { Stats } from './Stats/Stats';
import { Status } from './Status/Status';
import { Actions } from './Actions/Actions';
import { useDeleteWhatsappBroadcast } from '../../../hooks/useDeleteWhatsappBroadcast';
import { BroadcastListItemLoader } from './BroadcastListItemLoader';
import css from './BroadcastListItem.css';
import { deleteBroadcastConfirm } from '../../../helpers/deleteBroadcastConfirm';
import { useSingleBroadcastViewAllowed } from '../../../hooks/useSingleBroadcastViewAllowed';
import { logger } from '../utils';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useCreateWhatsappTemplateInCfEnable } from '@utils/Whatsapp/hooks/useCreateWhatsappTemplateInCfEnable';

interface BroadcastListItemProps {
  status: BroadcastStatus;
  stats: Maybe<WhatsappBroadcastStats>;
  type: WhatsappBroadcastType;
  title: string;
  schedule: Maybe<Schedule>;
  id: string;
  flowId: string;
  triggerType: Maybe<TriggeredMessageType>;
  style?: React.CSSProperties;
  statusUpdatedAt: Maybe<number>;
}

export const BroadcastListItem: React.FC<BroadcastListItemProps> = memo(
  ({
    schedule,
    status,
    stats,
    type,
    title,
    id,
    flowId,
    triggerType,
    statusUpdatedAt,
    style,
  }) => {
    const { t } = useSafeTranslation();
    const shopify = useShopifyAccount();
    const goToBroadcast = useGoToWhatsappBroadcast();
    const { isCreateWhatsappTemplateInCfEnable } =
      useCreateWhatsappTemplateInCfEnable();
    const { allowed } = useSingleBroadcastViewAllowed();
    const [copy, { loading: copyLoading }] = useCopyWhatsappBroadcastMutation();
    const {
      setBroadcastPause,
      setBroadcastUnpause,
      loading: pauseLoading,
    } = useUpdateBroadcastStatus();
    const [deleteMutation, { loading: deleteLoading }] =
      useDeleteWhatsappBroadcast();

    const deleteBroadcast = useCallback(
      (broadcastId: string) => {
        deleteBroadcastConfirm(() => {
          deleteMutation(broadcastId);
        }, isCreateWhatsappTemplateInCfEnable);
      },
      [isCreateWhatsappTemplateInCfEnable, deleteMutation],
    );

    const loading = pauseLoading || copyLoading || deleteLoading;

    if (loading) return <BroadcastListItemLoader style={style} />;

    const isShopifyAccountDisconnected =
      !shopify.loading &&
      isStartOrUnpauseButtonDisabled(triggerType, Boolean(shopify.account));
    const isShopifyAccountNotPaid = shopify.account?.subscription?.status
      ? {
          [ShopifySubscriptionStatus.cancelled]: true,
          [ShopifySubscriptionStatus.active]: false,
          [ShopifySubscriptionStatus.pending]: true,
          [ShopifySubscriptionStatus.cap_reached]: false,
        }[shopify.account.subscription.status]
      : true;

    const getErrorNote = () => {
      if (isShopifyAccountDisconnected) {
        return t(
          'pages.BotPage.ReengageWhatsappTab.broadcastListItem.shopifyStoreDisconnected',
        );
      }

      if (isShopifyTrigger(triggerType) && isShopifyAccountNotPaid) {
        return t(
          'pages.BotPage.ReengageWhatsappTab.broadcastListItem.missingShopifyPayment',
        );
      }

      return null;
    };

    return (
      <Flex
        className={css.container}
        style={style}
        data-testid="whatsapp-reengage__table-row"
      >
        <Flex
          className={css.statusContainer}
          data-testid="whatsapp-reengage__reengage-status"
        >
          <Status status={status} />
        </Flex>
        <MainInformation
          title={title}
          type={type}
          status={status}
          schedule={schedule}
          triggerType={triggerType}
          statusUpdatedAt={statusUpdatedAt}
          onOpenBroadcast={() => {
            logger('open broadcast button click');
            goToBroadcast(id, flowId);
          }}
          disabled={!allowed}
        >
          <Type color="red">{getErrorNote()}</Type>
        </MainInformation>
        {/** показывается при hover */}
        <Actions
          isSetLiveDisabled={isShopifyAccountDisconnected}
          className={css.actions}
          status={status}
          onUnpause={() => {
            logger('unpause button click');
            setBroadcastUnpause(id);
          }}
          onPause={() => {
            logger('pause button click');
            setBroadcastPause(id);
          }}
          onCopy={() => {
            logger('copy button click');
            copy(id);
          }}
          onDelete={() => {
            logger('delete button click');
            deleteBroadcast(id);
          }}
          onEdit={() => {
            logger('edit button click');
            goToBroadcast(id, flowId);
          }}
          disabled={!allowed}
        />
        {
          /** не показывается при hover */ status !== BroadcastStatus.DRAFT &&
            status !== BroadcastStatus.SCHEDULED &&
            stats && (
              <Stats
                className={css.stats}
                stats={stats}
                showProgress={status === BroadcastStatus.SENT}
              />
            )
        }
      </Flex>
    );
  },
);

import gql from 'graphql-tag';

export const WHATSAPP_NOTIFY_BOT_SUBSCRIPTION_STATE_QUERY = gql`
  query WhatsappNotifyBotSubscriptionStateQuery {
    whatsappNotifyBotSubscriptionState {
      subscribed
    }
  }
`;

export const WHATSAPP_NOTIFY_BOT_SUBSCRIPTION_REQUISITES_MUTATION = gql`
  mutation WhatsappNotifyBotSubscriptionRequisitesMutation {
    getWhatsappNotifyBotSubscriptionRequisites {
      displayPhoneNumber
      expirationDate
      signUpCode
    }
  }
`;

const phoneNumberFragment = gql`
  fragment phoneNumberFragment on PhoneNumber {
    countryCode
    nationalNumber
    phoneNumber
    isUtilized
    expiredAt
  }
`;

export const ADMIN_PHONES_QUERY = gql`
  query AdminPhonesQuery {
    me {
      id
      phones {
        canPurchaseNewPhone
        phoneNumbers {
          ...phoneNumberFragment
        }
      }
    }
  }
  ${phoneNumberFragment}
`;

export const PURCHASE_ADMIN_PHONE_NUMBER_MUTATION = gql`
  mutation PurchaseAdminPhoneNumberMutation($countryCode: String!) {
    purchaseAdminPhoneNumber(countryCode: $countryCode) {
      id
      phones {
        canPurchaseNewPhone
        phoneNumbers {
          ...phoneNumberFragment
        }
      }
    }
  }
  ${phoneNumberFragment}
`;

import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { NestedMenuNodeDisplayMode, PopperMenu } from '@ui/NestedMenu';
import { useCreateBlankBot } from '@utils/Data/Bot/useCreateBlankBot';
import { WorkspacesBots_workspacesBots_workspaces_workspace } from '@utils/Data/Workspaces/@types/WorkspacesBots';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import React, { useMemo } from 'react';
import { sendEvent } from '@utils/Analytics';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';

interface AddBotButtonProps {
  workspaceWithEditingList: WorkspacesBots_workspacesBots_workspaces_workspace[];
  disabled: boolean;
  onResetSearch: VoidFunction;
}

const WORKSAPCES = 'WORKSPACES';
const WITHOUT_WORKSPACE = 'WITHOUT_WORKSPACE';

export const AddBotMenu: React.FC<AddBotButtonProps> = ({
  workspaceWithEditingList,
  disabled,
  onResetSearch,
}) => {
  const [onCreateBlankBot] = useCreateBlankBot();
  const { t } = useSafeTranslation();

  const menuItems = useMemo(() => {
    const items = [];

    if (workspaceWithEditingList.length)
      items.push({
        id: WORKSAPCES,
        title: 'Workspace',
        displayMode: NestedMenuNodeDisplayMode.nested,
        children: workspaceWithEditingList.map(({ id, name }) => ({
          id,
          title: name,
        })),
      });

    items.push({
      id: WITHOUT_WORKSPACE,
      title: 'Add without workspace',
    });

    return items;
  }, [workspaceWithEditingList]);

  return (
    <PopperMenu
      placement="bottom-start"
      modifiers={{
        preventOverflow: {
          boundariesElement: 'viewport',
        },
      }}
      items={menuItems}
      onItemSelect={({ id }) => {
        const withoutWorkspace = id === WITHOUT_WORKSPACE;
        sendEvent({
          category: 'bot list page',
          action: 'create bot click',
          label: 'premium',
          propertyBag: {
            withoutWorkspace,
          },
        });
        onCreateBlankBot(withoutWorkspace ? undefined : id);
      }}
    >
      {(ref, { setOpen }) => (
        <Button
          ref={ref}
          disabled={disabled}
          onClick={() => {
            onResetSearch();
            setOpen(true);
          }}
        >
          <Flex justifyContent="center" alignItems="center">
            <Icon icon="plus" color="white" />
            <Spacer factor={1} horizontalFactor={1} />
            {t('CreateBlankBotButton-JSXText--111-add-blank-bot')}
          </Flex>
        </Button>
      )}
    </PopperMenu>
  );
};

import React, { useCallback, useContext, useState } from 'react';
import cn from 'classnames';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ButtonUnstyled } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { CircleLoader } from '@ui/Loader/CircleLoader';
import { CategoryContext } from '@components/ItemsModal';
import { sendEvent } from '@utils/Analytics';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import * as commonCSS from '../TemplatesListModal.css';
import * as css from './CreateBlankFlow.css';
import { OnCreateBlankFlow } from '../../../TemplatesModal';
import { getPlatformByCategory } from '../../../helpers';

interface CreateBlankFlowProps {
  onCreateBlankFlow: OnCreateBlankFlow;
}

export const CreateBlankFlow: React.FC<CreateBlankFlowProps> = ({
  onCreateBlankFlow,
}) => {
  const { t } = useSafeTranslation();
  const [loading, setLoading] = useState(false);
  const { activeCategory: category } = useContext(CategoryContext);
  const { isConnected } = usePageConnected();

  const onClick = useCallback(() => {
    const platform = getPlatformByCategory(category);
    sendEvent({
      category: 'templates first session',
      action: 'create blank flow from template',
      propertyBag: {
        'page connected': isConnected.toString(),
        category,
        platform,
      },
    });
    onCreateBlankFlow(platform);
  }, [category, isConnected, onCreateBlankFlow]);

  return (
    <figure className={css.wrapper}>
      <ButtonUnstyled
        data-testid="create-blank-flow"
        className={cn(css.button, commonCSS.templateImage)}
        onClick={() => {
          onClick();
          setLoading(true);
        }}
      >
        {loading ? (
          <CircleLoader color="blue" size={52} />
        ) : (
          <Icon color="blue" icon="plus" size="52px" />
        )}
      </ButtonUnstyled>

      <Spacer factor={2} />
      <Type weight="semibold" whiteSpace="pre-wrap" size="15px" as="figcaption">
        {t('modernComponents.TemplatesModal.createBlankFlow')}
      </Type>
    </figure>
  );
};

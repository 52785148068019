import React from 'react';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './BotPanelErrorView.css';

export const BotPanelErrorView = () => {
  const { t } = useSafeTranslation();

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      className={css.wrapper}
    >
      <Type size="18px" weight="semibold">
        {t('Oops-string-1985-something-went-wrong')}
      </Type>
      <Spacer factor={2} />

      <Type size="15px">{t('pages.GrowPage.BotPanel.errorMessage')}</Type>
    </Flex>
  );
};

import { useMutation } from '@apollo/react-hooks';
import { clone } from 'ramda';
import { useCallback } from 'react';
import { sendEvent } from '@utils/Analytics';
import { FLOW_GROUPS_QUERY } from '@utils/Data/Flow';
import {
  FlowGroupsQuery,
  FlowGroupsQueryVariables,
} from '@utils/Data/Flow/@types/FlowGroupsQuery';
import { useCurrentBotId } from '@utils/Routing';
import {
  ReorderFlowGroupMutation,
  ReorderFlowGroupMutationVariables,
} from './@types/ReorderFlowGroupMutation';
import {
  REORDER_FLOW_GROUP_MUTATION,
  REORDER_FLOW_ITEM_MUTATION,
} from './query';
import {
  ReorderFlowItemMutation,
  ReorderFlowItemMutationVariables,
} from './@types/ReorderFlowItemMutation';

export const useReorderFlowGroup = () => {
  const botId = useCurrentBotId();
  const [reorderFlowGroupMutation, { loading }] = useMutation<
    ReorderFlowGroupMutation,
    ReorderFlowGroupMutationVariables
  >(REORDER_FLOW_GROUP_MUTATION, {
    onCompleted: (data) => {
      sendEvent({
        category: 'flow navigation',
        action: 'reorder',
        label: 'group',
        propertyBag: {
          botId,
          groupId: data.updateFlowGroup.id,
        },
      });
    },
  });

  const reorderFlowGroup = useCallback(
    ({ groupId, position }) => {
      reorderFlowGroupMutation({
        variables: {
          flowGroup: {
            id: groupId,
            position,
          },
        },
        update: (cache) => {
          const flowGroupsData = cache.readQuery<
            FlowGroupsQuery,
            FlowGroupsQueryVariables
          >({
            query: FLOW_GROUPS_QUERY,
            variables: { botId: botId || '' },
          });
          if (flowGroupsData?.bot) {
            const groupIndex = flowGroupsData.bot.flow_groups?.findIndex(
              (item) => item.id === groupId,
            );
            if (groupIndex === undefined || groupIndex < 0) {
              return;
            }
            const group = flowGroupsData.bot.flow_groups?.[groupIndex];
            if (!group) {
              return;
            }
            flowGroupsData.bot.flow_groups?.splice(groupIndex, 1);
            flowGroupsData.bot.flow_groups?.splice(position, 0, group);
            cache.writeQuery({
              query: FLOW_GROUPS_QUERY,
              variables: { botId: botId || '' },
              data: clone(flowGroupsData),
            });
          }
        },
      });
    },
    [reorderFlowGroupMutation, botId],
  );

  return { reorderFlowGroup, loading };
};

export const useReorderFlow = () => {
  const botId = useCurrentBotId();
  const [reorderFlowItemMutation, { loading }] = useMutation<
    ReorderFlowItemMutation,
    ReorderFlowItemMutationVariables
  >(REORDER_FLOW_ITEM_MUTATION, {
    onCompleted: (data) => {
      sendEvent({
        category: 'flow navigation',
        action: 'reorder',
        label: 'flow',
        propertyBag: {
          botId,
          flowId: data.updateFlow.id,
        },
      });
    },
  });

  const reorderFlowItem = useCallback(
    ({ targetGroupId, flowId, position }) => {
      reorderFlowItemMutation({
        variables: {
          groupId: targetGroupId,
          flow: {
            id: flowId,
            position,
          },
          flowGroup: {
            id: targetGroupId,
            collapsed: false,
          },
        },
        update: (cache) => {
          const flowGroupsData = cache.readQuery<
            FlowGroupsQuery,
            FlowGroupsQueryVariables
          >({
            query: FLOW_GROUPS_QUERY,
            variables: { botId: botId || '' },
          });
          if (flowGroupsData?.bot.flow_groups) {
            const targetGroupIndex = flowGroupsData.bot.flow_groups.findIndex(
              (item) => item.id === targetGroupId,
            );
            const sourceGroupIndex = flowGroupsData?.bot.flow_groups.findIndex(
              (item) => item.flow_ids?.includes(flowId),
            );
            if (
              targetGroupIndex === undefined ||
              sourceGroupIndex === undefined ||
              targetGroupIndex < 0 ||
              sourceGroupIndex < 0
            ) {
              return;
            }
            const targetGroup =
              flowGroupsData.bot.flow_groups[targetGroupIndex];
            const sourceGroup =
              flowGroupsData?.bot.flow_groups[sourceGroupIndex];

            sourceGroup.flow_ids?.splice(
              sourceGroup?.flow_ids?.findIndex((item) => item === flowId),
              1,
            );
            targetGroup.flow_ids?.splice(position, 0, flowId);
            flowGroupsData.bot.flow_groups[targetGroupIndex] = {
              ...targetGroup,
              collapsed: false,
            };
            cache.writeQuery({
              query: FLOW_GROUPS_QUERY,
              variables: { botId: botId || '' },
              data: clone(flowGroupsData),
            });
          }
        },
      });
    },
    [reorderFlowItemMutation, botId],
  );

  return { reorderFlowItem, loading };
};

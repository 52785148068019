import React from 'react';
import { DownshiftProps } from 'downshift';
import { Type } from '@ui/Type';
import { ButtonUnstyled } from '@ui/Button';
import { MenuCombobox } from '@ui/SimpleCombobox';
import { ItemButton } from '../../../form/types';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { MenuItem } from '@ui/Menu';
import * as css from './ButtonTypeDropdown.css';

export interface ButtonTypeDropdownProps {
  disabled: boolean;
  dropdownItems: Array<ItemButton>;
  onDropdownChange: DownshiftProps<ItemButton>['onChange'];
  selectedItem: ItemButton;
}

export const ButtonTypeDropdown: React.FC<ButtonTypeDropdownProps> = ({
  disabled,
  dropdownItems,
  onDropdownChange,
  selectedItem,
}) => {
  const hideDropdown =
    (dropdownItems.length === 1 && dropdownItems[0].id === selectedItem.id) ||
    dropdownItems.length === 0;

  if (hideDropdown) {
    return (
      <Type color="blue" weight="medium">
        {selectedItem.title}
      </Type>
    );
  }

  return (
    <MenuCombobox
      menuBoxClassName={css.menu}
      items={dropdownItems}
      onChange={onDropdownChange}
      renderInput={({ popperReference, downshift }) => (
        <ButtonUnstyled
          data-testid="messages-template-button__button-type-dropdown"
          ref={popperReference.ref}
          {...downshift.getToggleButtonProps({
            disabled,
          })}
        >
          <Flex alignItems="center" style={{ height: 24 }}>
            <Type color="blue" weight="medium">
              {selectedItem.title}
            </Type>
            <Icon icon="chevronDown" color="blue" />
          </Flex>
        </ButtonUnstyled>
      )}
    >
      {({
        downshift: { getItemProps, highlightedIndex },
        props: { item, index },
      }) => (
        <MenuItem
          title={item.title}
          active={index === highlightedIndex}
          key={item.id}
          wrapContent={Boolean(item.comment)}
          comment={item.comment}
          {...getItemProps({ item })}
        />
      )}
    </MenuCombobox>
  );
};

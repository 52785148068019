import React from 'react';
import { Modal } from '@services/index';
import { HighlightBorderBoxHideable } from '@ui/Box/HighlightBorderBoxHideable';
import { Flex } from '@ui/Flex';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { FirstSessionBlockTitle } from '../../common/FirstSessionBlockTitle';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { ReactComponent as ChooseSuitable } from './chooseSuitable.svg';
import { ReactComponent as Meeting } from './onMeeting.svg';
import { ReactComponent as MakeInitial } from './makeInitial.svg';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import { CallendlyDialog } from './CallendlyDialog';
import { useFirstSessionStep } from '../../../hooks/useFirstSessionStep';
import { FirstSessionStep } from '../../../consts';
import { sendEvent } from '@utils/Analytics';
import { useCurrentBotId } from '@utils/Routing';
import css from './BookingDemo.css';
import { useIntroQuestionVariables } from '../../../hooks/useIntroQuestionVariables';
import {
  IntroQuestionsCodes,
  IntroQuestionsIndustyOptions,
  IntroQuestionsRoleOptions,
} from '../../../types';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { isSomeEnum } from '@utils/isSomeEnum';

interface CalendlyProps {}

const introQuestionVariables = [
  IntroQuestionsCodes.industry,
  IntroQuestionsCodes.role,
];

export const BookingDemo: React.FC<CalendlyProps> = () => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId();
  const { isSmallScreenSize } = useDeviceMedia();
  const [_, setStep] = useFirstSessionStep();
  const {
    data: [industryIntroQuestion, roleIntroQuestion],
    loading: introQuestionsSavedDataLoading,
  } = useIntroQuestionVariables(introQuestionVariables);

  const isEcommerce = industryIntroQuestion
    ? industryIntroQuestion.value.includes(
        IntroQuestionsIndustyOptions.eCommerce,
      )
    : false;
  const roleValue = roleIntroQuestion?.value[0];
  const roleText = isSomeEnum(IntroQuestionsRoleOptions, roleValue)
    ? t(`pages.FirstSession.BookingDemo.roles.${roleValue}`)
    : t('pages.FirstSession.BookingDemo.professionals');

  return (
    <HighlightBorderBoxHideable
      hide={isSmallScreenSize}
      highlightEnabled={false}
      borderRadiusFactor={3}
    >
      <Flex flexDirection="column">
        {introQuestionsSavedDataLoading ? (
          <LoadingPlaceholder />
        ) : (
          <FirstSessionBlockTitle
            title={
              isEcommerce
                ? t('pages.FirstSession.BookingDemo.eCommerceTitle', {
                    role: roleText,
                  })
                : t('pages.FirstSession.BookingDemo.title')
            }
            active
          />
        )}
        <Flex
          className={css.list}
          flexDirection={isSmallScreenSize ? 'column' : 'row'}
        >
          {[
            {
              IconComponent: ChooseSuitable,
              text: t('pages.FirstSession.BookingDemo.chooseSuitable'),
            },
            {
              IconComponent: Meeting,
              text: t('pages.FirstSession.BookingDemo.onMeeting'),
            },
            {
              IconComponent: MakeInitial,
              text: t('pages.FirstSession.BookingDemo.makeInitial'),
            },
          ].map(({ IconComponent, text }) => (
            <Flex
              key={text}
              className={css.listItem}
              flexDirection={isSmallScreenSize ? 'row' : 'column'}
            >
              <IconComponent className={css.image} />
              <Type size="15px" color="cobalt">
                {text}
              </Type>
            </Flex>
          ))}
        </Flex>
        <Flex gapFactor={4} className={css.footer}>
          <Button
            intent="secondary"
            onClick={() => {
              sendEvent({
                category: 'callendly-first-session-dialog',
                action: 'skip callendly dialog',
                propertyBag: {
                  botId,
                },
              });

              setStep(FirstSessionStep.paywall);
            }}
            data-testid="fist-session_skip-booking-demo"
          >
            {t('pages.FirstSession.BookingDemo.notNow')}
          </Button>
          <Button
            className={css.makeBookingAction}
            onClick={() => {
              sendEvent({
                category: 'callendly-first-session-dialog',
                action: 'show callendly dialog',
                propertyBag: {
                  botId,
                },
              });

              Modal.show(({ close }) => (
                <CallendlyDialog
                  close={close}
                  onEventScheduled={() => {
                    sendEvent({
                      category: 'callendly-first-session-dialog',
                      action: 'event sheduled',
                      propertyBag: {
                        botId,
                      },
                    });
                    setStep(FirstSessionStep.paywall);
                  }}
                  callendlyUrl="https://calendly.com/d/gp7-gfm-hr7/bot-building-consultation-chatfuel"
                />
              ));
            }}
            data-testid="fist-session_booking-demo"
          >
            {t('pages.FirstSession.BookingDemo.makeBooking')}
          </Button>
        </Flex>
      </Flex>
    </HighlightBorderBoxHideable>
  );
};

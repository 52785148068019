import React from 'react';
import cn from 'classnames';

import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Anchor } from '@ui/Links';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';

import { DeepLinksBotListQuery_bots_status as BotStatus } from './@types/DeepLinksBotListQuery';

import * as css from './DeepLinks.css';

export const BotPlaceholder: React.FC<{ wideButton?: boolean }> = ({
  wideButton,
}) => (
  <>
    <div className={css.botItem}>
      <LoadingPlaceholder style={{ width: 36, height: 36 }} />
      <Spacer horizontalFactor={4} />
      <div className={css.botDescription}>
        <LoadingPlaceholder className={css.botTitlePlaceholder} />
        <LoadingPlaceholder className={css.botPageTitlePlaceholder} />
      </div>
      <div className={css.botActionContainer}>
        <Button disabled>
          <LoadingPlaceholder
            className={cn(css.buttonPlaceholder, {
              [css.buttonWidePlaceholder]: wideButton,
            })}
            color="white"
          />
        </Button>
      </div>
    </div>
    <Spacer factor={5} />
  </>
);

interface BotInfoProps {
  title: string;
  status: BotStatus | null;
}
export const BotInfo: React.FC<BotInfoProps> = ({ title, status }) => (
  <Flex alignItems="center">
    {status?.page_info?.picture ? (
      <div
        className={css.botImage}
        style={{ backgroundImage: `url(${status?.page_info?.picture})` }}
      />
    ) : (
      <div className={cn(css.botImage, css.botDefaultImage)} />
    )}
    <Spacer horizontalFactor={4} />
    <div className={css.botDescription}>
      <Type as="p" weight="medium" noWrap size="15px_DEPRECATED">
        {title}
      </Type>
      {status?.page_info && (
        <Type as="p" color="greyDark" noWrap size="15px_DEPRECATED">
          {window.i18next.t('Bot-JSXText-5237-connected-to')}{' '}
          <Anchor
            hideArrow
            intent="subtitle"
            size="regular"
            className={css.pageLink}
            href={`https://www.facebook.com/${status.page_info.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {status.page_info.title}
          </Anchor>
        </Type>
      )}
    </div>
  </Flex>
);

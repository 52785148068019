import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import { PhoneInput } from '@ui/PhoneInput/PhoneInput';
import { ButtonViewChildrenGrid } from './ButtonViewChildrenGrid';
import { ButtonTextInputView } from './ButtonTextInputView';
import { useExtendedFormAction } from '../../../form/ExtendFormActionsContext';
import { ButtonErrors, TemplateButton } from '../../../form/types';

export interface CallButtonViewProps {
  disabled: boolean;
  button: TemplateButton;
  buttonErrors: ButtonErrors;
  onButtonChange: (button: TemplateButton) => void;
}

export const CallButtonView: React.FC<CallButtonViewProps> = ({
  disabled,
  button,
  buttonErrors,
  onButtonChange,
}) => {
  const { t } = useSafeTranslation();
  const { saveForm } = useExtendedFormAction();

  const value = button.phone_number || '';

  return (
    <ButtonViewChildrenGrid>
      <PluginControlLabel
        light
        label={t('pages.MessagesTemplates.message.buttonText')}
      >
        {() => (
          <ButtonTextInputView
            disabled={disabled}
            error={Boolean(buttonErrors?.text)}
            buttonText={button.text || ''}
            onButtonTextChange={(e) =>
              onButtonChange({ ...button, text: e.target.value })
            }
            data-testid={`messages-template-${button.type}__button-text`}
          />
        )}
      </PluginControlLabel>

      <PluginControlLabel
        light
        label={t('pages.MessagesTemplates.message.phoneNumber')}
      >
        {({ id }) => (
          <PhoneInput
            name={id}
            disabled={disabled}
            error={buttonErrors?.phone_number}
            defaultValue={value}
            onChange={(phone_number) => {
              onButtonChange({ ...button, phone_number });
            }}
            onlyNumberInput
            onBlur={() => saveForm()}
            data-testid={`messages-template-${button.type}__button-phone`}
          />
        )}
      </PluginControlLabel>
    </ButtonViewChildrenGrid>
  );
};

import React, { useMemo } from 'react';
import {
  FROM_CHATFUEL,
  I18_IGNORE_ATTRIBUTES,
  mapId,
  MESSAGE_COMMON_DATA,
  TEMPLATE_BUTTON_COMMON_DATA,
  TEMPLATE_COMMON_DATA,
} from './common';
import { EMPTY_MESSAGE_DATA } from '@components/QuickAccessToolbar/TestThis/TestThisChatfuel/utils';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { MessagesTemplatesTableSlide } from '../MessagesTemplatesTableSlide';
import img3 from './images/img3.png';
import { WhatsappTemplateButtonType } from '@globals';

const DATE_NOW = Date.now();

export const MessageSlide3 = () => {
  const { t } = useSafeTranslation();

  const messages = useMemo(
    () =>
      [
        {
          ...MESSAGE_COMMON_DATA,
          date: DATE_NOW.toString(),
          message: {
            ...EMPTY_MESSAGE_DATA,
            whatsapp_template: {
              ...TEMPLATE_COMMON_DATA,
              header_image: img3,
              body: t(
                'pages.MessagesTemplates.Table.emptyState.slider.item3.message1.text',
                I18_IGNORE_ATTRIBUTES,
              ),
              footer: t(
                'pages.MessagesTemplates.Table.emptyState.slider.item3.message1.subtext',
              ),
              buttons: [
                {
                  ...TEMPLATE_BUTTON_COMMON_DATA,
                  text: t(
                    'pages.MessagesTemplates.Table.emptyState.slider.item3.message1.button1',
                  ),
                },
                {
                  ...TEMPLATE_BUTTON_COMMON_DATA,
                  text: t(
                    'pages.MessagesTemplates.Table.emptyState.slider.item3.message1.button2',
                  ),
                },
                {
                  ...TEMPLATE_BUTTON_COMMON_DATA,
                  text: t(
                    'pages.MessagesTemplates.Table.emptyState.slider.item3.message1.button4',
                  ),
                  type: WhatsappTemplateButtonType.MARKETING_OPT_OUT,
                },
              ],
            },
          },
          from: FROM_CHATFUEL,
        },
      ].map(mapId),
    [t],
  );

  return <MessagesTemplatesTableSlide messages={messages} />;
};

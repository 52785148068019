import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useMemo } from 'react';

export const useProBillingInvoiceHeaders = () => {
  const { t } = useSafeTranslation();
  const headers = useMemo(
    () => [
      t('pages.Billing.Table.headers.date'),
      t('pages.Billing.Table.headers.description'),
      t('pages.Billing.Table.headers.total'),
      '',
    ],
    [t],
  );

  return headers;
};

import { WhatsappBroadcast_whatsappBroadcast } from './hooks/@types/WhatsappBroadcast';

export interface WhatsappBroadcastConfig
  extends WhatsappBroadcast_whatsappBroadcast {}

export enum Repeat {
  none = 'none',
  everyDay = 'everyDay',
  weekends = 'weekends',
  everyMonth = 'everyMonth',
  workdays = 'workdays',
  custom = 'custom',
}

export interface Disableable {
  disabled: boolean;
}

export interface Errorable {
  error: boolean;
}

export enum FormValidationErrors {
  timeInPast = 'timeInPast',
  dateInPast = 'dateInPast',
}

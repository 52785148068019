import React, { useMemo } from 'react';
import { useCurrentBotId } from '@utils/Routing';
import { useDisconnectPhone } from '@utils/Data/Whatsapp/useUpdateWhatsappSettings';
import { MoreMenuItem } from '@ui/MoreMenu';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { WhatsappBusinessAccount, WhatsappPhone } from '@utils/Data/Whatsapp';
import { Modal } from '@services/index';
import { sendEvent } from '@utils/Analytics';
import { WhatsappPhoneQrDialog } from '../WhatsappPhoneQrDialog/WhatsappPhoneQrDialog';
import { DisconnectWhatsappDialog } from '../DisconnectWhatsappDialog/DisconnectWhatsappDialog';
import { useReregisterWhatsapp } from './useReregisterWhatsapp';

interface UseMoreMenuItemsArgs {
  isConnected: boolean;
  isConfirmed: boolean;
  currentAccount?: WhatsappBusinessAccount;
  currentPhone?: WhatsappPhone;
  isEditAllowed: Boolean;
}

export enum WhatsappActionType {
  disconnect = 'disconnect',
  showQrCode = 'showQrCode',
  gotoWhatsapp = 'gotoWhatsapp',
  syncData = 'syncData',
}

export const useMoreMenuItems = ({
  isConnected,
  isConfirmed,
  currentAccount,
  currentPhone,
  isEditAllowed,
}: UseMoreMenuItemsArgs) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId();
  const { disconnectPhone, loading: disconnecting } = useDisconnectPhone(botId);
  const { reregisterWhatsapp, loading: reregistering } =
    useReregisterWhatsapp();

  const whatsappActionToMenuItem: Record<WhatsappActionType, MoreMenuItem> =
    useMemo(() => {
      return {
        [WhatsappActionType.disconnect]: {
          title: t('pages.BotPage.HomeTab.Whatsapp.inactivate'),
          onClick: () => {
            sendEvent({
              category: 'whatsapp',
              action: 'show disconnect phone modal',
              propertyBag: {
                botId,
                phoneId: currentPhone?.id,
                whatsappBusinessAccountId: currentAccount?.id,
              },
            });

            Modal.show(
              ({ close }) => (
                <DisconnectWhatsappDialog
                  disconnect={() => {
                    sendEvent({
                      category: 'whatsapp',
                      action: 'disconnect phone',
                      propertyBag: {
                        botId,
                        phoneId: currentPhone?.id,
                        whatsappBusinessAccountId: currentAccount?.id,
                      },
                    });
                    disconnectPhone();
                  }}
                  close={close}
                />
              ),
              {
                mobileAdaptive: true,
                mobileProps: {
                  fitHeight: true,
                },
              },
            );
          },
          disabled: !isConfirmed,
          'data-testid': 'whatsapp-more-menu__disconnect',
        },
        [WhatsappActionType.showQrCode]: {
          title: t('pages.BotPage.HomeTab.Whatsapp.showQR'),
          onClick: () => {
            if (!currentPhone) return;

            sendEvent({
              category: 'whatsapp',
              action: 'show qr code',
              propertyBag: {
                botId,
                phoneId: currentPhone.id,
                whatsappBusinessAccountId: currentAccount?.id,
              },
            });

            Modal.show(
              ({ close }) => (
                <WhatsappPhoneQrDialog
                  currentPhone={currentPhone}
                  close={close}
                />
              ),
              {
                mobileAdaptive: true,
                mobileProps: {
                  fitHeight: true,
                },
              },
            );
          },
          disabled: !isConfirmed,
          'data-testid': 'whatsapp-more-menu__qr',
        },
        [WhatsappActionType.gotoWhatsapp]: {
          title: t('pages.BotPage.HomeTab.Whatsapp.whatsappManager'),
          onClick: () => {
            sendEvent({
              category: 'whatsapp',
              action: 'go to whatsapp manager',
              propertyBag: {
                botId,
                phoneId: currentPhone?.id,
                whatsappBusinessAccountId: currentAccount?.id,
              },
            });
            window.open(
              `https://business.facebook.com/wa/manage/home/?waba_id=${currentAccount?.id}`,
              '_blank',
            );
          },
          'data-testid': 'whatsapp-more-menu__manager',
        },
        [WhatsappActionType.syncData]: {
          title: t('pages.BotPage.HomeTab.Whatsapp.syncData'),
          tooltipElement: t('pages.BotPage.HomeTab.Whatsapp.syncDataTooltip'),
          onClick: () => {
            sendEvent({
              category: 'whatsapp',
              action: 'sync data',
              propertyBag: {
                botId,
                phoneId: currentPhone?.id,
                whatsappBusinessAccountId: currentAccount?.id,
              },
            });
            reregisterWhatsapp();
          },
          'data-testid': 'whatsapp-more-menu__sync-data',
        },
      };
    }, [
      t,
      isConfirmed,
      botId,
      currentPhone,
      currentAccount,
      disconnectPhone,
      reregisterWhatsapp,
    ]);

  const menuItems = useMemo(() => {
    const items: MoreMenuItem[] = [];

    if (isConnected && isEditAllowed) {
      items.push(whatsappActionToMenuItem[WhatsappActionType.disconnect]);
    }

    if (currentPhone) {
      items.push(whatsappActionToMenuItem[WhatsappActionType.showQrCode]);
    }

    items.push(whatsappActionToMenuItem[WhatsappActionType.gotoWhatsapp]);

    if (isConnected && isEditAllowed) {
      items.push(whatsappActionToMenuItem[WhatsappActionType.syncData]);
    }

    return items;
  }, [isConnected, currentPhone, whatsappActionToMenuItem, isEditAllowed]);

  return {
    reregistering,
    disconnecting,
    menuItems,
    whatsappActionToMenuItem,
  };
};

import React, { useMemo } from 'react';
import { Input } from '@ui/Input';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import {
  TextWithAttributesEditor,
  deserialize,
  wrapAllAttributes,
} from '@ui/TextWithAttributesEditor';
import { useAttributes } from '@utils/AttributesUtils/AttributesData';
import { useCurrentBotId } from '@utils/Routing';
import { Platform } from '@globals';
import { VariableSuggestType } from '@utils/AttributesUtils/AttributesUtilsTypes';
import * as css from './MessagesTemplateBody.css';
import { AttributeExample } from '../../../AttributeExample';
import { useExtendedFormAction } from '../../../../form/ExtendFormActionsContext';
import { TextFormatControls } from '@ui/TextFormatControls';
import { combineRefs } from '@utils/combineRefs';
import { insertText } from '@utils/Slite/insertText';
import { Editor } from 'slate-react';
import { wrapText } from '@utils/Slite/wrapText';
import { currentAttributesAllowed } from './helpers/currentAttributesAllowed';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { getAttributeExamples } from '../../../../form/utils';

export const MAX_BODY_TEXT = 1024;

export const MessagesTemplateBody: React.FC = () => {
  const botId = useCurrentBotId()!;
  const { t } = useSafeTranslation();
  const { attributes, refetch: attributesRefetch } = useAttributes(
    botId,
    VariableSuggestType.template,
    Platform.whatsapp,
  );
  const {
    values,
    saveForm,
    touched,
    errors,
    formDisabled,
    setFieldTouched,
    setBodyText,
    setBodyAttributeExamples,
    updateBodyAttributeExamples,
    templateLanguage,
  } = useExtendedFormAction();

  const value = values.content.body.text || '';
  const attributeExamples = values.content.body.attribute_examples || [];

  const isCurrentAttributesAllowed = useMemo(
    () => currentAttributesAllowed(value),
    [value],
  );

  const currentTextLimit = useMemo(
    () => MAX_BODY_TEXT - wrapAllAttributes(value, () => '').trim().length,
    [value],
  );

  return (
    <Flex flexDirection="column">
      <Flex fullWidth>
        <TextOutsideControls
          onInsertRequest={(text, el) => {
            insertText(text, el as Editor);
          }}
          currentTextLimit={currentTextLimit}
          shouldShowOutsideControls={{
            emoji: true,
            symbolsLimit: true,
            attributes: isCurrentAttributesAllowed,
          }}
          initialShow={false}
          boxStyle={{ width: '100%' }}
          tight
        >
          {({ ref, getInputProps }) => (
            <Input
              disabled={formDisabled}
              error={touched.content?.body?.text && errors.content?.body?.text}
              render={() => (
                <TextFormatControls
                  onWrapRequest={(wrapEl, el) => {
                    wrapText(wrapEl, el as Editor);
                  }}
                  buttonsBoxClassName={css.buttonsBox}
                >
                  {({ ref: formatRef }) => (
                    <TextWithAttributesEditor
                      placeholder={
                        templateLanguage &&
                        t('pages.MessagesTemplates.message.body.placeholder', {
                          lang: templateLanguage?.display_name,
                        })
                      }
                      className={css.input}
                      containerClassName={css.inputBox}
                      defaultValue={deserialize(value || '')}
                      attributes={attributes}
                      maxLength={MAX_BODY_TEXT}
                      editorRef={combineRefs([ref, formatRef])}
                      hasManageAttributes
                      onManageAttributesClick={({ modal }) => {
                        modal?.onClose(() => {
                          // fix crash editor on blur
                          ref.current.normalize();
                          setTimeout(() => {
                            ref.current.focus();
                            attributesRefetch();
                          });
                        });
                      }}
                      onFocus={getInputProps().onFocus}
                      onStringChange={setBodyText}
                      onBlur={(event, value) => {
                        const newValues = updateBodyAttributeExamples(
                          getAttributeExamples(value),
                        );
                        newValues.content.body.text = value;
                        saveForm(newValues);
                        (getInputProps().onBlur as Function)(event);
                        setFieldTouched('content.body.text', true);
                      }}
                      data-testid="messages-template-message__body-text-input"
                    />
                  )}
                </TextFormatControls>
              )}
            />
          )}
        </TextOutsideControls>
      </Flex>

      {attributeExamples.length > 0 && (
        <>
          <Spacer factor={3} />
          <AttributeExample
            data-testid="messages-template-message__body-attribute-example"
            disabled={formDisabled}
            errors={errors.content?.body?.attribute_examples}
            attributeExamples={attributeExamples}
            onExampleChange={setBodyAttributeExamples}
          />
        </>
      )}
    </Flex>
  );
};

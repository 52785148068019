import { useState } from 'react';
import { usePrevious } from 'cf-common/src/utils/hooks';

export const INITIAL_GROUP_INDEX = 0;

export const useActiveGroupIndex = () => {
  const [activeGroupIndex, setActiveGroupIndex] =
    useState<number>(INITIAL_GROUP_INDEX);

  const prevGroupIndex = usePrevious(activeGroupIndex, INITIAL_GROUP_INDEX);

  return {
    activeGroupIndex,
    setActiveGroupIndex,
    activeGroupChanged: prevGroupIndex !== activeGroupIndex,
  };
};

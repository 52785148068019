import React from 'react';
import { BotTabCentered } from '@components/BotTab';
import { BroadcastsTable } from './components/BroadcastTable/BroadcastTable';
import { WhatsappNotConnected } from './WhatsappNotConnected';
import css from './WhatsappReEngageTabView.css';

export const WhatsappReEngageTabView = () => (
  <BotTabCentered
    className={css.container}
    containerClassName={css.botTabContainer}
  >
    <WhatsappNotConnected>
      <BroadcastsTable />
    </WhatsappNotConnected>
  </BotTabCentered>
);

import React, { useCallback, useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useHistory } from 'react-router-dom';
import { sendEvent } from '@utils/Analytics';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Modal } from '@services/index';
import { TemplatesModal } from '../FlowList/TemplatesModal';
import { FlowElementType } from '../FlowList';
import * as css from './TabPlaceholders.css';
import { ReactComponent as Rocket } from './images/new_rocket.svg';
import { useFlowGroups } from '@utils/Data/Flow/Groups/useFlowGroups';
import { getFirstFlowGroupId } from '@utils/Data/Flow/GroupingFlows/utils';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import {
  AddFlowItemType,
  getAddPlatformFlowMenuItemsAsArray,
} from '../FlowList/constants';
import { MenuItem } from '@ui/Menu';
import { Icon } from '@ui/Icon';

export const TabPlaceholderWithTemplates: React.FC = () => {
  const { t } = useSafeTranslation();
  const history = useHistory();
  const { isWhatsappEnabled } = useWhatsappEnabled();
  const { data: flowGroupsData } = useFlowGroups();
  const firstFlowGroupId = getFirstFlowGroupId(flowGroupsData?.bot)!;
  const [isFlowCreating, setIsFlowCreating] = useState<boolean>(false);
  const items = getAddPlatformFlowMenuItemsAsArray(t, isWhatsappEnabled);

  const onChange = useCallback(
    (item) => {
      if (!item) {
        return;
      }
      const { id: platform } = item;
      sendEvent({
        category: 'new flow screen',
        action: 'create new flow',
        propertyBag: {
          platform,
        },
      });
      history.push({
        search: `?createdElementType=${FlowElementType.flow}&platform=${platform}`,
      });
      setIsFlowCreating(true);
    },
    [history],
  );

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      className={css.templatesContainer}
    >
      <Rocket />
      <Spacer factor={4} />
      <Type size="24px" weight="semibold">
        {t('modernComponents.TabPlaceholders.templates.title')}
      </Type>
      <Spacer factor={4} />
      <div style={{ width: 370 }}>
        <Type size="15px" align="center" as="div">
          {t('modernComponents.TabPlaceholders.templates.text1')}
        </Type>
        <Spacer factor={2} />
        <Type size="15px" align="center" as="div">
          {t('modernComponents.TabPlaceholders.templates.text2')}
        </Type>
      </div>
      <Spacer factor={6} />
      <Flex justifyContent="center">
        <SimpleCombobox<AddFlowItemType>
          items={items}
          onChange={onChange}
          renderInput={({ getToggleButtonProps }) => (
            <Button
              intent="secondary"
              disabled={isFlowCreating}
              icon={<Icon icon="plus" />}
              {...(items.length === 1
                ? {
                    onClick: () => onChange(items[0]),
                  }
                : {
                    ...getToggleButtonProps(),
                  })}
            >
              {t('modernComponents.TabPlaceholders.templates.createBlankFlow')}
            </Button>
          )}
          renderItem={({ getItemProps, item, index, highlightedIndex }) => (
            <MenuItem
              key={`${item.id}_${index}`}
              type={item.type}
              style={{
                height: 36,
              }}
              {...getItemProps({ item })}
              active={index === highlightedIndex}
              title={item.title}
              leftElement={
                item.icon && (
                  <Icon
                    icon={item.icon.icon}
                    size="16px"
                    className={css.platformIcon}
                  />
                )
              }
            />
          )}
        />

        {!isWhatsappEnabled && (
          <>
            <Spacer horizontalFactor={4} />
            <Button
              intent="primary"
              onClick={() => {
                sendEvent({
                  category: 'new flow screen',
                  action: 'browse all templates',
                });
                Modal.show(
                  ({ close }) => (
                    <TemplatesModal
                      onClose={close}
                      onCreated={close}
                      groupId={firstFlowGroupId}
                      onCreateBlankFlow={(platform) => {
                        close();
                        history.push({
                          search: `?createdElementType=${FlowElementType.flow}&platform=${platform}`,
                        });
                      }}
                    />
                  ),
                  { mobileAdaptive: true },
                );
              }}
            >
              {t(
                'modernComponents.TabPlaceholders.templates.browseAllTemplates',
              )}
            </Button>
          </>
        )}
      </Flex>
    </Flex>
  );
};

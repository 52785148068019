import React from 'react';
import { Flex } from '@ui/Flex';
import {
  ButtonTypeDropdown,
  ButtonTypeDropdownProps,
} from './ButtonTypeDropdown';
import * as css from './ButtonView.css';

export interface ButtonViewProps extends ButtonTypeDropdownProps, TestLocator {
  actionButtons: React.ReactNode;
}

export const ButtonView: React.FC<ButtonViewProps> = ({
  disabled,
  children,
  dropdownItems,
  onDropdownChange,
  selectedItem,
  actionButtons,
  'data-testid': dataTestId,
}) => {
  return (
    <Flex
      gapFactor={3}
      flexDirection="column"
      className={css.wrapper}
      data-testid={dataTestId}
    >
      <Flex justifyContent="space-between">
        <ButtonTypeDropdown
          disabled={disabled}
          selectedItem={selectedItem}
          dropdownItems={dropdownItems}
          onDropdownChange={onDropdownChange}
        />

        <Flex className={css.buttons}>{actionButtons}</Flex>
      </Flex>

      {children}
    </Flex>
  );
};

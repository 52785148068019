import { useCallback, useState } from 'react';
import { WhatsappBuyPhoneDialogStep } from '../types';
import { useNotifyBotSubscription } from './useNotifyBotSubscription';

export const useWhatsappBuyPhoneDialogStep = () => {
  const [step, setStepState] = useState<WhatsappBuyPhoneDialogStep>(
    WhatsappBuyPhoneDialogStep.pickOption,
  );

  const { isBotSubscribed, loading } = useNotifyBotSubscription();

  const setStep = useCallback(
    (step: WhatsappBuyPhoneDialogStep) => {
      const needSkipRegister =
        step === WhatsappBuyPhoneDialogStep.registerToReceiveMessages &&
        isBotSubscribed;
      setStepState(
        needSkipRegister ? WhatsappBuyPhoneDialogStep.chooseCountryCode : step,
      );
    },
    [isBotSubscribed],
  );

  return {
    step,
    setStep,
    loading,
  };
};

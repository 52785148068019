import { AiIntentActionItemType } from '@globals';
import { TeachVariantsIds } from './ResponseTypeSelector';

export const AI_INTENT_ACTION_ITEM_TYPE_TO_RESPONSE_TYPE: Record<
  AiIntentActionItemType,
  TeachVariantsIds
> = {
  [AiIntentActionItemType.block]: TeachVariantsIds.block_or_flow,
  [AiIntentActionItemType.text]: TeachVariantsIds.text,
};

export const RESPONSE_TYPE_TO_AI_INTENT_ACTION_ITEM_TYPE: Record<
  TeachVariantsIds,
  AiIntentActionItemType | undefined
> = {
  [TeachVariantsIds.text]: AiIntentActionItemType.text,
  [TeachVariantsIds.block_or_flow]: AiIntentActionItemType.block,
  [TeachVariantsIds.ai_intent]: undefined,
};

import i18next from 'i18next';
import memoize from 'lodash-es/memoize';
import { TemplateButtonType } from '@globals';
import { ButtonSectionType } from '../../form/types';
import {
  DEFAULT_CUSTOM_BUTTON,
  DEFAULT_OPT_OUT_BUTTON,
  DEFAULT_PHONE_BUTTON,
  DEFAULT_URL_BUTTON,
} from '../../form/consts';

export const getCallToActionButtons = memoize(() => [
  {
    id: TemplateButtonType.url,
    title: i18next.t('pages.MessagesTemplates.message.url'),
  },

  {
    id: TemplateButtonType.phone,
    title: i18next.t('pages.MessagesTemplates.message.phone'),
  },
]);

export const getQuickReplyButtons = memoize(() => [
  {
    id: TemplateButtonType.quick_reply,
    title: i18next.t('pages.MessagesTemplates.message.custom'),
  },
  {
    id: TemplateButtonType.marketing_opt_out,
    title: i18next.t('pages.MessagesTemplates.message.optOut'),
    comment: i18next.t('pages.MessagesTemplates.message.optOutComment'),
  },
]);

export const getDropdownButtons = memoize(() => [
  ...getQuickReplyButtons(),
  ...getCallToActionButtons(),
]);

export const BUTTON_TYPE_TO_SECTION_TYPE = {
  [TemplateButtonType.quick_reply]: ButtonSectionType.quickReply,
  [TemplateButtonType.marketing_opt_out]: ButtonSectionType.quickReply,
  [TemplateButtonType.url]: ButtonSectionType.callToAction,
  [TemplateButtonType.phone]: ButtonSectionType.callToAction,
};

export const DEFAULT_CONFIG_BY_TYPE = {
  [TemplateButtonType.quick_reply]: DEFAULT_CUSTOM_BUTTON,
  [TemplateButtonType.marketing_opt_out]: DEFAULT_OPT_OUT_BUTTON,
  [TemplateButtonType.url]: DEFAULT_URL_BUTTON,
  [TemplateButtonType.phone]: DEFAULT_PHONE_BUTTON,
};

export const URL_BUTTONS_MAX_COUNT = 2;
export const CALL_BUTTONS_MAX_COUNT = 1;
export const OPT_OUT_BUTTONS_MAX_COUNT = 1;
export const ALL_BUTTONS_MAX_COUNT = 10;

export enum DndType {
  section = 'section',
  button = 'button',
}

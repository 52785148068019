import React from 'react';
import { Title } from './Primitives';

export interface SimplePaymentViewProps extends TestLocator {
  price: string;
}

export const SimplePaymentView: React.FC<SimplePaymentViewProps> = ({
  price,
  ...props
}) => <Title data-testid={props['data-testid']}>{price}</Title>;

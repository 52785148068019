import React from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { Type } from '@ui/Type';
import { getTierNameByType } from '@components/DialogsPricing/constants';

export interface PlanBlockHeaderProps {
  currentTier: string;
  nextTier: Maybe<string>;
}

export const PlanBlockHeader: React.FC<PlanBlockHeaderProps> = ({
  currentTier,
  nextTier,
}) => {
  return (
    <Type size="24px" weight="semibold" data-testid="plan_block_header">
      {getTierNameByType(currentTier)}
      {nextTier && currentTier !== nextTier && (
        <Type size="18px" weight="semibold" color="greyDark">
          {` → ${getTierNameByType(nextTier)}`}
        </Type>
      )}
    </Type>
  );
};

import { DEFAULT_PLUGINS as DEFAULT_FACEBOOK_ADS_PLUGINS } from '@components/FlowBuilder/views/entry-points/EntryPointFacebookAds/utils/defaultPlugins';
import { DEFAULT_PLUGINS as DEFAULT_INSTAGRAM_ADS_PLUGINS } from '@components/FlowBuilder/views/entry-points/EntryPointInstagramAds/utils/defaultPlugins';
import { getAdditionalPropertyBagFieldByEntryPointId } from '@components/FlowBuilder/utils/Analytics';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { Icon } from '@ui/Icon';
import { Badge, Kind } from '@ui/Badge';
import { MenuItem } from '@ui/Menu';
import { sendEvent } from '@utils/Analytics';
import { ENTRY_POINT_SUBTYPE } from '@utils/Data/Flow';
import React, { ReactNode, useMemo } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Platform } from '@globals';
import { BOT_CONTENT_ELEMENT_ID } from '../../../consts';
import { AddFlowItemType, getAddFlowItems } from '../constants';
import {
  FlowAddParams,
  FlowElementType,
  FlowWithTemplateAddParams,
} from '../types';
import { useAddMenuItemsByAdminFeatures } from '../utils';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { createFlowForMe } from '@utils/Intercom/createFlowForMe';

interface AddElementMenuPropsChildrenParams {
  getToggleButtonProps(): any;
  ref: React.MutableRefObject<HTMLButtonElement>;
}

interface AddElementMenuProps {
  botId?: string;
  groupId?: string;
  items?: AddFlowItemType[];
  onAddGroup?(): void;
  handleBlankFlowAdd(params: FlowAddParams): void;
  handleFlowAddWithTemplate(params: FlowWithTemplateAddParams): void;
  renderInput(params: AddElementMenuPropsChildrenParams): ReactNode;
}

export const AddElementMenu: React.FC<AddElementMenuProps> = ({
  botId,
  groupId,
  onAddGroup,
  handleBlankFlowAdd,
  handleFlowAddWithTemplate,
  items,
  renderInput,
}) => {
  const { t } = useSafeTranslation();
  const { isWhatsappEnabled } = useWhatsappEnabled();
  const currentAddFlowItems = useAddMenuItemsByAdminFeatures(
    items ?? getAddFlowItems(t, isWhatsappEnabled),
  );
  const boundariesElement = useMemo(
    () => document.getElementById(BOT_CONTENT_ELEMENT_ID) || undefined,
    [],
  );

  return (
    <SimpleCombobox<AddFlowItemType>
      scrollboxStyle={{ maxHeight: '500px' }}
      position="bottom-end"
      ignoreParentsOverflow
      boundariesElement={boundariesElement}
      unsaveSelectedOption
      renderItem={({ getItemProps, item, index, highlightedIndex }) => (
        <MenuItem
          key={`${item.id}_${index}`}
          type={item.type}
          style={item.style}
          {...getItemProps({ item })}
          active={index === highlightedIndex}
          title={item.title}
          leftElement={
            item.icon && <Icon {...item.icon} className={item.icon.className} />
          }
          rightElement={
            item.badgeName && (
              <Badge kind={Kind.primary}>{item.badgeName}</Badge>
            )
          }
        />
      )}
      renderInput={renderInput}
      onChange={(item) => {
        switch (item?.id) {
          case FlowElementType.group:
            onAddGroup?.();
            break;
          case FlowElementType.flow:
            handleBlankFlowAdd({
              groupId,
              onAdded: () => {
                sendEvent({
                  category: 'flow navigation',
                  action: 'add flow',
                  propertyBag: {
                    botId,
                    groupId,
                  },
                });
              },
            });
            break;
          case Platform.instagram:
          case Platform.facebook:
          case Platform.whatsapp:
            handleBlankFlowAdd({
              groupId,
              platform: item.id,
              onAdded: () => {
                sendEvent({
                  category: 'flow navigation',
                  action: 'add flow',
                  propertyBag: {
                    botId,
                    groupId,
                  },
                });
              },
            });
            break;
          case FlowElementType.template:
            handleFlowAddWithTemplate({
              groupId,
              onAdded: () => {
                sendEvent({
                  category: 'flow navigation',
                  action: 'add flow',
                  propertyBag: {
                    botId,
                    groupId,
                  },
                });
              },
            });
            break;
          case FlowElementType.createFlowForMe:
            createFlowForMe();
            break;
          case PluginType.customer_chat_entry_point:
          case PluginType.ref_link_entry_point:
          case PluginType.persistent_menu_entry_point:
          case PluginType.instagram_persistent_menu_entry_point:
          case PluginType.send_to_messenger_entry_point:
          case PluginType.fb_page_entry_point:
          case PluginType.comments_autoreply_entry_point:
          case PluginType.facebook_shops_entry_point:
          case PluginType.external_integration_entry_point:
          case PluginType.popup_entry_point:
          case PluginType.shopify_event_entry_point:
          case PluginType.order_confirmation_shortcut:
          case PluginType.order_status_update_shortcut:
          case PluginType.product_visit_shortcut:
          case PluginType.shopify_customer_chat_entry_point:
          case PluginType.shopify_back_in_stock:
            handleBlankFlowAdd({
              groupId,
              block: {
                subtype: ENTRY_POINT_SUBTYPE,
                pluginIds: [item.id],
              },
              onAdded: () => {
                sendEvent({
                  category: 'flow navigation',
                  action: 'add flow with entry point',
                  propertyBag: {
                    botId,
                    entryPointType: item.id,
                    ...getAdditionalPropertyBagFieldByEntryPointId(item.id),
                  },
                });
              },
            });
            break;

          case PluginType.ads_manager_sm_entry_point:
            handleBlankFlowAdd({
              groupId,
              block: {
                subtype: ENTRY_POINT_SUBTYPE,
                pluginIds: [
                  PluginType.ads_manager_sm_entry_point,
                  ...DEFAULT_FACEBOOK_ADS_PLUGINS,
                ],
              },
              onAdded: () => {
                sendEvent({
                  category: 'flow navigation',
                  action: 'add flow with entry point',
                  propertyBag: {
                    botId,
                    entryPointType: item.id,
                  },
                });
              },
            });
            break;

          case PluginType.ads_manager_ctm_entry_point:
            handleBlankFlowAdd({
              groupId,
              block: {
                subtype: ENTRY_POINT_SUBTYPE,
                pluginIds: [
                  PluginType.ads_manager_ctm_entry_point,
                  ...DEFAULT_FACEBOOK_ADS_PLUGINS,
                ],
              },
              onAdded: () => {
                sendEvent({
                  category: 'flow navigation',
                  action: 'add flow with entry point',
                  propertyBag: {
                    botId,
                    entryPointType: item.id,
                  },
                });
              },
            });
            break;
          case PluginType.instagram_ads_manager_ctm_entry_point:
            handleBlankFlowAdd({
              groupId,
              platform: Platform.instagram,
              block: {
                subtype: ENTRY_POINT_SUBTYPE,
                pluginIds: [
                  PluginType.instagram_ads_manager_ctm_entry_point,
                  ...DEFAULT_INSTAGRAM_ADS_PLUGINS,
                ],
              },
              onAdded: () => {
                sendEvent({
                  category: 'flow navigation',
                  action: 'add flow with entry point',
                  propertyBag: {
                    botId,
                    entryPointType: item.id,
                  },
                });
              },
            });
            break;
          case PluginType.instagram_direct_entry_point:
          case PluginType.instagram_comments_autoreply_entry_point:
          case PluginType.instagram_story_mention_entry_point:
          case PluginType.instagram_story_reply_entry_point:
            handleBlankFlowAdd({
              groupId,
              platform: Platform.instagram,
              block: {
                subtype: ENTRY_POINT_SUBTYPE,
                pluginIds: [item.id],
              },
              onAdded: () => {
                sendEvent({
                  category: 'flow navigation',
                  action: 'add flow with entry point',
                  propertyBag: {
                    botId,
                    entryPointType: item.id,
                  },
                });
              },
            });
            break;
          default:
            break;
        }
      }}
      items={currentAddFlowItems}
    />
  );
};

import React, { useCallback } from 'react';
import { sendEvent } from '@utils/Analytics';
import { useWorkspacesAvailable } from '@utils/Data/Workspaces/useWorkspacesAvailable';
import { getDefaultTab } from '@utils/Routing';
import { useHistory } from 'react-router-dom';
import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';
import { ExecutionResult } from 'react-apollo';
import { PlatformConnectionLoading } from '@pages/GrowPage/PlatformConnection';
import { BotCreationResult } from './types';
import { WorkspaceWhatsappBotNotCreated } from './WorkspaceWhatsappBotNotCreated';
import { NonWorkspaceWhatsappBotNotCreated } from './NonWorkspaceWhatsappBotNotCreated';

const sendWhatsappEvent = (action: string) =>
  sendEvent({
    action,
    category: 'whatsapp',
    label: 'whatsapp not connected widget',
  });

export const WhatsappBotNotCreated: React.FC = () => {
  const [isWorkspaceAvailable, isWorkspaceAvailableLoading] =
    useWorkspacesAvailable();
  const history = useHistory();

  const [widgetHidden, setWidgetHidden, { loading: serverStorageLoading }] =
    useServerStorage<boolean>(
      ServerStorageItemKeys.createWhatsappBotWidgetHidden,
    );

  const onClose = useCallback(() => {
    sendWhatsappEvent('close whatsapp widget');
    setWidgetHidden(true);
  }, [setWidgetHidden]);

  const onClick = useCallback(
    (botCreation: PromiseLike<ExecutionResult<BotCreationResult>>) => {
      sendWhatsappEvent('create new bot click');

      botCreation.then((result) => {
        history.push(getDefaultTab(result.data!.createBlankBot.id));
        setWidgetHidden(true);
      });
    },
    [history, setWidgetHidden],
  );

  if (widgetHidden || serverStorageLoading) {
    return null;
  }

  if (isWorkspaceAvailableLoading) {
    return <PlatformConnectionLoading />;
  }

  const View = isWorkspaceAvailable
    ? WorkspaceWhatsappBotNotCreated
    : NonWorkspaceWhatsappBotNotCreated;

  return <View onClick={onClick} onClose={onClose} />;
};

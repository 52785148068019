import { ReactComponent as DotsIcon } from '@ui/_deprecated/Icon/icons/More_small.svg';
import { Autofocus } from '@ui/Autofocus';
import { Button } from '@ui/Button';
import { DefaultDialog } from '@ui/Dialog/DefaultDialog';
import { Flex } from '@ui/Flex';
import { HoverDisclosure } from '@ui/HoverDisclosure';
import { Icon } from '@ui/Icon';
import { Loader } from '@ui/Loader';
import { Modal } from '@ui/Modal';
import { Spacer } from '@ui/Spacer';
import { useToaster } from '@ui/Toaster/ToasterProvider';
import { Tooltip2 } from '@ui/Tooltip2';
import { Type } from '@ui/Type';
import {
  SearchType,
  useFilterableSearch,
} from '@components/FilterableSearchField';
import { sendEvent } from '@utils/Analytics';
import { combineRefs } from '@utils/combineRefs';
import { useFlow } from '@utils/Data/Flow/useFlow';
import { FlowPlaceholder } from '@utils/Data/Flow/types';
import { isEllipsisDetect } from '@utils/DOM/isEllipsisDetect';
import { CollapsibleSection } from '@ui/CollapsibleSection';
import { FlowGroupsQuery_bot_flow_groups } from '@utils/Data/Flow/@types/FlowGroupsQuery';
import { useAiDefaultAnswer } from '@utils/Data/Ai/PredefinedIntents';
import { useDnd } from '@utils/Dnd';
import { BotTabs, useCurrentBotId, useFlowTabParams } from '@utils/Routing';
import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { NavLink, useHistory } from 'react-router-dom';
import { GlobalAttributeDialog } from '@components/GlobalAttributesDialog';
import { PluginType } from '@components/Plugins/common/PluginTypes';
import { isShopifyPlugin } from '@components/FlowBuilder/views/components/ShopifyAccount/utils/isShopifyPlugin';
import { scrollIntoViewIfNeeded } from 'cf-common/src/utils/DOM/scrollIntoViewIfNeeded';
import { Platform } from '@globals';
import {
  flowActionsEmitter,
  FlowActionsEventPayload,
  FlowActionsEvents,
  FlowActionsMenu,
  FlowItemMenuType,
  isFlowItemMenuType,
} from '../../common/Flow';
import { EditFlowItem } from '../EditFlowItem';
import { EntryPointItem } from '../EntryPointItem';
import { FlowsFilter, EntryPointStatus } from '../FilterableSearch';
import {
  matchFlowOrFlowGroupTitleWithFilterValue,
  matchStringWithFilterValue,
} from '../FilterableSearch/helpers';
import { FlowItemPlaceholder } from '../FlowItemPlaceholder';
import { getLeftShift } from '../utils';
import { useFlowClone, useFlowRemove, useFlowUpdate } from './FlowsMutations';
import { CloneFlowItemModal } from './CloneFlowItemModal';
import { ShareItemModal } from './ShareItemModal';
import * as css from './FlowItem.css';

interface FlowItemProps {
  flowId: string;
  nestedLevel: number;
  parentGroup?: FlowGroupsQuery_bot_flow_groups;
  refetchFlows: () => void;
  onEntryPointAdd(flowId: string, platform?: Platform | null): void;
  dndShouldBeDisabled?: boolean;
  readOnly: boolean;
}

type ToasterParams = {
  botId?: string;
  flow?: {
    id?: string;
    title?: string;
  };
  group?: {
    id?: string;
    title?: string;
  };
};

const getPlatformIconName = (platform: Platform) => {
  return {
    [Platform.instagram]: 'directLogo',
    [Platform.facebook]: 'messengerLogo',
    [Platform.whatsapp]: 'whatsappLogo',
  }[platform];
};

export const FlowItem: React.FC<FlowItemProps> = ({
  flowId,
  nestedLevel,
  parentGroup,
  refetchFlows,
  onEntryPointAdd,
  dndShouldBeDisabled,
  readOnly,
}) => {
  const { t } = useSafeTranslation();
  const { dndState, setDisabledDnd } = useDnd();
  const botId = useCurrentBotId();
  const { addToaster } = useToaster();
  const flowTabParams = useFlowTabParams();
  const history = useHistory();
  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState<FlowItemMenuType>(
    FlowItemMenuType.none,
  );
  const [loading, setLoading] = useState(false);
  const onModalDismiss = useCallback(() => {
    setShowModal(FlowItemMenuType.none);
  }, []);
  const [toasterParams, setToasterParams] = useState<ToasterParams>({});
  const [innerTitle, setInnerTitle] = useState('');
  const titleRef = useRef<HTMLSpanElement | null>(null);
  const { aiDefaultAnswerIntentBlockId } = useAiDefaultAnswer(botId);
  const boxRef = useRef<HTMLDivElement>(null);

  const { type: flowsFilterType, value: flowsFilterValue } =
    useFilterableSearch<FlowsFilter>();

  const { flow, loading: flowLoading } = useFlow({ flowId });

  const { updateFlow } = useFlowUpdate({
    flow,
    onCompleted: (data) => {
      sendEvent({
        category: 'flow navigation',
        action: 'rename',
        label: 'flow',
        propertyBag: {
          botId,
          flowId,
          title: data?.updateFlow.title,
        },
      });
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
      setInnerTitle(flow?.title || t('FlowItem-string-6723-error'));
      addToaster({
        type: 'error',
        content: (
          <div style={{ minWidth: '300px', whiteSpace: 'nowrap' }}>
            {t('FlowItem-JSXText-8624-changing-flow')}
            {flow?.title}
            {t('FlowItem-JSXText--107-failed-please-try-again-later')}
          </div>
        ),
        timeout: 3000,
        closeButton: true,
      });
    },
  });

  const { removeFlow } = useFlowRemove({
    flowId,
    onCompleted: () => {
      refetchFlows();
      addToaster({
        type: 'info',
        content: (
          <div style={{ minWidth: '300px', whiteSpace: 'nowrap' }}>
            {t('FlowItem-JSXText-1269-flow')}
            {flow?.title}
            {t('FlowItem-JSXText-5019-was-successfully-removed')}
          </div>
        ),
        timeout: 3000,
        closeButton: true,
      });
      if (flowTabParams?.flowId === flowId) {
        history.push(
          `/bot/${botId}/${BotTabs.flows}/${FlowPlaceholder.removed}`,
        );
      }
    },
  });

  const { cloneFlow, loading: cloneFlowLoading } = useFlowClone({
    onCompleted: (data) => {
      setLoading(false);
      setEditMode(false);
      setShowModal(FlowItemMenuType.none);
      addToaster({
        type: 'info',
        content: (
          <div style={{ minWidth: '300px' }}>
            {t('FlowItem-JSXText-1269-flow')}
            {toasterParams.flow?.title}
            {t('FlowItem-JSXText-9244-was-successfully-cloned')}
          </div>
        ),
        buttonText: t('FlowItem-string-2666-view'),
        onButtonClick: () => {
          history.push(
            `/bot/${toasterParams.botId}/flows/${data?.cloneFlow.id}`,
          );
        },
        timeout: 5000,
        closeButton: true,
      });
      refetchFlows();
    },
  });

  const isActiveFlow = flowId === flowTabParams?.flowId;

  const handleFlowAction = (actionId: string) => {
    if (!isFlowItemMenuType(actionId)) {
      return;
    }
    if (actionId === FlowItemMenuType.rename) {
      setEditMode(true);
    } else {
      setShowModal(actionId);
    }

    if (actionId === FlowItemMenuType.share) {
      sendEvent({
        category: 'share flow',
        action: 'open modal',
        propertyBag: {
          botId,
          flowId,
        },
      });
    }
  };

  const platform = flow?.platform;

  useEffect(
    () =>
      flowActionsEmitter.on<FlowActionsEventPayload>(
        FlowActionsEvents.showModal,
        (payload) => {
          if (flowId === payload.data.flowId) {
            handleFlowAction(payload.data.actionId);
          }
        },
      ),
    [flowId], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    setInnerTitle(flow?.title || '');
  }, [flow]);

  useEffect(() => {
    if (dndShouldBeDisabled) {
      setDisabledDnd(true);
      return;
    }

    setDisabledDnd?.(editMode);
  }, [dndShouldBeDisabled, editMode, setDisabledDnd]);

  useEffect(() => {
    if (boxRef.current && isActiveFlow) {
      scrollIntoViewIfNeeded(boxRef.current, true);
    }
  }, [isActiveFlow]);

  const flowGroupOrFlowTitleMatchedFilterValue =
    flow &&
    flowsFilterValue &&
    parentGroup &&
    matchFlowOrFlowGroupTitleWithFilterValue(
      [parentGroup],
      flow,
      flowsFilterValue,
    );

  const itemStyle = { paddingLeft: getLeftShift(nestedLevel) };

  return (
    <>
      <CollapsibleSection
        defaultExtended={!flow?.collapsed}
        unmountHiddenSection={false}
        anchor={({ changeExtended, extended }) => {
          const changeAndSaveExtended = () => {
            changeExtended();
            updateFlow({ collapsed: !!extended });
          };

          return (
            <HoverDisclosure
              render={({ bind, isVisible }) => {
                return (
                  <div
                    {...bind}
                    id={flowId}
                    className={cn(css.item, css.sectionTitle, {
                      [css.active]: flowTabParams?.flowId === flowId,
                      [css.focused]: isVisible,
                    })}
                    style={{
                      opacity: dndState?.draggingId === flowId ? 0.5 : 1,
                      pointerEvents: dndState?.isDragging ? 'none' : 'auto',
                    }}
                    ref={combineRefs<HTMLDivElement>([bind.ref, boxRef])}
                  >
                    {editMode ? (
                      <EditFlowItem
                        onSubmit={(itemTitle) => {
                          const nextTitle =
                            itemTitle ?? t('FlowItem-string-1318-new-flow');
                          setLoading(true);
                          setInnerTitle(nextTitle);
                          setEditMode(false);
                          updateFlow({
                            title: nextTitle,
                            groupId: parentGroup?.id,
                            position: parentGroup?.flow_ids?.indexOf(flowId),
                          });
                        }}
                        defaultValue={flow?.title}
                        onDismiss={() => setEditMode(false)}
                        disabled={loading}
                        style={itemStyle}
                      />
                    ) : (
                      <>
                        <NavLink
                          to={`/bot/${botId}/${BotTabs.flows}/${flowId}`}
                          className={css.title}
                          activeClassName={css.activeFlowItem}
                          onClick={() => {
                            // eslint-disable-next-line @typescript-eslint/unbound-method
                            (document.activeElement as HTMLElement).blur?.();
                            if (isActiveFlow) {
                              changeAndSaveExtended();
                            }
                          }}
                          title={
                            isEllipsisDetect(titleRef.current)
                              ? innerTitle
                              : undefined
                          }
                          style={itemStyle}
                        >
                          <Flex
                            alignItems="center"
                            className={css.flowItemTitleContainer}
                          >
                            <>
                              <Spacer horizontalFactor={1} />
                              <Icon
                                icon={getPlatformIconName(flow!.platform!)}
                                size="12px"
                                data-testid={`flow-item__${platform}-icon`}
                              />
                              <Spacer horizontalFactor={2} />
                            </>
                            <Type<HTMLSpanElement>
                              as="p"
                              innerRef={titleRef}
                              noWrap
                              size="15px_DEPRECATED"
                            >
                              {innerTitle}
                            </Type>
                          </Flex>
                          <Spacer horizontalFactor={2} />
                          {flow?.sharing_params?.sharing_enabled && (
                            <Icon size="16px" icon="shared" />
                          )}
                        </NavLink>
                        {(loading || flowLoading) && (
                          <>
                            <Spacer horizontalFactor={2} />
                            <Loader size="s" />
                          </>
                        )}
                        {!readOnly &&
                          isVisible &&
                          !dndState?.isDragging &&
                          !loading && (
                            <Flex
                              className={css.buttonsPart}
                              alignItems="center"
                            >
                              <Spacer horizontalFactor={2} />
                              <FlowActionsMenu
                                items={[
                                  {
                                    id: FlowItemMenuType.rename,
                                    title: t(
                                      'modernComponents.FlowBuilder.menuActions.rename',
                                    ),
                                  },
                                  {
                                    id: FlowItemMenuType.copy,
                                    title: t(
                                      'modernComponents.FlowBuilder.menuActions.copy',
                                    ),
                                  },
                                  {
                                    id: FlowItemMenuType.share,
                                    title: t(
                                      'modernComponents.FlowBuilder.menuActions.share',
                                    ),
                                  },
                                  {
                                    id: FlowItemMenuType.manageAttributes,
                                    title: t(
                                      'modernComponents.FlowBuilder.menuActions.manageAttributes',
                                    ),
                                  },
                                  {
                                    id: FlowItemMenuType.delete,
                                    title: t(
                                      'modernComponents.FlowBuilder.menuActions.delete',
                                    ),
                                  },
                                ]}
                                onSelect={handleFlowAction}
                              >
                                {({ getToggleButtonProps, ref }) => (
                                  <Tooltip2
                                    placement="top"
                                    boundariesElement="viewport"
                                    content={
                                      <Type as="p" size="12px" color="white">
                                        {t(
                                          'FlowItem-JSXText--200-section-options',
                                        )}
                                      </Type>
                                    }
                                    type="small"
                                    positionFixed
                                  >
                                    {(tooltipRef, bind) => (
                                      <Button
                                        {...bind}
                                        {...getToggleButtonProps()}
                                        ref={combineRefs([ref, tooltipRef])}
                                        className={css.controlButton}
                                        icon={<DotsIcon />}
                                        data-testid="flow-list__flow-item__menu-button"
                                        intent="text"
                                        size="xs"
                                      />
                                    )}
                                  </Tooltip2>
                                )}
                              </FlowActionsMenu>
                            </Flex>
                          )}
                      </>
                    )}
                  </div>
                );
              }}
            />
          );
        }}
      >
        {({ bind }) => (
          <div {...bind}>
            {flow?.entry_points?.map((entryPoint) => {
              if (flowsFilterType === FlowsFilter.entryPointStatus) {
                if (
                  !entryPoint.enabled &&
                  flowsFilterValue === EntryPointStatus.ACTIVE
                ) {
                  return null;
                }

                if (
                  entryPoint.enabled &&
                  flowsFilterValue === EntryPointStatus.INACTIVE
                ) {
                  return null;
                }
              } else if (
                flowsFilterType === SearchType.search &&
                flowsFilterValue &&
                !flowGroupOrFlowTitleMatchedFilterValue &&
                !matchStringWithFilterValue(entryPoint.title, flowsFilterValue)
              ) {
                return null;
              }

              if (isShopifyPlugin(entryPoint.entry_point_type as PluginType)) {
                return null;
              }

              return (
                <EntryPointItem
                  key={entryPoint.id}
                  flowId={flowId}
                  nestedLevel={nestedLevel + 1}
                  entryPoint={entryPoint}
                />
              );
            })}
            {!flow?.entry_points?.length && isActiveFlow && (
              <FlowItemPlaceholder
                title={t('FlowItem-string--213-add-entry-point')}
                onClick={() => onEntryPointAdd(flowId, platform)}
                loading={false}
                nestedLevel={nestedLevel + 1}
                icon={
                  <>
                    <Icon icon="plus" color="greyDark" size="20px" />
                    <Spacer horizontalFactor={1} />
                  </>
                }
              />
            )}
          </div>
        )}
      </CollapsibleSection>
      {showModal !== FlowItemMenuType.none &&
        showModal !== FlowItemMenuType.manageAttributes && (
          <Modal onDismiss={onModalDismiss}>
            <DefaultDialog
              dialogStyle={{
                width: showModal === FlowItemMenuType.share ? '580px' : 'auto',
              }}
              contentStyle={{ width: '100%' }}
              header={() => {
                switch (showModal) {
                  case FlowItemMenuType.delete:
                    return `${t('FlowItem-Template--107-delete')}${
                      flow?.title
                    }`;
                  case FlowItemMenuType.copy:
                    return `${t('FlowItem-Template-6529-copy')}${flow?.title}`;
                  case FlowItemMenuType.share:
                    return `${t('FlowItem-Template--576-share')}${
                      flow?.title
                    }”`;
                  default:
                    return '';
                }
              }}
              onDismiss={onModalDismiss}
            >
              {showModal === FlowItemMenuType.delete && (
                <Autofocus
                  render={({ bind }) => (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        sendEvent({
                          category: 'flow navigation',
                          action: 'delete',
                          label: 'flow',
                          propertyBag: {
                            botId,
                            flowId,
                          },
                        });
                        setLoading(true);
                        onModalDismiss();
                        removeFlow();
                      }}
                    >
                      <Type size="15px_DEPRECATED">
                        {t('FlowItem-JSXText-1282-you-are-going-to-delete')}
                        {flow?.title}
                        {t(
                          'FlowItem-JSXText-2058-you-will-not-be-able-to-return-this-action',
                        )}
                        <br />
                        {aiDefaultAnswerIntentBlockId === flow?.id && (
                          <Trans
                            i18nKey="pages.BotPage.FlowTab.FlowList.FlowItem.defaultAnswerWarning"
                            t={t}
                          >
                            {t(
                              'FlowItem-JSXText-1697-this-flow-is-used-as-default-answer-in-ai-tab',
                            )}
                            <br />
                            {t(
                              'FlowItem-JSXText-1852-if-you-delete-it-no-default-answer-will-be-sent',
                            )}
                          </Trans>
                        )}
                      </Type>
                      <Spacer factor={9} />
                      <Flex justifyContent="flex-end">
                        <Button intent="secondary" onClick={onModalDismiss}>
                          {t('FlowItem-JSXText-2673-cancel')}
                        </Button>
                        <Spacer horizontalFactor={4} factor={0} />
                        <Button {...bind} intent="red" type="submit">
                          {t('FlowItem-JSXText-1210-delete')}
                        </Button>
                      </Flex>
                    </form>
                  )}
                />
              )}
              {showModal === FlowItemMenuType.copy && (
                <CloneFlowItemModal
                  loading={cloneFlowLoading}
                  platform={flow?.platform || undefined}
                  onSubmit={({
                    botId: targetBotId,
                    groupId: targetGroupId,
                  }) => {
                    sendEvent({
                      category: 'flow navigation',
                      action: showModal as string,
                      label: 'flow',
                      propertyBag: {
                        fromBotId: botId,
                        toBotId: targetBotId,
                        flowId,
                        fromGroupId: parentGroup?.id,
                        toGroupId: targetGroupId,
                      },
                    });
                    setLoading(true);
                    setToasterParams({
                      botId: targetBotId,
                      flow: {
                        id: flowId,
                        title: flow?.title,
                      },
                    });
                    if (showModal === FlowItemMenuType.copy) {
                      cloneFlow(targetGroupId, flowId);
                    }
                  }}
                />
              )}
              {showModal === FlowItemMenuType.share && (
                <ShareItemModal
                  flowId={flowId}
                  sharingParams={flow?.sharing_params}
                  disabled={!!parentGroup?.sharing_params?.sharing_enabled}
                />
              )}
            </DefaultDialog>
          </Modal>
        )}
      {showModal === FlowItemMenuType.manageAttributes && (
        <Modal onDismiss={onModalDismiss}>
          <GlobalAttributeDialog onDismiss={onModalDismiss} flowId={flowId} />
        </Modal>
      )}
    </>
  );
};

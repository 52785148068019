import React from 'react';
import { useHistory } from 'react-router-dom';
import { getEntryPointsModalLink, useCurrentBotId } from '@utils/Routing';
import { getFirstFlowGroupId } from '@utils/Data/Flow/GroupingFlows/utils';
import { useAddFlow, useFlowGroups } from '@utils/Data/Flow';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { Modal } from '@services/.';
import { getTemplates } from '../../../FlowTab/FlowList/TemplatesModal/templates';
import { TemplatesModal } from '../../../FlowTab/FlowList/TemplatesModal';
import { TemplateId } from '../../../FlowTab/FlowList/TemplatesModal/types';
import { Platform } from '@globals';
import { TemplatesPanelView } from './TemplatesPanelView';
import { TemplatesPanelLoaderView } from './TemplatesPanelLoaderView';
import { sendHomeTabEvent } from '../../utils';
import { Spacer } from '@ui/Spacer';

export const TempaltesPanel: React.FC = () => {
  const { data, loading, error } = useFlowGroups();
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  const history = useHistory();
  const { isSmallScreenSize } = useDeviceMedia();
  const { addFlow } = useAddFlow();

  const groupId = getFirstFlowGroupId(data?.bot)!;

  const onCreateBlankFlow = (platform: Platform, close: () => void) => {
    addFlow({
      platform,
      parentGroupId: groupId,
    })
      .then((data) => {
        if (!data) {
          return;
        }

        const { id, platform } = data.addFlow;

        history.push(getEntryPointsModalLink(botId, id, platform));
      })
      .finally(close);
  };

  if (loading) {
    return (
      <>
        <TemplatesPanelLoaderView />
        <Spacer factor={8} />
      </>
    );
  }

  if (error) {
    return null;
  }

  const templates = getTemplates();

  const itemsCount = isSmallScreenSize ? 4 : 5;
  const templatesToShow = templates.slice(0, itemsCount);

  const templatesLeft = templates.length - templatesToShow.length;

  const showModal = (templateId?: TemplateId) => {
    Modal.show(
      ({ close }) => {
        return (
          <TemplatesModal
            templateId={templateId}
            groupId={groupId}
            onCreateBlankFlow={(platform) => onCreateBlankFlow(platform, close)}
            onClose={close}
            onCreated={close}
          />
        );
      },
      { mobileAdaptive: true },
    );
  };

  return (
    <>
      <TemplatesPanelView
        title={t('modernComponents.TemplatesModal.title')}
        templates={templatesToShow}
        templatesLeft={templatesLeft}
        onTemplateSelect={(template) => showModal(template?.id)}
        onButtonClick={() => {
          showModal();
          sendHomeTabEvent({ action: 'all templates click' });
        }}
      />

      <Spacer factor={8} />
    </>
  );
};

import React, { useCallback } from 'react';
import { useBotOptionsHandler } from '@components/BotList/components/BotOptionsHandler/BotOptionsHandler';
import { useRenameBot } from '@components/PageHeader/BotHeader/components/BotNameInput/useRenameBot';
import { useAdminId } from '@utils/Admin/useAdminId';
import { isBotCopying } from '@utils/Bot/isBotCopying';
import { useSubscribeToBotCreation } from '@components/PageHeader/BotHeader/hooks';
import { BotQuery_bot as Bot } from '../../../../../common/services/GQLqueries/@types/BotQuery';
import { useBotSectionData } from './Data/useBotSectionData';
import {
  BotSectionHeaderView,
  BotSectionHeaderViewProps,
} from './BotSectionHeaderView';
import { HOME_TAB_EVENT_CATEGORY } from '../../consts';
import { sendHomeTabEvent } from '../../utils';

export interface BotSectionHeaderProps
  extends Pick<BotSectionHeaderViewProps, 'headerClassName'> {}

export const BotSectionHeader: React.FC<BotSectionHeaderProps> = (props) => {
  const { bot } = useBotSectionData();
  const { id } = useAdminId();
  const updateBotTitle = useRenameBot({
    eventCategory: HOME_TAB_EVENT_CATEGORY,
  });
  const { isRenaming: isNewBotRenaming, doneRenaming } =
    useSubscribeToBotCreation();
  const { handleOptionSelect, isRenameMode, setIsRenameMode } =
    useBotOptionsHandler({ userId: id! });

  const exitRenameMode = useCallback(
    (bot: Bot) => {
      setIsRenameMode(bot, false);
      doneRenaming();
    },
    [setIsRenameMode, doneRenaming],
  );

  const onSubmit = useCallback(
    (title: string) => {
      if (!bot) {
        return;
      }

      sendHomeTabEvent({ action: 'rename submitted' });
      updateBotTitle(bot, title);
      exitRenameMode(bot);
    },
    [bot, exitRenameMode, updateBotTitle],
  );

  const onReject = useCallback(() => {
    if (!bot) {
      return;
    }

    sendHomeTabEvent({ action: 'rename rejected' });
    exitRenameMode(bot);
  }, [bot, exitRenameMode]);

  if (!bot) {
    return null;
  }

  return (
    <BotSectionHeaderView
      bot={bot}
      onSubmit={onSubmit}
      onReject={onReject}
      isRenameMode={isRenameMode(bot) || isNewBotRenaming}
      isBotOptionsMenuDisabled={isBotCopying(bot)}
      onActionSelect={(type) => handleOptionSelect(bot, type)}
      {...props}
    />
  );
};

import { useEffect } from 'react';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { FirstSessionStep } from './consts';

interface UseCheckPageConnectedProps {
  isPaywallStep: boolean;
  canBeSkipped: boolean;
  setStep: (newStep: FirstSessionStep) => void;
}

/**
 * Bot has several admins, this checks if anyone has disabled the page
 */
export const useCheckPageConnected = ({
  isPaywallStep,
  setStep,
  canBeSkipped,
}: UseCheckPageConnectedProps) => {
  const { pageId, isRequested: isBotPageRequested } = usePageConnected();

  useEffect(() => {
    if (!canBeSkipped && isPaywallStep && isBotPageRequested && !pageId) {
      setStep(FirstSessionStep.activateBot);
    }
  }, [canBeSkipped, isPaywallStep, isBotPageRequested, pageId, setStep]);
};

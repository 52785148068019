import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useCurrentBotId } from '@utils/Routing';
import { useAdminFeatures } from '@utils/Data/Admin';
import { RoleConsumer } from '@utils/Roles';
import { MovedToAnotherPlace } from './MovedToAnotherPlace';
import { subscribeToRulesList } from '../../../GrowPage/CommentsAutoreply/commentsAutoreplyMutations';
import { Permission } from '../../../../common/services/RoleService';
import * as css from './LegacyBlocks.css';
import { sendHomeTabEvent } from '../utils';

const FacebookAds = () => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  const history = useHistory();
  const { adminFeatures, adminFeaturesLoading } = useAdminFeatures();

  const goToFacebookMessengerAds = useCallback(() => {
    sendHomeTabEvent({ action: 'go to facebook messenger ads' });
    history.push(`/bot/${botId}/home/facebook-messenger-ads`);
  }, [botId, history]);

  if (
    adminFeatures?.hide_grow_facebook_messenger_ads ||
    !adminFeatures?.show_ads_manager
  ) {
    return null;
  }

  return (
    <MovedToAnotherPlace
      data-testid="moved-block__facebook-ads"
      title={t('pages.GrowPage.LegacyBlocks.facebookMessengerAds.title')}
      text={t('pages.GrowPage.LegacyBlocks.facebookMessengerAds.text')}
      buttonTitle={t(
        'pages.GrowPage.LegacyBlocks.facebookMessengerAds.buttonTitle',
      )}
      onClick={goToFacebookMessengerAds}
      loading={adminFeaturesLoading}
    />
  );
};

const Comments = () => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  const history = useHistory();
  const { adminFeatures } = useAdminFeatures();
  const [show, setShow] = useState(false);

  const goToComments = useCallback(() => {
    sendHomeTabEvent({ action: 'go to comments' });
    history.push(`/bot/${botId}/home/aquire-users-from-comments`);
  }, [botId, history]);

  useEffect(() => {
    const subscription = subscribeToRulesList(botId, ({ data }) => {
      /**
       * Условия отображения этого блока должны быть синхронизированны с файлом grow.html
       * Ангулярный компонент внутри себя по условиям понимает стоит ли рисоваться или нет
       * поэтому необходимо чтобы блок с редиректом на редактирование правил коментов
       * рисовался только тогда, когда ангулярный компонент будет отрисован
       */
      if (data?.bot?.commentsAutoreplyRules?.length > 0) {
        setShow(!adminFeatures?.deprecate_grow_acquire_users_card);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  });

  if (!show) {
    // Хак для гридов, проще всегда рисовать 2 колонки, чем одну и ее подгонять под нужную ширину
    return <div />;
  }

  return (
    <MovedToAnotherPlace
      data-testid="moved-block__comments"
      title={t('pages.GrowPage.LegacyBlocks.comments.title')}
      text={t('pages.GrowPage.LegacyBlocks.comments.text')}
      buttonTitle={t('pages.GrowPage.LegacyBlocks.comments.buttonTitle')}
      onClick={goToComments}
    />
  );
};

export const LegacyBlocks = () => (
  <div className={css.wrapper}>
    <RoleConsumer domain="grow" can={Permission.EDIT}>
      <FacebookAds />
    </RoleConsumer>

    <Comments />
  </div>
);

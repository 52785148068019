import { removeEntity } from '@components/FlowBuilder/components';
import Maybe from 'graphql/tsutils/Maybe';
import i18next from 'i18next';

export const deleteBroadcastConfirm = (
  callback: VoidFunction,
  isNew: Maybe<boolean>,
) =>
  isNew
    ? removeEntity({
        onSubmit: callback,
        renderActionText: () =>
          i18next.t('pages.BotPage.ReengageWhatsappTab.delete.action'),
        renderHeading: () =>
          i18next.t('pages.BotPage.ReengageWhatsappTab.delete.title'),
        renderNoteText: () =>
          i18next.t('pages.BotPage.ReengageWhatsappTab.delete.text'),
      })
    : removeEntity({
        onSubmit: callback,
        renderActionText: () =>
          i18next.t('pages.BotPage.ReengageWhatsappTab.delete_old.action'),
        renderHeading: () =>
          i18next.t('pages.BotPage.ReengageWhatsappTab.delete_old.title'),
        renderNoteText: () =>
          i18next.t('pages.BotPage.ReengageWhatsappTab.delete_old.text'),
      });

import React, { useRef } from 'react';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import {
  FilterableBaseInput,
  useFilterableSearch,
  RenderFilterableInputConfig,
} from '@components/FilterableSearchField';
import { ActionButton } from '@components/FilterableSearchField/components/ActionButton';
import { SearchType } from '@components/FilterableSearchField/context';
import { sendEvent } from '@utils/Analytics';
import {
  EntryPointStatusMenu,
  ENTRY_POINT_STATUS_MENU_ITEMS,
} from '../EntryPointStatusMenu';
import { EntryPointStatus, FlowsFilter } from '../../types';

import * as css from './EntryPointStatusFilterableInput.css';

interface EntryPointStatusFilterableInputProps
  extends RenderFilterableInputConfig<FlowsFilter> {
  preparedFilter: FlowsFilter;
}

export const EntryPointStatusFilterableInput: React.FC<EntryPointStatusFilterableInputProps> =
  ({
    comboboxStyles,
    preparedFilter,
    preparedFilterRef,
    searchValue,
    setPreparedFilter,
    setSearchInputValue,
    inputWidth,
  }) => {
    const { value, setFilter, resetFilter } = useFilterableSearch();
    const clearSelectionRef = useRef<HTMLButtonElement>(null);

    return (
      <EntryPointStatusMenu
        statusId={value as EntryPointStatus}
        comboboxProps={{ ...comboboxStyles, initialIsOpen: true }}
        onStatusChange={(status) => {
          sendEvent({
            category: 'flows',
            action: 'filter by entry point status',
            propertyBag: {
              status,
            },
          });
          setSearchInputValue('');
          setPreparedFilter(null);
          setFilter({
            type: FlowsFilter.entryPointStatus,
            value: status as string,
            parameters: null,
          });
        }}
      >
        {(comboboxProps) => {
          const { openMenu, closeMenu, isOpen } = comboboxProps;

          return (
            <FilterableBaseInput
              width={inputWidth}
              defaultValue={searchValue}
              showPreparedFilterInput={!!preparedFilter}
              preparedFilterRef={preparedFilterRef}
              onPreparedFilterChange={(event: any) => {
                const { value } = event.target;
                if (isOpen) {
                  closeMenu?.();
                }
                setPreparedFilter(null);
                setFilter({ type: SearchType.search, value, parameters: null });
              }}
              onControlClick={() => {
                if (preparedFilter) {
                  if (!isOpen) {
                    openMenu?.();
                  }
                } else {
                  clearSelectionRef.current?.focus();
                }
              }}
              renderFilter={() => (
                <div className={css.entryPointStatusInput}>
                  <Spacer horizontalFactor={1} factor={0} />
                  <Type size="15px_DEPRECATED">
                    {
                      ENTRY_POINT_STATUS_MENU_ITEMS().find(
                        ({ id }) => id === value,
                      )!.title
                    }
                  </Type>
                  <Spacer horizontalFactor={2} factor={0} />
                  <ActionButton
                    icon="close"
                    ref={clearSelectionRef}
                    onClick={() => {
                      resetFilter();
                    }}
                  />
                  <Spacer horizontalFactor={2} factor={0} />
                </div>
              )}
            />
          );
        }}
      </EntryPointStatusMenu>
    );
  };

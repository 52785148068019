import React from 'react';
import { ButtonUnstyled } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PlanBlockHeader, PlanBlockHeaderProps } from './PlanBlockHeader';
import { PlanBlockLayout } from './PlanBlockLayout';
import css from '../PlanBlock.css';
import {
  Conversations,
  PaymentEntityStatistics,
  PaymentEntityStatisticsProps,
} from './PaymentEntityStatistics';

export interface PremiumPlanBlock
  extends PlanBlockHeaderProps,
    Pick<PaymentEntityStatisticsProps, 'paymentEntityType'> {
  conversations: Conversations;
  onClick: () => void;
}

export const PremiumPlanBlock: React.FC<PremiumPlanBlock> = ({
  onClick,
  currentTier,
  nextTier,
  conversations,
  paymentEntityType,
}) => {
  const { t } = useSafeTranslation();

  return (
    <PlanBlockLayout
      headerView={
        <PlanBlockHeader currentTier={currentTier} nextTier={nextTier} />
      }
      statusView={
        <div className={css.wrapper}>
          {conversations && (
            <PaymentEntityStatistics
              {...conversations}
              paymentEntityType={paymentEntityType}
            />
          )}
        </div>
      }
      footerView={
        <>
          <Spacer factor={5} />
          <Flex>
            <ButtonUnstyled
              data-testid="plan-block__contact-us"
              className={css.contactUs}
              onClick={onClick}
            >
              {t('pages.Billing.premiumPage.contactUs')}
            </ButtonUnstyled>
            <Type size="15px">
              {t('pages.Billing.premiumPage.manageYourPlan')}
            </Type>
          </Flex>
        </>
      }
    />
  );
};

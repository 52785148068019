import React from 'react';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import { OnboardingEmitter } from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';
import { OnboardingTourEventType, OnboardingTourHTMLElementId } from '@globals';

export interface StartButtonProps {
  startButtonTitle: string;
  onStartButtonClick: React.MouseEventHandler<HTMLButtonElement>;
  startButtonDisabled?: boolean;
}

export const StartButton: React.FC<StartButtonProps> = ({
  startButtonDisabled,
  startButtonTitle,
  onStartButtonClick,
}) => {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    OnboardingEmitter.emit(OnboardingTourEventType.click, {
      element: OnboardingTourHTMLElementId.WAReengagePanelStartButton,
      value: null,
    });
    onStartButtonClick(e);
  };
  return (
    <Button
      id={OnboardingTourHTMLElementId.WAReengagePanelStartButton}
      style={{ width: '100%' }}
      intent="primary"
      disabled={startButtonDisabled}
      onClick={handleClick}
      data-testid="reengage-panel__start-button"
    >
      <Flex alignItems="center" justifyContent="center">
        <Icon icon="send" color="white" />
        <Type size="15px" color="white" weight="medium">
          {startButtonTitle}
        </Type>
      </Flex>
    </Button>
  );
};

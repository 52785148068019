import { useCallback } from 'react';
import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';

type CollapsibleWorkspacePayload = { [workspaceId: string]: boolean };

export const useCollapsibleWorkspaces = () => {
  const [collapsibleWorkspaces, setCollapsibleWorkspace, { loading }] =
    useServerStorage<CollapsibleWorkspacePayload>(
      ServerStorageItemKeys.collapsibleWorkspaces,
    );

  const getIsWorkspaceCollapsed = useCallback(
    (workspaceId: string) =>
      Boolean(collapsibleWorkspaces && collapsibleWorkspaces[workspaceId]),
    [collapsibleWorkspaces],
  );

  const collapseWorkspace = useCallback(
    (workspaceId: string, isCollapsed: boolean) => {
      const workspaceCollabsed = { [workspaceId]: isCollapsed };
      setCollapsibleWorkspace(
        collapsibleWorkspaces
          ? { ...collapsibleWorkspaces, ...workspaceCollabsed }
          : workspaceCollabsed,
      );
    },
    [collapsibleWorkspaces, setCollapsibleWorkspace],
  );

  return { getIsWorkspaceCollapsed, collapseWorkspace, loading };
};

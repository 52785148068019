import React, { useCallback } from 'react';
import cn from 'classnames';
import { useGoToPlans } from '@utils/Routing';
import * as css from './common.css';
import { PricingPlanType } from '../../../../../Plans/types';
import { sendHomeTabEvent } from '../../../utils';
import {
  SizeDependentButton,
  SizeDependentButtonProps,
} from './SizeDependentButton';

export interface GoToPlansButtonProps extends SizeDependentButtonProps {
  plan?: PricingPlanType;
}

export const GoToPlansButton = React.forwardRef<
  HTMLButtonElement,
  GoToPlansButtonProps
>(({ children, plan, onClick, ...props }, ref) => {
  const goToPlans = useGoToPlans({
    plan: plan || PricingPlanType.business,
    isDialogsPricing: true,
  });

  const onButtonClick = useCallback(
    (event) => {
      sendHomeTabEvent({ action: 'go to plans' });
      goToPlans();
      onClick?.(event);
    },
    [onClick, goToPlans],
  );

  return (
    <SizeDependentButton
      data-testid="bot-panel__go-to-plans-button"
      intent="primary"
      onClick={onButtonClick}
      className={cn(css.button, props.className)}
      ref={ref}
      {...props}
    >
      {children}
    </SizeDependentButton>
  );
});

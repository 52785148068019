import React from 'react';
import { Flex } from '@ui/Flex';
import cn from 'classnames';
import { FlexProps } from '@ui/Flex/Flex';
import { InputLoadingPlaceholder } from '@ui/LoadingPlaceholder/InputLoadingPlaceholder';
import * as css from './RespondLoader.css';
import { Spacer } from '@ui/Spacer';

interface RespondLoaderProps extends Omit<FlexProps, 'ref'> {}

export const RespondLoader: React.FC<RespondLoaderProps> = ({
  className,
  ...props
}) => (
  <Flex className={cn(css.box, className)} {...props}>
    <InputLoadingPlaceholder
      height={36}
      style={{ minWidth: 100, width: 'auto' }}
    />
    <Spacer horizontalFactor={2} factor={4} />
    <InputLoadingPlaceholder height={36} style={{ flexGrow: 2 }} />
  </Flex>
);

import { useCurrentBotId } from '@utils/Routing';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import {
  getContact,
  getContactVariables,
  getContact_bot_contact,
} from './@types/getContact';

export const GET_CONTACT_QUERY = gql`
  query getContact($id: ID!, $botId: String!) {
    bot(id: $botId) {
      id
      contact(id: $id) {
        id
        platformScopeId
        name
        phone
        platform
        profilePic
        systemAttributes {
          name
          value
          source
          isDateTime
        }
        tags {
          id
          name
        }
        livechatConversationId
        customAttributes {
          name
          value
        }
      }
    }
  }
`;

export const useContactQuery = (contactId?: string) => {
  const botId = useCurrentBotId();
  const queryRes = useQuery<getContact, getContactVariables>(
    GET_CONTACT_QUERY,
    {
      variables: {
        botId: botId!,
        id: contactId!,
      },
      skip: !botId || !contactId,
    },
  );

  return {
    contact: queryRes.data?.bot.contact,
    ...queryRes,
  };
};

export type Contact = getContact_bot_contact;

import { Platform } from '@globals';

export interface PlatformSelector {
  platform: Platform;
  onPlatformChange: (platform: Platform) => void;
}

export interface Step {
  enabled: boolean;
  done: boolean;
  current: boolean;
  stepSaving: boolean;
  onDone: () => void;
  onGoToPrevRequest?: () => void;
}

export enum IntroQuestionsCodes {
  industry = 'industry',
  role = 'role',
}

export enum IntroQuestionsIndustyOptions {
  eCommerce = 'e-commerce',
}

export enum IntroQuestionsRoleOptions {
  marketingManager = 'marketing-manager',
  marketingFreelancer = 'marketing-freelancer',
  marketingAgencyPartner = 'marketing-agency-partner',
  salesManager = 'sales-manager',
  customerSupportManager = 'customer-support-manager',
  businessOwner = 'business-owner',
  botBuilder = 'bot-builder',
}

export enum DeepLinksMode {
  templatesModal = 'templatesModal',
  entryPointsModal = 'entryPointsModal',
  upgrade = 'upgrade',
  plans = 'plans',
  premiumPlans = 'premiumPlans',
  enableEntryPoint = 'enableEntryPoint',
  enableEntryPointSelected = 'enableEntryPointSelected',
  enableEntryPointFb = 'enableEntryPointFb',
  testThis = 'testThis',
  testThisFlowSelected = 'testThisFlowSelected',
  testThisFb = 'testThisFb',
  referral = 'referral',
  connectWhatsapp = 'connectWhatsapp',
}

export enum DeepLinksQueryParam {
  mode = 'dlMode',
  testFlowId = 'dlTestFlowId',
  entryPointId = 'dlEntryPointId',
  flowId = 'flowId',
  platform = 'platform',
  tierId = 'dlTierId',
  tierPeriod = 'dlTierPeriod',
}

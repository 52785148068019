import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import * as css from './RegisterToReceiveMessages.css';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Type } from '@ui/Type';
import { SingleButtonFooter } from '../common/SingleButtonFooter';
import { Trans } from 'react-i18next';
import { Button, ButtonUnstyled } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { useNotifyBotSubscription } from '../../hooks/useNotifyBotSubscription';
import { CenteredLoader } from '@ui/Loader';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { usePrevious } from 'cf-common/src/utils/hooks';
import { sendEvent } from 'cf-common/src/analytics';

interface RegisterToReceiveMessagesProps {
  onDone: VoidFunction;
}

export const RegisterToReceiveMessages: React.FC<RegisterToReceiveMessagesProps> =
  ({ onDone }) => {
    const [isUseCamera, setIsUseCamera] = useState<boolean>(true);
    const [needUpdateCode, setNeedUpdateCode] = useState<boolean>(false);
    const { isSmallScreenSize } = useDeviceMedia();
    const { t } = useSafeTranslation();
    const {
      qrCodeUrl,
      signUpCode,
      displayPhoneNumber,
      isBotSubscribed,
      expirationDate,
      loading,
      refetchSubscriptionRequisites,
      openConnectUrl,
    } = useNotifyBotSubscription();

    useEffect(() => {
      const now = Date.now();
      if (!expirationDate) {
        return undefined;
      }
      if (expirationDate < now) {
        setNeedUpdateCode(true);
        return undefined;
      }
      setNeedUpdateCode(false);
      const timeout = window.setTimeout(() => {
        setNeedUpdateCode(true);
      }, expirationDate - now);
      return () => {
        window.clearTimeout(timeout);
      };
    }, [expirationDate]);

    useEffect(() => {
      if (needUpdateCode && isSmallScreenSize && !loading) {
        refetchSubscriptionRequisites();
      }
    }, [
      isSmallScreenSize,
      loading,
      needUpdateCode,
      refetchSubscriptionRequisites,
    ]);

    const prevIsBotSubscribed = usePrevious(isBotSubscribed);
    useEffect(() => {
      if (!prevIsBotSubscribed && isBotSubscribed) {
        onDone();
      }
    }, [isBotSubscribed, onDone, prevIsBotSubscribed]);

    const showUpdatedButton = needUpdateCode && !isBotSubscribed;

    if (isSmallScreenSize) {
      return (
        <>
          <Flex flexDirection="column" className={css.mobileBox}>
            <div className={css.mobilePhoneBox} />
            <Spacer factor={4} />
            <Type>
              <Trans
                t={t}
                i18nKey="pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.registerToReceiveMessages.textMobile"
              />
            </Type>
          </Flex>
          <SingleButtonFooter
            buttonI18nKey="pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.registerToReceiveMessages.buttonMobile"
            onButtonClick={() => {
              sendEvent({
                category: 'purchase phone number',
                label: 'register',
                action: 'open connect url click',
              });
              openConnectUrl();
            }}
          />
        </>
      );
    }

    return (
      <>
        {isUseCamera && (
          <>
            <Flex
              alignItems="center"
              justifyContent="center"
              className={css.qrBox}
            >
              {qrCodeUrl && !loading ? (
                <>
                  <img
                    className={cn(
                      css.qrImg,
                      (showUpdatedButton || isBotSubscribed) && css.disabled,
                    )}
                    src={qrCodeUrl}
                    width={100}
                    height={100}
                    alt={signUpCode}
                    data-testid="purchase-phone__qr_code_img"
                  />
                  {showUpdatedButton && (
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      className={css.updateQrButtonBox}
                    >
                      <Button
                        onClick={() => {
                          refetchSubscriptionRequisites();
                          sendEvent({
                            category: 'purchase phone number',
                            label: 'register',
                            action: 'update qr code button click',
                          });
                        }}
                        data-testid="purchase-phone-number_register_update-code-button"
                      >
                        <Flex gapFactor={2} alignItems="center">
                          <Icon icon="refresh2" size="16px" color="white" />
                          {t(
                            'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.registerToReceiveMessages.updateQr',
                          )}
                        </Flex>
                      </Button>
                    </Flex>
                  )}
                </>
              ) : (
                <CenteredLoader />
              )}
            </Flex>
            <Spacer factor={4} />
            <Type>
              <Trans
                t={t}
                i18nKey="pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.registerToReceiveMessages.text"
              />
            </Type>
            <Spacer factor={4} />
          </>
        )}
        <ButtonUnstyled
          data-testid="purchase-phone-number_register_cant-use-camera-button"
          onClick={() => {
            setIsUseCamera(!isUseCamera);
            sendEvent({
              category: 'purchase phone number',
              label: 'register',
              action: 'cant use camera button click',
            });
          }}
        >
          <Flex alignItems="center">
            <Type color="blue">
              {t(
                'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.registerToReceiveMessages.cantUseTheCamera',
              )}
            </Type>
            <Icon
              icon="chevronUp"
              color="blue"
              style={{
                transform: `rotate(${isUseCamera ? '-180deg' : '0deg'})`,
              }}
            />
          </Flex>
        </ButtonUnstyled>
        {!isUseCamera && (
          <>
            <Spacer factor={4} />
            <ol className={css.ol}>
              <li>
                <Type>
                  {t(
                    'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.registerToReceiveMessages.number',
                  )}
                </Type>
                <br />
                <Type
                  weight="bold"
                  data-testid="purchase-phone__send-code-to-number"
                >{displayPhoneNumber}</Type>
              </li>
              <li>
                <Type>
                  {t(
                    'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.registerToReceiveMessages.code',
                  )}
                </Type>{' '}
                <Type
                  weight={
                    showUpdatedButton || isBotSubscribed ? undefined : 'bold'
                  }
                  color={
                    showUpdatedButton || isBotSubscribed ? 'grey' : 'black'
                  }
                  decoration={showUpdatedButton ? 'line-through' : undefined}
                  data-testid="purchase-phone__sign-up-code-in-text"
                >
                  {signUpCode}
                </Type>{' '}
                {showUpdatedButton && (
                  <Button
                    className={css.updateCodeButton}
                    size="xs"
                    onClick={() => {
                      refetchSubscriptionRequisites();
                      sendEvent({
                        category: 'purchase phone number',
                        label: 'register',
                        action: 'update code button click',
                      });
                    }}
                    data-testid="purchase-phone__update-code-button"
                  >
                    <Flex alignItems="center" gapFactor={1}>
                      <Icon icon="refresh2" color="white" size="16px" />
                      <Type color="white">
                        {t(
                          'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.registerToReceiveMessages.updateCode',
                        )}
                      </Type>
                    </Flex>
                  </Button>
                )}
              </li>
            </ol>
            <Spacer factor={4} />
            <div className={css.phoneBox}>
              <Type
                as="div"
                size="11px" className={css.code}
                data-testid="purchase-phone__sign-up-code-in-image"
              >
                {signUpCode}
              </Type>
            </div>
          </>
        )}
        <SingleButtonFooter
          buttonI18nKey="pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.registerToReceiveMessages.button"
          onButtonClick={() => {
            sendEvent({
              category: 'purchase phone number',
              label: 'register',
              action: 'next button click',
            });
            onDone();
          }}
          buttonDisabled={!isBotSubscribed}
        />
      </>
    );
  };

import { MessagesTemplateContentInput } from '@globals';

export const DEFAULT_TEMPLATE_CONTENT: MessagesTemplateContentInput = {
  header: {
    text: '',
    type: null,
    attachment: null,
    attribute_examples: [],
  },
  body: {
    text: '',
    attribute_examples: [],
  },
  footer: {
    text: '',
  },
  buttons: [],
};

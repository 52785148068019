import React from 'react';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './NextChargeDetails.css';

interface NextChargeDetailsViewProps {
  tooltip: any;
  amount: string;
  date: string | null;
}

export const NextChargeDetailsView: React.FC<NextChargeDetailsViewProps> = ({
  tooltip,
  amount,
  date,
}) => {
  const { t } = useSafeTranslation();
  const Tooltip = tooltip;

  return (
    <span data-testid="bot-panel__next-charge-details">
      <Type size="15px">{t('pages.GrowPage.BotPanel.nextCharge')}&nbsp;</Type>
      <Tooltip>
        <Type
          size="15px"
          weight="semibold"
          className={css.amount}
          data-testid="bot-panel__next-charge-amount"
        >
          {amount}
        </Type>
      </Tooltip>
      {date && (
        <Type size="15px">
          &nbsp;{t('pages.GrowPage.BotPanel.on', { date })}
        </Type>
      )}
    </span>
  );
};

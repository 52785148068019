import i18next from 'i18next';
import { ChargingEntityType } from '@globals';
import {
  PaymentHistoryItem,
  PaymentHistoryType,
  PaymentPart,
  Payment,
  PartType,
  PaymentEntityType,
} from './types';
import { formatPrice } from '@utils/Format';
import Maybe from 'graphql/tsutils/Maybe';

const PADDING = 12;
const COMPLEX_PAYMENT_PART_HEIGHT = 32;
const COMPLEX_ITEM_HEIGHT = 20 + PADDING;
const REGULAR_ITEM_HEIGHT = 52;
const COMPLEX_BLOCK_HEIGHT = 140;

export const chargingEntityTypeToPaymentEntityType = (
  chargingEntityType: ChargingEntityType | null,
): PaymentEntityType => {
  if (chargingEntityType === ChargingEntityType.dialogs) {
    return PaymentEntityType.dialogs;
  }

  if (chargingEntityType === ChargingEntityType.users) {
    return PaymentEntityType.users;
  }

  return PaymentEntityType.dialogs;
};

export const calculateHeight = (
  partsLength: number,
  isSmallScreenSize: boolean,
) => {
  const partsSize =
    partsLength > 1
      ? partsLength * COMPLEX_PAYMENT_PART_HEIGHT + partsLength * PADDING
      : 0;

  if (isSmallScreenSize) {
    return COMPLEX_BLOCK_HEIGHT + partsSize;
  }

  return (partsLength ?? 0) > 1
    ? COMPLEX_ITEM_HEIGHT + partsSize
    : REGULAR_ITEM_HEIGHT;
};

export const getSubscriptionByType = (
  paymentEntityType: PaymentEntityType | null,
  value: number,
) => {
  let subscription = '';

  if (paymentEntityType) {
    subscription = {
      [PaymentEntityType.dialogs]: i18next.t(
        'pages.Billing.Table.nConversations',
        {
          conversations: value,
        },
      ),
      [PaymentEntityType.users]: i18next.t('pages.Billing.Table.nUsers', {
        users: value,
      }),
      [PaymentEntityType.broadcasts]: i18next.t(
        'pages.Billing.Table.nBroadcasts',
        {
          broadcasts: value,
        },
      ),
    }[paymentEntityType];
  }

  return subscription;
};

export const mapPart = (part: PaymentPart, currency: string): PartType => ({
  price: formatPrice(part.amount, {
    currency,
    decimals: 2,
  }),
  value: part.limit,
  type: part.type,
  pricePerEntity: formatPrice(part.pricePerEntity, {
    currency,
    decimals: 2,
  }),
  from: part.from ? Number(part.from) : null,
  to: part.to ? Number(part.to) : null,
});

export const invoicesTableAdapter = (
  payments: Array<Payment>,
): Array<PaymentHistoryItem> =>
  payments.map((payment) => {
    let type = PaymentHistoryType.default;

    if ((payment.parts?.length ?? 0) > 1) {
      type = PaymentHistoryType.complex;
    }

    if (!payment.parts || !payment.parts?.[0]) {
      type = PaymentHistoryType.empty;
    }

    const parts = payment.parts.map((part) => mapPart(part, payment.currency));

    return {
      id: payment.id,
      type,
      parts,
      date: payment.lastAttemptDate,
      paymentEntityType: chargingEntityTypeToPaymentEntityType(
        payment.chargingEntityType,
      ),
      price: formatPrice(payment.amount, {
        currency: payment.currency,
        decimals: 2,
      }),
      paymentResult: payment.lastAttemptStripeResult,
    };
  });

export const getNextCharge = (
  pricing: Maybe<any>,
  items: Payment[],
): PaymentHistoryItem | null => {
  const { currency, invoice_total, next_invoice_date, parts } = pricing ?? {};
  const paymentEntityType = chargingEntityTypeToPaymentEntityType(
    items[0]?.chargingEntityType,
  );

  if (
    next_invoice_date &&
    invoice_total &&
    paymentEntityType &&
    parts &&
    currency
  ) {
    const type =
      parts.length > 1
        ? PaymentHistoryType.complex
        : PaymentHistoryType.default;

    return {
      id: '',
      type,
      parts: parts.map((part: PaymentPart) => mapPart(part, currency)),
      date: String(next_invoice_date),
      paymentEntityType,
      price: formatPrice(invoice_total, { currency, decimals: 2 }),
      paymentResult: null,
    };
  }

  return null;
};

import React from 'react';
import * as css from './WhatsappObtainedPhoneCallout.css';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PhoneNumberView } from '../common/PhoneNumberView';
import { useAdminPhones } from '../WhatsappBuyPhoneDialog/hooks/useAdminPhones';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import cn from 'classnames';
import { DateFormat } from '@utils/DateTime';

export const WhatsappObtainedPhoneCallout: React.FC = () => {
  const { t } = useSafeTranslation();
  const { lastNotUtilizedPhone } = useAdminPhones();
  const { isSmallScreenSize } = useDeviceMedia();

  if (!lastNotUtilizedPhone) {
    return null;
  }

  return (
    <Flex
      className={cn(css.box, isSmallScreenSize && css.mobile)}
      gapFactor={2}
      alignItems="center"
      flexDirection={isSmallScreenSize ? 'column' : 'row'}
    >
      <PhoneNumberView
        code={`+${lastNotUtilizedPhone.countryCode}`}
        phone={lastNotUtilizedPhone.nationalNumber}
        color="accent3Dark"
        borderColor="accent3Normal"
      />
      <Type
        color="accent3Text"
        weight="medium"
        align={isSmallScreenSize ? 'center' : 'left'}
      >
        {t('pages.BotPage.HomeTab.Whatsapp.finishConnect', {
          date:
            lastNotUtilizedPhone.expiredAt &&
            DateFormat.MMMDD(lastNotUtilizedPhone.expiredAt),
        })}
      </Type>
    </Flex>
  );
};

import { useMemo } from 'react';
import { useIntroQuestionsSavedDataQuery } from '../components/IntroQuestionsBlock/hooks/useIntroQuestionsSavedDataQuery';
import { IntroQuestionsCodes } from '../types';

const defaultCodes: [] = [];

export const useIntroQuestionVariables = (
  codes: Array<IntroQuestionsCodes> = defaultCodes,
) => {
  const { data, loading } = useIntroQuestionsSavedDataQuery({
    fetchPolicy: 'cache-first',
  });

  return {
    data: useMemo(
      () =>
        codes.map((code) =>
          data?.savedIntroQuestions?.variables?.find(
            (variable) => variable.code === code,
          ),
        ),
      [codes, data],
    ),
    loading,
  };
};

import React from 'react';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { FooterMenu, FooterMenuProps } from './FooterMenu';
import { StartButton, StartButtonProps } from './StartButton';

export interface StartButtonWithMenuProps
  extends StartButtonProps,
    FooterMenuProps {}

export const StartButtonWithMenu: React.FC<StartButtonWithMenuProps> = ({
  startButtonDisabled,
  onCopyButtonClick,
  onDeleteButtonClick,
  startButtonTitle,
  onStartButtonClick,
}) => {
  return (
    <Flex>
      <FooterMenu
        onCopyButtonClick={onCopyButtonClick}
        onDeleteButtonClick={onDeleteButtonClick}
      />

      <Spacer factor={0} horizontalFactor={3} />

      <StartButton
        startButtonDisabled={startButtonDisabled}
        startButtonTitle={startButtonTitle}
        onStartButtonClick={onStartButtonClick}
      />
    </Flex>
  );
};

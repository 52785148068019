import React from 'react';
import { Trans } from 'react-i18next';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Type } from '@ui/Type';
import { Anchor } from '@ui/Links';
import { MenuItem } from '@ui/Menu';
import { Tooltip2 } from '@ui/Tooltip2';
import { getLinkByVideoHash } from '@utils/Youtube/getLinkByVideoHash';
import { PluginTutorialYoutube } from '../../../../../constants/externalLinks';
import { FlowItemMenuType } from './types';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { ReactComponent as ShareFlowPreview } from '../assets/share-flow-preview.svg';
import { ReactComponent as ManageAttributesPreview } from '../assets/manage-attributes-preview.svg';

export interface FlowActionsMenuItem {
  id: string;
  title: string;
  leftElement?: (isHighlighted: boolean) => React.ReactNode;
  type?: 'divider';
}

interface FlowActionsMenuProps {
  items: FlowActionsMenuItem[];
  onSelect(actionId: string): void;
  children(props: {
    getToggleButtonProps(): any;
    ref: React.Ref<any>;
  }): JSX.Element;
}

export const FlowActionsMenu: React.FC<FlowActionsMenuProps> = ({
  items,
  onSelect,
  children,
}) => {
  const { t } = useSafeTranslation();
  const manageAttributesDoc = t('common.helpDocLinks.attributes');

  return (
    <SimpleCombobox<FlowActionsMenuItem>
      position="bottom-end"
      unsaveSelectedOption
      items={items}
      onChange={(item) => {
        if (item?.id) {
          onSelect(item.id);
        }
      }}
      renderInput={children}
      renderItem={({ getItemProps, item, index, highlightedIndex }) => {
        const key = `${item.id}_${index}`;
        const isHighlighted = index === highlightedIndex;
        const commonProps = {
          ...getItemProps({ item }),
          key,
          active: isHighlighted,
          title: item.title,
          type: item.type,
          leftElement: item.leftElement
            ? item.leftElement(isHighlighted)
            : undefined,
        };

        switch (item.id) {
          case FlowItemMenuType.share: {
            const shareLinkTutorialHref = getLinkByVideoHash(
              PluginTutorialYoutube.shareLink,
            );
            return (
              <Tooltip2
                placement="right"
                boundariesElement="viewport"
                key={key}
                content={
                  <>
                    <ShareFlowPreview />
                    <br />
                    <br />
                    <Type as="p" size="12px" color="white">
                      {window.i18next.t(
                        'FlowActionsMenu-JSXText--105-share-the-link-to-your-bot-flow-group',
                      )}
                      <br />
                      {window.i18next.t(
                        'FlowActionsMenu-JSXText--634-with-colleagues-or-clients-theyll-get',
                      )}
                      <br />
                      {window.i18next.t(
                        'FlowActionsMenu-JSXText--114-an-overview-of-the-project-and-can',
                      )}
                      <br />
                      {window.i18next.t(
                        'FlowActionsMenu-JSXText-1681-copy-the-flow-group-to-a-new-bot-if',
                      )}
                      <br />
                      {window.i18next.t(
                        'FlowActionsMenu-JSXText-5967-you-choose-to-allow-that',
                      )}
                    </Type>
                    <br />
                    <Anchor
                      intent="tooltip"
                      size="small"
                      href={shareLinkTutorialHref}
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseDown={() => {
                        window.open(shareLinkTutorialHref, '_blank');
                      }}
                    >
                      {t('tutorial.FlowTutorial.ShareLinkTutorial')}
                    </Anchor>
                  </>
                }
                type="small"
                positionFixed
                hoverable
              >
                {(ref, bind) => (
                  <div {...bind} ref={ref}>
                    <MenuItem {...commonProps} />
                  </div>
                )}
              </Tooltip2>
            );
          }
          case FlowItemMenuType.manageAttributes:
            return (
              <Tooltip2
                placement="right"
                boundariesElement="viewport"
                key={key}
                content={
                  <>
                    <ManageAttributesPreview />
                    <br />
                    <br />
                    <Type as="p" size="12px" color="white">
                      <Trans
                        t={t}
                        i18nKey="pages.BotPage.FlowTab.FlowsActionMenu.manageAttributesTooltip"
                      >
                        {window.i18next.t(
                          'FlowActionsMenu-JSXText-5139-you-can-set-default-values-for-your',
                        )}
                        <br />
                        {window.i18next.t(
                          'FlowActionsMenu-JSXText--249-brands-main-info-and-quickly-edit-it',
                        )}
                        <br />
                        {window.i18next.t(
                          'FlowActionsMenu-JSXText-6272-afterward-or-you-can-add-fallbacks',
                        )}
                        <br />
                        {window.i18next.t(
                          'FlowActionsMenu-JSXText--205-for-them-in-case-the-information',
                        )}
                        <br />
                        {window.i18next.t(
                          'FlowActionsMenu-JSXText--484-about-a-particular-user-isnt-available',
                        )}
                      </Trans>
                    </Type>
                    <br />
                    <Anchor
                      intent="tooltip"
                      size="small"
                      href={manageAttributesDoc}
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseDown={() => {
                        window.open(manageAttributesDoc, '_blank');
                      }}
                    >
                      {t('tutorial.FlowTutorial.ManageAttributesTutorial')}
                    </Anchor>
                  </>
                }
                type="small"
                hoverable
                positionFixed
              >
                {(ref, bind) => (
                  <div {...bind} ref={ref}>
                    <MenuItem {...commonProps} />
                  </div>
                )}
              </Tooltip2>
            );
          default:
            return <MenuItem {...commonProps} />;
        }
      }}
    />
  );
};

import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Loader } from '@ui/Loader';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';

import { BillingDetailsForm } from '@components/Payments';
import {
  BillingDetailsFormProps,
  FormState,
} from '@components/Payments/BillingDetails';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

export const BillingInfoDialog: React.FC<{
  loading: boolean;
  error?: boolean;
  billingDetails?: BillingDetailsFormProps['initialValues'];
  onSubmit: BillingDetailsFormProps['onSubmit'];
  onClose: VoidFunction;
}> = ({ loading, error, billingDetails, onSubmit, onClose }) => {
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();

  const getContent = () => {
    if (loading)
      return (
        <Flex justifyContent="center" style={{ width: '100%' }}>
          <Loader />
        </Flex>
      );

    if (error || !billingDetails)
      return (
        <div>
          {t('BillingDetailsModal-JSXText--199-error-please-try-later')}
        </div>
      );

    return (
      <BillingDetailsForm
        initialValues={billingDetails}
        onSubmit={onSubmit}
        state={loading ? FormState.loading : undefined}
      />
    );
  };

  return (
    <DefaultDialog
      mobileAdaptive={isSmallScreenSize}
      onDismiss={onClose}
      header={t('BillingDetailsModal-JSXText-2023-billing-address')}
    >
      <Type size="15px">
        {t(
          'BillingDetailsModal-JSXText-3576-these-billing-details-appear-in-invoices',
        )}
      </Type>
      <Spacer factor={2} />
      {getContent()}
    </DefaultDialog>
  );
};

import React from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { Flex } from '@ui/Flex';
import { PricingStatusBadge } from '@components/DialogsPricing/PricingStatusBadge';
import { PlanBlockLayout } from './PlanBlockLayout';
import { PlanBlockHeader } from './PlanBlockHeader';
import { Setting } from '../Setting';
import {
  PaymentEntityStatistics,
  Conversations,
} from './PaymentEntityStatistics';
import { PaymentEntityAdjustmentFee } from './PaymentEntityAdjustmentFee';
import { PaymentDetailsStatus, TierType } from '@globals';
import css from '../PlanBlock.css';
import { PaymentEntityType } from '@pages/Billing/Table/types';
import { useTimeLimitedTrial } from '@utils/Data/Pricing/useTimeLimitedTrial';
import { SelectPlanButton } from './SelectPlanButton';
import { ButtonUnstyled } from '@ui/Button';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';

export interface DefaultPlanBlockProps {
  adjustmentFeePerDialog: Maybe<string>;
  nextPrepaymentDate: Maybe<number>;
  showChurnSurvey: () => void;
  paymentEntityType: Maybe<PaymentEntityType>;
  conversations: Conversations;
  currentTier: string;
  pausePro: () => void;
  nextTier: Maybe<string>;
  status: Maybe<PaymentDetailsStatus>;
  onCancel?: () => void;
  notUseTimeLimitedTrial?: boolean;
  showAdjForTrial?: boolean;
}

export const DefaultPlanBlock: React.FC<DefaultPlanBlockProps> = ({
  adjustmentFeePerDialog,
  nextPrepaymentDate,
  showChurnSurvey,
  paymentEntityType,
  conversations,
  currentTier,
  pausePro,
  nextTier,
  status,
  onCancel,
  notUseTimeLimitedTrial,
  showAdjForTrial,
}) => {
  const { t } = useSafeTranslation();
  const {
    currentTierTimeLimitedTrial,
    trialRemainingDays,
    trialExpired,
    trialEndDate,
  } = useTimeLimitedTrial(notUseTimeLimitedTrial);

  const isTrial = currentTier === TierType.trial;
  const showSettings = ((isTrial && nextTier) || !isTrial) && !onCancel;
  const canceled = status === PaymentDetailsStatus.to_be_cancelled;

  return (
    <PlanBlockLayout
      statusView={
        <PricingStatusBadge
          currentTierTimeLimitedTrial={currentTierTimeLimitedTrial}
          trialRemainingDays={trialRemainingDays}
          nextPrepaymentDate={nextPrepaymentDate}
          trialEndDate={trialEndDate}
          canceled={canceled}
        />
      }
      headerView={
        <Flex justifyContent="space-between">
          <PlanBlockHeader currentTier={currentTier} nextTier={nextTier} />
          {showSettings && (
            <Setting
              isTrial={isTrial}
              onPause={pausePro}
              onCancel={showChurnSurvey}
              canceled={canceled}
            />
          )}
          {onCancel && !canceled && (
            <ButtonUnstyled onClick={onCancel}>
              <Type color="blue" weight="semibold">
                {t('pages.Billing.cancel')}
              </Type>
            </ButtonUnstyled>
          )}
        </Flex>
      }
      footerView={
        currentTierTimeLimitedTrial ? (
          <SelectPlanButton trialExpired={trialExpired} />
        ) : (
          <div className={css.wrapper}>
            {conversations && (
              <PaymentEntityStatistics
                {...conversations}
                paymentEntityType={paymentEntityType}
              />
            )}

            {(!isTrial || showAdjForTrial) && adjustmentFeePerDialog && (
              <PaymentEntityAdjustmentFee
                paymentEntityType={paymentEntityType}
                adjustmentFeePerDialog={adjustmentFeePerDialog}
              />
            )}
          </div>
        )
      }
    />
  );
};

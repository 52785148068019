import {
  TriggeredMessageIntervalTimeUnit,
  TriggeredMessageType,
} from '@globals';
import { TType } from '@utils/useSafeTranslation';
import { WhatsappBroadcastConfig } from '../types';
import { IBroadcastType, BroadcastTypes } from './types';

export class TriggerBroadcastType implements IBroadcastType {
  public readonly type = BroadcastTypes.trigger;
  public readonly title: string;
  public readonly buttonTitle: string;

  constructor(public readonly t: TType) {
    this.title = t('pages.BotPage.ReengageWhatsappTab.broadcasts.trigger');
    this.buttonTitle = t(
      'pages.BotPage.ReengageWhatsappTab.panel.buttons.setLive',
    );
  }

  public getDefaultConfig(
    config: WhatsappBroadcastConfig,
  ): WhatsappBroadcastConfig {
    return {
      ...config,
      userFilter: null,
      schedule: null,
      triggerSettings: {
        __typename: 'BroadcastTriggerSettings',
        waitInterval: 1,
        waitIntervalTimeUnit: TriggeredMessageIntervalTimeUnit.minute,
        triggerType: TriggeredMessageType.first_interaction,
        userFilter: null,
        orderStatusUpdateStatus: null,
        timeOfDay: 0,
      },
    };
  }

  public isCurrentBroadcast(config: WhatsappBroadcastConfig): boolean {
    return Boolean(config.triggerSettings && !config.schedule);
  }
}

import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { DescriptionItemView, TitleProps } from './Primitives';
import { getSubscriptionByType } from '../utils';
import { PaymentEntityType } from '../types';

export interface PlanPrepaymentProps extends TitleProps {
  value: number;
  paymentEntityType: PaymentEntityType | null;
}

export const PlanPrepayment: React.FC<PlanPrepaymentProps> = ({
  value,
  paymentEntityType,
  ...props
}) => {
  const { t } = useSafeTranslation();

  return (
    <DescriptionItemView
      title={t('pages.Billing.Table.planSubscription')}
      subscription={getSubscriptionByType(paymentEntityType, value)}
      {...props}
    />
  );
};

import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Badge, BadgeProps } from '@ui/Badge';

export type ForBeginnersBadgeProps = Omit<BadgeProps, 'kind' | 'ref'>;

export const ForBeginnersBadge: React.FC<ForBeginnersBadgeProps> = (props) => {
  const { t } = useSafeTranslation();
  return (
    <Badge kind="success" {...props}>
      {t('modernComponents.TemplatesModal.templateLevels.forBeginners')}
    </Badge>
  );
};

import * as React from 'react';
import * as ng from 'angular';
import 'angular-ui-sortable';
import { angular2react } from 'angular2react';
import { useReadyToRender } from '../../../utils/AngularRouting';
import { lazyInjector } from '../../../utils/angularLazyInjector';
import broadcastSequence from '../../../components/broadcast/sequence';
import broadcastAutopost from '../../../components/broadcast/autopost';
import broadcastShedule from '../../../components/broadcast/schedule';
import template from '../../../components/broadcast/templates/broadcast.sidebar.html';
import controller from '../../../components/broadcast/sidebar.controller';

const reEngageAsideWrapper = {
  template: () => template,
  controller,
  controllerAs: 'vm',
};

export const ngReEngageAsideWrapper = ng
  .module('app.pages.reEngageAside', [
    'ui.sortable',
    broadcastSequence,
    broadcastAutopost,
    broadcastShedule,
  ])
  .component('reEngageAsideWrapper', reEngageAsideWrapper).name;

const ReEngageAsideWrapperFunc = () => {
  return angular2react<{}>(
    'reEngageAsideWrapper',
    reEngageAsideWrapper,
    lazyInjector.$injector as any,
  );
};

const ReEngageAsideWrapper = ReEngageAsideWrapperFunc();

export const ReEngageAside: React.FC = () => {
  const readyToRender = useReadyToRender('common');

  if (!readyToRender) {
    return <div />;
  }

  return (
    <section className="editor">
      <div>
        <div className="lcol">
          <ReEngageAsideWrapper />
        </div>
      </div>
    </section>
  );
};

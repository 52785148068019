import React from 'react';
import { Spacer } from '@ui/Spacer';
import css from '../PlanBlock.css';

export interface PlanBlockLayoutProps {
  statusView?: React.ReactNode;
  footerView?: React.ReactNode;
  headerView: React.ReactNode;
}

export const PlanBlockLayout: React.FC<PlanBlockLayoutProps> = ({
  statusView,
  footerView,
  headerView,
}) => {
  return (
    <div className={css.planBlock}>
      {headerView}

      {statusView && (
        <>
          <Spacer factor={1} />
          {statusView}
        </>
      )}

      {footerView && (
        <>
          <Spacer factor={5} />
          {footerView}
        </>
      )}
    </div>
  );
};

import React from 'react';
import cn from 'classnames';
import { TextEllipsis } from '@ui/TextEllipsis';
import { Type } from '@ui/Type';
import { Icon } from '@ui/Icon';
import { ButtonUnstyled } from '@ui/Button';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { WhatsappBusinessAccount, WhatsappPhone } from '@utils/Data/Whatsapp';
import { renderItemTitle } from '../utils';
import * as css from './PhoneNumberItem.css';
import { getDefaultTab, useCurrentBotId } from '@utils/Routing';
import { truncateWithEllipses } from 'cf-common/src/utils/String/truncateWithEllipses';
import { useHistory } from 'react-router-dom';
import { BotIdToTitle } from '@utils/Data/Bot/useBotsTitlesMap';
import { Tooltip2 } from '@ui/Tooltip2';
import { useShopifyAccount } from '@utils/Integrations/Shopify/useShopifyAccount';

interface PhoneNumberItemProps extends React.HTMLProps<HTMLDivElement> {
  phone: WhatsappPhone;
  currentPhone?: WhatsappPhone;
  account: WhatsappBusinessAccount;
  hovered?: boolean;
  phoneIsVerified: boolean;
  isConfirmed: boolean;
  handlePhoneVerificationClick(phone: WhatsappPhone): void;
  botIdToTitle: BotIdToTitle;
}

export const PhoneNumberItem: React.FC<PhoneNumberItemProps> = ({
  phone,
  account,
  currentPhone,
  hovered,
  phoneIsVerified,
  isConfirmed,
  handlePhoneVerificationClick,
  botIdToTitle,
  ...restProps
}) => {
  const history = useHistory();
  const { t } = useSafeTranslation();
  const phoneBotId = phone.bot_id || '';
  const botId = useCurrentBotId();
  const isPhoneConnectedToOtherBot = phoneBotId && phoneBotId !== botId;
  const { account: shopifyAccount } = useShopifyAccount();
  const isPhoneHasStripeSubscription = [
    'active',
    'forbidden',
    'to_be_cancelled',
  ].includes(phone?.paymentInformation?.status || '');
  const preventConnectPhoneWithStripe =
    shopifyAccount && isPhoneHasStripeSubscription;
  const isCurrentPhone = currentPhone === phone;
  const hasAccessToBot = Boolean(botIdToTitle[phoneBotId]);

  return (
    <Tooltip2
      type="small"
      disabled={!preventConnectPhoneWithStripe}
      boundariesElement="viewport"
      content={t('pages.BotPage.HomeTab.Whatsapp.activeSubscriptionTooltip')}
    >
      {(ref, bind) => (
        <div ref={ref} {...bind}>
          <div
            title={renderItemTitle(phone)}
            className={cn(css.item, {
              [css.itemHovered]: hovered,
              [css.itemDisabled]:
                isPhoneConnectedToOtherBot || preventConnectPhoneWithStripe,
            })}
            data-testid="whatsapp-phone-select__phone-item"
            {...restProps}
          >
            <TextEllipsis>
              <Type
                color={
                  isPhoneConnectedToOtherBot ||
                  isCurrentPhone ||
                  preventConnectPhoneWithStripe
                    ? 'baseSemilight'
                    : phoneIsVerified
                    ? 'black'
                    : 'cobaltSemilight'
                }
                data-testid="whatsapp-phone__number"
              >
                {renderItemTitle(phone)}
              </Type>
              {!phoneIsVerified &&
                !isPhoneConnectedToOtherBot &&
                !preventConnectPhoneWithStripe && (
                  <Type color="cobaltSemilight">
                    ・
                    <ButtonUnstyled
                      className={css.verifyPhone}
                      onClick={() => {
                        handlePhoneVerificationClick(phone);
                      }}
                    >
                      <Type
                        color="blue"
                        data-testid="whatsapp-phone__verify-link"
                      >
                        {t('pages.BotPage.HomeTab.Whatsapp.verifyPhone')}
                      </Type>
                    </ButtonUnstyled>
                  </Type>
                )}
            </TextEllipsis>
            <div className={css.icon}>
              {isCurrentPhone && (
                <Icon
                  icon="check"
                  color="baseSemilight"
                  size="24px"
                  data-testid="check-icon"
                />
              )}
            </div>
          </div>
          <Type
            size="12px"
            color="baseSemilight"
            className={css.phoneSubText}
            as="div"
          >
            {isPhoneConnectedToOtherBot &&
              (!hasAccessToBot ? (
                t('pages.BotPage.HomeTab.Whatsapp.usedByOther')
              ) : (
                <>
                  {t('pages.BotPage.HomeTab.Whatsapp.usedBy')}{' '}
                  <ButtonUnstyled
                    className={css.botLink}
                    onMouseDown={(event) => {
                      event.preventDefault();
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      history.push(getDefaultTab(phoneBotId));
                      (document.activeElement as HTMLDivElement)?.blur?.();
                    }}
                    title={botIdToTitle[phoneBotId || '']}
                  >
                    {truncateWithEllipses(botIdToTitle[phoneBotId || ''], 15)}
                  </ButtonUnstyled>{' '}
                  {t('pages.BotPage.HomeTab.Whatsapp.bot')}
                </>
              ))}
            {preventConnectPhoneWithStripe &&
              t('pages.BotPage.HomeTab.Whatsapp.activeSubscription')}
          </Type>
        </div>
      )}
    </Tooltip2>
  );
};

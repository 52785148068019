import React, { useEffect } from 'react';
import * as css from './MessagesTemplatesTable.css';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { CenteredLoader } from '@ui/Loader';
import { MessagesTemplatesTableView } from './components/MessagesTemplatesTableView';
import { MessagesTemplatesSearchInput } from './components/MessagesTemplatesSearchInput';
import { Flex } from '@ui/Flex';
import { useMessageTemplatesTableItems } from './hooks/useMessageTemplatesTableItems';
import { MessagesTemplatesSort } from './components/MessagesTemplatesSort';
import { useMessageTemplates } from './hooks/useMessageTemplates';
import { useNavigateToTemplate } from './hooks/useNavigateToTemplate';
import { logEvent } from './utils';
import { MessagesTemplatesTableEmptyState } from './components/MessagesTemplatesTableEmptyState';

export const MessagesTemplatesTable: React.FC = () => {
  const { t } = useSafeTranslation();
  const {
    items,
    loading,
    searchString,
    setSearchString,
    setSortMode,
    allItems,
  } = useMessageTemplatesTableItems();
  const {
    removeTemplate,
    createNewTemplate,
    creatingNew,
    cloneTemplate,
    cloning,
  } = useMessageTemplates();
  const { navigateToTemplate } = useNavigateToTemplate();

  useEffect(() => logEvent({ action: 'visit' }), []);

  if (loading || cloning) {
    return <CenteredLoader className={css.loader} />;
  }

  if (!allItems?.length) {
    return <MessagesTemplatesTableEmptyState />;
  }

  return (
    <div className={css.box}>
      <Button
        data-testid="messages-templates_create-button"
        intent="primary"
        onClick={() => {
          logEvent({ action: 'create new template' }, true);
          createNewTemplate();
        }}
        loading={creatingNew}
        disabled={creatingNew}
      >
        {t('pages.MessagesTemplates.Table.createNewButton')}
      </Button>
      <Spacer factor={6} />
      <Flex justifyContent="space-between" alignItems="center">
        <MessagesTemplatesSort setSortMode={setSortMode} />
        <MessagesTemplatesSearchInput
          value={searchString}
          onChange={setSearchString}
        />
      </Flex>
      <Spacer factor={6} />
      <MessagesTemplatesTableView
        items={items}
        onItemClick={navigateToTemplate}
        onDeleteRequest={removeTemplate}
        onCloneRequest={cloneTemplate}
      />
    </div>
  );
};

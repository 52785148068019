import React from 'react';
import { MoreMenuItem } from '@ui/MoreMenu';
import { Button } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { MobileMoreButton } from './MobileMoreButton';
import { WhatsappActionType } from '../hooks/useMoreMenuItems';

interface MenuButtonsProps {
  isConfirmed: boolean;
  whatsappActionToMenuItem: Record<WhatsappActionType, MoreMenuItem>;
}

export const MobileButtons: React.FC<MenuButtonsProps> = ({
  isConfirmed,
  whatsappActionToMenuItem,
}) => {
  if (isConfirmed) {
    const showQrCodeItem =
      whatsappActionToMenuItem[WhatsappActionType.showQrCode];
    return (
      <>
        <Spacer factor={3} />
        <Flex gapFactor={2} fullWidth>
          <Button intent="secondary" onClick={showQrCodeItem.onClick} fullWidth>
            {showQrCodeItem.title}
          </Button>
          <MobileMoreButton
            buttons={[
              whatsappActionToMenuItem[WhatsappActionType.gotoWhatsapp],
              whatsappActionToMenuItem[WhatsappActionType.disconnect],
            ]}
          />
        </Flex>
      </>
    );
  }

  const gotoWhatsapp =
    whatsappActionToMenuItem[WhatsappActionType.gotoWhatsapp];

  return (
    <>
      <Spacer factor={3} />
      <Button intent="secondary" onClick={gotoWhatsapp.onClick} fullWidth>
        {gotoWhatsapp.title}
      </Button>
    </>
  );
};

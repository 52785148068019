import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import React from 'react';
import css from './Footer.css';

interface FooterProps {
  nextButton: {
    content: React.ReactNode;
    onClick: VoidFunction;
  };
  loading?: boolean;
  onGoToPrevClick?: VoidFunction;
}

export const Footer: React.FC<FooterProps> = ({
  nextButton,
  onGoToPrevClick,
  loading,
}) => (
  <Flex className={css.footer} justifyContent="flex-end">
    {onGoToPrevClick && (
      <>
        <Button
          onClick={onGoToPrevClick}
          intent="secondary"
          fitToText
          className={css.backButton}
          loading={loading}
          data-testid="first-session__activate-bot-step__back-button"
        >
          <Icon icon="arrowBack" size="16px" />
        </Button>
        <Spacer horizontalFactor={4} factor={0} />
      </>
    )}
    <Button
      intent="primary"
      loading={loading}
      onClick={nextButton.onClick}
      className={css.nextButton}
      data-testid="first-session__activate-bot-step__connect-page-button"
    >
      {nextButton.content}
    </Button>
  </Flex>
);

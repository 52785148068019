import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex } from '@ui/Flex';
import { propEq } from 'ramda';
import { Tooltip2 } from '@ui/Tooltip2';
import { Type } from '@ui/Type';
import cn from 'classnames';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { Autofocus } from '@ui/Autofocus';
import { Input } from '@ui/Input';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { Spacer } from '@ui/Spacer';
import { MenuItem } from '@ui/Menu';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './FrequentUnrecognizedMessagesItemIntentSelector.css';
import { AiIntentActionItemType, Platform } from '@globals';
import { TFunction } from 'i18next';
import { MOBILE_WIDTH } from '../../../consts';
import { isMobileDevice } from 'cf-common/src/utils/isMobileDevice';
import { Button } from '@ui/Button';
import { TextEllipsis } from '@ui/TextEllipsis';
import { useAiIntents } from '@utils/Data/Ai/Groups';
import {
  aiIntentFragment as AiIntent,
  aiIntentFragment_action_items as AiActionItem,
  aiIntentFragment_ig_action_items_blocks,
} from '@utils/Data/Ai/common/@types/aiIntentFragment';
import { intentsFilter } from '@utils/Data/Ai/Groups/helpers';
import { useIsWindowWidthLess } from '@utils/DOM/useIsWindowWidthLess';

interface TeachYourBotItemIntentSelectorProps {
  selectedIntentId: string | undefined;
  onIntentSelected: (intentId: string) => void;
  autoFocus: boolean;
  platform: Platform;
}

const isItemActionHasAnswer = (item: AiActionItem | null) =>
  item &&
  (item.item_type === AiIntentActionItemType.text
    ? item.text
    : item.blocks?.length);

const intentHasNoEmptyAction = ({
  action,
  ig_action: igAction,
  wa_action: waAction,
}: AiIntent) =>
  action.items?.some(isItemActionHasAnswer) ||
  igAction.items?.some(isItemActionHasAnswer) ||
  waAction.items?.some(isItemActionHasAnswer);

const CONTROL_WIDTH = 131;
const COMMA = ',';

const renderBlockItems = (
  blocks: aiIntentFragment_ig_action_items_blocks[] | null,
  isNeedShowSeparator: boolean,
) => {
  return (blocks || []).map(
    ({ title }, blockIndex, { length: blocksLength }) => (
      <React.Fragment key={title}>
        <div className={css.blockBox}>
          <div key={title} className={css.block}>
            {title}
          </div>
          {isNeedShowSeparator && blockIndex < blocksLength - 1 ? COMMA : ''}
        </div>
        &nbsp;
      </React.Fragment>
    ),
  );
};

function getTypeProps(isActive: boolean) {
  const typeProps = {
    noWrap: true,
    as: 'div' as const,
    color: isActive ? ('white' as const) : ('black' as const),
    size: '15px_DEPRECATED' as const,
    style: {
      pointerEvents: 'none',
    },
  };
  return typeProps;
}

function getReplyText(
  items: (AiActionItem | null)[],
  igActionItems: (AiActionItem | null)[],
  waActionItems: (AiActionItem | null)[],
  t: TFunction,
) {
  return items
    .concat(igActionItems, waActionItems)
    .filter(Boolean)
    .map((item, i, { length }) => {
      const { text, blocks, item_type } = item as AiActionItem;
      const isNeedShowSeparator = i < length - 1;
      return item_type === AiIntentActionItemType.text
        ? `${text}${
            isNeedShowSeparator
              ? ` ${t(
                  'pages.Keywords.FrequentUnrecognizedMessages.item.IntentSelector.or',
                )}`
              : ''
          } `
        : renderBlockItems(blocks, isNeedShowSeparator);
    });
}

export const FrequentUnrecognizedMessagesItemIntentSelector: React.FC<TeachYourBotItemIntentSelectorProps> =
  ({ autoFocus, selectedIntentId, onIntentSelected, platform }) => {
    const { t } = useSafeTranslation();
    const [needFocusToIntent, setNeedFocusToIntent] = useState<boolean>(false);
    const isMobileWindowSize = useIsWindowWidthLess(MOBILE_WIDTH);
    const isMobile = isMobileDevice();
    const { aiIntents, loading } = useAiIntents();

    const itemToString = useCallback(
      (intent: AiIntent | null) => intent?.lines.join(', ') || '',
      [],
    );

    const currentIntents = useMemo(
      () =>
        aiIntents?.filter(
          (intent) =>
            intentsFilter('', platform, intent) &&
            intentHasNoEmptyAction(intent),
        ) || [],
      [aiIntents, platform],
    );

    useEffect(() => {
      if (aiIntents?.length && !selectedIntentId) {
        const id = currentIntents[0]?.id;
        if (id) {
          onIntentSelected(id);
        }
      }
    }, [
      aiIntents,
      selectedIntentId,
      onIntentSelected,
      platform,
      currentIntents,
    ]);

    useEffect(() => {
      setNeedFocusToIntent(true);
    }, []);

    useEffect(() => {
      if (needFocusToIntent) {
        setNeedFocusToIntent(false);
      }
    }, [needFocusToIntent]);

    if (loading) {
      return (
        <Flex alignItems="center">
          <LoadingPlaceholder style={{ width: CONTROL_WIDTH, height: 36 }} />
          <Spacer factor={1} horizontalFactor={2} />
          <LoadingPlaceholder style={{ width: CONTROL_WIDTH, height: 36 }} />
        </Flex>
      );
    }

    return (
      <Flex alignItems="center" className={css.box}>
        <SimpleCombobox<AiIntent>
          downshiftContainerClassName={css.box}
          selectedItem={aiIntents?.find(propEq('id', selectedIntentId)) || null}
          menuItemStyle={{ minWidth: CONTROL_WIDTH }}
          menuBoxClassName={cn(css.intentSelectorDropdown, {
            [css.mobile]: isMobileWindowSize,
          })}
          onSelect={(intent) => {
            if (intent) {
              onIntentSelected(intent.id);
            }
            (document.activeElement as HTMLElement)?.blur?.();
          }}
          itemToString={itemToString}
          renderInput={({
            getInputProps,
            openMenu,
            clearSelection,
            selectedItem,
          }) => (
            <Autofocus
              shouldFocus={needFocusToIntent}
              render={({ bind }) =>
                isMobile ? (
                  <Button
                    {...getInputProps({
                      onFocus: openMenu,
                    })}
                    intent="secondary"
                    className={css.button}
                    {...bind}
                    data-testid="keywords__keywords-groups__choose-intent-button"
                  >
                    <div className={css.buttonContent}>
                      <TextEllipsis>
                        {itemToString(selectedItem) ||
                          t(
                            'pages.Keywords.FrequentUnrecognizedMessages.item.IntentSelector.ChooseIntent',
                          )}
                      </TextEllipsis>
                    </div>
                  </Button>
                ) : (
                  <Input
                    autoFocus={autoFocus}
                    {...getInputProps({
                      placeholder: t(
                        'pages.Keywords.FrequentUnrecognizedMessages.item.IntentSelector.ChooseIntent',
                      ),
                      onFocus: openMenu,
                      onChange: () => {
                        clearSelection();
                      },
                      ...bind,
                    })}
                    data-testid="keywords__keywords-groups__choose-intent-input"
                  />
                )
              }
            />
          )}
          items={currentIntents}
          renderMenuHead={() =>
            isMobileWindowSize ? null : (
              <Flex alignItems="center" className={css.intentSelectorHead}>
                <div>
                  <Type size="15px_DEPRECATED" weight="semibold">
                    {t(
                      'pages.Keywords.FrequentUnrecognizedMessages.item.IntentSelector.Intents',
                    )}
                  </Type>
                </div>
                <div>
                  <Type size="15px_DEPRECATED" weight="semibold">
                    {t(
                      'pages.Keywords.FrequentUnrecognizedMessages.item.IntentSelector.Answers',
                    )}
                  </Type>
                </div>
              </Flex>
            )
          }
          renderItem={({ item, getItemProps, highlightedIndex, index }) => {
            const {
              lines,
              action: { items: actionItems = [] },
              ig_action: { items: igActionItems = [] },
              wa_action: { items: waActionItems = [] },
            } = item;
            const isActive = index === highlightedIndex;
            const typeProps = getTypeProps(isActive);
            const linesText = lines.join(', ');
            const reply = getReplyText(
              actionItems,
              igActionItems,
              waActionItems,
              t,
            );
            return (
              <MenuItem
                key={item.id}
                {...getItemProps({ item })}
                active={isActive}
                className={css.intentMenuItem}
                titleElement={
                  <Flex alignItems="center" className={css.intentSelectorItem}>
                    <Tooltip2
                      content={
                        <Type size="15px_DEPRECATED" color="white">
                          {linesText}
                        </Type>
                      }
                      placement="bottom"
                      type="small"
                      boundariesElement="viewport"
                      className={css.tooltip}
                      disabled={isMobile}
                    >
                      {(ref, bind) => (
                        <div
                          ref={ref}
                          data-testid="keywords__keywords-groups__choose-intent-menu-item-keyword-group"
                          {...bind}
                        >
                          <Type {...typeProps}>{linesText}</Type>
                        </div>
                      )}
                    </Tooltip2>
                    {!isMobileWindowSize && (
                      <Tooltip2
                        content={
                          <Type size="15px_DEPRECATED" color="white">
                            {reply}
                          </Type>
                        }
                        placement="bottom"
                        type="small"
                        boundariesElement="viewport"
                        className={css.tooltip}
                        disabled={isMobile}
                      >
                        {(ref, bind) => (
                          <Flex
                            ref={ref}
                            data-testid="keywords__keywords-groups__choose-intent-menu-item-reply"
                            {...bind}
                          >
                            <Type {...typeProps}>{reply}</Type>
                          </Flex>
                        )}
                      </Tooltip2>
                    )}
                  </Flex>
                }
              />
            );
          }}
        />
      </Flex>
    );
  };

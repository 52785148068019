import React from 'react';
import { Spacer } from '@ui/Spacer';
import { useBillingData } from '../Data/useBillingData';
import { useBotSectionData } from '../Data/useBotSectionData';
import { BotSectionBodyView } from '../BotSectionBodyView';
import { BotSectionHeader } from '../BotSectionHeader';
import { usePaymentData } from '../Data/usePaymentData';
import { BotPanelLoaderView } from '../BotPanelLoaderView';
import { BotPanelErrorView } from '../BotPanelErrorView';
import { WorkspaceBotSectionBodyView } from '../WorkspaceBotSectionBodyView';
import * as css from './DialogsPricingBotPanelView.css';

export interface DialogsPricingBotPanelViewProps {
  loading?: boolean;
}

export const DialogsPricingBotPanelView: React.FC<DialogsPricingBotPanelViewProps> =
  ({ loading }) => {
    const { loading: billingLoading, pricingError } = useBillingData({
      fetchPolicy: 'network-only',
    });
    const { bot, loading: botLoading } = useBotSectionData({
      fetchPolicy: 'network-only',
    });
    const { loading: paymentDataLoading } = usePaymentData();

    if (billingLoading || botLoading || paymentDataLoading || loading) {
      return <BotPanelLoaderView className={css.loader} />;
    }

    const getBotSectionBody = () => {
      if (bot?.workspace_id) {
        return <WorkspaceBotSectionBodyView workspaceId={bot?.workspace_id} />;
      }

      if (pricingError) {
        return <BotPanelErrorView />;
      }

      return <BotSectionBodyView />;
    };

    return (
      <>
        <BotSectionHeader headerClassName={css.header} />

        <Spacer factor={5} />

        {getBotSectionBody()}
      </>
    );
  };

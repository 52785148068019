import React from 'react';
import cn from 'classnames';
import { Icon, IconProps } from '@ui/Icon';
import { Anchor } from '@ui/Links';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { ButtonUnstyled } from '@ui/Button';
import { useCopyText } from '@utils/Copy';
import * as css from './MessengerLink.css';

export interface MessengerLinkProps {
  icon: IconProps['icon'];
  href: string;
  onCopyClick: () => void;
  onClick: () => void;
  className?: string;
}

export const MessengerLink: React.FC<MessengerLinkProps> = ({
  children,
  href,
  icon,
  onCopyClick,
  onClick,
  className,
}) => {
  const { copy } = useCopyText();

  return (
    <Flex alignItems="center" className={cn(css.wrapper, className)}>
      <Flex alignItems="center" justifyContent="center" className={css.iconBox}>
        <ButtonUnstyled
          onClick={() => {
            copy(href);
            onCopyClick();
          }}
          className={css.duplicate}
        >
          <Icon icon="duplicate" size="24px" />
        </ButtonUnstyled>
        <Icon icon={icon} size="24px" className={css.icon} />
      </Flex>

      <Spacer horizontalFactor={2} />

      <Anchor
        data-testid="bot-panel__messenger-link"
        intent="external"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        hideArrow
        colorfulBg
        onClick={onClick}
      >
        <Type size="15px" weight="medium">
          {children}
        </Type>
      </Anchor>
    </Flex>
  );
};

import React from 'react';
import {
  FacebookMessengerBotLinkBase,
  InstagramBotLinkBase,
} from '@components/BotLink/BotLink';
import { useWhatsappConnected } from '@utils/Data/Whatsapp';
import { removeNonDigits } from '@utils/String/removeNonDigits';
import {
  MessengerLink,
  MessengerLinkProps,
} from '../MessengerLink/MessengerLink';

interface LinkProps extends Omit<MessengerLinkProps, 'href' | 'icon'> {
  botId: string;
}

export const InstagramLink: React.FC<LinkProps> = ({ botId, ...props }) => (
  <InstagramBotLinkBase botId={botId}>
    {({ href }) => (
      <MessengerLink href={href} icon="instagramFilled" {...props}>
        ig.me
      </MessengerLink>
    )}
  </InstagramBotLinkBase>
);

export const FacebookLink: React.FC<LinkProps> = ({ botId, ...props }) => (
  <FacebookMessengerBotLinkBase botId={botId}>
    {({ href }) => (
      <MessengerLink href={href} icon="facebookFilled" {...props}>
        m.me
      </MessengerLink>
    )}
  </FacebookMessengerBotLinkBase>
);

export const WhatsappLink: React.FC<LinkProps> = ({ botId, ...props }) => {
  const { whatsappSettings } = useWhatsappConnected(botId);

  if (!whatsappSettings?.phone?.display_phone_number) return null;

  const phoneUrl = `https://wa.me/${removeNonDigits(
    whatsappSettings.phone.display_phone_number,
  )}`;

  return (
    <MessengerLink href={phoneUrl} icon="whatsapp" {...props}>
      wa.me
    </MessengerLink>
  );
};

export enum PlanButtonType {
  timeLimitedTrialExpired,
  updateCard,
  paused,
  withoutPage,
  limitReached,
  offerSubscription,
  default,
  none,
}

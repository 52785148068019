import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import {
  CancelShopifySubscriptionMutation,
  CancelShopifySubscriptionMutationVariables,
} from './@types/CancelShopifySubscriptionMutation';
import { useCurrentBotId } from '@utils/Routing';

const CANCEL_SHOPIFY_SUBSCRIPTION_MUTATION = gql`
  mutation CancelShopifySubscriptionMutation($botId: ID!) {
    cancelShopifySubscription(botId: $botId) {
      id
      connectedShop {
        id
        subscription {
          status
          confirmationUrl
          trialDays
          price
          currency
          trialEndsOn
        }
      }
    }
  }
`;

export const useCancelShopifySubscription = () => {
  const botId = useCurrentBotId()!;
  const [cancelShopifySubscription, queryState] = useMutation<
    CancelShopifySubscriptionMutation,
    CancelShopifySubscriptionMutationVariables
  >(CANCEL_SHOPIFY_SUBSCRIPTION_MUTATION, {
    variables: {
      botId,
    },
  });
  return { cancelShopifySubscription, ...queryState };
};

import React, { useMemo } from 'react';
import { Input } from '@ui/Input';
import { Flex } from '@ui/Flex';
import { IconButton } from '@ui/IconButton';
import { Spacer } from '@ui/Spacer';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import {
  TextWithAttributesEditor,
  deserialize,
  getFullAttributesBoundaries,
  wrapAllAttributes,
} from '@ui/TextWithAttributesEditor';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Editor } from 'slate-react';
import { insertText } from '@utils/Slite/insertText';
import { useExtendedFormAction } from '../../form/ExtendFormActionsContext';
import { AttributeExample } from '../AttributeExample';
import { useAttributes } from '@utils/AttributesUtils/AttributesData';
import { useCurrentBotId } from '@utils/Routing';
import { Platform } from '@globals';
import { VariableSuggestType } from '@utils/AttributesUtils/AttributesUtilsTypes';
import * as css from './MessagesTemplateHeaderText.css';
import { getAttributeExamples } from '../../form/utils';

export const MAX_HEADER_TEXT = 60;

export const MessagesTemplateHeaderText = () => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  const { attributes } = useAttributes(
    botId,
    VariableSuggestType.template,
    Platform.whatsapp,
  );
  const {
    values,
    errors,
    clearHeader,
    formDisabled,
    saveForm,
    setHeaderText,
    setHeaderAttributeExamples,
    updateHeaderAttributeExamples,
    templateLanguage,
  } = useExtendedFormAction();

  const value = values.content.header.text || '';
  const attributesAmount = getFullAttributesBoundaries(value).length;
  const attributeExamples = values.content.header.attribute_examples || [];

  const currentTextLimit = useMemo(
    () => MAX_HEADER_TEXT - wrapAllAttributes(value, () => '').trim().length,
    [value],
  );

  return (
    <Flex flexDirection="column">
      <Flex fullWidth>
        <TextOutsideControls
          onInsertRequest={(text, el) => {
            insertText(text, el as Editor);
          }}
          currentTextLimit={currentTextLimit}
          shouldShowOutsideControls={{
            emoji: true,
            symbolsLimit: true,
            attributes: attributesAmount === 0,
          }}
          initialShow={false}
          boxStyle={{ width: '100%' }}
          tight
        >
          {({ ref, getInputProps }) => (
            <Input
              disabled={formDisabled}
              error={errors.content?.header?.text}
              render={() => (
                <Flex alignItems="center">
                  <TextWithAttributesEditor
                    placeholder={t(
                      'pages.MessagesTemplates.message.header.placeholder',
                      { lang: templateLanguage?.display_name },
                    )}
                    className={css.input}
                    autoFocus
                    defaultValue={deserialize(value || '')}
                    attributes={attributes}
                    maxLength={MAX_HEADER_TEXT}
                    editorRef={ref}
                    hasManageAttributes
                    onFocus={getInputProps().onFocus}
                    onStringChange={setHeaderText}
                    onKeyDown={(event) => {
                      const { key, shiftKey } = event as KeyboardEvent;
                      if (key === 'Enter' && !shiftKey) {
                        ref.current?.blur();
                        return false;
                      }
                      return undefined;
                    }}
                    onBlur={(event, value) => {
                      const newValues = updateHeaderAttributeExamples(
                        getAttributeExamples(value),
                      );
                      (getInputProps().onBlur as Function)(event);
                      saveForm(newValues);
                    }}
                    data-testid="messages-template-message__header-text-input"
                  />
                </Flex>
              )}
            />
          )}
        </TextOutsideControls>
        <Spacer factor={0} horizontalFactor={2} />
        <IconButton
          color="blue"
          disabled={formDisabled}
          icon="delete"
          onClick={() => {
            saveForm(clearHeader());
          }}
          data-testid="messages-template-message__delete-header-text"
        />
      </Flex>

      {attributeExamples.length > 0 && (
        <>
          <Spacer factor={3} />
          <AttributeExample
            errors={errors.content?.header?.attribute_examples}
            disabled={formDisabled}
            attributeExamples={attributeExamples}
            onExampleChange={setHeaderAttributeExamples}
          />
        </>
      )}
    </Flex>
  );
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import { BotTabPlaceholder } from '../../../../modern-components/BotTab';
import {
  Button,
  ButtonColorWay,
  ButtonIntent,
} from '../../../../modern-ui/_deprecated/Button';
import { ReactComponent as AddIcon } from '../../../../modern-ui/_deprecated/Icon/icons/ic_add_small.svg';
import { ReactComponent as RocketIcon } from '../../../../assets/zero-state/rocket.svg';
import { FlowElementType } from '../FlowList/types';

export const EmptyTabPlaceholder: React.FC = () => {
  const history = useHistory();

  return (
    <BotTabPlaceholder
      icon={<RocketIcon />}
      title={window.i18next.t(
        'EmptyTabPlaceholder-string--324-time-to-build-something-awesome',
      )}
      subtitle={
        <>
          {window.i18next.t(
            'EmptyTabPlaceholder-JSXText-1532-create-a-new-flow-or-copy-an-existing-flow-to-get',
          )}
          <br />
          {window.i18next.t('EmptyTabPlaceholder-JSXText--194-started')}
        </>
      }
      renderFooter={() => (
        <Button
          intent={ButtonIntent.primary}
          colorWay={ButtonColorWay.blue}
          renderIcon={() => <AddIcon />}
          onClick={() => {
            history.push({
              search: `?createdElementType=${FlowElementType.flow}`,
            });
          }}
        >
          {window.i18next.t('EmptyTabPlaceholder-JSXText--199-add-flow')}
        </Button>
      )}
    />
  );
};

import React, { useEffect, useState } from 'react';
import { ActionButtons } from '@components/BotList/components/ActionButtons';
import { getUser } from '@components/FlowBuilder/api/network';
import { isWhiteLabelAgencyUser } from '@utils/WhiteLabelUtils';
import { Platform } from '@globals';

interface DashboardTabsReactProps {
  hideSearch: boolean;
  search: string;
  changeSearch: (newSearch: string) => void;
  disabled: boolean;
  hide: boolean;
  onCreateBlankBot: (platforms?: Platform[]) => void;
}

export const DashboardButtons: React.FC<DashboardTabsReactProps> = ({
  search,
  changeSearch,
  hideSearch,
  disabled,
  hide,
  onCreateBlankBot,
}) => {
  const [isWhiteLabelUser, setIsWhiteLabelUser] = useState(false);

  useEffect(() => {
    async function getUserData() {
      try {
        const user = await getUser();
        setIsWhiteLabelUser(isWhiteLabelAgencyUser(user));
      } catch (err) {
        window.console.log(err);
      }
    }
    getUserData();
  }, []);

  if (isWhiteLabelUser) {
    return null;
  }

  return (
    <ActionButtons
      search={search}
      changeSearch={changeSearch}
      hideSearch={hideSearch}
      disabled={disabled}
      hide={hide}
      onCreateBlankBot={onCreateBlankBot}
    />
  );
};

import React from 'react';
import { Type } from '@ui/Type';
import { formatPrice } from '@utils/Format';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { usePaymentData } from '../../BotSectionView/Data/usePaymentData';

export const FailedPaymentChargeDetails: React.FC = () => {
  const { t } = useSafeTranslation();
  const { paymentError, paymentInfo } = usePaymentData();

  if (!paymentError || !paymentInfo) {
    return null;
  }

  const amount = formatPrice(paymentError.amountToPay, {
    currency: paymentInfo?.currency,
  });

  return (
    <Type
      size="15px"
      color="red"
      weight="semibold"
      data-testid="bot-panel__failed-payment-details"
    >
      {t('pages.Billing.history.error.amountToPay', {
        amountToPay: amount,
      })}
    </Type>
  );
};

import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { GetMessageTemplateLanguages } from './@types/GetMessageTemplateLanguages';

export const GET_MESSAGE_TEMPLATE_LANGUAGES = gql`
  query GetMessageTemplateLanguages {
    messageTemplateLanguages {
      id
      language
      display_name
      opt_out_button_text
      opt_out_footer_text
    }
  }
`;

export const useGetMessageTemplateLanguages = () => {
  const queryResult = useQuery<GetMessageTemplateLanguages>(
    GET_MESSAGE_TEMPLATE_LANGUAGES,
  );

  return queryResult;
};

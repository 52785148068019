import React from 'react';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Subscription, Title, Ul } from './Primitives';
import * as css from './ComplexTitleView.css';

export interface ComplexTitleViewProps extends TestLocator {
  price: string;
  parts: Array<{ price: string }>;
}

export const ComplexTitleView: React.FC<ComplexTitleViewProps> = ({
  price,
  parts,
  ...props
}) => (
  <Flex flexDirection="column">
    <Title data-testid={props['data-testid']}>{price}</Title>
    <Ul>
      {parts.map(({ price }) => (
        <li className={css.listItem}>
          <Spacer factor={7} />
          <Subscription>{price}</Subscription>
        </li>
      ))}
    </Ul>
  </Flex>
);

import React from 'react';
import {
  RadioButton as BaseRadioButton,
  RadioButtonProps as BaseRadioButtonProps,
} from '@ui/Radio';
import css from './RadioButton.css';

interface RadioButtonProps extends BaseRadioButtonProps {}

export const RadioButton: React.FC<RadioButtonProps> = (props) => {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={props.className}>
      <BaseRadioButton {...props} className={css.radioButton} />
    </label>
  );
};

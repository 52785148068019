import React from 'react';
import { Callout } from '@ui/Callout';
import * as css from './BotStatusCalloutView.css';

interface BotStatusCalloutViewProps {
  visible: boolean;
  button: React.ReactNode;
  text: React.ReactNode;
}

export const BotStatusCalloutView: React.FC<BotStatusCalloutViewProps> = ({
  visible,
  button,
  text,
}) => {
  return (
    <div className={css.box}>
      {visible && (
        <Callout
          iconDisabled
          type="warning"
          text={text}
          button={button}
          className={css.panel}
        />
      )}
    </div>
  );
};

import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useScrollIntoView } from 'cf-common/src/utils/DOM/useScrollIntoView';
import { isMobileDevice } from 'cf-common/src/utils/isMobileDevice';
import { usePrevious } from 'cf-common/src/utils/hooks';

interface UseFirstSessionStepArgs {
  done: boolean;
  current: boolean;
  enabled: boolean;
  onDone: () => void;
}

export const useFirstSessionStepState = ({
  done,
  current,
  onDone,
  enabled,
}: UseFirstSessionStepArgs) => {
  const [expand, setExpand] = useState(false);
  const [completed, setCompleted] = useState(false);
  const { scrollToTarget, scrollTargetRef } = useScrollIntoView({
    block: 'end',
    scrollMode: 'if-needed',
  });

  const stepDoneHandler = useCallback(() => {
    if (!done) {
      onDone();
      setCompleted(true);
      setExpand(false);
    }
  }, [done, onDone]);

  const stepBoxClickHandler = useCallback(() => {
    if (enabled && !expand) {
      setExpand(true);
    }
  }, [enabled, expand]);

  const prevCurrent = usePrevious(current);
  useLayoutEffect(() => {
    if (current && !prevCurrent) {
      setTimeout(() => {
        scrollToTarget();
      });
    }
  }, [current, prevCurrent, scrollToTarget]);

  useEffect(() => {
    if (!expand && current && !completed) {
      setExpand(true);
    }
  }, [completed, current, expand]);

  return {
    expand: expand || isMobileDevice(),
    scrollTargetRef,
    stepDoneHandler,
    stepBoxClickHandler,
  };
};

import { useMemo, useRef } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useCurrentBotId, useCurrentContactId } from '@utils/Routing';
import {
  createOrUpdateContactAttribute,
  createOrUpdateContactAttributeVariables,
} from './@types/createOrUpdateContactAttribute';
import {
  deleteContactAttribute,
  deleteContactAttributeVariables,
} from './@types/deleteContactAttribute';
import debounce from 'lodash-es/debounce';
import { useApolloClient } from 'react-apollo';
import { contactFragment } from './@types/contactFragment';

const CREATE_OR_UPDATE_CONTACT_ATTRIBUTE = gql`
  mutation createOrUpdateContactAttribute(
    $botId: ID!
    $attribute: ContactCustomAttrInput!
    $contactId: ID!
  ) {
    createOrUpdateCustomAttribute(
      botId: $botId
      attribute: $attribute
      contactId: $contactId
    ) {
      id
      customAttributes {
        name
        value
      }
      systemAttributes {
        name
        value
      }
    }
  }
`;

const DELETE_CONTACT_ATTRIBUTE = gql`
  mutation deleteContactAttribute(
    $botId: ID!
    $attributeName: String!
    $contactId: ID!
  ) {
    deleteCustomAttribute(
      name: $attributeName
      botId: $botId
      contactId: $contactId
    ) {
      id
      customAttributes {
        name
        value
      }
    }
  }
`;

const CONTACT_CUSTOM_ATTRIBUTES_FRAGMENT = gql`
  fragment contactFragment on ContactProfile {
    id
    customAttributes {
      name
      value
    }
    systemAttributes {
      name
      value
    }
  }
`;

export const useContactAttributes = () => {
  const botId = useCurrentBotId();
  const contactId = useCurrentContactId();
  const client = useApolloClient();

  const [createOrUpdateMutation] = useMutation<
    createOrUpdateContactAttribute,
    createOrUpdateContactAttributeVariables
  >(CREATE_OR_UPDATE_CONTACT_ATTRIBUTE);

  const [deleteMutation] = useMutation<
    deleteContactAttribute,
    deleteContactAttributeVariables
  >(DELETE_CONTACT_ATTRIBUTE);

  const updateAttributeCache = useMemo(() => {
    return (attribute: { name: string; value: string }) => {
      client.writeData({
        id: `ContactCustomAttr:${attribute.name}`,
        data: attribute,
      });
    };
  }, [client]);

  const createOrUpdateAttribute = useMemo(() => {
    return (attribute: { name: string; value: string }) => {
      const contact = client.readFragment<contactFragment>({
        id: `ContactProfile:${contactId}`,
        fragment: CONTACT_CUSTOM_ATTRIBUTES_FRAGMENT,
      })!;

      const oldAttributes = contact.customAttributes;
      const isUpdate = oldAttributes.find((ca) => ca.name === attribute.name);

      return createOrUpdateMutation({
        variables: {
          botId: botId!,
          contactId: contactId!,
          attribute,
        },
        optimisticResponse: {
          createOrUpdateCustomAttribute: {
            ...contact,
            customAttributes: isUpdate
              ? oldAttributes.map((ca) =>
                  ca.name !== attribute.name
                    ? ca
                    : { ...attribute, __typename: 'ContactCustomAttr' },
                )
              : [
                  ...oldAttributes,
                  { ...attribute, __typename: 'ContactCustomAttr' },
                ],
          },
        },
      });
    };
  }, [botId, client, contactId, createOrUpdateMutation]);

  const deleteAttribute = useMemo(() => {
    return (attributeName: string) => {
      const contact = client.readFragment<contactFragment>({
        id: `ContactProfile:${contactId}`,
        fragment: CONTACT_CUSTOM_ATTRIBUTES_FRAGMENT,
      })!;

      return deleteMutation({
        variables: {
          botId: botId!,
          contactId: contactId!,
          attributeName,
        },
        optimisticResponse: {
          deleteCustomAttribute: {
            ...contact,
            customAttributes: contact.customAttributes.filter(
              (ca) => ca.name !== attributeName,
            ),
          },
        },
      });
    };
  }, [botId, client, contactId, deleteMutation]);

  const mapRef = useRef(new Map<string, any>());

  const syncAttribute = useMemo(() => {
    return (attribute: { name: string; value: string }) => {
      const map = mapRef.current;
      if (!map.has(attribute.name)) {
        map.set(
          attribute.name,
          debounce(
            (attribute: { name: string; value: string }) =>
              createOrUpdateMutation({
                variables: {
                  botId: botId!,
                  contactId: contactId!,
                  attribute,
                },
                fetchPolicy: 'no-cache',
              }),
            500,
          ),
        );
      }
      map.get(attribute.name)(attribute);
    };
  }, [botId, contactId, createOrUpdateMutation]);

  return {
    createOrUpdateAttribute,
    updateAttributeCache,
    deleteAttribute,
    syncAttribute,
  };
};

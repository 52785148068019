import { useEffect } from 'react';
import flatMap from 'lodash-es/flatMap';
import { useUpdateEntryPointsAdded } from '@utils/Intercom/entryPointsAdded';
import { FlowsQuery_bot_flows } from '@utils/Data/Flow/@types/FlowsQuery';

export const useIntercomEntryPointsAddedSync = (
  flows?: FlowsQuery_bot_flows[] | null,
) => {
  const updateIntercomEntryPointsAdded = useUpdateEntryPointsAdded();
  useEffect(() => {
    if (!flows) return;
    const entryPoints = flatMap(flows, (flow) => flow?.entry_points);

    const pluginIds = entryPoints
      .filter((entryPoint) => !!entryPoint?.entry_point_type)
      .map((entryPoint) => entryPoint!.entry_point_type!);

    const uniquePluginIds = new Set(pluginIds);

    updateIntercomEntryPointsAdded(...uniquePluginIds);
  }, [flows, updateIntercomEntryPointsAdded]);
};

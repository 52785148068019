import React from 'react';
import cn from 'classnames';
import { Translation } from '@translations';
import { Flex } from '@ui/Flex';
import { Type, TypeTranslation } from '@ui/Type';
import { WhatsappBroadcastStats } from '../../../type';
import css from './Stats.css';
import { Spacer } from '@ui/Spacer';

interface StatsProps {
  className: string;
  stats: WhatsappBroadcastStats;
  showProgress: boolean;
}

const Column: React.FC<{ i18nKey: Translation }> = ({ i18nKey, children }) => (
  <Flex
    flexDirection="column"
    alignItems="center"
    className={css.column}
    data-testid="whatsapp-reengage__reengage-table__column"
  >
    <TypeTranslation
      color="greyDark"
      weight="semibold"
      size="12px"
      className={css.title}
      i18nKey={i18nKey}
    />
    {children}
  </Flex>
);

export const Stats: React.FC<StatsProps> = ({
  className,
  stats,
  showProgress,
}) => {
  const { totalUsers, successUsers, errorUsers, readUsers, clickedUsers } =
    stats;
  const statsList: { textPath: Translation; value: number; percent: number }[] =
    [
      {
        textPath: 'pages.BotPage.ReengageWhatsappTab.stats.read',
        value: readUsers,
        percent: totalUsers ? (readUsers / totalUsers) * 100 : totalUsers,
      },
      {
        textPath: 'pages.BotPage.ReengageWhatsappTab.stats.clicked',
        value: clickedUsers,
        percent: totalUsers ? (clickedUsers / totalUsers) * 100 : totalUsers,
      },
      {
        textPath: 'pages.BotPage.ReengageWhatsappTab.stats.failed',
        value: errorUsers,
        percent: totalUsers ? (errorUsers / totalUsers) * 100 : totalUsers,
      },
    ];

  const progress = totalUsers
    ? ((successUsers + errorUsers) / totalUsers) * 100
    : totalUsers;

  return (
    <Flex className={cn(css.container, className)}>
      {progress < 100 && showProgress && (
        <Column i18nKey="pages.BotPage.ReengageWhatsappTab.stats.progress">
          <Spacer factor={2} />
          <Type
            color="baseTertiary"
            data-testid="whatsapp-reengage__reengage-stats__progress"
          >
            {Math.trunc(progress)}%
          </Type>
        </Column>
      )}
      <Column i18nKey="pages.BotPage.ReengageWhatsappTab.stats.recipients">
        <Spacer factor={2} />
        <Type
          color="baseNormal"
          data-testid="whatsapp-reengage__reengage-stats__recipients"
        >
          {totalUsers}
        </Type>
      </Column>
      {statsList.map(({ textPath, value, percent }) => (
        <Column key={textPath} i18nKey={textPath}>
          <Type
            color="baseNormal"
            data-testid="whatsapp-reengage__reengage-stats__percentage"
          >
            {Math.trunc(percent)}%
          </Type>
          <Type
            color="baseTertiary"
            data-testid="whatsapp-reengage__reengage-stats__absolute-value"
          >
            {value}
          </Type>
        </Column>
      ))}
    </Flex>
  );
};

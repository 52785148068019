import { useMutation, useQuery } from 'react-apollo';
import {
  WHATSAPP_NOTIFY_BOT_SUBSCRIPTION_REQUISITES_MUTATION,
  WHATSAPP_NOTIFY_BOT_SUBSCRIPTION_STATE_QUERY,
} from './queries';
import { WhatsappNotifyBotSubscriptionRequisitesMutation } from './@types/WhatsappNotifyBotSubscriptionRequisitesMutation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQrCodeUrl } from '@utils/QrCodes/useQrCodeUrl';
import { removeNonDigits } from '@utils/String/removeNonDigits';
import { WhatsappNotifyBotSubscriptionStateQuery } from './@types/WhatsappNotifyBotSubscriptionStateQuery';
import { log } from 'cf-common/src/logger';
import { showSomethingWentWrongToaster } from '@services/MessageService';

const SUBSCRIPTION_STATE_POLL_INTERVAL = 1000;

export const useNotifyBotSubscription = () => {
  const [isBotSubscribed, setIsBotSubscribed] = useState(false);
  const [getSubscriptionRequisites, { data: requisitesData, loading, called }] =
    useMutation<WhatsappNotifyBotSubscriptionRequisitesMutation>(
      WHATSAPP_NOTIFY_BOT_SUBSCRIPTION_REQUISITES_MUTATION,
      {
        onError: (error) => {
          if (error?.graphQLErrors?.[0].message !== 'NO_FB_TOKEN') {
            log.error({
              error,
              msg: 'Get whatsapp notify bot subscription requisites error',
            });
            showSomethingWentWrongToaster();
          }
        },
      },
    );

  const { data: stateData } = useQuery<WhatsappNotifyBotSubscriptionStateQuery>(
    WHATSAPP_NOTIFY_BOT_SUBSCRIPTION_STATE_QUERY,
    {
      pollInterval: isBotSubscribed ? 0 : SUBSCRIPTION_STATE_POLL_INTERVAL,
    },
  );

  const displayPhoneNumber =
    requisitesData?.getWhatsappNotifyBotSubscriptionRequisites
      .displayPhoneNumber || null;
  const signUpCode =
    requisitesData?.getWhatsappNotifyBotSubscriptionRequisites.signUpCode;

  const connectUrl = useMemo(
    () =>
      displayPhoneNumber &&
      `https://wa.me/${removeNonDigits(
        displayPhoneNumber,
      )}?${new URLSearchParams({
        text: signUpCode || '',
      }).toString()}`,
    [displayPhoneNumber, signUpCode],
  );

  const openConnectUrl = useCallback(() => {
    if (!connectUrl) {
      return;
    }
    window.open(connectUrl, '_blank');
  }, [connectUrl]);

  const { qrCodeUrl } = useQrCodeUrl(connectUrl);

  useEffect(() => {
    if (
      isBotSubscribed ||
      !stateData?.whatsappNotifyBotSubscriptionState.subscribed
    ) {
      return;
    }
    setIsBotSubscribed(true);
  }, [isBotSubscribed, stateData]);

  useEffect(() => {
    if (called) {
      return;
    }
    getSubscriptionRequisites();
  }, [called, getSubscriptionRequisites]);

  return {
    ...requisitesData?.getWhatsappNotifyBotSubscriptionRequisites,
    qrCodeUrl,
    loading: loading || !called,
    isBotSubscribed,
    openConnectUrl,
    refetchSubscriptionRequisites: getSubscriptionRequisites,
  };
};

import { usePlanLimitReached, useTiersData } from '@utils/Data/Pricing';
import { log } from 'cf-common/src/logger';
import { useCurrentBotId } from '@utils/Routing';
import {
  DialogsPricingQuery,
  DialogsPricingQueryVariables,
} from '@utils/Data/Pricing/@types/DialogsPricingQuery';
import { PricingPlanTiersQuery_bot_pricingPlanTiers as Tier } from '@utils/Data/Pricing/@types/PricingPlanTiersQuery';
import { PlanLimitReachedData } from '@utils/Data/Pricing/hooks';
import { getMoreExpensiveTier } from '@utils/Data/Pricing/utils';
import Maybe from 'graphql/tsutils/Maybe';
import { QueryHookOptions } from 'react-apollo';
import { useBotProStatus } from '@utils/Data/Bot';
import {
  DialoguesPricingDataProps,
  useDialoguesPricingData,
} from './useDialoguesPricingData';

interface PlanProps {
  planLimitReached: Maybe<PlanLimitReachedData>;
}

export interface TierProps {
  tier: Tier | undefined;
  moreExpensiveTier: Tier | null;
  tiers: Array<Tier> | undefined;
}

export interface PricingProps extends DialoguesPricingDataProps {
  isLastTier: boolean;
  tillDate: Maybe<number>;
}

interface BillingDataPayload extends TierProps, PricingProps, PlanProps {
  loading: boolean;
  lastTier: Maybe<Tier>;
}

export const useBillingData = (
  options?: QueryHookOptions<DialogsPricingQuery, DialogsPricingQueryVariables>,
): BillingDataPayload => {
  const pricingData = useDialoguesPricingData(options);
  const { pricing, isPlanChanged, pricingLoading } = pricingData;
  const planLimitReached = usePlanLimitReached(
    {
      onError: (error) => {
        log.error({ msg: 'Cannot load planLimit data', error });
      },
    },
    true,
  );
  const botId = useCurrentBotId()!;
  const { tiersLoading, tiers, getTier } = useTiersData(
    botId,
    pricing?.pricing_plan_id,
    pricing?.currency,
    {
      onError: (error) => {
        log.error({ msg: 'Cannot load tiers data', error });
      },
    },
  );
  const { loading: botProStatusLoading, proMillisLeft } = useBotProStatus();

  const { current_tier, next_tier, next_prepayment_date } = pricing || {};

  const tier = getTier(current_tier);

  const moreExpensiveTier = getMoreExpensiveTier(tiers, current_tier);

  const lastTier = tiers.length ? tiers[tiers.length - 1] : null;
  const isLastTier = [current_tier, next_tier].includes(lastTier?.type);

  const proMillisLeftDate = proMillisLeft ? Date.now() + proMillisLeft : null;
  const tillDate = isPlanChanged ? next_prepayment_date : proMillisLeftDate;

  const loading = pricingLoading || tiersLoading || botProStatusLoading;

  const value: BillingDataPayload = {
    tier,
    moreExpensiveTier,
    tiers,
    loading,
    planLimitReached,
    isLastTier,
    tillDate,
    lastTier,
    ...pricingData,
  };

  return value;
};

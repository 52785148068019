import React from 'react';
import { BotStatistic } from '../BotStatistic/BotStatistic';
import { PlanSummary } from '../PlanSummary/components/PlanSummary';
import { PlanButton } from './PlanButton';
import { BotSectionBodyLayout } from './BotSectionBodyLayout';
import * as css from './BotSectionBodyView.css';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { useWhatsappConnected } from '@utils/Data/Whatsapp';
import { useCurrentBotId } from '@utils/Routing';
import { useShopifyAccount } from '@utils/Integrations/Shopify/useShopifyAccount';
import { PlanBadge } from '../PlanSummary/components/PlanBadge';
import { Flex } from '@ui/Flex';

export const BotSectionBodyView = () => {
  const botId = useCurrentBotId();
  const { isWhatsappEnabled, loading: enabledLoading } = useWhatsappEnabled();
  const { hasShopifyAccount, loading: accountLoading } = useShopifyAccount();
  const { isConnected, loading: connectedLoading } =
    useWhatsappConnected(botId);
  const needShowPlanButton =
    !enabledLoading &&
    !connectedLoading &&
    !accountLoading &&
    !hasShopifyAccount &&
    (!isWhatsappEnabled || isConnected);

  return (
    <div>
      {!accountLoading && !hasShopifyAccount ? (
        <PlanSummary className={css.planSummary} />
      ) : (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          className={css.planSummary}
        >
          <PlanBadge className={css.planBadge} />
        </Flex>
      )}
      <BotSectionBodyLayout>
        <BotStatistic />
        {needShowPlanButton && <PlanButton />}
      </BotSectionBodyLayout>
    </div>
  );
};

import React, { useCallback, useImperativeHandle, useState } from 'react';
import { Input } from '@ui/Input';
import { ButtonUnstyled } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { TextEllipsis } from '@ui/TextEllipsis';
import { FontSizeKey, Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './IntentName.css';
import { Spacer } from '@ui/Spacer';
import { Icon } from '@ui/Icon';
import { IconButton } from '@ui/IconButton';

interface IntentNameProps {
  value: string;
  isNewAiIntentInit: boolean;
  onChange: (value: string) => void;
  expand?: boolean;
  onClick?: () => void;
  'data-testid'?: string;
  fontSize?: FontSizeKey;
  showEditButton?: boolean;
}

export interface IntentNameInstance {
  startRename: () => void;
}

export const IntentName = React.forwardRef<IntentNameInstance, IntentNameProps>(
  (
    {
      isNewAiIntentInit,
      expand,
      value,
      onClick,
      onChange,
      showEditButton,
      'data-testid': dataTestid,
      fontSize = '18px',
    },
    ref,
  ) => {
    const { t } = useSafeTranslation();

    const [renameIntent, setRenameIntent] =
      useState<boolean>(isNewAiIntentInit);
    const [oldIntentName, setOldIntentName] = useState<string | undefined>();

    const startRename = useCallback(() => {
      setRenameIntent(true);
      setOldIntentName(value || undefined);
    }, [value]);

    const stopRename = useCallback(() => {
      setRenameIntent(false);
      setOldIntentName(undefined);
    }, []);

    useImperativeHandle(ref, () => ({
      startRename,
    }));

    return (
      <Flex alignItems="center" className={css.box}>
        {renameIntent ? (
          <Input
            className={css.input}
            placeholder={t(
              'pages.Keywords.KeywordGroups.keywordsGroupNamePlaceholder',
            )}
            autoSelect
            autoFocus
            maxLength={100}
            defaultValue={value}
            onKeyDown={({ key, currentTarget: { value } }) => {
              if (key === 'Enter') {
                onChange(value);
                stopRename();
              }
              if (key === 'Escape') {
                if (oldIntentName) {
                  onChange(oldIntentName);
                }
                stopRename();
              }
            }}
            onBlur={({ currentTarget: { value } }) => {
              onChange(value);
              stopRename();
            }}
            data-testid={`${dataTestid}-name-input`}
          />
        ) : (
          <Flex>
            <ButtonUnstyled
              onClick={onClick}
              title={value || undefined /* for empty string too */}
              onDoubleClick={() => {
                startRename();
              }}
              data-testid={`${dataTestid}-name-button`}
            >
              <Flex alignItems="center">
                <TextEllipsis width={300}>
                  <Type
                    size={fontSize}
                    weight="semibold"
                    color={value ? 'black' : 'grey'}
                  >
                    {
                      value ||
                        t(
                          'pages.Keywords.KeywordGroups.undefined',
                        ) /* for empty string too */
                    }
                  </Type>
                </TextEllipsis>
                {onClick && (
                  <>
                    <Spacer horizontalFactor={1} factor={1} />
                    <Icon
                      icon="triangle"
                      style={{
                        transform: `rotate(${expand ? '-180deg' : '0deg'})`,
                      }}
                    />
                  </>
                )}
              </Flex>
            </ButtonUnstyled>
            {showEditButton && (
              <>
                <Spacer horizontalFactor={1} factor={1} />
                <IconButton
                  data-testid="intent-name__edit-button"
                  onClick={() => {
                    startRename();
                  }}
                  icon="edit"
                />
              </>
            )}
          </Flex>
        )}
      </Flex>
    );
  },
);

import React, { useCallback } from 'react';
import cn from 'classnames';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useGoToUpdateCardInfo } from '@utils/Routing';
import { sendHomeTabEvent } from '@pages/BotPage/HomeTab/utils';
import * as css from './common.css';
import {
  SizeDependentButton,
  SizeDependentButtonProps,
} from './SizeDependentButton';

export interface UpdateCardInfoButtonProps extends SizeDependentButtonProps {
  shortText?: boolean;
}

export const UpdateCardInfoButton: React.FC<UpdateCardInfoButtonProps> = ({
  shortText,
  onClick,
  ...props
}) => {
  const goToUpdateCard = useGoToUpdateCardInfo();
  const { t } = useSafeTranslation();

  const onButtonClick = useCallback(
    (event) => {
      sendHomeTabEvent({ action: 'go to update card info' });
      goToUpdateCard(true);
      onClick?.(event);
    },
    [goToUpdateCard, onClick],
  );

  return (
    <SizeDependentButton
      data-testid="bot-panel__update-card-info-button"
      intent="red"
      className={cn(css.button, props.className)}
      onClick={onButtonClick}
      {...props}
    >
      {shortText
        ? t('pages.GrowPage.BotPanel.updateCardInfoShort')
        : t('pages.GrowPage.BotPanel.updateCardInfo')}
    </SizeDependentButton>
  );
};

import React, { useCallback, useMemo, useState } from 'react';
import { Workspace as UIWorkspace } from '@components/Workspace/Workspace';
import css from './Workspace.css';
import { Permission } from '../../../../../common/services/RoleService';
import { BotListMenuItemType } from '../types';
import { Modal } from '@services/index';
import { WorkspaceManageUsersModal } from './WorkspaceManageUsersModal';
import { useChangeWorkspace } from '@utils/Data/Workspaces/useChangeWorkspace';
import {
  WorkspacesBots_workspacesBots_workspaces_workspace,
  WorkspacesBots_workspacesBots_workspaces_workspace_connectedPages,
} from '@utils/Data/Workspaces/@types/WorkspacesBots';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useGoPremiumBillingPage } from './utils';
import Maybe from 'graphql/tsutils/Maybe';
import { ChargingEntityType } from '@globals';
import { useCollapsibleWorkspaces } from '../useCollapsibleWorkspaces';
import { WorkspaceLoader } from '@components/Workspace/WorkspaceLoader';
import { sendEvent } from '@utils/Analytics';

interface WorkspaceProps {
  workspace: WorkspacesBots_workspacesBots_workspaces_workspace;
}

enum WorkspaceMenuItem {
  manageUsers = 'manageUsers',
  rename = 'rename',
}

const getPagesCount = (
  connectedPages: Maybe<WorkspacesBots_workspacesBots_workspaces_workspace_connectedPages>,
) => {
  const { limit, reached } = connectedPages ?? {};

  if (typeof limit === 'number' && typeof reached === 'number')
    return {
      limit,
      reached,
    };
  return null;
};

const menuItemSelect = ({ onClick }: BotListMenuItemType) => onClick?.();

export const Workspace: React.FC<WorkspaceProps> = ({
  children,
  workspace,
}) => {
  const { t } = useSafeTranslation();
  const { name, permission, limitation, id, subscription, connectedPages } =
    workspace;
  const [titleEditing, setTitleEditing] = useState(false);
  const [changeWorkspaceName] = useChangeWorkspace();
  const goBillingPage = useGoPremiumBillingPage();
  const { getIsWorkspaceCollapsed, collapseWorkspace, loading } =
    useCollapsibleWorkspaces();

  const handleChangeExtends = useCallback(
    (extended: boolean) => collapseWorkspace(id, !extended),
    [id, collapseWorkspace],
  );

  const handleTitleChange = useCallback(
    (newTitle: string) => {
      if (newTitle !== name)
        changeWorkspaceName({
          variables: {
            workspaceId: id,
            name: newTitle,
          },
        });
      setTitleEditing(false);
    },
    [setTitleEditing, changeWorkspaceName, name, id],
  );

  const menuItems = useMemo((): BotListMenuItemType<WorkspaceMenuItem>[] => {
    const items = [];

    items.push(
      {
        id: WorkspaceMenuItem.manageUsers,
        title: t('BotList.listItemActions.manageUsers'),
        onClick: () => {
          sendEvent({
            category: 'bot list page',
            action: 'click on manage users in workspace',
            label: 'premium',
            propertyBag: {
              workspaceId: id,
            },
          });

          Modal.show(
            ({ close }) => (
              <WorkspaceManageUsersModal
                onClose={close}
                workspaceId={id}
                title={name}
              />
            ),
            { mobileAdaptive: true },
          );
        },
      },
      {
        id: WorkspaceMenuItem.rename,
        title: t('BotList.listItemActions.rename'),
        onClick: () => {
          sendEvent({
            category: 'bot list page',
            action: 'click on rename workspace',
            label: 'premium',
            propertyBag: {
              workspaceId: id,
            },
          });

          setTitleEditing(true);
        },
      },
    );

    return items;
  }, [name, id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <WorkspaceLoader />;

  return (
    <UIWorkspace<BotListMenuItemType<WorkspaceMenuItem>>
      defaultExtended={!getIsWorkspaceCollapsed(workspace.id)}
      onChangeExtends={handleChangeExtends}
      isUsers={subscription?.chargingEntityType === ChargingEntityType.users}
      pages={getPagesCount(connectedPages)}
      className={css.workspace}
      status={subscription?.status}
      title={name}
      readOnly={permission !== Permission.EDIT}
      limitation={limitation}
      titleEditing={titleEditing}
      onTitleChange={handleTitleChange}
      menuItems={menuItems}
      onMenuItemSelect={menuItemSelect}
      onBillingClick={() => goBillingPage()}
    >
      {children}
    </UIWorkspace>
  );
};

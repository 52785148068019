import React from 'react';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { formatPricingDate } from '@utils/DateTime/formatDate';
import { useBillingData } from '../../BotSectionView/Data/useBillingData';
import * as css from './PlanTillDate.css';

export const PlanTillDate = () => {
  const { t } = useSafeTranslation();
  const { pricing, tillDate, isTrial } = useBillingData();

  if (!pricing || !tillDate || isTrial) {
    return null;
  }

  return (
    <Type
      size="15px"
      data-testid="bot-panel__plan-till-date"
      className={css.planTillDate}
    >
      {t('pages.GrowPage.BotPanel.till', {
        date: formatPricingDate(tillDate),
      })}
    </Type>
  );
};

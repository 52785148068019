import { useTimeLimitedTrial } from '@utils/Data/Pricing/useTimeLimitedTrial';
import React from 'react';
import { useBillingData } from '../BotSectionView/Data/useBillingData';
import { useBotSectionData } from '../BotSectionView/Data/useBotSectionData';
import { ConversationsView } from './ConversationsView';

export const Conversations = () => {
  const { pricing, tier, planLimitReached } = useBillingData();
  const { bot } = useBotSectionData();
  const { currentTierTimeLimitedTrial } = useTimeLimitedTrial();

  if (!pricing || !tier || !bot) {
    return null;
  }

  const dialoguesUsed = pricing.reached_limit ?? 0;
  const color = planLimitReached?.isLimitReached ? 'red' : 'black';

  return (
    <ConversationsView
      numerator={dialoguesUsed}
      denominator={tier.tier_dialogs}
      color={color}
      captionKey="pages.GrowPage.BotPanel.statistics.conversations"
      hideConversations={!!bot.pro?.manual || currentTierTimeLimitedTrial}
    />
  );
};

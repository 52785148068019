import { Flex } from '@ui/Flex';
import React from 'react';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { ReactComponent as PicSvg } from './images/pic.svg';
import * as css from './KeywordsGroupsEmpty.css';
import { sendEvent } from '@utils/Analytics';
import { Anchor } from '@ui/Links';

interface KeywordsGroupsEmptyProps {
  onAddIntentRequest: () => void;
}

export const KeywordsGroupsEmpty: React.FC<KeywordsGroupsEmptyProps> = ({
  onAddIntentRequest,
}) => {
  const { t } = useSafeTranslation();

  return (
    <Flex justifyContent="center" alignItems="center">
      <Flex flexDirection="column" alignItems="center" className={css.box}>
        <Spacer factor={20} />
        <PicSvg />
        <Spacer factor={6} />
        <Type size="24px" weight="semibold">
          {t('pages.Keywords.KeywordGroups.empty.title')}
        </Type>
        <Spacer factor={4} />
        <Type size="15px">
          {t('pages.Keywords.KeywordGroups.empty.text')}{' '}
          <Anchor
            href="https://docs.chatfuel.com/en/articles/828883-set-up-keywords"
            target="_blank"
            onClick={() => {
              sendEvent({
                category: 'keywords',
                label: 'keywords groups',
                action: 'Learn more link click',
              });
            }}
          >
            {t('pages.Keywords.KeywordGroups.empty.link')}
          </Anchor>
        </Type>
        <Spacer factor={6} />
        <Button
          onClick={() => {
            onAddIntentRequest();
            sendEvent({
              category: 'keywords',
              label: 'keywords groups',
              action: 'add keyword group click (empty state)',
            });
          }}
          icon={<Icon icon="plus" color="white" />}
          data-testid="keywords__keywords-groups__add-first-keyword-group-button"
        >
          {t('pages.Keywords.KeywordGroups.empty.add')}
        </Button>
        <Spacer factor={20} />
      </Flex>
    </Flex>
  );
};

import React, { useMemo } from 'react';
import { Editor } from 'slate-react';
import { lensPath, set } from 'ramda';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Input } from '@ui/Input';
import {
  TextWithAttributesEditor,
  getFullAttributesBoundaries,
  wrapAllAttributes,
} from '@ui/TextWithAttributesEditor';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import { deserialize } from '@ui/BubbleEditor';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import { ButtonViewChildrenGrid } from './ButtonViewChildrenGrid';
import { insertText } from '@utils/Slite/insertText';
import { ButtonErrors, TemplateButton } from '../../../form/types';
import { AttributeExample } from '../../AttributeExample';
import { useCurrentBotId } from '@utils/Routing';
import { useAttributes } from '@utils/AttributesUtils/AttributesData';
import { Platform } from '@globals';
import { VariableSuggestType } from '@utils/AttributesUtils/AttributesUtilsTypes';
import { ButtonTextInputView } from './ButtonTextInputView';
import * as css from './WebsiteButtonView.css';
import {
  getAttributeExamples,
  updateAttributeExamples,
} from '../../../form/utils';

export const MAX_WEBSITE_URL_BUTTON_LENGTH = 2000;

export interface WebsiteButtonViewProps {
  disabled: boolean;
  button: TemplateButton;
  buttonErrors: ButtonErrors;
  onButtonChange: (button: TemplateButton, save?: boolean) => void;
}

export const WebsiteButtonView: React.FC<WebsiteButtonViewProps> = ({
  button,
  disabled,
  buttonErrors,
  onButtonChange,
}) => {
  const botId = useCurrentBotId()!;
  const { attributes } = useAttributes(
    botId,
    VariableSuggestType.template,
    Platform.whatsapp,
  );
  const { t } = useSafeTranslation();

  const value = button.url || '';
  const attributesAmount = getFullAttributesBoundaries(value).length;
  const attributeExamples = button.attribute_examples;

  const currentTextLimit = useMemo(
    () =>
      MAX_WEBSITE_URL_BUTTON_LENGTH -
      wrapAllAttributes(value, () => '').trim().length,
    [value],
  );

  return (
    <>
      <ButtonViewChildrenGrid>
        <PluginControlLabel
          light
          label={t('pages.MessagesTemplates.message.buttonText')}
        >
          {() => (
            <ButtonTextInputView
              disabled={disabled}
              error={Boolean(buttonErrors?.text)}
              buttonText={button.text || ''}
              onButtonTextChange={(e) =>
                onButtonChange({ ...button, text: e.target.value })
              }
              data-testid={`messages-template-${button.type}__button-text`}
            />
          )}
        </PluginControlLabel>

        <PluginControlLabel
          light
          label={t('pages.MessagesTemplates.message.websiteUrl')}
        >
          {() => (
            <TextOutsideControls
              onInsertRequest={(text, el) => {
                insertText(text, el as Editor);
              }}
              currentTextLimit={currentTextLimit}
              shouldShowOutsideControls={{
                symbolsLimit: true,
                attributes: attributesAmount === 0,
              }}
              initialShow={false}
              boxStyle={{ width: '100%' }}
              tight
            >
              {({ ref, getInputProps }) => (
                <Input
                  disabled={disabled}
                  error={buttonErrors?.url}
                  render={() => (
                    <Flex alignItems="center">
                      <TextWithAttributesEditor
                        className={css.input}
                        placeholder={t(
                          'pages.MessagesTemplates.message.enterURL',
                        )}
                        autoFocus
                        defaultValue={deserialize(value || '')}
                        attributes={attributes}
                        maxLength={MAX_WEBSITE_URL_BUTTON_LENGTH}
                        editorRef={ref}
                        hasManageAttributes
                        onFocus={getInputProps().onFocus}
                        onStringChange={(value) =>
                          onButtonChange({ ...button, url: value })
                        }
                        onKeyDown={(event) => {
                          const { key, shiftKey } = event as KeyboardEvent;
                          if (key === 'Enter' && !shiftKey) {
                            ref.current?.blur();
                            return false;
                          }
                          return undefined;
                        }}
                        onBlur={(event, value) => {
                          const attribute_examples = updateAttributeExamples(
                            attributeExamples,
                            getAttributeExamples(value),
                          );

                          onButtonChange(
                            {
                              ...button,
                              url: value,
                              attribute_examples,
                            },
                            true,
                          );

                          (getInputProps().onBlur as Function)(event);
                        }}
                        data-testid={`messages-template-${button.type}__url`}
                      />
                    </Flex>
                  )}
                />
              )}
            </TextOutsideControls>
          )}
        </PluginControlLabel>
      </ButtonViewChildrenGrid>

      {attributeExamples.length > 0 && (
        <>
          <Spacer factor={3} />
          <AttributeExample
            disabled={disabled}
            errors={buttonErrors?.attribute_examples}
            attributeExamples={attributeExamples}
            onExampleChange={(example, index) =>
              onButtonChange(
                set(lensPath(['attribute_examples', index]), example, button),
              )
            }
          />
        </>
      )}
    </>
  );
};

import React from 'react';
import * as css from './Generate.css';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { SingleButtonFooter } from '../common/SingleButtonFooter';
import { PhoneNumberView } from '../../../common/PhoneNumberView';
import { useAdminPhones } from '../../hooks/useAdminPhones';
import { CenteredLoader } from '@ui/Loader';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import cn from 'classnames';
import { Trans } from 'react-i18next';
import { sendEvent } from 'cf-common/src/analytics';

interface GenerateProps {
  onDone: VoidFunction;
  onWhatsappLoginRequest: VoidFunction;
}

export const Generate: React.FC<GenerateProps> = ({
  onWhatsappLoginRequest,
}) => {
  const { t } = useSafeTranslation();
  const { lastNotUtilizedPhone } = useAdminPhones();
  const { isSmallScreenSize } = useDeviceMedia();

  if (!lastNotUtilizedPhone) {
    return <CenteredLoader />;
  }

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="center"
        className={cn(css.box, isSmallScreenSize && css.mobile)}
      >
        <Flex
          alignItems="center"
          flexDirection="column"
          gapFactor={2}
          className={css.subBox}
        >
          <Type align="center">
            <Trans
              t={t}
              i18nKey="pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.generate.text"
            />
          </Type>
          <PhoneNumberView
            phone={lastNotUtilizedPhone.nationalNumber}
            code={`+${lastNotUtilizedPhone.countryCode}`}
            color="accent2Dark"
          />
        </Flex>
      </Flex>
      <SingleButtonFooter
        buttonI18nKey="pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.generate.button"
        onButtonClick={() => {
          sendEvent({
            category: 'purchase phone number',
            label: 'generate',
            action: 'next button click',
            propertyBag: {
              phone: lastNotUtilizedPhone,
            },
          });
          onWhatsappLoginRequest();
        }}
        buttonDisabled={false}
      />
    </>
  );
};

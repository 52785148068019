import React, { useEffect, useMemo, useState } from 'react';
import { compose } from 'ramda';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { BotSelector } from '@components/BotSelector';
import { Flex } from '@ui/Flex';
import { Button } from '@ui/Button';
import { Input } from '@ui/Input';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { Loader } from '@ui/Loader';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { useCurrentBotId } from '@utils/Routing';
import { getFirstFlowGroupId } from '@utils/Data/Flow/GroupingFlows/utils';
import {
  FlowGroupsCloneModalQuery,
  FlowGroupsCloneModalQuery_bot_flow_groups,
  FlowGroupsCloneModalQueryVariables,
} from './@types/FlowGroupsCloneModalQuery';
import * as css from './FlowItem.css';
import { Platform } from '@globals';

interface CloneFlowItemModalProps {
  onSubmit: (params: { botId: string; groupId: string }) => void;
  platform?: Platform;
  loading?: boolean;
}

export const FLOW_GROUPS_CLONE_MODAL_QUERY = gql`
  query FlowGroupsCloneModalQuery($botId: String!) {
    bot(id: $botId) {
      id
      flow_groups {
        id
        title
      }
    }
  }
`;

export const CloneFlowItemModal: React.FC<CloneFlowItemModalProps> = ({
  onSubmit,
  loading,
  platform,
}) => {
  const botId = useCurrentBotId();
  const [selectedBotId, setSelectedBotId] = useState(botId);
  const [selectedGroupId, setSelectedGroupId] = useState<
    string | null | undefined
  >('');

  const { data, loading: dataLoading } = useQuery<
    FlowGroupsCloneModalQuery,
    FlowGroupsCloneModalQueryVariables
  >(FLOW_GROUPS_CLONE_MODAL_QUERY, {
    variables: { botId: selectedBotId || '' },
    skip: !selectedBotId,
  });

  useEffect(() => {
    setSelectedGroupId(getFirstFlowGroupId(data?.bot));
  }, [data]);

  const initialSelectedItem = useMemo(
    () => data?.bot.flow_groups?.find(({ id }) => id === selectedGroupId),
    [data, selectedGroupId],
  );

  return (
    <div className={css.cloneFlowModal}>
      <Type color="greyDark" size="15px_DEPRECATED">
        {window.i18next.t('MoveFlowItemModal-JSXText--125-to-the-bot')}
      </Type>
      <Spacer factor={1} />
      <BotSelector
        platforms={platform && [platform]}
        onChange={({ id }) => {
          setSelectedBotId(id);
        }}
        selectedBotId={selectedBotId}
      />
      <Spacer factor={6} />
      <Type color="greyDark" size="15px_DEPRECATED">
        {window.i18next.t('MoveFlowItemModal-JSXText-1130-to-the-group')}
      </Type>
      <Spacer factor={1} />
      {selectedGroupId &&
        (dataLoading ? (
          <Loader />
        ) : (
          <SimpleCombobox<FlowGroupsCloneModalQuery_bot_flow_groups>
            key={selectedBotId}
            renderInput={({
              getInputProps,
              openMenu,
              ref,
              selectInputValue,
              selectItem,
            }) => (
              <Input
                {...getInputProps({
                  ref: (n: HTMLInputElement) => {
                    ref(n);
                  },
                  onFocus: compose(openMenu, selectInputValue),
                  onChange: () => {
                    selectItem(null);
                  },
                })}
                required
              />
            )}
            itemToString={(group) => group?.title || ''}
            items={data?.bot.flow_groups || []}
            selectedItem={initialSelectedItem || null}
            onChange={(group) => {
              if (group) {
                setSelectedGroupId(group.id);
              }
            }}
            menuboxStyle={{ zIndex: 10000000000, minWidth: '400px' }}
            dataTestId="flow_group_selector__combobox"
          />
        ))}
      <Spacer factor={9} />
      <Flex justifyContent="flex-end">
        {loading ? (
          <Flex className={css.buttonLoader} alignItems="center">
            <LoadingPlaceholder color="grey" fullWidth />
          </Flex>
        ) : (
          <Button
            disabled={!selectedBotId || !selectedGroupId}
            onClick={() => {
              if (selectedBotId && selectedGroupId) {
                onSubmit({
                  botId: selectedBotId,
                  groupId: selectedGroupId,
                });
              }
            }}
          >
            {window.i18next.t('MoveFlowItemModal-string--843-copy-flow')}
          </Button>
        )}
      </Flex>
    </div>
  );
};

import { useRolePermission } from '@utils/Roles';
import { Permission } from '@common/services/RoleService';

export const useSingleBroadcastViewAllowed = () => {
  const {
    allowed: editBroadcastingAllowed,
    loading: loadingBroadcastingPermissions,
  } = useRolePermission({
    domain: 'broadcasting',
    can: Permission.EDIT,
  });
  const { allowed: viewFlowsAllowed, loading: loadingFlowsPermissions } =
    useRolePermission({
      domain: 'flows',
      can: Permission.VIEW,
    });

  return {
    allowed: viewFlowsAllowed && editBroadcastingAllowed,
    loading: loadingBroadcastingPermissions || loadingFlowsPermissions,
  };
};

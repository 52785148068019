import React from 'react';
import {
  PremiumPricingUsageQuery_premiumPricingUsage_pages as PremiumPricingUsagePages,
  PremiumPricingUsageQuery_premiumPricingUsage_usage as PremiumPricingUsage,
} from '@utils/Data/Pricing/@types/PremiumPricingUsageQuery';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { Type } from '@ui/Type';
import { MONTHS_IN_YEAR_COUNT } from '../constants';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from '../UsageTable.css';

const enUsFormatter = new Intl.NumberFormat('en-US');
const formatWithThousandsCommaSeparator = (num: number) =>
  enUsFormatter.format(num);

const EMPTY_SYMBOL = '—';

interface PageUsageCells {
  pageId: string;
  usage: PremiumPricingUsage[];
}

const PageUsageCells = React.memo<PageUsageCells>(({ pageId: id, usage }) =>
  !usage.length ? (
    <td colSpan={MONTHS_IN_YEAR_COUNT} />
  ) : (
    <>
      {usage.map(({ billingDate, pages }) => {
        const page = pages.find(({ pageId }) => pageId === id);
        const key = id + billingDate;
        return (
          <td key={key}>
            <Type size="12px">
              {!page ? (
                <span>{EMPTY_SYMBOL}</span>
              ) : (
                formatWithThousandsCommaSeparator(page.reachedLimit)
              )}
            </Type>
          </td>
        );
      })}
    </>
  ),
);

interface UsageDataRowProps {
  usage: PremiumPricingUsage[] | undefined;
  pages: PremiumPricingUsagePages[] | undefined;
  loading?: boolean;
}

export const UsageDataRow = React.memo<UsageDataRowProps>(
  ({ loading, pages, usage }) => {
    const { t } = useSafeTranslation();
    return loading || !pages || !usage ? (
      <>
        <tr>
          <td />
          <td colSpan={MONTHS_IN_YEAR_COUNT}>
            <LoadingPlaceholder className={css.usageTableLoader} />
          </td>
        </tr>
        <tr>
          <td />
          <td colSpan={MONTHS_IN_YEAR_COUNT}>
            <LoadingPlaceholder className={css.usageTableLoader} />
          </td>
        </tr>
      </>
    ) : (
      <>
        <tr>
          <td>
            <Type size="12px" weight="semibold">
              {t('pages.Billing.premiumPage.usage.totalUsage')}
            </Type>
            <br />
            <Type size="12px" color="greyDark">
              {t('pages.Billing.premiumPage.usage.limit')}
            </Type>
          </td>
          {usage.map(({ billingDate, limit, reachedLimit }) => (
            <td key={billingDate}>
              <Type
                size="12px"
                weight="semibold"
                color={reachedLimit > limit ? 'red' : 'black'}
              >
                {formatWithThousandsCommaSeparator(reachedLimit)}
              </Type>
              <br />
              <Type size="12px" color="greyDark">
                {formatWithThousandsCommaSeparator(limit)}
              </Type>
            </td>
          ))}
          {MONTHS_IN_YEAR_COUNT - usage.length > 0 ? (
            <td
              className={css.stubCell}
              colSpan={MONTHS_IN_YEAR_COUNT - usage.length}
            />
          ) : null}
        </tr>
        {pages.map(({ id, title }) => (
          <tr key={id}>
            <td>
              <Type size="12px" weight="semibold">
                {title}
              </Type>
            </td>
            <PageUsageCells pageId={id} usage={usage} />
          </tr>
        ))}
      </>
    );
  },
);

import React, { useCallback } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PlatformNotConnected } from '@pages/GrowPage/PlatformConnection';
import { useWhatsappLogin } from '@pages/MultiSystemAuth/hooks/useWhatsappLogin';
import { useCurrentBotId } from '@utils/Routing';
import { sendEvent } from '@utils/Analytics';
import { OnboardingTourEventType, OnboardingTourHTMLElementId } from '@globals';
import { PlatformConnectionLoading } from '@pages/GrowPage/PlatformConnection/PlatformConnectionLoading/PlatformConnectionLoading';
import { useProcessQueryParams } from './useProcessQueryParams';
import {
  OnboardingEmitter,
  OnboardingManualStateEmitter,
  OnboardingState,
} from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';
import { useShowWhatsappBuyPhoneDialog } from '../components/WhatsappBuyPhoneDialog/hooks/useShowWhatsappBuyPhoneDialog';

export const WhatsappUninitialized: React.FC = () => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;

  const { whatsappLogin, ready, tokenIsUpdating } = useWhatsappLogin({ botId });
  const { onWhatsappLogin, loading } = useShowWhatsappBuyPhoneDialog();

  const connectWhatsapp = useCallback(() => {
    onWhatsappLogin({
      onWhatsappLoginRequest: () =>
        whatsappLogin().then(() => {
          OnboardingEmitter.emit(OnboardingTourEventType.connectWhatsapp, {
            element: null,
            value: true,
          });
        }),
      onDismiss: () => {
        OnboardingEmitter.emit(OnboardingTourEventType.restart);
      },
    });
  }, [onWhatsappLogin, whatsappLogin]);

  useProcessQueryParams(connectWhatsapp, ready);

  if (tokenIsUpdating || loading) {
    return <PlatformConnectionLoading data-testid="whatsapp__loading" />;
  }

  return (
    <PlatformNotConnected
      icon="whatsapp"
      title={t('pages.BotPage.HomeTab.Whatsapp.title')}
      description={t('pages.BotPage.HomeTab.Whatsapp.description')}
      buttonTextId={
        ready ? 'whatsapp__connect-button' : 'whatsapp__connect-button-loading'
      }
      buttonId={
        OnboardingTourHTMLElementId.HomeTabWhatsappAccountBlockConnectionButton
      }
      buttonLoading={!ready}
      onClick={() => {
        sendEvent({
          category: 'whatsapp',
          action: 'connect button click',
          label: 'first connection',
          propertyBag: {
            botId,
          },
        });
        OnboardingManualStateEmitter.emit(OnboardingState.abort);
        connectWhatsapp();
      }}
      data-testid="whatsapp-uninitialized"
    />
  );
};

import React from 'react';
import { Type } from '@ui/Type';
import { ButtonUnstyled } from '@ui/Button';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PlanBlockLayout } from './PlanBlockLayout';
import css from '../PlanBlock.css';

export interface ChoosePlanBlockProps {
  onClick: () => void;
}

export const ChoosePlanBlock: React.FC<ChoosePlanBlockProps> = ({
  onClick,
}) => {
  const { t } = useSafeTranslation();

  return (
    <PlanBlockLayout
      headerView={
        <Type size="24px" weight="semibold">
          {t('pages.Billing.premiumPage.chosePlan')}
        </Type>
      }
      footerView={
        <div>
          <ButtonUnstyled
            data-testid="plan-block__contact-us"
            className={css.contactUs}
            onClick={onClick}
          >
            {t('pages.Billing.premiumPage.contactUs')}
          </ButtonUnstyled>
          <Type size="15px">
            {t('pages.Billing.premiumPage.chooseRightPlan')}
          </Type>
        </div>
      }
    />
  );
};

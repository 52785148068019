import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Button } from '@ui/Button';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { sendEvent } from '@utils/Analytics';
import { ChooseTemplateDialogBroadcasts } from './BroadcastsTemplates';
import { Modal } from '@services/index';
import * as css from './TopView.css';
import { useSingleBroadcastViewAllowed } from '../hooks/useSingleBroadcastViewAllowed';
import { OnboardingEmitter } from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';
import { OnboardingTourEventType, OnboardingTourHTMLElementId } from '@globals';

export const TopView = () => {
  const { t } = useSafeTranslation();
  const { allowed } = useSingleBroadcastViewAllowed();

  const onClick = () => {
    sendEvent({
      category: 'whatsapp reengage page',
      action: 'create new button click',
    });
    Modal.show(
      ({ close }) => <ChooseTemplateDialogBroadcasts onDismiss={close} />,
      {
        overlayClassName: css.chooseTemplatBroadcastsModalOverlay,
      },
    );
    OnboardingEmitter.emit(OnboardingTourEventType.click, {
      element: OnboardingTourHTMLElementId.WAReengageCreateNewButton,
      value: null,
    });
  };

  return (
    <Flex justifyContent="space-between">
      <Button
        intent="primary"
        id={OnboardingTourHTMLElementId.WAReengageCreateNewButton}
        data-testid="reengage__create-new-button"
        onClick={onClick}
        disabled={!allowed}
        className={cn(!allowed && css.disabled)}
      >
        {t('pages.BotPage.ReengageWhatsappTab.createMessage')}
      </Button>
    </Flex>
  );
};

import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { cond, always as aw, T } from 'ramda';
import { BotStatusCalloutState } from '../consts';
import { useFlowEntryPointsData } from '@components/FlowBuilder/utils/Data/useFlowEntryPointsData';
import {
  useCurrentBotId,
  useCurrentBroadcastId,
  useFlowTabParams,
} from '@utils/Routing';
import { getFlowController } from '@components/FlowBuilder/PixiFieldRepository';
import { useFlowInboundsLinks } from '@utils/Data/Flow/useFlowInboundsLinks';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { useWhatsappConnected } from '@utils/Data/Whatsapp';

export const useBotStatusCalloutState = () => {
  const {
    isConnected,
    loading: pageConnectedLoading,
    refetch: pageRefetch,
  } = usePageConnected();
  const flowTabParams = useFlowTabParams();
  const broadcastId = useCurrentBroadcastId();
  const { isWhatsappEnabled, loading: whatsappEnabledLoading } =
    useWhatsappEnabled();
  const botId = useCurrentBotId();
  const {
    isConnected: isWhatsappConnected,
    loading: whatsappConnectedLoading,
  } = useWhatsappConnected(botId);
  const flowId = flowTabParams?.flowId;
  const {
    entryPoints,
    loading: entryPointsLoading,
    refetch: flowsRefetch,
  } = useFlowEntryPointsData(flowId);
  const { qtyInboundLinks, loading: inboundLinksLoading } =
    useFlowInboundsLinks(flowId);
  const loading =
    pageConnectedLoading ||
    entryPointsLoading ||
    inboundLinksLoading ||
    whatsappEnabledLoading ||
    whatsappConnectedLoading;
  const hasActiveEntryPoints = entryPoints?.some(({ enabled }) => enabled);

  return {
    loading,
    refetch: () => {
      pageRefetch();
      flowsRefetch();
    },
    state: cond([
      [aw(!!broadcastId), aw(BotStatusCalloutState.broadcastWarring)],
      [aw(!flowId), aw(undefined)],
      [aw(getFlowController()?.flow.id !== flowId), aw(undefined)],
      [aw(loading), aw(undefined)],
      [
        aw(isWhatsappEnabled && !isWhatsappConnected),
        aw(BotStatusCalloutState.connectWhatsapp),
      ],
      [aw(!isConnected), aw(BotStatusCalloutState.connectPage)],
      [aw(qtyInboundLinks > 0), aw(undefined)],
      [
        aw(!!entryPoints && !entryPoints?.length),
        aw(BotStatusCalloutState.addEntryPoint),
      ],
      [aw(!hasActiveEntryPoints), aw(BotStatusCalloutState.activateEntryPoint)],
      [T, aw(undefined)],
    ])(),
  };
};

import { WhatsappBroadcastConfig } from '../types';

export enum BroadcastTypes {
  trigger = 'trigger',
  oneTime = 'oneTime',
  schedule = 'schedule',
}

export interface IBroadcastType {
  type: BroadcastTypes;
  title: string;
  buttonTitle: string;

  isCurrentBroadcast(config: WhatsappBroadcastConfig): boolean;
  getDefaultConfig(config: WhatsappBroadcastConfig): WhatsappBroadcastConfig;
}

import React from 'react';
import cn from 'classnames';
import { BotOptionsMenu } from '@components/BotList/components/BotOptionsMenu';
import { BotOptions } from '@components/BotActions';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { BotQuery_bot as Bot } from '../../../../../common/services/GQLqueries/@types/BotQuery';
import { BotTitleView } from './BotTitleView';
import { BotStatus } from './BotStatus';
import { FacebookLink, InstagramLink, WhatsappLink } from './BotLinks';
import { ChangeBotNameForm } from './ChangeBotNameForm';
import * as css from './BotSectionHeaderView.css';
import { sendHomeTabEvent } from '../../utils';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useWhatsappEnabled } from '@utils/Whatsapp';

export interface BotSectionHeaderViewProps {
  bot: Bot;
  isRenameMode: boolean;
  isBotOptionsMenuDisabled: boolean;
  onSubmit: (value: string) => void;
  onReject: () => void;
  onActionSelect: (type: BotOptions) => void;
  headerClassName?: string;
}

export const BotSectionHeaderView: React.FC<BotSectionHeaderViewProps> = ({
  bot,
  isRenameMode,
  isBotOptionsMenuDisabled,
  onSubmit,
  onReject,
  onActionSelect,
  headerClassName,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const { isWhatsappEnabled } = useWhatsappEnabled();

  if (isRenameMode) {
    return (
      <header className={cn(headerClassName, css.header)}>
        <ChangeBotNameForm
          title={bot.title}
          onSubmit={onSubmit}
          onReject={onReject}
        />
      </header>
    );
  }

  return (
    <header className={cn(headerClassName, css.header)}>
      <Flex
        justifyContent="space-between"
        flexDirection={isSmallScreenSize ? 'column' : 'row'}
        className={css.headerWrapper}
      >
        <Flex alignItems={isSmallScreenSize ? 'flex-end' : 'center'} flexWrap>
          <BotTitleView
            onDoubleClick={() => onActionSelect(BotOptions.rename)}
            className={css.botTitle}
          >
            {bot.title}
          </BotTitleView>
          <BotStatus className={css.botStatus} />
        </Flex>

        <Flex className={css.linksWrapper}>
          {!isWhatsappEnabled ? (
            <>
              <InstagramLink
                className={css.link}
                botId={bot.id}
                onClick={() =>
                  sendHomeTabEvent({ action: 'go to instagram bot click' })
                }
                onCopyClick={() =>
                  sendHomeTabEvent({ action: 'copy instagram bot link click' })
                }
              />
              <FacebookLink
                botId={bot.id}
                onClick={() =>
                  sendHomeTabEvent({ action: 'go to facebook bot click' })
                }
                onCopyClick={() =>
                  sendHomeTabEvent({ action: 'copy facebook bot link click' })
                }
              />
            </>
          ) : (
            <WhatsappLink
              botId={bot.id}
              onClick={() =>
                sendHomeTabEvent({ action: 'go to whatsapp bot click' })
              }
              onCopyClick={() =>
                sendHomeTabEvent({ action: 'copy whatsapp bot link click' })
              }
            />
          )}
        </Flex>
      </Flex>

      <Flex className={css.botOptionsMenu}>
        <Spacer horizontalFactor={3} />

        <BotOptionsMenu
          isLast={false}
          botId={bot.id}
          onActionSelect={({ type }) => onActionSelect(type)}
          disabled={isBotOptionsMenuDisabled}
        />
      </Flex>
    </header>
  );
};

import { clone } from 'ramda';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { FLOW_GROUPS_SUGGEST_QUERY } from '@components/BlocksSelector2';
import { useCallback } from 'react';
import { ApolloError } from 'apollo-client';

import {
  FLOW_GROUPS_SUGGEST_FRAGMENT,
  ARCHIVE_BLOCKS_FRAGMENT,
} from '@components/ButtonEditorPopup/common/ButtonPopupBlocksSelector';
import {
  FLOW_GROUPS_QUERY,
  FLOWS_QUERY,
  BOT_FLOWS_QUERY,
} from '@utils/Data/Flow';
import { FlowItemQuery_bot_flow } from '@utils/Data/Flow/@types/FlowItemQuery';
import {
  FlowsQuery,
  FlowsQueryVariables,
} from '@utils/Data/Flow/@types/FlowsQuery';
import { useCurrentBotId } from '@utils/Routing';
import { FlowInput, FlowSharingInput } from '@globals';

import {
  UpdateFlowMutation,
  UpdateFlowMutationVariables,
} from './@types/UpdateFlowMutation';
import {
  RemoveFlowMutation,
  RemoveFlowMutationVariables,
} from './@types/RemoveFlowMutation';
import {
  CloneFlowMutation,
  CloneFlowMutationVariables,
} from './@types/CloneFlowMutation';
import {
  UpdateFlowSharingMutation,
  UpdateFlowSharingMutationVariables,
} from './@types/UpdateFlowSharingMutation';
import {
  FlowBlocksSelectorQuery,
  FlowBlocksSelectorQueryVariables,
} from './@types/FlowBlocksSelectorQuery';

const UPDATE_FLOW_SHARING_MUTATION = gql`
  mutation UpdateFlowSharingMutation(
    $flowId: ID!
    $sharingParams: FlowSharingInput!
  ) {
    updateFlowSharing(flowId: $flowId, sharingParams: $sharingParams) {
      id
      sharing_params {
        allow_cloning
        sharing_enabled
      }
    }
  }
`;

const UPDATE_FLOW_MUTATION = gql`
  mutation UpdateFlowMutation($groupId: ID, $flow: FlowInput!) {
    updateFlow(groupId: $groupId, flow: $flow) {
      id
      title
      sharing_params {
        allow_cloning
        sharing_enabled
      }
      collapsed
    }
  }
`;

const REMOVE_FLOW_MUTATION = gql`
  mutation RemoveFlowMutation($flowId: ID!) {
    removeFlow(flowId: $flowId) {
      success
    }
  }
`;

const CLONE_FLOW_MUTATION = gql`
  mutation CloneFlowMutation($flowId: ID!, $flowGroupId: ID!) {
    cloneFlow(flowId: $flowId, groupId: $flowGroupId) {
      id
      title
    }
  }
`;

const FLOW_BLOCKS_SELECTOR_QUERY = gql`
  query FlowBlocksSelectorQuery($botId: String!) {
    bot(id: $botId) {
      id
      ...ArchiveBlocksFragment
      ...FlowGroupsSuggest
    }
  }
  ${ARCHIVE_BLOCKS_FRAGMENT}
  ${FLOW_GROUPS_SUGGEST_FRAGMENT}
`;

interface flowUpdateProps {
  flow?: FlowItemQuery_bot_flow | null;
  onCompleted?: (data?: UpdateFlowMutation) => void;
  onError?: () => void;
}

interface onUpdateFlowProps {
  title?: string;
  groupId?: string;
  position?: number;
  collapsed?: boolean;
}

export const useFlowUpdate = ({
  flow,
  onCompleted,
  onError,
}: flowUpdateProps) => {
  const flowId = flow?.id;
  const prevTitle = flow?.title;
  const botId = useCurrentBotId();
  const [updateFlowMutation, { loading }] = useMutation<
    UpdateFlowMutation,
    UpdateFlowMutationVariables
  >(UPDATE_FLOW_MUTATION, {
    update: (cache, { data }) => {
      const flowsData = cache.readQuery<FlowsQuery, FlowsQueryVariables>({
        query: FLOWS_QUERY,
        variables: { botId: botId || '' },
      });
      if (flowsData?.bot) {
        flowsData.bot.flows =
          flowsData?.bot.flows?.map((flow) =>
            flow.id !== data?.updateFlow.id
              ? flow
              : { ...flow, ...data?.updateFlow },
          ) || null;
        cache.writeQuery({
          query: FLOWS_QUERY,
          variables: { botId: botId || '' },
          data: flowsData,
        });
        if (prevTitle !== data?.updateFlow.title) {
          let flowBlocksSelectorData;
          try {
            flowBlocksSelectorData = clone(
              cache.readQuery<
                FlowBlocksSelectorQuery,
                FlowBlocksSelectorQueryVariables
              >({
                query: FLOW_BLOCKS_SELECTOR_QUERY,
                variables: { botId: botId || '' },
              }),
            );
          } catch {
            flowBlocksSelectorData = null;
          }

          if (flowBlocksSelectorData?.bot.flowGroupsSuggest) {
            const updatedFlowFromGroups = flowBlocksSelectorData.bot.flowGroupsSuggest
              .flatMap((v) => v.flows)
              .find((v) => v?.id === flowId);
            if (updatedFlowFromGroups) {
              updatedFlowFromGroups.title = data?.updateFlow.title ?? '';
            }

            const updatedFlowFromBlocks = flowBlocksSelectorData.bot.archiveBlocks.find(
              (v) => v.id === flowId,
            );
            if (updatedFlowFromBlocks) {
              updatedFlowFromBlocks.title = data?.updateFlow.title ?? '';
            }

            if (updatedFlowFromGroups || updatedFlowFromBlocks) {
              cache.writeQuery({
                query: FLOW_BLOCKS_SELECTOR_QUERY,
                variables: { botId: botId || '' },
                data: flowBlocksSelectorData,
              });
            }
          }
        }
      }
    },
    onCompleted,
    onError,
    refetchQueries: [
      { query: FLOW_GROUPS_SUGGEST_QUERY, variables: { botId } },
    ],
  });

  const updateFlow = useCallback(
    ({ title, groupId, position, collapsed }: onUpdateFlowProps) => {
      if (botId && flowId) {
        let flow: FlowInput = { id: flowId };
        if (title !== undefined) {
          flow = { ...flow, title };
        }
        if (position !== undefined) {
          flow = { ...flow, position };
        }
        if (collapsed !== undefined) {
          flow = { ...flow, collapsed };
        }

        const variables = {
          groupId,
          flow,
        };

        updateFlowMutation({ variables });
      }
    },
    [botId, flowId, updateFlowMutation],
  );

  return { updateFlow, loading };
};

interface flowUpdateSharingProps {
  flowId?: string;
  onCompleted?: (data?: UpdateFlowSharingMutation) => void;
  onError?: () => void;
}

interface onUpdateFlowSharingProps {
  sharingParams: FlowSharingInput;
}

export const useFlowSharingUpdate = ({
  flowId,
  onCompleted,
  onError,
}: flowUpdateSharingProps) => {
  const [updateFlowSharingMutation, { loading, error }] = useMutation<
    UpdateFlowSharingMutation,
    UpdateFlowSharingMutationVariables
  >(UPDATE_FLOW_SHARING_MUTATION, {
    onCompleted,
    onError,
  });

  const updateFlowSharing = useCallback(
    ({ sharingParams }: onUpdateFlowSharingProps) => {
      if (flowId) {
        updateFlowSharingMutation({
          variables: {
            flowId,
            sharingParams,
          },
          optimisticResponse: {
            updateFlowSharing: {
              __typename: 'Flow',
              id: flowId,
              sharing_params: {
                __typename: 'FlowSharing',
                sharing_enabled: !!sharingParams.sharing_enabled,
                allow_cloning: !!sharingParams.allow_cloning,
              },
            },
          },
        });
      }
    },
    [flowId, updateFlowSharingMutation],
  );

  return { updateFlowSharing, loading, error };
};

interface FlowRemoveProps {
  flowId?: string;
  onCompleted?: (data?: RemoveFlowMutation) => void;
}

export const useFlowRemove = ({
  flowId,
  onCompleted,
}: FlowRemoveProps = {}) => {
  const botId = useCurrentBotId();

  const [removeFlowMutation, { loading }] = useMutation<
    RemoveFlowMutation,
    RemoveFlowMutationVariables
  >(REMOVE_FLOW_MUTATION, {
    refetchQueries: [
      { query: BOT_FLOWS_QUERY, variables: { botId } },
      { query: FLOW_GROUPS_SUGGEST_QUERY, variables: { botId } },
    ],
    awaitRefetchQueries: true,
    onCompleted,
  });

  const removeFlow = useCallback(
    (passedFlowId?: string) => {
      const flowIdForRemoval = flowId ?? passedFlowId;
      if (flowIdForRemoval) {
        removeFlowMutation({
          variables: {
            flowId: flowIdForRemoval,
          },
        });
      }
    },
    [flowId, removeFlowMutation],
  );

  return { removeFlow, loading };
};

interface FlowCloneProps {
  onCompleted?: (data?: CloneFlowMutation) => void;
  onError?: (error: ApolloError) => void;
}

export const useFlowClone = ({ onCompleted, onError }: FlowCloneProps) => {
  const botId = useCurrentBotId();
  const [cloneFlowMutation, { loading, called, error }] = useMutation<
    CloneFlowMutation,
    CloneFlowMutationVariables
  >(CLONE_FLOW_MUTATION, {
    refetchQueries: botId
      ? [
          { query: FLOW_GROUPS_QUERY, variables: { botId } },
          { query: FLOW_GROUPS_SUGGEST_QUERY, variables: { botId } },
          { query: FLOWS_QUERY, variables: { botId } },
        ]
      : [],
    awaitRefetchQueries: true,
    onCompleted,
    onError,
  });

  const cloneFlow = useCallback(
    (targetGroupId: string, flowId: string) => {
      if (flowId) {
        cloneFlowMutation({
          variables: { flowId, flowGroupId: targetGroupId },
        });
      }
    },
    [cloneFlowMutation],
  );

  return { cloneFlow, loading, called, error };
};

import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import {
  WorkspaceAdminsAndInvitesQuery,
  WorkspaceAdminsAndInvitesQueryVariables,
} from './@types/WorkspaceAdminsAndInvitesQuery';
import client from '@common/services/ApolloService';

export const WORKSPACE_ADMINS_AND_INVITES_QUERY = gql`
  query WorkspaceAdminsAndInvitesQuery($workspaceId: String!) {
    workspaceAdmins(workspaceId: $workspaceId) {
      id
      name
      pictureUrl
      roleId
      mixedPermissions
    }
    workspaceInvites(workspaceId: $workspaceId) {
      id
      author
      created
      roleId
      login
    }
  }
`;

export const getWorkspaceUsersAndInvites = (workspaceId: string) =>
  client.query<
    WorkspaceAdminsAndInvitesQuery,
    WorkspaceAdminsAndInvitesQueryVariables
  >({
    query: WORKSPACE_ADMINS_AND_INVITES_QUERY,
    variables: { workspaceId },
  });

export const useWorkspaceUsersAndInvites = (workspaceId: string) => {
  const { data, loading } = useQuery<
    WorkspaceAdminsAndInvitesQuery,
    WorkspaceAdminsAndInvitesQueryVariables
  >(WORKSPACE_ADMINS_AND_INVITES_QUERY, {
    variables: {
      workspaceId,
    },
  });

  return {
    admins: data?.workspaceAdmins,
    invites: data?.workspaceInvites,
    loading,
  };
};

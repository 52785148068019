import React, { useCallback } from 'react';
import { DefaultDialogProps } from '@ui/Dialog';
import { useCurrentBotId, useGoToWhatsappBroadcast } from '@utils/Routing';
import { useCreateBroadcast } from '../../hooks/useCreateBroadcast';
import { assert } from '@utils/Assert';
import { defaultErrorHandler } from '../../utils';
import { ChooseTemplateDialog } from '@components/ChooseWhatsappTempalteDialog';

export interface ChooseTemplateDialogProps
  extends Pick<DefaultDialogProps, 'onDismiss'> {}

export const ChooseTemplateDialogBroadcasts: React.FC<ChooseTemplateDialogProps> =
  ({ onDismiss }) => {
    const botId = useCurrentBotId()!;
    const goToBroadcast = useGoToWhatsappBroadcast();
    const [createBroadcast, { loading: createBroadcastLoading }] =
      useCreateBroadcast();

    const onSubmitClick = useCallback(
      (
        templateId: string | undefined,
        managedTemplateId: string | undefined,
      ) => {
        return createBroadcast({
          botId,
          templateId,
          managedTemplateId,
        })
          .then((executionResult) => {
            const broadcastId =
              executionResult!.data?.createWhatsappBroadcast.id;
            const flowId =
              executionResult!.data?.createWhatsappBroadcast.flowId;

            assert(broadcastId, { msg: 'Created broadcast must have "id"' });
            assert(flowId, { msg: 'Created broadcast must have "flowId"' });

            goToBroadcast(broadcastId, flowId);
          })
          .catch(
            defaultErrorHandler({
              msg: 'Failed to create broadcast',
              data: { botId, templateId },
            }),
          )
          .finally(() => onDismiss?.());
      },
      [createBroadcast, botId, goToBroadcast, onDismiss],
    );

    return (
      <ChooseTemplateDialog
        loading={createBroadcastLoading}
        onSubmit={onSubmitClick}
        onDismiss={onDismiss}
      />
    );
  };

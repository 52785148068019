import { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { OrderStatusUpdateStatus, TriggeredMessageType } from '@globals';
import { DateFormat } from '@utils/DateTime';
import {
  useEditBroadcastCache,
  UseEditBroadcastCacheProps,
} from './useEditBroadcastCache';
import { Repeat } from '../types';
import {
  calculateDaysOfWeek,
  INTERVAL_TO_UNIT,
  repeatTypeFromConfig,
  REPEAT_TYPES,
  secondsToTime,
  timeToSeconds,
  UNIT_TO_INTERVAL,
} from '../utils';
import { TIME_INTERVALS_ID_TO_QUANT_ID } from '@ui/TimeoutInput/TimeoutInputByTimestamp';
import { QUANT_MULTIPLEXER } from '@utils/DateTime/quant';
import { TimeIntervalsIds } from '@ui/TimeoutInput';
import { fromTimestampInSeconds } from '@utils/DateTime/DateUtils';

export interface UseReengageEditorPanelProps
  extends UseEditBroadcastCacheProps {}

export const useReengageEditorPanelProps = (
  props: UseReengageEditorPanelProps,
) => {
  const editBroadcast = useEditBroadcastCache(props);
  const [repeatValue, setRepeatValue] = useState<Repeat>(
    repeatTypeFromConfig(editBroadcast.config),
  );

  const onRepeatChange = useCallback(
    (item: { id: Repeat }) => {
      setRepeatValue(item.id);

      const action = {
        [Repeat.custom]: () =>
          editBroadcast.updateDaysOfWeek(REPEAT_TYPES[Repeat.custom]),
        [Repeat.everyMonth]: () =>
          editBroadcast.updateDayOfMonth(
            fromTimestampInSeconds(
              editBroadcast.config.schedule?.startingTimestamp!,
            ).getDate(),
          ),
        [Repeat.everyDay]: () =>
          editBroadcast.updateDaysOfWeek(REPEAT_TYPES[Repeat.everyDay]),
        [Repeat.weekends]: () =>
          editBroadcast.updateDaysOfWeek(REPEAT_TYPES[Repeat.weekends]),
        [Repeat.workdays]: () =>
          editBroadcast.updateDaysOfWeek(REPEAT_TYPES[Repeat.workdays]),
        [Repeat.none]: () => editBroadcast.updateDaysOfWeek(null),
      }[item.id];

      action();
    },
    [editBroadcast],
  );

  const sendInValue = useMemo(() => {
    const waitInterval =
      editBroadcast.config.triggerSettings?.waitInterval ?? 0;
    const interval =
      UNIT_TO_INTERVAL[
        editBroadcast.config.triggerSettings?.waitIntervalTimeUnit!
      ];
    const intervalId = TIME_INTERVALS_ID_TO_QUANT_ID[interval];
    const multiplexer = QUANT_MULTIPLEXER[intervalId];

    return waitInterval * multiplexer;
  }, [editBroadcast]);

  const dateValue = useMemo(() => {
    return moment(
      fromTimestampInSeconds(
        editBroadcast.config.schedule?.startingTimestamp ?? 0,
      ),
    );
  }, [editBroadcast]);

  const timeValue = useMemo(
    () =>
      DateFormat.HHmm(
        fromTimestampInSeconds(
          editBroadcast.config.schedule?.startingTimestamp ?? 0,
        ).valueOf(),
      ),
    [editBroadcast],
  );

  const triggerValue =
    editBroadcast.config.triggerSettings?.triggerType ||
    TriggeredMessageType.first_interaction;

  const orderStatusUpdateStatus =
    editBroadcast.config.triggerSettings?.orderStatusUpdateStatus ||
    OrderStatusUpdateStatus.in_transit;

  const onOrderStatusUpdateStatusChange = (type: OrderStatusUpdateStatus) =>
    editBroadcast.updateOrderStatusUpdateStatus(type);

  const onCustomWeekdayClick = useCallback(
    (index) =>
      editBroadcast.updateDaysOfWeek(
        calculateDaysOfWeek(editBroadcast.config.schedule?.daysOfWeek, index),
      ),
    [editBroadcast],
  );

  const onSendInValueChange = useCallback(
    (rawValue: number, interval: TimeIntervalsIds) =>
      editBroadcast.updpateTriggerSendIn(rawValue, INTERVAL_TO_UNIT[interval]),
    [editBroadcast],
  );

  const onSendInUnitChange = useCallback(
    (value: string | null) =>
      editBroadcast.updateSendInTime(value ? timeToSeconds(value) : null),
    [editBroadcast],
  );

  const sendInUnitValue = secondsToTime(
    editBroadcast.config.triggerSettings?.timeOfDay || 0,
  );

  return {
    editBroadcast,
    disabled: editBroadcast.disabled,
    dateValue,
    timeValue,
    triggerValue,
    sendInValue,
    repeatValue,
    onRepeatChange,
    onCustomWeekdayClick,
    onSendInValueChange,
    onSendInUnitChange,
    sendInUnitValue,
    orderStatusUpdateStatus,
    onOrderStatusUpdateStatusChange,
  };
};

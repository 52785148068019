import React from 'react';
import { Flex } from '@ui/Flex';
import { Type, TypeProps } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { Spacer } from '@ui/Spacer';
import { ReactComponent as FacebookSvg } from './facebook.svg';
import css from './Header.css';

interface HeaderProps {
  loading: boolean;
  isEcommerce: boolean;
}

const TextComponent: React.FC<{ color?: TypeProps['color'] }> = ({
  children,
  color,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  return (
    <Type
      size={isSmallScreenSize ? '18px' : '32px'}
      weight={isSmallScreenSize ? 'semibold' : 'bold'}
      color={color ?? 'baseNormal'}
    >
      {children}
    </Type>
  );
};

export const Header: React.FC<HeaderProps> = ({ loading, isEcommerce }) => {
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();

  const getTitle = () => {
    if (loading) return null;
    return isEcommerce ? (
      <div>
        <TextComponent>
          {t('pages.FirstSession.ActivateBotBlock.connectFacebookPageAnd')}{' '}
        </TextComponent>
        <TextComponent color="accent1Normal">
          {t('pages.FirstSession.ActivateBotBlock.startECommerceSales')}
        </TextComponent>
      </div>
    ) : (
      <div>
        <TextComponent>
          {t('pages.FirstSession.ActivateBotBlock.connectFacebookPageAndStart')}
        </TextComponent>
        {isSmallScreenSize ? ' ' : <br />}
        <TextComponent color="accent1Normal">
          {t('pages.FirstSession.ActivateBotBlock.exellentResults')}
        </TextComponent>
      </div>
    );
  };

  return (
    <Flex gapFactor={2} className={css.header}>
      <Flex
        flexDirection="column"
        justifyContent="center"
        className={css.titleColumn}
      >
        {getTitle()}
        <Spacer factor={2} />
      </Flex>
      <Flex
        className={css.titleColumn}
        flexDirection="column"
        alignItems="flex-end"
      >
        <Spacer factor={2} />
        <FacebookSvg className={css.facebookImage} />
        <Spacer factor={3} />
      </Flex>
    </Flex>
  );
};

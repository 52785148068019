import { Flex } from '@ui/Flex';
import { UserListItem } from '@components/ManageUsersDialog/UserListItem/UserListItem';
import { NestedMenuNodeDisplayMode } from '@ui/NestedMenu';
import { Type } from '@ui/Type';
import { isSomeEnum } from '@utils/isSomeEnum';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { RoleIds } from '../../../../../../components/settings/admins/RolesList';
import React from 'react';
import { getRoleTexts } from '../../getRoleTexts';
import { WorkspaceAdminsAndInvitesQuery_workspaceAdmins } from '../@types/WorkspaceAdminsAndInvitesQuery';
import { useChangeUserRoleIdInWorkspace } from '../useChangeUserRoleIdInWorkspace';
import { useDeleteUserFromWorkspace } from '../useDeleteUserFromWorkspace';
import css from './WorkspaceUserListItem.css';

interface WorkspaceUserListItemProps
  extends WorkspaceAdminsAndInvitesQuery_workspaceAdmins {
  workspaceId: string;
  workspaceTitle: string;
  adminId?: string;
}

const TitleElement = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) => {
  return (
    <Flex flexDirection="column">
      <div>{title}</div>
      <div className={css.subtitle}>{subtitle}</div>
    </Flex>
  );
};

export const WorkspaceUserListItem: React.FC<WorkspaceUserListItemProps> = ({
  id: userId,
  name,
  pictureUrl,
  roleId,
  workspaceId,
  workspaceTitle,
  adminId,
}) => {
  const { t } = useSafeTranslation();
  const [deleteUserFromWorkspace, { loading: deleting }] =
    useDeleteUserFromWorkspace(workspaceId);
  const [chandeUserRole, { loading: changingRole }] =
    useChangeUserRoleIdInWorkspace(workspaceId);
  const isYou = userId === adminId;

  const menuItems = isYou
    ? undefined
    : [
        {
          id: 'menu',
          titleElement: (
            <TitleElement
              title={workspaceTitle}
              subtitle={roleId ? getRoleTexts(roleId as RoleIds).title : ''}
            />
          ),
          displayMode: NestedMenuNodeDisplayMode.nested,
          children: [
            {
              id: RoleIds.admin,
              title: getRoleTexts(RoleIds.admin).title,
            },
            {
              id: RoleIds.editor,
              title: getRoleTexts(RoleIds.editor).title,
            },
            {
              id: RoleIds.marketer,
              title: getRoleTexts(RoleIds.marketer).title,
            },
            {
              id: RoleIds.operator,
              title: getRoleTexts(RoleIds.operator).title,
            },
            {
              id: RoleIds.viewer,
              title: getRoleTexts(RoleIds.viewer).title,
            },
          ],
        },
      ];

  const handleRemove = isYou
    ? undefined
    : () => deleteUserFromWorkspace({ variables: { workspaceId, userId } });

  const user = {
    id: userId,
    pictureUrl,
    name: isYou ? (
      <>
        {name}
        <Type size="15px" color="greyDark">
          {' '}
          - {t('BotList.you')}
        </Type>
      </>
    ) : (
      name
    ),
    subtitle: isSomeEnum(RoleIds, roleId) ? getRoleTexts(roleId).title : '',
  };

  return (
    <UserListItem
      pending={deleting || changingRole}
      onRemove={handleRemove}
      user={user}
      onMenuItemSelect={(item) =>
        chandeUserRole({ workspaceId, userId, roleId: item.id })
      }
      menuItems={menuItems}
    />
  );
};

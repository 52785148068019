import React from 'react';
import cn from 'classnames';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import {
  ComboboxWithTriangleButton,
  BaseComboboxItem,
} from '@ui/SimpleCombobox';
import * as commonCSS from './common.css';
import * as css from './SendThisMessageControl.css';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Disableable } from '../types';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';

interface SendThisMessageControlProps<T> extends Disableable {
  item: T;
  items: Array<T>;
  onChange: (selectedItem: T) => void;
  loading?: boolean;
}

export const SendThisMessageControl = <T extends BaseComboboxItem>({
  item,
  items,
  disabled,
  onChange,
  loading,
}: SendThisMessageControlProps<T>) => {
  const { t } = useSafeTranslation();

  return (
    <PluginControlLabel
      label={t('pages.BotPage.ReengageWhatsappTab.panel.sendThisMessage.label')}
    >
      {() =>
        loading ? (
          <div className={cn(commonCSS.dropdown, commonCSS.dropdownLoader)}>
            <LoadingPlaceholder color="baseTertiary" width={124} height={8} />
          </div>
        ) : (
          <ComboboxWithTriangleButton
            disabled={disabled}
            itemToString={(item) => item?.title || ''}
            selectedItem={item}
            className={cn(commonCSS.dropdown, css.dropdown)}
            items={items}
            onChange={onChange}
            data-testid="reengage-editor__send-dropdown"
          />
        )
      }
    </PluginControlLabel>
  );
};

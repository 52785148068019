import { FormikErrors } from 'formik';
import {
  MessagesTemplateButtonInput,
  MessagesTemplateContentInput,
  MessagesTemplateInput,
  TemplateButtonType,
} from '@globals';
import { GetMessageTemplate_messageTemplate } from '@pages/BotPage/MessagesTemplatesTab/data/@types/GetMessageTemplate';
import { GetMessageTemplateLanguages_messageTemplateLanguages } from '@pages/BotPage/MessagesTemplatesTab/data/@types/GetMessageTemplateLanguages';

export interface TemplateButton extends MessagesTemplateButtonInput {
  id: string;
}

export interface MessagesTemplateSectionType {
  type: ButtonSectionType;
  title: string;
  children: TemplateButton[];
}

export interface MessagesTemplateRawType
  extends GetMessageTemplate_messageTemplate {}
export interface MessagesTemplateContentType
  extends Omit<MessagesTemplateContentInput, 'buttons'> {
  sections: Array<MessagesTemplateSectionType>;
}
export interface MessagesTemplateFormType
  extends Omit<MessagesTemplateInput, 'content'> {
  content: MessagesTemplateContentType;
}

export enum ButtonSectionType {
  callToAction = 'callToAction',
  quickReply = 'quickReply',
}

export interface ButtonTreeType {
  type: ButtonSectionType;
  title: string;
  children: TemplateButton[];
}

export interface ItemButton {
  id: TemplateButtonType;
  title: string;
  comment?: string;
}

export type ButtonErrors = FormikErrors<
  Pick<
    MessagesTemplateButtonInput,
    'text' | 'url' | 'phone_number' | 'attribute_examples'
  >
>;

export type AttributeExamples = any;

export interface MessagesTemplateLanguage
  extends GetMessageTemplateLanguages_messageTemplateLanguages {}

import { AnchorLoading } from '@ui/CollapsibleSection/CollapsibleSectionWithShadow/Anchor';
import React from 'react';
import { BroadcastTypes } from '../Broadcast';
import {
  OneTimeSettingsBlock,
  OneTimeSettingsBlockProps,
} from './OneTimeSettingsBlock';
import {
  ScheduleSettingsBlock,
  ScheduleSettingsBlockProps,
} from './ScheduleSettingsBlock';
import {
  TriggerSettingsBlock,
  TriggerSettingsBlockProps,
} from './TriggerSettingsBlock';
import { useIsShopifyV3 } from '@utils/Integrations/Shopify/useIsShopifyV3';
import { SendInControl, SendInControlProps } from './SendInControl';
import {
  SendInUnitsControl,
  SendInUnitsControlProps,
} from './SendInUnitsControl';
import { TriggeredMessageIntervalTimeUnit } from '@globals';
import { ShopifyControl, ShopifyControlProps } from './ShopifyControl';
import { Spacer } from '@ui/Spacer';
import { isShopifyTrigger } from '../utils';

interface TriggerWrapperProps extends ShopifyControlProps {}

const TriggerWrapper: React.FC<TriggerWrapperProps> = ({
  children,
  ...props
}) => {
  const isShopifyTriggers = isShopifyTrigger(props.triggerValue);
  const { isShopifyV3 } = useIsShopifyV3();

  if (isShopifyV3 && isShopifyTriggers) {
    return <ShopifyControl {...props}>{children}</ShopifyControl>;
  }

  return <>{children}</>;
};

export interface SettingsBlocksFactoryProps
  extends ScheduleSettingsBlockProps,
    OneTimeSettingsBlockProps,
    TriggerSettingsBlockProps,
    SendInUnitsControlProps,
    SendInControlProps,
    ShopifyControlProps {
  sendInValueUnit: TriggeredMessageIntervalTimeUnit;
  from: BroadcastTypes;
  loading?: boolean;
}

export const SettingsBlocksFactory: React.FC<SettingsBlocksFactoryProps> = ({
  from,
  loading,
  sendInUnitValue,
  onSendInUnitChange,
  sendInValueUnit,
  sendInValue,
  onSendInValueChange,
  orderStatusUpdateStatus,
  onOrderStatusUpdateStatusChange,
  ...props
}) => {
  if (loading) {
    return <AnchorLoading />;
  }

  return {
    [BroadcastTypes.schedule]: <ScheduleSettingsBlock {...props} />,
    [BroadcastTypes.trigger]: (
      <TriggerSettingsBlock {...props}>
        <TriggerWrapper
          disabled={props.disabled}
          triggerValue={props.triggerValue}
          orderStatusUpdateStatus={orderStatusUpdateStatus}
          onOrderStatusUpdateStatusChange={onOrderStatusUpdateStatusChange}
        >
          <SendInControl
            disabled={props.disabled}
            sendInValue={sendInValue}
            onSendInValueChange={onSendInValueChange}
          >
            {sendInValueUnit === TriggeredMessageIntervalTimeUnit.day && (
              <SendInUnitsControl
                disabled={props.disabled}
                sendInUnitValue={sendInUnitValue}
                onSendInUnitChange={onSendInUnitChange}
              />
            )}
          </SendInControl>
          <Spacer factor={2.5} />
        </TriggerWrapper>
      </TriggerSettingsBlock>
    ),
    [BroadcastTypes.oneTime]: <OneTimeSettingsBlock {...props} />,
  }[from];
};

import {
  InviteAdminDialog,
  InviteAdminDialogProps,
} from '@components/InviteAdminDialog/InviteAdminDialog';
import { getInviteLink } from '@utils/Data/Invite/getInviteLink';
import { useCreateBotInvite } from '@utils/Data/Invite/useCreateBotInvite';
import React from 'react';
import { DEFAULT_ROLES } from '../../consts';

interface InviteBotAdminDialogProps {
  botId: string;
  title: string;
  onClose: InviteAdminDialogProps['onClose'];
}

export const InviteBotAdminDialog: React.FC<InviteBotAdminDialogProps> = ({
  botId,
  title,
  onClose,
}) => {
  const [createInvite, { loading, data }] = useCreateBotInvite();
  const handleSelectRole: InviteAdminDialogProps['onRoleSelect'] = (roleId) => {
    createInvite({
      variables: {
        botId,
        roleId,
      },
    });
  };
  const inviteLink = data ? getInviteLink(data.createBotInvite) : undefined;

  return (
    <InviteAdminDialog
      items={DEFAULT_ROLES}
      title={title}
      onClose={onClose}
      onRoleSelect={handleSelectRole}
      inviteLoading={loading}
      inviteLink={inviteLink}
    />
  );
};

import { useBillingData } from '../Data/useBillingData';
import { useBotSectionData } from '../Data/useBotSectionData';
import { usePaymentData } from '../Data/usePaymentData';
import { PlanButtonType } from './types';
import { TierType } from '@globals';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { useTimeLimitedTrial } from '@utils/Data/Pricing/useTimeLimitedTrial';

export const usePlanButtonType = (): PlanButtonType => {
  const { planLimitReached, shouldOfferSubscription, pricing } =
    useBillingData();
  const { isWithoutPage, isPaused } = useBotSectionData();
  const { isWhatsappEnabled } = useWhatsappEnabled();
  const { paymentError } = usePaymentData();
  const { currentTierTimeLimitedTrial, trialExpired } = useTimeLimitedTrial();

  const paymentIsNotCanceled =
    pricing?.next_tier ||
    pricing?.current_tier !== TierType.trial ||
    pricing?.hasDebt;

  if (paymentError?.isPaymentFailed && paymentIsNotCanceled) {
    return PlanButtonType.updateCard;
  }

  if (isPaused) {
    return PlanButtonType.paused;
  }

  if (isWithoutPage && !isWhatsappEnabled) {
    return PlanButtonType.withoutPage;
  }

  if (
    currentTierTimeLimitedTrial &&
    pricing?.current_tier === TierType.trial &&
    trialExpired
  ) {
    return PlanButtonType.timeLimitedTrialExpired;
  }

  if (planLimitReached?.isLimitReached) {
    return PlanButtonType.limitReached;
  }

  if (shouldOfferSubscription) {
    return PlanButtonType.offerSubscription;
  }

  return PlanButtonType.default;
};

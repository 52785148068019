import React from 'react';
import { RoleConsumer } from '@utils/Roles';
import { Spacer } from '@ui/Spacer';
import { Permission } from '@common/services/RoleService';
import { BotTabCentered } from '@components/BotTab';
import { FacebookPage } from '../../../GrowPage/FacebookPage';
import { InstagramAccount } from '../../../GrowPage/InstagramAccount';
import { HomeBannersGroup } from '../../../GrowPage/HomeBannersGroup/HomeBannersGroup';
import { TempaltesPanel } from './TemplatesPanel';
import { LegacyBlocks } from './LegacyBlocks';
import { BotPanel } from './BotSectionView/BotPanel';
import { PaymentFailedCallout } from './PaymentFailedCallout';
import { AIStatisticsBlock } from './AIStatistics';
import { ChangeTierPanel } from './ChangeTierPanel';
import { WhatsappAccount } from './WhatsappAccount';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import * as css from './HomeTabView.css';
import { MiniOnboardingBanner } from '@components/MiniOnboarding/MiniOnboardingBanner';
import { useAdminFeatures } from '@utils/Data/Admin';
import { WhatsappBotNotCreated } from './WhatsappAccount/WhatsappBotNotCreated/WhatsappBotNotCreated';
import { ShopifyPaymentView } from '@components/ShopifyPaymentView/ShopifyPaymentView';
import { Flex } from '@ui/Flex';

export const HomeTabView: React.FC = () => {
  const { isWhatsappEnabled } = useWhatsappEnabled();
  const { adminFeatures } = useAdminFeatures();

  return (
    <BotTabCentered>
      <Flex flexDirection="column">
        <ShopifyPaymentView />
        <Spacer factor={10} />
        {adminFeatures?.entry_point_tips && (
          <MiniOnboardingBanner className={css.miniOnboardingBanner} />
        )}
      </Flex>

      <PaymentFailedCallout className={css.paymentFailed} />

      <ChangeTierPanel />

      <BotPanel />

      <RoleConsumer domain="pro" can={Permission.VIEW}>
        {isWhatsappEnabled ? (
          <WhatsappAccount />
        ) : (
          <div className={css.platforms}>
            <FacebookPage />
            <InstagramAccount />
            <WhatsappBotNotCreated />
          </div>
        )}
      </RoleConsumer>

      <Spacer factor={10} />

      {!isWhatsappEnabled && (
        <>
          <AIStatisticsBlock />
          <Spacer factor={10} />
          <RoleConsumer domain="pro" can={Permission.EDIT}>
            <TempaltesPanel />
          </RoleConsumer>
        </>
      )}

      <HomeBannersGroup />

      <Spacer factor={8} />

      {!isWhatsappEnabled && <LegacyBlocks />}
    </BotTabCentered>
  );
};

import React from 'react';
import { BotListItem, BotListItemLoader } from '@ui/BotListItem';
import { useAdminId } from '@utils/Admin/useAdminId';
import { WorkspaceStatistics } from '@components/Workspace/WorkspaceStatistics/WorkspaceStatistics';
import { BotListMenuItem, BotListMenuItemType } from '../types';
import { useRenameBot } from '@components/PageHeader/BotHeader/components/BotNameInput/useRenameBot';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { BotTabs, useGoToTab } from '@utils/Routing';
import { useBotActions } from './useBotActions';
import { BotProps } from './types';
import { useBotMenuItems } from './useBotMenuItems';
import { ChargingEntityType } from '@globals';
import { isBotPro, isBotProInWorkspace } from '@utils/Pro';
import { useBotOptionsHandler } from './useBotOptionsHandler';
import { useBotAdminsLazy } from './useBotAdminsLazy';
import css from './Bot.css';

export const Bot: React.FC<BotProps> = (props) => {
  const { bot, currentWorkspace } = props;
  const { id: botId, status, title, dialogsPricing } = bot;
  const page = status?.page_info;
  const { t } = useSafeTranslation();
  const goToTab = useGoToTab(bot.id);
  const updateBotTitle = useRenameBot({
    eventCategory: 'workspace page',
  });
  const [getAdmins, { admins, loading }] = useBotAdminsLazy();
  const { loading: botActionLoading, ...botAction } = useBotActions(
    bot,
    Boolean(currentWorkspace),
  );
  const getMenuItems = useBotMenuItems({ botId });
  const { id } = useAdminId();
  const {
    handleOptionSelect,
    exitRenameMode,
    isRenameMode,
    loading: handlerLoading,
  } = useBotOptionsHandler({
    userId: id!,
    currentWorkspaceId: currentWorkspace?.id,
  });

  const handleTitleChange = (newTitle: string) => {
    exitRenameMode();
    updateBotTitle({ id: botId, title }, newTitle);
  };

  if (botActionLoading || handlerLoading)
    return <BotListItemLoader className={css.botListItem} />;

  const menuItems = getMenuItems({ ...bot, admins }, props);

  return (
    <BotListItem<BotListMenuItemType<BotListMenuItem>>
      key={botId}
      platforms={bot.allowedPlatforms || undefined}
      className={css.botListItem}
      isBotPro={
        isBotProInWorkspace(bot, currentWorkspace) || isBotPro(bot) || false
      }
      connectedPageInfo={page}
      sections={
        page
          ? [
              <WorkspaceStatistics
                key="workspaceConversation"
                className={css.conversation}
                maxCount={
                  currentWorkspace ? null : dialogsPricing.current_tier_limit
                }
                count={dialogsPricing.reached_limit ?? 0}
                chargingEntityLabel={
                  dialogsPricing.charging_entity_type ===
                  ChargingEntityType.users
                    ? t('BotList.users')
                    : t('BotList.conversations')
                }
              />,
            ]
          : null
      }
      botTitle={title}
      onMenuItemSelect={(selectedItem) =>
        handleOptionSelect(
          { ...bot, workspace_id: currentWorkspace?.id },
          selectedItem,
        )
      }
      menuItems={menuItems}
      onTitleChange={handleTitleChange}
      titleEditing={isRenameMode(bot)}
      onBotSelect={() => goToTab(BotTabs.home)}
      loadingMenuBoxClassName={css.loadingMenuBox}
      menuLoading={loading}
      onMenuClick={() => getAdmins({ botId: bot.id })}
      {...botAction}
    />
  );
};

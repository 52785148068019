import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import Maybe from 'graphql/tsutils/Maybe';
import React from 'react';

export interface WorkspaceNameProps {
  workspaceName: Maybe<string>;
}

export const WorkspaceName: React.FC<WorkspaceNameProps> = ({
  workspaceName,
}) => {
  return workspaceName ? (
    <Type
      size="18px"
      weight="medium"
      color="greyDark"
      data-testid="workspace_name"
    >
      {workspaceName}
    </Type>
  ) : (
    <Spacer factor={6} />
  );
};

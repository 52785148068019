import React, { useCallback, useState } from 'react';
import { clone } from 'ramda';
import { ButtonUnstyled } from '@ui/Button';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './KeywordsInput.css';
import { KeywordInputItem } from './componens/KeywordInputItem';
import { sendEvent } from '@utils/Analytics';
import { KeywordsPlacements } from '../../../../../../consts';
import { ColorKey } from '@ui/_common/colors';
import { Platform } from '@globals';

interface KeywordsInputProps {
  initLines: string[];
  platform: Platform;
  onChange: (lines: string[]) => void;
  placement: KeywordsPlacements;
  itemsColor?: ColorKey;
}

export const KeywordsInput: React.FC<KeywordsInputProps> = ({
  initLines,
  platform,
  onChange,
  placement,
  itemsColor,
}) => {
  const { t } = useSafeTranslation();
  const [newKeywordValue, setNewKeywordValue] = useState<undefined | string>();
  const [addButtonAutoFocus, setAddButtonAutoFocus] = useState<boolean>(false);
  const isAddKeywordState = newKeywordValue !== undefined;
  const [lines, setLines] = useState<string[]>(initLines);

  const updateLines = useCallback(
    (callback: (lines: string[]) => string[]) =>
      setLines((prevLines) => {
        const updatedLines = callback(clone(prevLines));
        onChange(updatedLines);
        return updatedLines;
      }),
    [onChange],
  );

  return (
    <>
      {lines.map((line, i) => {
        const key = `${lines.length}_${i}`; // for width rerender after center item remove
        return (
          <KeywordInputItem
            platform={platform}
            placement={placement}
            key={key}
            value={line}
            color={itemsColor}
            onBlur={(value) => {
              updateLines((updatedLines) => {
                // eslint-disable-next-line no-param-reassign
                updatedLines[i] = value;
                return updatedLines;
              });
            }}
            onDeleteRequest={() => {
              setTimeout(() => {
                updateLines((updatedLines) => {
                  // eslint-disable-next-line no-param-reassign
                  updatedLines.splice(i, 1);
                  return updatedLines;
                });
              });
            }}
          />
        );
      })}
      {isAddKeywordState && (
        <KeywordInputItem
          platform={platform}
          placement={placement}
          value={newKeywordValue || ''}
          onChange={setNewKeywordValue}
          color={itemsColor}
          onBlur={(value) => {
            if (value) {
              updateLines((updatedLines) => [...updatedLines, value || '']);
              setAddButtonAutoFocus(true);
            }
            setNewKeywordValue(undefined);
          }}
          onDeleteRequest={() => {
            setNewKeywordValue(undefined);
          }}
          autoFocus
        />
      )}
      {!isAddKeywordState && (
        <ButtonUnstyled
          onClick={() => {
            setAddButtonAutoFocus(false);
            setNewKeywordValue('');
            sendEvent({
              category: placement,
              label: 'keywords groups',
              action: 'add keyword item click',
            });
          }}
          className={css.addButton}
          autoFocus={addButtonAutoFocus}
          data-testid={`${placement}__keywords-groups__add-keyword-button`}
        >
          <Type color="black" size="15px">
            {t('pages.Keywords.KeywordGroups.AddKeyword')}
          </Type>
        </ButtonUnstyled>
      )}
    </>
  );
};

import React from 'react';
import { Input, InputProps } from '@ui/Input';
import { InputMenuCombobox } from '@ui/SimpleCombobox';
import { combineRefs } from '@utils/combineRefs';
import { MenuItem } from '@ui/Menu';
import { useCurrentBotId } from '@utils/Routing';
import { Platform, VariablesType } from '@globals';
import { useAttributes } from '@utils/AttributesUtils/AttributesData';
import { VariableSuggestType } from '@utils/AttributesUtils/AttributesUtilsTypes';

interface EditAttributeNameProps {
  platform: Platform;
  onChange: (name: string) => void;
  value: string;
  excludedAttributeNames?: string[];
  inputProps?: InputProps;
}

export const EditAttributeName: React.FC<EditAttributeNameProps> = ({
  platform,
  excludedAttributeNames = [],
  onChange,
  value,
  inputProps = {},
}) => {
  const botId = useCurrentBotId();

  const excludedNames = new Set(excludedAttributeNames);

  const { attributes } = useAttributes(
    botId,
    VariableSuggestType.template,
    platform,
    excludedAttributeNames,
  );

  const customAttributesSuggestions = (attributes || [])
    .filter((suggest) => suggest.type === VariablesType.custom)
    .filter((suggest) =>
      suggest.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
    )
    .filter((suggest) => !excludedNames.has(suggest.name));

  return (
    <InputMenuCombobox
      autoSelectFirstItem
      onChange={(item) => {
        onChange(item?.name || '');
      }}
      items={customAttributesSuggestions}
      renderInput={({ popperReference, downshift, props }) => (
        <Input
          ref={combineRefs([popperReference.ref, props.inputRef])}
          {...downshift.getInputProps({
            onFocus: () => {
              downshift.openMenu();
              props.selectInputValue();
            },
          })}
          {...inputProps}
          value={value}
          onChange={(ev) => onChange(ev.target.value || '')}
        />
      )}
      renderEmptyState={() => null}
    >
      {({
        downshift: { getItemProps, highlightedIndex },
        props: { item, index },
      }) => (
        <MenuItem
          title={item.name}
          active={index === highlightedIndex}
          key={item.name}
          {...getItemProps({ item })}
        />
      )}
    </InputMenuCombobox>
  );
};

import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { isMobileDevice } from 'cf-common/src/utils/isMobileDevice';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { LSKey, useLocalStorage } from '@utils/LocalStorage';
import { ReactComponent as RingIcon } from './ring.svg';
import * as css from './FlowBuilderMobileWarning.css';

interface FlowBuilderMobileWarningViewProps {
  onClick: () => void;
}

export const FlowBuilderMobileWarningView: React.FC<FlowBuilderMobileWarningViewProps> =
  ({ onClick }) => {
    const { t } = useSafeTranslation();

    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        className={css.wrapper}
      >
        <RingIcon />

        <Spacer factor={6} />

        <Type size="24px" weight="bold" align="center">
          {t('pages.BotPage.FlowTab.FlowBuilderMobileWarning.title')}
        </Type>

        <Spacer factor={4} />

        <Type size="15px" align="center">
          {t('pages.BotPage.FlowTab.FlowBuilderMobileWarning.description')}
        </Type>

        <Spacer factor={6} />

        <Button onClick={onClick} intent="primary">
          {t('pages.BotPage.FlowTab.FlowBuilderMobileWarning.buttonTitle')}
        </Button>
      </Flex>
    );
  };

export const FlowBuilderMobileWarning: React.FC = ({ children }) => {
  const isMobile = isMobileDevice();
  const [showFlowBuilderOnMobile, setShowFlowBuilderOnMobile] = useLocalStorage(
    LSKey.showFlowBuilderOnMobile,
  );

  if (!isMobile || showFlowBuilderOnMobile) {
    return <>{children}</>;
  }

  return (
    <FlowBuilderMobileWarningView
      onClick={() => setShowFlowBuilderOnMobile(true)}
    />
  );
};

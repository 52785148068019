import React, { useCallback, useMemo, useState } from 'react';
import { Modal } from '@services/index';
import Maybe from 'graphql/tsutils/Maybe';
import { useCurrentBotId } from '@utils/Routing';
import {
  isWhatsappConnected,
  useUpdateWhatsappSettings,
  useWhatsappPhoneEntries,
  useWhatsappSettings,
  WhatsappPhone,
  WhatsappPhoneEntry,
} from '@utils/Data/Whatsapp';
import { sendEvent } from '@utils/Analytics';
import { useWhatsappLogin } from '@pages/MultiSystemAuth/hooks/useWhatsappLogin';

import {
  EnterVerificationCodeDialog,
  RequestNotificationCodeDialog,
} from '../WhatsappInitialized/PhoneVerification';
import { useRequestWhatsappVerificationCode } from '../WhatsappInitialized/PhoneVerification/hooks';
import { useShowWhatsappBuyPhoneDialog } from '../components/WhatsappBuyPhoneDialog/hooks/useShowWhatsappBuyPhoneDialog';

export interface VerificationDialogProps {
  phone: WhatsappPhone;
  close: VoidFunction;
  onSubmit: VoidFunction;
}

export const VerificationDialog: React.FC<VerificationDialogProps> = ({
  phone,
  close,
  onSubmit,
}) => {
  const [codeSent, setCodeSent] = useState(false);

  const { requestCode, errorType, called, error } =
    useRequestWhatsappVerificationCode();

  if (!codeSent) {
    return (
      <RequestNotificationCodeDialog
        phoneNumber={phone.display_phone_number!}
        onDismiss={close}
        onSubmit={() => {
          setCodeSent(true);
          requestCode({ phoneId: phone.id });
        }}
      />
    );
  }

  return (
    <EnterVerificationCodeDialog
      requestCode={(phoneId) => requestCode({ phoneId })}
      called={called}
      error={error}
      errorType={errorType}
      onDismiss={close}
      onSubmit={onSubmit}
      phoneNumber={phone.display_phone_number!}
      phoneId={phone.id}
    />
  );
};

const tryFindPhoneEntryByPhone = (
  whatsappPhoneEntries: Maybe<WhatsappPhoneEntry[]>,
  phoneId: Maybe<string>,
) => {
  return whatsappPhoneEntries?.find(({ phones }) =>
    phones?.some(({ id }) => id === phoneId),
  );
};

export const useWhatsappConnectionState = () => {
  const botId = useCurrentBotId()!;

  const {
    whatsappAccounts,
    whatsappPhoneEntries,
    loading: whatsappPhoneEntriesLoading,
  } = useWhatsappPhoneEntries(botId);
  const { whatsappSettings, loading: whatsappSettingsLoading } =
    useWhatsappSettings(botId);
  const { onWhatsappLogin, loading } = useShowWhatsappBuyPhoneDialog();

  const { whatsappLogin, tokenIsUpdating, refetchWhatsappData } =
    useWhatsappLogin({ botId });

  const [pickedPhone, setPickedPhone] = useState<WhatsappPhone>();

  const currentAccount = useMemo(() => {
    const pickedAccount =
      pickedPhone &&
      tryFindPhoneEntryByPhone(whatsappPhoneEntries, pickedPhone.id)
        ?.businessAccount;

    return (
      pickedAccount ??
      (
        whatsappSettings?.phone &&
        tryFindPhoneEntryByPhone(
          whatsappPhoneEntries,
          whatsappSettings?.phone?.id,
        )
      )?.businessAccount ??
      whatsappAccounts[0]
    );
  }, [pickedPhone, whatsappAccounts, whatsappPhoneEntries, whatsappSettings]);

  const currentAccountPhones = useMemo(() => {
    return (
      whatsappPhoneEntries?.find(
        ({ businessAccount }) => businessAccount.id === currentAccount.id,
      )?.phones ?? []
    );
  }, [whatsappPhoneEntries, currentAccount]);

  const currentPhone: WhatsappPhone | undefined = useMemo(() => {
    return (
      pickedPhone ??
      (whatsappSettings?.phone &&
        currentAccountPhones.find(
          ({ id }) => id === whatsappSettings?.phone?.id,
        )) ??
      currentAccountPhones[0]
    );
  }, [pickedPhone, whatsappSettings, currentAccountPhones]);

  const { updateWhatsappSettings, loading: updating } =
    useUpdateWhatsappSettings(botId);

  const currentAccountId = currentAccount?.id;
  const handleUpdatePhone = useCallback(() => {
    if (!currentPhone) return;

    updateWhatsappSettings({
      phoneId: currentPhone.id,
      whatsappBusinessAccountId: currentAccountId,
    });

    sendEvent({
      category: 'whatsapp',
      action: 'confirm phone click',
      propertyBag: {
        botId,
        phoneId: currentPhone.id,
        whatsappBusinessAccountId: currentAccountId,
      },
    });
  }, [currentPhone, botId, currentAccountId, updateWhatsappSettings]);

  const handleNoPhoneConnectedClick = useCallback(() => {
    sendEvent({
      category: 'whatsapp',
      action: 'connect phone',
      propertyBag: {
        whatsappBusinessAccountId: currentAccountId,
      },
    });
    onWhatsappLogin({ onWhatsappLoginRequest: () => whatsappLogin() });
  }, [currentAccountId, onWhatsappLogin, whatsappLogin]);

  const handlePhoneVerificationClick = useCallback(
    (itemPhone?: WhatsappPhone) => {
      const phone = itemPhone || currentPhone;
      if (!phone) {
        whatsappLogin();
        return;
      }
      sendEvent({
        category: 'whatsapp',
        action: 'verify phone',
        propertyBag: {
          whatsappBusinessAccountId: currentAccountId,
        },
      });

      Modal.show(
        ({ close }) => (
          <VerificationDialog
            phone={phone}
            close={close}
            onSubmit={() => {
              updateWhatsappSettings({
                phoneId: currentPhone.id,
                whatsappBusinessAccountId: currentAccountId,
              })?.then(refetchWhatsappData);
              close();
            }}
          />
        ),
        {
          mobileAdaptive: true,
          mobileProps: {
            fitHeight: true,
          },
        },
      );
    },
    [
      currentAccountId,
      currentPhone,
      refetchWhatsappData,
      updateWhatsappSettings,
      whatsappLogin,
    ],
  );

  const handlePickPhone = useCallback(
    (next: WhatsappPhone) => {
      sendEvent({
        category: 'whatsapp',
        action: 'select phone',
        propertyBag: {
          botId,
          phoneId: next.id,
        },
      });
      setPickedPhone(next);
    },
    [botId],
  );

  return {
    isConnected: isWhatsappConnected(whatsappSettings),
    isConfirmed: Boolean(
      currentPhone && whatsappSettings?.phone?.id === currentPhone.id,
    ),
    whatsappPhoneEntries,
    currentAccount,
    currentPhone,
    handlePickPhone,
    handleUpdatePhone,
    handleNoPhoneConnectedClick,
    handlePhoneVerificationClick,
    tokenIsUpdating,
    updating,
    loading: loading || whatsappPhoneEntriesLoading || whatsappSettingsLoading,
  };
};

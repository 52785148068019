import React, { CSSProperties, useState } from 'react';
import { Autofocus } from '@ui/Autofocus';
import * as css from './EditFlowItem.css';

const TITLE_SYMBOLS_LIMIT = 40;

interface EditFlowItem {
  onSubmit(title: string): void;
  onDismiss?(): void;
  defaultValue?: string;
  disabled?: boolean;
  style?: CSSProperties;
}

export const EditFlowItem: React.FC<EditFlowItem> = ({
  onSubmit,
  onDismiss,
  defaultValue,
  disabled,
  style,
}) => {
  const [title, setTitle] = useState(defaultValue ?? '');

  const handleSubmit = () => {
    const trimmedTitle = title.trim();
    if (trimmedTitle) {
      onSubmit(trimmedTitle);
    } else {
      onDismiss?.();
    }
  };

  return (
    <Autofocus
      render={({ bind }) => (
        <input
          {...bind}
          className={css.input}
          style={style}
          name="itemTitle"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          onBlur={handleSubmit}
          onKeyDown={(event) => {
            switch (event.key) {
              case 'Enter':
                return handleSubmit();
              case 'Escape':
                return onDismiss?.();
              default:
                return undefined;
            }
          }}
          autoComplete="off"
          maxLength={TITLE_SYMBOLS_LIMIT}
          disabled={disabled}
        />
      )}
      selectAll
    />
  );
};

import React, { useCallback } from 'react';
import cn from 'classnames';
import { DefaultDialog } from '@ui/Dialog';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { WhatsappPhone } from '@utils/Data/Whatsapp';
import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { download } from '@utils/download';
import { removeNonDigits } from '@utils/String/removeNonDigits';
import { sendEvent } from '@utils/Analytics';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import * as css from './WhatsappPhoneQrDialog.css';
import { useQrCodeUrl } from '../../../../../../utils/QrCodes/useQrCodeUrl';

interface WhatsappPhoneQrDialogProps {
  close(): void;
  currentPhone: WhatsappPhone;
}

export const WhatsappPhoneQrDialog: React.FC<WhatsappPhoneQrDialogProps> = ({
  close,
  currentPhone,
}) => {
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();
  const phone = currentPhone.display_phone_number;
  const phoneUrl = phone && `https://wa.me/${removeNonDigits(phone)}`;
  const { qrCodeUrl } = useQrCodeUrl(phoneUrl);

  const downloadQrCode = useCallback(() => {
    if (!qrCodeUrl || !phone) return;
    sendEvent({
      category: 'whatsapp',
      action: 'download qr code',
      propertyBag: {
        phoneId: currentPhone.id,
      },
    });
    download({
      url: qrCodeUrl,
      fileName: phone,
      preserveObjectUrl: true,
    });
  }, [qrCodeUrl, phone, currentPhone.id]);

  return (
    <DefaultDialog
      header={t('pages.BotPage.HomeTab.Whatsapp.QrCode')}
      footer={
        <div className={cn({ [css.mobileFooter]: isSmallScreenSize })}>
          <Button
            icon={<Icon icon="download" />}
            intent="secondary"
            fullWidth
            onClick={downloadQrCode}
            data-testid="whatsapp-qr-dialog__download"
          >
            {t('pages.BotPage.HomeTab.Whatsapp.downloadQrCode')}
          </Button>
        </div>
      }
      dialogStyle={{ width: '400px' }}
      onDismiss={close}
      mobileAdaptive={isSmallScreenSize}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        className={css.qrBoxWrapper}
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          className={cn(css.qrBox, { [css.qrBoxMobile]: isSmallScreenSize })}
        >
          {qrCodeUrl && <img alt="" height={100} width={100} src={qrCodeUrl} />}
        </Flex>
      </Flex>
      <Spacer factor={4} />
      <Type>{t('pages.BotPage.HomeTab.Whatsapp.downloadQrCodeNote')}</Type>
    </DefaultDialog>
  );
};

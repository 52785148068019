import React, { useMemo } from 'react';
import * as css from './Status.css';
import { Color, ColorKey } from '@ui/_common/colors';
import { TextEllipsis } from '@ui/TextEllipsis';
import { Type } from '@ui/Type';
import { WhatsappTemplateStatus } from '@globals';
import { Anchor } from '@ui/Links';
import { Tooltip2 } from '@ui/Tooltip2';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Spacer } from '@ui/Spacer';
import { Icon } from '@ui/Icon';
import { Flex } from '@ui/Flex';

interface StatusProps {
  name: string;
  statusColor: ColorKey;
  textColor: ColorKey;
  status: WhatsappTemplateStatus;
}

export const Status: React.FC<StatusProps> = ({
  name,
  textColor,
  statusColor,
  status,
}) => {
  const { t } = useSafeTranslation();

  const out = useMemo(
    () => (
      <Flex
        alignItems="center"
        gapFactor={1}
        className={css.wrapper}
        style={{ backgroundColor: Color[statusColor] }}
      >
        <TextEllipsis>
          <Type size="15px" color={textColor}>
            {name}
          </Type>
        </TextEllipsis>
        {status === WhatsappTemplateStatus.REJECTED && (
          <Icon
            icon="circleWarning"
            size="20px"
            color="red"
            className={css.icon}
          />
        )}
      </Flex>
    ),
    [name, statusColor, textColor, status],
  );

  if (status !== WhatsappTemplateStatus.REJECTED) {
    return out;
  }

  return (
    <Tooltip2
      content={
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div>{t('pages.MessagesTemplates.Table.reject.text')}</div>
          <Spacer factor={2} />
          <Anchor
            href="https://developers.facebook.com/docs/whatsapp/message-templates/guidelines"
            className={css.link}
            externalLinkClassName={css.linkIcon}
            noUnderline
            intent="external"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('pages.MessagesTemplates.Table.reject.link')}
          </Anchor>
        </div>
      }
      boundariesElement="viewport"
      hoverable
    >
      {(ref, bind) => (
        <div ref={ref} {...bind} className={css.tooltipHover}>
          {out}
        </div>
      )}
    </Tooltip2>
  );
};

import { PREMIUM_BILLING_PATH } from '@pages/Billing/PremiumBillingPage/constants';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export const useGoPremiumBillingPage = (
  queryParams?: Record<string, string>,
) => {
  const history = useHistory();

  return useCallback(() => {
    const params = new URLSearchParams(queryParams);
    history.push(`${PREMIUM_BILLING_PATH}?${params.toString()}`);
  }, [history, queryParams]);
};

import React from 'react';
import { Flex } from '@ui/Flex';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import css from './BroadcastListItem.css';
import { Spacer } from '@ui/Spacer';

export const BroadcastListItemLoader: React.FC<{
  style?: React.CSSProperties;
}> = ({ style }) => (
  <Flex className={css.container} style={style}>
    <Flex className={css.statusContainer}>
      <LoadingPlaceholder color="cobaltSemilight" width={52} height={8} />
    </Flex>
    <Spacer horizontalFactor={5} />
    <Flex flexDirection="column" gapFactor={3}>
      <LoadingPlaceholder color="baseTertiary" width={165} height={8} />
      <LoadingPlaceholder color="cobaltSemilight" width={135} height={8} />
      <LoadingPlaceholder color="cobaltSemilight" width={150} height={8} />
    </Flex>
  </Flex>
);

import memoizeOne from 'memoize-one';
import { TFunction } from 'i18next';
import { CountryCodeItem } from '../types';

export const getCountriesItems = memoizeOne(
  (t: TFunction) =>
    [
      {
        id: 'CA',
        title: t(
          'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.chooseCountryCode.options.canada',
        ),
        flag: '🇨🇦',
      },
      {
        id: 'US',
        title: t(
          'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.chooseCountryCode.options.us',
        ),
        flag: '🇺🇸',
      },
    ] as CountryCodeItem[],
);

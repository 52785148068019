import {
  SetAdminMutation,
  SetAdminMutationVariables,
} from '@components/AdminPermissionManagerDialog/@types/SetAdminMutation';
import { SET_ADMIN_MUTATION } from '@components/AdminPermissionManagerDialog/AdminPermissionManagerDialog';
import { ROLES_AND_INVITES_QUERY } from '../../../../../../components/settings/admins/AdminsConst';
import { useMutation } from 'react-apollo';

export const useSetBotAdminRole = (botId: string) => {
  return useMutation<SetAdminMutation, SetAdminMutationVariables>(
    SET_ADMIN_MUTATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: ROLES_AND_INVITES_QUERY,
          variables: { botId },
        },
      ],
    },
  );
};

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useBotFeaturesLazy } from '@utils/Data/Bot';
import { useQuery } from 'react-apollo';
import { BotsListQuery } from '../../../../common/services/GQLqueries/@types/BotsListQuery';
import { BOTS_LIST_QUERY } from '../../../../common/services/GQLqueries/BotGQService.const';
import { useCreateBlankBot } from '@utils/Data/Bot/useCreateBlankBot';
import { useCreateBotFromTemplate } from '@utils/Data/Bot/useCreateBotFromTemplate';
import { redirect } from '@utils/UrlUtils';
import { getPlansPageUrl } from '@utils/Routing';
import { BotsListBannerBox } from '../BotsListBannerBox';
import { DashboardButtons } from '../DashboardButtons';
import { BotList } from '@components/BotList';
import css from './BotsList.css';

interface BotsListProps {}

export const BotsList: React.FC<BotsListProps> = () => {
  const { pathname, state } = useLocation();
  const { replace } = useHistory();
  const { getBotFeatures, botFeatures, botFeaturesLoading, botFeaturesCalled } =
    useBotFeaturesLazy();
  const [botIdToUpgrade, setBotIdToUpgrade] = useState<string>();

  const { data: botListQuery, loading: botListLoading } =
    useQuery<BotsListQuery>(BOTS_LIST_QUERY);
  const [onCreateBlankBot, { loading: blankBotLoading }] =
    useCreateBlankBot(botListQuery);
  const [onCreateBotFromTemplate, { loading: templateBotLoading }] =
    useCreateBotFromTemplate(botListQuery);

  const hideCreateButtons = botListQuery?.bots.length === 0;
  const hideSearch = botListQuery?.bots.length === 0;

  const onUpgradeToProClick = async (botId: string) => {
    setBotIdToUpgrade(botId);
    getBotFeatures(botId);
  };

  useEffect(() => {
    if (!botFeaturesLoading && botIdToUpgrade && botFeaturesCalled) {
      redirect(
        getPlansPageUrl(
          botIdToUpgrade,
          !!botFeatures?.dialogs_pricing_enabled,
          'bots-list',
        ),
      );
    }
  }, [botFeatures, botFeaturesLoading, botIdToUpgrade, botFeaturesCalled]);

  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (!state || !state.create || botListLoading || !botListQuery) {
      return;
    }

    replace(pathname, {});

    const { template } = state;

    if (template) {
      onCreateBotFromTemplate(template.botId, template.name);
      return;
    }

    onCreateBlankBot();
  }, [
    state,
    botListQuery,
    botListLoading,
    pathname,
    replace,
    onCreateBlankBot,
    onCreateBotFromTemplate,
  ]);

  const loading = blankBotLoading || templateBotLoading;

  return (
    <>
      <div className="bots-list">
        <BotsListBannerBox />
        <div className={css.wrapper}>
          <DashboardButtons
            hideSearch={hideSearch}
            search={search}
            changeSearch={(newSearch) => setSearch(newSearch)}
            disabled={loading}
            hide={hideCreateButtons}
            onCreateBlankBot={(platforms) => {
              onCreateBlankBot(null, platforms);
            }}
          />
        </div>
        <BotList
          search={search}
          showLoaderTile={loading}
          onCreateBlankBot={(platforms) => {
            onCreateBlankBot(null, platforms);
          }}
          onUpgradeToProClick={onUpgradeToProClick}
        />
      </div>
    </>
  );
};

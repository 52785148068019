import React from 'react';
import { PageHeader } from '@components/PageHeader';
import { LogoSize } from '@components/PageHeader/WhiteLabelLogo';
import { Page } from '@ui/Page';
import { PageContent } from '@ui/PageContent';
import { WorkspaceList } from './components/WorkspaceList';
import { BotsList } from './components/BotsList';
import { CenteredLoader } from '@ui/Loader';
import { useWorkspacesAvailable } from '@utils/Data/Workspaces/useWorkspacesAvailable';

export const BotListPage: React.FC = () => {
  const [workspacesAvailable, loading] = useWorkspacesAvailable();

  const getContent = () => {
    if (loading) return <CenteredLoader />;
    return workspacesAvailable ? <WorkspaceList /> : <BotsList />;
  };

  return (
    <Page>
      <PageHeader logoSize={LogoSize.default} />
      <PageContent>{getContent()}</PageContent>
    </Page>
  );
};

import { Button } from '@ui/Button';
import { Flex, FlexItem } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import React from 'react';
import type { Contact } from '../useContactQuery';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useQueryParams } from '@utils/queryParams';
import { useHistory } from 'react-router-dom';
import { sendEvent } from '@utils/Analytics';

export const ContactPageHeader: React.FC<{ contact: Contact }> = ({
  contact,
}) => {
  const { t } = useSafeTranslation();
  const history = useHistory();
  const { backUrl } = useQueryParams<{ backUrl?: string }>();

  const backUrlParsed = backUrl && decodeURIComponent(backUrl);

  return (
    <Flex gapFactor={3} alignItems="center" fullWidth>
      {backUrlParsed && (
        <Button
          data-testid="contact-card_return-to-people-tab-button"
          intent="secondary"
          icon={<Icon icon="leftArrow" />}
          onClick={() => {
            sendEvent({
              category: 'contact profile',
              action: 'click',
              label: 'contact page',
              propertyBag: {
                contactId: contact.id,
                tab: 'people tab',
              },
            });
            history.push(backUrlParsed);
          }}
        >
          {t('PeoplePage.contactPage')}
        </Button>
      )}
      <FlexItem grow>
        <Type color="baseSecondary">{contact?.name}</Type>
      </FlexItem>
    </Flex>
  );
};

import { defaultItemToString, Item, SimpleCombobox } from '@ui/SimpleCombobox';
import React, { useMemo } from 'react';
import { IconButton } from '@ui/IconButton';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { sendEvent } from '@utils/Analytics';
import { MenuItem } from '@ui/Menu';

export enum MoreMenuItemIds {
  delete = 'delete',
  rename = 'rename',
}

interface MoreMenuProps {
  onSelect: (id: MoreMenuItemIds) => void;
  className?: string;
}

export const MoreMenu: React.FC<MoreMenuProps> = ({ onSelect, className }) => {
  const { t } = useSafeTranslation();
  const items: Item[] = useMemo(
    () => [
      {
        id: MoreMenuItemIds.rename,
        title: t('pages.Keywords.KeywordGroups.rename'),
      },
      {
        id: MoreMenuItemIds.delete,
        title: t('pages.Keywords.KeywordGroups.delete'),
      },
    ],
    [t],
  );

  return (
    <div className={className}>
      <SimpleCombobox
        position="bottom-end"
        unsaveSelectedOption
        items={items}
        onChange={(item) => {
          if (item) {
            onSelect(item.id as MoreMenuItemIds);
            sendEvent({
              category: 'keywords',
              label: 'keywords groups',
              action: 'keyword item more menu item click',
              propertyBag: {
                id: item.id,
              },
            });
          }
        }}
        renderInput={({ getToggleButtonProps, ref }) => (
          <IconButton
            {...getToggleButtonProps()}
            ref={ref}
            icon="more"
            data-testid="keywords__keywords-groups__keywords-group-more-button"
          />
        )}
        renderItem={({ getItemProps, item, index, highlightedIndex }) => (
          <MenuItem
            key={item.id}
            {...getItemProps({ item })}
            active={index === highlightedIndex}
            title={defaultItemToString(item)}
            data-testid={`keywords__keywords-groups__intent-more-menu__${item.id}`}
          />
        )}
      />
    </div>
  );
};

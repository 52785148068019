import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Tooltip2 } from '@ui/Tooltip2';
import { Toggle } from '@ui/Toggle';
import { useMutation } from '@apollo/react-hooks';
import { ServiceMessageType, toaster } from '@services/MessageService';
import gql from 'graphql-tag';
import { react2angular } from 'react2angular';
import ng from 'angular';
import { ApolloProvider } from '@apollo/react-common';
import { useQuery } from 'react-apollo';
import { sendEvent } from '@utils/Analytics';
import { BuiltinBlockStatus, BuiltinBlockType } from '@globals';
import client from '../../../common/services/ApolloService';
import {
  setBuiltinBlock,
  setBuiltinBlockVariables,
} from './@types/setBuiltinBlock';
import {
  getBuiltinBlock,
  getBuiltinBlockVariables,
} from './@types/getBuiltinBlock';

export interface EnableBuiltinBlock {
  bot: { id: string };
  block: { title: string; builtin: boolean };
  onChange: () => void;
}

const SPECIAL_BLOCK_QUERY = gql`
  query getBuiltinBlock($botId: String!) {
    bot(id: $botId) {
      id
      builtin_blocks_status {
        default_message
        welcome_message
      }
    }
  }
`;

const SPECIAL_BLOCK_MUTATION = gql`
  mutation setBuiltinBlock(
    $botId: String!
    $builtinBlockType: BuiltinBlockType!
    $status: BuiltinBlockStatus!
  ) {
    setBotsBuiltinBlockStatus(
      botId: $botId
      builtinBlockType: $builtinBlockType
      status: $status
    )
  }
`;

export const EnableBuiltinBlock: React.FC<EnableBuiltinBlock> = ({
  bot,
  block,
}) => {
  const { t } = useSafeTranslation();
  const { data } = useQuery<getBuiltinBlock, getBuiltinBlockVariables>(
    SPECIAL_BLOCK_QUERY,
    {
      variables: { botId: bot?.id },
      skip: !bot?.id,
    },
  );
  const [toggleBuiltinBlock] = useMutation<
    setBuiltinBlock,
    setBuiltinBlockVariables
  >(SPECIAL_BLOCK_MUTATION, {
    refetchQueries: [
      { query: SPECIAL_BLOCK_QUERY, variables: { botId: bot?.id } },
    ],
    awaitRefetchQueries: true,
    onError: () => {
      toaster.show({
        type: ServiceMessageType.error,
        payload: {
          message: window.i18next.t(
            'EnableBuiltinBlock-string-1034-could-not-toggle-builtin-block-please-try-again-later',
          ),
        },
      });
    },
  });
  const specialBlocksMap: Record<string, BuiltinBlockType> = {
    'welcome message': BuiltinBlockType.welcome_message,
    'default answer': BuiltinBlockType.default_message,
  };
  const currentBuiltinBlockType = specialBlocksMap[block?.title.toLowerCase()];
  const showToggle = Boolean(
    currentBuiltinBlockType &&
      data?.bot?.builtin_blocks_status &&
      block.builtin,
  );
  const toggleValue =
    showToggle &&
    Object.entries(specialBlocksMap).some(([, builtinStatusKey]) => {
      return (
        currentBuiltinBlockType === builtinStatusKey &&
        (data?.bot?.builtin_blocks_status &&
          data.bot.builtin_blocks_status[builtinStatusKey]) ===
          BuiltinBlockStatus.ENABLED
      );
    });

  if (!showToggle) {
    return null;
  }
  return (
    <Tooltip2
      content={t('TurnOnWelcomeMessage.tooltip')}
      placement="bottom"
      style={{ maxWidth: 400 }}
      disabled={currentBuiltinBlockType !== BuiltinBlockType.welcome_message}
      hoverable
    >
      {(ref, bind) => (
        <Toggle
          ref={ref}
          {...bind}
          small
          value={toggleValue}
          onChange={(newValue) => {
            toggleBuiltinBlock({
              variables: {
                botId: bot.id,
                builtinBlockType: currentBuiltinBlockType,
                status: newValue
                  ? BuiltinBlockStatus.ENABLED
                  : BuiltinBlockStatus.DISABLED,
              },
            });
            sendEvent({
              category: block?.title,
              action: `click turn ${newValue ? 'on' : 'off'}`,
            });
          }}
        />
      )}
    </Tooltip2>
  );
};

export const EnableBuiltinBlockWrapper: React.FC<EnableBuiltinBlock> = (
  props,
) => {
  return (
    <ApolloProvider client={client}>
      <EnableBuiltinBlock {...props} />
    </ApolloProvider>
  );
};
export const ngEnableBuiltinBlock = ng
  .module('app.modernUi.ngEnableBuiltinBlock', [])
  .component(
    'enableBuiltinBlock',
    react2angular(EnableBuiltinBlockWrapper, ['bot', 'block'], []),
  ).name;

import { Flex } from '@ui/Flex';
import { LineLoader } from '@ui/Loader';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { getArrayFrom0ToN, shuffle } from '@utils/ArrayUtils';
import cn from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import memoize from 'lodash-es/memoize';
import * as css from './FlowLoader.css';
import sendMsg from './images/send_msg.svg';
import condition from './images/condition.svg';
import action from './images/action.svg';
import delay from './images/delay.svg';
import redirect from './images/redirect.svg';

interface FlowLoaderProps {
  state: FlowLoadingState;
  iconSize?: number;
  className?: string;
}

export enum FlowLoadingState {
  none = 'none',
  fetching = 'fetching',
  rendering = 'rendering',
  generatingImage = 'generatingImage',
  ready = 'ready',
}

const FlowLoadingStateToPercent: Record<FlowLoadingState, number> = {
  [FlowLoadingState.ready]: 1000,
  [FlowLoadingState.generatingImage]: 90,
  [FlowLoadingState.rendering]: 80,
  [FlowLoadingState.fetching]: 40,
  [FlowLoadingState.none]: 0,
};

const FlowLoadingStateToDescription: () => Record<FlowLoadingState, string> =
  memoize(() => ({
    [FlowLoadingState.ready]: window.i18next.t(
      'FlowLoader-string-6060-almost-done',
    ),
    [FlowLoadingState.generatingImage]: window.i18next.t(
      'FlowLoader-string-8370-generating-png-from-flow',
    ),
    [FlowLoadingState.rendering]: window.i18next.t(
      'FlowLoader-string-1507-rendering-flow',
    ),
    [FlowLoadingState.fetching]: window.i18next.t(
      'FlowLoader-string--212-fetching-flow-data',
    ),
    [FlowLoadingState.none]: '',
  }));

export const FlowLoader: React.FC<FlowLoaderProps> = ({
  state,
  iconSize = 200,
  className,
}) => {
  const [show, setShow] = useState(false);

  const animationIndexArray = useMemo(
    () => (show ? shuffle<number>(getArrayFrom0ToN(5)) : []),
    [show],
  );

  const imageArray = [
    { alt: 'sendMsg', src: sendMsg },
    { alt: 'action', src: action },
    { alt: 'condition', src: condition },
    { alt: 'delay', src: delay },
    { alt: 'redirect', src: redirect },
  ];

  useEffect(() => {
    if (state === FlowLoadingState.ready) {
      setTimeout(() => setShow(false), 300);
    } else {
      setShow(true);
    }
  }, [state]);

  if (state === FlowLoadingState.ready && !show) {
    return null;
  }

  return (
    <div className={cn(css.loaderWrapper, className)}>
      <div className={css.lineWrapper}>
        <LineLoader percent={FlowLoadingStateToPercent[state]} />
      </div>
      <Flex
        alignItems="center"
        justifyContent="center"
        className={css.loader}
        flexDirection="column"
      >
        <div
          className={css.loaderImageWrapper}
          style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
        >
          {imageArray.map((img, index) => (
            <img
              key={img.alt}
              className={cn(
                css.loaderImage,
                css[`loaderImage${animationIndexArray[index]}`],
              )}
              alt={img.alt}
              src={img.src}
            />
          ))}
        </div>
        <Type weight="medium" size="24px">
          {window.i18next.t('FlowLoader-JSXText--647-loading-flow')}
        </Type>
        <Spacer factor={2} />
        <Type color="grey" size="15px_DEPRECATED">
          {FlowLoadingStateToDescription()[state] || ''}
        </Type>
      </Flex>
    </div>
  );
};

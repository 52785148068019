import * as ng from 'angular';
import * as React from 'react';
import { angular2react } from 'angular2react';

import { Flex } from '@ui/Flex';
import { lazyInjector } from '@utils/angularLazyInjector';
import { useReadyToRender } from '@utils/AngularRouting';
import { Spacer } from '@ui/Spacer';
import { BotTabAngular } from '@components/BotTab';

import SettingsFactory from '../../../components/settings/settings.factory';
import controller from '../../../components/settings/controller';
import PaymentsSettings from '../../../components/settings/payments';
import KommoIntegration from '../../../components/settings/kommo';
import HubSpotIntegration from '../../../components/settings/hubspot';
import ShopifyIntegration from '../../../components/settings/shopify';
import BcApi from '../../../components/settings/bc-api';
import PersistentMenu from '../../../components/settings/persistent-menu';
import DomainWhitelist from '../../../components/settings/domain-whitelist';
import MessengerExtensions from '../../../components/settings/messenger-extensions';
import DefaultAnswer from '../../../components/settings/default-answer';

import template from '../../../components/settings/templates/settings.html';

const configureTabWrapper = {
  controller,
  template: () => template,
  controllerAs: 'vm',
  restrict: 'E',
  replace: true,
};

export const ngConfigureTabWrapper = ng
  .module('app.pages.configureTab', [
    KommoIntegration,
    HubSpotIntegration,
    ShopifyIntegration,
    PaymentsSettings,
    BcApi,
    PersistentMenu,
    DomainWhitelist,
    MessengerExtensions,
    DefaultAnswer,
  ])
  .component('configureTabWrapper', configureTabWrapper)
  .factory('SettingsFactory', SettingsFactory).name;

const ConfigureTabWrapperFunc = () => {
  return angular2react<{}>(
    'configureTabWrapper',
    configureTabWrapper,
    lazyInjector.$injector as any,
  );
};

const ConfigureTabWrapper = ConfigureTabWrapperFunc();

export const ConfigureTab: React.FC = () => {
  const readyToRender = useReadyToRender('common');

  if (!readyToRender) {
    return <div />;
  }
  return (
    <Flex flexDirection="column" style={{ width: '100%' }}>
      <Spacer factor={8} />
      <BotTabAngular
        tabName="settings"
        rcol
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '0',
        }}
      >
        <ConfigureTabWrapper />
      </BotTabAngular>
    </Flex>
  );
};

import React from 'react';
import * as css from './ChangeTierPanel.css';
import { Flex } from '@ui/Flex';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Type } from '@ui/Type';
import { formatPrice } from '@utils/Format';
import { useBotOldPricingData } from './hooks/useBotOldPricingData';
import { Spacer } from '@ui/Spacer';
import { numberFormatter } from '@utils/Format/numberFormatter';
import { Icon } from '@ui/Icon';
import { IconButton } from '@ui/IconButton';
import {
  ServerStorageItemKeys,
  useServerStorageForBot,
} from '@utils/ServerStorage';
import { sendEvent } from '@utils/Analytics';
import { usePaymentInfo } from '@utils/Data/Pricing';
import { useBotProStatus } from '@utils/Data/Bot';

export const ChangeTierPanel: React.FC = () => {
  const { pricingData, loading: pricingDataLoading } = useBotOldPricingData();
  const { paymentInfo, paymentInfoLoading } = usePaymentInfo();
  const {
    botIsManualPro,
    botIsPro,
    loading: botProStatusLoading,
  } = useBotProStatus();
  const { t } = useSafeTranslation();
  const [closedKey, setClosedKey, { loading: closedStateLoading }] =
    useServerStorageForBot<string>(
      ServerStorageItemKeys.closeAdjustmentFeePanel,
    );

  if (
    paymentInfoLoading ||
    pricingDataLoading ||
    botProStatusLoading ||
    closedStateLoading ||
    botIsManualPro ||
    !botIsPro ||
    !pricingData
  ) {
    return null;
  }

  const { currentMaxUsers, currentPrice, currency, adjustmentFee } =
    pricingData;

  const currentClosedKey = `${currentMaxUsers}_${(
    paymentInfo?.nextBillingDate || 0
  ).toFixed(0)}`;

  if (closedKey === currentClosedKey || !adjustmentFee?.price) {
    return null;
  }

  return (
    <>
      <div className={css.box}>
        <Flex>
          <Icon icon="info" color="orange" className={css.info} />
          <Spacer factor={1} horizontalFactor={1} />
          <Type size="15px" color="alloyOrange">
            {t('pages.GrowPage.ChangeTierPanel.text', {
              subscribers: numberFormatter(currentMaxUsers),
              tier: formatPrice(currentPrice, {
                currency,
                decimals: 0,
              }),
              adjustmentFee: formatPrice(adjustmentFee?.price || 0, {
                currency: adjustmentFee?.currency,
              }),
            })}
          </Type>
          <Spacer factor={1} horizontalFactor={2} />
          <IconButton
            onClick={() => {
              setClosedKey(currentClosedKey);
              sendEvent({
                category: 'home tab',
                label: 'change tier panel',
                action: 'close button click',
              });
            }}
            icon="close"
            size="16px"
            className={css.close}
          />
        </Flex>
      </div>
      <Spacer factor={7} />
    </>
  );
};

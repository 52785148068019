import i18next from 'i18next';
import Maybe from 'graphql/tsutils/Maybe';
import { useCallback } from 'react';
import { NestedMenuNodeDisplayMode } from '@ui/NestedMenu';
import { useRolePermission } from '@utils/Roles';
import { Permission } from '@common/services/RoleService';
import { BotListMenuItem } from '../types';
import { isBotPro } from '@utils/Pro';
import memoize from 'lodash-es/memoize';
import { NestedMenuItem } from '@ui/NestedMenu/types';
import {
  botActionsSorted as botActionsSortedBasic,
  BotOptions,
  Permissions,
} from '@components/BotActions';
import { WorkspacesBots_workspacesBots_workspaces_workspace } from '@utils/Data/Workspaces/@types/WorkspacesBots';

const copyWithoutWorkspace = (): BotOptionsItemProps => ({
  id: BotListMenuItem.copy,
  title: 'Copy without workspace',
  canBeShown: (_, { canEdit }) => !!canEdit,
});

interface WorkspaceNodeProps {
  workspacesList: Array<{ title: string }>;
}

const copyToWorkspace = ({
  workspacesList,
}: WorkspaceNodeProps): BotOptionsItemProps => ({
  id: BotListMenuItem.copyToWorkspace,
  title: 'Workspace',
  displayMode: NestedMenuNodeDisplayMode.nested,
  canBeShown: (_, { canEdit }) => !!canEdit,
  children: workspacesList.map(({ title }) => ({
    id: BotListMenuItem.copyToWorkspace,
    title,
    canBeShown: (_, { canEdit }) => !!canEdit,
  })),
});

interface WorkspaceCopyMenuProps extends WorkspaceNodeProps {
  currentWorkspace:
    | WorkspacesBots_workspacesBots_workspaces_workspace
    | undefined;
}

const getWorkspaceCopyMenuItem = (
  props: WorkspaceCopyMenuProps,
): BotOptionsItemProps => ({
  id: BotListMenuItem.copyToWorkspace,
  title: i18next.t('modernComponents.BotActions.copy'),
  canBeShown: (_, { canEdit }) =>
    !!canEdit && props.currentWorkspace?.permission === Permission.EDIT,
  displayMode: NestedMenuNodeDisplayMode.nested,
  children: [copyWithoutWorkspace(), copyToWorkspace(props)],
});

interface Bot {
  status?: Maybe<{ page_info: unknown }>;
  pro?: Maybe<{ manual: Maybe<boolean> }>;
  admins: Array<unknown>;
  connectedShop?: Maybe<{ id: string }>;
}

interface BotOptionsItemProps extends NestedMenuItem {
  id: BotListMenuItem;
  title: string;
  canBeShown: (bot: Bot, permissions: Permissions) => boolean;
  children?: Array<BotOptionsItemProps>;
}

interface BotActionsSortedProps {
  workspaceWithEditingList: WorkspacesBots_workspacesBots_workspaces_workspace[];
  currentWorkspace?: WorkspacesBots_workspacesBots_workspaces_workspace;
}

export const botActionsSorted = memoize(
  ({
    currentWorkspace,
    workspaceWithEditingList,
  }: BotActionsSortedProps): BotOptionsItemProps[] => [
    {
      id: BotListMenuItem.removeFromWorkspace,
      title: i18next.t('BotList.listItemActions.removeFromWorkspace'),
      canBeShown: (bot) => {
        return (
          currentWorkspace?.permission === Permission.EDIT &&
          !bot.status?.page_info
        );
      },
    },
    {
      id: BotListMenuItem.moveTo,
      title: i18next.t('BotList.listItemActions.moveTo'),
      displayMode: NestedMenuNodeDisplayMode.nested,
      canBeShown: (bot, { canEdit }) => {
        return Boolean(
          !currentWorkspace &&
            workspaceWithEditingList.length &&
            canEdit &&
            (!isBotPro(bot) || bot.pro?.manual) &&
            !bot.connectedShop?.id,
        );
      },
      children: workspaceWithEditingList.map(({ id, name }) => ({
        id: BotListMenuItem.moveTo,
        workspaceId: id,
        title: name,
        canBeShown: () => true,
      })),
    },
    {
      id: BotListMenuItem.manageUsers,
      title: i18next.t('BotList.listItemActions.manageUsers'),
      canBeShown: (_, { canEdit }) => Boolean(canEdit),
    },
    ...botActionsSortedBasic()
      .map(({ type, ...item }) =>
        type === BotOptions.copy
          ? currentWorkspace?.permission === Permission.EDIT
            ? getWorkspaceCopyMenuItem({
                currentWorkspace,
                workspacesList: workspaceWithEditingList.map(({ name }) => ({
                  title: name,
                })),
              })
            : ({
                ...item,
                id: type as any as BotListMenuItem,
              } as BotOptionsItemProps)
          : { ...item, id: type as any as BotListMenuItem },
      )
      .flat(),
  ],
);

interface BotMenuItemsProps {
  botId: string;
}

export const useBotMenuItems = ({ botId }: BotMenuItemsProps) => {
  const { allowed: canEdit } = useRolePermission({
    domain: 'bot',
    can: Permission.EDIT,
    botId,
  });
  const { allowed: canView } = useRolePermission({
    domain: 'bot',
    can: Permission.VIEW,
    botId,
  });

  const getItems = useCallback(
    (bot: Bot, props: BotActionsSortedProps) => {
      const botActions = botActionsSorted(props);
      const permissions = { canView, canEdit };

      return botActions.filter((item) => item.canBeShown(bot, permissions));
    },
    [canEdit, canView],
  );

  return getItems;
};

import { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { IntroQuestionsValues } from '../types';
import { sendEvent } from '@utils/Analytics';
import {
  clearValues,
  getOtherFieldId,
  getQuestionIdByKeywordsSelector,
  getScalarValueSign,
  testValue,
} from '../helpers';
import {
  IntroQuestionsKeywordsSelector,
  IntroQuestionsQuestionType,
  Platform,
} from '@globals';
import { refetchFirstSessionAiIntents } from '../../../hooks/useFirstSessionAiIntents';
import { MIN_PHONE_LENGTH, OTHER_ITEM_ID } from '../consts';
import { useCurrentBotId } from '@utils/Routing';
import { IntroQuestionsConfigQuery_introQuestionsConfig_variables } from './@types/IntroQuestionsConfigQuery';
import * as Yup from 'yup';
import { IntroQuestionsSavedDataQuery_savedIntroQuestions_variables } from './@types/IntroQuestionsSavedDataQuery';

interface IntroQuestionsFormikParams {
  platform: Platform;
  stepDoneHandler: () => void;
  saveIntroQuestionsData: (
    data: IntroQuestionsValues,
  ) => Promise<void> | undefined;
  questionsConfig:
    | IntroQuestionsConfigQuery_introQuestionsConfig_variables[]
    | undefined;
  introQuestionsSavedData:
    | IntroQuestionsSavedDataQuery_savedIntroQuestions_variables[]
    | undefined;
}

const PLUS_SYMBOL_LENGTH = 1;

export const useIntroQuestionsFormik = ({
  platform,
  stepDoneHandler,
  saveIntroQuestionsData,
  questionsConfig,
  introQuestionsSavedData,
}: IntroQuestionsFormikParams) => {
  const botId = useCurrentBotId();
  const [currentIndustry, setCurrentIndustry] = useState<string | undefined>();
  const [currentNiche, setCurrentNiche] = useState<string | undefined>();
  const validationSchema = useMemo(() => {
    return Yup.object().shape(
      questionsConfig?.reduce<Record<string, Yup.StringSchema<any>>>(
        (schema, { id, show_other, dependency, required, type }) => {
          let itemValidationSchema = Yup.string().trim();
          if (required && type === IntroQuestionsQuestionType.phoneInput) {
            itemValidationSchema = itemValidationSchema.min(
              MIN_PHONE_LENGTH + PLUS_SYMBOL_LENGTH,
            );
          }
          if (required) {
            itemValidationSchema = itemValidationSchema.required();
          }
          const dependencyRequiredSchema = dependency?.length
            ? Yup.string()
                .nullable()
                .when(
                  dependency.map(({ question }) => question),
                  {
                    is: (...values) =>
                      dependency.some(({ option }, index) =>
                        testValue(option, values[index] || ''),
                      ),
                    then: itemValidationSchema,
                  },
                )
            : itemValidationSchema;

          // eslint-disable-next-line no-param-reassign
          schema[id] = dependencyRequiredSchema;
          if (show_other) {
            // eslint-disable-next-line no-param-reassign
            schema[getOtherFieldId(id)] = Yup.string()
              .nullable()
              .when(id, {
                is: testValue(OTHER_ITEM_ID),
                then: dependencyRequiredSchema,
              });
          }
          return schema;
        },
        {},
      ) || {},
    );
  }, [questionsConfig]);

  const initialValues = useMemo(() => {
    return (questionsConfig || []).reduce((prev, curr) => {
      if (curr.default_value) {
        return {
          ...prev,
          [curr.id]: [curr.default_value],
        };
      }

      return prev;
    }, {});
  }, [questionsConfig]);

  const {
    values,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    isValid,
    errors,
    touched,
    validateField,
    handleSubmit,
    validateForm,
    setValues,
  } = useFormik<IntroQuestionsValues>({
    initialValues,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema,
    onSubmit: async (values, { setTouched }) => {
      sendEvent({
        category: 'first-session',
        label: 'intro questions block',
        action: 'form submit',
        propertyBag: {
          platform,
        },
      });
      setTouched({});
      await saveIntroQuestionsData(clearValues(values, questionsConfig));
      const updatedIndustry = getScalarValueSign(
        values[
          getQuestionIdByKeywordsSelector(
            questionsConfig,
            IntroQuestionsKeywordsSelector.industry,
          )
        ],
      );
      const updatedNiche = getScalarValueSign(
        values[
          getQuestionIdByKeywordsSelector(
            questionsConfig,
            IntroQuestionsKeywordsSelector.niche,
          )
        ],
      );
      if (
        (currentIndustry !== updatedIndustry ||
          currentNiche !== updatedNiche) &&
        botId
      ) {
        setCurrentIndustry(updatedIndustry);
        setCurrentNiche(updatedNiche);
        refetchFirstSessionAiIntents(botId);
      }
      stepDoneHandler();
    },
  });

  useEffect(() => {
    if (Object.values(values).length) {
      validateForm(values);
    }
  }, [questionsConfig, validateForm, values]);

  useEffect(() => {
    if (introQuestionsSavedData?.length && Object.values(values).length === 0) {
      const savedValues = Object.fromEntries(
        introQuestionsSavedData.map(({ code, value }) => [code, value]),
      );
      setValues(savedValues);
      validateForm(savedValues);
      setCurrentIndustry(
        getScalarValueSign(
          savedValues[
            getQuestionIdByKeywordsSelector(
              questionsConfig,
              IntroQuestionsKeywordsSelector.industry,
            )
          ],
        ),
      );
      setCurrentNiche(
        getScalarValueSign(
          savedValues[
            getQuestionIdByKeywordsSelector(
              questionsConfig,
              IntroQuestionsKeywordsSelector.niche,
            )
          ],
        ),
      );
    }
  }, [
    introQuestionsSavedData,
    questionsConfig,
    setValues,
    validateForm,
    values,
  ]);

  return {
    values,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    isValid,
    errors,
    touched,
    validateField,
    handleSubmit,
  };
};

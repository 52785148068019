import React from 'react';
import { Icon } from '@ui/Icon';
import { MenuItem } from '@ui/Menu';
import { SimpleCombobox, SimpleComboboxProps } from '@ui/SimpleCombobox';
import { TITLE_ITEM, ENTRY_POINT_STATUS_MENU_ITEMS } from './constants';
import { EntryPointStatus } from '../../types';

export interface EntryPointMenuItem {
  id: string | EntryPointStatus;
  title?: string;
  type?: string;
}

interface EntryPointStatusMenuProps {
  children(props: any): JSX.Element;
  title?: string;
  statusId?: EntryPointStatus;
  onStatusChange?(status?: EntryPointStatus): void;
  comboboxProps?: Partial<SimpleComboboxProps<EntryPointMenuItem>>;
}

export const EntryPointStatusMenu: React.FC<EntryPointStatusMenuProps> = ({
  title,
  statusId,
  onStatusChange,
  children,
  comboboxProps,
}) => {
  const items = ENTRY_POINT_STATUS_MENU_ITEMS();

  if (title) {
    items.unshift({ name: title, ...TITLE_ITEM } as any);
  }

  return (
    <SimpleCombobox<EntryPointMenuItem>
      {...comboboxProps}
      items={items}
      position="bottom-start"
      renderInput={children}
      renderItem={({
        getItemProps,
        item,
        index,
        highlightedIndex,
        closeMenu,
      }) => {
        const active = index === highlightedIndex;
        const currentItemSelected = item.id === statusId;

        const commonProps = {
          ...getItemProps({ item }),
          key: `${item.id}_${index}`,
          type: item.type,
          title: item.title,
          active,
        };

        switch (item.type) {
          case 'title':
            return <MenuItem {...commonProps} />;

          default:
            return (
              <MenuItem
                {...commonProps}
                onClick={(event: Event) => {
                  event.preventDefault();
                  if (item?.title && !item.type) {
                    if (!currentItemSelected) {
                      onStatusChange?.(item.id as EntryPointStatus);
                    }
                    closeMenu();
                  }
                }}
                rightElement={
                  currentItemSelected && (
                    <Icon icon="check" color={active ? 'white' : 'blue'} />
                  )
                }
              />
            );
        }
      }}
      unsaveSelectedOption
    />
  );
};

import React from 'react';
import { Flex } from '@ui/Flex';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

export const BotSectionBodyLayout: React.FC = ({ children }) => {
  const { isSmallScreenSize } = useDeviceMedia();

  return (
    <Flex
      justifyContent="space-between"
      alignItems={isSmallScreenSize ? 'flex-start' : 'center'}
      flexDirection={isSmallScreenSize ? 'column' : 'row'}
    >
      {children}
    </Flex>
  );
};

import React, { useMemo } from 'react';
import { Type } from '@ui/Type';
import { MenuItem } from '@ui/Menu';
import { ComboboxWithTriangleButton } from '@ui/SimpleCombobox';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { MessagesTemplateCategory } from '@globals';
import {
  MessagesTemplateFields,
  useExtendedFormAction,
} from '../../form/ExtendFormActionsContext';
import * as css from './common.css';
import { logEvent } from '../../utils';

export const CategoryDropdown = () => {
  const { t } = useSafeTranslation();
  const {
    values,
    errors,
    touched,
    setCategory,
    formDisabled,
    saveForm,
    setFieldTouched,
  } = useExtendedFormAction();

  const items = useMemo(
    () => [
      {
        id: MessagesTemplateCategory.marketing,
        title: t('pages.MessagesTemplates.settings.categories.marketing.title'),
        comment: t(
          'pages.MessagesTemplates.settings.categories.marketing.description',
        ),
      },
      {
        id: MessagesTemplateCategory.utility,
        title: t('pages.MessagesTemplates.settings.categories.utility.title'),
        comment: t(
          'pages.MessagesTemplates.settings.categories.utility.description',
        ),
      },
    ],
    [t],
  );
  const selectedCategoryItem = items.find(({ id }) => values.category === id);

  return (
    <ComboboxWithTriangleButton
      menuBoxClassName={css.menu}
      disabled={formDisabled}
      icon="chevronDown"
      onClick={() => {
        logEvent({ action: 'category dropdown click' });
      }}
      onChange={(item) => {
        logEvent({ action: 'category item select', label: item.id });
        saveForm(setCategory(item.id));
      }}
      items={items}
      onBlur={() => setFieldTouched(MessagesTemplateFields.category, true)}
      intent={touched.category && errors.category ? 'error' : 'secondary'}
      selectedItem={selectedCategoryItem}
      className={css.dropdownButton}
      buttonChildren={() => (
        <Type size="15px">
          {selectedCategoryItem?.title ||
            t('pages.MessagesTemplates.settings.chooseCategory')}
        </Type>
      )}
      data-testid="messages-template-header__category-dropdown"
    >
      {({
        downshift: { getItemProps, highlightedIndex },
        props: { item, index },
      }) => (
        <MenuItem
          title={item.title}
          active={index === highlightedIndex}
          key={item.id}
          wrapContent={Boolean(item.comment)}
          comment={item.comment}
          {...getItemProps({ item })}
        />
      )}
    </ComboboxWithTriangleButton>
  );
};

import { OTHER_ITEM_ID } from './consts';
import { curry, last } from 'ramda';
import { IntroQuestionsKeywordsSelector } from '@globals';
import { IntroQuestionsConfigQuery_introQuestionsConfig_variables } from './hooks/@types/IntroQuestionsConfigQuery';
import { IntroQuestionsValues } from './types';

export const getOtherFieldId = (id: string) => `${id}_${OTHER_ITEM_ID}`;

export const isOtherFieldId = (id: string) =>
  last(id.split('_')) === OTHER_ITEM_ID;

export const testValue = curry((text: string, value: string | string[]) =>
  Array.isArray(value) ? value.includes(text) : value === text,
);

export const getScalarValueSign = (value: string | string[] | undefined = '') =>
  Array.isArray(value) ? value.join() : value;

export const getQuestionByKeywordsSelector = (
  questions: IntroQuestionsConfigQuery_introQuestionsConfig_variables[],
  selector: IntroQuestionsKeywordsSelector,
) =>
  questions.find(
    ({ keywords_selector: keywordsSelector }) => keywordsSelector === selector,
  );

export const getQuestionIdByKeywordsSelector = (
  questions:
    | IntroQuestionsConfigQuery_introQuestionsConfig_variables[]
    | undefined,
  selector: IntroQuestionsKeywordsSelector,
) => getQuestionByKeywordsSelector(questions || [], selector)?.id || '';

export const getFirstOptionIdInQuestionByKeywordsSelector = (
  questions:
    | IntroQuestionsConfigQuery_introQuestionsConfig_variables[]
    | undefined,
  selector: IntroQuestionsKeywordsSelector,
) => getQuestionByKeywordsSelector(questions || [], selector)?.options[0]?.id;

export const clearValues = (
  values: IntroQuestionsValues,
  config:
    | IntroQuestionsConfigQuery_introQuestionsConfig_variables[]
    | undefined,
) => {
  if (!config) {
    return values;
  }

  const activeOtherField = Object.entries(values)
    .filter(([_, value]) => testValue(OTHER_ITEM_ID, value || ''))
    .map(([fieldName]) => getOtherFieldId(fieldName));
  return Object.fromEntries(
    Object.entries(values).filter(([fieldName]) => {
      const dependecyConfig = config.find(
        ({ id, dependency }) => id === fieldName && dependency?.length,
      )?.dependency;

      return (
        (!isOtherFieldId(fieldName) || activeOtherField.includes(fieldName)) &&
        (!dependecyConfig ||
          dependecyConfig.some(({ question, option }) =>
            testValue(option, values[question] || ''),
          ))
      );
    }),
  );
};

import gql from 'graphql-tag';

export const DEEP_LINKS_BOT_LIST_ADMIN_FRAGMENT = gql`
  fragment DeepLinksAdminFragment on Admin {
    id
    roles {
      bot_id
      role_id
    }
  }
`;

export const DEEP_LINKS_BOT_LIST_QUERY = gql`
  query DeepLinksBotListQuery {
    me {
      ...DeepLinksAdminFragment
    }
    bots {
      id
      title
      status {
        page_info {
          id
          title
          picture
        }
      }
      flow_groups {
        id
        flow_ids
      }
      pro {
        status
        manual
      }
    }
  }
  ${DEEP_LINKS_BOT_LIST_ADMIN_FRAGMENT}
`;

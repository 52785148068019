import React from 'react';
import { VisuallyDisabled } from '@ui/VisuallyDisabled';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import { FormContainer } from '../FormContainer';
import { MessageHeader } from './MessageHeader';
import { MessageButtons } from './MessageButtons';
import { MessagesTemplateBody } from './components/MessagesTempalteBody';
import { MessagesTemplateFooter } from './components/MessagesTemplateFooter';
import { useExtendedFormAction } from '../../form/ExtendFormActionsContext';
import * as css from './MessagesTemplateMessage.css';

export const MessagesTemplateMessage = () => {
  const { t } = useSafeTranslation();
  const { hasOptOutButton, contentPartDisabled } = useExtendedFormAction();

  return (
    <div className={css.wrapper}>
      <VisuallyDisabled disable={contentPartDisabled}>
        <FormContainer>
          <Type as="h1" weight="medium">
            {t('pages.MessagesTemplates.message.title')}
          </Type>

          <PluginControlLabel
            spacerFactor={2}
            light
            label={t('pages.MessagesTemplates.message.header.title')}
          >
            {() => <MessageHeader />}
          </PluginControlLabel>

          <PluginControlLabel
            spacerFactor={2}
            light
            label={t('pages.MessagesTemplates.message.body.title')}
          >
            {() => <MessagesTemplateBody />}
          </PluginControlLabel>

          {!hasOptOutButton && (
            <PluginControlLabel
              spacerFactor={2}
              light
              label={t('pages.MessagesTemplates.message.footer.title')}
            >
              {() => <MessagesTemplateFooter />}
            </PluginControlLabel>
          )}

          <PluginControlLabel
            spacerFactor={2}
            light
            label={t('pages.MessagesTemplates.message.buttons')}
          >
            {() => <MessageButtons />}
          </PluginControlLabel>
        </FormContainer>
      </VisuallyDisabled>
    </div>
  );
};

import React from 'react';
import { useBillingData } from '../Data/useBillingData';
import { useBotSectionData } from '../Data/useBotSectionData';
import { useRolePermission } from '@utils/Roles';
import { Permission } from '@common/services/RoleService';
import { usePlanButtonType } from './hooks';
import { PlanButtonView } from './PlanButtonView';

export interface PlanButtonProps {
  small?: boolean;
  shortText?: boolean;
  className?: string;
  id?: string;
}

export const PlanButton: React.FC<PlanButtonProps> = (props) => {
  const planButtonType = usePlanButtonType();
  const { pricing, loading: billingLoading } = useBillingData();
  const { bot, loading: botLoading } = useBotSectionData();
  const { allowed, loading: roleLoading } = useRolePermission({
    domain: 'pro',
    can: Permission.EDIT,
  });

  const disabled = !allowed;
  const loading = billingLoading || botLoading || roleLoading;

  if (!pricing || loading || !bot || bot.pro?.manual) {
    return null;
  }

  return (
    <PlanButtonView {...props} disabled={disabled} type={planButtonType} />
  );
};

import React from 'react';
import cn from 'classnames';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Disableable, Repeat } from '../types';
import * as commonCSS from './common.css';
import * as css from './RepeatControl.css';
import { ComboboxWithTriangleButton } from '@ui/SimpleCombobox';
import {
  SegmentedCheckboxOnClick,
  SegmentedCheckboxText,
  SegmentedCheckboxTextItemProps,
} from '@ui/SegmentedCheckbox/SegmentedCheckboxText';
import { Spacer } from '@ui/Spacer';
import { getRepeatItems, getWeekdaysShort } from './constants';
import { weekdayIdsFromValue } from '../utils';

export interface RepeatControlProps extends Disableable {
  customWeekdayValue: string | null;
  onCustomWeekdayClick: SegmentedCheckboxOnClick<SegmentedCheckboxTextItemProps>;
  repeatValue: Repeat;
  onRepeatChange: (value: { id: Repeat }) => void;
}

export const RepeatControl: React.FC<RepeatControlProps> = ({
  disabled,
  repeatValue,
  onRepeatChange,
  customWeekdayValue,
  onCustomWeekdayClick,
}) => {
  const { t } = useSafeTranslation();
  const currentItem = getRepeatItems().find(({ id }) => id === repeatValue)!;

  return (
    <PluginControlLabel
      label={t('pages.BotPage.ReengageWhatsappTab.panel.repeat.label')}
      light
    >
      {() => (
        <>
          <ComboboxWithTriangleButton
            disabled={disabled}
            itemToString={(item) => item?.title || ''}
            selectedItem={currentItem}
            className={cn(commonCSS.dropdown, css.dropdown)}
            items={getRepeatItems()}
            onChange={(item) => onRepeatChange(item)}
            data-testid="reengage-editor__repeat-dropdown"
          />

          {currentItem.id === Repeat.custom && (
            <>
              <Spacer factor={3} />

              <SegmentedCheckboxText
                disabled={
                  disabled ? getWeekdaysShort().map(({ id }) => id) : []
                }
                items={getWeekdaysShort()}
                checkedIds={weekdayIdsFromValue(customWeekdayValue)}
                onClick={onCustomWeekdayClick}
              />
            </>
          )}
        </>
      )}
    </PluginControlLabel>
  );
};

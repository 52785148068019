import React, { useCallback, useState } from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { Spacer } from '@ui/Spacer';
import {
  isPhoneVerified,
  WhatsappPhone,
  WhatsappPhoneEntry,
} from '@utils/Data/Whatsapp';
import { AddAccountButton } from '../AddAccountButton/AddAccountButton';
import { BusinessAccountItem } from '../BusinessAccountItem/BusinessAccountItem';
import { PhoneNumberItem } from '../PhoneNumberItem/PhoneNumberItem';
import { NoPhonesCallout } from '../../NoPhonesCallout/NoPhonesCallout';
import { WHATSAPP_BUSINESS_ACCOUNT_DEFAULT_NAME } from '../../../consts';
import * as css from './MobilePhoneSelectDialog.css';
import { useBotsTitlesMap } from '@utils/Data/Bot/useBotsTitlesMap';
import { useBotFeatures } from '@utils/Data/Bot';
import { useCurrentBotId } from '@utils/Routing';

interface MobilePhoneSelectDialogProps {
  currentPhone?: WhatsappPhone;
  whatsappPhoneEntries?: WhatsappPhoneEntry[];
  hasNoPhones?: boolean;
  isConfirmed: boolean;
  close(): void;
  onAddAccount(): void;
  onPhoneChange?(next: WhatsappPhone): void;
  onAddPhone(whatsappBusinessAccountId: string): void;
  handlePhoneVerificationClick(phone: WhatsappPhone): void;
}

export const MobilePhoneSelectDialog: React.FC<MobilePhoneSelectDialogProps> =
  ({
    currentPhone,
    whatsappPhoneEntries,
    hasNoPhones,
    close,
    onAddPhone,
    handlePhoneVerificationClick,
    onAddAccount,
    onPhoneChange,
    isConfirmed,
  }) => {
    const [showNoPhonesCallout, setShowNoPhonesCallout] = useState(false);
    const handleAccountMissClick = useCallback(() => {
      setShowNoPhonesCallout(true);
    }, []);
    const botIdToTitle = useBotsTitlesMap();

    const botId = useCurrentBotId()!;
    const { botFeatures } = useBotFeatures(botId);
    const isBuisnessAppIntegration =
      botFeatures?.whatsapp_business_app_integration;

    return (
      <DefaultDialog
        footer={
          <div className={css.mobileFooter}>
            <AddAccountButton
              onAddAccount={() => {
                onAddAccount();
                close();
              }}
            />
          </div>
        }
        onDismiss={close}
        mobileAdaptive
      >
        {(hasNoPhones || showNoPhonesCallout) && <NoPhonesCallout />}
        {whatsappPhoneEntries?.map((entry) => {
          const { id } = entry.businessAccount;
          const title =
            entry.businessAccount.name ??
            WHATSAPP_BUSINESS_ACCOUNT_DEFAULT_NAME;
          const phones = entry.phones ?? [];
          return (
            <React.Fragment key={id}>
              <BusinessAccountItem
                id={id}
                title={title}
                hasPhones={phones.length > 0}
                onAddPhone={(accountId) => {
                  onAddPhone(accountId);
                  close();
                }}
                onAccountMissClick={handleAccountMissClick}
              />
              {phones.map((phone) => {
                const phoneIsVerified = isPhoneVerified(
                  phone,
                  isBuisnessAppIntegration,
                );
                return (
                  <PhoneNumberItem
                    key={phone.id}
                    isConfirmed={isConfirmed}
                    account={entry.businessAccount}
                    phone={phone}
                    currentPhone={currentPhone}
                    phoneIsVerified={phoneIsVerified}
                    handlePhoneVerificationClick={handlePhoneVerificationClick}
                    botIdToTitle={botIdToTitle}
                    onClick={() => {
                      onPhoneChange?.(phone);
                      close();
                    }}
                  />
                );
              })}
            </React.Fragment>
          );
        })}
        <Spacer factor={12} />
      </DefaultDialog>
    );
  };

import React from 'react';
import { Type } from '@ui/Type';
import { PremiumPricingUsageQuery_premiumPricingUsage_usage as PremiumPricingUsage } from '@utils/Data/Pricing/@types/PremiumPricingUsageQuery';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { MONTHS_IN_YEAR_COUNT } from '../constants';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { DateFormat } from '@utils/DateTime';
import * as css from '../UsageTable.css';

interface HeaderRowProps {
  usage: PremiumPricingUsage[] | undefined;
  loading?: boolean;
}

export const HeaderRow = React.memo<HeaderRowProps>(({ usage, loading }) => {
  const { t } = useSafeTranslation();
  return (
    <tr>
      <th>
        <Type size="12px" weight="semibold" color="white">
          {t('pages.Billing.premiumPage.usage.pageName')}
        </Type>
      </th>
      <>
        {loading || !usage ? (
          <th colSpan={MONTHS_IN_YEAR_COUNT}>
            <LoadingPlaceholder className={css.usageTableLoader} />
          </th>
        ) : (
          <>
            {usage.map(({ billingDate }) => (
              <th key={billingDate}>
                <Type as="p" size="12px" weight="semibold" color="white" noWrap>
                  {DateFormat.MMMMD(+billingDate)}
                </Type>
              </th>
            ))}
            {MONTHS_IN_YEAR_COUNT - usage.length > 0 ? (
              <td
                className={css.stubCell}
                colSpan={MONTHS_IN_YEAR_COUNT - usage.length}
              />
            ) : null}
          </>
        )}
      </>
    </tr>
  );
});

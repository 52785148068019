import React from 'react';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';

export interface IntroQuestionsBlockQuestionProps extends TestLocator {
  title: string;
  required: boolean;
}

export const IntroQuestionsBlockQuestion: React.FC<IntroQuestionsBlockQuestionProps> =
  ({ title, children, 'data-testid': dataTestid, required }) => {
    return (
      <div data-testid={dataTestid}>
        <div>
          <Type size="15px" weight="semibold">
            {title}
          </Type>
          {required && (
            <Type size="15px" color="red" weight="semibold">
              *
            </Type>
          )}
        </div>
        <Spacer factor={2} />
        {children}
      </div>
    );
  };

import React from 'react';
import { IconButton } from '@ui/IconButton';
import {
  PlatformBody,
  PlatformHeader,
  PlatformSeparator,
  PlatformConnectionLoading,
  PlatformButton,
} from '@pages/GrowPage/PlatformConnection';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ButtonUnstyled } from '@ui/Button';
import { Type } from '@ui/Type';
import * as css from './WhatsappBotNotCreatedView.css';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

export interface WhatsappBotNotCreatedViewProps {
  loading: boolean;
  buttonLoading: boolean;
  onClose(): void;
  onClick(): void;
}

export const WhatsappBotNotCreatedView: React.FC<WhatsappBotNotCreatedViewProps> =
  ({ loading, buttonLoading, onClose, onClick }) => {
    const { isSmallScreenSize } = useDeviceMedia();
    const { t } = useSafeTranslation();

    if (loading || (buttonLoading && !isSmallScreenSize)) {
      return <PlatformConnectionLoading />;
    }

    return (
      <>
        <PlatformHeader
          icon="whatsapp"
          title={t('pages.BotPage.HomeTab.Whatsapp.titleNeed')}
          data-testid="whatsapp__create-bot"
        >
          <IconButton icon="close" color="black" onClick={onClose} />
        </PlatformHeader>
        <PlatformSeparator />
        <PlatformBody
          description={
            isSmallScreenSize ? (
              t(t('pages.BotPage.HomeTab.Whatsapp.noBotDescription'))
            ) : (
              <>
                {t('pages.BotPage.HomeTab.Whatsapp.noBotDescription1')}{' '}
                <ButtonUnstyled
                  data-testid="whatsapp__create-button"
                  onClick={onClick}
                  className={css.button}
                >
                  <Type color="blue">
                    {t('pages.BotPage.HomeTab.Whatsapp.noBotDescriptionLink')}
                  </Type>
                </ButtonUnstyled>{' '}
                {t('pages.BotPage.HomeTab.Whatsapp.noBotDescription2')}
              </>
            )
          }
        >
          {isSmallScreenSize && (
            <PlatformButton
              data-testid="whatsapp__create-button"
              onClick={onClick}
              loading={buttonLoading}
              intent="secondary"
            >
              {t('pages.BotPage.HomeTab.Whatsapp.createNewBot')}
            </PlatformButton>
          )}
        </PlatformBody>
      </>
    );
  };

import { useCurrentBotId } from '@utils/Routing';
import { QueryOptions, useQuery } from 'react-apollo';
import {
  WhatsappBroadcasts,
  WhatsappBroadcastsVariables,
} from './@types/WhatsappBroadcasts';
import { WHATSAPP_BROADCASTS_QUERY } from './queries';
import { BroadcastStatus, WhatsappBroadcastType } from '@globals';

export const useWhatsappBroadcastsList = ({
  status,
  type,
  options,
}: {
  status?: BroadcastStatus | null;
  type?: WhatsappBroadcastType | null;
  options?: Partial<
    QueryOptions<WhatsappBroadcasts, WhatsappBroadcastsVariables>
  >;
}) => {
  const botId = useCurrentBotId();

  const { data, fetchMore, ...other } = useQuery<
    WhatsappBroadcasts,
    WhatsappBroadcastsVariables
  >(WHATSAPP_BROADCASTS_QUERY, {
    variables: {
      botId: botId!,
      status,
      type,
    },
    skip: !botId,
    ...options,
  });

  return {
    whatsappBroadcastsList: data?.whatsappBroadcastList?.broadcasts || [],
    fetchMore: () => {
      if (!data?.whatsappBroadcastList?.nextBefore) {
        return Promise.resolve();
      }

      return fetchMore({
        query: WHATSAPP_BROADCASTS_QUERY,
        variables: {
          botId: botId!,
          status,
          type,
          before: data?.whatsappBroadcastList.nextBefore,
        },
        updateQuery(prev, { fetchMoreResult }) {
          if (!fetchMoreResult?.whatsappBroadcastList) return prev;

          return {
            ...prev,
            whatsappBroadcastList: {
              ...prev.whatsappBroadcastList,
              broadcasts: [
                ...(prev.whatsappBroadcastList?.broadcasts || []),
                ...fetchMoreResult.whatsappBroadcastList.broadcasts,
              ],
              nextBefore: fetchMoreResult.whatsappBroadcastList.nextBefore,
            },
          } as WhatsappBroadcasts;
        },
      });
    },
    isFetchMoreAvailable: Boolean(data?.whatsappBroadcastList?.nextBefore),
    ...other,
  };
};

import { useQuery } from 'react-apollo';
import { ADMIN_PHONES_QUERY } from './queries';
import {
  AdminPhonesQuery,
  AdminPhonesQuery_me_phones_phoneNumbers as PhoneNumber,
} from './@types/AdminPhonesQuery';
import { last } from 'ramda';
import { useMemo } from 'react';
import { useWhatsappPhoneEntries } from '@utils/Data/Whatsapp/useWhatsappPhoneEntries';
import { useCurrentBotId } from '@utils/Routing';
import { useRefetchAdminPhones } from './useRefreshAdminPhones';

const POOL_INTERVAL = 1000;

export const useAdminPhones = (pooling?: boolean) => {
  const botId = useCurrentBotId();
  const {
    data,
    loading: phonesLoading,
    ...queryState
  } = useQuery<AdminPhonesQuery>(ADMIN_PHONES_QUERY, {
    pollInterval: pooling ? POOL_INTERVAL : 0,
    notifyOnNetworkStatusChange: true,
  });
  const { refetch } = useRefetchAdminPhones();

  const {
    loading: whatsappPhoneEntriesLoading,
    whatsappPhoneEntries,
    noFBToken,
  } = useWhatsappPhoneEntries(botId);
  const phones = data?.me.phones.phoneNumbers;

  const notUtilizedPhones = useMemo(
    () => phones?.filter(({ isUtilized }) => !isUtilized),
    [phones],
  );

  const lastNotUtilizedPhone = last(notUtilizedPhones || []) as
    | PhoneNumber
    | undefined;

  const lastNotUtilizedPhoneWhatsappEntry = useMemo(
    () =>
      whatsappPhoneEntries.find(({ phones }) =>
        phones?.some(
          ({ normalized_phone_number: phone }) =>
            phone === lastNotUtilizedPhone?.phoneNumber,
        ),
      ),
    [lastNotUtilizedPhone, whatsappPhoneEntries],
  );

  const lastNotUtilizedWhatsappPhone = useMemo(
    () =>
      lastNotUtilizedPhoneWhatsappEntry?.phones?.find(
        ({ normalized_phone_number: phone }) =>
          phone === lastNotUtilizedPhone?.phoneNumber,
      ),
    [lastNotUtilizedPhone, lastNotUtilizedPhoneWhatsappEntry],
  );

  return {
    phones,
    noFBToken,
    canPurchaseNewPhone: data?.me.phones.canPurchaseNewPhone,
    notUtilizedPhones,
    lastNotUtilizedPhone,
    lastNotUtilizedPhoneWhatsappEntry,
    lastNotUtilizedWhatsappPhone,
    loading: whatsappPhoneEntriesLoading || phonesLoading,
    ...queryState,
    refetch,
  };
};

import gql from 'graphql-tag';

export const REREGISTER_WHATSAPP_MUTATION = gql`
  mutation ReregisterWhatsappMutation($botId: String!) {
    reregisterWhatsapp(botId: $botId) {
      id
      hasEmbeddedSignupToken
      whatsappBusinessAccount {
        id
        name
        timezone_id
        message_template_namespace
        account_review_status
        business_verification_status
        on_behalf_of_business_info {
          id
          name
          status
          type
        }
      }
      phone {
        id
        quality_rating
        display_phone_number
        code_verification_status
        normalized_phone_number
        verified_name
        is_on_biz_app
      }
      webhookUrl
      qrCode {
        code
        prefilled_message
        deep_link_url
        qr_image_url
      }
    }
  }
`;

import React, { useCallback } from 'react';
import { useToaster } from '@ui/Toaster';
import { getAggregatedFlow } from '@utils/Data/Flow/Aggregated';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  FlowNotFoundError,
  RecreatedFlowError,
} from '@utils/Data/Flow/Aggregated/errors';
import { BotTabs, getCurrentBroadcastId, getTabLink } from '@utils/Routing';
import { useHistory } from 'react-router-dom';
import { useTryToRecreateRemovedFlow } from './hooks';

export const useLoadAggregatedFlow = (
  botId: string,
  catchUnhandledError: (error: any) => void,
) => {
  const history = useHistory();
  const { addToaster } = useToaster();
  const { t, i18n } = useSafeTranslation();
  const tryToRecreateRemovedFlow = useTryToRecreateRemovedFlow();
  const isReengageTab = !!getCurrentBroadcastId();

  const redirectToFlow = (flowId: string) => {
    history.push(getTabLink(BotTabs.flows, botId, { flowId }));
  };

  const showFlowDoesntExistToaster = useCallback(() => {
    addToaster({
      type: 'error',
      content: (
        <div style={{ minWidth: '300px', whiteSpace: 'nowrap' }}>
          {t('FlowTab-JSXText--188-flow-doesnt-exist')}
        </div>
      ),
      timeout: 2000,
      closeButton: true,
    });
  }, [addToaster, t]);

  const loadFlow = (botId: string, flowId: string) => {
    return getAggregatedFlow(botId, flowId, i18n.language).catch((error) => {
      if (error instanceof RecreatedFlowError) {
        redirectToFlow(error.recreatedFlowId);
        return Promise.reject(error);
      }
      if (error instanceof FlowNotFoundError && !isReengageTab) {
        return tryToRecreateRemovedFlow(botId, flowId).then(
          (recreatedFlowId) => {
            if (recreatedFlowId == null) {
              showFlowDoesntExistToaster();
              return Promise.reject(
                new Error(`Can't restore flow after FlowNotFoundError`),
              );
            }
            redirectToFlow(recreatedFlowId);
            return Promise.reject(error);
          },
        );
      }
      catchUnhandledError?.(error);
      return Promise.reject(error);
    });
  };

  return loadFlow;
};

import { useEffect, useRef } from 'react';
import { WhatsappBroadcast_whatsappBroadcast } from './@types/WhatsappBroadcast';
import { BroadcastStatus } from '@globals';

export const useOnBroadcastTimePassed = (
  config: WhatsappBroadcast_whatsappBroadcast | undefined,
  callback: VoidFunction,
) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (config?.status === BroadcastStatus.DRAFT) {
      return undefined;
    }

    if (!config?.schedule?.startingTimestamp) {
      return undefined;
    }

    const timeLeft = config.schedule.startingTimestamp * 1000 - Date.now();

    if (timeLeft < 0) {
      return undefined;
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(callback, timeLeft);

    return () => clearTimeout(timeoutRef.current!);
  }, [callback, config]);
};

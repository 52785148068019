import i18n from 'i18next';
import memoize from 'lodash-es/memoize';
import { Category } from '@components/ItemsModal';
import { Platform } from '@globals';
import { Template } from './types';
import { ReactComponent as AnswerFAQIcon } from './images/icons/answer-faq.svg';
import { ReactComponent as RecommendProductIcon } from './images/icons/recommend-a-product.svg';
import { ReactComponent as SaveEmailIcon } from './images/icons/save-emails.svg';
import { ReactComponent as CollectLeadsFromCommentsIcon } from './images/icons/collect-leads-from-comments.svg';
import { ReactComponent as FacebookAdsIcon } from './images/icons/facebook-ads.svg';
import { ReactComponent as CollectFeedbackIcon } from './images/icons/collect-feedback.svg';
import { ReactComponent as SpecialOfferIcon } from './images/icons/special-offer.svg';
import { ReactComponent as CartIcon } from './images/icons/cart.svg';
import { ReactComponent as QualifyLeadsIcon } from './images/icons/qualify-leads.svg';

import { ReactComponent as CollectEmailsIgIcon } from './images/icons/collect-emails-ig.svg';
import { ReactComponent as CollectFeedbackIgIcon } from './images/icons/collect-feedback-ig.svg';
import { ReactComponent as GiveAPromoCodeIgIcon } from './images/icons/give-a-promo-code-ig.svg';
import { ReactComponent as QualifyLeadsIgIcon } from './images/icons/qualify-leads-ig.svg';
import { ReactComponent as SpecialOfferIgIcon } from './images/icons/special-offer-ig.svg';

type FacebookTemplates = Array<Template<Category.facebook>>;
type InstagramTemplates = Array<Template<Category.instagram>>;
type WebsiteTemplates = Array<Template<Category.website>>;

export const getTemplates = memoize((): Template[] => {
  const instagramTemplates: InstagramTemplates = [
    {
      id: 'instagramFAQ',
      category: Category.instagram,
      platform: Platform.instagram,
      title: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramFAQ.title',
      ),
      description: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramFAQ.description',
      ),
      details: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramFAQ.details',
      ),
      icon: AnswerFAQIcon,
      flowId: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramFAQ.flowId',
      ),
    },
    {
      id: 'instagramProductRecommendation',
      category: Category.instagram,
      platform: Platform.instagram,
      title: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramProductRecommendation.title',
      ),
      description: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramProductRecommendation.description',
      ),
      details: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramProductRecommendation.details',
      ),
      icon: RecommendProductIcon,
      flowId: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramProductRecommendation.flowId',
      ),
    },
    {
      id: 'instagramCollectFeedback',
      category: Category.instagram,
      platform: Platform.instagram,
      title: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramCollectFeedback.title',
      ),
      description: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramCollectFeedback.description',
      ),
      details: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramCollectFeedback.details',
      ),
      icon: CollectFeedbackIgIcon,
      flowId: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramCollectFeedback.flowId',
      ),
    },
    {
      id: 'instagramCollectEmails',
      category: Category.instagram,
      platform: Platform.instagram,
      title: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramCollectEmails.title',
      ),
      description: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramCollectEmails.description',
      ),
      details: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramCollectEmails.details',
      ),
      icon: CollectEmailsIgIcon,
      flowId: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramCollectEmails.flowId',
      ),
    },
    {
      id: 'instagramQualifyLeads',
      category: Category.instagram,
      platform: Platform.instagram,
      title: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramQualifyLeads.title',
      ),
      description: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramQualifyLeads.description',
      ),
      details: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramQualifyLeads.details',
      ),
      icon: QualifyLeadsIgIcon,
      flowId: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramQualifyLeads.flowId',
      ),
    },
    {
      id: 'instagramSpecialOffer',
      category: Category.instagram,
      platform: Platform.instagram,
      title: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramSpecialOffer.title',
      ),
      description: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramSpecialOffer.description',
      ),
      details: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramSpecialOffer.details',
      ),
      icon: SpecialOfferIgIcon,
      flowId: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramSpecialOffer.flowId',
      ),
    },
    {
      id: 'instagramGivePromoCode',
      category: Category.instagram,
      platform: Platform.instagram,
      title: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramGivePromoCode.title',
      ),
      description: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramGivePromoCode.description',
      ),
      details: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramGivePromoCode.details',
      ),
      icon: GiveAPromoCodeIgIcon,
      flowId: i18n.t(
        'modernComponents.TemplatesModal.templates.instagramGivePromoCode.flowId',
      ),
    },
  ];

  const facebookTemplates: FacebookTemplates = [
    {
      id: 'faqBasic',
      category: Category.facebook,
      platform: Platform.facebook,
      title: i18n.t('modernComponents.TemplatesModal.templates.faqBasic.title'),
      description: i18n.t(
        'modernComponents.TemplatesModal.templates.faqBasic.description',
      ),
      details: i18n.t(
        'modernComponents.TemplatesModal.templates.faqBasic.details',
      ),
      icon: AnswerFAQIcon,
      flowId: i18n.t(
        'modernComponents.TemplatesModal.templates.faqBasic.flowId',
      ),
      flowGroupId: undefined,
    },
    {
      id: 'leadgenBasic',
      category: Category.facebook,
      platform: Platform.facebook,
      title: i18n.t(
        'modernComponents.TemplatesModal.templates.leadgenBasic.title',
      ),
      description: i18n.t(
        'modernComponents.TemplatesModal.templates.leadgenBasic.description',
      ),
      details: i18n.t(
        'modernComponents.TemplatesModal.templates.leadgenBasic.details',
      ),
      icon: SaveEmailIcon,
      flowId: i18n.t(
        'modernComponents.TemplatesModal.templates.leadgenBasic.flowId',
      ),
    },
    {
      id: 'engageFromCommentsBasic',
      category: Category.facebook,
      platform: Platform.facebook,
      title: i18n.t(
        'modernComponents.TemplatesModal.templates.engageFromCommentsBasic.title',
      ),
      description: i18n.t(
        'modernComponents.TemplatesModal.templates.engageFromCommentsBasic.description',
      ),
      details: i18n.t(
        'modernComponents.TemplatesModal.templates.engageFromCommentsBasic.details',
      ),
      icon: CollectLeadsFromCommentsIcon,
      flowId: i18n.t(
        'modernComponents.TemplatesModal.templates.engageFromCommentsBasic.flowId',
      ),
    },
    {
      id: 'segmentAudienceAdvanced',
      category: Category.facebook,
      alternativeId: 'leadgenBasic',
      platform: Platform.facebook,
      title: i18n.t(
        'modernComponents.TemplatesModal.templates.segmentAudienceAdvanced.title',
      ),
      description: i18n.t(
        'modernComponents.TemplatesModal.templates.segmentAudienceAdvanced.description',
      ),
      details: i18n.t(
        'modernComponents.TemplatesModal.templates.segmentAudienceAdvanced.details',
      ),
      icon: QualifyLeadsIcon,
      flowId: i18n.t(
        'modernComponents.TemplatesModal.templates.segmentAudienceAdvanced.flowId',
      ),
    },
    {
      id: 'facebookAdsBasic',
      category: Category.facebook,
      platform: Platform.facebook,
      title: i18n.t(
        'modernComponents.TemplatesModal.templates.facebookAdsBasic.title',
      ),
      description: i18n.t(
        'modernComponents.TemplatesModal.templates.facebookAdsBasic.description',
      ),
      details: i18n.t(
        'modernComponents.TemplatesModal.templates.facebookAdsBasic.details',
      ),
      icon: FacebookAdsIcon,
      flowId: i18n.t(
        'modernComponents.TemplatesModal.templates.facebookAdsBasic.flowId',
      ),
    },
    {
      id: 'collectFeedbackBasic',
      category: Category.facebook,
      platform: Platform.facebook,
      title: i18n.t(
        'modernComponents.TemplatesModal.templates.collectFeedbackBasic.title',
      ),
      description: i18n.t(
        'modernComponents.TemplatesModal.templates.collectFeedbackBasic.description',
      ),
      details: i18n.t(
        'modernComponents.TemplatesModal.templates.collectFeedbackBasic.details',
      ),
      icon: CollectFeedbackIcon,
      flowId: i18n.t(
        'modernComponents.TemplatesModal.templates.collectFeedbackBasic.flowId',
      ),
    },
    {
      id: 'remindEventBasic', // Remind users about an event → Special Offer
      category: Category.facebook,
      platform: Platform.facebook,
      title: i18n.t(
        'modernComponents.TemplatesModal.templates.remindEventBasic.title',
      ),
      description: i18n.t(
        'modernComponents.TemplatesModal.templates.remindEventBasic.description',
      ),
      details: i18n.t(
        'modernComponents.TemplatesModal.templates.remindEventBasic.details',
      ),
      icon: SpecialOfferIcon,
      flowId: i18n.t(
        'modernComponents.TemplatesModal.templates.remindEventBasic.flowId',
      ),
    },
    {
      id: 'backInStockAlertBasic',
      category: Category.facebook,
      platform: Platform.facebook,
      title: i18n.t(
        'modernComponents.TemplatesModal.templates.backInStockAlertBasic.title',
      ),
      description: i18n.t(
        'modernComponents.TemplatesModal.templates.backInStockAlertBasic.description',
      ),
      details: i18n.t(
        'modernComponents.TemplatesModal.templates.backInStockAlertBasic.details',
      ),
      icon: CartIcon,
      flowId: i18n.t(
        'modernComponents.TemplatesModal.templates.backInStockAlertBasic.flowId',
      ),
    },
  ];

  const websiteTemplates: WebsiteTemplates = [
    {
      id: 'faqBasic',
      category: Category.website,
      platform: Platform.facebook,
      title: i18n.t('modernComponents.TemplatesModal.templates.faqBasic.title'),
      description: i18n.t(
        'modernComponents.TemplatesModal.templates.faqBasic.description',
      ),
      details: i18n.t(
        'modernComponents.TemplatesModal.templates.faqBasic.details',
      ),
      icon: AnswerFAQIcon,
      flowId: i18n.t(
        'modernComponents.TemplatesModal.templates.faqBasic.flowId',
      ),
      flowGroupId: undefined,
    },
    {
      id: 'leadgenBasic',
      category: Category.website,
      platform: Platform.facebook,
      title: i18n.t(
        'modernComponents.TemplatesModal.templates.leadgenBasic.title',
      ),
      description: i18n.t(
        'modernComponents.TemplatesModal.templates.leadgenBasic.description',
      ),
      details: i18n.t(
        'modernComponents.TemplatesModal.templates.leadgenBasic.details',
      ),
      icon: SaveEmailIcon,
      flowId: i18n.t(
        'modernComponents.TemplatesModal.templates.leadgenBasic.flowId',
      ),
    },
    {
      id: 'collectFeedbackBasic',
      category: Category.website,
      platform: Platform.facebook,
      title: i18n.t(
        'modernComponents.TemplatesModal.templates.collectFeedbackBasic.title',
      ),
      description: i18n.t(
        'modernComponents.TemplatesModal.templates.collectFeedbackBasic.description',
      ),
      details: i18n.t(
        'modernComponents.TemplatesModal.templates.collectFeedbackBasic.details',
      ),
      icon: CollectFeedbackIcon,
      flowId: i18n.t(
        'modernComponents.TemplatesModal.templates.collectFeedbackBasic.flowId',
      ),
    },
    {
      id: 'backInStockAlertBasic',
      category: Category.website,
      platform: Platform.facebook,
      title: i18n.t(
        'modernComponents.TemplatesModal.templates.backInStockAlertBasic.title',
      ),
      description: i18n.t(
        'modernComponents.TemplatesModal.templates.backInStockAlertBasic.description',
      ),
      details: i18n.t(
        'modernComponents.TemplatesModal.templates.backInStockAlertBasic.details',
      ),
      icon: CartIcon,
      flowId: i18n.t(
        'modernComponents.TemplatesModal.templates.backInStockAlertBasic.flowId',
      ),
    },
    {
      id: 'remindEventBasic',
      category: Category.website,
      platform: Platform.facebook,
      title: i18n.t(
        'modernComponents.TemplatesModal.templates.remindEventBasic.title',
      ),
      description: i18n.t(
        'modernComponents.TemplatesModal.templates.remindEventBasic.description',
      ),
      details: i18n.t(
        'modernComponents.TemplatesModal.templates.remindEventBasic.details',
      ),
      icon: SpecialOfferIcon,
      flowId: i18n.t(
        'modernComponents.TemplatesModal.templates.remindEventBasic.flowId',
      ),
    },
  ];

  return [...facebookTemplates, ...instagramTemplates, ...websiteTemplates];
});

import React from 'react';
import { NextChargeDetails } from './components/NextChargeDetails';
import { FailedPaymentChargeDetails } from './components/FailedPaymentChargeDetails';
import { usePaymentData } from '../BotSectionView/Data/usePaymentData';
import { useBillingData } from '../BotSectionView/Data/useBillingData';
import { NextAdjustmentFeeChargeDetails } from './components/NextAdjustmentFeeChargeDetails';

export const PlanNextChargeDetails: React.FC = () => {
  const { pricing, isAdjustmentFeeBillingUpcoming } = useBillingData();
  const { paymentError } = usePaymentData();

  if (!pricing) {
    return null;
  }

  if (paymentError?.isPaymentFailed) {
    return <FailedPaymentChargeDetails />;
  }

  if (isAdjustmentFeeBillingUpcoming) {
    return <NextAdjustmentFeeChargeDetails />;
  }

  return <NextChargeDetails />;
};

import { Category } from '@components/ItemsModal';
import { Platform } from '@globals';

export const getPlatformByCategory = (category: Category) => {
  let platform = null;

  switch (category) {
    case Category.facebook:
    case Category.shopify:
    case Category.website: {
      platform = Platform.facebook;
      break;
    }

    case Category.instagram:
      platform = Platform.instagram;
      break;
    default:
      throw new Error(`Unexpected template category ${category}`);
  }
  return platform;
};

import React from 'react';
import {
  UnpausePlanButton as UnpausePlanButtonComponent,
  UnpausePlanButtonProps as UnpausePlanButtonComponentProps,
} from '@components/DialogsPricing';
import { useBotSectionData } from '../Data/useBotSectionData';
import { useBillingData } from '../Data/useBillingData';

type SpecifiedProps =
  | 'pricingPlanId'
  | 'tier'
  | 'botId'
  | 'from'
  | 'data-testid'
  | 'loading';

export interface UnpausePlanButtonProps
  extends Omit<UnpausePlanButtonComponentProps, SpecifiedProps> {}

export const UnpausePlanButton: React.FC<UnpausePlanButtonProps> = (props) => {
  const { bot, loading: botLoading } = useBotSectionData();
  const { pricing, tier, loading: billingLoading } = useBillingData();

  const loading = billingLoading || botLoading || !pricing || !bot;

  return (
    <UnpausePlanButtonComponent
      {...props}
      pricingPlanId={pricing!.pricing_plan_id}
      tier={tier?.type}
      botId={bot!.id}
      from="home"
      data-testid="bot-panel__unpause-button"
      loading={loading}
    />
  );
};

import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import {
  RequestWhatsappVerificationCode,
  RequestWhatsappVerificationCodeVariables,
} from './@types/RequestWhatsappVerificationCode';
import {
  VerifyWhatsappCode,
  VerifyWhatsappCodeVariables,
} from './@types/VerifyWhatsappCode';
import { useCallback } from 'react';

export const WHATSAPP_CODE_FRAGMENT = gql`
  fragment whatsappCodeFragment on WhatsappVerificationCodeResponse {
    success
    errorType
  }
`;

export const VERIFY_WHATSAPP_CODE_MUTATION = gql`
  mutation VerifyWhatsappCode($phoneId: String!, $code: String!) {
    verifyWhatsappCode(phoneId: $phoneId, code: $code) {
      ...whatsappCodeFragment
    }
  }

  ${WHATSAPP_CODE_FRAGMENT}
`;

export const useVerifyWhatsappCode = () => {
  const [verifyCodeRaw, queryResult] = useMutation<
    VerifyWhatsappCode,
    VerifyWhatsappCodeVariables
  >(VERIFY_WHATSAPP_CODE_MUTATION, {
    optimisticResponse: {
      verifyWhatsappCode: {
        __typename: 'WhatsappVerificationCodeResponse',
        success: true,
        errorType: null,
      },
    },
  });

  const verifyCode = useCallback(
    (variables: VerifyWhatsappCodeVariables) => {
      return verifyCodeRaw({ variables });
    },
    [verifyCodeRaw],
  );

  return {
    verifyCode,
    errorType: queryResult.data?.verifyWhatsappCode?.errorType,
    ...queryResult,
  };
};

export const REQUEST_WHATSAPP_VERIFICATION_CODE_MUTATION = gql`
  mutation RequestWhatsappVerificationCode($phoneId: String!) {
    requestWhatsappVerificationCode(phoneId: $phoneId) {
      ...whatsappCodeFragment
    }
  }

  ${WHATSAPP_CODE_FRAGMENT}
`;

export const useRequestWhatsappVerificationCode = () => {
  const [requestCodeRaw, queryResult] = useMutation<
    RequestWhatsappVerificationCode,
    RequestWhatsappVerificationCodeVariables
  >(REQUEST_WHATSAPP_VERIFICATION_CODE_MUTATION, {
    optimisticResponse: {
      requestWhatsappVerificationCode: {
        __typename: 'WhatsappVerificationCodeResponse',
        success: true,
        errorType: null,
      },
    },
  });

  const requestCode = useCallback(
    (variables: RequestWhatsappVerificationCodeVariables) => {
      return requestCodeRaw({ variables });
    },
    [requestCodeRaw],
  );

  return {
    requestCode,
    errorType: queryResult.data?.requestWhatsappVerificationCode?.errorType,
    ...queryResult,
  };
};

import React from 'react';
import { DefaultDialog, DefaultDialogProps } from '@ui/Dialog';
import { Header } from './Components';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { EnterVerificationCodeView } from './EnterVerificationCodeView';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  UseEnterVerificaitonCodeParams,
  useEnterVerificaitonCode,
} from './useEnterVerificaitonCode';

interface EnterVerificationCodeDialogProps
  extends Omit<DefaultDialogProps, 'children'>,
    UseEnterVerificaitonCodeParams {
  phoneNumber: string;
}

export const EnterVerificationCodeDialog: React.FC<EnterVerificationCodeDialogProps> =
  ({
    onSubmit,
    phoneId,
    requestCode,
    called,
    errorType,
    error: requestCodeError,
    ...dialogProps
  }) => {
    const { t } = useSafeTranslation();
    const { isSmallScreenSize } = useDeviceMedia();
    const {
      error,
      code,
      disabled,
      setCode,
      inputErrorMessage,
      timer,
      onResendClick,
      onSubmitClick,
      verification,
    } = useEnterVerificaitonCode({
      onSubmit,
      phoneId,
      called,
      requestCode,
      error: requestCodeError,
      errorType,
    });

    return (
      <DefaultDialog
        small
        mobileAdaptive={isSmallScreenSize}
        header={
          <Header>
            {t('pages.BotPage.HomeTab.Whatsapp.EnterVerificationCode.header')}
          </Header>
        }
        dialogStyle={{ width: 400 }}
        {...dialogProps}
      >
        <EnterVerificationCodeView
          error={error}
          code={code}
          setCode={setCode}
          inputErrorMessage={inputErrorMessage}
          canRetry={timer.started}
          timeLeft={timer.time}
          submitLoading={verification.loading}
          onResendClick={onResendClick}
          onSubmit={onSubmitClick}
          disabled={disabled}
        />
      </DefaultDialog>
    );
  };

import i18next from 'i18next';
import { formatPrice } from '@utils/Format';
import Maybe from 'graphql/tsutils/Maybe';

interface GetTooltipContentParams {
  invoice_total: Maybe<number>;
  adjustmentPrice: Maybe<number>;
  pausePricingAmount: Maybe<number>;
  currency: string;
  isPaused: boolean;
  tierName: string;
}

const formatNullablePrice = (price: Maybe<number>, currency: string) =>
  price ? formatPrice(price, { currency }) : null;

const getNextChargeTooltip = ({
  invoice_total,
  currency,
  tierName,
}: GetTooltipContentParams) => {
  const invoice = formatNullablePrice(invoice_total, currency);
  if (!invoice) {
    return null;
  }

  return i18next.t('pages.GrowPage.BotPanel.tooltips.nextCharge', {
    amount: invoice,
    plan: tierName,
  });
};

const getNextChargeWithFeeTooltip = ({
  invoice_total,
  adjustmentPrice,
  currency,
  tierName,
}: GetTooltipContentParams) => {
  const invoiceTotal = (invoice_total ?? 0) - (adjustmentPrice ?? 0);
  const invoice = formatNullablePrice(invoiceTotal, currency);
  const fee = formatNullablePrice(adjustmentPrice, currency);
  if (!fee || !invoice) {
    return null;
  }

  return i18next.t('pages.GrowPage.BotPanel.tooltips.nextChargeWithFee', {
    amount: invoice,
    plan: tierName,
    fee,
  });
};

const getPausedTooltip = ({
  pausePricingAmount,
  currency,
}: GetTooltipContentParams) => {
  const pauseAmount = pausePricingAmount
    ? formatPrice(pausePricingAmount, { currency })
    : null;
  if (!pauseAmount) {
    return null;
  }

  return i18next.t('pages.GrowPage.BotPanel.tooltips.pausingFee', {
    fee: pauseAmount,
  });
};

export const getTooltipContent = (params: GetTooltipContentParams) => {
  if (params.isPaused) {
    return getPausedTooltip(params);
  }

  return getNextChargeWithFeeTooltip(params) || getNextChargeTooltip(params);
};

interface GetNextChargeAdjustmentFeeTooltipParams {
  adjustmentPrice: Maybe<number>;
  currency: string;
}

export const getNextChargeAdjustmentFeeTooltip = ({
  adjustmentPrice,
  currency,
}: GetNextChargeAdjustmentFeeTooltipParams) => {
  if (!adjustmentPrice) {
    return null;
  }

  const price = formatNullablePrice(adjustmentPrice, currency);

  return i18next.t('pages.Pricing.businessCard.pricePerExtraDialog', {
    price,
  });
};

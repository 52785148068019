import React from 'react';
import cn from 'classnames';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import * as css from './Primitives.css';

export interface TitleProps extends TestLocator {
  className?: string;
}

export const Title: React.FC<TitleProps> = ({
  children,
  className,
  ...props
}) => (
  <Type size="15px" className={className} data-testid={props['data-testid']}>
    {children}
  </Type>
);

export interface SubscriptionProps extends TestLocator {}

export const Subscription: React.FC<SubscriptionProps> = ({
  children,
  ...props
}) => (
  <Type
    size="12px"
    color="grey"
    weight="medium"
    data-testid={props['data-testid']}
  >
    {children}
  </Type>
);

export const Dash: React.FC = () => (
  <Type size="15px" color="grey">
    —
  </Type>
);

export interface UlProps {
  className?: string;
}

export const Ul: React.FC<UlProps> = ({ children, className }) => (
  <ul className={cn(css.ul, className)}>{children}</ul>
);

export interface DescriptionItemViewProps extends TitleProps {
  title: string;
  subscription: string;
}

export const DescriptionItemView: React.FC<DescriptionItemViewProps> = ({
  title,
  subscription,
  className,
}) => (
  <Flex flexDirection="column">
    <Title className={className} data-testid="payment-history__item-title">
      {title}
    </Title>
    <Subscription data-testid="payment-history__item-subscription">
      {subscription}
    </Subscription>
  </Flex>
);

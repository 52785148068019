import { useLazyQuery } from 'react-apollo';
import {
  UserCountByFilterQuery,
  UserCountByFilterQueryVariables,
} from './@types/UserCountByFilterQuery';
import { USER_COUNT_BY_FILTER_QUERY } from './queries';

export const useUserCount = () => {
  return useLazyQuery<UserCountByFilterQuery, UserCountByFilterQueryVariables>(
    USER_COUNT_BY_FILTER_QUERY,
    {
      fetchPolicy: 'no-cache',
    },
  );
};

import { BillingDetailsFormProps } from '@components/Payments/BillingDetails';
import { WorkspacesBillingInfoQuery_workspacesBillingDetails } from '@utils/Data/Pricing/@types/WorkspacesBillingInfoQuery';
import Maybe from 'graphql/tsutils/Maybe';
import { PremiumBillingInfoProps } from '../components/PremiumBillingInfo/PremiumBillingInfo';

export interface GetBillingAdressDetails {
  company_name: Maybe<string>;
  email: Maybe<string>;
  city: Maybe<string>;
  street: Maybe<string>;
  postcode: Maybe<string>;
  country: Maybe<string>;
}

export const getBillingAddress = (
  details: Maybe<GetBillingAdressDetails>,
): PremiumBillingInfoProps['billingAdress'] => {
  const { company_name, email, city, street, postcode, country } =
    details ?? {};

  return {
    company: company_name,
    email,
    adress: (city || street) && `${city}${city && street ? ', ' : ''}${street}`,
    postcode,
    country,
  };
};

export const getBillingCard = (
  billingDetails: Maybe<WorkspacesBillingInfoQuery_workspacesBillingDetails>,
): PremiumBillingInfoProps['billingCard'] => {
  const { card4LastDigits, cardType } = billingDetails ?? {};

  return {
    lastDigits: card4LastDigits,
    cardType,
    error: false,
  };
};

const getRequiredString = (value: Maybe<string>) => value ?? '';

export const getBillingInfo = (
  billingDetails: Maybe<WorkspacesBillingInfoQuery_workspacesBillingDetails>,
): BillingDetailsFormProps['initialValues'] => {
  const {
    company_name,
    email,
    tax_id,
    city,
    street,
    state,
    postcode,
    country,
  } = billingDetails ?? {};

  return {
    company_name: getRequiredString(company_name),
    email: getRequiredString(email),
    tax_id: getRequiredString(tax_id),
    street: getRequiredString(street),
    city: getRequiredString(city),
    state: getRequiredString(state),
    postcode: getRequiredString(postcode),
    country: getRequiredString(country),
  };
};

import React from 'react';
import cn from 'classnames';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Invoices, InvoicesProps } from '../../Invoices';
import css from './NextCharge.css';

interface NextChargeProps<InvoiceItem>
  extends Pick<
    InvoicesProps<InvoiceItem>,
    'columns' | 'headers' | 'columnClassName' | 'itemHeight'
  > {
  nextCharge: InvoiceItem | null;
  nextChargeParts: number;
}

export const NextCharge = <InvoiceItem,>({
  nextCharge,
  nextChargeParts,
  ...props
}: NextChargeProps<InvoiceItem>) => {
  const { t } = useSafeTranslation();

  return nextCharge ? (
    <Invoices<InvoiceItem>
      className={cn(css.nextCharge, {
        [css.nextChargeDefault]: nextChargeParts <= 1,
        [css.nextChargeComplex]: nextChargeParts > 1,
      })}
      title={t('pages.Billing.invoices.nextCharge')}
      listHeight={48}
      items={[nextCharge]}
      data-testid="next-charge__row"
      {...props}
    />
  ) : null;
};

import { MessagesTemplateErrorType } from '@globals';
import { exhaustiveCheck } from '@utils/exhaustiveCheck';
import memoize from 'lodash-es/memoize';
import i18next from 'i18next';

export const getPublishTemplateErrorText = memoize(
  (templateError: MessagesTemplateErrorType) => {
    switch (templateError) {
      case MessagesTemplateErrorType.meta_upload_error:
      case MessagesTemplateErrorType.unexpected_error:
        return i18next.t('common.ToasterMessages.somethingWentWrong');
      case MessagesTemplateErrorType.whatsapp_business_is_restricted:
        return i18next.t('pages.MessagesTemplates.accountIsRestricted');
      case MessagesTemplateErrorType.whatsapp_template_name_is_taken:
        return i18next.t('pages.MessagesTemplates.nameIsTaken');
      case MessagesTemplateErrorType.too_many_buttons_of_type:
      case MessagesTemplateErrorType.string_contains_invalid_number_of_examples:
      case MessagesTemplateErrorType.string_is_too_long:
      case MessagesTemplateErrorType.string_is_too_short:
      case MessagesTemplateErrorType.string_is_not_a_valid_phone_number:
      case MessagesTemplateErrorType.string_contains_too_many_attributes:
      case MessagesTemplateErrorType.string_is_not_a_valid_url:
        return null;
      default:
        return exhaustiveCheck(templateError);
    }
  },
);

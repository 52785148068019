import React, { useMemo } from 'react';
import {
  FROM_CHATFUEL,
  FROM_USER,
  I18_IGNORE_ATTRIBUTES,
  mapId,
  MESSAGE_COMMON_DATA,
  TEMPLATE_BUTTON_COMMON_DATA,
  TEMPLATE_COMMON_DATA,
} from './common';
import { EMPTY_MESSAGE_DATA } from '@components/QuickAccessToolbar/TestThis/TestThisChatfuel/utils';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { MessagesTemplatesTableSlide } from '../MessagesTemplatesTableSlide';
import ava2 from './images/ava2.png';
import img2 from './images/img2.png';

const DAYS_5_AND_SOME = 3600 * 24 * 5 * 1000 + 3600 * 12 * Math.random() * 1000;
const MINUTES_1 = 60 * 1000;
const DATE_NOW = Date.now();

export const MessageSlide2 = () => {
  const { t } = useSafeTranslation();

  const messages = useMemo(
    () =>
      [
        {
          ...MESSAGE_COMMON_DATA,
          date: (DATE_NOW - DAYS_5_AND_SOME - MINUTES_1).toString(),
          message: {
            ...EMPTY_MESSAGE_DATA,
            text: t(
              'pages.MessagesTemplates.Table.emptyState.slider.item2.message1.text',
              I18_IGNORE_ATTRIBUTES,
            ),
          },
          from: FROM_USER,
        },
        {
          ...MESSAGE_COMMON_DATA,
          date: (DATE_NOW - DAYS_5_AND_SOME).toString(),
          message: {
            ...EMPTY_MESSAGE_DATA,
            text: t(
              'pages.MessagesTemplates.Table.emptyState.slider.item2.message2.text',
            ),
          },
          from: FROM_CHATFUEL,
        },
        {
          ...MESSAGE_COMMON_DATA,
          date: DATE_NOW.toString(),
          message: {
            ...EMPTY_MESSAGE_DATA,
            whatsapp_template: {
              ...TEMPLATE_COMMON_DATA,
              header_image: img2,
              body: t(
                'pages.MessagesTemplates.Table.emptyState.slider.item2.message3.text',
                I18_IGNORE_ATTRIBUTES,
              ),
              buttons: [
                {
                  ...TEMPLATE_BUTTON_COMMON_DATA,
                  text: t(
                    'pages.MessagesTemplates.Table.emptyState.slider.item2.message3.button',
                  ),
                },
              ],
            },
          },
          from: FROM_CHATFUEL,
        },
      ].map(mapId),
    [t],
  );

  return (
    <MessagesTemplatesTableSlide messages={messages} userAvatarSrc={ava2} />
  );
};

import React, { useMemo } from 'react';
import { Type } from '@ui/Type';
import { ComboboxWithTriangleButton } from '@ui/SimpleCombobox';
import { useGetMessageTemplateLanguages } from '@pages/BotPage/MessagesTemplatesTab/data/useGetMessageTemplateLanguages';
import {
  MessagesTemplateFields,
  useExtendedFormAction,
} from '../../form/ExtendFormActionsContext';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './common.css';
import { logEvent } from '../../utils';

export const LanguageDropdown = () => {
  const { t } = useSafeTranslation();
  const { data } = useGetMessageTemplateLanguages();
  const {
    values,
    errors,
    touched,
    setLanguage,
    saveForm,
    formDisabled,
    setFieldTouched,
  } = useExtendedFormAction();

  const languageItems = useMemo(
    () =>
      (data?.messageTemplateLanguages || []).map((language) => ({
        id: language.id,
        title: language.display_name,
      })),
    [data],
  );

  const selectedLanguageItem = useMemo(
    () => languageItems.find(({ id }) => id === values.language),
    [languageItems, values.language],
  );

  return (
    <ComboboxWithTriangleButton
      icon="chevronDown"
      disabled={formDisabled}
      onChange={(item) => {
        logEvent({ action: 'language item select' });
        saveForm(setLanguage(item.id));
      }}
      onBlur={() => setFieldTouched(MessagesTemplateFields.language, true)}
      items={languageItems}
      intent={touched.language && errors.language ? 'error' : 'secondary'}
      selectedItem={selectedLanguageItem}
      className={css.dropdownButton}
      menuBoxClassName={css.menuBox}
      buttonChildren={() => (
        <Type size="15px">
          {selectedLanguageItem?.title ||
            t('pages.MessagesTemplates.settings.chooseLanguage')}
        </Type>
      )}
      data-testid="messages-template-header__language-picker"
    />
  );
};

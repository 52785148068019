import React from 'react';
import { Callout } from '@ui/Callout';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import * as css from './NoPhonesCallout.css';

interface NoPhonesCalloutProps {}

export const NoPhonesCallout: React.FC<NoPhonesCalloutProps> = () => {
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();

  return (
    <Callout
      iconView={<Icon icon="phone" color="accent3Orange" />}
      type="warning"
      text={
        <Type color="accent3Text" weight="medium">
          {t('pages.BotPage.HomeTab.Whatsapp.noPhonesCallout')}
        </Type>
      }
      className={css.callout}
      flexDirection="row"
      gapFactor={2}
      alignItems={isSmallScreenSize ? 'center' : 'flex-start'}
      data-testid="whatsapp-connect-phone-callout"
    />
  );
};

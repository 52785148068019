import { Modal } from '@services/index';
import React from 'react';
import { WhatsappBuyPhoneDialog } from './WhatsappBuyPhoneDialog';

export interface ShowWhatsappBuyPhoneDialogArgs {
  onWhatsappLoginRequest(): Promise<unknown>;
  onDismiss?: VoidFunction;
}

export const showWhatsappBuyPhoneDialog = ({
  onWhatsappLoginRequest,
  onDismiss,
}: ShowWhatsappBuyPhoneDialogArgs) => {
  Modal.show(
    ({ close }) => (
      <WhatsappBuyPhoneDialog
        onDismiss={close}
        onWhatsappLoginRequest={async () => {
          await onWhatsappLoginRequest();
          close();
        }}
      />
    ),
    {
      mobileAdaptive: true,
    },
  )?.onClose(() => {
    onDismiss?.();
  });
};

import React from 'react';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Spacer } from '@ui/Spacer';
import { useWelcomeMessage } from '@utils/Data/Ai/PredefinedIntents';
import { useCurrentBotId } from '@utils/Routing';
import { ACTION_FIELD_BY_PLATFORM } from '@utils/Data/Ai/consts';
import { KeywordsPageBlockBox } from '../common/KeywordsPageBlockBox';
import { AiIntentActionItemType, Platform } from '@globals';
import { Respond } from '../common/Respond';
import { RespondLoader } from '../common/RespondLoader';
import { useBlocksSelectorData } from '@components/BlocksSelector2';
import { Tooltip2 } from '@ui/Tooltip2';
import { Icon } from '@ui/Icon';
import * as css from './WelcomeMessage.css';
import { isMobileDevice } from 'cf-common/src/utils/isMobileDevice';

interface WelcomeMessageParams {
  platform: Platform;
}

export const WelcomeMessage: React.FC<WelcomeMessageParams> = ({
  platform,
}) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId();
  const {
    aiWelcomeMessageIntent,
    updateWelcomeMessage,
    loading: welcomeMessageLoading,
  } = useWelcomeMessage(botId);
  const { loading: blocksSelectorDataLoading } = useBlocksSelectorData();

  const welcomMessage =
    aiWelcomeMessageIntent?.[ACTION_FIELD_BY_PLATFORM[platform]]?.items[0];

  const type = welcomMessage?.item_type || AiIntentActionItemType.text;
  const text = welcomMessage?.text || '';
  const blocks = welcomMessage?.blocks || [];
  const loading = blocksSelectorDataLoading || welcomeMessageLoading;

  return (
    <KeywordsPageBlockBox>
      <Flex justifyContent="space-between" alignItems="center">
        <Type size="24px" weight="semibold">
          {t('pages.Keywords.WelcomeMessage.title')}
        </Type>
        <Tooltip2
          placement={isMobileDevice() ? 'top' : 'right'}
          content={t('pages.Keywords.WelcomeMessage.infoTooltip')}
          data-testid="keywords__welcome-message__info-tooltip"
        >
          {(ref, bind) => (
            <Icon
              size="20px"
              className={css.infoIcon}
              icon="question"
              ref={ref}
              {...bind}
            />
          )}
        </Tooltip2>
      </Flex>
      <Spacer factor={6} />
      {loading ? (
        <RespondLoader className={css.respond} />
      ) : (
        <Respond
          eventLabel="welcome message"
          data-testid="keywords__welcome-message"
          className={css.respond}
          platform={platform}
          type={type}
          text={text}
          blocks={blocks}
          onTypeChange={(updatedType) => {
            updateWelcomeMessage({
              actionField: ACTION_FIELD_BY_PLATFORM[platform],
              type: updatedType,
              blocks,
              text,
            });
          }}
          onTextChange={(value) => {
            updateWelcomeMessage({
              blocks,
              text: value,
              type,
              actionField: ACTION_FIELD_BY_PLATFORM[platform],
            });
          }}
          onBlocksChange={(updatedBlocks) => {
            updateWelcomeMessage({
              blocks: updatedBlocks,
              text,
              type,
              actionField: ACTION_FIELD_BY_PLATFORM[platform],
            });
          }}
        />
      )}
    </KeywordsPageBlockBox>
  );
};

import { useMemo } from 'react';

import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import {
  updateContactTags,
  updateContactTagsVariables,
} from './@types/updateContactTags';
import { BOT_TAGS_QUERY, BotTag } from './useBotTags';
import { useCurrentBotId, useCurrentContactId } from '@utils/Routing';
import { createBotTag, createBotTagVariables } from './@types/createBotTag';
import nanoid from 'nanoid';

export const CREATE_BOT_TAG_MUTATION = gql`
  mutation createBotTag($botId: ID!, $tag: BotTagInput!, $contactId: ID!) {
    createTag(botId: $botId, tag: $tag, contactId: $contactId) {
      id
      contact(id: $contactId) {
        id
        tags {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_CONTACT_TAGS_MUTATION = gql`
  mutation updateContactTags($botId: ID!, $contactId: ID!, $tags: [ID!]!) {
    updateContactTags(botId: $botId, contactId: $contactId, tags: $tags) {
      id
      tags {
        id
        name
      }
    }
  }
`;

export const useUpdateBotTags = (allContactTags: BotTag[]) => {
  const contactTags = allContactTags;
  const botId = useCurrentBotId();
  const contactId = useCurrentContactId();
  const [updateContactTagMutation] = useMutation<
    updateContactTags,
    updateContactTagsVariables
  >(UPDATE_CONTACT_TAGS_MUTATION);
  const [createTagMutate] = useMutation<createBotTag, createBotTagVariables>(
    CREATE_BOT_TAG_MUTATION,
    {
      refetchQueries: [{ query: BOT_TAGS_QUERY, variables: { botId: botId! } }],
    },
  );

  const deleteTag = useMemo(() => {
    return (id: string) => {
      const tags = contactTags.filter((t) => t.id !== id);

      return updateContactTagMutation({
        variables: {
          tags: tags.map((t) => t.id),
          botId: botId!,
          contactId: contactId!,
        },
        optimisticResponse: {
          updateContactTags: {
            __typename: 'ContactProfile',
            id: contactId!,
            tags: tags.map((t) => ({ ...t, __typename: 'BotTag' })),
          },
        },
      });
    };
  }, [botId, contactId, contactTags, updateContactTagMutation]);

  const addTag = useMemo(() => {
    return (tag: { id: string; name: string }) => {
      const tags = [...contactTags];

      if (!tags.find((t) => t.id === tag.id)) {
        tags.push(tag);
      }

      return updateContactTagMutation({
        variables: {
          tags: tags.map((t) => t.id),
          botId: botId!,
          contactId: contactId!,
        },
        optimisticResponse: {
          updateContactTags: {
            __typename: 'ContactProfile',
            id: contactId!,
            tags: tags.map((t) => ({ ...t, __typename: 'BotTag' })),
          },
        },
      });
    };
  }, [botId, contactId, contactTags, updateContactTagMutation]);

  const createTag = useMemo(() => {
    return async (
      tag: { name: string },
      optimistic?: { botTags: BotTag[]; contactTags: BotTag[] },
    ) => {
      let optimisticResponse: createBotTag | undefined;
      if (optimistic) {
        optimisticResponse = {
          createTag: {
            __typename: 'Bot',
            id: botId!,
            contact: {
              __typename: 'ContactProfile',
              id: contactId!,
              tags: [...optimistic.contactTags, { ...tag, id: nanoid() }].map(
                (t) => ({
                  ...t,
                  __typename: 'BotTag',
                }),
              ),
            },
          },
        };
      }

      return createTagMutate({
        variables: {
          botId: botId!,
          tag,
          contactId: contactId!,
        },
        optimisticResponse,
      });
    };
  }, [botId, contactId, createTagMutate]);

  return {
    deleteTag,
    addTag,
    createTag,
  };
};

import React from 'react';
import { MoreMenuItem } from '@ui/MoreMenu';
import { IconButton } from '@ui/IconButton';
import { Modal } from '@services/index';
import { DefaultDialog } from '@ui/Dialog';
import { Button } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import * as css from './MobileMoreButton.css';

interface MobileMoreButtonProps {
  buttons: MoreMenuItem[];
}

export const MobileMoreButton: React.FC<MobileMoreButtonProps> = ({
  buttons,
}) => {
  const showModal = () =>
    Modal.show(
      ({ close }) => (
        <DefaultDialog onDismiss={close} mobileAdaptive>
          <Flex flexDirection="column" gapFactor={3}>
            {buttons.map((button) => (
              <Button
                key={button.title}
                intent="text"
                className={css.textButton}
                onClick={() => {
                  button.onClick();
                  close();
                }}
              >
                {button.title}
              </Button>
            ))}
          </Flex>
          <Spacer factor={12} />
        </DefaultDialog>
      ),
      {
        mobileAdaptive: true,
        mobileProps: {
          fitHeight: true,
        },
      },
    );

  return (
    <IconButton
      icon="more"
      className={css.moreButton}
      data-testid="whatsapp-mobile-more-menu"
      onClick={showModal}
    />
  );
};

import React from 'react';
import { Flex } from '@ui/Flex';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { Spacer } from '@ui/Spacer';

export const FlowListLoader: React.FC = () => {
  const longLoader = (
    <div>
      <LoadingPlaceholder
        style={{ width: '250px', height: '12px' }}
        color="grey"
      />
      <Spacer factor={5} />
    </div>
  );

  const shortLoader = (
    <div>
      <LoadingPlaceholder
        style={{ width: '200px', height: '12px' }}
        color="grey"
      />
      <Spacer factor={5} />
    </div>
  );

  const searchLoader = (
    <div>
      <LoadingPlaceholder
        style={{ width: '278px', height: '36px' }}
        color="grey"
      />
      <Spacer factor={5} />
    </div>
  );

  const platformLoader = (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <LoadingPlaceholder
          style={{ width: '100px', height: '20px' }}
          color="grey"
        />
        <LoadingPlaceholder
          style={{ width: '24px', height: '24px' }}
          color="grey"
        />
      </Flex>
      <Spacer factor={5} />
    </>
  );

  return (
    <div
      style={{
        width: '374px',
        padding: '24px',
        boxSizing: 'border-box',
        opacity: 0.6,
      }}
    >
      {platformLoader}
      {searchLoader}
      {longLoader}
      {shortLoader}
      {longLoader}
      {shortLoader}
      {longLoader}
      {shortLoader}
      {longLoader}
      {shortLoader}
      {longLoader}
      {shortLoader}
    </div>
  );
};

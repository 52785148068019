import React, { useMemo } from 'react';
import {
  ManageUsersDialog,
  ManageUsersDialogProps,
} from '@components/ManageUsersDialog/ManageUsersDialog';
import { useAdminId } from '@utils/Admin/useAdminId';
import { useWorkspaceUsersAndInvites } from './useWorkspaceUsersAndInvites';
import { InviteWorkspaceUserListItem } from './InviteWorkspaceUserListItem';
import { WorkspaceUserListItem } from './WorkspaceUserListItem/WorkspaceUserListItem';
import { Modal } from '@services/index';
import { InviteWorkspaceAdminDialog } from './InviteWorkspaceAdminDialog';
import { CenteredLoader } from '@ui/Loader';

interface WorkspaceManageUsersModalProps
  extends Pick<ManageUsersDialogProps, 'onClose' | 'title'> {
  workspaceId: string;
}

export const WorkspaceManageUsersModal: React.FC<WorkspaceManageUsersModalProps> =
  ({ workspaceId, title, onClose, ...props }) => {
    const { id: adminId } = useAdminId();
    const { admins, invites, loading } =
      useWorkspaceUsersAndInvites(workspaceId);
    const showInviteAdminPopup = () => {
      Modal.show(
        ({ close }) => (
          <InviteWorkspaceAdminDialog
            title={title}
            workspaceId={workspaceId}
            onClose={close}
          />
        ),
        { mobileAdaptive: true },
      );
    };

    const users = useMemo(() => {
      const items: React.ReactNode[] = [];

      if (loading) return [<CenteredLoader key="loader" />];

      if (invites) {
        items.push(
          ...invites.map((invite) => (
            <InviteWorkspaceUserListItem
              {...invite}
              key={invite.id}
              workspaceId={workspaceId}
            />
          )),
        );
      }

      if (admins) {
        items.push(
          ...admins
            .filter(({ roleId }) => !!roleId)
            .map((admin) => (
              <WorkspaceUserListItem
                {...admin}
                key={admin.id}
                workspaceId={workspaceId}
                workspaceTitle={title}
                adminId={adminId}
              />
            )),
        );
      }

      return items;
    }, [admins, invites, workspaceId, adminId, title, loading]);

    const handleInviteClick = () => {
      onClose();
      showInviteAdminPopup();
    };

    return (
      <ManageUsersDialog
        {...props}
        onClose={onClose}
        users={users}
        title={title}
        onInviteClick={handleInviteClick}
      />
    );
  };

import React from 'react';
import { ChargeDisplayType } from '@globals';
import { ExtraPayment } from './ExtraPayment';
import { PausePlan } from './PausePlan';
import { PlanPrepayment } from './PlanPrepayment';
import { PrepaymentDelayDebt } from './PrepaymentDelayDebt';
import { UpgradePlan } from './UpgradePlan';
import { PartType, PaymentEntityType } from '../types';
import { TitleProps } from './Primitives';

export interface SimplePaymentFactoryProps extends TitleProps {
  paymentEntityType: PaymentEntityType;
  part: PartType;
}

export const SimplePaymentFactory: React.FC<SimplePaymentFactoryProps> = ({
  part,
  paymentEntityType,
  ...props
}) => {
  return {
    [ChargeDisplayType.adjustment_fee]: (
      <ExtraPayment
        payment={part.value}
        pricePerPayment={part.pricePerEntity}
        paymentEntityType={paymentEntityType}
        {...props}
      />
    ),
    [ChargeDisplayType.pause]: <PausePlan price={part.price} {...props} />,
    [ChargeDisplayType.prepayment]: (
      <PlanPrepayment
        paymentEntityType={paymentEntityType}
        value={part.value}
        {...props}
      />
    ),
    [ChargeDisplayType.prepayment_delayed_debt]: (
      <PrepaymentDelayDebt
        firstDate={part.from}
        lastDate={part.to}
        {...props}
      />
    ),
    [ChargeDisplayType.tier_upgrade]: (
      <UpgradePlan price={part.price} {...props} />
    ),
  }[part.type];
};

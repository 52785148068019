import { sendEvent } from '@utils/Analytics';

export const logEvent = (
  event: Omit<PureAnalyticsEvent, 'category'>,
  immediate?: boolean,
) => {
  sendEvent(
    {
      category: 'whatsapp templates page',
      ...event,
    },
    immediate,
  );
};

import Maybe from 'graphql/tsutils/Maybe';
import { ChargeDisplayType, ChargingEntityType } from '@globals';
import { BillingPaymentHistoryQuery_premiumPricingPaymentList } from '@utils/Data/Pricing/@types/BillingPaymentHistoryQuery';

export type PaymentHistoryApiItem =
  BillingPaymentHistoryQuery_premiumPricingPaymentList;

export type UnixTime = number;

export enum PaymentHistoryType {
  empty,
  complex,
  default,
}

export enum PaymentEntityType {
  dialogs = 'dialogs',
  users = 'users',
  broadcasts = 'broadcasts',
}

export interface PartType {
  type: ChargeDisplayType;
  price: string;
  value: number;
  from: Maybe<UnixTime>;
  to: Maybe<UnixTime>;
  pricePerEntity: string;
}

export interface PaymentResult {
  id: Maybe<string>;
  failed: Maybe<boolean>;
}

export interface PaymentHistoryItem {
  id: string;
  type: PaymentHistoryType;
  parts: Array<PartType>;
  date: string;
  price: string;
  paymentEntityType: PaymentEntityType;
  paymentResult: Maybe<PaymentResult>;
}

// API types

export interface PaymentPart {
  amount: number;
  limit: number;
  pricePerEntity: number;
  type: ChargeDisplayType;
  from: string | null;
  to: string | null;
}

export interface StripeResult {
  id: string | null;
  failed: boolean | null;
}

export interface Payment {
  id: string;
  parts: Array<PaymentPart>;
  currency: string;
  lastAttemptDate: string;
  lastAttemptStripeResult: StripeResult | null;
  amount: number;
  chargingEntityType: ChargingEntityType | null;
}

// API types

import React, { useCallback, useEffect } from 'react';
import { ApolloProvider, useQuery } from 'react-apollo';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { equals } from 'ramda';

import { sendEvent } from '@utils/Analytics';
import { isBotPro } from '@utils/Pro';
import { BotTabs } from '@utils/Routing';

import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Page } from '@ui/Page';
import { Spacer } from '@ui/Spacer';
import { ScrollBoxWithShadow } from '@ui/ScrollBox/ScrollBoxWithShadow';
import { Type } from '@ui/Type';
import { ReactComponent as GlobalLoaderIcon } from '@ui/_common/images/global_loader.svg';

import { PageHeader } from '@components/PageHeader';
import { LogoSize } from '@components/PageHeader/WhiteLabelLogo';

import client from '../../common/services/ApolloService';
import { BotInfo } from './Bot';
import * as Queries from './queries';

import { DeepLinksMode, DeepLinksQueryParam } from './types';
import {
  DeepLinksBotListQuery,
  DeepLinksBotListQuery_bots,
} from './@types/DeepLinksBotListQuery';

import * as css from './DeepLinks.css';
import { RoleIds } from '../../components/settings/admins/RolesList';

function isBotTab(tab: string): tab is BotTabs {
  return tab in BotTabs || Object.values(BotTabs).some(equals(tab));
}

const eventLabelModes: Partial<Record<DeepLinksMode, string>> = {
  [DeepLinksMode.templatesModal]: 'template',
  [DeepLinksMode.entryPointsModal]: 'add entrypoint',
  [DeepLinksMode.upgrade]: 'upgrade',
  [DeepLinksMode.enableEntryPoint]: 'enable entrypoint',
  [DeepLinksMode.testThis]: 'test flow',
};
const getEventLabelByMode = (mode: DeepLinksMode) =>
  eventLabelModes[mode] ?? '';

const DeepLinks: React.FC = () => {
  const { t } = useSafeTranslation();

  const history = useHistory();
  const routeMatch = useRouteMatch<{ mode: DeepLinksMode }>('/go/:mode');
  const mode = routeMatch?.params.mode ?? DeepLinksMode.templatesModal;

  const { data, loading: botListLoading } = useQuery<DeepLinksBotListQuery>(
    Queries.DEEP_LINKS_BOT_LIST_QUERY,
    {
      notifyOnNetworkStatusChange: true,
    },
  );
  const isAdminOrEditor = (bot: DeepLinksBotListQuery_bots) =>
    data?.me.roles?.some(
      (role) =>
        role.bot_id === bot.id &&
        [RoleIds.admin, RoleIds.editor].includes(role.role_id as RoleIds),
    );
  const bots = (data?.bots ?? []).filter(
    (bot) =>
      isAdminOrEditor(bot) &&
      (!isBotPro(bot) || mode !== DeepLinksMode.upgrade),
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  queryParams.set(DeepLinksQueryParam.mode, mode);

  const goToTab = useCallback(
    (botId: string, tab: BotTabs, params: URLSearchParams) => {
      sendEvent({
        category: 'deeplink',
        action: 'go to bot',
        label: getEventLabelByMode(mode),
        propertyBag: { botId, isBotSingle: data?.bots.length === 1, tab },
      });

      history.push(`/bot/${botId}/${tab}?${params}`);
    },
    [data, history, mode],
  );

  const handleRedirect = useCallback(
    (botId: string) => {
      if (mode === DeepLinksMode.upgrade) {
        goToTab(botId, BotTabs.configure, queryParams);
      } else if (mode === DeepLinksMode.plans) {
        goToTab(botId, BotTabs.plans, queryParams);
      } else if (mode === DeepLinksMode.referral) {
        goToTab(botId, BotTabs.referral, queryParams);
      } else if (isBotTab(mode)) {
        queryParams.delete(DeepLinksQueryParam.mode);
        goToTab(botId, BotTabs[mode] ?? mode, queryParams);
      } else {
        goToTab(botId, BotTabs.flows, queryParams);
      }
    },
    [goToTab, mode, queryParams],
  );

  useEffect(() => {
    if (!botListLoading && data) {
      if (bots.length === 1) {
        handleRedirect(bots[0].id);
      }
    }
  }, [botListLoading, bots, data, handleRedirect]);

  return botListLoading ? (
    <div className="global-loader">
      <GlobalLoaderIcon className="global-loader-cf" />
    </div>
  ) : (
    <Page>
      <PageHeader logoSize={LogoSize.big} hideNav />
      <Flex
        className={css.pageContainer}
        justifyContent="center"
        alignItems="center"
      >
        <div
          className={css.botChooseContainer}
          data-testid="choose-bot__dialog"
        >
          <div className={css.infoContainer}>
            <Type as="h1" size="24px">
              {t('pages.DeepLinks.header')}
            </Type>
          </div>
          {bots.length === 0 && <Redirect to="/" />}
          <ScrollBoxWithShadow className={css.scrollBox} fullHeight>
            {bots.length > 0 &&
              bots.map((bot) => (
                <React.Fragment key={bot.id}>
                  <Flex
                    justifyContent="space-between"
                    data-testid="choose-bot__bot-item"
                  >
                    <BotInfo title={bot?.title} status={bot?.status} />
                    <Button
                      intent="primary"
                      onClick={() => handleRedirect(bot.id)}
                      data-testid="choose-bot__go-to-bot-button"
                    >
                      {t('pages.DeepLinks.goToBot')}
                    </Button>
                  </Flex>
                  <Spacer factor={5} />
                </React.Fragment>
              ))}
          </ScrollBoxWithShadow>
        </div>
      </Flex>
    </Page>
  );
};

export const DeepLinksPage: React.FC = () => (
  <ApolloProvider client={client}>
    <DeepLinks />
  </ApolloProvider>
);

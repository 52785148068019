import React from 'react';
import cn from 'classnames';
import { PluginControlLabel } from '@components/Plugins/common/PluginControlLabel';
import { TimePicker, TimePickerProps } from '@ui/DatePicker';
import { Disableable, Errorable } from '../types';
import * as commonCSS from './common.css';
import * as css from './TimeControl.css';
import { useSafeTranslation } from '@utils/useSafeTranslation';

export interface TimeControlProps extends Disableable, Errorable {
  value: string;
  onChange: TimePickerProps['onChange'];
}

export const TimeControl: React.FC<TimeControlProps> = ({
  value,
  error,
  disabled,
  onChange,
}) => {
  const { t } = useSafeTranslation();

  return (
    <PluginControlLabel
      label={t('pages.BotPage.ReengageWhatsappTab.panel.time.label')}
      light
    >
      {() => (
        <TimePicker
          error={error}
          disabled={disabled}
          defaultValue={value}
          className={cn(commonCSS.control, css.timePicker)}
          onChange={onChange}
          data-testid="reengage-editor__time-picker"
        />
      )}
    </PluginControlLabel>
  );
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@ui/Button';
import Maybe from 'graphql/tsutils/Maybe';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import { Flex } from '@ui/Flex';
import css from '../PlanBlock.css';

interface SelectPlanButtonProps {
  trialExpired: Maybe<boolean>;
}

export const SelectPlanButton: React.FC<SelectPlanButtonProps> = ({
  trialExpired,
}) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId();
  const history = useHistory();

  return (
    <Flex fullWidth>
      <Button
        className={css.selectPlanAction}
        intent={trialExpired ? 'red' : 'primary'}
        onClick={() => history.push(getTabLink(BotTabs.plans, botId))}
      >
        {t('pages.Pricing.selectPlan')}
      </Button>
    </Flex>
  );
};

import React from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { Type, TypeProps } from '@ui/Type';
import { LONG_DASH } from '@ui/Statistics/consts';

const nf = new Intl.NumberFormat('en-US');

interface FormattedNumberProps
  extends Omit<TypeProps<HTMLSpanElement>, 'size'> {
  number: Maybe<number>;
}

export const FormattedNumber: React.FC<FormattedNumberProps> = ({
  number,
  ...typeProps
}) =>
  typeof number === 'number' ? (
    <Type as="span" weight="medium" {...typeProps} size="24px">
      {nf.format(number)}
    </Type>
  ) : (
    <Type size="24px" weight="medium">
      {LONG_DASH}
    </Type>
  );

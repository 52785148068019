import { Platform } from '@globals';
import { LSKey, useLocalStorageForId } from '@utils/LocalStorage';
import { usePrevious } from 'cf-common/src/utils/hooks';
import { useEffect } from 'react';
import { BotTabs, getTabLink } from '@utils/Routing';
import { useHistory } from 'react-router-dom';
import { useWhatsappEnabled } from '@utils/Whatsapp';

export const useKeywordsPlatformRedirect = (
  platform: Platform | undefined,
  botId: string | undefined,
) => {
  const history = useHistory();
  const { isWhatsappEnabled, loading: whatsappStatusLoading } =
    useWhatsappEnabled();
  const [savedPlatform = Platform.facebook, setSavedPlatform] =
    useLocalStorageForId<Platform>(LSKey.keywordsTabPlatform, botId);

  const prevPlatform = usePrevious(platform);

  useEffect(() => {
    if (isWhatsappEnabled || whatsappStatusLoading) {
      return;
    }

    if (platform && platform !== prevPlatform) {
      setSavedPlatform(platform);
    }
  }, [
    platform,
    prevPlatform,
    isWhatsappEnabled,
    whatsappStatusLoading,
    setSavedPlatform,
  ]);

  useEffect(() => {
    if (isWhatsappEnabled || whatsappStatusLoading) {
      return;
    }

    if (!platform && savedPlatform) {
      history.push(
        getTabLink(BotTabs.keywords, botId, { platform: savedPlatform }),
      );
    }
  }, [
    botId,
    history,
    platform,
    savedPlatform,
    isWhatsappEnabled,
    whatsappStatusLoading,
  ]);

  useEffect(() => {
    if (isWhatsappEnabled && platform !== Platform.whatsapp) {
      history.push(
        getTabLink(BotTabs.keywords, botId, { platform: Platform.whatsapp }),
      );
    }
  }, [isWhatsappEnabled, whatsappStatusLoading, platform, history, botId]);
};

import React from 'react';
import cn from 'classnames';
import { useBlocksSelectorData } from '@components/BlocksSelector2';
import { useCurrentBotId } from '@utils/Routing';
import { InputLoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { useAutomateEnabled } from '@utils/Data/Admin/Automate';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Platform } from '@globals';
import * as css from './BlocksSelectorWithData.css';
import { BlockGroupsQuery_bot_blocksGroups_blocks } from '../../../../../AiSetupPage/@types/BlockGroupsQuery';
import {
  BlocksSelector,
  BlocksSelectorProps,
} from '../../../../../AiSetupPage/BlocksSelector';
import { BlockWithPermissions } from '../../../../../AiSetupPage';

interface BlocksSelectorWithDataProps
  extends Omit<
    BlocksSelectorProps,
    'onChange' | 'blocksSelected' | 'blocksGroups' | 'currentBotId'
  > {
  selectedBlocks: BlockGroupsQuery_bot_blocksGroups_blocks[];
  onBlocksSelected: (
    blocks: BlockGroupsQuery_bot_blocksGroups_blocks[],
  ) => void;
  autoFocus: boolean;
  error?: boolean;
  platform: Platform;
  style?: React.CSSProperties;
}

export const BlocksSelectorWithData: React.FC<BlocksSelectorWithDataProps> = ({
  autoFocus,
  onBlocksSelected,
  selectedBlocks,
  style,
  rightElement,
  platform,
  error,
  onBlur,
  ...props
}) => {
  const botId = useCurrentBotId();
  const { isAutomateEnabled } = useAutomateEnabled();
  const { t } = useSafeTranslation();
  const { loading, blocksGroups, flowsGroups } = useBlocksSelectorData();

  return loading || !botId ? (
    <InputLoadingPlaceholder width={36} height={270} style={style} />
  ) : (
    <div className={cn(css.blocksSelectorBox, css.input)} style={style}>
      <BlocksSelector
        blocksSelected={selectedBlocks}
        blocksGroups={blocksGroups as BlockWithPermissions[]}
        flowsGroups={flowsGroups}
        onChange={onBlocksSelected}
        currentBotId={botId}
        autofocus={autoFocus}
        platform={platform}
        rightElement={rightElement}
        error={error}
        onBlur={onBlur}
        useButtonForMobile
        placeholder={`${t(
          'pages.Keywords.FrequentUnrecognizedMessages.item.flowResponse.select',
        )} ${
          isAutomateEnabled && platform !== Platform.instagram
            ? `${t(
                'pages.Keywords.FrequentUnrecognizedMessages.item.flowResponse.blockOr',
              )} `
            : ''
        }${t(
          'pages.Keywords.FrequentUnrecognizedMessages.item.flowResponse.flow',
        )}`}
        hideTips
        {...props}
      />
    </div>
  );
};

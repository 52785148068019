import React from 'react';
import { Flex } from '@ui/Flex';
import { ReactComponent as Line } from './line.svg';
import { TopView } from '../TopView';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Anchor } from '@ui/Links';
import css from './EmptyState.css';
import { useCreateWhatsappTemplateInCfEnable } from '@utils/Whatsapp/hooks/useCreateWhatsappTemplateInCfEnable';

interface EmptyStateProps {}

export const EmptyState: React.FC<EmptyStateProps> = () => {
  const { t } = useSafeTranslation();
  const { isCreateWhatsappTemplateInCfEnable } =
    useCreateWhatsappTemplateInCfEnable();

  return (
    <Flex gapFactor={3}>
      <TopView />
      <Flex flexDirection="column" gapFactor={4}>
        <Line />
        <Type size="24px" weight="semibold" align="center" className={css.text}>
          {isCreateWhatsappTemplateInCfEnable
            ? t('pages.BotPage.ReengageWhatsappTab.emptyState.remindCustomers')
            : t(
                'pages.BotPage.ReengageWhatsappTab.emptyState_old.remindCustomers',
              )}
        </Type>
        <Type align="center" className={css.text}>
          {isCreateWhatsappTemplateInCfEnable
            ? t('pages.BotPage.ReengageWhatsappTab.emptyState.createAReEngage')
            : t(
                'pages.BotPage.ReengageWhatsappTab.emptyState_old.createAReEngage',
              )}{' '}
          <Anchor
            intent="external"
            href="https://docs.chatfuel.com/en/articles/6975885-reengage-whatsapp-messages"
            target="_blank"
            hideArrow
          >
            {isCreateWhatsappTemplateInCfEnable
              ? t('pages.BotPage.ReengageWhatsappTab.emptyState.thisGuide')
              : t('pages.BotPage.ReengageWhatsappTab.emptyState_old.thisGuide')}
          </Anchor>
          .
        </Type>
      </Flex>
    </Flex>
  );
};

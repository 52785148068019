import { useUnpausePlan } from '@components/DialogsPricing/hooks';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { Type } from '@ui/Type';
import {
  usePausePricing,
  useDialoguesPricing,
} from '@utils/Data/Pricing/hooks';
import { useCurrentBotId } from '@utils/Routing';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import React from 'react';

interface PauseFooterProps {}

export const PauseFooter: React.FC<PauseFooterProps> = () => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  const { pricing } = useDialoguesPricing();
  const { pausePrice } = usePausePricing(botId, pricing?.currency);
  const { unpausePlan } = useUnpausePlan(botId);

  return (
    <Flex justifyContent="space-between">
      <Flex flexDirection="column">
        {pausePrice ? (
          <Type size="15px" weight="semibold">
            {pausePrice}
          </Type>
        ) : (
          <LoadingPlaceholder />
        )}
        <Type size="15px" color="greyDark">
          {t('pages.Billing.pro.monthlyPayment')}
        </Type>
      </Flex>
      <Button intent="red" onClick={unpausePlan}>
        {t('pages.Billing.pro.unpause')}
      </Button>
    </Flex>
  );
};

import React from 'react';
import { Flex } from '@ui/Flex';
import { Conversations } from './Conversations';
import { BotSubscribers } from './BotSubscribers';
import { Teammates } from './Teammates';
import * as css from './BotStatistic.css';

export const BotStatistic = () => (
  <Flex className={css.wrapper}>
    <Flex className={css.item}>
      <Conversations />
    </Flex>

    <Flex className={css.item}>
      <BotSubscribers />
    </Flex>

    <Flex className={css.item}>
      <Teammates />
    </Flex>
  </Flex>
);

import React from 'react';
import { UserListItem } from '@components/ManageUsersDialog/UserListItem/UserListItem';
import { Type } from '@ui/Type';
import { ROLES_AND_INVITES_QUERY_bot_invites } from '../../../../../../components/settings/admins/@types/ROLES_AND_INVITES_QUERY';
import { RoleIds } from '../../../../../../components/settings/admins/RolesList';
import { getRoleTexts } from '../../getRoleTexts';
import { isSomeEnum } from '@utils/isSomeEnum';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { timeFormat } from '../../../../../../components/settings/admins/AdminsList/AdminsList';
import { useDeleteInvite } from '@utils/Data/Invite';

type InviteUserListItemProps = {
  botId: string;
} & ROLES_AND_INVITES_QUERY_bot_invites;

export const InviteUserListItem: React.FC<InviteUserListItemProps> = ({
  id,
  roleId,
  created,
  botId,
  login,
}) => {
  const [deleteInvite, { loading: inviteDeleting }] = useDeleteInvite({
    botId,
  });
  const { t } = useSafeTranslation();

  return (
    <UserListItem
      user={{
        id,
        name: (
          <Type size="15px" color="greyDark">
            {login
              ? t('components.settings.adminList.loginPending', {
                  login,
                })
              : t('components.settings.adminList.newPending', {
                  role: isSomeEnum(RoleIds, roleId)
                    ? getRoleTexts(roleId).title
                    : '',
                })}
          </Type>
        ),
        subtitle: created
          ? t('components.settings.adminList.addedTime', {
              time: timeFormat(created),
            })
          : '',
      }}
      onRemove={() =>
        deleteInvite({
          variables: { inviteId: id },
        })
      }
      pending={inviteDeleting}
    />
  );
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAiIntents } from '@utils/Data/Ai/Groups';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { removeEntity } from '@components/FlowBuilder/components';
import { KeywordsGroupIntent } from './components/KeywordsGroupIntent';
import { isAiIntentsEq } from '@utils/Data/Ai/isAiIntentsEq';
import {
  createDefaultIntent,
  intentsFilter,
} from '@utils/Data/Ai/Groups/helpers';
import { CenteredLoader } from '@ui/Loader';
import * as css from './KeywordsGroups.css';
import { ButtonLoadingPlaceholder } from '@ui/LoadingPlaceholder/ButtonLoadingPlaceholder';
import { Tooltip2 } from '@ui/Tooltip2';
import { useFrequentUnrecognizedMessages } from '../FrequentUnrecognizedMessages/hooks/useFrequentUnrecognizedMessages';
import { AiGroupSelector } from '../AiGroupSelector';
import {
  BotTabs,
  getTabLink,
  useCurrentBotId,
  useKeywordsTabParams,
} from '@utils/Routing';
import { useAiGroups } from '../AiGroupSelector/useAiGroups';
import {
  KeywordsGroupsEmpty,
  KeywordsGroupsNotFound,
} from './components/KeywordsGroupsEmpty';
import { isMobileDevice } from 'cf-common/src/utils/isMobileDevice';
import { sendEvent } from '@utils/Analytics';
import { Pagination } from '@ui/Pagination';
import { Spacer } from '@ui/Spacer';
import { useHistory } from 'react-router-dom';
import { calcPagesQty, prepareItemsPage } from '@ui/Pagination/utils';
import { useActiveGroupIndex } from './useActiveGroupIndex';
import { Platform } from '@globals';

interface KeywordsGroupsProps {
  platform: Platform;
  intentsFilterTerm: string;
}

const INTENTS_PAGE_LIMIT = 8;

export const KeywordsGroups: React.FC<KeywordsGroupsProps> = ({
  platform,
  intentsFilterTerm,
}) => {
  const [newAiIntentIndex, setNewAiIntentIndex] = useState<number | null>(null);
  const { activeGroupIndex, setActiveGroupIndex, activeGroupChanged } =
    useActiveGroupIndex();
  const { page: currentPageIndex } = useKeywordsTabParams();
  const history = useHistory();
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId() || '';
  const { groups, cardId, loading: groupsLoading } = useAiGroups();
  const {
    aiIntents,
    updateAiIntentInCache,
    saveAiIntent,
    addAiIntent,
    removeAiIntent,
  } = useAiIntents(activeGroupIndex);

  const { loading: aiPhraseLoading } =
    useFrequentUnrecognizedMessages(platform);

  const loading = !aiIntents || aiPhraseLoading;

  const currentAiIntents = useMemo(
    () => aiIntents?.filter(intentsFilter(intentsFilterTerm, platform)),
    [aiIntents, intentsFilterTerm, platform],
  );

  const currentAiIntentsPagginate = useMemo(
    () =>
      currentAiIntents &&
      prepareItemsPage(currentAiIntents, currentPageIndex, INTENTS_PAGE_LIMIT),
    [currentAiIntents, currentPageIndex],
  );

  const isEmptyIntentsList = useMemo(
    () => !aiIntents?.filter(intentsFilter('', platform)).length,
    [aiIntents, platform],
  );

  const pageChange = useCallback(
    (pageIndex: number) => {
      sendEvent({
        category: 'keywords',
        label: 'keywords groups',
        action: 'pagination page change',
        propertyBag: {
          pageIndex,
        },
      });
      history.push(
        getTabLink(BotTabs.keywords, botId, {
          platform,
          page: pageIndex.toString(10), // гет параметры описаны как строки, к ним и приводим
        }),
      );
    },
    [botId, history, platform],
  );

  const pagesQty = calcPagesQty(
    currentAiIntents?.length || 0,
    INTENTS_PAGE_LIMIT,
  );

  useEffect(() => {
    if (activeGroupChanged || currentPageIndex < 1) {
      pageChange(1);
    } else if (currentAiIntents && currentPageIndex > pagesQty) {
      pageChange(pagesQty);
    }
  }, [
    currentAiIntents,
    currentPageIndex,
    pageChange,
    pagesQty,
    activeGroupChanged,
  ]);

  return (
    <div>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        className={css.head}
      >
        <div className={css.title}>
          <Type size="24px" weight="semibold" whiteSpace="normal">
            {t('pages.Keywords.KeywordGroups.title')}
          </Type>
          &nbsp;
          <div className={css.info}>
            <Tooltip2
              placement={isMobileDevice() ? 'top' : 'right'}
              content={t('pages.Keywords.KeywordGroups.infoTooltip')}
              data-testid="keywords__keywords-groups__info-tooltip"
            >
              {(ref, bind) => (
                <Icon
                  size="20px"
                  icon="question"
                  className={css.infoIcon}
                  ref={ref}
                  {...bind}
                />
              )}
            </Tooltip2>
          </div>
        </div>
        {loading ? (
          <ButtonLoadingPlaceholder fitToText className={css.addButtonLoader} />
        ) : currentAiIntents?.length ? (
          <Button
            icon={<Icon icon="plus" color="white" />}
            onClick={() => {
              history.push(
                getTabLink(BotTabs.keywords, botId, {
                  platform,
                }),
              );
              sendEvent({
                category: 'keywords',
                label: 'keywords groups',
                action: 'add keywords group click',
              });
              setTimeout(() => {
                // call after redirect to first page
                addAiIntent(platform);
                setNewAiIntentIndex(0);
              });
            }}
            data-testid="keywords__keywords-groups__add-keyword-group-button"
          >
            {t('pages.Keywords.KeywordGroups.addButton')}
          </Button>
        ) : null}
      </Flex>
      {!loading && !groupsLoading && (groups?.length || 0) > 1 && (
        <AiGroupSelector // Is legacy for old users. Show if user have more one Ai folder (group).
          activeGroupIndex={activeGroupIndex}
          onChange={setActiveGroupIndex}
          platform={platform}
          currentBotId={botId}
          groups={groups}
          cardId={cardId}
          intentsFilterTerm={intentsFilterTerm}
        />
      )}
      {loading && <CenteredLoader className={css.loader} />}
      {!loading &&
        currentAiIntentsPagginate?.map((intent, index) => {
          return (
            <KeywordsGroupIntent
              key={intent.id}
              isNewAiIntentInit={newAiIntentIndex === index}
              aiIntent={intent}
              platform={platform}
              onChange={(updatedKeywordsGroup) => {
                updateAiIntentInCache(updatedKeywordsGroup);
              }}
              onSaveRequest={(updatedKeywordsGroup) => {
                saveAiIntent(updatedKeywordsGroup);
                setNewAiIntentIndex(null);
              }}
              onDeleteRequest={() => {
                if (isAiIntentsEq(intent, createDefaultIntent(platform))) {
                  removeAiIntent(intent.id);
                  return;
                }
                removeEntity({
                  onSubmit: () => {
                    sendEvent({
                      category: 'keywords',
                      label: 'keywords groups',
                      action: 'keywords group delete confirm',
                    });
                    removeAiIntent(intent.id);
                  },
                  renderActionText: () =>
                    t('pages.Keywords.KeywordGroups.deleteDialog.actionText'),
                  renderHeading: () =>
                    t('pages.Keywords.KeywordGroups.deleteDialog.heading'),
                  renderNoteText: () =>
                    t('pages.Keywords.KeywordGroups.deleteDialog.noteText'),
                });
              }}
            />
          );
        })}
      {!loading && (currentAiIntents?.length || 0) > INTENTS_PAGE_LIMIT && (
        <>
          <Spacer factor={6} />
          <Pagination
            pagesQty={pagesQty}
            currentPageIndex={currentPageIndex}
            onPageChange={pageChange}
          />
        </>
      )}
      {!loading && isEmptyIntentsList && (
        <KeywordsGroupsEmpty
          onAddIntentRequest={() => {
            setNewAiIntentIndex(0);
            addAiIntent(platform);
          }}
        />
      )}
      {!loading && !isEmptyIntentsList && currentAiIntents?.length === 0 && (
        <KeywordsGroupsNotFound />
      )}
    </div>
  );
};

import React, { useMemo } from 'react';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './MessagesTemplatePreview.css';
import { useExtendedFormAction } from '../form/ExtendFormActionsContext';
import { Spacer } from '@ui/Spacer';
import { WhatsappTemplate } from '../../../../../LiveChat/components/Messages/Message/WhatsappTemplate';
import {
  ManagedWhatsappAttachmentType,
  MessagesTemplateAttributeExampleInput,
  MessagesTemplateHeaderInput,
  MessagesTemplateHeaderType,
  TemplateButtonType,
  WhatsappStatusEnum,
  WhatsappTemplateButtonType,
} from '@globals';
import Maybe from 'graphql/tsutils/Maybe';
import { ATTR_SIGN } from '@ui/TextWithAttributesEditor';
import { VisuallyDisabled } from '@ui/VisuallyDisabled';
import escapeRegExp from 'lodash-es/escapeRegExp';

const TEMPLATE_BUTTON_TO_WHATSAPP_TEMPLATE_BUTTON_TYPE: Record<
  TemplateButtonType,
  WhatsappTemplateButtonType | undefined
> = {
  [TemplateButtonType.phone]: WhatsappTemplateButtonType.PHONE_NUMBER,
  [TemplateButtonType.url]: WhatsappTemplateButtonType.URL,
  [TemplateButtonType.marketing_opt_out]:
    WhatsappTemplateButtonType.MARKETING_OPT_OUT,
  [TemplateButtonType.quick_reply]: undefined,
};

export const replaceAttributeExamples = (
  rawText: string,
  attributeExamples: Maybe<MessagesTemplateAttributeExampleInput[]>,
) => {
  if (!attributeExamples?.length) {
    return rawText;
  }
  let text = rawText;
  attributeExamples.forEach(({ name, value }) => {
    if (!value?.trim().length) {
      return;
    }
    text = text.replace(
      new RegExp(
        escapeRegExp(`${ATTR_SIGN.start}${name}${ATTR_SIGN.end}`),
        'ig',
      ),
      value,
    );
  });
  return text;
};

const getHeaderFieldByType = ({
  attachment,
  type,
  text,
  attribute_examples: attributeExamples,
}: MessagesTemplateHeaderInput) => {
  if (type === MessagesTemplateHeaderType.text && text?.trim().length) {
    return {
      header_text: replaceAttributeExamples(text || '', attributeExamples),
    };
  }
  if (attachment?.type === ManagedWhatsappAttachmentType.image) {
    return { header_image: attachment.url };
  }
  if (attachment?.type === ManagedWhatsappAttachmentType.video) {
    return { header_video: attachment.url };
  }
  if (attachment?.type === ManagedWhatsappAttachmentType.document) {
    return {
      header_document: {
        __typename: 'WhatsappTemplateMessageDocument' as const,
        link: attachment.url,
        name: attachment.filename,
      },
    };
  }
  return {};
};

export const MessagesTemplatePreview = () => {
  const { t } = useSafeTranslation();
  const { values, contentPartDisabled, hasOptOutButton, templateLanguage } =
    useExtendedFormAction();

  const { header, body, footer, sections } = values.content;
  const buttons = sections.flatMap(({ children }) => children);

  const isNotEmptyTemplate = useMemo(
    () =>
      Boolean(
        body.text?.trim().length ||
          footer.text?.trim().length ||
          Object.values(getHeaderFieldByType(header)).length,
      ),
    [body.text, footer.text, header],
  );

  const footerText =
    (hasOptOutButton ? templateLanguage?.opt_out_footer_text : footer.text) ||
    '';

  const config = useMemo(
    () => ({
      __typename: 'WhatsappTemplateMessage' as const,
      name: null,
      header_text: null,
      header_image: null,
      header_video: null,
      header_document: null,
      ...getHeaderFieldByType(header),
      body: isNotEmptyTemplate
        ? replaceAttributeExamples(body.text || '', body.attribute_examples)
        : t('pages.MessagesTemplates.preview.none'),
      footer: footerText,
      buttons: buttons
        .map(({ text, type }) => ({
          __typename: 'WhatsappTemplateButton' as const,
          text: text || '',
          type: TEMPLATE_BUTTON_TO_WHATSAPP_TEMPLATE_BUTTON_TYPE[type] || null,
          url: null,
        }))
        .filter(({ text }) => text),
    }),
    [
      body.attribute_examples,
      body.text,
      buttons,
      footerText,
      header,
      isNotEmptyTemplate,
      t,
    ],
  );

  return (
    <div className={css.wrapper}>
      <VisuallyDisabled disable={contentPartDisabled}>
        <Type as="h1" weight="medium">
          {t('pages.MessagesTemplates.preview.title')}
        </Type>
        <Spacer factor={6} />
        <WhatsappTemplate
          data-testid="messages-template-message__preview"
          type="incoming"
          status={WhatsappStatusEnum.delivered}
          date={0}
          config={config}
          className={css.preview}
          hideFooter
        />
      </VisuallyDisabled>
    </div>
  );
};

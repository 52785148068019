import cn from 'classnames';
import { Badge } from '@ui/Badge';
import { ButtonUnstyled } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { sendEvent } from '@utils/Analytics';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import React, { useCallback } from 'react';
import { CategoryIcon } from '@components/ItemsModal';
import { TemplateModalHandlers } from '../../../TemplatesModal';
import { Template, TemplateLevel } from '../../../types';
import * as commonCSS from '../TemplatesListModal.css';
import * as css from './TemplateItem.css';
import { ForBeginnersBadge } from '../../ForBeginnersBadge';

export interface TemplateItemProps
  extends Omit<TemplateModalHandlers, 'onCreateBlankFlow'> {
  template: Template;
  eventCategory?: string;
  className?: string;
  buttonClassName?: string;
  iconClassName?: string;
}

export const TemplateItem: React.FC<TemplateItemProps> = ({
  template,
  className,
  eventCategory,
  buttonClassName,
  iconClassName,
  onTemplateSelect,
}) => {
  const { isConnected } = usePageConnected();

  const onPreviewClick = useCallback(() => {
    sendEvent({
      category: eventCategory || 'templates first session',
      action: 'choose template',
      propertyBag: {
        templateid: template.id,
        templatename: template.title,
        category: template.category.toLowerCase(),
        'page connected': isConnected.toString(),
      },
    });
    onTemplateSelect(template);
  }, [template, onTemplateSelect, isConnected, eventCategory]);

  const { id, category, title, isNew, level, icon } = template;
  const Icon = icon;

  return (
    <figure className={cn(css.figure, className)}>
      <ButtonUnstyled
        data-testid={`tempalte_${id}`}
        className={cn(commonCSS.templateImage, buttonClassName)}
        onClick={onPreviewClick}
      >
        <Flex flexDirection="column">
          <Flex justifyContent="center" alignItems="center">
            <Icon className={iconClassName} />
          </Flex>

          {level === TemplateLevel.basic && <ForBeginnersBadge />}
        </Flex>
      </ButtonUnstyled>

      <Spacer factor={2} />
      <figcaption>
        <Flex>
          <CategoryIcon category={category} style={{ marginTop: 4 }} />
          <Spacer factor={1} horizontalFactor={1} />
          <Type weight="semibold" whiteSpace="pre-wrap" size="15px">
            {title}
            {isNew && (
              <span style={{ whiteSpace: 'nowrap' }}>
                &nbsp;&nbsp;
                <Badge kind="blue" size="s">
                  New
                </Badge>
              </span>
            )}
          </Type>
        </Flex>
      </figcaption>
    </figure>
  );
};

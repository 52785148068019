import React from 'react';
import { Flex } from '@ui/Flex';
import { useWorkspace } from '@utils/Data/Workspaces/useWorkspace';
import { ConversationsView } from './ConversationsView';
import * as css from './BotStatistic.css';
import { ChargingEntityType } from '@globals';

interface WorkspaceBotStatisticProps {
  workspaceId: string;
}

export const WorkspaceBotStatistic: React.FC<WorkspaceBotStatisticProps> = ({
  workspaceId,
}) => {
  const { workspace } = useWorkspace(workspaceId);
  const chargingEntityType = workspace?.subscription?.chargingEntityType;

  return (
    <Flex className={css.wrapper}>
      <Flex className={css.item}>
        <ConversationsView
          numerator={workspace?.limitation?.reached ?? 0}
          denominator={workspace?.limitation?.limit ?? 0}
          captionKey={
            chargingEntityType === ChargingEntityType.users
              ? 'pages.GrowPage.BotPanel.statistics.botSubscribers'
              : 'pages.GrowPage.BotPanel.statistics.conversations'
          }
        />
      </Flex>
    </Flex>
  );
};

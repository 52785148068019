import React from 'react';
import { getOtherFieldId, testValue } from '../../helpers';
import { sendEvent } from '@utils/Analytics';
import {
  IntroQuestionsQuestionHasOtherProps,
  IntroQuestionsValues,
} from '../../types';
import { IntroQuestionsQuestionType, Platform } from '@globals';
import { IntroQuestionsBlockQuestionSelect } from '../IntroQuestionsBlockQuestionSelect';
import { IntroQuestionsBlockQuestionMultiSelect } from '../IntroQuestionsBlockQuestionMultiSelect';
import { IntroQuestionsBlockQuestionRadio } from '../IntroQuestionsBlockQuestionRadio';
import { IntroQuestionsConfigQuery_introQuestionsConfig_variables } from '../../hooks/@types/IntroQuestionsConfigQuery';
import { FormikErrors, FormikTouched } from 'formik';
import { IntroQuestionsBlockQuestionPhoneInput } from '../IntroQuestionsBlockQuestionPhoneInput';
import { IntroQuestionsBlockQuestionTextInput } from '../IntroQuestionsBlockQuestionTextInput';

interface IntroQuestionsItemProps {
  item: IntroQuestionsConfigQuery_introQuestionsConfig_variables;
  index: number;
  values: IntroQuestionsValues;
  errors: FormikErrors<IntroQuestionsValues>;
  touched: FormikTouched<IntroQuestionsValues>;
  platform: Platform;
  handleBlur: React.FocusEventHandler;
  validateField: (name: string) => Promise<void> | Promise<string | undefined>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  setFieldTouched: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
}

export const IntroQuestionsItem: React.FC<IntroQuestionsItemProps> = ({
  item: {
    id,
    type,
    title,
    options,
    show_other: showOther,
    dependency,
    required,
    disabled,
  },
  index,
  platform,
  values,
  handleBlur,
  validateField,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
}) => {
  if (
    dependency?.length &&
    dependency.every(
      ({ option, question }) => !testValue(option, values[question] || ''),
    )
  ) {
    return null;
  }

  const otherItemId = getOtherFieldId(id);

  const commonQuestionOptions = {
    id,
    title,
    required: !!required,
    disabled: !!disabled,
    key: id,
    items: options,
    selectedItemIds: values[id] as string[],
    onBlur: (event: React.FocusEvent) => {
      handleBlur(event);
      validateField(id);
      sendEvent({
        category: 'first-session',
        label: 'intro questions block',
        action: 'field change',
        propertyBag: {
          fieldName: id,
          value: values[id] as string[],
          platform,
        },
      });
    },
    showErrorState: !!(touched[id] && errors[id]),
    onChange: async (value: string | string[]) => {
      setFieldValue(id, value);
      await setFieldTouched(id, true);
      validateField(id);
    },
    'data-testid': `first-session__intro-questions-block_field-${index}`,
  };

  const commonQuestionSelectOptions: IntroQuestionsQuestionHasOtherProps = {
    showOtherItem: !!showOther,
    otherItemValue: values[otherItemId] as string,
    onChangeOtherItemValue: async (value: string) => {
      setFieldValue(otherItemId, value);
      await setFieldTouched(otherItemId, true);
      validateField(otherItemId);
    },
    showOtherItemErrorState: !!(touched[otherItemId] && errors[otherItemId]),
    onOtherItemBlur: (event: React.FocusEvent<HTMLInputElement>) => {
      handleBlur(event);
      validateField(otherItemId);
      sendEvent({
        category: 'first-session',
        label: 'intro questions block',
        action: 'field change',
        propertyBag: {
          fieldName: otherItemId,
          value: event.currentTarget.value,
          platform,
        },
      });
    },
  };

  switch (type) {
    case IntroQuestionsQuestionType.select:
      return (
        <IntroQuestionsBlockQuestionSelect
          {...commonQuestionOptions}
          {...commonQuestionSelectOptions}
        />
      );
    case IntroQuestionsQuestionType.multiselect:
      return (
        <IntroQuestionsBlockQuestionMultiSelect
          {...commonQuestionOptions}
          {...commonQuestionSelectOptions}
        />
      );
    case IntroQuestionsQuestionType.phoneInput:
      return (
        <IntroQuestionsBlockQuestionPhoneInput {...commonQuestionOptions} />
      );
    case IntroQuestionsQuestionType.radiobutton:
      return <IntroQuestionsBlockQuestionRadio {...commonQuestionOptions} />;
    case IntroQuestionsQuestionType.text:
      return <IntroQuestionsBlockQuestionTextInput {...commonQuestionOptions} />;
    default:
      return null;
  }
};

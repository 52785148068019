import React, { useState } from 'react';
import OutsideClick from 'react-outsideclick';
import { ChangeTextInput } from '@ui/ChangeTextInput/ChangeTextInput';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import * as css from './ChangeBotNameForm.css';

export interface ChangeBotNameFormProps {
  title: string;
  onSubmit: (name: string) => void;
  onReject: () => void;
}

export const ChangeBotNameForm: React.FC<ChangeBotNameFormProps> = ({
  title,
  onSubmit,
  onReject,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const [botName, setBotName] = useState('');

  return (
    <form
      className={css.formMobile}
      noValidate
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          setBotName('');
          onReject();
        }
      }}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(botName);
      }}
    >
      <OutsideClick onClickOutside={() => onReject()}>
        <ChangeTextInput
          className={css.input}
          columnOrientation={isSmallScreenSize}
          containerClassName={css.inputContainer}
          initialValue={title}
          error={(value) => value.trim().length === 0}
          maxLength={255}
          onChange={(e) => setBotName(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          onRenameClick={(_, title) => {
            onSubmit(title);
          }}
        />
      </OutsideClick>
    </form>
  );
};

import React from 'react';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import { DefaultDialog, DefaultDialogProps } from '@ui/Dialog';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Header } from './Components';

export interface RequestNotificationCodeDialogProps
  extends Omit<DefaultDialogProps, 'children'> {
  phoneNumber: string;
  onSubmit: () => void;
}

export const RequestNotificationCodeDialog: React.FC<RequestNotificationCodeDialogProps> =
  ({ phoneNumber, onSubmit, ...dialogProps }) => {
    const { t } = useSafeTranslation();
    const { isSmallScreenSize } = useDeviceMedia();

    return (
      <DefaultDialog
        small
        mobileAdaptive={isSmallScreenSize}
        header={
          <Header>
            {t('pages.BotPage.HomeTab.Whatsapp.RequestNotificationCode.header')}
          </Header>
        }
        dialogStyle={{ width: 400 }}
        {...dialogProps}
      >
        <Type>
          {t('pages.BotPage.HomeTab.Whatsapp.RequestNotificationCode.text')}
          &nbsp;
          {phoneNumber}
        </Type>

        <Spacer factor={23} />

        <Button
          onClick={onSubmit}
          fullWidth
          data-testid="whatsapp__send-verification-code"
        >
          {t('pages.BotPage.HomeTab.Whatsapp.RequestNotificationCode.submit')}
        </Button>
      </DefaultDialog>
    );
  };

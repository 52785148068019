import React from 'react';
import { BotSectionBodyLayout } from './BotSectionBodyLayout';
import { WorkspaceBotStatistic } from '../BotStatistic/WorkspaceBotStatistic';

interface WorkspaceBotSectionBodyViewProps {
  workspaceId: string;
}

export const WorkspaceBotSectionBodyView: React.FC<WorkspaceBotSectionBodyViewProps> =
  ({ workspaceId }) => {
    return (
      <BotSectionBodyLayout>
        <WorkspaceBotStatistic workspaceId={workspaceId} />
      </BotSectionBodyLayout>
    );
  };

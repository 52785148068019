import React from 'react';
import { Button, ButtonProps, ButtonUnstyled } from '@ui/Button';
import { Type } from '@ui/Type';

export interface SizeDependentButtonProps extends ButtonProps {
  small?: boolean;
}

export const SizeDependentButton = React.forwardRef<
  HTMLButtonElement,
  SizeDependentButtonProps
>(({ small, size, ...props }, ref) =>
  small ? (
    <ButtonUnstyled ref={ref} {...props}>
      <Type
        size="12px"
        color={props.intent === 'red' ? 'redSecondary' : 'blue'}
      >
        {props.children}
      </Type>
    </ButtonUnstyled>
  ) : (
    <Button ref={ref} {...props} />
  ),
);

import { SimpleCombobox } from '@ui/SimpleCombobox';
import { ReactComponent as DotsIcon } from '@ui/_deprecated/Icon/icons/More_small.svg';
import { Button } from '@ui/Button';
import { MenuItem } from '@ui/Menu';
import { Tooltip2 } from '@ui/Tooltip2';
import { Type } from '@ui/Type';
import { sendEvent } from '@utils/Analytics';
import { combineRefs } from '@utils/combineRefs';
import React from 'react';
import { ReactComponent as ShareFlowPreview } from '../../common/assets/share-flow-preview.svg';
import * as css from './FlowGroup.css';
import { FlowGroupMenuType } from './types';

interface FlowGroupMenuProps {
  botId?: string;
  groupId: string;
  isDefault: boolean;
  isLast: boolean;
  setEditMode(status: boolean): void;
  setShownModalId(id: FlowGroupMenuType): void;
}

export const FlowGroupMenu: React.FC<FlowGroupMenuProps> = ({
  botId,
  groupId,
  isDefault,
  isLast,
  setEditMode,
  setShownModalId,
}) => {
  if (!botId) {
    return null;
  }

  return (
    <SimpleCombobox<{
      id: FlowGroupMenuType;
      title: string;
    }>
      position="bottom-end"
      unsaveSelectedOption
      onChange={(item) => {
        switch (item?.id) {
          case FlowGroupMenuType.rename:
            setEditMode(true);
            break;
          case FlowGroupMenuType.delete:
          case FlowGroupMenuType.copy:
            setShownModalId(item?.id);
            break;
          case FlowGroupMenuType.share:
            sendEvent({
              category: 'share flows',
              action: 'open modal',
              propertyBag: {
                botId,
                groupId,
              },
            });
            setShownModalId(item?.id);
            break;
          default:
            break;
        }
      }}
      renderInput={({ getToggleButtonProps, ref }) => (
        <Tooltip2
          placement="top"
          boundariesElement="viewport"
          content={
            <Type as="p" size="12px" color="white">
              {window.i18next.t('FlowGroupMenu-JSXText--200-section-options')}
            </Type>
          }
          type="small"
          positionFixed
        >
          {(tooltipRef, bind) => (
            <Button
              {...bind}
              {...getToggleButtonProps()}
              className={css.dotsButton}
              ref={combineRefs([ref, tooltipRef])}
              icon={<DotsIcon />}
              data-testid="flow-list__flow-group__menu-button"
              intent="text"
              size="xs"
            />
          )}
        </Tooltip2>
      )}
      renderItem={({ getItemProps, item, index, highlightedIndex }) => {
        const commonProps = {
          ...getItemProps({ item }),
          key: `${item.id}_${index}`,
          active: index === highlightedIndex,
          title: item.title,
        };

        switch (item.id) {
          case FlowGroupMenuType.share:
            return (
              <Tooltip2
                placement="right"
                boundariesElement="viewport"
                key={commonProps.key}
                content={
                  <>
                    <ShareFlowPreview />
                    <br />
                    <br />
                    <Type as="p" size="12px" color="white">
                      {window.i18next.t(
                        'FlowGroupMenu-JSXText--105-share-the-link-to-your-bot-flow-group',
                      )}
                      <br />
                      {window.i18next.t(
                        'FlowGroupMenu-JSXText--442-with-colleagues-or-clients-theyll-get',
                      )}
                      <br />
                      {window.i18next.t(
                        'FlowGroupMenu-JSXText--114-an-overview-of-the-project-and-can',
                      )}
                      <br />
                      {window.i18next.t(
                        'FlowGroupMenu-JSXText-1681-copy-the-flow-group-to-a-new-bot-if',
                      )}
                      <br />
                      {window.i18next.t(
                        'FlowGroupMenu-JSXText-5967-you-choose-to-allow-that',
                      )}
                    </Type>
                  </>
                }
                type="small"
                positionFixed
              >
                {(ref, bind) => (
                  <div {...bind} ref={ref}>
                    <MenuItem {...commonProps} />
                  </div>
                )}
              </Tooltip2>
            );
          default:
            return <MenuItem {...commonProps} />;
        }
      }}
      items={(
        [
          !isDefault && {
            id: FlowGroupMenuType.rename,
            title: window.i18next.t('FlowGroupMenu-string--185-rename'),
          },
          {
            id: FlowGroupMenuType.copy,
            title: window.i18next.t('FlowGroupMenu-string-2106-copy'),
          },
          {
            id: FlowGroupMenuType.share,
            title: window.i18next.t('FlowGroupMenu-string-1470-share-link'),
          },
          !isLast && {
            id: FlowGroupMenuType.delete,
            title: window.i18next.t('FlowGroupMenu-string-2043-delete'),
          },
        ] as any[]
      ).filter(Boolean)}
    />
  );
};

import { clone } from 'ramda';
import { useCurrentBotId } from '@utils/Routing';
import { useQuery } from 'react-apollo';
import {
  WhatsappBroadcast,
  WhatsappBroadcastVariables,
} from './@types/WhatsappBroadcast';
import { WHATSAPP_BROADCAST_QUERY } from './queries';
import Maybe from 'graphql/tsutils/Maybe';
import { useOnBroadcastTimePassed } from './useOnBroadcastTimePassed';
import { BroadcastStatus } from '@globals';
import memoize from 'lodash-es/memoize';
import { Observable } from 'rxjs';
import client from '@common/services/ApolloService';
import { ObservableInput } from 'rxjs/Observable';
import { ApolloQueryResult } from 'apollo-client';

export const useWhatsappBroadcast = (broadcastId: Maybe<string>) => {
  const botId = useCurrentBotId()!;
  const queryResult = useQuery<WhatsappBroadcast, WhatsappBroadcastVariables>(
    WHATSAPP_BROADCAST_QUERY,
    {
      variables: { botId, broadcastId: broadcastId! },
      skip: !botId || !broadcastId,
    },
  );

  useOnBroadcastTimePassed(queryResult.data?.whatsappBroadcast, () => {
    /**
     * Тк нет четкого понимания когда именно бродкаст отправится, может случиться
     * ситуация что при вызове refetch бродкаст все еще не отправлен, поэтому проще
     * будет не рефетчить, а напрямую писать в кеш
     */
    queryResult.updateQuery((prev) => {
      const broadcast = clone(prev);
      broadcast.whatsappBroadcast.status = BroadcastStatus.SENT;
      return broadcast;
    });
  });

  return queryResult;
};

export const getWhatsappBroadcastObservable = memoize(
  (botId: string, broadcastId: string) =>
    Observable.from(
      client.watchQuery<WhatsappBroadcast, WhatsappBroadcastVariables>({
        query: WHATSAPP_BROADCAST_QUERY,
        variables: {
          botId,
          broadcastId,
        },
      }) as ObservableInput<ApolloQueryResult<WhatsappBroadcast>>,
    ).map(({ data }) => ({
      whatsappBroadcast: data?.whatsappBroadcast,
    })),
  (...args) => Object.values(args).join('_'),
);

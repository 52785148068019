import React from 'react';
import { formatPrice } from '@utils/Format';
import { formatPricingDate } from '@utils/DateTime/formatDate';
import { useBillingData } from '../../BotSectionView/Data/useBillingData';
import { getNextChargeAdjustmentFeeTooltip } from '../utils';
import { NextChargeDetailsView } from './NextChargeDetailsView';
import { PlanTooltip } from '../PlanTooltip';

const Tooltip: React.FC = ({ children }) => {
  const { pricing, tier } = useBillingData();

  if (!pricing || !tier) {
    return null;
  }

  const { adjustment_fee, currency } = pricing;

  const tooltipContent = getNextChargeAdjustmentFeeTooltip({
    currency,
    adjustmentPrice: adjustment_fee?.price,
  });

  return <PlanTooltip tooltipContent={tooltipContent}>{children}</PlanTooltip>;
};

export const NextAdjustmentFeeChargeDetails = () => {
  const { pricing } = useBillingData();

  if (!pricing) {
    return null;
  }

  const { adjustment_fee, currency, next_billing_date } = pricing;

  const date = formatPricingDate(next_billing_date);
  const amount = adjustment_fee?.price
    ? formatPrice(adjustment_fee.price, { currency })
    : null;

  if (!amount) {
    return null;
  }

  return (
    <NextChargeDetailsView tooltip={Tooltip} amount={amount} date={date} />
  );
};

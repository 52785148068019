import gql from 'graphql-tag';

export const RECREATE_REMOVED_FLOW_MUTATION = gql`
  mutation RecreateRemovedFlowMutation($botId: ID!, $flowId: ID!) {
    recreateRemovedFlow(botId: $botId, flowId: $flowId) {
      parentGroupId
      flow {
        id
        title
        platform
        entry_points {
          id
          entry_point_type
          title
          enabled
        }
        sharing_params {
          allow_cloning
          sharing_enabled
        }
        collapsed
      }
    }
  }
`;

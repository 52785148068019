import React from 'react';
import cn from 'classnames';
import { Translation } from '@translations';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './SingleButtonFooter.css';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

interface SingleButtonFooterProps {
  buttonI18nKey: Translation;
  onButtonClick: VoidFunction;
  buttonDisabled?: boolean;
  buttonLoading?: boolean;
}

export const SingleButtonFooter: React.FC<SingleButtonFooterProps> = ({
  buttonI18nKey,
  onButtonClick,
  buttonDisabled,
  buttonLoading,
}) => {
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();

  return (
    <div className={cn(isSmallScreenSize && css.mobile)}>
      <Spacer factor={4} />
      <div className={css.divider} />
      <Spacer factor={4} />
      <Button
        data-testid="purchase-phone-number_footer-button"
        loading={buttonLoading}
        disabled={buttonDisabled}
        onClick={onButtonClick}
        fullWidth
      >
        {t(buttonI18nKey)}
      </Button>
    </div>
  );
};

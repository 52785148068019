import { WhatsappBroadcastConfig } from '../types';
import { IBroadcastType, BroadcastTypes } from './types';

type BroadcastMap = Record<BroadcastTypes, IBroadcastType>;

export class BroadcastsTypeManager {
  private readonly broadcastsMap: BroadcastMap;

  constructor(private readonly broadcasts: Array<IBroadcastType>) {
    this.broadcastsMap = this.broadcasts.reduce(
      (prev, curr) => ({ ...prev, [curr.type]: curr }),
      {} as BroadcastMap,
    );
  }

  public getBroadcastByType(type: BroadcastTypes) {
    return this.broadcastsMap[type];
  }

  public getTypeFromConfig(config: WhatsappBroadcastConfig): BroadcastTypes {
    const currentBroadcast = this.broadcasts.find((broadcast) =>
      broadcast.isCurrentBroadcast(config),
    );

    if (!currentBroadcast) {
      throw new Error('Cannot deduce broadcast type by config');
    }

    return currentBroadcast.type;
  }

  public getBroadcasts() {
    return this.broadcasts;
  }

  public getDefaultBroadcast() {
    return this.broadcasts[0];
  }
}

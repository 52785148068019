import React, { useCallback, useEffect, useState } from 'react';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { SingleButtonFooter } from '../common/SingleButtonFooter';
import { Spacer } from '@ui/Spacer';
import { MenuItem } from '@ui/Menu';
import { ComboboxWithTriangleButton } from '@ui/SimpleCombobox';
import { CountryCode } from './components/CountryCode';
import * as css from './ChooseCountryCode.css';
import { getCountriesItems } from './helpers/getCountriesItems';
import { CountryCodeItem } from './types';
import { usePurchaseAdminPhoneNumber } from '../../hooks/usePurchaseAdminPhoneNumber';
import { usePrevious } from 'cf-common/src/utils/hooks';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import cn from 'classnames';
import { sendEvent } from 'cf-common/src/analytics';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Button, ButtonUnstyled } from '@ui/Button';
import { Trans } from 'react-i18next';
import { CollapsibleSection } from '@ui/CollapsibleSection';

export const ChooseCountryCodeDropdown = () => {
  const { t } = useSafeTranslation();
  const [isNeedDifferentClicked, setIsNeedDifferentClicked] =
    useState<boolean>(false);

  const needDifferentClickHandler = useCallback(() => {
    setIsNeedDifferentClicked(true);
    window.Intercom?.('update', { need_different_country_code: true });
    sendEvent({
      category: 'purchase phone number',
      label: 'choose country code',
      action: 'interested button click',
    });
  }, []);

  return (
    <CollapsibleSection
      anchor={({ changeExtended, extended }) => (
        <ButtonUnstyled
          data-testid="purchase-phone-number_register_cant-use-camera-button"
          onClick={() => {
            changeExtended();
            sendEvent({
              category: 'purchase phone number',
              label: 'choose country code',
              action: 'need a different button click',
            });
          }}
        >
          <Flex alignItems="center">
            <Type color="blue">
              {t(
                'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.chooseCountryCode.needCode',
              )}
            </Type>
            <Icon
              icon="chevronUp"
              color="blue"
              style={{
                transform: `rotate(${!extended ? '-180deg' : '0deg'})`,
              }}
            />
          </Flex>
        </ButtonUnstyled>
      )}
    >
      {({ bind }) => (
        <div {...bind}>
          <Spacer factor={1} />
          <div className={css.noteBox}>
            <Trans
              t={t}
              i18nKey="pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.chooseCountryCode.requestCode"
            />
            <Spacer factor={3} />
            <Flex alignItems="center" gapFactor={3}>
              <Button
                intent="primary"
                disabled={isNeedDifferentClicked}
                onClick={needDifferentClickHandler}
              >
                {t(
                  'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.chooseCountryCode.interested',
                )}
              </Button>
              {isNeedDifferentClicked && (
                <Type color="accent2Dark">
                  {t(
                    'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.chooseCountryCode.weWillContact',
                  )}
                </Type>
              )}
            </Flex>
          </div>
        </div>
      )}
    </CollapsibleSection>
  );
};

interface ChooseCountryCodeProps {
  onDone: VoidFunction;
}

export const ChooseCountryCode: React.FC<ChooseCountryCodeProps> = ({
  onDone,
}) => {
  const { t } = useSafeTranslation();
  const [country, setCountry] = useState<CountryCodeItem | undefined>();
  const {
    purchaseAdminPhoneNumber: purchaseAdminPhoneNumberMutation,
    loading,
    phones,
  } = usePurchaseAdminPhoneNumber();
  const { isSmallScreenSize } = useDeviceMedia();

  const purchaseAdminPhoneNumber = useCallback(() => {
    sendEvent({
      category: 'purchase phone number',
      label: 'generate',
      action: 'create button click',
      propertyBag: {
        country: country?.id,
      },
    });
    if (!country) {
      return;
    }
    purchaseAdminPhoneNumberMutation({
      variables: { countryCode: country.id },
    });
  }, [country, purchaseAdminPhoneNumberMutation]);

  const prevPhones = usePrevious(phones);
  useEffect(() => {
    if (!prevPhones?.length && phones?.length) {
      onDone();
    }
  }, [onDone, phones, prevPhones]);

  return (
    <>
      <Type>
        {t(
          'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.chooseCountryCode.text',
        )}
      </Type>
      <Spacer factor={4} />
      <ComboboxWithTriangleButton<CountryCodeItem>
        itemToString={(item) => item?.title || ''}
        className={cn(css.selector, isSmallScreenSize && css.mobile)}
        items={getCountriesItems(t)}
        selectedItem={country}
        onChange={(country) => {
          setCountry(country);
          sendEvent({
            category: 'purchase phone number',
            label: 'choose country code',
            action: 'change country code',
            propertyBag: {
              countryCode: country.id,
            },
          });
        }}
        data-testid="purchase-phone-number_choose-country_selector"
        buttonChildren={(item) =>
          item ? (
            <CountryCode item={item} />
          ) : (
            <Type>
              {t(
                'pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.chooseCountryCode.placeholder',
              )}
            </Type>
          )
        }
      >
        {({
          downshift: { getItemProps, highlightedIndex },
          props: { item, index },
        }) => (
          <MenuItem
            titleElement={<CountryCode item={item} />}
            active={index === highlightedIndex}
            key={item.id}
            className={cn(isSmallScreenSize && css.itemMobile)}
            {...getItemProps({ item })}
          />
        )}
      </ComboboxWithTriangleButton>
      {/* https://chatfuel.atlassian.net/browse/CHAT-10065
      <Spacer factor={6} />
      <ChooseCountryCodeDropdown />
			*/}
      <SingleButtonFooter
        buttonI18nKey="pages.BotPage.HomeTab.Whatsapp.buyPhoneDialog.steps.chooseCountryCode.button"
        onButtonClick={purchaseAdminPhoneNumber}
        buttonLoading={loading}
        buttonDisabled={!country}
      />
    </>
  );
};

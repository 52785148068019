import gql from 'graphql-tag';

export const FREQUENT_UNRECOGNIZED_MESSAGES_QUERY = gql`
  query FrequentUnrecognizedMessagesQuery(
    $botId: String!
    $platform: Platform!
  ) {
    bot(id: $botId) {
      id
      aiPhraseGroups(platform: $platform) {
        phrase
        count
      }
    }
  }
`;
